import {
    SET_LOADING,
    SET_USER_INFO,
    SET_STARTUP_WEBSOCKETS_ACTIVE,
    SET_DEVICE_DISCONNECTED,
    SET_VISIT_COUNT,
    SET_SPECES,
    SET_DEFAULT_SPECS,
} from "./userInfoActions";

export default (state: any, action: any) => {
    switch (action.type) {
        case SET_LOADING: {
            return {
                ...state,
                loading: true,
            };
        }
        case SET_SPECES: {
            return {
                ...state,
                speces: action.payload,
            };
        }
        case SET_DEFAULT_SPECS: {
            return {
                ...state,
                defaultSpecs: action.payload,
            };
        }
        case SET_USER_INFO: {
            return {
                ...state,
                loading: false,
                configuratorVersion: action.payload.configurationVersion,
                desktopVersion: action.payload.desktopVersion,
                enableSentryCrashReport: action.payload.enableSentryCrashReport,
                enableTerminal: action.payload.enableTerminal,
            };
        }
        case SET_STARTUP_WEBSOCKETS_ACTIVE: {
            return {
                ...state,
                isStartUpWebsocketsActive: action.payload,
            };
        }
        case SET_DEVICE_DISCONNECTED: {
            return {
                ...state,
                isDeviceDisconnected: action.payload,
            };
        }
        case SET_VISIT_COUNT:
            return {
                ...state,
                visitCount: state.visitCount + 1,
            };
        default:
            return state;
    }
};
