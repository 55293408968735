/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/react";
import { Fragment, useContext } from "react";
import IoTableToolbar from "./IoTable/IoTableToolbar";
import { Block, Frame, Segment } from "../../../../../generatedTypes";
import IoTable from "./IoTable";
import InputOutputState from "../../../../../context/inputOutput/inputOutputState";
import MenuItemContext from "../../../../../context/menuItem/menuItemContext";

export interface IoFrameProps {
    data: Frame;
}

const IoFrame = ({ data }: IoFrameProps) => {
    const { blocks } = data;

    const { parameters } = useContext(MenuItemContext);

    const renderToolbar = (index: number, isCanTable: boolean) => {
        if (index === 0) {
            return <IoTableToolbar isCanTable={isCanTable} />;
        }
        return null;
    };

    const renderRegularIoItem = (block: Block, segment: Segment) => {
        if (!segment.ioItem) {
            throw new Error(
                `Cannot render segment in block ${block.name} (index: ${block.index}) because ioItem is required for IoFrame`
            );
        }

        return {
            name: segment.ioItem.name,
            description: segment.ioItem.description,
            avlId: segment.ioItem.avlId,
            parameterValue: {
                id: segment.ioItem.ioId,
                switchValue:
                    parameters[segment.ioItem.ioId] !== "0" ? "1" : "0",
            },
            eventOnly: segment.ioItem.eventOnly && {
                ...segment.ioItem.eventOnly,
                parameterValue:
                    parameters[segment.ioItem.eventOnly.parameterId],
            },
            highLevel: segment.ioItem.highLevel && {
                ...segment.ioItem.highLevel,
                parameterValue:
                    parameters[segment.ioItem.highLevel.parameterId],
            },
            lowLevel: segment.ioItem.lowLevel && {
                ...segment.ioItem.lowLevel,
                parameterValue: parameters[segment.ioItem.lowLevel.parameterId],
            },
            operand: segment.ioItem.operand && {
                ...segment.ioItem.operand,
                parameterValue: parameters[segment.ioItem.operand.parameterId],
            },
            phone: segment.ioItem.phone && {
                ...segment.ioItem.phone,
                parameterValue: parameters[segment.ioItem.phone.parameterId],
            },
            priority: {
                ...segment.ioItem.priority,
                parameterValue:
                    parameters[segment.ioItem.priority?.parameterId || ""],
            },
            text: segment.ioItem.text && {
                ...segment.ioItem.text,
                parameterValue: parameters[segment.ioItem.text.parameterId],
            },
            averagingConstant: segment.ioItem.averagingConstant && {
                ...segment.ioItem.averagingConstant,
                parameterValue:
                    parameters[segment.ioItem.averagingConstant.parameterId],
            },
            canId: undefined,
        };
    };

    const renderCanIoItem = (block: Block, segment: Segment) => {
        if (!segment.canItem) {
            throw new Error(
                `Cannot render segment in block ${block.name} (index: ${block.index}) because ioItem is required for IoFrame`
            );
        }
        return {
            name: segment.canItem.name,
            description: "",
            avlId: segment.canItem.avlId,
            parameterValue: {
                id: segment.canItem.ioId,
                switchValue:
                    parameters[segment.canItem.ioId] !== "0" ? "1" : "0",
            },
            canSrc: segment.canItem.canSrc && {
                ...segment.canItem.canSrc,
                parameterValue: parameters[segment.canItem.canSrc.parameterId],
            },
            canType: segment.canItem.canType && {
                ...segment.canItem.canType,
                parameterValue: parameters[segment.canItem.canType.parameterId],
            },
            canId: segment.canItem.canId && {
                ...segment.canItem.canId,
                parameterValue: parameters[segment.canItem.canId.parameterId],
            },
            dataMask: segment.canItem.dataMask && {
                ...segment.canItem.dataMask,
                parameterValue:
                    parameters[segment.canItem.dataMask.parameterId],
            },
            priority: {
                ...segment.canItem.priority,
                parameterValue:
                    parameters[segment.canItem.priority?.parameterId || ""],
            },
            operand: segment.canItem.operand && {
                ...segment.canItem.operand,
                parameterValue: parameters[segment.canItem.operand.parameterId],
            },
        };
    };

    return (
        <Fragment>
            {blocks.map((block, i) => {
                const { name, ioColumnLocalization } = block;
                const rowData =
                    block.segments?.map((segment) => {
                        return segment.canItem
                            ? renderCanIoItem(block, segment)
                            : renderRegularIoItem(block, segment);
                    }) ?? [];

                return (
                    <Fragment key={i}>
                        {renderToolbar(i, rowData[0].canId !== undefined)}
                        <IoTable
                            key={i}
                            name={name || ""}
                            data={rowData}
                            isFirstTable={i === 0}
                            isCanTable={rowData[0].canId !== undefined}
                            ioColumnLocalization={
                                ioColumnLocalization?.columnLocalization || []
                            }
                        />
                    </Fragment>
                );
            })}
        </Fragment>
    );
};

const IoFrameWithState = ({ data }: IoFrameProps) => {
    return (
        <InputOutputState>
            <IoFrame data={data} />
        </InputOutputState>
    );
};

export default IoFrameWithState;
