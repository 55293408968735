import React, {  useMemo, useState } from "react";
import AuthenticationContext from "./authenticationContext";

interface AuthenticationStateProps {
    children: React.ReactNode;
}

const AuthenticationState = ({ children }: AuthenticationStateProps) => {
    const [isAuthenticated, setAuthenticated] = useState<boolean>(false);
    const contextValue = useMemo(() => {
        return { isAuthenticated, setAuthenticated }
    }, [isAuthenticated]);

    return <AuthenticationContext.Provider
        value={contextValue}
    >
        {children}
    </AuthenticationContext.Provider>
}

export default AuthenticationState;