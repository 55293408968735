import { ListItemInterface } from "../../components/Editor/MenuItemView/Segment/Component/List/List";
import {
    ADD_INPUT,
    CHANGE_INPUTS,
    CLEAR_INPUTS,
    CLOSE_WITHOUT_SAVING,
    HANDLE_CONFIRMATION_DIALOG,
    IMPORT_INPUT_VALUES,
    SAVE_VALUES,
    SET_ERROR,
    SET_INITIAL_INPUTS,
    SET_PREVIOUS_LANGUAGE,
    SET_SAVE_LOADING,
} from "./listActions";
import { importInputValues } from "./listHelpers";

export interface ErrorsObj {
    [key: number]: string | null;
}

export interface InitialState {
    isSaveLoading: boolean;
    isAllowedToScroll: boolean;
    isOpenConfirmation: boolean;
    isError: boolean;
    inputs: ListItemInterface[];
    previousInputs: ListItemInterface[];
    errors: ErrorsObj;
    availableParameterIds: number[];
}

type Action =
    | {
          type: typeof SET_INITIAL_INPUTS;
          payload: {
              inputsPayload: ListItemInterface[];
              errorsPayload: ErrorsObj;
          };
      }
    | {
          type: typeof IMPORT_INPUT_VALUES;
          payload: {
              inputsPayload: ListItemInterface[];
              errorsPayload: ErrorsObj;
          };
      }
    | { type: typeof CLEAR_INPUTS; payload: ListItemInterface[] }
    | {
          type: typeof CHANGE_INPUTS;
          payload: {
              inputsPayload: ListItemInterface[];
              countPayload: number;
              errorValuePayload: ErrorsObj;
          };
      }
    | {
          type: typeof ADD_INPUT;
          payload: {
              inputPayload: ListItemInterface;
              errorPayload: ErrorsObj;
          };
      }
    | {
          type: typeof SAVE_VALUES;
          payload: {
              countPayload: number;
              payloadInputs: ListItemInterface[];
              errorsPayload: ErrorsObj;
          };
      }
    | {
          type: typeof CLOSE_WITHOUT_SAVING;
          payload: {
              inputsPayload: ListItemInterface[];
              countPayload: number;
              errorsPayload: ErrorsObj;
          };
      }
    | {
          type: typeof HANDLE_CONFIRMATION_DIALOG;
          payload: boolean;
      }
    | {
          type: typeof SET_SAVE_LOADING;
          payload: boolean;
      }
    | {
          type: typeof SET_ERROR;
          payload: boolean;
      }
    | { type: typeof SET_PREVIOUS_LANGUAGE; payload: string };

export default (state: InitialState, action: Action) => {
    switch (action.type) {
        case SET_INITIAL_INPUTS: {
            const { inputsPayload, errorsPayload } = action.payload;

            return {
                ...state,
                inputs: inputsPayload,
                previousInputs: inputsPayload,
                errors: errorsPayload,
            };
        }
        case IMPORT_INPUT_VALUES: {
            return importInputValues(state, action);
        }
        case CLEAR_INPUTS: {
            const clearErrors = () => {
                const errorsObj = { ...state.errors };

                Object.keys(errorsObj).forEach(
                    (key) => (errorsObj[Number(key)] = null)
                );

                return errorsObj;
            };

            return {
                ...state,
                inputs: action.payload,
                errors: clearErrors(),
            };
        }
        case CHANGE_INPUTS: {
            const { inputsPayload, errorValuePayload } = action.payload;

            return {
                ...state,
                inputs: inputsPayload,
                errors: { ...state.errors, ...errorValuePayload },
            };
        }
        case ADD_INPUT: {
            const { inputPayload, errorPayload } = action.payload;

            return {
                ...state,
                isAllowedToScroll: true,
                inputs: [...state.inputs, inputPayload],
                errors: { ...state.errors, ...errorPayload },
            };
        }
        case SAVE_VALUES: {
            const { payloadInputs, errorsPayload } = action.payload;

            return {
                ...state,
                isAllowedToScroll: false,
                inputs: payloadInputs,
                previousInputs: payloadInputs,
                errors: errorsPayload,
            };
        }
        case CLOSE_WITHOUT_SAVING: {
            return importInputValues(state, action);
        }
        case HANDLE_CONFIRMATION_DIALOG: {
            return {
                ...state,
                isOpenConfirmation: action.payload,
            };
        }
        case SET_SAVE_LOADING: {
            return {
                ...state,
                isSaveLoading: action.payload,
            };
        }
        case SET_ERROR: {
            return {
                ...state,
                isError: action.payload,
            };
        }
        default:
            return state;
    }
};
