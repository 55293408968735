/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import { useContext, useEffect, useState } from "react";
import MuiDrawer from "@mui/material/Drawer";
import LayoutContext from "../../../context/layout/layoutContext";
import Button from "../Button";
import CloseIcon from "@mui/icons-material/CloseRounded";
import InfoIcon from "../../../assets/icons/InfoIcon";
import { renderFeElement } from "./elements/renderElement";
import useTct from "../../../utils/useTct";

export interface DrawerProps {}

const Drawer = () => {
    const [activeDrawerTooltipData, setActiveDrawerTooltipData] =
        useState<any>(null);
    const {
        setDrawerParameterId,
        drawerParameterId,
        layoutData,
        setDrawerBlockName,
        drawerBlockName,
    } = useContext(LayoutContext);

    const { getParameterTooltipAsync, getBlockTooltipAsync } = useTct();

    useEffect(() => {
        const getTooltipData = async () => {
            try {
                if(!layoutData){
                    throw new Error("Cannot get a parameter tooltip without a layout");
                }
                
                const data = await getParameterTooltipAsync(layoutData.id, drawerParameterId);
                
                setActiveDrawerTooltipData(data);
            } catch (err) {
                console.log(err);
            }
        };
        const getBlockData = async () => {
            try {
                if(!layoutData){
                    throw new Error("Cannot get a parameter tooltip without a layout");
                }
                
                const data = await getBlockTooltipAsync(layoutData.id, drawerBlockName);
                
                setActiveDrawerTooltipData(data);
            } catch (err) {
                console.log(err);
            }
        };
        if (!!drawerParameterId) {
            getTooltipData();
        }
        if (!!drawerBlockName) {
            getBlockData();
        }
    }, [drawerParameterId, drawerBlockName]);

    return (
        <MuiDrawer
            open={!!drawerParameterId || !!drawerBlockName}
            BackdropProps={{ invisible: true }}
            anchor={"right"}
            variant={"persistent"}
            sx={{
                "& .MuiDrawer-paper": {
                    width: "100%",
                    maxWidth: "354px",
                    padding: "4px",
                    borderRadius: "0px",
                    "@media (max-width: 1024px)": {
                        maxWidth: "198px",
                    },
                    "@media (max-width: 839px)": {
                        width: "100%",
                        maxWidth: "unset",
                    },
                },
            }}
        >
            <Button
                variant="iconOnly"
                size="small"
                color="white"
                icon={<CloseIcon />}
                css={css({
                    position: "absolute",
                    top: "4px",
                    right: "4px",
                })}
                onClick={() => {
                    setDrawerParameterId(0);
                    setDrawerBlockName("");
                }}
                idForTesting="closeDrawerButton"
            />
            <div css={css({ padding: "18px" })}>
                <span
                    css={css({
                        "& > svg": {
                            width: "24px",
                            height: "24px",
                        },
                    })}
                >
                    <InfoIcon color="primary" />
                </span>
                {activeDrawerTooltipData &&
                    renderFeElement(activeDrawerTooltipData)}
            </div>
        </MuiDrawer>
    );
};

export default Drawer;
