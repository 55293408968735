/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import { Fragment, useContext, useEffect, useState } from "react";

import FormModal from "../../../../MuiComponents/Modals/FormModal";
import LanguageContext from "../../../../../context/language/languageContext";
import { findBiggestId } from "../../../../../utils/helpers";
import Select from "../../../../MuiComponents/Select";
import useApi from "../../../../../utils/useApi";
import { MenuItem } from "@mui/material";
import TypedGrid from "../../../../MuiComponents/TypedGrid";
import FuelRow from "./FuelRow";
import ThemeContext from "../../../../../context/theme/themeContext";
import Label from "../../../../MuiComponents/Label";
import { AddCircleOutline } from "@mui/icons-material";
import PolynomialSensorValueRow from "./PolynomialSensorValueRow";
import AccessoriesContext from "../../../../../context/accessories/accessoriesContext";
import useDesktopHost from "../../../../../utils/useDesktopHost";
import AlertContext from "../../../../../context/alert/alertContext";
import { AlertStatus } from "../../../../../constants/constants";

export interface LlsCalibrationModalProps {
    isOpen: boolean;
    onClose: any;
}

const LlsCalibrationModal = ({ isOpen, onClose }: LlsCalibrationModalProps) => {
    const { t, selectedLanguage } = useContext(LanguageContext);
    const { setAlert } = useContext(AlertContext);
    const { resetFuelValues, fuelValues, setFuelValues } =
        useContext(AccessoriesContext);
    const {
        colors: { blue700 },
    } = useContext(ThemeContext);

    const [rowsCount, setRowCount] = useState(4);
    const [availableSensors, setAvailableSensors] = useState<
        { id: number; name: string }[]
    >([]);

    const [activeSensor, setActiveSensor] = useState(0);
    const [polynomialData, setPolynomialData] = useState<null | {
        a0Coefficient: number;
        a1Coefficient: number;
        a2Coefficient: number;
        a3Coefficient: number;
    }>(null);

    const [activeStep, setActiveStep] = useState(0);

    const { getData, postData, showErrorMsg, deleteData } = useApi();
    const { saveFileDialog } = useDesktopHost();

    const handleExportLls = () => {
        saveFileDialog(
            {
                title: "Save File",
                filters: [
                    {
                        extensions: ["csv"],
                        name: "CSV file",
                    },
                ],
            },
            (result: string[]) => {
                if (result.length > 0) {
                    const exportToCsv = async () => {
                        try {
                            await postData(
                                `${selectedLanguage}/device/lls/exportlls?filePath=${result}`
                            );
                            onClose();
                            setAlert(
                                AlertStatus.Success,
                                "Polynomial sensor values successfully exported "
                            );
                        } catch (error) {
                            onClose();
                            showErrorMsg(error as any);
                        }
                    };

                    exportToCsv();
                }
            }
        );
    };

    const handleOnChange = async (e: any) => {
        await deleteData(`${selectedLanguage}/device/lls/all`);
        setActiveSensor(e.target.value);
    };

    useEffect(() => {
        const getAvailableSensors = async () => {
            try {
                const { data } = await getData(
                    `${selectedLanguage}/device/lls/availableSensors`
                );
                setAvailableSensors(data);
                setActiveSensor(data[0].id);
            } catch (error) {
                console.log(error);
            }
        };

        getAvailableSensors();
    }, []);

    useEffect(() => {
        setActiveStep(0);
        resetFuelValues();
        const getLlsRows = async () => {
            try {
                const { data } = await getData(
                    `${selectedLanguage}/device/lls/llslist`
                );
                const formatedData = data.map((item: any) => ({
                    fuelValue: item.userValue,
                    llsValue: item.deviceValue,
                    id: item.rowId,
                }));
                setRowCount(
                    (findBiggestId(formatedData) || 0) > 4
                        ? findBiggestId(formatedData) || 0
                        : 4
                );
                setFuelValues(formatedData);
            } catch (error) {
                console.log(error);
            }
        };
        getLlsRows();
    }, [isOpen, activeSensor]);

    useEffect(() => {
        if (activeStep === 1) {
            const getPolynomialData = async () => {
                try {
                    const { data } = await getData(
                        `${selectedLanguage}/device/lls/calculatepolynomial`
                    );
                    setPolynomialData(data);
                } catch (error) {
                    console.log(error);
                }
            };
            getPolynomialData();
        }
    }, [activeStep]);

    const modalSteps: { [key: number]: any } = {
        [0]: (
            <Fragment>
                <TypedGrid container>
                    <TypedGrid
                        item
                        lg={5}
                        md={5}
                        sm={5}
                        xs={5}
                        css={css({ marginBottom: "20px" })}
                    >
                        <Select
                            value={activeSensor}
                            label={"Available sensors"}
                            onChange={handleOnChange}
                            medium
                        >
                            {availableSensors.map((sensor) => (
                                <MenuItem key={sensor.id} value={sensor.id}>
                                    {sensor.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </TypedGrid>
                </TypedGrid>
                <TypedGrid container>
                    <TypedGrid container alignItems={"center"}>
                        <TypedGrid
                            item
                            lg={5}
                            md={5}
                            sm={5}
                            xs={5}
                            css={css({ marginBottom: "4px" })}
                        >
                            <Label title={"Fuel (liters)"} />
                        </TypedGrid>
                        <TypedGrid
                            item
                            lg={5}
                            md={5}
                            sm={5}
                            xs={5}
                            css={css({ marginBottom: "4px" })}
                        >
                            <Label title={"Value (mV)"} />
                        </TypedGrid>
                    </TypedGrid>
                    {[...Array(rowsCount)].map((item, index) => (
                        <FuelRow
                            key={item}
                            sensorId={activeSensor}
                            rowId={index + 1}
                            isFirstRow={index === 0}
                            isLastRow={index + 1 === rowsCount}
                            hasNoBorderRadius={
                                index !== 0 && index + 1 !== rowsCount
                            }
                        />
                    ))}
                </TypedGrid>
                <div
                    css={css({
                        width: "100%",
                        padding: "16px 0px",
                        display: "flex",
                        alignItems: "center",
                    })}
                >
                    <AddCircleOutline
                        css={css({ fill: blue700, marginLeft: "8px" })}
                    />
                    <span
                        css={css({
                            fontWeight: 600,
                            fontSize: "14px",
                            lineHeight: "20px",
                            letterSpacing: "0.1px",
                            color: blue700,
                            cursor: "pointer",
                            padding: "0px 4px",
                        })}
                        onClick={() => setRowCount(rowsCount + 1)}
                    >
                        Add another row
                    </span>
                </div>
            </Fragment>
        ),
        [1]: (
            <Fragment>
                {" "}
                <TypedGrid
                    container
                    alignItems={"center"}
                    css={css({ marginBottom: "140px" })}
                >
                    <TypedGrid
                        item
                        lg={6}
                        md={6}
                        sm={6}
                        xs={6}
                        css={css({ marginBottom: "4px" })}
                    >
                        <Label title={"Polynomial sensor values"} />
                    </TypedGrid>
                    <PolynomialSensorValueRow
                        firstValue={"a0"}
                        secondValue={polynomialData?.a0Coefficient}
                        isFirstRow
                    />
                    <PolynomialSensorValueRow
                        firstValue={"a1"}
                        secondValue={polynomialData?.a1Coefficient}
                        hasNoBorderRadius
                    />
                    <PolynomialSensorValueRow
                        firstValue={"a2"}
                        secondValue={polynomialData?.a2Coefficient}
                        hasNoBorderRadius
                    />
                    <PolynomialSensorValueRow
                        firstValue={"a3"}
                        secondValue={polynomialData?.a3Coefficient}
                        isLastRow
                    />
                </TypedGrid>
            </Fragment>
        ),
    };
    return (
        <Fragment>
            <FormModal
                isOpen={isOpen}
                onClose={onClose}
                onSecondaryButtonClick={
                    activeStep === 0
                        ? () => {
                              onClose();
                              setActiveStep(0);
                          }
                        : () => setActiveStep(0)
                }
                title={"LLS calibration"}
                description={`The table is filled row by row, representing one fuel tank fill. To calculate, select a sensor from „Available sensors“, fill the „Fuel (liters)“ column, and capture the current value of a sensor by clicking the "Refresh" icon.`}
                primaryButtonText={
                    activeStep === 0 ? "Calculate" : "Export .csv"
                }
                secondaryButtonText={activeStep === 0 ? t.Cancel : "Go back"}
                onSubmit={
                    activeStep === 0
                        ? (e) => {
                              e.preventDefault();
                              setActiveStep(1);
                          }
                        : () => {
                              handleExportLls();
                          }
                }
                isPrimaryButtonDisabled={!fuelValues.length}
                customModalStyles={{ maxWidth: "566px" }}
            >
                {modalSteps[activeStep]}
            </FormModal>
        </Fragment>
    );
};

export default LlsCalibrationModal;
