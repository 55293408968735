/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import { useContext, useEffect } from "react";
import LanguageContext from "../../../../../context/language/languageContext";
import ThemeContext from "../../../../../context/theme/themeContext";
import { LanguageInfo } from "../../../../../generatedTypes";
import useApi from "../../../../../utils/useApi";
import useImagePath from "../../../../../utils/useImagePath";
import LayoutContext from "../../../../../context/layout/layoutContext";

export interface SingleLanguageProps {
    data: LanguageInfo;
}

const SingleLanguage = ({
    data: { shortName: id, description },
}: SingleLanguageProps) => {
    const { selectedLanguage, changeLanguage } = useContext(LanguageContext);
    const {
        colors: { gray100, blue200 },
    } = useContext(ThemeContext);
    const { layoutData } = useContext(LayoutContext);

    const { cancelSource, isCanceled } = useApi();
    const { flagPath, getFlagSrc } = useImagePath();

    useEffect(() => {
        let isSubscribed = true;

        getFlagSrc(id, isSubscribed, isCanceled);

        return () => {
            isSubscribed = false;
            cancelSource.cancel("Api is being cancelled");
        };

        // eslint-disable-next-line
    }, []);

    const handleClick = () => {
        // To avoid unnecessary loading of the same language
        id !== selectedLanguage && changeLanguage(id, layoutData?.id || 0);
    };

    return (
        <div
            css={css({
                display: "flex",
                padding: "14px 34px 14px 26px",
                alignItems: "center",
                backgroundColor: selectedLanguage === id ? blue200 : "unset",
                ":hover": {
                    backgroundColor:
                        selectedLanguage === id ? blue200 : gray100,
                    cursor: "pointer",
                },
            })}
            key={id}
            onClick={handleClick}
        >
            {flagPath && <img src={flagPath} alt={description} />}
            <div
                css={css({
                    display: "flex",
                    alignItems: "center",
                    marginLeft: "9px",
                    fontWeight: 600,
                })}
            >
                {description}
            </div>
        </div>
    );
};

export default SingleLanguage;
