import React, { useReducer } from "react";
import AvlSearchContext from "./avlSearchContext";
import AvlSearchReducer from "./avlSearchReducer";
import {
    SET_POPOVER_ANCHOR,
    SET_RESULTS,
    SELECT_SEARCH_RESULT,
    SET_ACTIVE_PARAMETER_ID,
    SET_PARAMETER_NAMES,
    SET_INITIAL_PARAMETER_NAMES,
} from "./avlSearchActions";

interface AvlSearchStateProps {
    children: React.ReactNode;
}

const AvlSearchState = ({ children }: AvlSearchStateProps) => {
    const initialState = {
        openModalAnchor: null,
        results: null,
        result: null,
        activeParameterId: "",
        parameterNames: [],
    };

    const [state, dispatch] = useReducer(AvlSearchReducer, initialState);

    const setModalAnchor = (data: any) =>
        dispatch({ type: SET_POPOVER_ANCHOR, payload: data });

    const setResults = (data: any) => {
        dispatch({ type: SET_RESULTS, payload: data });
    };

    const selectSearchResult = (data: any) => {
        dispatch({ type: SELECT_SEARCH_RESULT, payload: data });
    };

    const setActiveParameterId = (data: any) => {
        dispatch({ type: SET_ACTIVE_PARAMETER_ID, payload: data });
    };

    const setParameterNames = (data: {
        parameterId: string;
        parameterName: string;
    }) => {
        dispatch({ type: SET_PARAMETER_NAMES, payload: data });
    };

    const setInitialParameterNames = (data: any[]) => {
        dispatch({ type: SET_INITIAL_PARAMETER_NAMES, payload: data });
    };
    return (
        <AvlSearchContext.Provider
            value={{
                openModalAnchor: state.openModalAnchor,
                results: state.results,
                result: state.result,
                activeParameterId: state.activeParameterId,
                parameterNames: state.parameterNames,
                setModalAnchor,
                setResults,
                selectSearchResult,
                setActiveParameterId,
                setParameterNames,
                setInitialParameterNames,
            }}
        >
            {children}
        </AvlSearchContext.Provider>
    );
};

export default AvlSearchState;
