/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import { CircularProgress } from "@mui/material";
import { Fragment, useContext } from "react";
import ThemeContext from "../../../context/theme/themeContext";
import Button from "../Button";
import Label from "../Label";
import ListCard, { ListCardProps } from "./ListCard";
import { MenuItem } from "./MoreActionsMenu";

export interface DropzoneProps {
    supportedFileFormats: string;
    label?: string;
    menuItems: MenuItem[];
    OnInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    multipleFiles?: boolean;
    items: ListCardProps[];
    loading?: boolean;
    isDropzoneDisabled?: boolean;
    customButton?: React.JSX.Element;
    isDropzoneHidden?: boolean;
}

const Dropzone = ({
    supportedFileFormats,
    label,
    menuItems,
    OnInputChange,
    multipleFiles = true,
    items = [],
    loading,
    isDropzoneDisabled,
    customButton,
    isDropzoneHidden,
}: DropzoneProps) => {
    const {
        colors: { gray50, gray200, blue100, blue700 },
    } = useContext(ThemeContext);

    return (
        <Fragment>
            {label && <Label title={label} />}
            {!isDropzoneHidden && (
                <div css={css({ display: "flex", justifyContent: "center" })}>
                    <label
                        css={css({
                            width: "100%",
                            height: "128px",
                            backgroundColor: gray50,
                            border: `2px dashed ${gray200}`,
                            display: "flex",
                            borderRadius: "8px",
                            justifyContent: "center",
                            flexDirection: "column",
                            alignItems: "center",
                            position: "relative",
                            cursor: isDropzoneDisabled
                                ? "not-allowed"
                                : "pointer",
                            pointerEvents: isDropzoneDisabled ? "none" : "auto",
                            "&:hover": {
                                backgroundColor: isDropzoneDisabled
                                    ? gray50
                                    : blue100,
                                border: isDropzoneDisabled
                                    ? `2px dashed ${gray200}`
                                    : `2px dashed ${blue700}`,
                            },
                        })}
                    >
                        <Button
                            size="small"
                            color="primarySubtle"
                            disabled={isDropzoneDisabled}
                            idForTesting="dropzone-button"
                        >
                            {loading ? <CircularProgress /> : "Select file"}
                        </Button>
                        <span css={css({ fontWeight: 600, marginTop: "4px" })}>
                            Or drop file to upload
                        </span>
                        <span css={css({ fontWeight: 400 })}>
                            Supported formats {supportedFileFormats}
                        </span>
                        <input
                            css={css({
                                position: "absolute",
                                opacity: 0,
                                width: "100%",
                                height: "100%",
                                cursor: "pointer",
                            })}
                            type="file"
                            accept={supportedFileFormats}
                            multiple={multipleFiles}
                            onChange={OnInputChange}
                            disabled={isDropzoneDisabled}
                        />
                    </label>
                </div>
            )}
            {items.map((item, index) => {
                if (item.isEmpty) {
                    return null;
                }
                return (
                    <ListCard
                        key={index}
                        fileName={item.fileName}
                        filePath={item.filePath}
                        timeStamp={item.timeStamp}
                        fileSize={item.fileSize}
                        menuItems={menuItems}
                        customButton={customButton}
                        customCardContent={item.customCardContent}
                    />
                );
            })}
        </Fragment>
    );
};

export default Dropzone;
