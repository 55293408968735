/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import Breadcrumbs from "../../MuiComponents/Breadcrumbs";
import { MouseEvent, useContext, useState } from "react";
import Container from "../../Container";
import GeneralSkeleton from "../../Loader/EditorSkeleton/GeneralSkeleton";
import TextLink from "../../MuiComponents/TextLink";
import FrameItemsMenu from "./FrameItemsMenu";
import MenuItemContext from "../../../context/menuItem/menuItemContext";
import { generatePath, useHistory, useParams } from "react-router-dom";
import { EDITOR_MENU_ITEM } from "../../../constants/routes";
import { FrameItemViewParams } from ".";
import LanguageContext from "../../../context/language/languageContext";

const FrameItemViewSkeleton = () => {
    const { frames } = useContext(MenuItemContext);
    const { t } = useContext(LanguageContext);

    const { menuItem, frameItemIndex } = useParams<FrameItemViewParams>();
    const history = useHistory();
    const [menuAnchorEl, setMenuAnchorEl] = useState<HTMLDivElement | null>(
        null
    );
    const goToHub = () => {
        const generatedPath = generatePath(EDITOR_MENU_ITEM, {
            menuItem,
        });

        history.push(generatedPath);
    };

    const openItemsMenu = (e: MouseEvent<HTMLDivElement>) =>
        setMenuAnchorEl(e.currentTarget);

    const closeItemsMenu = () => setMenuAnchorEl(null);
    const activeFrame = frames && frames[frameItemIndex as any];
    return (
        <Container
            css={css({
                paddingTop: "24px",
                paddingBottom: "24px",
                maxWidth: activeFrame?.ioContainerProperties
                    ? "unset"
                    : "1156px",
                width: activeFrame?.ioContainerProperties ? "90%" : "100%",
            })}
        >
            {activeFrame && (
                <div>
                    <TextLink
                        size="normal"
                        idForTesting="back-to-hub"
                        onClick={goToHub}
                        startIcon
                    >
                        {t.BackTo} {activeFrame.menuItemName}
                    </TextLink>

                    <div
                        css={css({
                            margin: "8px 0 16px 18px",
                        })}
                    >
                        <Breadcrumbs>{activeFrame.menuItemName}</Breadcrumbs>

                        <Breadcrumbs
                            isSeparator
                            isActive
                            isIconRight
                            onClick={openItemsMenu}
                        >
                            {activeFrame.name}
                        </Breadcrumbs>
                    </div>

                    <FrameItemsMenu
                        anchorEl={menuAnchorEl}
                        closeMenu={closeItemsMenu}
                        activeFrame={activeFrame}
                    />
                </div>
            )}
            <GeneralSkeleton
                hasDivider={true}
                hasNoLoadingText={true}
                hasOneColumn={true}
                unsetMaxWidth={activeFrame?.ioContainerProperties}
            />
        </Container>
    );
};

export default FrameItemViewSkeleton;
