/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import BannerView from "../../../../BannerView";
import Dropzone from "../../../../Dropzone";
import BundleUpdateContext from "../../context/bundleUpdateContext";
import { useContext } from "react";
import useApi from "../../../../../../utils/useApi";
import LanguageContext from "../../../../../../context/language/languageContext";
import { sortFirmwareAndDtb } from "../../../../../../utils/helpers";
import { FirmwareBundleUpdateStep } from "../../../../../../generatedTypes";
import CustomCardContent from "./CustomCardContent";
import Button from "../../../../Button";
import DeviceStatusContext from "../../../../../../context/deviceStatus/deviceStatusContext";

export interface IntroStepProps {}

const IntroStep: React.FunctionComponent<IntroStepProps> = () => {
    const { setLoading, setError, loading, setFile, file, error } =
        useContext(BundleUpdateContext);
    const { selectedLanguage } = useContext(LanguageContext);
    const { setBundleUpdateProgressStates, bundleModalProgressStates } =
        useContext(DeviceStatusContext);

    const { postData } = useApi();
    const splitToDtbAndFirmware = sortFirmwareAndDtb(bundleModalProgressStates);
    const firmwareSteps = splitToDtbAndFirmware?.firmware || [];

    const dtbSteps = splitToDtbAndFirmware?.dtb || [];

    const getSteps = (
        filePath: string,
        firmwareSteps: FirmwareBundleUpdateStep[],
        dtbSteps: FirmwareBundleUpdateStep[]
    ) => {
        const fileName = filePath.split("\\").pop();
        return [
            {
                fileName: fileName,
                filePath: filePath,
                isEmpty: false,
                customCardContent: (
                    <CustomCardContent
                        firmwareSteps={firmwareSteps}
                        dtbSteps={dtbSteps}
                    />
                ),
            },
        ];
    };

    const results: any = !!bundleModalProgressStates.length
        ? getSteps(file?.filename || "", firmwareSteps, dtbSteps)
        : [];

    const handleFileUpload = async (files: FileList) => {
        if (!files || files.length === 0) {
            return;
        }

        const formData = new FormData();

        formData.append(files[0].name, files[0]);

        try {
            const { data } = await postData(
                `${selectedLanguage}/updateFile/firmwarebundle`,
                formData
            );

            setBundleUpdateProgressStates(null, data.updateSteps);
            setFile({ filename: files[0].name, file: formData });
        } catch (error: any) {
            const err = error.response?.data;
            setError(err.detail);
        }
    };

    const handleInputChange = async (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        const files = event.target.files;
        if (!files?.length) return;
        try {
            setLoading(true);
            handleFileUpload(files);
            setLoading(false);
        } catch (error: any) {
            const err = error.response?.data;
            setError(err.detail);
            setLoading(false);
        }
    };

    return (
        <div
            css={css({
                display: "flex",
                gap: "16px",
                flexDirection: "column",
            })}
        >
            {error && (
                <BannerView
                    data={{
                        bannerType: "Error",
                        description: error,
                    }}
                />
            )}
            <Dropzone
                supportedFileFormats={".tpack"}
                OnInputChange={handleInputChange}
                menuItems={[]}
                items={results}
                customButton={
                    <Button
                        variant="textOnly"
                        size="small"
                        color="primarySubtle"
                        idForTesting="clearButton"
                    >
                        Change
                        <input
                            css={css({
                                position: "absolute",
                                opacity: 0,
                                width: "100%",
                                height: "100%",
                                cursor: "pointer",
                            })}
                            type="file"
                            onChange={handleInputChange}
                            accept=".tpack"
                        />
                    </Button>
                }
                isDropzoneHidden={results.length > 0}
                loading={loading}
            />
        </div>
    );
};

export default IntroStep;
