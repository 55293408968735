/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from "@emotion/react";

import { useContext, useState, useEffect, Fragment } from "react";
import ConfigurationContext from "../../../context/configuration/configurationContext";
import useApi from "../../../utils/useApi";
import useMediaQueries from "../../../utils/useMediaQueries";
import TableSkeleton from "../../Loader/StartUpSkeleton/TableSkeleton";
import Box from "../../Box";
import Toolbar from "./Toolbar";
import MobileCards from "./MobileCards";
import DesktopTable from "./DesktopTable";
import useTct from "../../../utils/useTct";

const Offline = () => {
    const {
        offlineConfigurations,
        searchValue,
        setOfflineConfigurations,
        toggleLegacyConfigurations,
        loading,
        isExperimentalModalOpen,
        experimentalConfigurations,
    } = useContext(ConfigurationContext);

    const { isCanceled } = useApi();
    const { getStartupDevicesAsync } = useTct();
    const { toMd } = useMediaQueries();

    const [showLegacy, setShowLegacy] = useState(false);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const getConfigurations = async () => {
        try {
            const data = await getStartupDevicesAsync();
            setOfflineConfigurations(data);
        } catch (error) {
            if (!isCanceled(error)) {
                setOfflineConfigurations({ hardwareVersionTypes: [] });
            }
        }
    };

    useEffect(() => {
        !isExperimentalModalOpen &&
            experimentalConfigurations &&
            getConfigurations();
        // eslint-disable-next-line
    }, [isExperimentalModalOpen, experimentalConfigurations]);

    const handleLegacyDevices = () => {
        toggleLegacyConfigurations(!showLegacy);
        setShowLegacy(!showLegacy);
    };

    const handleChangePage = (e: any, newPage: number) => setPage(newPage);

    const handleChangeRowsPerPage = (e: any) => {
        setRowsPerPage(parseInt(e.target.value, 10));
        setPage(0);
    };

    const noSearchResults =
        searchValue.length > 0 && offlineConfigurations.length === 0;

    return loading || isExperimentalModalOpen ? (
        <TableSkeleton />
    ) : (
        <Box
            className={noSearchResults ? "remaining-height" : ""}
            css={css`
                margin-top: 24px;

                @media (max-width: 839px) {
                    background: unset;
                    box-shadow: none;
                }
            `}
        >
            <Fragment>
                <Toolbar changePage={handleChangePage} />

                {toMd ? (
                    <MobileCards
                        page={page}
                        cardsPerPage={rowsPerPage}
                        noSearchResults={noSearchResults}
                        toggleLegacyDevices={handleLegacyDevices}
                        changePage={handleChangePage}
                        changeCardsPerPage={handleChangeRowsPerPage}
                    />
                ) : (
                    <DesktopTable
                        page={page}
                        rowsPerPage={rowsPerPage}
                        noSearchResults={noSearchResults}
                        toggleLegacyDevices={handleLegacyDevices}
                        changePage={handleChangePage}
                        changeRowsPerPage={handleChangeRowsPerPage}
                    />
                )}
            </Fragment>
        </Box>
    );
};

export default Offline;
