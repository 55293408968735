/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from "@emotion/react";

import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "../Button";
import CloseIcon from "@mui/icons-material/CloseRounded";
import Divider from "../Divider";
import { CircularProgress } from "@mui/material";
import Tooltip from "../Tooltip";
import useMediaQueries from "../../../utils/useMediaQueries";
import ThemeContext from "../../../context/theme/themeContext";
import { useContext } from "react";

interface FormModalProps {
    isOpen: boolean;
    onClose?: () => void;
    onSubmit: (e: React.FormEvent<HTMLFormElement>) => any;
    title: string;
    description?: string | null;
    secondaryButtonText?: string | null;
    primaryButtonText?: string | null;
    children: React.ReactNode;
    isSubmitting?: boolean;
    customModalStyles?: any;
    isPrimaryButtonDisabled?: boolean;
    disabledTooltip?: { btnTitle: string; goTo: number; title: string };
    onSecondaryButtonClick?: (e: any) => void;
    hasNoDivider?: boolean;
    isSecondaryButtonDisabled?: boolean;
    isCloseDisabled?: boolean;
}

const FormModal = ({
    isOpen,
    onClose,
    onSubmit,
    title,
    description,
    secondaryButtonText,
    primaryButtonText,
    isPrimaryButtonDisabled,
    disabledTooltip,
    isSubmitting,
    customModalStyles = {},
    children,
    onSecondaryButtonClick,
    hasNoDivider,
    isSecondaryButtonDisabled,
    isCloseDisabled,
    ...props
}: FormModalProps) => {
    const { toMd, toSm } = useMediaQueries();
    const {
        colors: { white },
    } = useContext(ThemeContext);
    return (
        <Dialog
            onClose={onClose}
            open={isOpen}
            sx={{
                "& .MuiDialog-paper": {
                    maxWidth: !toMd ? "448px" : "unset",
                    height: toSm ? "100vh" : "unset",
                    ...customModalStyles,
                },
            }}
            {...props}
        >
            <DialogTitle
                sx={{
                    fontSize: "20px",
                    lineHeight: "24px",
                    letterSpacing: "0.15px",
                    marginRight: "24px",
                    paddingBottom: description ? "8px" : "12px",
                }}
            >
                {title}

                {onClose && (
                    <Button
                        variant="iconOnly"
                        size="small"
                        color="white"
                        icon={<CloseIcon onClick={onClose} />}
                        css={css`
                            position: absolute;
                            top: 4px;
                            right: 4px;
                        `}
                        idForTesting="close-modal"
                        disabled={isCloseDisabled}
                    />
                )}
            </DialogTitle>
            <form noValidate onSubmit={onSubmit}>
                {description && (
                    <DialogContent
                        sx={{
                            lineHeight: "20px",
                            letterSpacing: "0.1px",
                            padding: "0 16px 12px 16px",
                            fontFamily: "Open Sans",
                            fontSize: "14px",
                            fontStyle: "normal",
                            fontWeight: 400,
                        }}
                    >
                        {description}
                    </DialogContent>
                )}
                {!hasNoDivider && <Divider />}
                {children && (
                    <div css={css({ padding: "18px 16px" })}>{children}</div>
                )}
                {(secondaryButtonText || primaryButtonText) && (
                    <DialogActions
                        sx={{
                            "span:last-of-type": {
                                marginLeft:
                                    secondaryButtonText && primaryButtonText
                                        ? "12px"
                                        : "0px",
                            },
                        }}
                    >
                        {secondaryButtonText && (
                            <span css={css({ width: "100%" })}>
                                <Button
                                    variant="textOnly"
                                    size="normal"
                                    color="secondary"
                                    fullWidth
                                    onClick={onSecondaryButtonClick || onClose}
                                    idForTesting="secondary-button"
                                    disabled={isSecondaryButtonDisabled}
                                >
                                    {secondaryButtonText}
                                </Button>
                            </span>
                        )}
                        {primaryButtonText && (
                            <Tooltip
                                title={
                                    isPrimaryButtonDisabled && disabledTooltip
                                        ? disabledTooltip.title
                                        : ""
                                }
                                placement="top"
                            >
                                <span css={css({ width: "100%" })}>
                                    <Button
                                        focus
                                        variant="textOnly"
                                        size="normal"
                                        color="primary"
                                        fullWidth
                                        onClick={onSubmit}
                                        disabled={isPrimaryButtonDisabled}
                                        idForTesting="primary-button"
                                    >
                                        {isSubmitting ? (
                                            <CircularProgress
                                                size={26}
                                                css={css`
                                                    color: ${white};
                                                `}
                                            />
                                        ) : (
                                            primaryButtonText
                                        )}
                                    </Button>
                                </span>
                            </Tooltip>
                        )}
                    </DialogActions>
                )}
            </form>
        </Dialog>
    );
};

export default FormModal;
