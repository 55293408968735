import React from "react";
import SearchState from "../../../context/search/SearchState";
import MobileTopBar from "./MobileTopBar";
import DesktopTopBar from "./DesktopTopBar";
import { TopBarProps } from "../../../utils/types";

interface DeviceStatusTopBarProps extends TopBarProps {
    isMobile?: boolean;
    isOpenMobileMenu?: boolean;
    toggleMobileMenu?: () => void;
}

const DeviceStatusTopBar = ({
                                isMobile,
                                isOpenMobileMenu,
                                toggleMobileMenu,
                                disabled,
                            }: DeviceStatusTopBarProps) => {
    return (
        <SearchState>
            {isMobile ? (
                <MobileTopBar
                    isOpenMenu={isOpenMobileMenu!}
                    toggleMenu={toggleMobileMenu!}
                    disabled={disabled}
                />
            ) : (
                <DesktopTopBar disabled={disabled}/>
            )}
        </SearchState>
    );
};

export default DeviceStatusTopBar;
