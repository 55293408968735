import React, { useContext } from "react";
import ThemeContext from "../../context/theme/themeContext";
import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

const AlertSuccessIcon = (props: SvgIconProps) => {
    const {
        colors: { green600 },
    } = useContext(ThemeContext);

    return (
        <SvgIcon {...props}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M2 12C2 6.48 6.48 2 12 2C17.52 2 22 6.48 22 12C22 17.52 17.52 22 12 22C6.48 22 2 17.52 2 12ZM9.6725 15.7125L7.2925 13.3125C6.9025 12.9225 6.9025 12.2925 7.2925 11.9025L7.3625 11.8325C7.7525 11.4425 8.3925 11.4425 8.7825 11.8325L10.3925 13.4525L15.5425 8.2925C15.9325 7.9025 16.5725 7.9025 16.9625 8.2925L17.0325 8.3625C17.4225 8.7525 17.4225 9.3825 17.0325 9.7725L11.1125 15.7125C10.7025 16.1025 10.0725 16.1025 9.6725 15.7125Z"
                fill={green600}
            />
        </SvgIcon>
    );
};

export default AlertSuccessIcon;
