/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from "@emotion/react";

import InfoIcon from "../../assets/icons/InfoIcon";
import Tooltip from "./Tooltip";
import { Component } from "../../generatedTypes";

interface TooltipCompositeProps {
    components: Component[];
    onClick: () => void;
}
const TooltipComposite = ({ components, onClick }: TooltipCompositeProps) => (
    <Tooltip
        title={
            <div
                css={css({
                    display: "flex",
                    flexDirection: "column",
                    gap: 4,
                })}
            >
                {components.map(
                    (component) =>
                        (component.label || component.tooltip) && (
                            <p key={component.parameterId}>
                                <span css={css({ fontWeight: "bold" })}>
                                    {component.label}
                                </span>{" "}
                                - {component.tooltip}
                            </p>
                        )
                )}
            </div>
        }
    >
        <span
            css={css({
                display: "flex",
                alignItems: "center",
                marginLeft: 4,
            })}
        >
            <InfoIcon color="primary" onClick={onClick} />
        </span>
    </Tooltip>
);

export default TooltipComposite;
