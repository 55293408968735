/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx, SerializedStyles } from "@emotion/react";

export interface ContainerProps {
    children: React.ReactNode;
    css: SerializedStyles;
    className?: string;
    id?: string;
}
const Container = ({ children, ...props }: ContainerProps) => {
    return (
        <div
            {...props}
            css={css`
                padding-left: 24px;
                padding-right: 24px;

                @media (max-width: 599px) {
                    padding-left: 16px;
                    padding-right: 16px;
                }
            `}
        >
            {children}
        </div>
    );
};

export default Container;
