import React, { useContext } from "react";
import ThemeContext from "../../context/theme/themeContext";
import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export interface DisableableSvgIconProps extends SvgIconProps {
    disabled?: boolean;
}

const CheckWhiteIcon = (props: DisableableSvgIconProps) => {
    const {
        colors: { textWhite, gray100 },
    } = useContext(ThemeContext);

    return (
        <SvgIcon viewBox="0 0 16 16" {...props}>
            <path
                d="M5.99985 10.78L3.68651 8.46667C3.42651 8.20667 3.00651 8.20667 2.74651 8.46667C2.48651 8.72667 2.48651 9.14667 2.74651 9.40667L5.53318 12.1933C5.79318 12.4533 6.21318 12.4533 6.47318 12.1933L13.5265 5.14C13.7865 4.88 13.7865 4.46 13.5265 4.2C13.2665 3.94 12.8465 3.94 12.5865 4.2L5.99985 10.78Z"
                fill={props.disabled ? gray100 : textWhite}
            />
        </SvgIcon>
    );
};

export default CheckWhiteIcon;
