import { replacePlaceholders } from "./helpers";

export const validateNumberLength = (
    val: string,
    min: number | undefined,
    max: number | undefined,
    t: { [key: string]: string }
) => {
    if (val === "") {
        return "Entered value is not a number";
    }
    if ((!min && min !== 0) || (!max && max !== 0)) {
        return null;
    }
    if (Number(val) >= min && Number(val) <= max) {
        return null;
    }
    return replacePlaceholders(t.InputSupport, min, max);
};

export const validateTextLength = (
    val: string,
    textMinLength: number | undefined,
    textMaxLength: number | undefined,
    t: { [key: string]: string }
) => {
    if (
        (!textMinLength && textMinLength !== 0) ||
        (!textMaxLength && textMaxLength !== 0)
    ) {
        return null;
    }
    if (val.length >= textMinLength && val.length <= textMaxLength) {
        return null;
    }
    return replacePlaceholders(t.InputSupportsLength, textMinLength, textMaxLength);
};

export const validateRegex = (
    value: string,
    regex: string,
    validationMessage: string
) => {
    if(regex === null){
        return null;
    }
    const regexp = new RegExp(regex);
    if (value && !regexp.test(value)) {
        return validationMessage || "Invalid input";
    }
    return null;
};

export const validateTextLengthAndRegex = (
    value: string,
    textMinLength: number,
    textMaxLength: number,
    regex: string,
    validationMessage: string,
    t: { [key: string]: string }
) =>
    validateTextLength(value, textMinLength, textMaxLength, t) ||
    validateRegex(value, regex, validationMessage);

export const validateNumberLengthAndRegex = (
    value: string,
    min: number,
    max: number,
    regex: string,
    validationMessage: string,
    t: { [key: string]: string }
) =>
    validateNumberLength(value, min, max, t) ||
    validateRegex(value, regex, validationMessage);
