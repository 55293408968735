import { useContext } from "react";
import { defaultListLanguageFlag } from "../../../../../../constants/constants";
import ListContext from "../../../../../../context/list/listContext";
import { ListItemInterface } from "./List";

interface ListConfigValues {
    value: string;
    parameterId: number;
}

const useAuxiliary = () => {
    const { isNumberType } = useContext(ListContext);

    const filterAndTransform = (values: ListConfigValues[]) => {
        return values.reduce<ListItemInterface[]>((previous, current, i) => {
            const currentItem = current.value;

            const isFilledValue = isNumberType
                ? currentItem.trim() !== "0"
                : currentItem.trim().length > 0;

            return [
                ...previous,
                ...(isFilledValue
                    ? [
                          {
                              index: i + 1,
                              value: currentItem,
                              parameterId: current.parameterId,
                          },
                      ]
                    : []),
            ];
        }, []);
    };

    const findFirstUnusedParameterId = (
        availableParameterIds: number[],
        usedParameterIds: number[]
    ): number => {
        const usedSet = new Set(usedParameterIds);
        const unusedParameterId = availableParameterIds.find(
            (availableParameterId) => !usedSet.has(availableParameterId)
        );
        return unusedParameterId || Math.min(...availableParameterIds);
    };

    const insertMissingItems = (
        existingValues: ListItemInterface[],
        availableParameterIds: number[],
        minNumber: number = 3
    ) => {
        const lastIndex =
            existingValues.length > 0
                ? existingValues[existingValues.length - 1].index
                : 0;

        const loopNumber = lastIndex < minNumber ? minNumber : lastIndex;

        const inputsFromZero = [];

        for (let i = 0; i < loopNumber; i++) {
            const currentIndex = i + 1;

            const existingInput = existingValues.find(
                (value) => value.index === currentIndex
            );

            const pushableInput: any = existingInput
                ? existingInput
                : {
                      index: currentIndex,
                      value: "",
                      language: defaultListLanguageFlag,
                      parameterId: findFirstUnusedParameterId(
                          availableParameterIds,
                          [
                              ...inputsFromZero.map(
                                  (input) => input.parameterId
                              ),
                              ...existingValues.map(
                                  (value) => value.parameterId
                              ),
                          ]
                      ),
                  };

            inputsFromZero.push(pushableInput);
        }
        return inputsFromZero;
    };

    const filterItems = (items: ListItemInterface[]) =>
        items.filter(({ value }) => value.trim().length > 0);

    return {
        filterAndTransform,
        insertMissingItems,
        filterItems,
        findFirstUnusedParameterId,
    };
};

export default useAuxiliary;
