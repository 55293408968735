import React, { Fragment, useContext, useEffect } from "react";
import FeaturesContext from "../../../../../../context/features/featuresContext";
import { Component } from "../../../../../../generatedTypes";
import {
    convertIntToTimeString,
    convertTimeFromStringToInt,
    getIdTooltipText,
} from "../../../../../../utils/helpers";
import InputField from "../../../../../MuiComponents/InputField";
import Label from "../../../../../MuiComponents/Label";
import Tooltip from "../../../../../MuiComponents/Tooltip";
import Tag from "../../../../../MuiComponents/Tag";

export interface TimeInputProps {
    data: Component;
    isWithoutLabel?: boolean;
    disabled?: boolean;
    defaultValue?: number;
}

const TimeInput = ({
    data,
    isWithoutLabel,
    disabled,
    defaultValue,
}: TimeInputProps) => {
    const { isDisabled, parameterId, parameterValue, avlId, label, tooltip } =
        data;

    const { setParameterValue, removeParameterValueById } =
        useContext(FeaturesContext);

    const formatDefaultValue = convertIntToTimeString(
        Number(defaultValue) || Number(parameterValue)
    );
    const defaultTime = formatDefaultValue || "00:00";
    const isInputDisabled = isDisabled || disabled;

    const [value, setValue] = React.useState(defaultTime);

    useEffect(() => {
        if (isInputDisabled) {
            removeParameterValueById(parameterId);
        }
        // eslint-disable-next-line
    }, [isInputDisabled]);

    const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValue(event.target.value);
    };

    const getSecondsFromHHMMSS = (timeValue: string) => {
        const [str1, str2, str3] = timeValue.split(":");

        const val1 = Number(str1);
        const val2 = Number(str2);
        const val3 = Number(str3);

        if (!isNaN(val1) && isNaN(val2) && isNaN(val3)) {
            // seconds
            return val1;
        }

        if (!isNaN(val1) && !isNaN(val2) && isNaN(val3)) {
            // minutes * 60 + seconds
            return val1 * 60 + val2;
        }

        if (!isNaN(val1) && !isNaN(val2) && !isNaN(val3)) {
            // hours * 60 * 60 + minutes * 60 + seconds
            return val1 * 60 * 60 + val2 * 60 + val3;
        }

        return 0;
    };

    const toHHMMSS = (secs: number) => {
        const secNum = parseInt(secs.toString(), 10);
        const hours = Math.floor(secNum / 3600);
        const minutes = Math.floor(secNum / 60) % 60;
        const seconds = secNum % 60;
        return [hours, minutes, seconds]
            .map((val) => (val < 10 ? `0${val}` : val))
            .filter((val, index) => val !== "00" || index > 0)
            .join(":");
    };

    const onBlur = (event: React.FocusEvent<HTMLInputElement>) => {
        const eventValue = event.target.value;
        const maxTimeAsString = "24:00";
        const maxTimeAsNumber = convertTimeFromStringToInt(maxTimeAsString);
        const seconds =
            getSecondsFromHHMMSS(eventValue) > maxTimeAsNumber
                ? Math.max(0, getSecondsFromHHMMSS(maxTimeAsString))
                : Math.max(0, getSecondsFromHHMMSS(eventValue));

        const time = toHHMMSS(seconds);

        setValue(time);
        setParameterValue({
            parameterId,
            value: convertTimeFromStringToInt(time),
        });
    };

    return (
        <Fragment>
            {!isWithoutLabel && <Label title={label || ""} />}
            <InputField
                id={String(parameterId)}
                fullWidth
                type="text"
                size="medium"
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                disabled={isInputDisabled}
                info={tooltip}
                iconRight={
                    isWithoutLabel ? null : (
                        <Tooltip
                            title={getIdTooltipText(parameterId, avlId)}
                            small
                            placement="top"
                        >
                            <span>
                                <Tag size="tiny" color="white" title="ID" />
                            </span>
                        </Tooltip>
                    )
                }
            />
        </Fragment>
    );
};

export default TimeInput;
