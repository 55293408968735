/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from "@emotion/react";

import { useContext } from "react";
import LanguageContext from "../../../../context/language/languageContext";
import DeviceStatusContext from "../../../../context/deviceStatus/deviceStatusContext";
import useApi from "../../../../utils/useApi";
import InputField from "../../InputField";
import TextLink from "../../TextLink";
import VersionBlock from "./VersionBlock";
import ProgressBar from "../../ProgressBar";
import FormModal from "../FormModal";
import useDesktopHost from "../../../../utils/useDesktopHost";
import Stepper from "../../Stepper/Stepper";
import { extractEXIMFile } from "../../../../utils/helpers";
import { AlertStatus, FirmwareType } from "../../../../constants/constants";
import AlertContext from "../../../../context/alert/alertContext";
import LayoutContext from "../../../../context/layout/layoutContext";

export interface DtbUpdateModalProps {
    onUpdateStart: () => void;
    title: string;
    description: string;
    loaderName: string;
    extensions: string[];
    isOpen: boolean;
}
const UpdateFirmwareModal = ({
    onUpdateStart,
    title,
    description,
    loaderName,
    extensions,
    isOpen,
}: DtbUpdateModalProps) => {
    const { t, selectedLanguage } = useContext(LanguageContext);
    const { setAlert } = useContext(AlertContext);
    const {
        progressBars,
        updateFirmwareModalInfo,
        setUpdateFirmwareModalInfo,
        resetProgressBars,
    } = useContext(DeviceStatusContext);

    const { layoutData } = useContext(LayoutContext);

    const { openFileDialog } = useDesktopHost();
    const { getData } = useApi();

    const handleClose = () => {
        setUpdateFirmwareModalInfo({
            isOpen: false,
            path: "",
            step: 0,
            newVersion: "",
            firmwareType: FirmwareType.None,
            currentVersion: "",
            error: "",
        });
        resetProgressBars();
    };

    const isDtbFirmware =
        updateFirmwareModalInfo.firmwareType === FirmwareType.Dtb;
    const isModemFirmware =
        updateFirmwareModalInfo.firmwareType === FirmwareType.Modem;
    const isDeviceFirmware =
        updateFirmwareModalInfo.firmwareType === FirmwareType.Firmware;
    const canReportModemFwUpdate =
        layoutData?.deviceStatusWindow?.canReportModemFwUpdate;

    const renderFilterName = () => {
        if (isDtbFirmware) {
            return t.DeviceTreeDatabaseFile;
        }
        if (isModemFirmware) {
            return t.ModemFirmwareFile;
        }
        return t.FirmwareFile;
    };

    const renderInputLabel = () => {
        if (isDtbFirmware) {
            return t.DtbAddFile;
        }
        if (isModemFirmware) {
            return t.ModemAddFile;
        }
        return t.FirmwareAddFile;
    };

    const handleOpenFileDialog = () => {
        openFileDialog(
            {
                title: t.SelectFile,
                filters: [
                    {
                        extensions: extensions,
                        name: renderFilterName(),
                    },
                ],
            },

            async (result: any) => {
                if (result.length > 0) {
                    try {
                        let data: any = null;
                        if (!isDeviceFirmware) {
                            const responseData = await getData(
                                `${selectedLanguage}/updatefile/${updateFirmwareModalInfo?.firmwareType}?filePath=${result[0]}`
                            );
                            data = responseData?.data;
                        }

                        if (isDeviceFirmware) {
                            data = extractEXIMFile(result[0]);
                        }

                        setUpdateFirmwareModalInfo({
                            isOpen: true,
                            path: result[0],
                            step: 0,
                            newVersion: data,
                            firmwareType: updateFirmwareModalInfo.firmwareType,
                            currentVersion:
                                updateFirmwareModalInfo.currentVersion,
                            error: "",
                        });
                    } catch (err: any) {
                        const error = err.response?.data;
                        setAlert(AlertStatus.Critical, error.detail);
                        setUpdateFirmwareModalInfo({
                            isOpen: true,
                            path: updateFirmwareModalInfo.path,
                            step: 0,
                            newVersion: updateFirmwareModalInfo.newVersion,
                            firmwareType: updateFirmwareModalInfo.firmwareType,
                            currentVersion:
                                updateFirmwareModalInfo.currentVersion,
                            error: "",
                        });
                    }
                }
            }
        );
    };

    const isFirstStep = updateFirmwareModalInfo?.step === 0;
    const isSecondStep =
        updateFirmwareModalInfo?.step === 1 ||
        updateFirmwareModalInfo?.step === 2;
    const modalHasError = !!updateFirmwareModalInfo?.error;

    const stepErrors = {
        step: updateFirmwareModalInfo?.stepWithError || -1,
        error: updateFirmwareModalInfo?.error,
    };

    const renderProgressStep = (stepNumber: number) => {
        const isFirstLoader = stepNumber === 1;

        const renderLoadingText = () => {
            if (isFirstLoader) {
                return t.LoadingTextLabel;
            }
            return `Update might take up to ${isDtbFirmware ? 1 : 3} minutes.`;
        };
        return (
            <div
                css={css({
                    marginTop: "48px",
                    marginBottom: "12px",
                    display: "flex",
                    flexDirection: "column",
                    gap: "16px",
                })}
            >
                <div
                    css={css({
                        display: "flex",
                        flexDirection: "column",
                        gap: "16px",
                        justifyContent: "center",
                        alignItems: "center",
                    })}
                >
                    <ProgressBar
                        value={progressBars[loaderName] || 0}
                        label={
                            isFirstLoader || canReportModemFwUpdate
                                ? `${progressBars[loaderName] || 0}%`
                                : ""
                        }
                    />
                    <span
                        css={css({
                            fontSize: "14px",
                            fontStyle: "normal",
                            fontWeight: 600,
                            lineHeight: "20px",
                            letterSpacing: "0.1px",
                            textAlign: "center",
                        })}
                    >
                        {renderLoadingText()}
                    </span>
                </div>
            </div>
        );
    };

    const renderSuccessMessage = () => {
        if (isDtbFirmware) {
            return t.DtbUpdateSuccessMessage;
        }
        if (isModemFirmware) {
            return t.ModemUpdateSuccessMessage;
        }
        return t.DeviceFirmwareUpdateSuccessMessage;
    };
    const steps: { [key: number]: any } = {
        [0]: (
            <div>
                <div
                    css={css({
                        marginTop: "48px",
                        marginBottom: "12px",
                        display: "flex",
                        flexDirection: "column",
                        gap: "16px",
                    })}
                >
                    <VersionBlock
                        type={updateFirmwareModalInfo?.firmwareType}
                        version={updateFirmwareModalInfo?.currentVersion}
                    />
                    <VersionBlock
                        type={updateFirmwareModalInfo?.firmwareType}
                        version={updateFirmwareModalInfo?.newVersion}
                        isNew
                    />
                    <InputField
                        fullWidth
                        name="captureLogPath"
                        value={updateFirmwareModalInfo?.path}
                        size="medium"
                        label={renderInputLabel()}
                        iconRight={
                            <TextLink
                                size="normal"
                                onClick={handleOpenFileDialog}
                                idForTesting="browseButton"
                            >
                                {t.Change}
                            </TextLink>
                        }
                        readOnly
                    />
                </div>
            </div>
        ),
        [1]: renderProgressStep(1),
        [2]: renderProgressStep(2),
        [3]: (
            <div
                css={css({
                    marginTop: "48px",
                    marginBottom: "12px",
                    display: "flex",
                    flexDirection: "column",
                    gap: "16px",
                    textAlign: "center",
                    fontSize: "14px",
                    fontStyle: "normal",
                    fontWeight: 600,
                    lineHeight: "20px",
                    letterSpacing: "0.1px",
                })}
            >
                {modalHasError
                    ? updateFirmwareModalInfo?.error
                    : renderSuccessMessage()}
            </div>
        ),
    };

    const stepperInfoSteps = [
        { label: t.FileUpload, indexes: [0, 1] },
        { label: title, indexes: [2] },
    ];

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        try {
            if (!isFirstStep && modalHasError) {
                setUpdateFirmwareModalInfo({
                    isOpen: true,
                    path: updateFirmwareModalInfo?.path,
                    newVersion: updateFirmwareModalInfo?.newVersion,
                    step: 0,
                    firmwareType: updateFirmwareModalInfo?.firmwareType,
                    currentVersion: updateFirmwareModalInfo?.currentVersion,
                    error: "",
                });
                return;
            }
            onUpdateStart();
            setUpdateFirmwareModalInfo({
                isOpen: true,
                path: updateFirmwareModalInfo?.path,
                newVersion: updateFirmwareModalInfo?.newVersion,
                step: 1,
                firmwareType: updateFirmwareModalInfo?.firmwareType,
                currentVersion: updateFirmwareModalInfo?.currentVersion,
                error: "",
            });
        } catch (error: any) {
            console.log("update", error);
        }
    };

    const renderPrimaryButtonText = () => {
        if (!isFirstStep && modalHasError) {
            return "Retry";
        }
        if (!isFirstStep) {
            return "";
        }
        return t.Update;
    };

    return (
        <FormModal
            isOpen={isOpen}
            title={title}
            description={description}
            onSubmit={handleSubmit}
            primaryButtonText={renderPrimaryButtonText()}
            secondaryButtonText={t.Close}
            onClose={isSecondStep ? () => {} : handleClose}
            isPrimaryButtonDisabled={isSecondStep}
            isSecondaryButtonDisabled={isSecondStep}
            customModalStyles={{ maxWidth: "684px" }}
            isCloseDisabled={isSecondStep}
        >
            <Stepper
                steps={stepperInfoSteps}
                activeStep={updateFirmwareModalInfo?.step}
                stepErrors={stepErrors}
            />
            {steps[updateFirmwareModalInfo?.step]}
        </FormModal>
    );
};

export default UpdateFirmwareModal;
