/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/react";
import L, { Layer } from "leaflet";
import { useEffect, useMemo, useRef, useState } from "react";
import { FeatureGroup, useMap } from "react-leaflet";
import { EditControl } from "react-leaflet-draw";
import { Geozone, GeozoneType } from "../../../../../../utils/types";
import useDebounce from "../../../../../../utils/useDebounce";
import useGeozones from "../../../../../../utils/useGeozones";

export interface GeozoneOptions {
    AllowedShapes: GeozoneType[];
    MaxPolygonVerticesCount: number;
}

export interface GeozoneEditState {
    currentIndex: number;
    previousIndex: number;
    currentEditorState: EditorState;
}

export enum EditorState {
    None,
    Start,
    Cancel,
    Commit,
}
interface EditableLayerProps {
    geozone: Geozone;
    editState: GeozoneEditState;
    geozoneOptions: GeozoneOptions;
    highlightedGeozone: number;
    highlightGeozone: (index: number) => void;
    onEditStart: (index: number) => void;
    onGeozoneChanged: (modifiedGeozone: Geozone) => void;
    onShapeChange: (shape: Geozone) => void;
    onMapChange?: () => void;
}

const EditableLayer = ({
    geozone,
    editState,
    geozoneOptions,
    highlightedGeozone,
    highlightGeozone,
    onEditStart,
    onGeozoneChanged,
    onShapeChange,
    onMapChange,
}: EditableLayerProps) => {
    const [workingGeozone, setWorkingGeozone] = useState(geozone);
    const workingGeozoneRef = useRef(workingGeozone);
    const editLayerRef = useRef<any>();
    const drawControlRef = useRef<any>();
    const map = useMap();
    const [editHandler, setEditHandler] = useState<any>();
    const editStateRef = useRef(editState);
    const updateWorkingGeozone = (newGeozone: Geozone) => {
        setWorkingGeozone(newGeozone);
        onEditStart(newGeozone.index);
    };
    const debouncedUpdateWorkingGeozone = useDebounce(
        updateWorkingGeozone,
        1000
    );
    const deboucedGeofenceOnChange = useDebounce(onGeozoneChanged, 1000);
    const debouncedonShapeChange = useDebounce(onShapeChange, 1000);
    const geozoneIndex = geozone.index;
    const {
        commonFeatureOptions,
        highlightedFeatureOptions,
        selectedFeatureOptions,
        convertGeozoneToFeature,
        convertFeatureToGeozoneByGeozoneType,
        convertFeatureToGeozoneByFeatureType,
        updateGeozoneTooltip,
    } = useGeozones(highlightGeozone, onEditStart, editStateRef);

    const layer = useMemo(
        () => convertGeozoneToFeature(workingGeozone),
        // eslint-disable-next-line
        [workingGeozone.type, workingGeozone.radius, workingGeozone.coordinates]
    );

    const startEditing = () => {
        map.addControl(drawControlRef.current);
        editLayerRef.current.setStyle(selectedFeatureOptions);
        if (isShape(layer)) {
            editHandler._enableLayerEdit(layer);
            layer.closeTooltip();
        }
    };
    const cancelEditing = () => {
        map.removeControl(drawControlRef.current);
        editLayerRef.current.setStyle(commonFeatureOptions);
        editHandler?.revertLayers();
        if (isShape(layer) && editHandler) {
            editHandler._disableLayerEdit(layer);
            layer.openTooltip();
        }
        setWorkingGeozone(geozone);
    };
    const commitEditing = () => {
        map.removeControl(drawControlRef.current);
        editLayerRef.current.setStyle(commonFeatureOptions);
        if (isShape(layer) && editHandler) {
            editHandler._disableLayerEdit(layer);
            layer.openTooltip();
        }
    };

    useEffect(() => {
        editStateRef.current = editState;

        switch (editState.currentEditorState) {
            case EditorState.None:
                break;
            case EditorState.Start: {
                editState.currentIndex === geozoneIndex
                    ? startEditing()
                    : cancelEditing();
                break;
            }
            case EditorState.Cancel: {
                editState.previousIndex === geozoneIndex && cancelEditing();
                break;
            }
            case EditorState.Commit: {
                editState.previousIndex === geozoneIndex && commitEditing();
                break;
            }
        }
        // eslint-disable-next-line
    }, [editState]);

    useEffect(() => {
        setWorkingGeozone(geozone);
    }, [geozone, geozone.type, geozone.radius, geozone.coordinates]);

    useEffect(() => {
        workingGeozoneRef.current = workingGeozone;
    }, [workingGeozone]);

    useEffect(() => {
        drawControlRef.current._toolbars.edit.getModeHandlers()[0].handler._map =
            map;
        setEditHandler(
            drawControlRef.current._toolbars.edit.getModeHandlers()[0].handler
        );
    }, [drawControlRef, map]);

    useEffect(() => {
        if (
            highlightedGeozone === geozoneIndex &&
            editState.currentIndex !== geozoneIndex
        ) {
            editLayerRef.current.setStyle(highlightedFeatureOptions);
            updateGeozoneTooltip(layer, geozone.name, true);
        } else if (editState.currentIndex !== geozoneIndex) {
            editLayerRef.current.setStyle(commonFeatureOptions);
            updateGeozoneTooltip(layer, geozone.name);
        }
    }, [
        highlightedGeozone,
        editState,
        geozoneIndex,
        commonFeatureOptions,
        highlightedFeatureOptions,
    ]);

    useEffect(() => {
        editLayerRef.current.clearLayers();
        const hideInitialGeozone = workingGeozone?.isHidden;

        if (isShape(layer) && !hideInitialGeozone) {
            editLayerRef.current.addLayer(layer);
        }
    }, [layer, map, editState, workingGeozone]);

    map.on("zoomend", (_e) => {
        updateGeozoneTooltip(
            layer,
            geozone.name,
            highlightedGeozone === geozoneIndex
        );
    });

    function onMounted(ctl: L.Map) {
        drawControlRef.current = ctl;
        editState.currentIndex !== geozoneIndex &&
            map.removeControl(drawControlRef.current);
    }

    const onCreated = (e: L.DrawEvents.Created) => {
        onMapChange && onMapChange();
        const newFeature = e.layer as L.Path;
        // Leaflet draw event fires in every feature group (layer)
        // If current component's index is different just remove the new feature to disallow duplicates
        if (
            editStateRef.current.currentIndex !==
            workingGeozoneRef.current.index
        ) {
            editLayerRef.current.removeLayer(newFeature);
            return;
        }

        const newGeozone = convertFeatureToGeozoneByFeatureType(
            newFeature,
            e.layerType,
            workingGeozoneRef.current.index,
            workingGeozoneRef.current.name
        );
        setWorkingGeozone(newGeozone);
        // After the feature is drawn it is no longer editable,
        // fire the onEditStart event to reenable editing for the new feature
        onEditStart(newGeozone.index);
        debouncedonShapeChange(newGeozone);
    };

    const onEdit = (e: L.DrawEvents.EditMove | L.DrawEvents.EditResize) => {
        onMapChange && onMapChange();
        if (
            editStateRef.current.currentIndex !==
            workingGeozoneRef.current.index
        )
            return;

        const newFeature = e.layer as L.Path;
        const newGeo = convertFeatureToGeozoneByGeozoneType(
            newFeature,
            workingGeozoneRef.current.type,
            workingGeozoneRef.current.index,
            workingGeozoneRef.current.name
        );
        debouncedUpdateWorkingGeozone(newGeo);
        deboucedGeofenceOnChange(newGeo);
    };

    const onEditPolygon = (e: L.DrawEvents.EditVertex) => {
        onMapChange && onMapChange();
        if (
            editStateRef.current.currentIndex !==
            workingGeozoneRef.current.index
        )
            return;

        const newFeature = e.poly;
        const newGeo = convertFeatureToGeozoneByGeozoneType(
            newFeature,
            workingGeozoneRef.current.type,
            workingGeozoneRef.current.index,
            workingGeozoneRef.current.name
        );
        debouncedUpdateWorkingGeozone(newGeo);
        deboucedGeofenceOnChange(newGeo);
    };

    return (
        <FeatureGroup ref={editLayerRef}>
            <EditControl
                position="topleft"
                onMounted={onMounted}
                onCreated={onCreated}
                onEditMove={onEdit}
                onEditResize={onEdit}
                onEditVertex={onEditPolygon}
                draw={{
                    marker: false,
                    circlemarker: false,
                    polyline: false,
                    circle: geozoneOptions.AllowedShapes.find(
                        (shape) => shape === GeozoneType.Circle
                    )
                        ? { shapeOptions: selectedFeatureOptions }
                        : false,
                    rectangle: geozoneOptions.AllowedShapes.find(
                        (shape) => shape === GeozoneType.Rectangle
                    )
                        ? { shapeOptions: selectedFeatureOptions }
                        : false,
                    polygon: geozoneOptions.AllowedShapes.find(
                        (shape) => shape === GeozoneType.Polygon
                    )
                        ? {
                              maxPoints: geozoneOptions.MaxPolygonVerticesCount,
                              shapeOptions: {
                                  dashArray: [1],
                                  weight: 2,
                                  ...selectedFeatureOptions,
                              },
                          }
                        : false,
                }}
                edit={{ edit: false, remove: false }}
            />
        </FeatureGroup>
    );
};

export default EditableLayer;

function isShape(obj: Layer) {
    return obj instanceof L.Path;
}
