/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import { useState } from "react";
import useInterval from "../../utils/useInterval";
interface TimerProps {
    initialCountSeconds: number;
    timerText: string;
}

const Timer = ({ initialCountSeconds = 0, timerText = "" }: TimerProps) => {
    const [secondsRemaining, setSecondsRemaining] =
        useState(initialCountSeconds);
    const twoDigits = (num: number) => String(num).padStart(2, "0");
    const secondsToDisplay = secondsRemaining % 60;
    const minutesRemaining = (secondsRemaining - secondsToDisplay) / 60;
    const minutesToDisplay = minutesRemaining % 60;
    const hoursToDisplay = (minutesRemaining - minutesToDisplay) / 60;

    useInterval(() => {
        if (secondsRemaining > 0) {
            setSecondsRemaining(secondsRemaining - 1);
        }
    }, 1000);

    return (
        <div>
            <p
                css={css`
                    font-size: 14px;
                    line-height: 20px;
                    letter-spacing: 0.1px;
                    margin-top: 12px;
                `}
            >
                {timerText} {twoDigits(hoursToDisplay)}:
                {twoDigits(minutesToDisplay)}:{twoDigits(secondsToDisplay)}
            </p>
        </div>
    );
};

export default Timer;
