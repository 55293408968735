/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import TypedGrid from "../TypedGrid";
import { ExpandMore as ExpandMoreIcon } from "@mui/icons-material";
import ThemeContext from "../../../context/theme/themeContext";
import { useContext } from "react";

export interface AccordionItemProps {
    item: {
        itemName: string;
        onItemClick: () => void;
        customItemBadge: React.ReactNode;
        itemDescription: string;
        index: number;
    };
    unsetItemMinHeight?: boolean;
}

const AccordionItem = ({ item, unsetItemMinHeight }: AccordionItemProps) => {
    const {
        colors: { gray200 },
    } = useContext(ThemeContext);
    const deviceGuideSelector = `frame${String(item.index)}`;
    return (
        <TypedGrid item xl={6} md={12} xs={12}>
            <div
                css={css({
                    padding: "24px 24px 16px 24px",
                    border: `1px solid ${gray200}`,
                    borderRadius: "8px",
                    margin: "12px 6px",
                    display: "flex",
                    justifyContent: "space-between",
                    cursor: "pointer",
                    minHeight: unsetItemMinHeight ? "unset" : "106px",
                })}
                onClick={item.onItemClick}
                id={deviceGuideSelector}
            >
                <div>
                    <div
                        css={css({
                            marginBottom: "8px",
                            fontWeight: 600,
                            fontSize: "16px",
                            lineHeight: "20px",
                            letterSpacing: "0.15px",
                            display: "flex",
                            alignItems: "center",
                        })}
                    >
                        <span css={css({ marginRight: "8px" })}>
                            {item.itemName}
                        </span>
                        {item.customItemBadge}
                    </div>
                    <div>{item.itemDescription}</div>
                </div>
                <div>
                    <div
                        css={css({
                            transform: "rotate(-90deg)",
                        })}
                    >
                        <ExpandMoreIcon />
                    </div>
                </div>
            </div>
        </TypedGrid>
    );
};

export default AccordionItem;
