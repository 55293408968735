/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from "@emotion/react";

import { useContext } from "react";
import ThemeContext from "../../../../context/theme/themeContext";
import SearchContext from "../../../../context/search/searchContext";
import useMediaQueries from "../../../../utils/useMediaQueries";
import HistoryResults from "./HistoryResults";
import SearchResults from "./SearchResults";
import SearchInput from "../SearchInput";
import Button from "../../../MuiComponents/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import CloseIcon from "@mui/icons-material/CloseRounded";
import CircularProgress from "@mui/material/CircularProgress";

const SearchModal = () => {
    const {
        colors: { white, gray300, blue400, blue200 },
    } = useContext(ThemeContext);

    const { isOpenModal, isLoading, results, closeModal } =
        useContext(SearchContext);

    const { toMd } = useMediaQueries();

    return (
        <Dialog
            onClose={toMd ? undefined : closeModal}
            open={isOpenModal}
            fullScreen={toMd}
            fullWidth
            maxWidth="xl"
            disableAutoFocus={toMd}
            disableEnforceFocus={toMd}
            sx={{
                top: toMd ? "52px" : "0",

                "& .MuiBackdrop-root": {
                    top: toMd ? "52px" : "0",
                },

                "& .MuiDialog-paper": {
                    position: "absolute",
                    top: toMd ? "0" : "6px",
                    left: toMd ? "0" : "260px",
                    maxWidth: toMd ? "unset" : "566px",
                    width: toMd ? "100%" : "calc(100% - 284px)",
                    margin: "0",
                },
            }}
        >
            {!toMd && (
                <DialogTitle
                    sx={{
                        padding: "4px 0",
                        borderBottom: `1px solid ${gray300}`,
                    }}
                >
                    <SearchInput
                        wrapperStyle={css({
                            marginRight: "32px",
                        })}
                    />

                    <Button
                        variant="iconOnly"
                        size="small"
                        color="white"
                        icon={<CloseIcon onClick={closeModal} />}
                        css={css({
                            position: "absolute",
                            top: "4px",
                            right: "4px",
                        })}
                        idForTesting="closeSearchModal"
                    />
                </DialogTitle>
            )}

            <DialogContent
                sx={{
                    minHeight: toMd ? "100%" : "117px",
                    maxHeight: toMd ? "100%" : "590px",
                    position: "relative",
                    padding: "0",

                    "&::-webkit-scrollbar": {
                        width: "18px",
                    },

                    "&::-webkit-scrollbar-thumb": {
                        backgroundColor: blue200,
                        borderRadius: "100px",
                        border: `5px solid ${white}`,
                        minHeight: "48px",
                    },

                    "&::-webkit-scrollbar-thumb:hover": {
                        backgroundColor: blue400,
                    },
                }}
            >
                <div
                    css={css({
                        opacity: isLoading ? "0.3" : "1",
                        transition: "all 300ms ease-out",
                    })}
                >
                    {!results ? <HistoryResults /> : <SearchResults />}
                </div>

                {isLoading && (
                    <CircularProgress
                        size={40}
                        css={css({
                            position: "absolute",
                            left: "calc(50% - 20px)",
                            top: "32px",
                        })}
                    />
                )}
            </DialogContent>
        </Dialog>
    );
};

export default SearchModal;
