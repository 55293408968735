export const SET_LAYOUT_ERROR = "SET_LAYOUT_ERROR";
export const SET_LAYOUT_DATA = "SET_LAYOUT_DATA";
export const START_ACTION_LOADING = "START_ACTION_LOADING";
export const STOP_ACTION_LOADING = "STOP_ACTION_LOADING";
export const RESET_LAYOUT_DATA = "RESET_LAYOUT_DATA";
export const SET_NEXT_MENU_ITEM_INDEX = "SET_NEXT_MENU_ITEM_INDEX";
export const DISABLE_NAVIGATION_BETWEEN_MENU_ITEMS =
    "DISABLE_NAVIGATION_BETWEEN_MENU_ITEMS";
export const SET_ACTIVE_FRAME_INDEX = "SET_ACTIVE_FRAME_INDEX";
export const SET_LIST_VALUES = "SET_LIST_VALUES";
export const SET_ACTIVE_DEVICE_GUIDE_COMPONENT_INDEX =
    "SET_ACTIVE_DEVICE_GUIDE_COMPONENT_INDEX";
export const SET_ACTIVE_DEVICE_GUIDE_FRAME_INDEX =
    "SET_ACTIVE_DEVICE_GUIDE_FRAME_INDEX";
export const SET_DRAWER_DATA = "SET_DRAWER_DATA";
export const SET_DRAWER_BLOCK_NAME = "SET_DRAWER_BLOCK_NAME";
export const RELOAD_MENU_ITEM = "RELOAD_MENU_ITEM";
export const SET_GLOBAL_ERROR = "SET_GLOBAL_ERROR";
export const SET_DEVICE_CONFIGURATION_BUTTON_PORT_NAME =
    "SET_DEVICE_CONFIGURATION_BUTTON_PORT_NAME";
export const SET_DEVICE_GUIDE = "SET_DEVICE_GUIDE";
export const SET_UPLOADED_FILE_DATA_TO_LAYOUT =
    "SET_UPLOADED_FILE_DATA_TO_LAYOUT";
export const SET_UPLOAD_FILE_LOAD_STATUS = "SET_UPLOAD_FILE_LOAD_STATUS";
export const SET_UPLOAD_FILE_NAME = "SET_UPLOAD_FILE_NAME";
