/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/react";
import SaveToFile, { SaveToFileButtonProps } from "../SaveToFile";
import { Fragment, SyntheticEvent, useContext, useState } from "react";
import useDesktopHost from "../../../../../../utils/useDesktopHost";
import LanguageContext from "../../../../../../context/language/languageContext";
import useApi from "../../../../../../utils/useApi";
import LayoutContext from "../../../../../../context/layout/layoutContext";
import AlertContext from "../../../../../../context/alert/alertContext";
import { DeviceFamily } from "../../../../../../utils/types";
import SavePasswordsConfirmationModal from "./modals/SavePasswordsConfirmationModal";
import EnterSavedPasswordsModal from "./modals/EnterSavedPasswordsModal";
import useTct from "../../../../../../utils/useTct";
import { AlertStatus } from "../../../../../../constants/constants";

export interface SaveToFileProps extends SaveToFileButtonProps {
    isMobileSaveModalOpen: boolean;
    setMobileSaveModal: (isOpen: boolean) => void;
}

const SaveToLocalFile = ({
    isDisabled,
    isModalOpen,
    setModalOpen,
    modifiedParameterResponse,
    isMobileSaveModalOpen,
    setMobileSaveModal,
    isSaving,
    setIsSaving,
}: SaveToFileProps) => {
    const { setAlert } = useContext(AlertContext);
    const { selectedLanguage, t } = useContext(LanguageContext);
    const { layoutData } = useContext(LayoutContext);

    const { showErrorMsg, getData } = useApi();
    const { saveToFileAsync } = useTct();
    const { saveFileDialog } = useDesktopHost();

    const [passwordConfirmationModalInfo, setPasswordConfirmationModalInfo] =
        useState({
            isOpen: false,
            hasBothPasswords: false,
            hasOnlyPrimaryPassword: false,
            modified: false,
        });

    const [enterPasswordsModalInfo, setEnterPasswordsModalInfo] = useState({
        isOpen: false,
        hasBothPasswords: false,
        hasOnlyPrimaryPassword: false,
        modified: false,
    });

    const [isPrimaryButtonLoading, setIsPrimaryButtonLoading] = useState(false);

    const handlePasswordConfirmationModal = (
        isOpen: boolean,
        hasBothPasswords: boolean,
        hasOnlyPrimaryPassword: boolean,
        modified: boolean
    ) => {
        setPasswordConfirmationModalInfo({
            isOpen,
            hasBothPasswords,
            hasOnlyPrimaryPassword,
            modified,
        });
    };

    const isFtcDeviceFamily =
        layoutData?.deviceFamily &&
        (layoutData?.deviceFamily as unknown) === DeviceFamily.FTC;

    const handleSaveFile = async (
        e: SyntheticEvent,
        modified: boolean,
        skipPasswords: boolean = false
    ) => {
        saveFileDialog(
            {
                title: t.SaveFile,
                filters: [
                    {
                        extensions: ["cfg"],
                        name: t.ConfigurationFile,
                    },
                ],
            },
            (result: any) => {
                if (result.length > 0) {
                    isModalOpen && setModalOpen(false);

                    const saveToFile = async () => {
                        try {
                            if (!layoutData) {
                                throw new Error("No layout");
                            }

                            setIsSaving(true);

                            await saveToFileAsync(
                                layoutData.id,
                                result,
                                modified,
                                skipPasswords
                            );

                            setAlert(
                                AlertStatus.Success,
                                t.ConfigurationSuccessfullySaved
                            );
                        } catch (error) {
                            showErrorMsg(error as any);
                        }
                        setIsSaving(false);
                    };

                    saveToFile();
                }
            }
        );
    };

    const handleFtcDeviceSave = async (
        e: SyntheticEvent,
        modified: boolean
    ) => {
        try {
            let skipPasswords = false;
            if (layoutData?.isOnline) {
                skipPasswords = true;
                setIsPrimaryButtonLoading(true);
                const { data } = await getData(
                    `${selectedLanguage}/device/getsecurityinfo`
                );
                setIsPrimaryButtonLoading(false);
                if (data?.keywordInfo.hasRecoveryPassword) {
                    handlePasswordConfirmationModal(
                        true,
                        true,
                        false,
                        modified
                    );
                    return;
                }
                if (data?.keywordInfo.hasPrimaryPassword) {
                    handlePasswordConfirmationModal(
                        true,
                        false,
                        true,
                        modified
                    );
                    return;
                }
            }
            handleSaveFile(e, modified, skipPasswords);
        } catch (error) {
            setIsPrimaryButtonLoading(false);
            showErrorMsg(error as any);
        }
    };

    const handleSaveToFile = async (e: SyntheticEvent, modified: boolean) => {
        e.preventDefault();
        isMobileSaveModalOpen && setMobileSaveModal(false);
        if (isFtcDeviceFamily) {
            handleFtcDeviceSave(e, modified);
        } else {
            handleSaveFile(e, modified, false);
        }
    };

    const handleSaveConfirmationModalClose = () => {
        handlePasswordConfirmationModal(false, false, false, false);
    };

    const handleSaveConfirmationModalSecondaryAction = (
        e: React.SyntheticEvent
    ) => {
        handleSaveConfirmationModalClose();
        handleSaveFile(e, passwordConfirmationModalInfo.modified, true);
    };

    const handleSavePasswordsConfirmationSubmit = () => {
        setEnterPasswordsModalInfo({
            ...passwordConfirmationModalInfo,
            isOpen: true,
        });
        handleSaveConfirmationModalClose();
    };

    const handleEnterPasswordsModalClose = () => {
        setEnterPasswordsModalInfo({
            isOpen: false,
            hasBothPasswords: false,
            hasOnlyPrimaryPassword: false,
            modified: false,
        });
    };

    const handleEnterPasswordsSavePasswords = (e: any) => {
        handleSaveFile(e, enterPasswordsModalInfo?.modified, false);
        setEnterPasswordsModalInfo({
            isOpen: false,
            hasBothPasswords: false,
            hasOnlyPrimaryPassword: false,
            modified: false,
        });
    };

    const isPasswordChanged =
        !!modifiedParameterResponse?.recoveryConfigurationPasswordState ||
        !!modifiedParameterResponse?.configurationPasswordState;

    return (
        <Fragment>
            <SaveToFile
                isDisabled={isDisabled}
                isModalOpen={isModalOpen}
                setModalOpen={setModalOpen}
                modifiedParameterResponse={modifiedParameterResponse}
                onSave={handleSaveToFile}
                isSaving={isSaving}
                setIsSaving={setIsSaving}
                btnLabel={t.SaveToFile}
                isPrimaryColor={!layoutData?.isOnline}
                isPrimaryButtonLoading={isPrimaryButtonLoading}
            />
            <SavePasswordsConfirmationModal
                modalInfoData={passwordConfirmationModalInfo}
                onSecondaryButtonClick={
                    handleSaveConfirmationModalSecondaryAction
                }
                onClose={handleSaveConfirmationModalClose}
                onSubmit={handleSavePasswordsConfirmationSubmit}
            />
            <EnterSavedPasswordsModal
                enterPasswordsModalInfo={enterPasswordsModalInfo}
                onClose={handleEnterPasswordsModalClose}
                isPasswordChanged={isPasswordChanged}
                onPasswordSave={handleEnterPasswordsSavePasswords}
            />
        </Fragment>
    );
};

export default SaveToLocalFile;
