import React, { useReducer } from "react";
import {
    RESET_ALL_FILTERS,
    SEARCH_GEOFENCE_PARAMETERS,
    SET_ACTIVE_SHAPE,
    SET_CENTER_COORDINATES,
    SET_EDIT_STATE,
    SET_GEOZONES,
    SET_GEOFENCE_PARAMETERS,
    SET_HIGHLIGHTED_GEOZONE,
    SET_OPERAND,
    SET_PRIORITY,
    SET_LATITUDE_LONGITUDE_VALUES,
    SET_SHAPE_PARAMETER,
    SET_GEOZONE_DISABLED,
    SET_RADIUS,
    SET_AVAILABLE_SHAPES,
    SET_LOADING,
    SET_REFETCH_LIST,
} from "./manualGeofenceActions";
import ManualGeofenceContext, {
    EditorState,
    GeozoneEditState,
} from "./manualGeofenceContext";
import ManualGeofenceReducer from "./manualGeofenceReducer";

export interface ManualGeofenceStateProps {
    children: React.ReactNode;
}

export interface ManualGeofenceInitialState {
    geofenceSearchValue: string;
    previousGeofenceParameters: any;
    editState: GeozoneEditState;
    highlightedGeozone: number;
    availableShapes: number;
}

const ManualGeofenceState = ({ children }: ManualGeofenceStateProps) => {
    const initialState = {
        geofenceSearchValue: "",
        previousGeofenceParameters: {},
        editState: {
            currentEditorState: EditorState.None,
            currentIndex: -1,
            previousIndex: -1,
        },
        highlightedGeozone: 0,
        centerCoordinates: [0, 0],
        geozones: [],
        previousGeozones: [],
        activeShape: [],
        activePriority: [],
        activeOperand: [],
        latitudeLongitudeParameters: {
            lat: [],
            long: [],
        },
        radius: undefined,
        shapeParameter: 0,
        isSaveDisabled: false,
        availableShapes: [],
    };
    const [state, dispatch] = useReducer(ManualGeofenceReducer, initialState);

    const setGeofenceSearchValue = (data: any) => {
        dispatch({
            type: SEARCH_GEOFENCE_PARAMETERS,
            payload: data,
        });
    };

    const setPreviousGeofenceParameters = (data: any) => {
        dispatch({
            type: SET_GEOFENCE_PARAMETERS,
            payload: data,
        });
    };

    const setEditState = (data: any) => {
        dispatch({
            type: SET_EDIT_STATE,
            payload: data,
        });
    };

    const setRefetchList = (data: any) => {
        dispatch({
            type: SET_REFETCH_LIST,
            payload: data,
        });
    };

    const setHighlightedGeozone = (data: any) => {
        dispatch({
            type: SET_HIGHLIGHTED_GEOZONE,
            payload: data,
        });
    };

    const setCenterCoordinates = (data: any) => {
        dispatch({
            type: SET_CENTER_COORDINATES,
            payload: data,
        });
    };

    const setGeozones = (data: any) => {
        dispatch({
            type: SET_GEOZONES,
            payload: data,
        });
    };

    const setActiveShape = (shape: { value: string; text: string }[]) => {
        dispatch({ type: SET_ACTIVE_SHAPE, payload: shape });
    };
    const setActiveOperand = (operand: { value: string; text: string }[]) => {
        dispatch({ type: SET_OPERAND, payload: operand });
    };
    const setActivePriority = (priority: { value: string; text: string }[]) => {
        dispatch({ type: SET_PRIORITY, payload: priority });
    };
    const resetAllFilters = () => {
        dispatch({ type: RESET_ALL_FILTERS });
    };

    const setShapeParameter = (value: any) =>
        dispatch({ type: SET_SHAPE_PARAMETER, payload: value });

    const setLatitudeLongituteValues = (value: { lat: any[]; long: any[] }) =>
        dispatch({ type: SET_LATITUDE_LONGITUDE_VALUES, payload: value });

    const setGeozoneDisabled = (value: any) =>
        dispatch({ type: SET_GEOZONE_DISABLED, payload: value });

    const setRadius = (value: any) =>
        dispatch({ type: SET_RADIUS, payload: value });

    const setAvailableShapes = (value: any) =>
        dispatch({ type: SET_AVAILABLE_SHAPES, payload: value });

    const setLoading = (value: any) =>
        dispatch({ type: SET_LOADING, payload: value });

    return (
        <ManualGeofenceContext.Provider
            value={{
                geofenceSearchValue: state.geofenceSearchValue,
                previousGeofenceParameters: state.previousGeofenceParameters,
                editState: state.editState,
                highlightedGeozone: state.highlightedGeozone,
                centerCoordinates: state.centerCoordinates,
                geozones: state.geozones,
                activePriority: state.activePriority,
                activeOperand: state.activeOperand,
                activeShape: state.activeShape,
                previousGeozones: state.previousGeozones,
                shapeParameter: state.shapeParameter,
                latitudeLongitudeParameters: state.latitudeLongitudeParameters,
                isSaveDisabled: state.isSaveDisabled,
                radius: state.radius,
                availableShapes: state.availableShapes,
                isLoading: state.isLoading,
                refetchList: state.refetchList,
                setGeofenceSearchValue,
                setPreviousGeofenceParameters,
                setEditState,
                setHighlightedGeozone,
                setCenterCoordinates,
                setGeozones,
                setActiveShape,
                setActiveOperand,
                setActivePriority,
                resetAllFilters,
                setLatitudeLongituteValues,
                setShapeParameter,
                setGeozoneDisabled,
                setRadius,
                setAvailableShapes,
                setLoading,
                setRefetchList,
            }}
        >
            {children}
        </ManualGeofenceContext.Provider>
    );
};

export default ManualGeofenceState;
