/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/react";
import { useContext, useEffect, useState } from "react";
import { saveAs } from "file-saver";
import DeleteIcon from "../../../../../assets/icons/DeleteIcon";
import DownloadIcon from "../../../../../assets/icons/DownloadIcon";
import AlertContext from "../../../../../context/alert/alertContext";
import LanguageContext from "../../../../../context/language/languageContext";
import { Component } from "../../../../../generatedTypes";
import {
    convertBytesToKilobytes,
    convertPathToTwoBackslashes,
    convertUnixTimeToDate,
    findSubStringInString,
} from "../../../../../utils/helpers";
import useApi from "../../../../../utils/useApi";
import Dropzone from "../../../../MuiComponents/Dropzone";
import { AlertStatus } from "../../../../../constants/constants";

export interface FileInputProps {
    data: Component;
}

const FileInput = ({ data }: FileInputProps) => {
    const { selectedLanguage, t } = useContext(LanguageContext);
    const { setAlert } = useContext(AlertContext);

    const { postData, getData, showErrorMsg } = useApi();

    const [results, setResults] = useState<any>([]);
    const [loading, setLoading] = useState(false);

    const getDropzoneResults = async () => {
        try {
            const { data: fileData } = await postData(
                `${selectedLanguage}/command/getfileinfo`,
                convertPathToTwoBackslashes(data.path || "")
            );
            setResults([
                {
                    fileName: fileData.fileName,
                    timeStamp: convertUnixTimeToDate(fileData.timeStamp),
                    fileSize: convertBytesToKilobytes(fileData.fileSize),
                    isEmpty: fileData.isEmpty,
                },
            ]);
        } catch (error) {
            setResults([]);
        }
    };

    const handleInputChange = async (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        if (
            !findSubStringInString(
                event.target.value,
                data.supportedFormats || ""
            )
        ) {
            setAlert(AlertStatus.Critical, t.WrongFileFormat);
            return;
        }
        const formData = new FormData();
        event.target.files && formData.append("files", event.target.files[0]);
        data.path &&
            formData.append(
                "pathToFileOnDevice",
                convertPathToTwoBackslashes(data.path)
            );
        event.target.value = "";
        try {
            setLoading(true);
            await postData(`${selectedLanguage}/command/uploadfile`, formData);
            getDropzoneResults();
            setLoading(false);
        } catch (error) {
            showErrorMsg(error as any);
            setLoading(false);
        }
    };

    const handleItemDelete = async () => {
        try {
            await postData(`${selectedLanguage}/command/deletefiles`, [
                data.path,
            ]);
            getDropzoneResults();
        } catch (error) {
            setAlert(AlertStatus.Critical, "Failed to delete");
        }
    };

    const handleItemDownload = async () => {
        try {
            const { data: file } = await getData(
                `${selectedLanguage}/command/downloadfiles?pathToFileOnDevice=${data.path}`
            );
            const blob = new Blob([file], { type: "application/octet-stream" });
            const fileName = results[0].fileName;
            saveAs(blob, fileName);
        } catch (error) {
            setAlert(AlertStatus.Critical, "Failed to download");
        }
    };

    const menuItems = [
        {
            icon: <DownloadIcon />,
            label: "Download",
            onItemClick: handleItemDownload,
        },
        {
            icon: <DeleteIcon />,
            label: "Delete",
            onItemClick: handleItemDelete,
        },
    ];

    useEffect(() => {
        getDropzoneResults();
        // eslint-disable-next-line
    }, []);

    return (
        <Dropzone
            supportedFileFormats={data.supportedFormats || ""}
            label={"Add files"}
            menuItems={menuItems}
            OnInputChange={handleInputChange}
            items={results}
            loading={loading}
            isDropzoneDisabled={!results[0]?.isEmpty}
        />
    );
};

export default FileInput;
