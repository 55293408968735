import React, { Fragment, useEffect, useState } from "react";
import ThemeState from "./context/theme/ThemeState";
import AlertState from "./context/alert/AlertState";
import AppTypeState from "./context/appType/AppTypeState";
import LanguageState from "./context/language/LanguageState";
import UserInfoState from "./context/userInfo/UserInfoState";
import SettingsState from "./context/settings/SettingsState";
import ConfigurationState from "./context/configuration/ConfigurationState";
import LayoutState from "./context/layout/LayoutState";
import MenuItemState from "./context/menuItem/MenuItemState";
import DeviceStatusState from "./context/deviceStatus/DeviceStatusState";
import Router from "./components/Router";
import FeaturesState from "./context/features/FeaturesState";
import BluetoothState from "./context/bluetoothView/bluetoothViewState";
import ManualGeofenceState from "./context/manualGeofence/ManualGeofenceState";
import WebSocketsState from "./context/webSockets/webSocketsState";
import ErrorState from "./context/error/errorState";
import AuthenticationState from "./context/authentication/authenticationState";
import AccessoriesState from "./context/accessories/AccessoriesState";

import TerminalState from "./context/components/terminal/terminalState";
import BlazorApi from "./components/Blazor/BlazorApi";

const App = () => {
    const isWebEditor = process.env.REACT_APP_WEB_EDITOR === "true";
    const useLoader = process.env.REACT_APP_USE_LOADER === "true";

    const [appReady, setAppReady] = useState(!useLoader);

    useEffect(() => {
        if (!appReady) {
            const interval = setInterval(() => {
                if ((window as any).tctLoader.isLoaded) {
                    clearInterval(interval);
                    setAppReady(true);
                }
            }, 100);
        }
    }, []);

    return (
        <Fragment>
            {isWebEditor && <BlazorApi/>}
            {appReady && <ThemeState>
                <ErrorState>
                    <AuthenticationState>
                        <AlertState>
                            <AppTypeState>
                                <LanguageState>
                                    <UserInfoState>
                                        <ConfigurationState>
                                            <LayoutState>
                                                <SettingsState>
                                                    <MenuItemState>
                                                        <DeviceStatusState>
                                                            <FeaturesState>
                                                                <AccessoriesState>
                                                                    <BluetoothState>
                                                                        <ManualGeofenceState>
                                                                            <WebSocketsState>
                                                                                <TerminalState>
                                                                                    <Router/>
                                                                                </TerminalState>
                                                                            </WebSocketsState>
                                                                        </ManualGeofenceState>
                                                                    </BluetoothState>
                                                                </AccessoriesState>
                                                            </FeaturesState>
                                                        </DeviceStatusState>
                                                    </MenuItemState>
                                                </SettingsState>
                                            </LayoutState>
                                        </ConfigurationState>
                                    </UserInfoState>
                                </LanguageState>
                            </AppTypeState>
                        </AlertState>
                    </AuthenticationState>
                </ErrorState>
            </ThemeState>}
        </Fragment>
    );
};

export default App;
