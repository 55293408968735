import { useContext } from "react";
import AlertContext from "../context/alert/alertContext";
import LanguageContex from "../context/language/languageContext";
import useApi from "./useApi";
import { AlertStatus } from "../constants/constants";

const useDesktopHost = () => {
    const { setAlert } = useContext(AlertContext);

    const { selectedLanguage } = useContext(LanguageContex);

    const { postData } = useApi();

    const openFileDialog = async (
        payload: any,
        callbackFunc?: (data: any) => void
    ) => {
        try {
            const { data } = await postData(
                `${selectedLanguage}/desktopHost/openfiledialog`,
                payload
            );

            callbackFunc && callbackFunc(data);
        } catch (error) {
            setAlert(AlertStatus.Critical, "Dialog error");
        }
    };

    const saveFileDialog = async (
        payload: any,
        callbackFunc?: (data: any) => void
    ) => {
        try {
            const { data } = await postData(
                `${selectedLanguage}/desktopHost/savefiledialog`,
                payload
            );

            callbackFunc && callbackFunc(data);
        } catch (error) {
            setAlert(AlertStatus.Critical, "Dialog error");
        }
    };

    const openDirectory = async (
        payload: any,
        callbackFunc?: (data: any) => void
    ) => {
        try {
            const { data } = await postData(
                `${selectedLanguage}/desktopHost/opendirectory`,
                payload
            );

            callbackFunc && callbackFunc(data);
        } catch (error) {
            setAlert(AlertStatus.Critical, "Dialog error");
        }
    };

    const openSpecialDirectory = async (
        payload: any,
        callbackFunc?: (data: any) => void
    ) => {
        try {
            const { data } = await postData(
                `${selectedLanguage}/desktopHost/openspecialdirectory`,
                payload
            );

            callbackFunc && callbackFunc(data);
        } catch (error) {
            setAlert(AlertStatus.Critical, "Dialog error");
        }
    };

    return {
        openFileDialog,
        saveFileDialog,
        openSpecialDirectory,
        openDirectory,
    };
};

export default useDesktopHost;
