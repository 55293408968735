/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from "@emotion/react";

import { useContext, useState, Fragment } from "react";
import ThemeContext from "../../../../context/theme/themeContext";
import LanguageContext from "../../../../context/language/languageContext";
import ConfigurationContext from "../../../../context/configuration/configurationContext";
import Management from "./Management";
import Filter from "./Filter";
import Search from "./Search";
import Stack from "@mui/material/Stack";
import Chip from "@mui/material/Chip";
import Button from "../../../MuiComponents/Button";

export interface ToolbarProps {
    changePage: (e: any, page: number) => void;
}

const Toolbar = ({ changePage }: ToolbarProps) => {
    const {
        colors: { blue800, blue700, blue100, textDark },
    } = useContext(ThemeContext);

    const { t } = useContext(LanguageContext);

    const { filteredValues, filterConfigurations } =
        useContext(ConfigurationContext);

    const [showSearchOnMobile, setShowSearchOnMobile] = useState(false);

    const expandSearchOnMobile = () => setShowSearchOnMobile(true);
    const hideSearchOnMobile = () => setShowSearchOnMobile(false);

    const handleChipDelete = (value: any) => {
        const updatedChipArray = filteredValues.filter(
            (chip: any) => chip !== value
        );
        filterConfigurations(updatedChipArray);
    };

    return (
        <Fragment>
            <div
                css={css`
                    padding: 16px 24px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;

                    h2 {
                        color: ${textDark};
                        font-size: 20px;
                        font-weight: 600;
                        line-height: 24px;
                        letter-spacing: 0.15px;
                        margin-right: 24px;
                    }

                    @media (max-width: 839px) {
                        display: block;
                        padding: 16px 0;

                        h2 {
                            margin-right: 0;
                            margin-bottom: 16px;
                        }
                    }
                `}
            >
                <h2>{t.OfflineConfiguration}</h2>

                <div
                    css={css`
                        display: flex;
                        align-items: center;
                    `}
                >
                    {!showSearchOnMobile && (
                        <Fragment>
                            <Management />
                            <Filter changePage={changePage} />
                        </Fragment>
                    )}

                    <Search
                        renderOnMobile={showSearchOnMobile}
                        expandOnMobile={expandSearchOnMobile}
                        shrinkOnMobile={hideSearchOnMobile}
                        changePage={changePage}
                    />
                </div>
            </div>

            {filteredValues.length > 0 && (
                <div
                    css={css`
                        display: flex;
                        align-items: stretch;
                        padding: 0 24px 8px 24px;

                        @media (max-width: 839px) {
                            padding: 0 0 16px 0;
                        }
                    `}
                >
                    <p
                        css={css`
                            color: ${textDark};
                            line-height: 20px;
                            padding: 6px 0;
                            letter-spacing: 0.1px;
                            margin-right: 16px;
                            white-space: nowrap;
                        `}
                    >
                        {t.FilteredBy}:
                    </p>

                    <Stack
                        direction="row"
                        css={css`
                            flex-wrap: wrap;
                            margin: -6px 16px 0 -6px;

                            & > div {
                                background: ${blue100};
                                color: ${blue700};
                                font-weight: 600;
                                line-height: 20px;
                                letter-spacing: 0.1px;
                                margin-left: 6px;
                                margin-top: 6px;
                                padding: 6px 8px;
                                border-radius: 4px;
                            }
                        `}
                    >
                        {filteredValues.map((value: any) => (
                            <Chip
                                key={value}
                                label={value}
                                onDelete={() => handleChipDelete(value)}
                                sx={{
                                    "& .MuiChip-label": {
                                        padding: "0 8px 0 0",
                                    },

                                    "& .MuiChip-deleteIcon": {
                                        color: blue700,
                                        margin: "0",
                                        fontSize: "16px",

                                        "&:hover": {
                                            color: blue800,
                                        },
                                    },
                                }}
                            />
                        ))}
                    </Stack>

                    <Button
                        variant="textOnly"
                        size="small"
                        color="white"
                        onClick={() => filterConfigurations([])}
                        css={css`
                            align-self: flex-start;
                            white-space: nowrap;
                            margin-top: -2px;
                        `}
                        idForTesting="clear-all-btn"
                    >
                        {t.ClearAll}
                    </Button>
                </div>
            )}
        </Fragment>
    );
};

export default Toolbar;
