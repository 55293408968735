/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import React, { Fragment, useContext } from "react";
import Tooltip from "../../../../Tooltip";
import { UserKeyStatuses } from "../../../../../../constants/constants";
import ThemeContext from "../../../../../../context/theme/themeContext";

export interface StatusCellProps {
    row: any;
}

const StatusCell: React.FunctionComponent<StatusCellProps> = ({
    row,
    ...props
}: StatusCellProps) => {
    const {
        colors: { gray600, green600, red600 },
    } = useContext(ThemeContext);
    const renderStatusColor: { [key: string]: string } = {
        [UserKeyStatuses.Unknown]: gray600,
        [UserKeyStatuses.Active]: green600,
        [UserKeyStatuses.Expired]: red600,
    };
    return (
        <Tooltip
            title={row?.original?.statusMessage || ""}
            small
            placement="top"
        >
            <Fragment>
                {renderStatusColor[row.original.status] && (
                    <span
                        css={css({
                            width: "8px",
                            height: "8px",
                            borderRadius: "50%",
                            backgroundColor:
                                renderStatusColor[row.original.status],
                            marginRight: "8px",
                        })}
                    />
                )}
                <div
                    css={css({
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        paddingRight: "4px",
                    })}
                >
                    {row.original.statusMessage
                        ? row.original.statusMessage
                        : ""}
                </div>
            </Fragment>
        </Tooltip>
    );
};

export default StatusCell;
