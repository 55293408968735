/** @jsxRuntime classic */
/** @jsx jsx */
import { useMap } from "react-leaflet";
import { useEffect } from "react";

interface MapCenterProps {
    center: [number, number];
}

const MapCenter = ({ center }: MapCenterProps) => {
    const map = useMap();
    const hasCoordinates = center.some((item) => item !== 0);
    useEffect(() => {
        if (hasCoordinates) {
            map.panTo(center);
        }
    }, [map, center]);
    return null;
};

export default MapCenter;
