import { createContext } from "react";
import { InitialState } from "./inputOutputReducer";

interface InputOutputContextState extends InitialState {
    activeColumns: string[];
    filterColumnsByName: (e: string) => void;
    resetColumnsToDefault: () => void;
    setActiveFilters: (filters: string[]) => void;
    setActiveFilter: (filter: string) => void;
    setActivePriority: (priority: { value: string; text: string }[]) => void;
    setActiveOperand: (operand: { value: string; text: string }[]) => void;
    resetAllFilters: () => void;
    resetFilter: (filter: string) => void;
    resetPriority: (priority: string) => void;
    resetOperand: (operand: string) => void;
    setActiveStatus: (status: string) => void;
    setSearchValue: (value: string) => void;
    resetColumn: (column: string) => void;
    setColumns: (columns: any[]) => void;
}

const InputOutputContext = createContext({} as InputOutputContextState);

export default InputOutputContext;
