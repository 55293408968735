/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from "@emotion/react";

import { useContext } from "react";
import LanguageContext from "../../../../context/language/languageContext";
import useMediaQuries from "../../../../utils/useMediaQueries";
import Container from "../../../Container";
import Alert from "../../../Alert";
import SingleOfflineCard from "./SingleOfflineCard";
import Grid from "@mui/material/Grid";
import { AlertStatus } from "../../../../constants/constants";

const OfflineView = () => {
    const { t } = useContext(LanguageContext);

    const { toXl, fromXl } = useMediaQuries();

    return (
        <Container
            css={css({
                paddingTTop: "24px",
                paddingBottom: "80px",
                maxWidth: "1156px",
                width: "100%",
                marginTop: "20px",
            })}
        >
            <Alert
                status={AlertStatus.Info}
                title={t.DeviceNotConnected}
                description={t.CanProceed}
            />

            <Grid
                container
                columnSpacing={3}
                css={css({
                    marginTop: "16px",
                })}
            >
                <Grid
                    item
                    xl={6}
                    css={css({
                        "& > div + div": {
                            marginTop: "16px",
                        },
                    })}
                >
                    <SingleOfflineCard
                        title={t.DeviceInfo}
                        subtitle={t.DeviceNotConnected}
                    />

                    {toXl && (
                        <SingleOfflineCard
                            title={t.GnssInformation}
                            description={t["GnssInformation.description"]}
                            subtitle={t.DeviceNotConnected}
                        />
                    )}

                    <SingleOfflineCard
                        title={t.CellularInfo}
                        description={t["CellularInfo.description"]}
                        subtitle={t.NoRecords}
                    />
                    {toXl && (
                        <SingleOfflineCard
                            title={t.SimSecurity}
                            description={t["SimSecurity.description"]}
                            subtitle={t.DeviceNotConnected}
                        />
                    )}
                    <SingleOfflineCard
                        title={t.Maintenance}
                        subtitle={t.DeviceNotConnected}
                    />
                </Grid>

                {fromXl && (
                    <Grid
                        item
                        xl={6}
                        css={css({
                            "& > div + div": {
                                marginTop: "16px",
                            },
                        })}
                    >
                        <SingleOfflineCard
                            title={t.GnssInformation}
                            description={t["GnssInformation.description"]}
                            subtitle={t.DeviceNotConnected}
                        />
                        <SingleOfflineCard
                            title={t.SimSecurity}
                            description={t["SimSecurity.description"]}
                            subtitle={t.DeviceNotConnected}
                        />
                    </Grid>
                )}
            </Grid>
        </Container>
    );
};

export default OfflineView;
