/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import React, { Fragment, useContext } from "react";
import CircleIcon from "../../../../../assets/icons/shapes/CircleIcon";
import Badge from "../../../../MuiComponents/Badge";
import Button from "../../../../MuiComponents/Button";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import SearchInput from "./SearchInput";
import MoreFiltersSelect from "./MoreFiltersSelect";
import ThemeContext from "../../../../../context/theme/themeContext";
import EmptySearchHistoryIcon from "../../../../../assets/icons/EmptySearchHistoryIcon";
import PolygonIcon from "../../../../../assets/icons/shapes/PolygonIcon";
import RectangleIcon from "../../../../../assets/icons/shapes/RectangleIcon";
import ConfirmModal from "../../../../MuiComponents/Modals/ConfirmModal";
import LanguageContext from "../../../../../context/language/languageContext";
import ManualGeofenceContext, {
    EditorState,
} from "../../../../../context/manualGeofence/manualGeofenceContext";
import LayoutContext from "../../../../../context/layout/layoutContext";
import useTct from "../../../../../utils/useTct";

export interface ManualGeofenceListProps {
    finalGeozoneId: number;
    maxGeozones: number;
}

const ManualGeofenceList: React.FunctionComponent<ManualGeofenceListProps> = ({
    finalGeozoneId,
    maxGeozones,
}) => {
    const {
        colors: {
            textDark,
            textPlaceholder,
            blue100,
            textDarkSecondary,
            gray50,
            black,
            geozoneGray,
        },
        boxShadows: { grayBoxShadow, blueBoxShadow },
    } = useContext(ThemeContext);
    const { t } = useContext(LanguageContext);
    const { layoutData } = useContext(LayoutContext);
    const {
        setEditState,
        editState,
        setCenterCoordinates,
        centerCoordinates,
        setRefetchList,
        setHighlightedGeozone,
        highlightedGeozone,
        geozones,
    } = useContext(ManualGeofenceContext);

    const [removedGeozoneId, setRemovedGeozoneId] = React.useState(-1);

    const { getGeozoneAsync } = useTct();

    const renderPriorityBadge: any = {
        ["Low"]: <Badge color="neutral">Low</Badge>,
        ["High"]: <Badge color="warning">High</Badge>,
        ["Panic"]: <Badge color="critical">Panic</Badge>,
    };
    const renderStatusBadge: any = {
        ["NoEvent"]: <Badge color="info">No Event</Badge>,
        ["OnExit"]: <Badge color="info">On Exit</Badge>,
        ["OnEntrance"]: <Badge color="info">On Entrance</Badge>,
        ["OnBoth"]: <Badge color="info">On Both</Badge>,
    };

    const getFirstEmptyGeozone = () => {
        return finalGeozoneId;
    };

    const withoutNewGeozone = geozones.filter(
        (geozone: any) => !geozone.isHidden
    );

    const onEditStart = (index: number) => {
        const getGeozoneCoordinates = (index: number) => {
            const geozone = geozones.find((zone: any) => zone.index === index);
            return geozone?.coordinates.length
                ? geozone.coordinates[0]
                : centerCoordinates;
        };
        setEditState({
            currentIndex: index,
            previousIndex: editState.currentIndex,
            currentEditorState: EditorState.Start,
        });
        setCenterCoordinates(getGeozoneCoordinates(index));
    };

    const onDeleteGeozone = async (index: number) => {
        try {
            //in BE geozones starts from 0 in FE from 1
            await getGeozoneAsync(layoutData?.id || 0, index, true);
            setRefetchList(true);
        } catch (error) {
            console.log(error);
        }
        if (highlightedGeozone == index) {
            setHighlightedGeozone(0);
        }
    };

    const onHighlightGeozone = (index: number) => {
        setHighlightedGeozone(index);
    };

    const renderContent = () => {
        if (withoutNewGeozone.length <= 0) {
            return (
                <div
                    css={css({
                        backgroundColor: gray50,
                        boxShadow: grayBoxShadow,
                        height: "100%",
                        color: black,
                        padding: "61px",
                        display: "flex",
                        flexDirection: "column",
                        gap: "8px",
                        alignItems: "center",
                    })}
                >
                    <EmptySearchHistoryIcon
                        css={css({
                            width: "120px",
                            height: "120px",
                        })}
                    />
                    <div
                        css={css({
                            fontWeight: 600,
                            fontSize: "16px",
                            color: textDark,
                        })}
                    >
                        {t.NoGeozonesCreated}
                    </div>
                    <div
                        css={css({
                            fontWeight: 400,
                            fontSize: "14px",
                            color: textDarkSecondary,
                        })}
                    >
                        {t.GeozonesGetStarted}
                    </div>
                </div>
            );
        } else {
            return (
                <Fragment>
                    {geozones.map((geozone: any, index: number) => {
                        const id = Number(geozone.index);
                        if (geozone.isHidden) {
                            return null;
                        }
                        const activeIcon: { [key: number]: any } = {
                            [1]: (
                                <CircleIcon
                                    css={css({ width: "32px", height: "32px" })}
                                />
                            ),
                            [2]: (
                                <RectangleIcon
                                    css={css({ width: "32px", height: "32px" })}
                                />
                            ),
                            [3]: (
                                <PolygonIcon
                                    css={css({ width: "32px", height: "32px" })}
                                />
                            ),
                        };
                        return (
                            <div
                                key={index}
                                onMouseLeave={() => onHighlightGeozone(-1)}
                                onMouseOver={() => onHighlightGeozone(id)}
                                css={css({
                                    display: "grid",
                                    gap: "26px",
                                    padding: "13px 24px",
                                    boxShadow: grayBoxShadow,
                                    gridAutoFlow: "column",
                                    backgroundColor:
                                        highlightedGeozone === id
                                            ? geozoneGray
                                            : "none",
                                })}
                            >
                                <div
                                    css={css({
                                        display: "flex",
                                        alignItems: "center",
                                    })}
                                >
                                    {activeIcon[geozone.type]}
                                </div>
                                <div
                                    css={css({
                                        display: "flex",
                                        gap: "4px",
                                        flexDirection: "column",
                                    })}
                                >
                                    <div
                                        css={css({
                                            fontWeight: 600,
                                            fontSize: "14px",
                                            lineHeight: "20px",
                                            letterSpacing: "0.1px",
                                            color: textDark,
                                        })}
                                    >
                                        {geozone.name}
                                    </div>
                                    <div
                                        css={css({
                                            display: "flex",
                                            gap: "4px",
                                        })}
                                    >
                                        {
                                            renderPriorityBadge[
                                                geozone.priorityStatus
                                            ]
                                        }
                                        {
                                            renderStatusBadge[
                                                geozone.generateEventStatus
                                            ]
                                        }
                                    </div>
                                </div>
                                <span
                                    css={css({
                                        display: "flex",
                                        gap: "12px",
                                        justifySelf: "flex-end",
                                        padding: "6px 0px",
                                        alignItems: "center",
                                    })}
                                >
                                    <Button
                                        variant="textOnly"
                                        size="small"
                                        color="primarySubtle"
                                        onClick={() => onEditStart(id)}
                                        idForTesting={`edit-geozone-${id}`}
                                    >
                                        Edit
                                    </Button>
                                    <Button
                                        variant="iconOnly"
                                        size="small"
                                        color="primarySubtle"
                                        icon={<DeleteOutlineIcon />}
                                        onClick={() => setRemovedGeozoneId(id)}
                                        idForTesting={`delete-geozone-${id}`}
                                    />
                                </span>
                            </div>
                        );
                    })}
                </Fragment>
            );
        }
    };

    return (
        <Fragment>
            <div
                css={css({
                    display: "flex",
                    justifyContent: "space-between",
                    padding: "24px 16px",
                    alignItems: "center",
                    flexDirection: "column",
                    gap: "16px",
                })}
            >
                <div
                    css={css({
                        display: "flex",
                        justifyContent: "space-between",
                        width: "100%",
                        alignItems: "center",
                    })}
                >
                    <div
                        css={css({
                            fontWeight: 600,
                            fontSize: "16px",
                            lineHeight: "20px",
                            letterSpacing: "0.15px",
                            color: textDark,
                        })}
                    >
                        {t.GeozonesList} ({withoutNewGeozone.length})
                        <span
                            css={css({
                                color: textPlaceholder,
                            })}
                        ></span>
                    </div>
                    <Button
                        onClick={() => onEditStart(getFirstEmptyGeozone())}
                        size="normal"
                        color="primary"
                        disabled={geozones.length > maxGeozones}
                        idForTesting="add-geozone"
                    >
                        {t.AddGeozone}
                    </Button>
                </div>
                <div
                    css={css({
                        display: "flex",
                        justifyContent: "space-between",
                        width: "100%",
                    })}
                >
                    <SearchInput />
                    <MoreFiltersSelect />
                </div>
            </div>
            <div
                css={css({
                    display: "flex",
                    alignItems: "center",
                    padding: "13px 24px",
                    backgroundColor: blue100,
                    gap: "38px",
                    boxShadow: blueBoxShadow,
                })}
            >
                <div
                    css={css({
                        fontWeight: 600,
                        fontSize: "12px",
                        color: textDark,
                    })}
                >
                    {t.Shape}
                </div>
                <div
                    css={css({
                        fontWeight: 600,
                        fontSize: "12px",
                        color: textDark,
                    })}
                >
                    {t.Geozone}
                </div>
            </div>
            <div
                css={css({
                    maxHeight: "506px",
                    overflow: geozones.length === 1 ? "hidden" : "auto",
                    height: "100%",
                })}
            >
                {renderContent()}
            </div>
            <ConfirmModal
                open={removedGeozoneId !== -1}
                close={() => setRemovedGeozoneId(-1)}
                submit={() => {
                    onDeleteGeozone(removedGeozoneId);
                    setRemovedGeozoneId(-1);
                }}
                title={t.ManualGeofenceRemove}
                additionalStyles={{
                    maxWidth: "470px",
                }}
            />
        </Fragment>
    );
};

export default ManualGeofenceList;
