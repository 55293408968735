import React, { SyntheticEvent } from "react";
import DialogModal from "../../../MuiComponents/Modals/DialogModal";
import ModifiedParameterView from "./ModifiedParametersList/ModifiedParameterView";
import { ModifiedParametersFixedType } from "../../../../utils/types";

export interface SaveConfigurationModalProps {
    title: string;
    isOpen: boolean;
    onClose: () => void;
    primaryBtnText: string;
    onPrimaryBtnClick: (e: SyntheticEvent) => void;
    secondaryBtnText?: string;
    onSecondaryBtnClick?: (e: SyntheticEvent) => void;
    modifiedParameters: ModifiedParametersFixedType;
    isPrimaryButtonDisabled?: boolean;
    isSecondaryButtonDisabled?: boolean;
    hasNoInfoBanner?: boolean;
    isPrimaryButtonLoading?: boolean;
}

const SaveConfigurationModal = ({
    title,
    isOpen,
    onClose,
    primaryBtnText,
    onPrimaryBtnClick,
    secondaryBtnText,
    onSecondaryBtnClick,
    modifiedParameters,
    isPrimaryButtonDisabled,
    isSecondaryButtonDisabled,
    hasNoInfoBanner,
    isPrimaryButtonLoading,
}: SaveConfigurationModalProps) => {
    return (
        <DialogModal
            title={title}
            isOpen={isOpen}
            close={onClose}
            primaryButtonText={primaryBtnText}
            onPrimaryBtnClick={onPrimaryBtnClick}
            isPrimaryButtonDisabled={isPrimaryButtonDisabled}
            secondaryButtonText={secondaryBtnText}
            onSecondaryBtnClick={onSecondaryBtnClick}
            isSecondaryButtonDisabled={isSecondaryButtonDisabled}
            secondaryButtonDisabledTooltipText={
                !modifiedParameters.parameters.length
                    ? "No configuration changes were made"
                    : ""
            }
            withCancel
            description={
                <ModifiedParameterView
                    data={modifiedParameters}
                    hasNoInfoBanner={hasNoInfoBanner}
                />
            }
            isPrimaryButtonLoading={isPrimaryButtonLoading}
        />
    );
};

export default SaveConfigurationModal;
