/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import { Checkbox, ListItemIcon, ListItemText, MenuItem } from "@mui/material";
import { NameValueStringsPair } from "../../../../../../constants/constants";
import Select from "../../../../../MuiComponents/Select";

export interface FilterSelectProps {
    onChange: (value: any) => void;
    value: any;
    columns: NameValueStringsPair[];
    name: string;
}

const FilterSelect = ({
    onChange,
    value,
    columns,
    name,
}: FilterSelectProps) => {
    const isCheckboxChecked = (columnValue: string) =>
        value.includes(columnValue);

    return (
        <Select
            multiple
            value={value}
            onChange={onChange}
            wrapperStyle={css({ marginBottom: "12px" })}
            placeholder={name}
            renderValue={(selected: any) => {
                const renderNames = selected.map(
                    (item: string, index: number) => columns[index].name
                );
                return <div>{renderNames.join(", ")}</div>;
            }}
            disabled={false}
        >
            {columns.map((column: any) => (
                <MenuItem
                    key={column.name}
                    value={column.value}
                    css={{ padding: "14px 2px 14px 16px" }}
                >
                    <ListItemText
                        primary={column.name}
                        sx={{
                            ".MuiListItemText-primary": {
                                fontWeight: "600",
                                fontSize: "14px",
                            },
                        }}
                    />
                    <ListItemIcon>
                        <Checkbox checked={isCheckboxChecked(column.value)} />
                    </ListItemIcon>
                </MenuItem>
            ))}
        </Select>
    );
};

export default FilterSelect;
