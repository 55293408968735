import { createContext } from "react";
import { InitialState } from "./searchReducer";
import { SearchResult } from "../../generatedTypes";

interface SearchContextState extends InitialState {
    openModal: () => void;
    closeModal: () => void;
    setResults: (searchValue: string) => void;
    selectSearchResult: (result: SearchResult) => void;
    clearInput: () => void;
}

const SearchContext = createContext({} as SearchContextState);

export default SearchContext;
