import React from "react";
import SvgIcon from "@mui/material/SvgIcon";

const MobileNetworkIcon = (props: any) => {
    return (
        <SvgIcon {...props}>
            <path
                d="M18.5 4C19.33 4 20 4.67 20 5.5V18.5C20 19.33 19.33 20 18.5 20C17.67 20 17 19.33 17 18.5V5.5C17 4.67 17.67 4 18.5 4ZM6.5 14C7.33 14 8 14.67 8 15.5V18.5C8 19.33 7.33 20 6.5 20C5.67 20 5 19.33 5 18.5V15.5C5 14.67 5.67 14 6.5 14ZM12.5 9C13.33 9 14 9.67 14 10.5V18.5C14 19.33 13.33 20 12.5 20C11.67 20 11 19.33 11 18.5V10.5C11 9.67 11.67 9 12.5 9Z"
                fill="currentColor"
            />
        </SvgIcon>
    );
};

export default MobileNetworkIcon;
