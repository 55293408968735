/** @jsxRuntime classic */
/** @jsx jsx */
import React, { useContext } from "react";
import ThemeContext from "../../../context/theme/themeContext";
import LayoutContext from "../../../context/layout/layoutContext";
import { useHistory } from "react-router-dom";
import { START_UP } from "../../../constants/routes";
import { css, jsx } from "@emotion/react";
import ColoredCircles from "../ColoredCircles";
import Lottie from "lottie-react";
import errorAnimation from "../../../assets/animations/error_animation.json";
import { ReactComponent as ErrorPageIcon } from "../../../assets/icons/ErrorPageIcon.svg";
import Button from "../Button";

export interface ErrorSectionProps {
    title: string;
    description: string;
    withoutButton?: boolean;
}

const ErrorSection: React.FunctionComponent<ErrorSectionProps> = ({
    title,
    description,
    withoutButton,
}) => {
    const {
        colors: { textDarkSecondary, textDark, gray200 },
    } = useContext(ThemeContext);

    const { resetLayoutData } = useContext(LayoutContext);

    const history = useHistory();

    const redirectToStartUp = () => {
        history.replace(START_UP);
        resetLayoutData();
    };

    return (
        <div
            css={css({
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                paddingTop: "240px",
                paddingBottom: "180px",
            })}
        >
            <div
                css={css({
                    position: "relative",
                    display: "flex",
                    width: "625px",
                    height: "416px",
                    justifyContent: "center",
                    alignItems: "center",
                    boxSizing: "border-box",
                    border: `1px solid ${gray200}`,

                    "@media (max-width: 599px)": {
                        width: "328px",
                        height: "218px",
                    },
                })}
            >
                <ColoredCircles />
                <ErrorPageIcon
                    css={css({
                        "@media (max-width: 599px)": {
                            maxWidth: "246px",
                            width: "100%",
                            position: "absolute",
                            right: "10%",
                        },
                    })}
                />
                <Lottie
                    animationData={errorAnimation}
                    css={css({
                        width: "75%",
                        position: "absolute",
                        right: "10%",
                        top: "15%",

                        "@media (max-width: 599px)": {
                            width: "75%",
                            position: "absolute",
                            right: "10%",
                        },
                    })}
                />
            </div>
            <h1
                css={css({
                    color: textDark,
                    textAlign: "center",
                    fontFeatureSettings: "'clig' off, 'liga' off",
                    fontFamily: '"Open Sans"',
                    fontSize: "32px",
                    fontStyle: "normal",
                    fontWeight: 600,
                    lineHeight: "40px", // 125%
                    letterSpacing: "0.25px",
                    marginTop: "30px",
                })}
            >
                {title}
            </h1>
            <div
                css={css({
                    color: textDarkSecondary,
                    textAlign: "center",
                    fontFeatureSettings: "'clig' off, 'liga' off",
                    fontFamily: '"Open Sans"',
                    fontSize: "16px",
                    fontStyle: "normal",
                    fontWeight: 400,
                    lineHeight: "20px", // 125%
                    letterSpacing: "0.15px",
                    maxWidth: "580px",
                    margin: "16px 0px",
                })}
            >
                {description}
            </div>
            {!withoutButton && (
                <Button
                    variant="textOnly"
                    size="normal"
                    color="primary"
                    onClick={redirectToStartUp}
                    idForTesting="errorPageGoBackButton"
                >
                    Take me back
                </Button>
            )}
        </div>
    );
};

export default ErrorSection;
