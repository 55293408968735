/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from "@emotion/react";

import { useContext } from "react";
import LanguageContext from "../../../../../context/language/languageContext";
import ConfigurationContext from "../../../../../context/configuration/configurationContext";
import useMediaQueries from "../../../../../utils/useMediaQueries";
import Select from "../../../../MuiComponents/Select";
import MenuItem from "@mui/material/MenuItem";
import { SelectChangeEvent } from "@mui/material";

export interface FilterProps {
    changePage: (event: any, newPage: number) => void;
}

const Filter = ({ changePage }: FilterProps) => {
    const { t } = useContext(LanguageContext);

    const { filteredValues, technologies, filterConfigurations } =
        useContext(ConfigurationContext);

    const { toXl, toMd } = useMediaQueries();

    const handleChange = (e: SelectChangeEvent<any>) => {
        const value = e.target.value;

        filterConfigurations(
            typeof value === "string" ? value.split(",") : value
        );

        changePage(e, 0);
    };

    const setWidth = () => {
        if (toMd) {
            return "unset";
        }

        return toXl ? "180px" : "212px";
    };

    return (
        <Select
            multiple
            value={filteredValues}
            placeholder={t.AllTechnologies}
            onChange={handleChange}
            MenuProps={{
                disablePortal: true,
                PaperProps: {
                    style: {
                        width: setWidth(),
                    },
                },
            }}
            wrapperStyle={css`
                margin: 0 24px;
                width: 212px;

                @media (max-width: 1439px) {
                    width: 180px;
                }

                @media (max-width: 839px) {
                    flex: 1;
                    margin: 0 8px 0 0;
                }
            `}
        >
            {technologies.map((technologyData) => (
                <MenuItem
                    key={technologyData.technology}
                    value={technologyData.technology}
                >
                    {technologyData.technology}
                </MenuItem>
            ))}
        </Select>
    );
};

export default Filter;
