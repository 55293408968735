/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import { useContext } from "react";
import ThemeContext from "../../../../../context/theme/themeContext";
import { ExpandMore as ExpandMoreIcon } from "@mui/icons-material";

export interface VersionInfoItemProps {
    item: {
        icon: any;
        title: string;
        onClick: () => void;
    };
}

const VersionInfoItem = ({ item }: VersionInfoItemProps) => {
    const { icon, title, onClick } = item;
    const {
        colors: { gray100 },
    } = useContext(ThemeContext);
    return (
        <div
            css={css({
                display: "flex",
                padding: "14px 34px 14px 26px",
                alignItems: "center",
                position: "relative",
                ":hover": {
                    backgroundColor: gray100,
                    cursor: "pointer",
                },
            })}
            onClick={onClick}
        >
            {!!icon && icon}
            <div
                css={css({
                    display: "flex",
                    alignItems: "center",
                    marginLeft: "9px",
                    fontWeight: 600,
                })}
            >
                {title}
            </div>
            <div
                css={css({
                    transform: "rotate(-90deg)",
                    position: "absolute",
                    right: "26px",
                })}
            >
                <ExpandMoreIcon />
            </div>
        </div>
    );
};

export default VersionInfoItem;
