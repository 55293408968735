import React, { useReducer } from "react";

import {
    SET_ACTIVE_TAB_ID,
    SET_IS_OPEN_CONFIRMATION_MODAL,
    SET_DEVICES,
    SET_PORT_LIST_ANCHOR_EL,
    SET_SINGLE_PORT_LIST_ANCHOR_EL,
    SET_TERMINAL_SIZE,
    SET_ACTIVE_PORTS_FOR_DROPDOWN,
    SET_ACTIVE_TERMINAL_PORT,
    REMOVE_ACTIVE_TERMINAL_PORT,
    SET_REMOVE_PORT_MODAL_INFO,
    REMOVE_ALL_ACTIVE_TERMINAL_PORTS,
    SET_PORT_MESSAGES,
    SET_PORT_KEEP_FOLDER,
    SET_PORT_IS_LOGGING,
    SET_COMMAND,
    SET_COMMAND_HISTORY_INDEX,
    SET_COMMAND_HISTORY,
    CLEAR_PORT_MESSAGES_BY_PORT_ID,
} from "./terminalActions";

import TerminalReducer from "./terminalReducer";
import TerminalContext from "./terminalContext";
import useWebSockets from "../../../utils/useWebSockets";

interface TerminalStateProps {
    children: React.ReactNode;
}

const TerminalState = ({ children }: TerminalStateProps) => {
    const previousCommandsFromLocalStorage =
        localStorage.getItem("commandHistory");

    const initialState = {
        activeTabId: "",
        isOpenConfirmationModal: false,
        terminalSize: {
            width: window.innerWidth,
            height: window.innerHeight / 2,
        },
        portListAnchorEl: null,
        singlePortListAnchorEl: null,
        activePortsForDropdown: [],
        activeTerminalPorts: [],
        removePortModalInfo: {
            isOpen: false,
            portName: null,
        },
        portMessages: [],
        command: "",
        commandHistory: previousCommandsFromLocalStorage
            ? JSON.parse(previousCommandsFromLocalStorage)
            : [],
        commandHistoryIndex: -1,
    };

    const [state, dispatch] = useReducer(TerminalReducer, initialState);

    const setActiveTabId = (activeTabId: string) => {
        dispatch({
            type: SET_ACTIVE_TAB_ID,
            payload: activeTabId,
        });
    };

    const setIsOpenConfirmationModal = (isOpen: boolean) => {
        dispatch({
            type: SET_IS_OPEN_CONFIRMATION_MODAL,
            payload: isOpen,
        });
    };

    const setTerminalSize = (data: { width: number; height: number }) => {
        dispatch({
            type: SET_TERMINAL_SIZE,
            payload: data,
        });
    };

    const setPortListAnchorEl = (
        anchorEl: (EventTarget & HTMLDivElement) | null
    ) => {
        dispatch({
            type: SET_PORT_LIST_ANCHOR_EL,
            payload: anchorEl,
        });
    };

    const setSinglePortListAnchorEl = (
        anchorEl: (EventTarget & HTMLDivElement) | null
    ) => {
        dispatch({
            type: SET_SINGLE_PORT_LIST_ANCHOR_EL,
            payload: anchorEl,
        });
    };

    const setDevices = (ports: any) => {
        dispatch({
            type: SET_DEVICES,
            payload: ports,
        });
    };

    const setActivePortsForDropdown = (ports: any) => {
        dispatch({
            type: SET_ACTIVE_PORTS_FOR_DROPDOWN,
            payload: ports,
        });
    };

    const setActiveTerminalPort = (port: any) => {
        setActiveTabId(port.id);
        dispatch({
            type: SET_ACTIVE_TERMINAL_PORT,
            payload: port,
        });
    };

    const removeActiveTerminalPort = (port: any) => {
        dispatch({
            type: REMOVE_ACTIVE_TERMINAL_PORT,
            payload: port,
        });
    };

    const setRemovePortModalInfo = (data: any) => {
        dispatch({
            type: SET_REMOVE_PORT_MODAL_INFO,
            payload: data,
        });
    };

    const removeAllActiveTerminalPorts = () => {
        dispatch({
            type: REMOVE_ALL_ACTIVE_TERMINAL_PORTS,
        });
    };

    const setPortMessages = (data: any) => {
        dispatch({
            type: SET_PORT_MESSAGES,
            payload: data,
        });
    };

    const setPortKeepFolder = (logPath: string[] | null, id: string) => {
        dispatch({
            type: SET_PORT_KEEP_FOLDER,
            payload: {
                logPath,
                id,
            },
        });
    };

    const setPortIsLogging = (isLogging: boolean, id: string) => {
        dispatch({
            type: SET_PORT_IS_LOGGING,
            payload: {
                isLogging,
                id,
            },
        });
    };

    const setCommand = (command: string) => {
        dispatch({
            type: SET_COMMAND,
            payload: command,
        });
    };

    const setCommandHistory = (command: string) => {
        if (command === state.commandHistory[0]) {
            return;
        }
        const commandHistory = [command, ...state.commandHistory];
        const maxLength = 30;
        const commandHistoryWithLimit = [...commandHistory].slice(0, maxLength);
        localStorage.setItem(
            "commandHistory",
            JSON.stringify(commandHistoryWithLimit)
        );
        dispatch({
            type: SET_COMMAND_HISTORY,
            payload: commandHistoryWithLimit,
        });
    };

    const setCommandHistoryIndex = (index: number) => {
        dispatch({
            type: SET_COMMAND_HISTORY_INDEX,
            payload: index,
        });
    };

    const clearPortMessagesByPort = (port: string) => {
        dispatch({
            type: CLEAR_PORT_MESSAGES_BY_PORT_ID,
            payload: port,
        });
    };

    return (
        <TerminalContext.Provider
            value={{
                activeTab: state.activeTerminalPorts.find(
                    (item: any) => item.id === state.activeTabId
                ),
                isOpenConfirmationModal: state.isOpenConfirmationModal,
                setActiveTabId,
                setIsOpenConfirmationModal,
                setTerminalSize,
                terminalSize: state.terminalSize,
                setCommand,
                command: state.command,
                setPortListAnchorEl,
                portListAnchorEl: state.portListAnchorEl,
                setDevices,
                devices: state.ports,
                setSinglePortListAnchorEl,
                singlePortAnchorEl: state.singlePortAnchorEl,
                setActivePortsForDropdown,
                activePortsForDropdown: state.activePortsForDropdown,
                setActiveTerminalPort,
                activeTerminalPorts: state.activeTerminalPorts,
                removeActiveTerminalPort,
                setRemovePortModalInfo,
                removePortModalInfo: state.removePortModalInfo,
                removeAllActiveTerminalPorts,
                portMessages: state.portMessages,
                isTerminalLoggingStarted: state.isTerminalLoggingStarted,
                setPortMessages,
                setPortKeepFolder,
                setPortIsLogging,
                setCommandHistory,
                commandHistory: state.commandHistory,
                commandHistoryIndex: state.commandHistoryIndex,
                setCommandHistoryIndex,
                clearPortMessagesByPort,
            }}
        >
            {children}
        </TerminalContext.Provider>
    );
};

export default TerminalState;
