import useMediaQuery from "@mui/material/useMediaQuery";

const useMediaQueries = () => {
    const toXl: boolean = useMediaQuery("(max-width:1439px)", { noSsr: true });
    const toMd: boolean = useMediaQuery("(max-width:839px)", { noSsr: true });
    const toSm: boolean = useMediaQuery("(max-width:599px)", { noSsr: true });
    const fromXl: boolean = useMediaQuery("(min-width:1440px)", {
        noSsr: true,
    });
    const fromMd: boolean = useMediaQuery("(min-width:840px)", { noSsr: true });
    const fromSm: boolean = useMediaQuery("(min-width:600px)", { noSsr: true });
    const fromLg: boolean = useMediaQuery("(min-width:1024px)", {
        noSsr: true,
    });
    const toLg: boolean = useMediaQuery("(max-width:1024px)", { noSsr: true });
    return {
        toXl,
        toMd,
        toSm,
        fromXl,
        fromMd,
        fromSm,
        fromLg,
        toLg,
    };
};

export default useMediaQueries;
