/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from "@emotion/react";

import { useContext, useEffect } from "react";
import ThemeContext from "../../../../../context/theme/themeContext";
import LanguageContext from "../../../../../context/language/languageContext";
import ConfigurationContext from "../../../../../context/configuration/configurationContext";
import useMediaQueries from "../../../../../utils/useMediaQueries";
import Button from "../../../../MuiComponents/Button";
import InputField from "../../../../MuiComponents/InputField";
import SearchIcon from "@mui/icons-material/SearchRounded";
import DeleteCircleIcon from "../../../../../assets/icons/DeleteCircleIcon";

export interface SearchProps {
    renderOnMobile: boolean;
    expandOnMobile: () => void;
    shrinkOnMobile: () => void;
    changePage: (event: any, newPage: number) => void;
}

const Search = ({
    renderOnMobile,
    expandOnMobile,
    shrinkOnMobile,
    changePage,
}: SearchProps) => {
    const {
        colors: { textPlaceholder, gray600 },
    } = useContext(ThemeContext);

    const { t } = useContext(LanguageContext);

    const { searchConfigurations, searchValue } =
        useContext(ConfigurationContext);

    const { toSm } = useMediaQueries();

    useEffect(() => {
        if (toSm) {
            searchValue.trim() !== "" && expandOnMobile();
        } else {
            shrinkOnMobile();
        }
        // eslint-disable-next-line
    }, [toSm]);

    const handleChange = (e: any) => {
        searchConfigurations(e.target.value);
        changePage(e, 0);
    };

    const handleDeleteClick = (e: any) => {
        toSm && shrinkOnMobile();

        searchConfigurations("");
        changePage(e, 0);
    };

    const showDeleteBtn = toSm ? true : searchValue.length > 0;

    return toSm && !renderOnMobile ? (
        <Button
            variant="iconOnly"
            icon={<SearchIcon />}
            size="small"
            color="white"
            onClick={expandOnMobile}
            idForTesting="search-btn"
        />
    ) : (
        <InputField
            wrapperStyle={css`
                @media (max-width: 839px) {
                    flex: 1;
                }
            `}
            autoFocus={toSm}
            size="small"
            iconLeft={<SearchIcon />}
            iconRight={
                showDeleteBtn ? (
                    <DeleteCircleIcon
                        onClick={handleDeleteClick}
                        css={css`
                            cursor: pointer;
                            &:hover {
                                color: ${gray600};
                            }
                        `}
                    />
                ) : null
            }
            placeholder={`${t.Search}...`}
            value={searchValue}
            onChange={handleChange}
            css={css`
                width: 188px;

                svg {
                    color: ${textPlaceholder};
                }

                @media (max-width: 1439px) {
                    width: 180px;
                }

                @media (max-width: 839px) {
                    width: 100%;
                }
            `}
        />
    );
};

export default Search;
