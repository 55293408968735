/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import { MenuItem } from "@mui/material";
import { Fragment, useContext, useEffect, useState } from "react";

import FormModal from "../../../../MuiComponents/Modals/FormModal";
import Select from "../../../../MuiComponents/Select";
import useApi from "../../../../../utils/useApi";
import LanguageContext from "../../../../../context/language/languageContext";
import AlertContext from "../../../../../context/alert/alertContext";
import { AlertStatus } from "../../../../../constants/constants";

export interface CanConfigurationModalProps {
    isOpen: boolean;
    onClose: any;
}

const CanConfigurationModal = ({
    isOpen,
    onClose,
}: CanConfigurationModalProps) => {
    const { selectedLanguage, t } = useContext(LanguageContext);
    const { setAlert } = useContext(AlertContext);
    const { postData, getData, showErrorMsg } = useApi();
    const [results, setResults] = useState<any>([]);
    const [make, setMake] = useState<any>("");
    const [model, setModel] = useState<any>("");
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        const getSelectData = async () => {
            try {
                const { data } = await getData(
                    `${selectedLanguage}/fota/vehicles`
                );
                setResults(data);
            } catch (error) {
                showErrorMsg(error as any, t.ErrorUnexpected);
            }
        };
        getSelectData();
    }, []);

    if (!results.length) {
        return null;
    }
    const makesData = results.map((item: any) => {
        return {
            name: item.make,
            isDisabled: false,
        };
    });

    const modelData = results
        .find((item: any) => item.make === make)
        ?.models.map((item: { model: string; vehicleId: number }) => {
            return {
                name: item.model,
                value: item.vehicleId,
                isDisabled: false,
            };
        });

    const handleSubmit = async () => {
        try {
            setIsLoading(true);
            await postData(`${selectedLanguage}/device/canConfig/file`, model);
            setIsLoading(false);
            onClose();
            setAlert(AlertStatus.Success, t.CanUploadSuccessfully);
        } catch (err) {
            setIsLoading(false);
            showErrorMsg(err as any, t.ErrorUnexpected);
        }
    };

    return (
        <Fragment>
            <FormModal
                isOpen={isOpen}
                onClose={onClose}
                title={t.CanModalTitle}
                description={t.CanModalDescription}
                primaryButtonText={t.CanUpload}
                secondaryButtonText={t.CanClose}
                onSubmit={handleSubmit}
                isPrimaryButtonDisabled={!model}
                isSubmitting={isLoading}
            >
                <Select
                    value={make}
                    wrapperStyle={css({ marginBottom: "12px" })}
                    placeholder={t.CanSelect}
                    disabled={false}
                    label={t.CanMake}
                >
                    {makesData.map((item: any) => {
                        return (
                            <MenuItem
                                key={item.name}
                                value={item.name}
                                onClick={() => {
                                    setMake(item.name);
                                    setModel("");
                                }}
                                disabled={item.isDisabled}
                            >
                                {item.name}
                            </MenuItem>
                        );
                    })}
                </Select>
                <Select
                    value={model}
                    wrapperStyle={css({ marginBottom: "12px" })}
                    placeholder={t.CanSelect}
                    disabled={!modelData}
                    label={t.CanModel}
                >
                    {modelData?.map((item: any) => {
                        return (
                            <MenuItem
                                key={item.name}
                                value={item.value}
                                onClick={() => setModel(item.value)}
                                disabled={item.isDisabled}
                            >
                                {item.name}
                            </MenuItem>
                        );
                    })}
                </Select>
            </FormModal>
        </Fragment>
    );
};

export default CanConfigurationModal;
