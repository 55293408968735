/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from "@emotion/react";

import { useContext } from "react";
import ThemeContext from "../../../../context/theme/themeContext";
import useMediaQueries from "../../../../utils/useMediaQueries";
import Box from "../../../Box";
import MobileSkeleton from "./MobileSkeleton";
import DesktopSkeleton from "./DesktopSkeleton";
import Skeleton from "@mui/material/Skeleton";

const TableSkeleton = () => {
    const {
        colors: { gray100 },
    } = useContext(ThemeContext);

    const { toXl, toMd } = useMediaQueries();

    const rows = 10;

    return (
        <Box
            css={css`
                margin-top: 24px;

                @media (max-width: 839px) {
                    background: unset;
                    box-shadow: none;
                }
            `}
        >
            <div
                css={css`
                    padding: 16px 24px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;

                    @media (max-width: 839px) {
                        display: block;
                        padding: 16px 0;
                    }
                `}
            >
                <Skeleton width={207} height={24} />

                <div
                    css={css`
                        display: flex;

                        span {
                            height: 36px;
                            background: ${gray100};
                        }

                        span + span {
                            margin-left: 24px;
                        }

                        span:nth-of-type(1) {
                            width: 90px;
                        }

                        span:nth-of-type(2) {
                            width: 115px;
                        }

                        span:nth-of-type(3) {
                            width: 212px;
                        }

                        span:nth-of-type(4) {
                            width: 188px;
                        }

                        @media (max-width: 1439px) {
                            span:nth-of-type(1) {
                                width: 36px;
                            }

                            span:nth-of-type(2),
                            span:nth-of-type(3) {
                                width: 180px;
                            }
                        }

                        @media (max-width: 839px) {
                            margin-top: 15px;

                            span + span {
                                margin-left: 8px;
                            }

                            span:nth-of-type(1),
                            span:nth-of-type(2) {
                                width: unset;
                                flex: 1;
                            }

                            span:nth-of-type(3) {
                                width: 36px;
                            }
                        }

                        @media (max-width: 599px) {
                            span:nth-of-type(2) {
                                width: 36px;
                                flex: none;
                            }
                        }
                    `}
                >
                    {!toXl && <Skeleton />}
                    <Skeleton />
                    <Skeleton />
                    <Skeleton />
                </div>
            </div>

            {toMd ? (
                <MobileSkeleton cards={rows} />
            ) : (
                <DesktopSkeleton rows={rows} />
            )}
        </Box>
    );
};

export default TableSkeleton;
