import {
    SET_ACTIVE_FRAME,
    SET_BREADCRUMB_ITEMS,
    SET_DISABLED_PARAMETERS,
    SET_ERROR,
    SET_ERRORS,
    SET_FRAMES,
    SET_PARAMETER,
    SET_PARAMETERS,
    SET_MANUAL_GEOFENCE_PARAMETERS,
    RESET_PARAMETERS,
} from "./menuItemActions";

export default (state: any, action: any) => {
    switch (action.type) {
        case SET_FRAMES: {
            return {
                ...state,
                frames: action.payload.allData,
                parameters: action.payload.allParameters,
                errors: {},
            };
        }
        //Rename as this is currently used for manual geofences
        case SET_MANUAL_GEOFENCE_PARAMETERS: {
            return {
                ...state,
                parameters: {
                    ...state.parameters,
                    ...action.payload,
                },
                errors: {},
            };
        }
        case SET_PARAMETERS: {
            const allData = [...action.payload];
            const formatedArrayData: any = allData.forEach((item) => {
                state.parameters[item.id] = item.value;
            });
            return {
                ...state,
                parameters: {
                    ...state.parameters,
                    ...formatedArrayData,
                },
                errors: {},
            };
        }
        case RESET_PARAMETERS: {
            const parameterIds: number[] = action.payload;
            let newParameters = { ...state.parameters };
            parameterIds.forEach((id) => {
                let { [id]: _, ...rest } = newParameters;
                newParameters = rest;
            });
            return {
                ...state,
                parameters: newParameters,
                errors: {},
            };
        }
        case SET_BREADCRUMB_ITEMS: {
            const allData = action.payload;
            return {
                ...state,
                breadcrumbItems: allData,
            };
        }
        case SET_ACTIVE_FRAME: {
            return {
                ...state,
                activeFrame: action.payload.allData,
                parameters: {
                    ...state.parameters,
                    ...action.payload.allParameters,
                },
                errors: {},
            };
        }
        case SET_PARAMETER: {
            const { id, value } = action.payload;

            return {
                ...state,
                parameters: {
                    ...state.parameters,
                    [id]: value,
                },
            };
        }
        case SET_ERROR: {
            const { id, error } = action.payload;

            return {
                ...state,
                errors: {
                    ...state.errors,
                    [id]: error,
                },
            };
        }
        case SET_ERRORS: {
            const errorArray = action.payload;

            return {
                ...state,
                errors: {
                    ...state.errors,
                    ...errorArray,
                },
            };
        }
        case SET_DISABLED_PARAMETERS: {
            const previousDisabledParams: any[] = [];
            const otherDisabledParams = [];
            for (let i = 0; i < state.disabledParameters?.length ?? 0; i++) {
                if (
                    action.payload.some(
                        ({ parameterId }: { parameterId: string }) =>
                            parameterId ===
                            state.disabledParameters[i].parameterId
                    )
                ) {
                    previousDisabledParams.push(state.disabledParameters[i]);
                } else {
                    otherDisabledParams.push(state.disabledParameters[i]);
                }
            }

            const mappedDisabledParams = action.payload.map((item: any) => {
                const previousParam = previousDisabledParams.find(
                    (previousItem) =>
                        previousItem.parameterId === item.parameterId
                );
                if (previousParam) {
                    item.collection = {
                        ...previousParam.collection,
                        ...item.collection,
                    };
                }
                return {
                    parameterId: item.parameterId,
                    isDisabled: item.isDisabled,
                    collection: item.collection,
                };
            });

            return {
                ...state,
                disabledParameters: [
                    ...otherDisabledParams,
                    ...mappedDisabledParams,
                ],
            };
        }
        default:
            return state;
    }
};
