/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from "@emotion/react";

import { useContext } from "react";
import ThemeContext from "../../../../context/theme/themeContext";
import LanguageContext from "../../../../context/language/languageContext";
import Box from "../../../Box";
import { ReactComponent as OfflineStatusIcon } from "../../../../assets/icons/OfflineStatusIcon.svg";
import SettingsContext from "../../../../context/settings/settingsContext";

export interface SingleOfflineCardProps {
    title: string;
    description?: string;
    subtitle: string;
}

const SingleOfflineCard = ({
    title,
    description,
    subtitle,
}: SingleOfflineCardProps) => {
    const {
        colors: { textDark, gray700 },
    } = useContext(ThemeContext);
    const { isExplanatoryTextsHidden } = useContext(SettingsContext);
    const { t } = useContext(LanguageContext);

    return (
        <Box
            css={css`
                padding: 0 24px;
            `}
        >
            <div
                css={css`
                    display: flex;
                    justify-content: space-between;
                `}
            >
                <div
                    css={css`
                        padding: 16px 0;
                    `}
                >
                    <div
                        css={css`
                            font-size: 20px;
                            line-height: 24px;
                            font-weight: 600;
                            letter-spacing: 0.15px;
                            margin-bottom: ${description ? "8px" : "0"};
                        `}
                    >
                        {title}
                    </div>

                    {description && !isExplanatoryTextsHidden && (
                        <div
                            css={css`
                                font-size: 14px;
                                line-height: 20px;
                                letter-spacing: 0.1px;
                            `}
                        >
                            {description}
                        </div>
                    )}
                </div>
            </div>

            <div
                css={css`
                    padding-top: 16px;
                    padding-bottom: 32px;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                `}
            >
                <OfflineStatusIcon />

                <div
                    css={css`
                        color: ${textDark};
                        font-size: 16px;
                        line-height: 20px;
                        font-weight: 600;
                        letter-spacing: 0.15px;
                        margin: 16px 0 8px 0;
                    `}
                >
                    {subtitle}
                </div>

                <div
                    css={css`
                        color: ${gray700};
                        font-size: 14px;
                        line-height: 20px;
                        letter-spacing: 0.1px;
                    `}
                >
                    {t.DeviceNeedsConnection}
                </div>
            </div>
        </Box>
    );
};

export default SingleOfflineCard;
