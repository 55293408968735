/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import { Collapse } from "@mui/material";
import { Fragment, useContext, useEffect, useRef, useState } from "react";
import SettingsContext from "../../../../../context/settings/settingsContext";
import ThemeContext from "../../../../../context/theme/themeContext";
import { Frame } from "../../../../../generatedTypes";
import useMediaQueries from "../../../../../utils/useMediaQueries";
import TypedGrid from "../../../../MuiComponents/TypedGrid";
import Block from "../../Block";
import SwitchToggle from "../../Segment/Component/SwitchToggle";
import AccordionFrameEmptyState from "./AccordionFrameEmptyState";
import MenuItemContext from "../../../../../context/menuItem/menuItemContext";
import Divider from "../../../../MuiComponents/Divider";

export interface AccordionFrameProps {
    data: Frame;
}

const AccordionFrame = ({ data }: AccordionFrameProps) => {
    const {
        name,
        description,
        switchId,
        switchValue,
        switchDefaultValue,
        avlId,
        blocks,
        orientation,
        switchOffValue,
    } = data;

    const {
        colors: { textDark },
    } = useContext(ThemeContext);
    const { isExplanatoryTextsHidden } = useContext(SettingsContext);

    const { fromSm } = useMediaQueries();

    const switchRef = useRef<HTMLInputElement>(null);

    const { findParameterValueById } = useContext(MenuItemContext);

    const switchParameterValue = findParameterValueById(switchId);

    const isTurnedOn =
        switchId === 0 || switchParameterValue !== switchOffValue;

    const [switchedOn, setSwitchedOn] = useState(isTurnedOn);

    useEffect(() => setSwitchedOn(isTurnedOn), [switchParameterValue]);

    const handleSwitch = (state: boolean) => setSwitchedOn(state);

    const renderAccordionFrame = () => {
        if (switchedOn && !!blocks?.length) {
            return (
                <Fragment>
                    <Divider hasLighterGray />

                    <TypedGrid
                        container
                        spacing={3}
                        css={css`
                            padding: 16px 24px 24px;
                        `}
                    >
                        {blocks.map((block) => (
                            <TypedGrid
                                key={block.index}
                                item
                                xs0={12}
                                xl={orientation === "Vertical" ? 12 : 6}
                            >
                                <Block data={block} />
                            </TypedGrid>
                        ))}
                    </TypedGrid>
                </Fragment>
            );
        } else if (!switchedOn) {
            return (
                <AccordionFrameEmptyState
                    name={name || ""}
                    switchRef={switchRef}
                />
            );
        } else {
            return null;
        }
    };

    return (
        <Fragment>
            <div
                css={css`
                    padding: 24px 24px 16px 24px;
                    color: ${textDark};

                    & > p {
                        font-size: 14px;
                        font-weight: 400;
                        line-height: 20px;
                        letter-spacing: 0.1px;
                        margin-top: ${fromSm ? "6px" : "12px"};
                    }
                `}
            >
                <div
                    css={css`
                        display: flex;
                        justify-content: space-between;
                        align-items: center;

                        & > h4 {
                            font-size: 20px;
                            font-weight: 600;
                            line-height: 24px;
                            letter-spacing: 0.15px;
                        }
                    `}
                >
                    <h4>{name}</h4>

                    {switchValue && (
                        <SwitchToggle
                            ref={switchRef}
                            id={switchId}
                            avlId={avlId}
                            defaulValue={switchDefaultValue || ""}
                            switchedOn={switchedOn}
                            defaultOffValue={switchOffValue}
                            label={name || ""}
                            elementRef={switchRef}
                            toggle={handleSwitch}
                            dependentParameters={
                                data.switchComponent?.dependentParameterIds ||
                                null
                            }
                            wrapperStyle={css`
                                margin-left: ${fromSm ? "12px" : "24px"};
                                padding: 2px 0;
                                align-self: flex-start;
                            `}
                        />
                    )}
                </div>

                {description && !isExplanatoryTextsHidden && (
                    <p>{description}</p>
                )}
            </div>

            <Collapse in={true}>{renderAccordionFrame()}</Collapse>
        </Fragment>
    );
};

export default AccordionFrame;
