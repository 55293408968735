import { createContext } from "react";

export interface AccessoriesContextState {
    setFuelValue: (payload: {
        fuelValue: number | undefined;
        llsValue: string;
        id: number;
    }) => void;
    setFuelValues: (payload: any[]) => void;
    resetFuelValues: () => void;
    fuelValues: {
        fuelValue: number | undefined;
        llsValue: string;
        id: number;
    }[];
}

const AccessoriesContext = createContext({} as AccessoriesContextState);

export default AccessoriesContext;
