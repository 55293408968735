/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from "@emotion/react";

import { useContext } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import ThemeContext from "../../../context/theme/themeContext";
import LayoutContext from "../../../context/layout/layoutContext";
import SearchContext from "../../../context/search/searchContext";
import Search from "../Search";
import SearchInput from "../Search/SearchInput";
import { ReactComponent as OnlineStatusIcon } from "../../../assets/OnlineStatus.svg";
import { ReactComponent as OfflineStatusIcon } from "../../../assets/OfflineStatus.svg";
import Button from "../../MuiComponents/Button";
import NavigationListButton from "../../MuiComponents/NavigationListButton";
import BurgerIcon from "@mui/icons-material/MenuRounded";
import CloseIcon from "@mui/icons-material/CloseRounded";
import { EDITOR_STATUS } from "../../../constants/routes";
import UserInfoContext from "../../../context/userInfo/userInfoContext";
import { noop } from "../../../utils/helpers";
import { TopBarProps } from "../../../utils/types";

export interface MobileTopBarInterface extends TopBarProps {
    isOpenMenu: boolean;
    toggleMenu: () => void;
}

const MobileTopBar = ({
    isOpenMenu,
    toggleMenu,
    disabled,
}: MobileTopBarInterface) => {
    const { isDesktopApplication, isDeviceDisconnected } =
        useContext(UserInfoContext);
    const {
        colors: { blueSideNavigation },
    } = useContext(ThemeContext);

    const { layoutData, actionLoading } = useContext(LayoutContext);
    const { isMobileSearchInput } = useContext(SearchContext);

    const history = useHistory();
    const { path } = useRouteMatch();

    const statusIcon =
        layoutData?.isOnline && !isDeviceDisconnected ? (
            <OnlineStatusIcon />
        ) : (
            <OfflineStatusIcon />
        );

    return (
        <header
            css={css`
                padding: 8px 16px;
                background: ${blueSideNavigation};
                display: flex;
                align-items: center;
            `}
        >
            {isMobileSearchInput ? (
                <SearchInput filled />
            ) : (
                <NavigationListButton
                    idForTest={"mobile-top-bar-status-button"}
                    isActive={path === EDITOR_STATUS}
                    icon={statusIcon}
                    disabled={actionLoading || !isDesktopApplication}
                    onClick={
                        !isDesktopApplication
                            ? noop
                            : () => history.push(EDITOR_STATUS)
                    }
                    css={css`
                        flex: 1;
                    `}
                >
                    {layoutData?.fmType}
                </NavigationListButton>
            )}

            <Search
                isOpenMenu={isOpenMenu}
                toggleMenu={toggleMenu}
                disabled={disabled}
            />

            <Button
                variant="iconOnly"
                size="small"
                color="transparent"
                icon={isOpenMenu ? <CloseIcon /> : <BurgerIcon />}
                disabled={actionLoading}
                onClick={toggleMenu}
                idForTesting="mobile-top-bar-menu-button"
            />
        </header>
    );
};

export default MobileTopBar;
