/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import { Slider } from "@mui/material";
import React, { useContext, useState } from "react";
import MenuItemContext from "../../../context/menuItem/menuItemContext";
import useDebounce from "../../../utils/useDebounce";
import InputField from "../InputField";
import ThemeContext from "../../../context/theme/themeContext";

export interface RangeSliderProps {
    data: any;
}

const RangeSlider: React.FunctionComponent<RangeSliderProps> = ({ data }) => {
    const { name, thumbs } = data;
    const firstThumb = thumbs[0]?.component;
    const secondThumb = thumbs[1]?.component;
    const convertFirstThumbValueToPositive = Math.abs(
        firstThumb?.parameterValue || firstThumb?.default
    );
    const convertSecondThumbValueToPositive = Math.abs(
        secondThumb?.parameterValue || secondThumb?.default
    );
    const [min, setMinValue] = useState(convertFirstThumbValueToPositive);
    const [max, setMaxValue] = useState(convertSecondThumbValueToPositive);
    const [minError, setMinError] = useState<boolean>(false);
    const [maxError, setMaxError] = useState<boolean>(false);

    const marks = [
        {
            value: 0,
            label: 0,
        },
        {
            value: 10,
            label: -10,
        },
        {
            value: 20,
            label: -20,
        },
        {
            value: 30,
            label: -30,
        },
        {
            value: 40,
            label: -40,
        },
        {
            value: 50,
            label: -50,
        },
        {
            value: 60,
            label: -60,
        },
        {
            value: 70,
            label: -70,
        },
        {
            value: 80,
            label: -80,
        },
        {
            value: 90,
            label: -90,
        },
        {
            value: 100,
            label: -100,
        },
    ];
    const { updateParameter, setParameterError } = useContext(MenuItemContext);
    const {
        colors: {
            textPlaceholder,
            textDark,
            blue800,
            gray300,
            gray700,
            blue1000,
            orange300,
            sliderGreen,
        },
    } = useContext(ThemeContext);

    const debouncedChangeHandler = useDebounce(updateParameter, 500);

    const onSuccessfulRequest = (id: string) => {
        setParameterError(id, false);
        if (id === firstThumb?.parameterId) {
            setMinError(false);
        } else {
            setMaxError(false);
        }
    };

    const onFailedRequest = (id: string) => {
        setParameterError(id, true);
        if (id === firstThumb?.parameterId) {
            setMinError(true);
        } else {
            setMaxError(true);
        }
    };

    const handleOnMinChange = (value: string) => {
        const newValue = parseInt(value);
        if (newValue !== min) {
            setMinValue(newValue);
            debouncedChangeHandler(
                firstThumb?.parameterId,
                String("-" + newValue),
                "",
                null,
                () => onSuccessfulRequest(firstThumb?.parameterId),
                () => onFailedRequest(firstThumb?.parameterId)
            );
        }
    };

    const handleOnMaxChange = (value: string) => {
        const newValue = parseInt(value);
        if (newValue !== max) {
            setMaxValue(newValue);
            debouncedChangeHandler(
                secondThumb?.parameterId,
                String("-" + newValue),
                "",
                null,
                () => onSuccessfulRequest(secondThumb?.parameterId),
                () => onFailedRequest(secondThumb?.parameterId)
            );
        }
    };

    const handleSliderChange = (e: any) => {
        handleOnMinChange(e.target.value[0]);
        handleOnMaxChange(e.target.value[1]);
    };

    const renderPercentageForGradient = (value: number) => {
        const percentage = (value / 100) * 100;
        return percentage;
    };

    return (
        <div
            css={css({
                display: "flex",
                marginTop: "2px",
                marginBottom: "16px",
                flexDirection: "column",
                border: "1px solid #E2E8F0",
                padding: "12px 12px 16px 12px",
            })}
        >
            <div
                css={css({
                    fontWeight: 600,
                    fontSize: "14px",
                    lineHeight: "20px",
                    display: "flex",
                    alignItems: "center",
                    letterSpacing: "0.1px",
                    color: textDark,
                    marginBottom: "6px",
                })}
            >
                {name}
            </div>
            <div
                css={css({
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "28px",
                })}
            >
                <InputField
                    id={String(firstThumb?.parameterId)}
                    size="medium"
                    value={min}
                    type="number"
                    inputProps={{
                        min: 0,
                        max: max,
                        step: 1,
                        style: { width: "40px" },
                    }}
                    otherInputProps={{
                        startAdornment: min ? "-" : "",
                    }}
                    onChange={(e) => handleOnMinChange(e.target.value)}
                    wrapperStyle={css({ marginRight: "8px" })}
                    error={minError ? "error" : ""}
                    label="Min (dBm)"
                    stacked
                />
                <Slider
                    sx={{
                        " &.MuiSlider-root": {
                            margin: "0px 12px",
                        },
                        "& .MuiSlider-track": {
                            background: "yellow",
                            borderColor: "white",
                            height: "4px",
                        },
                        "& .MuiSlider-thumb": {
                            background: blue800,
                            width: "12px",
                            height: "12px",
                        },
                        "& .MuiSlider-mark": {
                            background: "none",
                        },
                        "& .MuiSlider-rail": {
                            background: `linear-gradient(to right, #6FEB9D ${renderPercentageForGradient(
                                min
                            )}%, #70B8FF ${renderPercentageForGradient(
                                min
                            )}% ${renderPercentageForGradient(
                                max
                            )}%,#FDBA74 0%)`,
                            opacity: 1,
                        },
                        "& .MuiSlider-markLabel": {
                            paddingTop: "5px",
                            fontWeight: "600",
                            fontSize: "10px",
                            lineHeight: "12px",
                            letterSpacing: "1px",
                            color: textPlaceholder,

                            "&:before": {
                                content: '""',
                                position: "absolute",
                                top: "0px",
                                left: "50%",
                                width: "1px",
                                height: "4px",
                                background: gray300,
                            },
                        },
                    }}
                    valueLabelDisplay="auto"
                    value={[min, max]}
                    min={0}
                    max={100}
                    scale={(x) => x}
                    marks={marks}
                    step={1}
                    track={false}
                    onChange={handleSliderChange}
                    disableSwap
                />
                <InputField
                    id={String(secondThumb?.parameterId)}
                    size="medium"
                    value={max}
                    type="number"
                    inputProps={{
                        min: min,
                        max: 100,
                        step: 1,
                        style: { width: "40px" },
                    }}
                    otherInputProps={{
                        startAdornment: max ? "-" : "",
                    }}
                    error={maxError ? "error" : ""}
                    onChange={(e) => handleOnMaxChange(e.target.value)}
                    wrapperStyle={css({ marginLeft: "8px" })}
                    label="Max (dBm)"
                    stacked
                />
            </div>
            <div
                css={css({
                    display: "flex",
                    flexDirection: "column",
                    gap: " 10px",
                })}
            >
                <div css={css({ display: "flex" })}>
                    <span
                        css={css({
                            width: "18px",
                            height: "18px",
                            backgroundColor: sliderGreen,
                            borderRadius: "3px",
                            display: "block",
                            marginRight: "8px",
                        })}
                    />
                    <span
                        css={css({
                            fontWeight: "600",
                            fontSize: "14px",
                            lineHeight: "20px",
                            letterSpacing: "0.1px",
                            color: gray700,
                        })}
                    >
                        Near ({0} to {min ? `-${min}` : min})
                    </span>
                </div>
                <div css={css({ display: "flex" })}>
                    <span
                        css={css({
                            width: "18px",
                            height: "18px",
                            backgroundColor: blue1000,
                            borderRadius: "3px",
                            marginRight: "8px",
                        })}
                    />
                    <span
                        css={css({
                            fontWeight: "600",
                            fontSize: "14px",
                            lineHeight: "20px",
                            letterSpacing: "0.1px",
                            color: gray700,
                        })}
                    >
                        Far ({min ? `-${min}` : min} to {max ? `-${max}` : max})
                    </span>
                </div>
                <div css={css({ display: "flex" })}>
                    <span
                        css={css({
                            width: "18px",
                            height: "18px",
                            backgroundColor: orange300,
                            borderRadius: "3px",
                            marginRight: "8px",
                        })}
                    />
                    <span
                        css={css({
                            fontWeight: "600",
                            fontSize: "14px",
                            lineHeight: "20px",
                            letterSpacing: "0.1px",
                            color: gray700,
                        })}
                    >
                        Lost ({max ? `-${max}` : max} to -100)
                    </span>
                </div>
            </div>
        </div>
    );
};

export default RangeSlider;
