/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import { Fragment, useContext, useEffect, useState } from "react";
import SettingsContext from "../../../../../../context/settings/settingsContext";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "../../../../../MuiComponents/Button";
import CloseIcon from "@mui/icons-material/CloseRounded";
import DialogContent from "@mui/material/DialogContent";
import useMediaQuery from "../../../../../../utils/useMediaQueries";
import LanguageContext from "../../../../../../context/language/languageContext";
import ThemeContext from "../../../../../../context/theme/themeContext";

import { ReactComponent as CopySystemInfo } from "../../../../../../assets/icons/CopySystemInfo.svg";
import { DialogActions } from "@mui/material";
import { SystemInformation } from "../../../../../../generatedTypes";
import Tooltip from "../../../../../MuiComponents/Tooltip";
import useTct from "../../../../../../utils/useTct";

const SystemInformationModalModal = () => {
    const { isSystemInformationModalOpen, closeSystemInformationModal } =
        useContext(SettingsContext);
    const { toMd } = useMediaQuery();
    const { t } = useContext(LanguageContext);
    const {
        colors: { gray300, gray50, textDarkSecondary, gray200 },
    } = useContext(ThemeContext);

    const [systemInfoData, setSystemInformation] =
        useState<SystemInformation | null>(null);

    const [hasTextBeenCopied, setTextBeenCopied] = useState(false);

    const { getSystemInformationAsync } = useTct();

    useEffect(() => {
        setTextBeenCopied(false);
        const getSystemInformation = async () => {
            try {
                const data = await getSystemInformationAsync();
                setSystemInformation(data);
            } catch (error) {
                console.log("err", error);
            }
        };
        if (isSystemInformationModalOpen) {
            getSystemInformation();
        }
        // eslint-disable-next-line
    }, [isSystemInformationModalOpen]);

    const formatJsonToPlainText = (jsonData: SystemInformation | null) => {
        if (!jsonData) {
            return "";
        }

        const appName = jsonData.applicationName;
        const appVersion = jsonData.applicationVersion;
        const configurations = jsonData.configurationInfo?.configurations ?? [];

        let plainText = `${t.AppName}: ${appName}\n${t.Version}: ${appVersion}\n`;
        const space = `\u00A0\u00A0\u00A0`;

        configurations.forEach((config: any) => {
            const platform = config.platform;
            const publishTime = config.publishTime;
            const hash = `${t.Hash}: ${config.hash}`;

            const line = `${platform}:   \n${space}${t.From}: ${config.source}; ${space}${t.Published}: ${publishTime}; ${space}${hash}`;
            plainText += `\n${line.split("; ").join(";\n")}\n`;
        });

        return plainText;
    };

    const generateParts = (item: any) => {
        const parts = [];

        if (item.source) {
            parts.push(`${t.From}: ${item.source}`);
        }

        if (item.publishTime) {
            parts.push(`${t.Published}: ${item.publishTime}`);
        }

        if (item.version) {
            parts.push(`${t.Version}: ${item.version}`);
        }

        if (item.hash) {
            parts.push(`${t.Hash}: ${item.hash}`);
        }

        return parts;
    };

    const renderItem = (title: string, info: string | string[]) => {
        return (
            <div
                css={css({
                    display: "flex",
                    flexDirection: "column",
                })}
            >
                <div
                    css={css({
                        color: textDarkSecondary,
                        fontSize: "12px",
                        fontStyle: "normal",
                        fontWeight: 600,
                        lineHeight: "16px" /* 133.333% */,
                        letterSpacing: "0.4px",
                    })}
                >
                    {title}
                </div>
                <div
                    css={css({
                        fontSize: "12px",
                        fontStyle: "normal",
                        fontWeight: 400,
                        lineHeight: "16px",
                        letterSpacing: "0.4px",
                    })}
                >
                    {Array.isArray(info) ? info.join("; ") : info}
                </div>
            </div>
        );
    };
    return (
        <Dialog
            onClose={closeSystemInformationModal}
            open={isSystemInformationModalOpen}
            fullScreen={toMd}
            fullWidth
            maxWidth="md480"
        >
            <DialogTitle>
                System information
                <Button
                    variant="iconOnly"
                    size="small"
                    color="white"
                    icon={<CloseIcon onClick={closeSystemInformationModal} />}
                    css={css({
                        position: "absolute",
                        top: "4px",
                        right: "4px",
                    })}
                    idForTesting="closeSystemInformationModal"
                />
            </DialogTitle>
            <DialogContent>
                <div
                    css={css({
                        display: "flex",
                        flexDirection: "column",
                        padding: "16px",
                        borderRadius: "8px",
                        border: `1px solid ${gray300}`,
                        background: gray50,
                        gap: "15px",
                    })}
                >
                    {systemInfoData && (
                        <Fragment>
                            {renderItem(
                                `${t.AppName}:`,
                                systemInfoData.applicationName
                            )}
                            {renderItem(
                                `${t.Version}:`,
                                systemInfoData.applicationVersion
                            )}
                            {systemInfoData.configurationInfo &&
                                systemInfoData.configurationInfo
                                    .configurations &&
                                systemInfoData.configurationInfo.configurations.map(
                                    (item: any) => {
                                        return renderItem(
                                            item.platform,
                                            generateParts(item)
                                        );
                                    }
                                )}
                        </Fragment>
                    )}
                    <div
                        css={css({
                            width: "100%",
                            display: "flex",
                            justifyContent: "flex-end",
                        })}
                    >
                        <Tooltip
                            title={
                                hasTextBeenCopied ? t.Copied : t.CopyToClipboard
                            }
                            small
                            placement="top"
                        >
                            <span
                                css={css({
                                    cursor: "pointer",
                                    padding: "4px",
                                    borderRadius: "8px",
                                    "&:hover": {
                                        backgroundColor: gray200,
                                    },
                                })}
                                onClick={() => {
                                    setTextBeenCopied(true);
                                    navigator.clipboard.writeText(
                                        formatJsonToPlainText(systemInfoData)
                                    );
                                }}
                            >
                                <CopySystemInfo />
                            </span>
                        </Tooltip>
                    </div>
                </div>
            </DialogContent>
            <DialogActions>
                <Button
                    variant="textOnly"
                    size="normal"
                    color="secondary"
                    fullWidth
                    onClick={closeSystemInformationModal}
                    idForTesting="closeSystemInformationModalButton"
                >
                    {t.Close}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default SystemInformationModalModal;
