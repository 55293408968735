/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import { Fragment, useContext, useState } from "react";
import FeaturesContext from "../../../../../../../context/features/featuresContext";
import { Schedule } from "../../../../../../../generatedTypes";
import Switch from "../../../../../../MuiComponents/Switch";
import TypedGrid from "../../../../../../MuiComponents/TypedGrid";
import TimeInput from "../TimeInput";
import Tooltip from "../../../../../../MuiComponents/Tooltip";
import Tag from "../../../../../../MuiComponents/Tag";
import useMediaQueries from "../../../../../../../utils/useMediaQueries";
import Divider from "../../../../../../MuiComponents/Divider";
import ThemeContext from "../../../../../../../context/theme/themeContext";

export interface WeekdayInputProps {
    data: Schedule["weekdays"][0];
    hasNoDivider?: boolean;
}

export enum SwitchValues {
    ON = "1",
    OFF = "0",
}
const WeekdayInput = ({ data, hasNoDivider }: WeekdayInputProps) => {
    const { setParameterValue, findDefaultParameterValueById } =
        useContext(FeaturesContext);
    const {
        colors: { gray700 },
    } = useContext(ThemeContext);

    const { toSm } = useMediaQueries();

    const defaultSwitchValue =
        findDefaultParameterValueById(data.status!.parameterId) ||
        data.status!.parameterValue ||
        data.status!.default;

    const [isSwitchOn, setSwitchOn] = useState(defaultSwitchValue);

    const isSwitchChecked = isSwitchOn === SwitchValues.ON;

    const handleSwitchToggle = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSwitchOn(isSwitchChecked ? SwitchValues.OFF : SwitchValues.ON);
        setParameterValue({
            parameterId: data.status!.parameterId,
            value: event.target.checked ? SwitchValues.ON : SwitchValues.OFF,
        });
    };

    const weekendTooltip = () => {
        return (
            <div>
                <div>
                    <div>Status:</div>
                    <div>ID: {data?.status?.parameterId}</div>
                </div>
                <hr />
                <div>
                    <div>Start Time:</div>
                    <div>ID: {data?.start?.parameterId}</div>
                </div>
                <hr />
                <div>
                    <div>End Time: </div>
                    <div>ID: {data?.end?.parameterId}</div>
                </div>
            </div>
        );
    };

    const renderContent = () => {
        if (toSm) {
            return (
                <Fragment>
                    {!hasNoDivider && <Divider />}
                    <div
                        css={css({
                            display: "flex",
                            justifyContent: "space-between",
                            width: "100%",
                            marginBottom: "8px",
                            alignItems: "flex-end",
                            marginTop: "16px",
                        })}
                    >
                        <div>
                            <span
                                css={css({
                                    color: gray700,
                                    fontSize: "14px",
                                    fontWeight: 600,
                                    lineHeight: "20px",
                                    letterSpacing: "0.1px",
                                })}
                            >
                                {data.name}
                            </span>
                        </div>
                        <div>
                            <Tooltip
                                title={weekendTooltip()}
                                small
                                placement="top"
                            >
                                <span>
                                    <Tag size="tiny" color="white" title="ID" />
                                </span>
                            </Tooltip>
                            <Switch
                                checked={isSwitchChecked}
                                onChange={handleSwitchToggle}
                                size="medium"
                                labelPlacement="start"
                                wrapperStyle={css({ marginLeft: "8px" })}
                                idForTesting={data.name || ""}
                            />
                        </div>
                    </div>
                    <TypedGrid container spacing={2}>
                        <TypedGrid item xs={6}>
                            <TimeInput
                                data={data.start!}
                                disabled={!isSwitchChecked}
                                defaultValue={
                                    findDefaultParameterValueById(
                                        data.start!.parameterId
                                    ) as number
                                }
                                isWithoutLabel
                            />
                        </TypedGrid>
                        <TypedGrid item xs={6}>
                            <TimeInput
                                data={data.end!}
                                disabled={!isSwitchChecked}
                                defaultValue={
                                    findDefaultParameterValueById(
                                        data.end!.parameterId
                                    ) as number
                                }
                                isWithoutLabel
                            />
                        </TypedGrid>
                    </TypedGrid>
                </Fragment>
            );
        }
        return (
            <Fragment>
                <TypedGrid item xs={3}>
                    <Tooltip title={weekendTooltip()} small placement="top">
                        <span>
                            <Tag size="tiny" color="white" title="ID" />
                        </span>
                    </Tooltip>
                    <Switch
                        checked={isSwitchChecked}
                        onChange={handleSwitchToggle}
                        size="medium"
                        labelPlacement="start"
                        wrapperStyle={css({ marginLeft: "8px" })}
                        idForTesting={data.name || ""}
                    />
                </TypedGrid>
                <TypedGrid item xs={2}>
                    <span
                        css={css({
                            color: gray700,
                            fontSize: "14px",
                            fontWeight: 600,
                            lineHeight: "20px",
                            letterSpacing: "0.1px",
                        })}
                    >
                        {data.name}
                    </span>
                </TypedGrid>
                <TypedGrid item xs={3}>
                    <TimeInput
                        data={data.start!}
                        disabled={!isSwitchChecked}
                        defaultValue={
                            findDefaultParameterValueById(
                                data.start!.parameterId
                            ) as number
                        }
                        isWithoutLabel
                    />
                </TypedGrid>
                <TypedGrid item xs={3}>
                    <TimeInput
                        data={data.end!}
                        disabled={!isSwitchChecked}
                        defaultValue={
                            findDefaultParameterValueById(
                                data.end!.parameterId
                            ) as number
                        }
                        isWithoutLabel
                    />
                </TypedGrid>
            </Fragment>
        );
    };
    return (
        <div css={css({ margin: "16px 0" })}>
            <TypedGrid
                container
                justifyContent="space-between"
                alignItems="center"
            >
                {renderContent()}
            </TypedGrid>
        </div>
    );
};

export default WeekdayInput;
