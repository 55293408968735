/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from "@emotion/react";

import { useContext, useEffect, useState } from "react";
import { CSVLink } from "react-csv";
import ThemeContext from "../../../../../../context/theme/themeContext";
import AlertContext from "../../../../../../context/alert/alertContext";
import ListContext from "../../../../../../context/list/listContext";
import useUploadFile from "../../../../../../utils/useUploadFile";
import useAuxiliary from "./useAuxiliary";
import ListItems from "./ListItems";
import ListManagement from "./ListManagement";
import Badge from "../../../../../MuiComponents/Badge";
import Tooltip from "../../../../../MuiComponents/Tooltip";
import Button from "../../../../../MuiComponents/Button";
import ManageIcon from "../../../../../../assets/icons/ManageIcon";
import AddIcon from "@mui/icons-material/AddRounded";
import ExportIcon from "@mui/icons-material/FileDownloadRounded";
import ImportIcon from "@mui/icons-material/FileUploadRounded";
import CircularProgress from "@mui/material/CircularProgress";
import LayoutContext from "../../../../../../context/layout/layoutContext";
import SettingsContext from "../../../../../../context/settings/settingsContext";
import Divider from "../../../../../MuiComponents/Divider";
import { AlertStatus } from "../../../../../../constants/constants";

export interface ListItemInterface {
    index: number;
    value: string;
    language?: string;
    parameterId: number;
}

interface ListProps {
    addCaption: string;
    manageCaption: string;
    exportTooltip: string;
    exportDisabledTooltip: string;
    importTooltip: string;
}

const List = ({
    addCaption,
    manageCaption,
    exportTooltip,
    exportDisabledTooltip,
    importTooltip,
}: ListProps) => {
    const {
        colors: { gray200, textDark },
    } = useContext(ThemeContext);

    const { setListValue } = useContext(LayoutContext);
    const { setAlert } = useContext(AlertContext);
    const { isExplanatoryTextsHidden } = useContext(SettingsContext);

    const {
        items,
        valuesCountLimit,
        name,
        description,
        idRangeFrom,
        idRangeTo,
        subtitle,
        idRangeFromSecondary,
        idRangeToSecondary,
        valuesCountLimitWithSecondaryItems,
        setListInitialValues,
    } = useContext(ListContext);

    const { inputProps, selectedFile, openFileDialog } = useUploadFile({
        accept: ".csv",
    });
    const { filterAndTransform, insertMissingItems } = useAuxiliary();

    const [importLoading, setImportLoading] = useState(false);
    const [isOpenDialog, setIsOpenDialog] = useState(false);
    const [values, setValues] = useState<ListItemInterface[]>([]);

    useEffect(() => {
        const transformedValues = filterAndTransform(items);
        const transformedInputs = insertMissingItems(
            transformedValues,
            items.map((item: any) => item.parameterId)
        );
        setListInitialValues(transformedInputs, transformedInputs);
        setValues(transformedValues);
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        //set initial values when adding config
        if (name === "SMS Events Numbers") {
            const transformedValues = filterAndTransform(items);
            setListValue(transformedValues, name);
        }
    }, []);

    const handleOpenDialog = () => setIsOpenDialog(true);
    const handleCloseDialog = () => setIsOpenDialog(false);

    const generateExportData = (): string[][] =>
        values.reduce<string[][]>(
            (previous, current) => [...previous, [current.value]],
            []
        );

    const exportData = generateExportData();

    const handleListChange = (listValues: any) => {
        setValues(listValues);
        setListValue(listValues, name);
    };

    const hasSecondaryItems = !!idRangeFromSecondary;

    return (
        <div
            css={css({
                border: `1px solid ${gray200}`,
                borderRadius: "8px",
            })}
        >
            <div
                css={css({
                    padding: "24px 24px 16px 24px",
                })}
            >
                <div
                    css={css({
                        display: "flex",
                        alignItems: "center",
                        marginBottom: "8px",

                        "& > h5": {
                            fontSize: "16px",
                            fontWeight: 600,
                            lineHeight: "20px",
                            letterSpacing: "0.15px",
                            marginRight: "8px",
                        },
                    })}
                >
                    <h5>{subtitle}</h5>

                    <Badge color="info">
                        {values.length} /
                        {hasSecondaryItems
                            ? valuesCountLimitWithSecondaryItems
                            : valuesCountLimit}
                    </Badge>
                </div>
                {description && !isExplanatoryTextsHidden && (
                    <p>{description}</p>
                )}
                {(idRangeFrom > 0 || (idRangeFromSecondary ?? 0) > 0) && (
                    <div
                        css={css({
                            fontSize: "14px",
                            lineHeight: "20px",
                            letterSpacing: "0.1px",
                        })}
                    >
                        {hasSecondaryItems
                            ? `range: ${idRangeFromSecondary} - ${idRangeToSecondary}`
                            : `range: ${idRangeFrom} - ${idRangeTo}`}
                    </div>
                )}
            </div>

            <Divider hasLighterGray />

            <div
                css={css({
                    padding: "16px 24px 24px",
                })}
            >
                {values.length > 0 && <ListItems items={values} />}

                <div css={css({ display: "flex" })}>
                    <Button
                        fullWidth
                        variant="iconLeft"
                        size="normal"
                        color="primarySubtle"
                        icon={!!values.length ? <ManageIcon /> : <AddIcon />}
                        onClick={handleOpenDialog}
                        idForTesting="list-add-manage-button"
                    >
                        {values.length === 0 ? addCaption : manageCaption}
                    </Button>

                    <Tooltip
                        title={
                            values.length
                                ? exportTooltip
                                : exportDisabledTooltip
                        }
                        placement="top-end"
                    >
                        <span
                            css={css({
                                display: "inline-block",
                                margin: "0 16px",
                            })}
                        >
                            <Button
                                variant="iconOnly"
                                size="normal"
                                color="secondary"
                                disabled={!values.length}
                                icon={
                                    <CSVLink
                                        data={exportData}
                                        filename={`${subtitle}.csv`}
                                        enclosingCharacter={""}
                                        onClick={() =>
                                            setAlert(
                                                AlertStatus.Success,
                                                "Exported"
                                            )
                                        }
                                        css={css({
                                            display: "inherit",
                                            color: "inherit",
                                        })}
                                    >
                                        <ExportIcon />
                                    </CSVLink>
                                }
                                idForTesting="list-export-button"
                            />
                        </span>
                    </Tooltip>

                    <Tooltip title={importTooltip} placement="top-end">
                        <span
                            css={css({
                                display: "inline-block",
                            })}
                        >
                            <Button
                                variant="iconOnly"
                                size="normal"
                                color="secondary"
                                icon={
                                    importLoading ? (
                                        <div
                                            css={css({
                                                width: "24px",
                                                height: "24px",
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                            })}
                                        >
                                            <CircularProgress
                                                size={20}
                                                css={css({
                                                    color: textDark,
                                                })}
                                            />
                                        </div>
                                    ) : (
                                        <ImportIcon />
                                    )
                                }
                                onClick={openFileDialog}
                                css={css({
                                    pointerEvents: importLoading
                                        ? "none"
                                        : "auto",
                                })}
                                idForTesting="list-import-button"
                            />
                        </span>
                    </Tooltip>

                    <input {...inputProps} />
                </div>
            </div>

            <ListManagement
                isOpen={isOpenDialog}
                selectedFile={selectedFile}
                valuesCount={values.length}
                openDialog={handleOpenDialog}
                closeDialog={handleCloseDialog}
                setImportLoading={setImportLoading}
                setValues={handleListChange}
            />
        </div>
    );
};

export default List;
