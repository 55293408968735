/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from "@emotion/react";

import { useContext } from "react";
import MenuItem from "@mui/material/MenuItem";
import ThemeContext from "../../../../../context/theme/themeContext";
import IdBadge from "../../../../MuiComponents/IdBadge";

interface ModifiedParameterItemProps {
    parameterId: number;
    avlId: number;
    label?: string;
}

const ModifiedParameterListItem = ({
    parameterId,
    avlId,
    label,
}: ModifiedParameterItemProps) => {
    const {
        colors: { white, gray100 },
    } = useContext(ThemeContext);

    return (
        <MenuItem
            data-test={String(parameterId)}
            css={css({
                display: "flex",
                gap: "8px",
                alignItems: "center",
                backgroundColor: white,
                borderRadius: "3px",
                cursor: "pointer",
                pointerEvents: "auto",
                padding: "8px",
                minHeight: "auto",
                transition:
                    "background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;",

                "&:hover": {
                    backgroundColor: gray100,
                },
                "@media (min-width: 600px)": {
                    minHeight: "auto",
                },
            })}
        >
            {parameterId === 0 ? (
                <span css={css({ width: 31 })}></span>
            ) : (
                <IdBadge parameterId={parameterId} avlId={avlId} />
            )}
            <span>{label}</span>
        </MenuItem>
    );
};

export default ModifiedParameterListItem;
