import React from "react";
import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

const FilterIcon = (props: SvgIconProps) => {
    return (
        <SvgIcon viewBox="0 0 16 16" {...props}>
            <path
                d="M3.00262 2H13.0026L7.99262 8.3L3.00262 2ZM0.252619 1.61C2.27262 4.2 6.00262 9 6.00262 9V15C6.00262 15.55 6.45262 16 7.00262 16H9.00262C9.55262 16 10.0026 15.55 10.0026 15V9C10.0026 9 13.7226 4.2 15.7426 1.61C16.2526 0.95 15.7826 0 14.9526 0L1.04262 0C0.212619 0 -0.257381 0.95 0.252619 1.61Z"
                fill="#334155"
            />
        </SvgIcon>
    );
};

export default FilterIcon;
