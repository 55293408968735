import React, { useMemo } from "react";
import AppTypeContext from "./appTypeContext";

interface AppTypeStateProps {
    children: React.ReactNode;
}

const AppTypeState = ({ children }: AppTypeStateProps) => {
    const isWasm = process.env.REACT_APP_WEB_EDITOR === "true";
    const getFileIdFromQuery = () => {
        const match = window.location.search.match(
            /id=(\d+)/
        ) as RegExpMatchArray;

        return (match?.length ?? 0) === 2 ? Number(match[1]) : 0;
    };

    const fileId = getFileIdFromQuery();
    const isEditor = Boolean(fileId);

    const contextValue = useMemo(() => {
        return {
            webVersion: false,
            isEditor,
            fileId,
            isWasm,
        };
    }, []);

    return (
        <AppTypeContext.Provider value={contextValue}>
            {children}
        </AppTypeContext.Provider>
    );
};

export default AppTypeState;
