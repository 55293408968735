/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from "@emotion/react";

import { Fragment, useContext, useEffect } from "react";
import LayoutContext from "../../../context/layout/layoutContext";
import SearchContext from "../../../context/search/searchContext";
import useMediaQueries from "../../../utils/useMediaQueries";
import SearchModal from "./SearchModal";
import Button from "../../MuiComponents/Button";
import { SearchRounded } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/CloseRounded";
import LanguageContext from "../../../context/language/languageContext";

interface SearchProps {
    isOpenMenu: boolean;
    toggleMenu: () => void;
    disabled: boolean;
}

const Search = ({ isOpenMenu, toggleMenu, disabled }: SearchProps) => {
    const { actionLoading } = useContext(LayoutContext);
    const { t } = useContext(LanguageContext);
    const { isOpenModal, openModal, closeModal } = useContext(SearchContext);

    const { toMd } = useMediaQueries();

    useEffect(() => {
        toMd && isOpenMenu && isOpenModal && closeModal();

        // eslint-disable-next-line
    }, [toMd, isOpenMenu, isOpenModal]);

    const handleMobileClick = () => {
        if (isOpenModal) {
            closeModal();
        } else {
            openModal();
            isOpenMenu && toggleMenu();
        }
    };

    const MobileIcon = isOpenModal ? CloseIcon : SearchRounded;

    return (
        <Fragment>
            {toMd ? (
                <Button
                    variant="iconOnly"
                    size="small"
                    color="transparent"
                    icon={<MobileIcon />}
                    disabled={actionLoading || disabled}
                    onClick={handleMobileClick}
                    css={css({
                        margin: "0 16px",
                    })}
                    idForTesting="searchButton"
                />
            ) : (
                <Button
                    variant="iconLeft"
                    size="small"
                    idForTesting="searchButton"
                    color="secondary"
                    icon={<SearchRounded />}
                    disabled={actionLoading || disabled}
                    onClick={openModal}
                    css={css({
                        margin: "0 16px",
                        width: "49%",
                        justifyContent: "flex-start",
                        maxWidth: "566px",
                    })}
                >
                    {t.Search}
                </Button>
            )}

            <SearchModal />
        </Fragment>
    );
};

export default Search;
