/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from "@emotion/react";

import { useContext, useEffect, useRef, useState } from "react";
import Switch from "../../../../MuiComponents/Switch";
import ThemeContext from "../../../../../context/theme/themeContext";
import LanguageContext from "../../../../../context/language/languageContext";
import useApi from "../../../../../utils/useApi";
import { DoutInfo } from "../../../../../generatedTypes";

interface DoutSwitchProps {
    dout: DoutInfo;
}

const DoutSwitch = ({ dout }: DoutSwitchProps) => {
    const {
        colors: { gray200, gray700, textDark },
    } = useContext(ThemeContext);
    const { t, selectedLanguage } = useContext(LanguageContext);

    const { getData } = useApi();

    const [doutEnabled, setDoutEnabled] = useState(dout.state === 0);

    // Normalizing state because 0 is ON and 1 is OFF (thank you, ESD)
    useEffect(() => {
        setDoutEnabled(dout.state === 0);
    }, [dout.state]);

    const switchRef = useRef<HTMLInputElement>(null);

    const toggleDout = async () => {
        setDoutEnabled(!doutEnabled);
        await getData(`${selectedLanguage}/command/toggledout/${dout.id}`);
    };

    return (
        <div
            css={css({
                border: `1px solid ${gray200}`,
                borderRadius: "8px",
                padding: "16px",
                color: textDark,
                marginBottom: "8px",
            })}
        >
            <div
                css={css({
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                })}
            >
                <div
                    css={css({
                        margin: "8px 0",
                        fontWeight: "600",
                        fontSize: "16px",
                        color: gray700,
                    })}
                >
                    {`${t.Dout} ${dout.id + 1}`}
                </div>

                <Switch
                    ref={switchRef}
                    checked={doutEnabled}
                    onChange={toggleDout}
                    size="medium"
                    labelPlacement="start"
                    idForTesting={`dout-switch-${dout.id}`}
                />
            </div>
        </div>
    );
};

export default DoutSwitch;
