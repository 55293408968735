import { createContext } from "react";
import { EcoCalculatorValues, ParameterValue } from "./FeaturesState";

export interface FeaturesContextState {
    editableInputs: number[];
    parametersValues: ParameterValue[];
    setEditableInput: (payload: number) => void;
    resetEditableInputs: () => void;
    removeEditableInputById: (payload: number) => void;
    setParametersValues: (payload: ParameterValue[]) => void;
    setParameterValue: (payload: ParameterValue) => void;
    submitParameterValues: () => void;
    submitParameterValue: (payload: ParameterValue) => void;
    removeParameterValueById: (payload: number) => void;
    findParameterValueById: (payload: number) => string | undefined | number;
    findDefaultParameterValueById: (
        payload: number
    ) => string | undefined | number;
    hasEditableInput: (payload: number) => boolean;
    getEcoCalculatorValues: () => EcoCalculatorValues | null | undefined;
    setEcoCalculatorValues: (values: EcoCalculatorValues | null) => void;
    resetDefaultParameterValues: () => void;
}

const FeaturesContext = createContext({} as FeaturesContextState);

export default FeaturesContext;
