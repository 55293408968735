/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx, SerializedStyles } from "@emotion/react";

import { useContext, useState, useEffect } from "react";
import ThemeContext from "../../../context/theme/themeContext";
import SearchContext from "../../../context/search/searchContext";
import useDebounce from "../../../utils/useDebounce";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import { SearchRounded } from "@mui/icons-material";

interface SearchInputProps {
    filled?: boolean;
    wrapperStyle?: SerializedStyles;
}

const SearchInput = ({ filled, wrapperStyle, ...props }: SearchInputProps) => {
    const {
        colors: { white, gray700, textDark },
    } = useContext(ThemeContext);

    const { isInputClear, setResults } = useContext(SearchContext);

    const debouncedChangeHandler = useDebounce(setResults, 500);

    const [value, setValue] = useState("");

    useEffect(() => {
        isInputClear && setValue("");
    }, [isInputClear]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const searchValue = e.target.value;

        setValue(searchValue);

        (searchValue.length === 0 || searchValue.length >= 3) &&
            debouncedChangeHandler(searchValue);
    };

    return (
        <div
            css={css({
                flex: "1",
                ...wrapperStyle,
            })}
        >
            <TextField
                {...props}
                autoFocus
                fullWidth
                variant="filled"
                placeholder="Search"
                value={value}
                onChange={handleChange}
                InputProps={{
                    spellCheck: "false",
                    disableUnderline: true,
                    startAdornment: (
                        <InputAdornment position="start">
                            <SearchRounded />
                        </InputAdornment>
                    ),
                    sx: {
                        backgroundColor: "rgba(255, 255, 255, 0.05)",
                        color: filled ? white : textDark,
                        fontWeight: "600",
                        lineHeight: "20px",
                        letterSpacing: "0.1px",
                        padding: "6px 12px",
                        borderRadius: "4px",

                        "&:hover": {
                            backgroundColor: "rgba(255, 255, 255, 0.05)",
                        },

                        "&.Mui-focused": {
                            backgroundColor: "rgba(255, 255, 255, 0.05)",
                        },

                        "& .MuiFilledInput-input": {
                            padding: "0",

                            "&::placeholder": {
                                color: filled ? white : gray700,
                                lineHeight: "20px",
                                letterSpacing: "0.1px",
                            },
                        },

                        "& .MuiInputAdornment-root.MuiInputAdornment-positionStart.MuiInputAdornment-root:not(.MuiInputAdornment-hiddenLabel)":
                            {
                                marginTop: "0",
                            },

                        "& .MuiInputAdornment-root": {
                            color: filled ? white : gray700,
                            height: "unset",
                        },
                    },
                }}
            />
        </div>
    );
};

export default SearchInput;
