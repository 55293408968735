/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import ReactGA from "react-ga4";

import { Fragment, useContext, useEffect, useState } from "react";
import ThemeContext from "../../../../context/theme/themeContext";
import AlertContext from "../../../../context/alert/alertContext";
import LanguageContext from "../../../../context/language/languageContext";
import LayoutContext from "../../../../context/layout/layoutContext";
import useMediaQuries from "../../../../utils/useMediaQueries";
import Container from "../../../Container";
import DeviceInformation from "./DeviceInformation";
import Loader from "../../../Loader/EditorSkeleton/GeneralSkeleton";
import TypedGrid from "../../../MuiComponents/TypedGrid";
import useNavigation from "../../../../utils/useNavigation";
import { useAtom } from "jotai";
import {
    DeviceStatusAtom,
    FirmwareUpdateResultAtom,
} from "../../../../context/webSockets/webSocketsState";
import Alert from "../../../Alert";
import DoutInformation from "./DoutInformation";
import MaintenanceInformation from "./MaintenanceInformation";
import SimSecurityInformation from "./SimSecurityInformation";
import CellularInformation from "./CellularInformation";
import GnssInformation from "./GnssInformation";
import { AlertData } from "../../../../utils/types";
import { AlertStatus, ConnectionTypes } from "../../../../constants/constants";
import DeviceAuthorizationContext from "../../../../context/deviceAuthorization/deviceAuthorizationContext";
import { DeviceStatusWindow } from "../../../../generatedTypes";
import useConfiguration from "../../../../utils/useConfiguration";

const OnlineView = () => {
    const {
        colors: { textDark, gray700 },
    } = useContext(ThemeContext);

    const { setAlert } = useContext(AlertContext);

    const { t } = useContext(LanguageContext);

    const { layoutData, actionLoading, stopActionLoading, setLayoutError } =
        useContext(LayoutContext);

    const showStatuses = layoutData?.isConfigurationSupported ?? false;
    const visibilitySettings =
        layoutData?.deviceStatusWindow ?? ({} as DeviceStatusWindow);

    const [deviceStatus] = useAtom(DeviceStatusAtom);
    const [firmwareUpdateResult, setFirmwareUpdateResult] = useAtom(
        FirmwareUpdateResultAtom
    );

    const [visibleAlerts, setVisibleAlerts] = useState<AlertData[]>([]);
    const [hasSimInformation, setHasSimInformation] = useState<boolean>(false);

    useEffect(() => {
        let filteredAlerts: AlertData[] = [];
        if (layoutData?.alerts && deviceStatus?.alertVisibilities) {
            filteredAlerts = layoutData.alerts
                .map((alert) => {
                    const alertCopy = structuredClone(alert);
                    const alertVisibility =
                        deviceStatus.alertVisibilities[alertCopy.key];
                    if (alertVisibility) {
                        alertCopy.visible = alertVisibility;
                    }
                    return alertCopy;
                })
                .filter((alert) => alert.visible);
        }
        setVisibleAlerts(filteredAlerts);
    }, [deviceStatus]);

    useEffect(() => {
        setHasSimInformation(
            (!!deviceStatus &&
                deviceStatus.simSecurity &&
                deviceStatus.simSecurity.simStates &&
                !!deviceStatus.simSecurity.simStates.length) ??
                false
        );
    }, [deviceStatus?.simSecurity]);

    const { getConfigurationFromDevice } = useConfiguration();

    const { toXl, fromXl } = useMediaQuries();

    const { refreshCurrentPage } = useNavigation();

    const {
        openEnterPasswordModal: openContextEnterPasswordModal,
        openFreshUnlockDeviceModal,
    } = useContext(DeviceAuthorizationContext);

    const openEnterPasswordModal = (
        additionalInfo: any,
        shouldRedirectToStartupOnCancel: boolean
    ) => {
        openContextEnterPasswordModal(
            String(layoutData?.port),
            Number(layoutData?.connectionType),
            getLayoutData,
            additionalInfo,
            shouldRedirectToStartupOnCancel
        );
    };

    const openDeviceLockedModal = (code: string) => {
        openFreshUnlockDeviceModal(
            String(layoutData?.port),
            Number(layoutData?.connectionType),
            getLayoutData,
            code
        );
    };

    const getLayoutData = async (redirect: boolean, reconnect = false) => {
        try {
            await getConfigurationFromDevice(
                layoutData?.port ?? "",
                Number(layoutData?.connectionType),
                reconnect
            );
            redirect = true;
        } catch (error) {
            redirect = false;

            const errorStatus = (error as any).response.status;

            if (errorStatus === 401) {
                const errorData = (error as any).response.data;
                const authorizationInfo = JSON.parse(errorData.detail);
                errorData.requiresUnlock
                    ? openDeviceLockedModal(errorData.unlockCode)
                    : openEnterPasswordModal(authorizationInfo, reconnect);
            } else {
                setLayoutError(error);
            }
        }
        redirect && refreshCurrentPage();
    };

    useEffect(() => {
        ReactGA.gtag("event", "device_connection", {
            connectionType:
                (layoutData?.connectionType || 0) === ConnectionTypes.Serial
                    ? "serial"
                    : "bluetooth",
            deviceModel: layoutData?.fmType,
        });

        const connectDevice = async () => {
            try {
                await getLayoutData(true, true);
                stopActionLoading();
            } catch (error) {
                stopActionLoading();
                setAlert(AlertStatus.Critical, t.FailedToConnectToDevice);
                setFirmwareUpdateResult(null);
            }
            setFirmwareUpdateResult(null);
        };
        firmwareUpdateResult && connectDevice();
    }, [firmwareUpdateResult]);

    const keyBaseStyles = css({
        color: gray700,
        fontSize: "14px",
        lineHeight: "20px",
        letterSpacing: "0.1px",
    });

    const valueBaseStyles = css({
        color: textDark,
        fontSize: "16px",
        lineHeight: "20px",
        letterSpacing: "0.15px",
        wordBreak: "break-all",
    });

    return actionLoading || !deviceStatus ? (
        <Loader fullWidth />
    ) : (
        <Container
            css={css({
                paddingTop: "24px",
                paddingBottom: "80px",
                width: "100%",
            })}
        >
            <TypedGrid
                container
                rowSpacing={2}
                columnSpacing={{ md: 2, xl: 3 }}
            >
                <TypedGrid item xs0={12}>
                    {visibleAlerts.map((alert) => (
                        <Alert
                            key={alert.title}
                            status={alert.type.toLowerCase() as AlertStatus}
                            title={alert.title}
                            description={alert.description}
                        />
                    ))}
                </TypedGrid>
                <TypedGrid
                    item
                    xs0={12}
                    xl={6}
                    css={css({
                        "& > div + div": {
                            marginTop: "16px",
                        },
                    })}
                >
                    <DeviceInformation
                        keyBaseStyles={keyBaseStyles}
                        valueBaseStyles={valueBaseStyles}
                        visibilitySettings={visibilitySettings}
                    />

                    {toXl &&
                        showStatuses &&
                        (deviceStatus.gnssLocationInformation ||
                            deviceStatus.gnssStatusInformation) && (
                            <GnssInformation
                                keyBaseStyles={keyBaseStyles}
                                valueBaseStyles={valueBaseStyles}
                            />
                        )}

                    {showStatuses && (
                        <Fragment>
                            {deviceStatus.cellularInformation && (
                                <CellularInformation
                                    data={deviceStatus.cellularInformation}
                                    keyBaseStyles={keyBaseStyles}
                                    valueBaseStyles={valueBaseStyles}
                                    visibilitySettings={visibilitySettings}
                                />
                            )}
                            {toXl && hasSimInformation && (
                                <SimSecurityInformation
                                    valueBaseStyles={valueBaseStyles}
                                />
                            )}
                            {(visibilitySettings.isCaptureLogSupported ||
                                visibilitySettings.isDownloadLogDumpSupported ||
                                visibilitySettings.accelerometerSupport) && (
                                <MaintenanceInformation
                                    visibilitySettings={visibilitySettings}
                                    valueBaseStyles={valueBaseStyles}
                                />
                            )}
                        </Fragment>
                    )}

                    {showStatuses &&
                        visibilitySettings.isDoutSupported &&
                        (deviceStatus.doutInformation?.length ?? 0) > 0 && (
                            <DoutInformation
                                data={deviceStatus.doutInformation!}
                            />
                        )}
                </TypedGrid>

                <TypedGrid
                    item
                    xs0={12}
                    xl={6}
                    css={css({
                        "& > div + div": {
                            marginTop: "16px",
                        },
                    })}
                >
                    {fromXl &&
                        showStatuses &&
                        (deviceStatus.gnssLocationInformation ||
                            deviceStatus.gnssStatusInformation) && (
                            <GnssInformation
                                keyBaseStyles={keyBaseStyles}
                                valueBaseStyles={valueBaseStyles}
                            />
                        )}
                    {fromXl && hasSimInformation && (
                        <SimSecurityInformation
                            valueBaseStyles={valueBaseStyles}
                        />
                    )}
                </TypedGrid>
            </TypedGrid>
        </Container>
    );
};

export default OnlineView;
