/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from "@emotion/react";

import { Fragment, useContext, useEffect, useRef, useState } from "react";
import { CircularProgress } from "@mui/material";
import ThemeContext from "../../../../../context/theme/themeContext";
import MenuItemContext from "../../../../../context/menuItem/menuItemContext";
import useDebounce from "../../../../../utils/useDebounce";
import { validateTextLengthAndRegex } from "../../../../../utils/validations";
import { TableSwitchDefaultValue } from "../IoFrame/IoTable/components/TableSwitchInput";
import Tooltip from "../../../../MuiComponents/Tooltip";
import { getIdTooltipText, noop } from "../../../../../utils/helpers";
import { Component } from "../../../../../generatedTypes";
import SettingsContext from "../../../../../context/settings/settingsContext";
import Tag from "../../../../MuiComponents/Tag";
import LanguageContext from "../../../../../context/language/languageContext";

export interface TableInputProps {
    data: Component;
    switchId?: string;
    inputType?: string;
}

const TableInput = ({ data, switchId }: TableInputProps) => {
    const {
        label = "",
        parameterId,
        placeholder,
        parameterValue,
        increment,
        textMinLength,
        textMaxLength,
        validate,
        validationMessage,
    } = data;

    const {
        colors: {
            blue700,
            textDarkDisabled,
            textDark,
            textPlaceholder,
            gray100,
            red700,
        },
        boxShadows: { focusBoxShadow },
    } = useContext(ThemeContext);
    const { updateParameter, findParameterValueById, setParameterError } =
        useContext(MenuItemContext);
    const { isParameterIdsHidden } = useContext(SettingsContext);
    const debouncedChangeHandler = useDebounce(updateParameter, 500);
    const hasBeenMounted = useRef<boolean>(false);
    const { t } = useContext(LanguageContext);

    const [value, setValue] = useState(parameterValue);
    const [previousValue, setPreviousValue] = useState(parameterValue);
    const [loading, setLoading] = useState(false);
    const [requestError, setRequestError] = useState(false);
    const [validationError, setValidationError] = useState<string | null>(null);

    const elementRef = useRef(null);

    const paramValFromContext = findParameterValueById(parameterId);

    useEffect(() => {
        if (paramValFromContext) {
            setValue(paramValFromContext);
            setPreviousValue(paramValFromContext);
        }
        // eslint-disable-next-line
    }, [paramValFromContext]);

    useEffect(() => {
        if (hasBeenMounted.current) {
            setParameterError(parameterId, validationError || requestError);
        } else {
            hasBeenMounted.current = true;
        }
        // eslint-disable-next-line
    }, [validationError, requestError]);

    const onSuccessfulRequest = (val: string) => {
        requestError && setRequestError(false);
        setPreviousValue(val);
    };

    const onFailedRequest = () => {
        setRequestError(true);
        setValue(previousValue);
    };

    const onCanceledRequest = () => {
        setValue(previousValue);
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value;

        const errorValue = validateTextLengthAndRegex(
            newValue,
            textMinLength,
            textMaxLength,
            validate || "",
            validationMessage || "",
            t
        );
        setValidationError(null);
        setValue(String(newValue));

        if (errorValue) {
            setValidationError(errorValue);
            setLoading(false);
            debouncedChangeHandler.cancel();
            return;
        }
        if (newValue.length === 0) {
            return;
        }
        setLoading(true);

        debouncedChangeHandler(
            parameterId,
            newValue,
            label,
            elementRef,
            () => onSuccessfulRequest(newValue),
            onFailedRequest,
            () => setLoading(false),
            true,
            onCanceledRequest
        );
    };
    const isInputDisabled =
        findParameterValueById(switchId) === TableSwitchDefaultValue.OFF;

    const renderBorderColor = () => {
        if (validationError || requestError) {
            return `1px solid ${red700}`;
        }
        return "none";
    };

    const tooltipTitle = validationError ? validationError : "";

    return (
        <Tooltip title={tooltipTitle} small placement="top">
            <Fragment>
                <span
                    css={css({
                        display: "block",
                        width: "100%",
                        height: "100%",
                        position: "relative",
                    })}
                >
                    <input
                        data-test={String(parameterId)}
                        css={css({
                            height: "99%", //for borders to be visible
                            backgroundColor: "unset",
                            boxSizing: "border-box",
                            width: "99%",
                            padding: "0 84px 0px 16px",
                            color: textDark,
                            fontWeight: "600",
                            lineHeight: "20px",
                            letterSpacing: "0.1px",
                            fontFamily: "Open Sans",
                            fontSize: "14px",
                            border: renderBorderColor(),
                            position: "relative",
                            "&:disabled": {
                                pointerEvents: isInputDisabled
                                    ? "none"
                                    : "auto",
                                backgroundColor: isInputDisabled
                                    ? gray100
                                    : "unset",
                                color: isInputDisabled
                                    ? textDarkDisabled
                                    : "unset",
                            },
                            "&:focus": {
                                outline: "none",
                                border: `1px solid ${blue700}`,
                                boxShadow: focusBoxShadow,
                            },
                            "&::placeholder": {
                                color: textPlaceholder,
                                lineHeight: "20px",
                                opacity: "1",
                            },
                        })}
                        ref={elementRef}
                        type={"text"}
                        placeholder={placeholder ? placeholder : ""}
                        value={value}
                        onChange={handleChange}
                        onKeyDown={noop}
                        disabled={isInputDisabled}
                        step={increment}
                    />
                    {loading && (
                        <CircularProgress
                            size={13}
                            css={css`
                                color: ${blue700};
                                position: absolute;
                                right: 72px;
                                bottom: 42%;
                            `}
                        />
                    )}
                </span>
                {parameterId && !isParameterIdsHidden ? (
                    <Tooltip
                        title={getIdTooltipText(parameterId, 0)}
                        small
                        placement="top"
                    >
                        <span
                            css={css({
                                position: "absolute",
                                right: "24px",
                                top: "22px",
                            })}
                        >
                            <Tag size="tiny" color="white" title="ID" />
                        </span>
                    </Tooltip>
                ) : null}
            </Fragment>
        </Tooltip>
    );
};

export default TableInput;
