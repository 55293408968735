import {
    FILTER_COLUMNS_BY_NAME,
    RESET_BLUETOOTH_VIEW,
    RESET_COLUMN,
    RESET_COLUMNS,
    SET_ACTIVE_STATUS_FILTER,
    SET_ADD_DEVICE_MODAL,
    SET_BLUETOOTH_DATA,
    SET_BLUETOOTH_MODAL_NAME,
    SET_BLUETOOTH_MODAL_PRESET,
    SET_BLUETOOTH_MODAL_TABLE,
    SET_BLUETOOTH_TOTAL_COUNT,
    SET_EDIT_DEVICE_MODAL,
    SET_MODAL_DATA,
    SET_RELOAD_BLUETOOTH_VIEW,
    SET_SUBMIT_DISABLED,
    SET_TABLE_SEARCH_VALUE,
} from "./bluetoothViewActions";
export interface InitialState {
    columns: any;
    activeColumns: string[];
    bluetoothData: any;
    bluetoothModalTable: any;
    bluetoothModalPreset: any;
    isSubmitDisabled: boolean;
    activeStatusFilter: string;
    searchValue: string;
    bluetoothModalName: string;
    modalData: any;
    isAddDeviceModalOpen: boolean;
    isEditDeviceModalOpen: boolean;
    reloadBluetoothView: boolean;
    bluetoothTotalCount: number;
}

type Action =
    | { type: typeof FILTER_COLUMNS_BY_NAME; payload: string }
    | { type: typeof RESET_COLUMN; payload: string }
    | { type: typeof RESET_COLUMNS; payload: string[] }
    | { type: typeof SET_BLUETOOTH_DATA; payload: any }
    | { type: typeof SET_BLUETOOTH_MODAL_TABLE; payload: any }
    | { type: typeof SET_BLUETOOTH_MODAL_PRESET; payload: any }
    | { type: typeof SET_SUBMIT_DISABLED; payload: boolean }
    | { type: typeof SET_ACTIVE_STATUS_FILTER; payload: any }
    | { type: typeof SET_TABLE_SEARCH_VALUE; payload: any }
    | {
          type: typeof SET_BLUETOOTH_MODAL_NAME;
          payload: any;
      }
    | {
          type: typeof SET_MODAL_DATA;
          payload: any;
      }
    | {
          type: typeof SET_ADD_DEVICE_MODAL;
          payload: boolean;
      }
    | {
          type: typeof SET_EDIT_DEVICE_MODAL;
          payload: boolean;
      }
    | {
          type: typeof SET_RELOAD_BLUETOOTH_VIEW;
          payload: boolean;
      }
    | {
          type: typeof SET_BLUETOOTH_TOTAL_COUNT;
          payload: number;
      }
    | {
          type: typeof RESET_BLUETOOTH_VIEW;
      };

export default (state: InitialState, action: Action): InitialState => {
    switch (action.type) {
        case FILTER_COLUMNS_BY_NAME: {
            return {
                ...state,
                activeColumns: [...state.activeColumns, action.payload],
            };
        }
        case RESET_COLUMN: {
            return {
                ...state,
                activeColumns: state.activeColumns.filter(
                    (x) => x !== action.payload
                ),
            };
        }
        case RESET_COLUMNS: {
            return {
                ...state,
                activeColumns: action.payload,
            };
        }
        case SET_BLUETOOTH_DATA: {
            return {
                ...state,
                bluetoothData: action.payload,
            };
        }
        case SET_BLUETOOTH_MODAL_TABLE: {
            return {
                ...state,
                bluetoothModalTable: action.payload,
            };
        }
        case SET_BLUETOOTH_MODAL_PRESET: {
            return {
                ...state,
                bluetoothModalPreset: action.payload,
            };
        }
        case SET_SUBMIT_DISABLED: {
            return {
                ...state,
                isSubmitDisabled: action.payload,
            };
        }
        case SET_ACTIVE_STATUS_FILTER: {
            return {
                ...state,
                activeStatusFilter: action.payload,
            };
        }
        case SET_TABLE_SEARCH_VALUE: {
            return {
                ...state,
                searchValue: action.payload,
            };
        }
        case SET_BLUETOOTH_MODAL_NAME: {
            return {
                ...state,
                bluetoothModalName: action.payload,
            };
        }
        case SET_MODAL_DATA: {
            return {
                ...state,
                modalData: action.payload,
            };
        }
        case SET_ADD_DEVICE_MODAL: {
            return {
                ...state,
                isAddDeviceModalOpen: action.payload,
            };
        }
        case SET_EDIT_DEVICE_MODAL: {
            return {
                ...state,
                isEditDeviceModalOpen: action.payload,
            };
        }
        case SET_RELOAD_BLUETOOTH_VIEW: {
            return {
                ...state,
                reloadBluetoothView: action.payload,
            };
        }
        case SET_BLUETOOTH_TOTAL_COUNT: {
            return {
                ...state,
                bluetoothTotalCount: action.payload,
            };
        }
        case RESET_BLUETOOTH_VIEW: {
            return {
                ...state,
                bluetoothData: null,
                bluetoothModalTable: null,
                bluetoothModalPreset: null,
                isSubmitDisabled: false,
                searchValue: "",
                bluetoothModalName: "",
                modalData: null,
                isAddDeviceModalOpen: false,
                isEditDeviceModalOpen: false,
                reloadBluetoothView: false,
                bluetoothTotalCount: 0,
            };
        }
        default:
            return state;
    }
};
