/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from "@emotion/react";

import useMediaQueries from "../../../../utils/useMediaQueries";
import Content from "./Content";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";

const NoSearchResults = () => {
    const { toMd } = useMediaQueries();

    return toMd ? (
        <Content />
    ) : (
        <TableRow
            css={css`
                & > td {
                    white-space: unset;
                }
            `}
        >
            <TableCell colSpan={4}>
                <Content />
            </TableCell>
        </TableRow>
    );
};

export default NoSearchResults;
