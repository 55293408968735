/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import Tooltip from "../../Tooltip";

export interface CellWithTooltipProps {
    rowValue: string;
    maxShownStringLength: number;
}

const CellWithTooltip: React.FunctionComponent<CellWithTooltipProps> = ({
    rowValue,
    maxShownStringLength,
}) => {
    return (
        <Tooltip
            title={rowValue.length > maxShownStringLength ? rowValue : ""}
            small
            placement="top"
        >
            <span
                css={css({
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    paddingRight: "4px",
                })}
            >
                {rowValue ? rowValue : ""}
            </span>
        </Tooltip>
    );
};

export default CellWithTooltip;
