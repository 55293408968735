/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/react";

import Tooltip from "./Tooltip";
import { getIdTooltipText } from "../../utils/helpers";
import Tag from "./Tag";
import { useContext } from "react";
import SettingsContext from "../../context/settings/settingsContext";

interface IdBadgeProps {
    parameterId: number;
    avlId?: number;
}

const IdBadge = ({ parameterId, avlId }: IdBadgeProps) => {
    const { isParameterIdsHidden } = useContext(SettingsContext);

    return isParameterIdsHidden || !Boolean(parameterId) ? null : (
        <Tooltip
            title={getIdTooltipText(parameterId, avlId ?? 0)}
            small
            placement="top"
        >
            <span>
                <Tag size="tiny" color="white" title="ID" />
            </span>
        </Tooltip>
    );
};

export default IdBadge;
