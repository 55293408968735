import {
    SET_EXPANDED,
    SET_STEP,
    SET_LOADING,
    SET_ERROR,
    SET_BUNDLE_STEPS,
    SET_FILE,
} from "./bundleUpdateActions";
import { BundleUpdateContextInitialState } from "./bundleUpdateContext";

export default (state: BundleUpdateContextInitialState, action: any) => {
    switch (action.type) {
        case SET_STEP: {
            return {
                ...state,
                step: action.payload,
            };
        }
        case SET_FILE: {
            return {
                ...state,
                file: action.payload,
            };
        }
        case SET_BUNDLE_STEPS:
            return {
                ...state,
                bundleSteps: action.payload,
            };
        case SET_EXPANDED: {
            return {
                ...state,
                expanded: action.payload,
            };
        }
        case SET_LOADING: {
            return {
                ...state,
                loading: action.payload,
            };
        }
        case SET_ERROR: {
            return {
                ...state,
                error: action.payload,
            };
        }
        default:
            return state;
    }
};
