/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from "@emotion/react";

import { useContext } from "react";
import ThemeContext from "../../../../context/theme/themeContext";
import LanguageContext from "../../../../context/language/languageContext";
import ConfigurationContext from "../../../../context/configuration/configurationContext";
import useMediaQueries from "../../../../utils/useMediaQueries";
import { ReactComponent as ConfigurationImage } from "../../../../assets/Configuration.svg";
import Button from "../../../MuiComponents/Button";

const Content = () => {
    const {
        colors: { white, gray700 },
    } = useContext(ThemeContext);

    const { t } = useContext(LanguageContext);

    const { searchConfigurations, searchValue } =
        useContext(ConfigurationContext);

    const { toMd } = useMediaQueries();

    return (
        <div
            css={css`
                display: flex;
                flex-direction: column;
                align-items: center;
                flex: ${toMd ? "1" : "unset"};
                background: ${white};
                height: 100%;
                padding: ${toMd ? "16px" : "0"};

                svg {
                    margin-top: ${toMd ? "0" : "6px"};
                }

                h4 {
                    margin: 16px 0 8px 0;
                    font-size: 16px;
                    line-height: 20px;
                    font-weight: 600;
                    letter-spacing: 0.15px;
                    word-break: break-word;
                    text-align: center;
                }

                p {
                    color: ${gray700};
                    font-weight: 400;
                    letter-spacing: 0.1px;
                    margin-bottom: 12px;
                    text-align: center;
                }
            `}
        >
            <ConfigurationImage />

            <h4>
                {t.NoResultsFor} "{searchValue}"
            </h4>

            <p>{t.TryOtherKeyword}</p>

            <Button
                variant="textOnly"
                size="normal"
                color="primary"
                onClick={() => searchConfigurations("")}
                idForTesting="reset-keyword-btn"
            >
                {t.ResetKeyword}
            </Button>
        </div>
    );
};

export default Content;
