import {
    ListItemInterface,
    OfflineOnlineDataWithSettings,
} from "../../utils/types";
import {
    DISABLE_NAVIGATION_BETWEEN_MENU_ITEMS,
    RELOAD_MENU_ITEM,
    RESET_LAYOUT_DATA,
    SET_ACTIVE_DEVICE_GUIDE_COMPONENT_INDEX,
    SET_ACTIVE_DEVICE_GUIDE_FRAME_INDEX,
    SET_ACTIVE_FRAME_INDEX,
    SET_DEVICE_CONFIGURATION_BUTTON_PORT_NAME,
    SET_DEVICE_GUIDE,
    SET_DRAWER_BLOCK_NAME,
    SET_DRAWER_DATA,
    SET_LAYOUT_DATA,
    SET_LAYOUT_ERROR,
    SET_LIST_VALUES,
    SET_NEXT_MENU_ITEM_INDEX,
    SET_UPLOADED_FILE_DATA_TO_LAYOUT,
    SET_UPLOAD_FILE_LOAD_STATUS,
    SET_UPLOAD_FILE_NAME,
    START_ACTION_LOADING,
    STOP_ACTION_LOADING,
} from "./layoutActions";
import { DeviceGuide } from "../../generatedTypes";
import { DeviceGuideType } from "../../constants/constants";

export interface InitialState {
    layoutLoading: boolean;
    layoutError: boolean;
    layoutData: OfflineOnlineDataWithSettings | null;
    listValues: { [key: string]: ListItemInterface[] };
    actionLoading: boolean;
    rebooting: boolean;
    readingAccelometer: boolean;
    capturingDump: boolean;
    fwUpdating: boolean;
    configResetting: boolean;
    navigationDisabled: boolean;
    menuItemIndex: number | null | undefined;
    activeFrameIndex: number | null | undefined;
    activeDeviceGuideComponentIndex: number | null;
    activeDeviceGuideFrameIndex: number | null;
    drawerParameterId: number;
    drawerBlockName: string;
    bluetoothLayoutResetting: boolean;
    reloadMenuItem: boolean;
    layoutErrorMessage: string;
    deviceConfigureButtonPortName: string;
    deviceGuide: DeviceGuide | null;
    uploadFileLoadStatus: string;
    uploadFileName: string;
}

type Action =
    | { type: typeof SET_LAYOUT_ERROR; payload: string }
    | { type: typeof SET_LAYOUT_DATA; payload: any }
    | { type: typeof START_ACTION_LOADING; payload: string }
    | {
          type: typeof STOP_ACTION_LOADING;
      }
    | {
          type: typeof SET_ACTIVE_FRAME_INDEX;
          payload: number | null | undefined;
      }
    | {
          type: typeof SET_ACTIVE_DEVICE_GUIDE_COMPONENT_INDEX;
          payload: number | null;
      }
    | {
          type: typeof SET_ACTIVE_DEVICE_GUIDE_FRAME_INDEX;
          payload: number | null;
      }
    | { type: typeof RESET_LAYOUT_DATA }
    | {
          type: typeof DISABLE_NAVIGATION_BETWEEN_MENU_ITEMS;
          payload: boolean;
      }
    | { type: typeof SET_NEXT_MENU_ITEM_INDEX; payload: number }
    | {
          type: typeof SET_LIST_VALUES;
          payload: { [key: string]: any[] };
      }
    | {
          type: typeof SET_DRAWER_DATA;
          payload: number;
      }
    | {
          type: typeof SET_DRAWER_BLOCK_NAME;
          payload: string;
      }
    | {
          type: typeof RELOAD_MENU_ITEM;
          payload: boolean;
      }
    | {
          type: typeof SET_DEVICE_CONFIGURATION_BUTTON_PORT_NAME;
          payload: string;
      }
    | { type: typeof SET_DEVICE_GUIDE; payload: DeviceGuide | null }
    | {
          type: typeof SET_UPLOADED_FILE_DATA_TO_LAYOUT;
          payload: any;
      }
    | { type: typeof SET_UPLOAD_FILE_LOAD_STATUS; payload: string }
    | { type: typeof SET_UPLOAD_FILE_NAME; payload: string };

export default (state: InitialState, action: Action): InitialState => {
    switch (action.type) {
        case SET_LAYOUT_ERROR: {
            return {
                ...state,
                layoutLoading: false,
                layoutError: true,
                layoutData: null,
                layoutErrorMessage: action.payload,
            };
        }
        case SET_UPLOAD_FILE_LOAD_STATUS: {
            return {
                ...state,
                uploadFileLoadStatus: action.payload,
            };
        }
        case SET_UPLOAD_FILE_NAME: {
            return {
                ...state,
                uploadFileName: action.payload,
            };
        }
        case SET_UPLOADED_FILE_DATA_TO_LAYOUT: {
            return {
                ...state,
                layoutData: {
                    ...state.layoutData,
                    fromFile: action.payload.fromFile,
                    path: action.payload.path,
                } as any,
            };
        }
        case SET_LAYOUT_DATA: {
            return {
                ...state,
                layoutData: action.payload,
                layoutLoading: false,
                layoutError: false,
                layoutErrorMessage: "",
                deviceGuide: null,
            };
        }
        case SET_ACTIVE_FRAME_INDEX: {
            return {
                ...state,
                activeFrameIndex: action.payload,
            };
        }
        case SET_ACTIVE_DEVICE_GUIDE_COMPONENT_INDEX: {
            return {
                ...state,
                activeDeviceGuideComponentIndex: action.payload,
            };
        }
        case SET_ACTIVE_DEVICE_GUIDE_FRAME_INDEX: {
            return {
                ...state,
                activeDeviceGuideFrameIndex: action.payload,
            };
        }
        case START_ACTION_LOADING: {
            return {
                ...state,
                actionLoading: true,
                ...(action.payload === "rebooting" && { rebooting: true }),
                ...(action.payload === "readingAccelometer" && {
                    readingAccelometer: true,
                }),
                ...(action.payload === "capturingDump" && {
                    capturingDump: true,
                }),
                ...(action.payload === "fwUpdating" && { fwUpdating: true }),
                ...(action.payload === "configResetting" && {
                    configResetting: true,
                }),
                ...(action.payload === "bluetoothLayoutResetting" && {
                    bluetoothLayoutResetting: true,
                }),
            };
        }
        case STOP_ACTION_LOADING: {
            return {
                ...state,
                actionLoading: false,
                rebooting: false,
                readingAccelometer: false,
                capturingDump: false,
                fwUpdating: false,
                configResetting: false,
                bluetoothLayoutResetting: false,
            };
        }
        case RESET_LAYOUT_DATA: {
            return {
                ...state,
                layoutLoading: true,
                layoutError: false,
                layoutErrorMessage: "",
                layoutData: null,
                rebooting: false,
                readingAccelometer: false,
                capturingDump: false,
                deviceGuide: null,
            };
        }
        case DISABLE_NAVIGATION_BETWEEN_MENU_ITEMS: {
            return {
                ...state,
                navigationDisabled: action.payload,
            };
        }
        case SET_NEXT_MENU_ITEM_INDEX: {
            return {
                ...state,
                menuItemIndex: action.payload,
            };
        }
        case SET_LIST_VALUES: {
            return {
                ...state,
                listValues: { ...state.listValues, ...action.payload },
            };
        }
        case SET_DRAWER_DATA: {
            return {
                ...state,
                drawerParameterId: action.payload,
            };
        }
        case SET_DRAWER_BLOCK_NAME: {
            return {
                ...state,
                drawerBlockName: action.payload,
            };
        }
        case RELOAD_MENU_ITEM: {
            return {
                ...state,
                reloadMenuItem: action.payload,
            };
        }
        case SET_DEVICE_CONFIGURATION_BUTTON_PORT_NAME: {
            return {
                ...state,
                deviceConfigureButtonPortName: action.payload,
            };
        }
        case SET_DEVICE_GUIDE: {
            if (action.payload === undefined || action.payload === null)
                return {
                    ...state,
                    deviceGuide: null,
                };
            const uncheckedNecessarySteps = action.payload?.guideSteps?.filter(
                (step: any) =>
                    step.guideType === (DeviceGuideType.Necessary as string) &&
                    !step.isChecked
            );
            const numberOfUncheckedNecessarySteps =
                uncheckedNecessarySteps?.length;

            return {
                ...state,
                deviceGuide: {
                    ...action.payload,
                    notCompletedSteps: numberOfUncheckedNecessarySteps,
                },
            };
        }
        default:
            return state;
    }
};
