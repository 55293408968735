import React, { useContext } from "react";
import SearchContext from "../../../../../context/search/searchContext";
import NoHistoryResults from "./NoHistoryResults";
import HistoryResultsList from "./HistoryResultsList";

const HistoryResults = () => {
    const { historyResults } = useContext(SearchContext);

    return !historyResults ? <NoHistoryResults /> : <HistoryResultsList />;
};

export default HistoryResults;
