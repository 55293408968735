/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import {
    Checkbox,
    ListItemIcon,
    ListItemText,
    MenuItem,
    SelectChangeEvent,
} from "@mui/material";
import { useContext } from "react";
import {
    IoTableColumns,
    priorityColumns,
} from "../../../../../../../../constants/constants";
import InputOutputContext from "../../../../../../../../context/inputOutput/inputOutputContext";
import Select from "../../../../../../../MuiComponents/Select";

const PrioritySelect = () => {
    const { setActivePriority, activePriority, activeColumns } =
        useContext(InputOutputContext);

    const handleChange = (event: SelectChangeEvent<any>) => {
        const value = event.target.value;
        setActivePriority(value);
    };
    const isCheckboxChecked = (value: string) => activePriority.includes(value);
    const isDisabled = !activeColumns.some(
        (item) => item === IoTableColumns.priority
    );

    return (
        <Select
            multiple
            value={activePriority}
            onChange={handleChange}
            wrapperStyle={css({ marginBottom: "12px" })}
            placeholder="Priority"
            renderValue={(selected) => {
                const renderNames = selected.map(
                    (item: string) => priorityColumns[Number(item) - 1].name
                );
                return <div>{renderNames.join(", ")}</div>;
            }}
            disabled={isDisabled}
        >
            {priorityColumns.map((column: any) => (
                <MenuItem
                    key={column.name}
                    value={column.value}
                    css={{ padding: "14px 2px 14px 16px" }}
                >
                    <ListItemText
                        primary={column.name}
                        sx={{
                            ".MuiListItemText-primary": {
                                fontWeight: "600",
                                fontSize: "14px",
                            },
                        }}
                    />
                    <ListItemIcon>
                        <Checkbox checked={isCheckboxChecked(column.value)} />
                    </ListItemIcon>
                </MenuItem>
            ))}
        </Select>
    );
};

export default PrioritySelect;
