/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/react";
import { Fragment, SyntheticEvent, useContext, useState } from "react";
import UserInfoContext from "../../../../../context/userInfo/userInfoContext";
import SaveConfigurationModal from "../SaveConfigurationModal";
import { SaveToFileProps } from "./SaveToLocalFile";
import LayoutContext from "../../../../../context/layout/layoutContext";
import AlertContext from "../../../../../context/alert/alertContext";
import LanguageContext from "../../../../../context/language/languageContext";
import SaveButton from "./SaveButton";
import DevicePasswordModal from "./DevicePasswordModal";
import { ConfigurationPasswordState } from "../../../../../utils/types";
import useTct from "../../../../../utils/useTct";
import { AlertStatus } from "../../../../../constants/constants";

export interface SaveToDeviceProps extends SaveToFileProps {
    renderParameterErrors: (error: any) => void;
}

const SaveToDevice = ({
    isDisabled,
    isModalOpen,
    setModalOpen,
    modifiedParameterResponse,
    isMobileSaveModalOpen,
    setMobileSaveModal,
    renderParameterErrors,
    isSaving,
    setIsSaving,
}: SaveToDeviceProps) => {
    const { setAlert } = useContext(AlertContext);

    const { isDeviceDisconnected } = useContext(UserInfoContext);
    const { t } = useContext(LanguageContext);
    const { layoutData } = useContext(LayoutContext);

    const [isDevicePasswordModalOpen, setDevicePasswordModalOpen] =
        useState(false);

    const { saveToDeviceAsync } = useTct();

    const isPrimarySet =
        modifiedParameterResponse.configurationPasswordState ===
        ConfigurationPasswordState.Set;
    const isRecoverySet =
        modifiedParameterResponse.recoveryConfigurationPasswordState ===
        ConfigurationPasswordState.Set;

    const isPrimaryChanged =
        modifiedParameterResponse.configurationPasswordState ===
        ConfigurationPasswordState.Changed;
    const isRecoveryChanged =
        modifiedParameterResponse.recoveryConfigurationPasswordState ===
        ConfigurationPasswordState.Changed;

    const isPrimarySame =
        modifiedParameterResponse.configurationPasswordState ===
        ConfigurationPasswordState.Same;

    const isRecoverySame =
        modifiedParameterResponse.recoveryConfigurationPasswordState ===
        ConfigurationPasswordState.Same;

    const closeSaveChangesToDeviceModal = () => setModalOpen(false);
    const closeDevicePasswordModal = () => {
        isDevicePasswordModalOpen && setDevicePasswordModalOpen(false);
    };

    const handleSaveToDevice = async (e: SyntheticEvent) => {
        e.preventDefault();

        if (
            modifiedParameterResponse.configurationPasswordState ||
            modifiedParameterResponse.recoveryConfigurationPasswordState
        ) {
            setDevicePasswordModalOpen(true);
            return;
        } else {
            await saveToDevice(false);
        }
    };

    const saveToDevice = async (savePassword: boolean) => {
        isModalOpen && closeSaveChangesToDeviceModal();
        isMobileSaveModalOpen && setMobileSaveModal(false);
        closeDevicePasswordModal();

        if (
            !savePassword &&
            !isPrimarySame &&
            !isRecoverySame &&
            modifiedParameterResponse.parameters.length == 2
        ) {
            // If password is not the same modified parameters count is 1
            // If user decided not to save password to device, do nothing
            return;
        }

        if (
            !savePassword &&
            !isPrimarySame &&
            modifiedParameterResponse.parameters.length == 1
        ) {
            // If password is not the same modified parameters count is 1
            // If user decided not to save password to device, do nothing
            return;
        }

        if (
            !savePassword &&
            !isRecoverySame &&
            modifiedParameterResponse.parameters.length == 1
        ) {
            // If password is not the same modified parameters count is 1
            // If user decided not to save password to device, do nothing
            return;
        }

        try {
            if (!layoutData) {
                throw new Error("No layout");
            }

            setIsSaving(true);

            await saveToDeviceAsync(layoutData.id, !savePassword);

            setAlert(AlertStatus.Success, t.ConfigurationSuccessfullySaved);
        } catch (error) {
            renderParameterErrors(error);
        }

        setIsSaving(false);
    };

    const isPrimaryButtonDisabled = () => {
        if (
            !modifiedParameterResponse.parameters.length &&
            isPrimarySame &&
            isRecoverySame
        ) {
            return true;
        }
        return false;
    };

    const renderTitle = () => {
        switch (true) {
            case isPrimarySet && isRecoverySet:
            case isPrimaryChanged && isRecoveryChanged:
            case isPrimarySet && isRecoveryChanged:
            case isPrimaryChanged && isRecoverySet:
                return t.PasswordsHasBeenChangedTitle;
            case isPrimarySet:
                return t.SetConfigurationPassword;
            case isRecoverySet:
                return t.SetConfigurationPasswordRecovery;
            case isPrimaryChanged:
                return t.ChangeConfigurationPassword;
            case isRecoveryChanged:
                return t.ChangeConfigurationPasswordRecovery;
            default:
                return "";
        }
    };

    const renderDescription = () => {
        switch (true) {
            case isPrimarySet && isRecoverySet:
            case isPrimaryChanged && isRecoveryChanged:
            case isPrimarySet && isRecoveryChanged:
            case isPrimaryChanged && isRecoverySet:
                return t.PasswordsHasBeenChanged;
            case isPrimarySet:
                return t.PasswordSetWouldYouLikeToSetOnDevice;
            case isRecoverySet:
                return t.PasswordSetWouldYouLikeToSetOnDeviceRecovery;
            case isPrimaryChanged:
                return t.PasswordChangedWouldYouLikeToChangeOnDevice;
            case isRecoveryChanged:
                return t.PasswordChangedWouldYouLikeToChangeOnDeviceRecovery;
            default:
                return "";
        }
    };

    return (
        <Fragment>
            <SaveButton
                isDisabled={isDisabled || isDeviceDisconnected}
                setModalOpen={setModalOpen}
                isSaving={isSaving}
                btnLabel={t.SaveToDevice}
                isPrimaryColor={true}
                idForTesting="save-to-device-button"
            />
            <SaveConfigurationModal
                title={t.SaveToDevicePrompt}
                isOpen={isModalOpen}
                onClose={closeSaveChangesToDeviceModal}
                primaryBtnText={t.Save}
                onPrimaryBtnClick={handleSaveToDevice}
                modifiedParameters={modifiedParameterResponse}
                isPrimaryButtonDisabled={isPrimaryButtonDisabled()}
                hasNoInfoBanner
            />
            <DevicePasswordModal
                isOpen={isDevicePasswordModalOpen}
                closeModal={() => setDevicePasswordModalOpen(false)}
                onClick={saveToDevice}
                title={renderTitle()}
                description={renderDescription()}
            />
        </Fragment>
    );
};

export default SaveToDevice;
