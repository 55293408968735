/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import { useContext } from "react";
import ThemeContext from "../../../../context/theme/themeContext";
import { ExpandMore as ExpandMoreIcon } from "@mui/icons-material";
import CheckWhiteIcon from "../../../../assets/icons/CheckWhiteIcon";

export interface ListItemProps {
    onClick: () => void;
    label: string;
    isChecked: boolean;
    isFinalItem?: boolean;
}

const ListItem = ({
    onClick,
    label,
    isChecked,
    isFinalItem,
}: ListItemProps) => {
    const {
        colors: { gray100, textDarkDisabled },
    } = useContext(ThemeContext);
    return (
        <div
            css={css({
                display: "flex",
                padding: "8px 26px 8px 20px",
                marginBottom: isFinalItem ? "16px" : "0px",
                justifyContent: "space-between",
                alignItems: "center",
                ":hover": {
                    backgroundColor: gray100,
                    cursor: "pointer",
                },
            })}
            data-test={label}
            onClick={onClick}
        >
            <div
                css={css({
                    display: "flex",
                    alignItems: "center",
                })}
            >
                <span
                    css={css({
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        borderRadius: "50%",
                        width: "20px",
                        height: "20px",
                        border: !isChecked
                            ? `2px solid ${textDarkDisabled}`
                            : "unset",
                        backgroundColor: isChecked ? "green" : "unset",
                        "& > svg": {
                            width: 16,
                        },
                    })}
                >
                    {isChecked && <CheckWhiteIcon />}
                </span>
                <div
                    css={css({
                        marginLeft: "12px",
                        fontWeight: 600,
                        letterSpacing: "0.1px",
                    })}
                >
                    {label}
                </div>
            </div>
            <div
                css={css({
                    transform: "rotate(-90deg)",
                })}
            >
                <ExpandMoreIcon />
            </div>
        </div>
    );
};

export default ListItem;
