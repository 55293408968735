import React from "react";
import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

const SearchParametersIcon = (props: SvgIconProps) => {
    return (
        <SvgIcon viewBox="0 0 16 16" {...props}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M14.6667 6.26663V11.0666C14.6667 11.9466 13.9467 12.6666 13.0667 12.6666H12.2667H10.6667H2.93337C2.05337 12.6666 1.33337 11.9466 1.33337 11.0666V6.26663C1.33337 5.38663 2.05337 4.66663 2.93337 4.66663H13.0667C13.9467 4.66663 14.6667 5.38663 14.6667 6.26663ZM12.3333 11.3333H13.3333V6H2.66667V11.3333H11.3333H12.3333Z"
                fill="currentColor"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M3.99996 9.66671L3.99996 7.66671C3.99996 7.48337 4.14996 7.33337 4.33329 7.33337C4.51663 7.33337 4.66663 7.48337 4.66663 7.66671L4.66663 9.66671C4.66663 9.85004 4.51663 10 4.33329 10C4.14996 10 3.99996 9.85004 3.99996 9.66671Z"
                fill="currentColor"
            />
        </SvgIcon>
    );
};

export default SearchParametersIcon;
