/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from "@emotion/react";

import { useContext, useEffect, useState } from "react";
import LanguageContext from "../../../../../context/language/languageContext";
import UserInfoContext from "../../../../../context/userInfo/userInfoContext";
import useMediaQuery from "../../../../../utils/useMediaQueries";
import useApi from "../../../../../utils/useApi";
import useSettings from "./useSettings";
import CaptureLog from "./CaptureLog";
import CaptureAccelerometer from "./CaptureAccelerometer";
import Presets from "./Presets";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "../../../../MuiComponents/Button";
import CloseIcon from "@mui/icons-material/CloseRounded";
import Grid from "@mui/material/Grid";
import SettingsContext from "../../../../../context/settings/settingsContext";
import TypedGrid from "../../../../MuiComponents/TypedGrid";
import ConfiguratorLog from "./ConfiguratorLog";
import ReadRecords from "./ReadRecords";
import useTct from "../../../../../utils/useTct";

const StartupSettings = () => {
    const { t, selectedLanguage } = useContext(LanguageContext);

    const { configuratorVersion } = useContext(UserInfoContext);

    const { isGeneralSettingsModalOpen, closeGeneralSettingsModal } =
        useContext(SettingsContext);

    const { toMd } = useMediaQuery();
    const { cancelSource, isCanceled } = useApi();
    const { postSettingsAsync, getSettingsAsync } = useTct();

    const { isEmpty, validNumericValue } = useSettings();

    const minTime = 1;
    const maxTimeLog = 15300;
    const maxTimeAccelerometer = 60;

    const [wrongValue, setWrongValue] = useState(true);

    const [focusSubmitBtn, setfocusSubmitBtn] = useState(false);

    const [settingsData, setSettingsData] = useState({
        captureLogPath: "",
        captureLogTime: "",
        captureAccelerometerPath: "",
        captureAccelerometerTime: "",
        presetsPath: "",
        recordsPath: "",
        configuratorLogPath: "",
    });

    const {
        captureLogPath,
        captureLogTime,
        captureAccelerometerPath,
        captureAccelerometerTime,
        presetsPath,
        recordsPath,
        configuratorLogPath,
    } = settingsData;

    useEffect(() => {
        let isSubscribed = true;

        const getSettingsData = async () => {
            try {
                const data = await getSettingsAsync();
                if (isSubscribed) {
                    setSettingsData({
                        ...settingsData,
                        captureLogPath: data.captureLogPath,
                        captureLogTime: `${data.captureTimeMin}`, // conversion to string - best performance
                        captureAccelerometerPath: data.captureAccelerometerPath,
                        captureAccelerometerTime: `${data.captureTimeSec}`, // conversion to string - best performance
                        presetsPath: data.presetsPath,
                        recordsPath: data.recordsPath,
                        configuratorLogPath: data.configuratorLogPath,
                    });
                }
            } catch (error) {
                if (isSubscribed && !isCanceled(error)) {
                    console.log("error: ", error);
                }
            }
        };

        isGeneralSettingsModalOpen && getSettingsData();

        return () => {
            isSubscribed = false;
            cancelSource.cancel("Api is being cancelled");
        };

        // eslint-disable-next-line
    }, [isGeneralSettingsModalOpen]);

    useEffect(() => {
        const isWrong =
            isEmpty(captureLogPath) ||
            isEmpty(captureAccelerometerPath) ||
            isEmpty(recordsPath) ||
            isEmpty(presetsPath) ||
            !validNumericValue(captureLogTime, minTime, maxTimeLog) ||
            !validNumericValue(
                captureAccelerometerTime,
                minTime,
                maxTimeAccelerometer
            );

        setWrongValue(isWrong);

        // eslint-disable-next-line
    }, [
        captureLogPath,
        captureAccelerometerPath,
        presetsPath,
        recordsPath,
        captureLogTime,
        minTime,
        maxTimeLog,
        captureAccelerometerTime,
        minTime,
        maxTimeAccelerometer,
        recordsPath,
    ]);

    const handleSettingsData = (name: string, value: string) => {
        setSettingsData({
            ...settingsData,
            [name]: value,
        });

        (name === "captureLogPath" ||
            name === "captureAccelerometerPath" ||
            name === "certificatesPath" ||
            name === "presetsPath" ||
            name === "recordsPath") &&
            setfocusSubmitBtn(true);
    };

    const handleClose = () => {
        closeGeneralSettingsModal();

        setSettingsData({
            ...settingsData,
            captureLogPath: "",
            captureLogTime: "",
            captureAccelerometerPath: "",
            captureAccelerometerTime: "",
            presetsPath: "",
            recordsPath: "",
        });
    };

    const submit = async (e: any) => {
        e.preventDefault();

        if (!wrongValue) {
            try {
                await postSettingsAsync({
                    captureLogPath,
                    captureAccelerometerPath,
                    captureTimeMin: captureLogTime,
                    captureTimeSec: captureAccelerometerTime,
                    presetsPath,
                    recordsPath,
                });

                handleClose();
            } catch (error) {
                console.log("error: ", error);
            }
        }
    };

    return (
        <Dialog
            onClose={handleClose}
            open={isGeneralSettingsModalOpen}
            fullScreen={toMd}
            fullWidth
            maxWidth="md920"
        >
            <DialogTitle>
                {t.Settings}
                <Button
                    variant="iconOnly"
                    size="small"
                    color="white"
                    icon={<CloseIcon onClick={handleClose} />}
                    css={css`
                        position: absolute;
                        top: 4px;
                        right: 4px;
                    `}
                    idForTesting="closeGeneralSettingsModal"
                />
            </DialogTitle>

            <form
                noValidate
                onSubmit={submit}
                css={css`
                    display: flex;
                    flex-direction: column;
                    flex: 1 1 auto;
                `}
            >
                <DialogContent dividers>
                    <div
                        css={css`
                            display: flex;
                            flex-direction: column;
                            height: 100%;
                        `}
                    >
                        <Grid container columnSpacing={3} rowSpacing={4}>
                            <TypedGrid
                                item
                                xs0={12}
                                md={6}
                                css={css`
                                    & > div + div {
                                        margin-top: 32px;
                                    }
                                `}
                            >
                                <CaptureLog
                                    path={captureLogPath}
                                    time={captureLogTime}
                                    minTimeValue={minTime}
                                    maxTimeValue={maxTimeLog}
                                    validateNumber={validNumericValue}
                                    change={handleSettingsData}
                                />

                                <ConfiguratorLog path={configuratorLogPath} />
                            </TypedGrid>

                            <TypedGrid
                                item
                                xs0={12}
                                md={6}
                                css={css`
                                    & > div + div {
                                        margin-top: 32px;
                                    }
                                `}
                            >
                                <CaptureAccelerometer
                                    path={captureAccelerometerPath}
                                    time={captureAccelerometerTime}
                                    minTimeValue={minTime}
                                    maxTimeValue={maxTimeAccelerometer}
                                    validateNumber={validNumericValue}
                                    change={handleSettingsData}
                                />
                                <Presets
                                    path={presetsPath}
                                    change={handleSettingsData}
                                />
                                <ReadRecords
                                    path={recordsPath}
                                    change={handleSettingsData}
                                />
                            </TypedGrid>
                        </Grid>

                        <div
                            css={css`
                                display: flex;
                                justify-content: flex-end;
                                font-size: 12px;
                                line-height: 16px;
                                letter-spacing: 0.4px;
                                margin-top: 16px;

                                @media (max-width: 839px) {
                                    flex: 1;
                                    align-items: flex-end;
                                    margin-top: 0;
                                }
                            `}
                        >
                            {t.ConfiguratorVersion}: {configuratorVersion}
                        </div>
                    </div>
                </DialogContent>

                <DialogActions>
                    <Button
                        variant="textOnly"
                        size="normal"
                        color="secondary"
                        fullWidth
                        onClick={handleClose}
                        idForTesting="cancelGeneralSettingsModal"
                    >
                        {t.Close}
                    </Button>

                    <Button
                        disabled={wrongValue}
                        focus={focusSubmitBtn}
                        variant="textOnly"
                        size="normal"
                        color="primary"
                        fullWidth
                        type="submit"
                        onClick={submit}
                        idForTesting="saveGeneralSettingsModal"
                    >
                        {t.Save}
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    );
};

export default StartupSettings;
