/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import { MenuItem, SelectChangeEvent } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import {
    DriveTypes,
    DrivingModes,
    EcoCalculatorConstants,
    TransmissionTypes,
    valueByDriveType,
    valueByDrivingMode,
    valueByTransmissionType,
} from "../../../../../../constants/constants";
import FeaturesContext from "../../../../../../context/features/featuresContext";
import LanguageContext from "../../../../../../context/language/languageContext";
import { Component, InputParameters } from "../../../../../../generatedTypes";
import Card from "../../../../../MuiComponents/Card";
import Select from "../../../../../MuiComponents/Select";
import { validateNumberLengthAndRegex } from "../../../../../../utils/validations";
import CalculatorInput from "./CalculatorInput";
import { EcoCalculatorValues } from "../../../../../../context/features/FeaturesState";

export interface EcoAutoCalculatorProps {
    parameters: Component[];
    inputParameters: InputParameters;
}

const EcoAutoCalculator = ({
    parameters,
    inputParameters,
}: EcoAutoCalculatorProps) => {
    if(!inputParameters || !inputParameters.powerParameter || !inputParameters.weightParameter) {
        throw new Error("Power and weight parameters are required for EcoAutoCalculator component");
    }
    
    const {
        getEcoCalculatorValues,
        setEcoCalculatorValues,
        setParametersValues,
    } = useContext(FeaturesContext);
    const { t } = useContext(LanguageContext);

    const savedEcoCalculatorValues = getEcoCalculatorValues();
    const [
        {
            driveType,
            transmissionType,
            drivingMode,
            vehicleWeight,
            vehiclePower,
            vehicleWeightError,
            vehiclePowerError,
        },
        setCalculatorValues,
    ] = useState<EcoCalculatorValues>(
        savedEcoCalculatorValues ?? {
            driveType: DriveTypes.FWD,
            transmissionType: TransmissionTypes.Manual,
            drivingMode: DrivingModes.Sensitive,
            vehicleWeight: inputParameters.weightParameter.default,
            vehiclePower: inputParameters.powerParameter.default,
            vehicleWeightError: false,
            vehiclePowerError: false,
        }
    );

    useEffect(() => {
        if (!vehicleWeightError && !vehiclePowerError) {
            const ecoCalculatorFormula = Math.pow(
                ((Number(vehicleWeight) * EcoCalculatorConstants.massByKg) /
                    (Number(vehiclePower) * EcoCalculatorConstants.powerByKw)) *
                    valueByDriveType[driveType] *
                    valueByTransmissionType[transmissionType] *
                    EcoCalculatorConstants.kilometersPerHour,
                EcoCalculatorConstants.exponentiationValue
            );

            const hashAccelometerDrivingValue =
                0.8 * (100 / 3.6) * (1 / ecoCalculatorFormula);

            const calculateHashParameters = valueByDrivingMode[drivingMode].map(
                (sensitivity) =>
                    sensitivity * Number(hashAccelometerDrivingValue)
            );

            const parameterValues = parameters.map((item, i) => {
                const newValue = Number(calculateHashParameters[i].toFixed(2));
                return {
                    parameterId: item.parameterId,
                    label: item.label,
                    value: Number(calculateHashParameters[i].toFixed(2)),
                    index: item.index,
                    error: validateNumberLengthAndRegex(
                        String(newValue),
                        Number(parameters[i].min),
                        Number(parameters[i].max),
                        parameters[i].validate || "",
                        parameters[i].validationMessage || "",
                        t
                    ),
                };
            });

            if (
                parameterValues?.some(
                    (item) => isFinite(item.value) && !isNaN(item.value)
                )
            ) {
                setParametersValues(parameterValues);
            }
        }
        setEcoCalculatorValues({
            driveType,
            transmissionType,
            drivingMode,
            vehicleWeight,
            vehiclePower,
            vehicleWeightError,
            vehiclePowerError,
        });
        // eslint-disable-next-line
    }, [driveType, transmissionType, drivingMode, vehicleWeight, vehiclePower]);

    const onValueChange = (event: SelectChangeEvent<any>, type: string) => {
        setCalculatorValues((prev) => ({
            ...prev,
            [type]: event.target.value,
        }));
    };

    const renderTranslationBasedOnType = (type: any) => {
        if (type === TransmissionTypes.Manual) {
            return t.ManualTransmission;
        }
        if (type === TransmissionTypes.Automatic) {
            return t.AutomaticTransmission;
        }
        if (type === TransmissionTypes.DualClutch) {
            return t.DualClutchTransmission;
        }
        return "";
    };

    const renderTranslationBasedOnMode = (type: any) => {
        if (type === DrivingModes.Sensitive) {
            return t.Sensitive;
        }
        if (type === DrivingModes.Normal) {
            return t.Normal;
        }
        if (type === DrivingModes.Insensitive) {
            return t.Insensitive;
        }
        return "";
    };

    return (
        <Card isSection title={t.Calculator}>
            <div
                css={css({
                    marginBottom: "16px",
                })}
            >
                <Select
                    value={driveType}
                    label={t.DriveType}
                    onChange={(e) => onValueChange(e, "driveType")}
                    medium
                >
                    {Object.values(DriveTypes).map((type) => (
                        <MenuItem key={type} value={type}>
                            {type}
                        </MenuItem>
                    ))}
                </Select>
            </div>
            <div
                css={css({
                    marginBottom: "16px",
                })}
            >
                <Select
                    value={transmissionType}
                    label={t.TransmissionType}
                    onChange={(e) => onValueChange(e, "transmissionType")}
                    medium
                >
                    {Object.values(TransmissionTypes).map((type) => (
                        <MenuItem key={type} value={type}>
                            {renderTranslationBasedOnType(type)}
                        </MenuItem>
                    ))}
                </Select>
            </div>
            <div
                css={css({
                    marginBottom: "16px",
                })}
            >
                <Select
                    value={drivingMode}
                    label={t.DrivingMode}
                    onChange={(e) => onValueChange(e, "drivingMode")}
                    wrapperStyle={css({
                        scrollMargin: "16px",
                    })}
                    medium
                >
                    {Object.values(DrivingModes).map((mode) => (
                        <MenuItem key={mode} value={mode}>
                            {renderTranslationBasedOnMode(mode)}
                        </MenuItem>
                    ))}
                </Select>
            </div>
            <div
                css={css({
                    marginBottom: "16px",
                })}
            >
                <CalculatorInput
                    min={inputParameters.weightParameter.min}
                    max={inputParameters.weightParameter.max}
                    increment={inputParameters.weightParameter.increment}
                    initialValue={vehicleWeight}
                    label={t.VehicleHeight}
                    onChange={(e) => onValueChange(e, "vehicleWeight")}
                    onError={(hasError) =>
                        setCalculatorValues((prev) => ({
                            ...prev,
                            vehicleWeightError: hasError,
                        }))
                    }
                />
            </div>
            <div
                css={css({
                    marginBottom: "16px",
                })}
            >
                <CalculatorInput
                    min={inputParameters.powerParameter.min}
                    max={inputParameters.powerParameter.max}
                    increment={inputParameters.powerParameter.increment}
                    initialValue={vehiclePower}
                    label={t.VehiclePower}
                    onChange={(e) => onValueChange(e, "vehiclePower")}
                    onError={(hasError) =>
                        setCalculatorValues((prev) => ({
                            ...prev,
                            vehiclePowerError: hasError,
                        }))
                    }
                />
            </div>
        </Card>
    );
};

export default EcoAutoCalculator;
