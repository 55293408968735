import React from "react";
import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

const EditIcon = (props: SvgIconProps) => {
    return (
        <SvgIcon viewBox="0 0 36 36" {...props}>
            <path
                d="M9 23.4601V26.5001C9 26.7801 9.22 27.0001 9.5 27.0001H12.54C12.67 27.0001 12.8 26.9501 12.89 26.8501L23.81 15.9401L20.06 12.1901L9.15 23.1001C9.05 23.2001 9 23.3201 9 23.4601ZM26.71 13.0401C27.1 12.6501 27.1 12.0201 26.71 11.6301L24.37 9.29006C23.98 8.90006 23.35 8.90006 22.96 9.29006L21.13 11.1201L24.88 14.8701L26.71 13.0401Z"
                fill="#697D95"
            />
        </SvgIcon>
    );
};

export default EditIcon;
