/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import Container from "../../../Container";
import TypedGrid from "../../../MuiComponents/TypedGrid";
import GeneralBlock from "./GeneralBlock";
import useMediaQueries from "../../../../utils/useMediaQueries";

interface GeneralSkeletonProps {
    hasOneColumn?: boolean;
    hasDivider?: boolean;
    hasNoLoadingText?: boolean;
    unsetMaxWidth?: boolean;
    fullWidth?: boolean;
}

const GeneralSkeleton = ({
    hasOneColumn,
    hasDivider,
    hasNoLoadingText,
    unsetMaxWidth,
    fullWidth,
}: GeneralSkeletonProps) => {
    const { fromLg } = useMediaQueries();
    return (
        <Container
            css={css({
                paddingTop: "24px",
                paddingBottom: "24px",
                height: fromLg ? "100%" : "90vh",
                width: hasOneColumn || fullWidth ? "100%" : "unset",
                display: "flex",
                justifyContent: "center",
            })}
        >
            <div
                css={css({
                    width: "100%",
                    height: "100%",
                    maxWidth: unsetMaxWidth ? "unset" : "1156px",
                })}
            >
                <TypedGrid
                    container
                    css={css({
                        gap: "24px",
                        height: "100%",
                    })}
                    flexWrap={"nowrap"}
                >
                    <TypedGrid
                        item
                        xl={hasOneColumn ? 12 : 6}
                        lg={hasOneColumn ? 12 : 6}
                        md={12}
                        sm={12}
                        xs={12}
                    >
                        <GeneralBlock
                            hasDivider={hasDivider}
                            hasNoLoadingText={hasNoLoadingText}
                        />
                    </TypedGrid>
                    {!hasOneColumn && fromLg && (
                        <TypedGrid item xl={6} lg={6}>
                            <GeneralBlock hasNoLoadingText />
                        </TypedGrid>
                    )}
                </TypedGrid>
            </div>
        </Container>
    );
};

export default GeneralSkeleton;
