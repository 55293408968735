/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from "@emotion/react";

import { useState, useEffect } from "react";
import useApi from "../../utils/useApi";
import useImagePath from "../../utils/useImagePath";
import Skeleton from "@mui/material/Skeleton";
import { DeviceConfigurableStatus } from "../../constants/constants";

export interface ModelImageProps {
    fmType: string;
    discovered?: boolean;
    isConfigurable?: boolean;
}

const ModelImage = ({
    fmType,
    discovered,
    isConfigurable,
}: ModelImageProps) => {
    const { cancelSource, isCanceled } = useApi();

    const { modelPaths, getModelSrc } = useImagePath();

    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        let isSubscribed = true;

        const model = isConfigurable
            ? fmType.split(" ")[0]
            : DeviceConfigurableStatus.Unsupported;

        getModelSrc(model, isSubscribed, isCanceled);

        return () => {
            isSubscribed = false;
            cancelSource.cancel("Api is being cancelled");
        };

        // eslint-disable-next-line
    }, [fmType]);

    return (
        <div
            css={css`
                display: flex;
                justify-content: center;
                align-items: center;
                box-sizing: border-box;
                width: ${discovered ? "96px" : "102px"};
                height: ${discovered ? "96px" : "72px"};
                border-radius: 6px;

                img {
                    ${!loaded && `display: none`};
                    max-width: 100%;
                    max-height: 100%;
                    ${!isConfigurable && `width: 40px;`}
                    ${!isConfigurable && `height: 38px;`}
                }

                @media (max-width: 839px) {
                    min-width: 72px;
                    min-height: 72px;
                    width: 72px;
                    height: 72px;
                    margin-right: ${discovered ? "8px" : "16px"};
                }
            `}
        >
            {!loaded && <Skeleton width="100%" height="100%" />}

            <img
                src={modelPaths.path1}
                srcSet={`${modelPaths.path2}`}
                alt={fmType}
                onLoad={() => setLoaded(true)}
            />
        </div>
    );
};

export default ModelImage;
