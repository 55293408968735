/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from "@emotion/react";

import { useContext } from "react";
import ThemeContext from "../../../context/theme/themeContext";
import Settings from "../Settings";
import { ReactComponent as Logo } from "../../../assets/TeltonikaLogo.svg";

const StartUpTopBar = () => {
    const {
        colors: { white },
    } = useContext(ThemeContext);

    return (
        <header
            css={css`
                background: ${white};
                padding: 10px 24px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                box-shadow: 0px 6px 14px -6px rgba(24, 39, 75, 0.12),
                    0px 10px 32px -4px rgba(24, 39, 75, 0.1);
            `}
        >
            <Logo />

            <Settings startup={true} />
        </header>
    );
};

export default StartUpTopBar;
