/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from "@emotion/react";

import { useContext } from "react";
import ThemeContext from "../../context/theme/themeContext";
import Button from "./Button";
import { styled } from "@mui/material/styles";
import TooltipMui, {
    TooltipProps,
    tooltipClasses,
} from "@mui/material/Tooltip";

interface TooltipLargeProps extends TooltipProps {
    noMargin?: boolean;
    buttonText: string;
    onBtnClick: () => void;
    otherTooltipProps?: any;
    onTooltipClick?: (e: any) => void;
}

const TooltipLarge = styled(
    ({
        noMargin,
        buttonText,
        onBtnClick,
        className,
        otherTooltipProps,
        onTooltipClick,
        ...props
    }: TooltipLargeProps) => {
        const {
            colors: { gray700 },
        } = useContext(ThemeContext);

        const content = props.title ? (
            <div>
                <div
                    css={css({
                        maxHeight: "136px",
                        overflowY: "auto",
                        marginBottom: "16px",

                        "&::-webkit-scrollbar": {
                            width: "18px",
                        },

                        "&::-webkit-scrollbar-thumb": {
                            backgroundColor: "rgba(255, 255, 255, 0.6)",
                            borderRadius: "100px",
                            border: `5px solid ${gray700}`,
                        },

                        "&::-webkit-scrollbar-thumb:hover": {
                            backgroundColor: "rgba(255, 255, 255, 0.4)",
                        },
                    })}
                >
                    {props.title}
                </div>

                {buttonText && (
                    <Button
                        variant="textOnly"
                        size="normal"
                        color="secondary"
                        fullWidth
                        onClick={onBtnClick}
                        idForTesting={
                            props.title ? String(props.title) : buttonText
                        }
                    >
                        {buttonText}
                    </Button>
                )}
            </div>
        ) : (
            ""
        );

        return (
            <TooltipMui
                {...props}
                {...otherTooltipProps}
                title={content}
                placement={props.placement ? props.placement : "top"}
                classes={{ popper: className }}
                onClick={onTooltipClick}
            />
        );
    }
)(({ noMargin }) => {
    const {
        colors: { gray700 },
    } = useContext(ThemeContext);

    return {
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: gray700,
            fontSize: "14px",
            lineHeight: "20px",
            letterSpacing: "0.1px",
            borderRadius: "12px",
            padding: "16px",
            boxSizing: "border-box",
            maxWidth: "343px",
        },
        [`&[data-popper-placement*="top"] .${tooltipClasses.tooltip}`]: {
            margin: noMargin ? "0 !important" : "2px 2px 4px 2px !important",
        },
    };
});

export default TooltipLarge;
