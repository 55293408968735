import { createContext } from "react";
import { ConnectionTypes } from "../../constants/constants";

interface DeviceAuthorizationContextState {
    isPasswordModalOpen: boolean;
    setPasswordModalOpen: (isOpen: boolean) => void;
    port: string;
    infoData: any;
    connectionType: ConnectionTypes;
    connectAction: (redirect: boolean) => void;
    openEnterPasswordModal: (
        portName: string,
        connType: ConnectionTypes,
        connectFn: (redirect: boolean) => void,
        additionalInfo?: any,
        shouldRedirectToStartupOnCancel?: boolean
    ) => void;
    deviceUnlockCode: string;
    isUnlockModalOpen: boolean;
    setUnlockModalOpen: (isOpen: boolean) => void;
    openExistingUnlockDeviceModal: (unlockCode: string) => void;
    openFreshUnlockDeviceModal: (
        portName: string,
        connType: ConnectionTypes,
        connectFn: (redirect: boolean) => void,
        unlockCode: string
    ) => void;
    onSetInfoData: (data: any) => void;
}

const DeviceAuthorizationContext = createContext(
    {} as DeviceAuthorizationContextState
);

export default DeviceAuthorizationContext;
