/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from "@emotion/react";

import { useContext } from "react";
import ThemeContext from "../../../context/theme/themeContext";
import useMediaQuries from "../../../utils/useMediaQueries";
import Skeleton from "@mui/material/Skeleton";

const BottomBarSkeleton = () => {
    const {
        colors: { white },
    } = useContext(ThemeContext);

    const { fromSm } = useMediaQuries();

    return (
        <div
            css={css`
                position: sticky;
                bottom: 0;
                z-index: 3;
                padding: 10px 24px;
                box-shadow: 0px -6px 14px -6px rgba(24, 39, 75, 0.12),
                    0px -10px 32px -4px rgba(24, 39, 75, 0.1);
                background: ${white};

                @media (max-width: 599px) {
                    padding: 10px 16px;
                }
            `}
        >
            <div
                css={css`
                    display: flex;
                    justify-content: space-between;
                `}
            >
                {fromSm && (
                    <div>
                        <Skeleton width={156} height={36} />
                    </div>
                )}

                <div
                    css={css`
                        display: flex;
                        justify-content: space-between;

                        @media (max-width: 599px) {
                            width: 100%;
                        }

                        & > span:last-of-type {
                            margin-left: 8px;
                        }
                    `}
                >
                    <Skeleton width={fromSm ? 99 : "100%"} height={36} />
                    <Skeleton width={fromSm ? 122 : "100%"} height={36} />
                </div>
            </div>
        </div>
    );
};

export default BottomBarSkeleton;
