/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from "@emotion/react";

import {
    useContext,
    useState,
    useRef,
    useEffect,
    MutableRefObject,
} from "react";
import ThemeContext from "../../../../../../../context/theme/themeContext";
import SingleInput from "./SingleInput";

export type Ref = MutableRefObject<HTMLInputElement | undefined>;

interface InputNumbers {
    [key: number]: {
        value: string;
        ref: Ref;
    };
}

interface CodeInputProps {
    error?: string | null;
    retriesLeft?: number | null;
    explanatoryText: string;
    step: number;
    setPayloadValue: (value: string | null) => void;
}

const CodeInput = ({
    error,
    retriesLeft,
    explanatoryText,
    step,
    setPayloadValue,
}: CodeInputProps) => {
    const {
        colors: { textDark, red800 },
    } = useContext(ThemeContext);

    const [errorMsg, setErrorMsg] = useState<string | null>(error || null);
    const [retries, setRetries] = useState<number | null>(retriesLeft || null);

    const [inputNumbers, setInputNumbers] = useState<InputNumbers>({
        0: { value: "", ref: useRef() },
        1: { value: "", ref: useRef() },
        2: { value: "", ref: useRef() },
        3: { value: "", ref: useRef() },
    });

    useEffect(() => {
        if (error !== undefined) {
            setErrorMsg(error);
        }

        if (retriesLeft !== undefined) {
            setRetries(retriesLeft);
        }
    }, [error, retriesLeft]);

    useEffect(() => {
        setInputNumbers({
            0: { ...inputNumbers[0], value: "" },
            1: { ...inputNumbers[1], value: "" },
            2: { ...inputNumbers[2], value: "" },
            3: { ...inputNumbers[3], value: "" },
        });

        if (errorMsg) {
            inputNumbers[0].ref.current?.blur();
        } else {
            inputNumbers[0].ref.current?.focus();
        }
        // eslint-disable-next-line
    }, [step, errorMsg]);

    useEffect(() => {
        const pinValue = Object.values(inputNumbers).reduce(
            (previousValue, currentValue) => {
                return `${previousValue}${currentValue.value}`;
            },
            ""
        );

        const payload = pinValue.length === 4 ? pinValue : null;

        setPayloadValue(payload);
        // eslint-disable-next-line
    }, [inputNumbers]);

    const handleChangeInputNumber = (index: number, value: string) => {
        setInputNumbers({
            ...inputNumbers,
            [index]: { ...inputNumbers[index], value },
        });

        const someEmpty = Object.values(inputNumbers).some(
            (input, i) => i !== index && input.value.length === 0
        );

        const focusOnNext = value.length > 0 && someEmpty;

        if (focusOnNext) {
            const nextIndex = index === 3 ? 0 : index + 1;
            const inputRef = inputNumbers[nextIndex].ref.current;

            inputRef?.focus();
        }
    };

    const handleKeyDown = (
        index: number,
        event: React.KeyboardEvent<HTMLInputElement>
    ) => {
        if (event.key === "Backspace" && inputNumbers[index].value === "") {
            const prevIndex = index === 0 ? 3 : index - 1;
            const prevInput = inputNumbers[prevIndex];
            const prevValue = prevInput.value.slice(0, -1);
            prevInput.ref.current?.focus();
            handleChangeInputNumber(prevIndex, prevValue);
        }
    };

    const clearError = () => {
        errorMsg && setErrorMsg(null);
        retries && setRetries(null);
    };

    return (
        <div
            css={css({
                marginTop: "95px",
            })}
        >
            <div
                css={css({
                    display: "flex",
                    justifyContent: "center",

                    "& > div": {
                        width: "40px",
                    },

                    "& > div + div": {
                        marginLeft: "8px",
                    },
                })}
            >
                {Object.values(inputNumbers).map((input, i) => (
                    <SingleInput
                        key={i}
                        ref={input.ref}
                        index={i}
                        value={input.value}
                        error={errorMsg}
                        onChange={handleChangeInputNumber}
                        onKeyDown={(event) => handleKeyDown(i, event)}
                        handleFocus={clearError}
                    />
                ))}
            </div>

            <div
                css={css({
                    marginTop: "12px",
                    color: errorMsg ? red800 : textDark,
                    lineHeight: "20px",
                    letterSpacing: "0.1px",
                    textAlign: "center",
                })}
            >
                {errorMsg ? errorMsg : explanatoryText}
            </div>

            {retries !== null && (
                <div
                    css={css({
                        marginTop: "16px",
                        color: red800,
                        lineHeight: "20px",
                        letterSpacing: "0.1px",
                        textAlign: "center",
                        fontWeight: "600",
                    })}
                >
                    {retries} attempt left
                </div>
            )}
        </div>
    );
};

export default CodeInput;
