/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from "@emotion/react";

import { Fragment, useContext, useState } from "react";
import ThemeContext from "../../../../../context/theme/themeContext";
import SettingsContext from "../../../../../context/settings/settingsContext";
import { SingleNotification as Notification } from "../../../../../context/settings/settingsReducer";
import useMediaQueries from "../../../../../utils/useMediaQueries";
import UpdateNotification from "../UpdateNotification";
import SingleNotification from "./SingleNotification";
import NoNotifications from "./NoNotifications";
import ToggleButton from "../../../ToggleButton";
import Collapse from "@mui/material/Collapse";
import { TctUpdateAvailableAtom } from "../../../../../context/webSockets/webSocketsState";
import { useAtom } from "jotai";

const HistoryNotifications = () => {
    const {
        colors: { white, blue400, blue200, gray200, textPlaceholder },
    } = useContext(ThemeContext);

    const { historyNotifications } = useContext(SettingsContext);

    const [{ available }, _] = useAtom(TctUpdateAvailableAtom);

    const { toMd, fromMd } = useMediaQueries();

    const [showAll, setShowAll] = useState(false);

    const handleShowAllToggle = () => setShowAll(!showAll);

    const desktopMaxHeight = available ? "492px" : "384px";

    return !available && historyNotifications.length === 0 ? (
        <NoNotifications description="All the read notifications will be listed here" />
    ) : (
        <Fragment>
            {showAll && (
                <div
                    css={css({
                        height: "1px",
                        background: gray200,
                        marginTop: "8px",
                    })}
                />
            )}

            <div
                css={css({
                    maxHeight: toMd ? "unset" : desktopMaxHeight,
                    overflowY: "auto",

                    "&::-webkit-scrollbar": {
                        width: "18px",
                    },

                    "&::-webkit-scrollbar-thumb": {
                        backgroundColor: blue200,
                        borderRadius: "100px",
                        border: `5px solid ${white}`,
                        minHeight: "48px",
                    },

                    "&::-webkit-scrollbar-thumb:hover": {
                        backgroundColor: blue400,
                    },
                })}
            >
                {available && <UpdateNotification />}
                <div>
                    {historyNotifications.length > 0 && (
                        <div
                            css={css({
                                color: textPlaceholder,
                                fontSize: "12px",
                                fontWeight: "600",
                                lineHeight: "16px",
                                letterSpacing: "0.4px",
                                padding: "12px 16px 4px 16px",
                            })}
                        >
                            History
                        </div>
                    )}

                    {historyNotifications.map(
                        (
                            {
                                id,
                                type,
                                timeStamp,
                                title,
                                description,
                                dynamicContent,
                            }: Notification,
                            i: number
                        ) =>
                            i < 4 && (
                                <SingleNotification
                                    key={id}
                                    id={id}
                                    type={type}
                                    timeStamp={timeStamp}
                                    title={title}
                                    description={description}
                                    dynamicContent={dynamicContent}
                                    isRead
                                />
                            )
                    )}

                    <Collapse in={showAll}>
                        {historyNotifications.map(
                            (
                                {
                                    id,
                                    type,
                                    timeStamp,
                                    title,
                                    description,
                                    dynamicContent,
                                }: Notification,
                                i: number
                            ) =>
                                i > 3 && (
                                    <SingleNotification
                                        key={id}
                                        id={id}
                                        type={type}
                                        timeStamp={timeStamp}
                                        title={title}
                                        description={description}
                                        dynamicContent={dynamicContent}
                                        isRead
                                    />
                                )
                        )}
                    </Collapse>
                </div>
            </div>

            {historyNotifications.length > 4 && (
                <Fragment>
                    {fromMd && (
                        <div
                            css={css({
                                height: "1px",
                                background: gray200,
                            })}
                        />
                    )}

                    <ToggleButton
                        closed={!showAll}
                        title={`Show all ${
                            Number(available) + historyNotifications.length
                        } notifications`}
                        toggleResults={handleShowAllToggle}
                    />
                </Fragment>
            )}
        </Fragment>
    );
};

export default HistoryNotifications;
