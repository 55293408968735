/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import InfoIcon from "../../assets/icons/InfoIcon";
import ThemeContext from "../../context/theme/themeContext";
import { useContext } from "react";
import Alert from "../Alert";
import AlertWarningIcon from "../../assets/icons/AlertWarningIcon";
import { AlertStatus } from "../../constants/constants";

interface BannerProps {
    data: {
        bannerType: string;
        description?: string;
    };
}

const BannerView = ({ data }: BannerProps) => {
    const {
        colors: { blue100, blue1000, gray700, orange50, red50 },
    } = useContext(ThemeContext);

    const renderBanner = (banner: {
        bannerType: string;
        description?: string;
    }) => {
        switch (banner.bannerType) {
            case "Info": {
                return (
                    <div
                        css={css({
                            padding: "9px",
                            backgroundColor: blue100,
                            border: `1px solid ${blue1000}`,
                            borderRadius: "8px",
                            display: "flex",
                            alignItems: "center",
                            gap: "10px",
                        })}
                    >
                        <InfoIcon color="primary" />
                        <span
                            css={css({
                                fontWeight: 600,
                                fontSize: "14px",
                                lineHeight: "20px",
                                color: gray700,
                            })}
                        >
                            {banner.description}
                        </span>
                    </div>
                );
            }
            case "Warning": {
                return (
                    <div
                        css={css({
                            padding: "8px",
                            backgroundColor: orange50,
                            border: `1px solid #FDBA74`,
                            borderRadius: "8px",
                            display: "flex",
                            alignItems: "center",
                            alignSelf: "stretch",
                            gap: "10px",
                        })}
                    >
                        <AlertWarningIcon />
                        <span
                            css={css({
                                fontWeight: 600,
                                fontSize: "14px",
                                lineHeight: "20px",
                                color: gray700,
                            })}
                        >
                            {banner.description}
                        </span>
                    </div>
                );
            }
            case "Success":
            case "Error":
                return (
                    <div
                        css={css({
                            padding: "8px",
                            backgroundColor: red50,
                            border: `1px solid #FDBA74`,
                            borderRadius: "8px",
                            display: "flex",
                            alignItems: "center",
                            alignSelf: "stretch",
                            gap: "10px",
                        })}
                    >
                        <AlertWarningIcon />
                        <span
                            css={css({
                                fontWeight: 600,
                                fontSize: "14px",
                                lineHeight: "20px",
                                color: gray700,
                            })}
                        >
                            {banner.description}
                        </span>
                    </div>
                );
            default: {
                return (
                    <Alert
                        status={AlertStatus.Critical}
                        title={`No banner of type ${banner.bannerType} is shown`}
                    />
                );
            }
        }
    };

    return renderBanner(data);
};

export default BannerView;
