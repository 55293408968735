import React from "react";
import SetPassword from "./SetPassword";
import DisablePassword from "./DisablePassword";

export type ModalType = "setPassword" | "changePassword" | "disablePassword";

export interface Data {
    component: any;
}

interface PasswordBoxModalProps {
    type: ModalType | null;
    data: Data;
    close: () => void;
    togglePassword: (value: boolean) => void;
    isRecoveryPassword?: boolean;
}

const PasswordBoxModal = ({
    type,
    data,
    close,
    togglePassword,
    isRecoveryPassword,
}: PasswordBoxModalProps) => {
    const renderModal = () => {
        switch (type) {
            case "setPassword":
                return (
                    <SetPassword
                        isOpen={!!type}
                        close={close}
                        togglePassword={togglePassword}
                        data={data}
                        isRecoveryPassword={isRecoveryPassword}
                    />
                );
            case "changePassword":
                return (
                    <SetPassword
                        isOpen={!!type}
                        close={close}
                        togglePassword={togglePassword}
                        data={data}
                        isRecoveryPassword={isRecoveryPassword}
                        isChangePassword
                    />
                );
            default:
                return (
                    <DisablePassword
                        isOpen={!!type}
                        close={close}
                        togglePassword={togglePassword}
                        isRecoveryPassword={isRecoveryPassword}
                        data={data}
                    />
                );
        }
    };

    return renderModal();
};

export default PasswordBoxModal;
