import {
    SEARCH_GEOFENCE_PARAMETERS,
    SET_CENTER_COORDINATES,
    SET_EDIT_STATE,
    SET_GEOFENCE_PARAMETERS,
    RESET_ALL_FILTERS,
    SET_ACTIVE_SHAPE,
    SET_OPERAND,
    SET_PRIORITY,
    SET_GEOZONES,
    SET_SHAPE_PARAMETER,
    SET_LATITUDE_LONGITUDE_VALUES,
    SET_GEOZONE_DISABLED,
    SET_RADIUS,
    SET_AVAILABLE_SHAPES,
    SET_LOADING,
    SET_REFETCH_LIST,
    SET_HIGHLIGHTED_GEOZONE,
} from "./manualGeofenceActions";

// TODO: remove any add typings

const getParameters = (allData: any) => {
    const parameters: any = {};
    const fillParametersObj = (
        componentType: any,
        parameterId: any,
        parameterValue: any,
        listConfig: any = null
    ) => {
        parameterId !== 0 && (parameters[parameterId] = parameterValue);
    };
    allData.forEach(({ blocks }: any) => {
        blocks.forEach(({ segments }: any) => {
            segments.forEach(({ component, groupBox, geoCoordinates }: any) => {
                if (component) {
                    fillParametersObj(
                        component.componentType,
                        component.parameterId,
                        component.parameterValue,
                        component.listConfig
                    );
                } else if (groupBox) {
                    groupBox.switchValue &&
                        fillParametersObj(
                            "Switch",
                            groupBox.switchId,
                            groupBox.switchValue
                        );
                    groupBox.components.forEach((component: any) =>
                        fillParametersObj(
                            component.componentType,
                            component.parameterId,
                            component.parameterValue,
                            component.listConfig
                        )
                    );
                } else if (geoCoordinates) {
                    geoCoordinates.components.forEach((component: any) =>
                        fillParametersObj(
                            component.componentType,
                            component.parameterId,
                            component.parameterValue,
                            component.listConfig
                        )
                    );
                }
                return;
            });
        });
    });

    return parameters;
};

export default (state: any, action: any) => {
    switch (action.type) {
        case SET_HIGHLIGHTED_GEOZONE: {
            return {
                ...state,
                highlightedGeozone: action.payload,
            };
        }
        case SET_REFETCH_LIST: {
            return {
                ...state,
                refetchList: action.payload,
            };
        }
        case SET_GEOFENCE_PARAMETERS: {
            const allActiveFrameParameters = action.payload
                ? getParameters([{ blocks: [action.payload.manualGeozones] }])
                : {};
            return {
                ...state,
                previousGeofenceParameters: {
                    ...allActiveFrameParameters,
                },
                errors: {},
            };
        }
        case SEARCH_GEOFENCE_PARAMETERS: {
            return {
                ...state,
                geofenceSearchValue: action.payload,
            };
        }
        case SET_CENTER_COORDINATES: {
            return {
                ...state,
                centerCoordinates: action.payload,
            };
        }
        case SET_EDIT_STATE: {
            return {
                ...state,
                editState: action.payload,
            };
        }
        case SET_GEOZONES: {
            return {
                ...state,
                geozones: action.payload,
            };
        }
        case SET_ACTIVE_SHAPE: {
            return {
                ...state,
                activeShape: action.payload,
            };
        }
        case SET_PRIORITY: {
            return {
                ...state,
                activePriority: action.payload,
            };
        }
        case SET_OPERAND: {
            return {
                ...state,
                activeOperand: action.payload,
            };
        }
        case SET_SHAPE_PARAMETER: {
            return {
                ...state,
                shapeParameter: action.payload,
            };
        }
        case SET_LATITUDE_LONGITUDE_VALUES: {
            const lat = action.payload.lat;
            const long = action.payload.long;
            return {
                ...state,
                latitudeLongitudeParameters: {
                    lat,
                    long,
                },
            };
        }
        case RESET_ALL_FILTERS: {
            return {
                ...state,
                activePriority: [],
                activeOperand: [],
                activeShape: [],
            };
        }
        case SET_GEOZONE_DISABLED: {
            return {
                ...state,
                isSaveDisabled: action.payload,
            };
        }
        case SET_RADIUS: {
            return {
                ...state,
                radius: action.payload,
            };
        }
        case SET_AVAILABLE_SHAPES: {
            return {
                ...state,
                availableShapes: action.payload,
            };
        }
        case SET_LOADING: {
            return {
                ...state,
                isLoading: action.payload,
            };
        }
        default:
            return state;
    }
};
