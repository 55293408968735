/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from "@emotion/react";

import { Fragment, useContext } from "react";
import ThemeContext from "../../../../../context/theme/themeContext";
import SettingsContext from "../../../../../context/settings/settingsContext";
import useMediaQueries from "../../../../../utils/useMediaQueries";
import Popover from "@mui/material/Popover";
import TextLink from "../../../../MuiComponents/TextLink";
import { ReactComponent as ChangeLog } from "../../../../../assets/icons/ChangeLog.svg";
import { ReactComponent as ComputerInfo } from "../../../../../assets/icons/ComputerInfo.svg";
import VersionInfoItem from "./VersionInfoItem";
import VersionHistoryModal from "./VersionHistoryModal";
import SystemInformationModalModal from "./SystemInformationModal";
import LanguageContext from "../../../../../context/language/languageContext";

const VersionInfoModal = () => {
    const {
        colors: { textDark },
    } = useContext(ThemeContext);
    const {
        anchorEl,
        isVersionInfoModalOpen,
        closeVersionInfoModal,
        openSettingsModal,
        openVersionHistoryModal,
        openSystemInformationModal,
    } = useContext(SettingsContext);
    const { t } = useContext(LanguageContext);

    const { toMd } = useMediaQueries();

    const menuItems = [
        {
            title: t.ReleaseChangeLog,
            icon: <ChangeLog />,
            onClick: () => openVersionHistoryModal(),
        },
        {
            title: t.SystemInformation,
            icon: <ComputerInfo />,
            onClick: () => openSystemInformationModal(),
        },
    ];
    return (
        <Fragment>
            <Popover
                open={isVersionInfoModalOpen}
                anchorEl={anchorEl}
                onClose={closeVersionInfoModal}
                anchorReference={toMd ? "anchorPosition" : "anchorEl"}
                anchorPosition={{ top: 0, left: 0 }}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                }}
                marginThreshold={toMd ? 0 : 16}
                sx={{
                    "& .MuiPaper-root": {
                        maxHeight: toMd ? "unset" : "626px",
                        height: toMd ? "100%" : "unset",
                        maxWidth: toMd ? "unset" : "448px",
                        width: toMd ? "100%" : "calc(100% - 284px)",
                        marginTop: toMd ? "0" : "10px",
                        overflowY: toMd ? "auto" : "hidden",
                    },
                }}
            >
                <div
                    css={css({
                        display: "flex",
                        padding: "16px 30px 16px 24px",

                        "& > h2": {
                            color: textDark,
                            fontSize: "20px",
                            lineHeight: "24px",
                            fontWeight: "600",
                            letterSpacing: "0.15px",
                            alignSelf: "flex-end",
                        },
                    })}
                >
                    <TextLink
                        size="normal"
                        onClick={() => openSettingsModal()}
                        idForTesting="settings-modal-back-button"
                        startIcon
                    >
                        Go back
                    </TextLink>
                </div>
                <div>
                    {menuItems.map((item: any, index: number) => (
                        <VersionInfoItem item={item} key={index} />
                    ))}
                </div>
            </Popover>
            <VersionHistoryModal />
            <SystemInformationModalModal />
        </Fragment>
    );
};

export default VersionInfoModal;
