/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import { FEText } from "../../../../../../generatedTypes";
import useFeElementParser from "../../../../../../utils/useFeElementParser";

interface FeTextProps {
    text: FEText;
    type?: "notification" | "version";
}
const FeText = ({ text, type }: FeTextProps) => {
    const { parseTextStyle } = useFeElementParser();
    const isNotification = type === "notification";

    return (
        <span
            css={css(
                parseTextStyle(
                    isNotification
                        ? `notification${text.textStyle}`
                        : text.textStyle
                )
            )}
        >
            {text.text}
        </span>
    );
};

export default FeText;
