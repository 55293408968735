/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from "@emotion/react";

import { useContext } from "react";
import ThemeContext from "../../../../context/theme/themeContext";
import Box from "../../../Box";
import FooterSkeleton from "./FooterSkeleton";
import Skeleton from "@mui/material/Skeleton";
import Divider from "@mui/material/Divider";

export interface MobileSkeletonProps {
    cards: number;
}

const MobileSkeleton = ({ cards }: MobileSkeletonProps) => {
    const {
        colors: { white, gray200, gray100 },
    } = useContext(ThemeContext);

    const SingleCard = () => {
        return (
            <div
                css={css`
                    padding: 12px 8px;
                    background: ${white};
                `}
            >
                <div
                    css={css`
                        display: flex;
                        align-items: center;
                        margin-bottom: 16px;
                    `}
                >
                    <Skeleton width={72} height={72} />

                    <div
                        css={css`
                            margin-left: 16px;

                            span:first-of-type {
                                margin-bottom: 8px;
                            }

                            span:last-of-type {
                                margin-bottom: 4px;
                            }
                        `}
                    >
                        <Skeleton width={89} height={20} />
                        <Skeleton width={89} height={16} />

                        <div
                            css={css`
                                display: flex;
                                align-items: center;

                                span + span {
                                    margin-left: 8px;
                                }
                            `}
                        >
                            <Skeleton width={61} height={20} />
                            <Skeleton width={85} height={20} />
                        </div>
                    </div>
                </div>

                <Divider
                    sx={{
                        borderColor: gray200,
                        marginBottom: "16px",
                    }}
                />

                <Skeleton width="100%" height={36} />
            </div>
        );
    };

    return (
        <Box
            css={css`
                background: ${gray100};

                & > div + div {
                    margin-top: 4px;
                }

                & > div:first-of-type {
                    border-radius: 8px 8px 0 0;
                }

                & > div:last-of-type {
                    margin-top: 0;
                }
            `}
        >
            {Array(cards)
                .fill(0)
                .map((row, i) => (
                    <SingleCard key={i} />
                ))}

            <FooterSkeleton />
        </Box>
    );
};

export default MobileSkeleton;
