import {
    FILTER_COLUMNS_BY_NAME,
    SET_FILTERS,
    SET_FILTER,
    SET_PRIORITY,
    SET_OPERAND,
    RESET_ALL_FILTERS,
    RESET_FILTER,
    RESET_OPERAND,
    RESET_PRIORITY,
    SET_ACTIVE_STATUS,
    SET_SEARCH_VALUE,
    RESET_COLUMN,
    RESET_COLUMNS,
    SET_COLUMNS,
} from "./inputOutputActions";
export interface InitialState {
    columns: any;
    activeColumns: string[];
    activeFilters: string[];
    activePriority: any;
    activeOperand: any;
    activeStatus: string;
    searchValue: string;
}

type Action =
    | { type: typeof FILTER_COLUMNS_BY_NAME; payload: string }
    | { type: typeof SET_FILTERS; payload: string[] }
    | { type: typeof SET_FILTER; payload: string }
    | { type: typeof SET_PRIORITY; payload: { value: string; text: string }[] }
    | { type: typeof SET_OPERAND; payload: { value: string; text: string }[] }
    | { type: typeof RESET_ALL_FILTERS }
    | { type: typeof RESET_FILTER; payload: string }
    | { type: typeof RESET_PRIORITY; payload: string }
    | { type: typeof RESET_OPERAND; payload: string }
    | { type: typeof SET_ACTIVE_STATUS; payload: string }
    | { type: typeof SET_SEARCH_VALUE; payload: string }
    | { type: typeof RESET_COLUMN; payload: string }
    | { type: typeof RESET_COLUMNS; payload: string[] }
    | {
          type: typeof SET_COLUMNS;
          payload: any;
      };

export default (state: InitialState, action: Action): InitialState => {
    switch (action.type) {
        case SET_COLUMNS: {
            return {
                ...state,
                columns: action.payload,
            };
        }
        case FILTER_COLUMNS_BY_NAME: {
            return {
                ...state,
                activeColumns: [...state.activeColumns, action.payload],
            };
        }
        case RESET_COLUMN: {
            return {
                ...state,
                activeColumns: state.activeColumns.filter(
                    (x) => x !== action.payload
                ),
            };
        }
        case RESET_COLUMNS: {
            return {
                ...state,
                activeColumns: action.payload,
            };
        }
        case SET_FILTERS: {
            const filters = [
                ...state.activeFilters.filter(
                    (x) => !action.payload.includes(x)
                ),
                ...action.payload.filter(
                    (x) => !state.activeFilters.includes(x)
                ),
            ];
            return {
                ...state,
                activeFilters: [...filters],
            };
        }
        case SET_FILTER: {
            return {
                ...state,
                activeFilters: [...state.activeFilters, action.payload],
            };
        }
        case SET_PRIORITY: {
            return {
                ...state,
                activePriority: action.payload,
            };
        }
        case SET_OPERAND: {
            return {
                ...state,
                activeOperand: action.payload,
            };
        }
        case RESET_ALL_FILTERS: {
            return {
                ...state,
                activeFilters: [],
                activePriority: [],
                activeOperand: [],
                activeStatus: "2",
            };
        }
        case RESET_FILTER: {
            return {
                ...state,
                activeFilters: state.activeFilters.filter(
                    (x) => x !== action.payload
                ),
            };
        }
        case RESET_PRIORITY: {
            return {
                ...state,
                activePriority: state.activePriority.filter(
                    (x: string) => x !== action.payload
                ),
            };
        }
        case RESET_OPERAND: {
            return {
                ...state,
                activeOperand: state.activeOperand.filter(
                    (x: string) => x !== action.payload
                ),
            };
        }
        case SET_ACTIVE_STATUS: {
            return {
                ...state,
                activeStatus: action.payload,
            };
        }
        case SET_SEARCH_VALUE: {
            return {
                ...state,
                searchValue: action.payload,
            };
        }
        default:
            return state;
    }
};
