/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from "@emotion/react";

import { Fragment, useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import ThemeContext from "../../../../context/theme/themeContext";
import LanguageContext from "../../../../context/language/languageContext";
import LayoutContext from "../../../../context/layout/layoutContext";
import ModelImage from "../../ModelImage";
import Technology from "../Technology";
import Button from "../../../MuiComponents/Button";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { EDITOR_STATUS } from "../../../../constants/routes";
import useConfiguration from "../../../../utils/useConfiguration";
import Badge from "../../../MuiComponents/Badge";
import { InputAdornment, Popover, TextField } from "@mui/material";
import { SearchRounded } from "@mui/icons-material";
import { numberOfSpecsInList } from "../../../../constants/constants";
import UserInfoContext from "../../../../context/userInfo/userInfoContext";

export interface SingleOfflineDeviceProps {
    data: any;
}

const SingleOfflineDevice = ({ data }: SingleOfflineDeviceProps) => {
    const { version, fmType, technologies } = data;

    const {
        colors: { gray200, blue100, textPlaceholder },
    } = useContext(ThemeContext);

    const { t } = useContext(LanguageContext);

    const { setLayoutError } = useContext(LayoutContext);

    const { speces, defaultSpecs } = useContext(UserInfoContext);

    const [menuAnchorEl, setMenuAnchorEl] = useState<HTMLDivElement | null>(
        null
    );
    const [searchValue, setSearchValue] = useState("");

    const history = useHistory();
    const { getConfigurationFromOffline } = useConfiguration();

    const getLayoutData = async (item?: any) => {
        history.push(EDITOR_STATUS);
        try {
            await getConfigurationFromOffline(version, item?.id);
        } catch (error) {
            setLayoutError(error);
        }
    };

    const defaultSpec = defaultSpecs.find(
        (item) => item.hardwareId === version
    );

    const handleButtonClick = async () => {
        if (!defaultSpec) {
            getLayoutData(data[0]);
            return;
        }
        getLayoutData({ id: defaultSpec?.specId });
    };

    const handleSpecClick = async (e: any) => {
        setMenuAnchorEl(e.currentTarget as any);
    };

    const filteredSpecs = speces.filter(
        (item) =>
            searchValue === "" ||
            String(item.id).toLowerCase() === searchValue.toLowerCase()
    );

    return (
        <Fragment>
            <TableRow
                hover
                sx={{
                    "&.MuiTableRow-hover:hover": {
                        backgroundColor: blue100,
                        boxShadow: `inset 0px -1px 0px ${gray200}`,
                    },
                }}
            >
                <TableCell>
                    <ModelImage fmType={fmType} isConfigurable={true} />
                </TableCell>

                <TableCell>{fmType}</TableCell>

                <TableCell
                    css={css`
                        & > span + span {
                            margin-left: 8px;
                        }
                    `}
                >
                    {technologies.length > 0 ? (
                        technologies.map((tech: any) => (
                            <Technology key={`${tech}`} name={tech} />
                        ))
                    ) : (
                        <Technology name="legacy" />
                    )}
                </TableCell>
                <TableCell css={css({ textAlign: "end" })}>
                    {defaultSpec && (
                        <Badge color="light">{defaultSpec.title}</Badge>
                    )}
                    <Button
                        css={css({
                            marginLeft: "12px",
                            borderTopRightRadius: speces.length > 1 ? 0 : "6px",
                            borderBottomRightRadius:
                                speces.length > 1 ? 0 : "6px",
                        })}
                        variant={"textOnly"}
                        size="small"
                        color="primary"
                        onClick={handleButtonClick}
                        idForTesting={`configure-${version}`}
                    >
                        {t.Configure}
                    </Button>
                    {speces.length > 1 && (
                        <Button
                            css={css({
                                borderTopLeftRadius:
                                    speces.length > 1 ? 0 : "6px",
                                borderBottomLeftRadius:
                                    speces.length > 1 ? 0 : "6px",
                                borderLeft:
                                    speces.length > 1
                                        ? "1px solid #FFFFFF4D"
                                        : 0,
                            })}
                            variant="iconOnly"
                            size="small"
                            color="primary"
                            idForTesting="arrow"
                            onClick={handleSpecClick}
                            icon={<ExpandMoreIcon />}
                        />
                    )}
                </TableCell>
            </TableRow>
            <Popover
                onClose={() => setMenuAnchorEl(null)}
                open={!!menuAnchorEl}
                anchorEl={menuAnchorEl}
                anchorPosition={{ top: 0, left: 0 }}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                }}
                sx={{
                    "& .MuiPaper-root": {
                        maxWidth: "200px",
                        width: "100%",
                    },
                }}
            >
                {speces?.length >= numberOfSpecsInList && (
                    <TextField
                        autoFocus
                        fullWidth
                        variant="filled"
                        placeholder={t.Search}
                        value={searchValue}
                        onChange={(e) => setSearchValue(e.target.value)}
                        InputProps={{
                            spellCheck: "false",
                            disableUnderline: true,
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchRounded
                                        style={{
                                            color: textPlaceholder,
                                        }}
                                    />
                                </InputAdornment>
                            ),
                            sx: {
                                backgroundColor: "rgba(255, 255, 255, 0.05)",
                                color: textPlaceholder,
                                fontWeight: "600",
                                lineHeight: "20px",
                                letterSpacing: "0.1px",
                                padding: "10px 16px",
                                borderBottom: "1px solid #CCD4DF",
                                "& .MuiInputAdornment-root.MuiInputAdornment-positionStart.MuiInputAdornment-root:not(.MuiInputAdornment-hiddenLabel)":
                                    {
                                        marginTop: "0",
                                    },
                                "& .MuiInputAdornment-root": {
                                    height: "unset",
                                    fontSize: "14px",
                                    fontStyle: "normal",
                                },
                                "& .MuiFilledInput-input": {
                                    padding: "0",
                                    paddingBottom: "4px",
                                    color: "#334155",
                                    "&::placeholder": {
                                        lineHeight: "20px",
                                        letterSpacing: "0.1px",
                                        fontSize: "14px",
                                    },
                                },
                            },
                        }}
                    />
                )}
                {filteredSpecs.length ? (
                    <div style={{ maxHeight: "144px", overflowY: "auto" }}>
                        {filteredSpecs.map((item) => {
                            return (
                                <div
                                    css={css({
                                        padding: "14px 16px",
                                        fontFamily: "Open Sans",
                                        fontSize: "14px",
                                        fontStyle: "normal",
                                        fontWeight: 600,
                                        lineHeight: "20px", // 142.857%
                                        letterSpacing: "0.1px",
                                        cursor: "pointer",
                                        "&:hover": {
                                            backgroundColor: "#C5E2FF",
                                        },
                                    })}
                                    key={item.title}
                                    onClick={() => getLayoutData(item)}
                                >
                                    {item.title}
                                </div>
                            );
                        })}
                    </div>
                ) : (
                    <div
                        css={css({
                            padding: "14px 16px",
                            fontSize: "14px",
                            color: "#697D95",
                            fontWeight: 600,
                        })}
                    >
                        No results
                    </div>
                )}
            </Popover>
        </Fragment>
    );
};

export default SingleOfflineDevice;
