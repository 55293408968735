/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from "@emotion/react";

import {
    CSSProperties,
    useCallback,
    useContext,
    useEffect,
    useRef,
} from "react";
import { VariableSizeList } from "react-window";
import ThemeContext from "../../../../../../../context/theme/themeContext";
import { ErrorsObj } from "../../../../../../../context/list/listReducer";
import { ListItemInterface } from "../List";
import ListInput from "./ListInput";
import { getSumOfObjectValues } from "../../../../../../../utils/helpers";

interface ListData {
    errors: ErrorsObj;
    inputs: ListItemInterface[];
    isDisabled: boolean;
    placeholder: string;
    locale: string;
    change: (
        inputIndex: number,
        inputValue: string,
        errorValue: string | null,
        parameterId: number
    ) => void;
}

interface ItemSizesRef {
    [key: number]: number;
}

interface RenderListInputData extends ListData {
    listInputsRef: React.MutableRefObject<any>;
    itemSizesRef: React.MutableRefObject<ItemSizesRef>;
}

interface RenderListInputProps {
    index: number;
    data: RenderListInputData;
    isScrolling?: boolean;
    style: CSSProperties;
}

interface ListInputsProps extends ListData {
    isAllowedToScroll: boolean;
}

const ListInputs = ({
    errors,
    inputs,
    isAllowedToScroll,
    isDisabled,
    change,
    placeholder,
    locale,
    ...props
}: ListInputsProps) => {
    const {
        colors: { white, blue400, blue200 },
    } = useContext(ThemeContext);
    const listInputsRef = useRef({});
    const itemSizesRef = useRef<ItemSizesRef>({});

    useEffect(() => {
        const scrollToBottom = (): void | null =>
            (listInputsRef.current as any).scrollToItem(
                inputs.length - 1,
                "start"
            );

        isAllowedToScroll && scrollToBottom();
    }, [isAllowedToScroll, inputs.length]);

    const getItemSize = (index: number) => itemSizesRef.current[index] || 84;

    return (
        <VariableSizeList
            useIsScrolling
            ref={listInputsRef as any}
            itemData={{
                listInputsRef,
                itemSizesRef,
                errors,
                inputs,
                isDisabled,
                change,
                placeholder,
                locale,
            }}
            height={362}
            itemCount={inputs.length}
            itemSize={getItemSize}
            width="100%"
            css={css({
                marginTop: "16px",
                scrollBehavior: "smooth",

                "&::-webkit-scrollbar": {
                    width: "18px",
                },

                "&::-webkit-scrollbar-thumb": {
                    backgroundColor: blue200,
                    borderRadius: "100px",
                    border: `5px solid ${white}`,
                    minHeight: "48px",
                },

                "&::-webkit-scrollbar-thumb:hover": {
                    backgroundColor: blue400,
                },
            })}
        >
            {RenderListInput}
        </VariableSizeList>
    );
};

const RenderListInput = ({
    index,
    data,
    isScrolling,
    style,
}: RenderListInputProps) => {
    const {
        listInputsRef,
        itemSizesRef,
        errors,
        inputs,
        isDisabled,
        change,
        placeholder,
    } = data;

    const setSize = useCallback(
        (i: number, size: number) => {
            itemSizesRef.current = { ...itemSizesRef.current, [i]: size };
            listInputsRef.current.resetAfterIndex(i);
        },
        [itemSizesRef, listInputsRef]
    );
    const hasTopPosition = () => {
        const listPosition =
            listInputsRef &&
            listInputsRef.current.state?.scrollOffset +
                listInputsRef.current.props?.height;
        const inputPosition =
            itemSizesRef && getSumOfObjectValues(itemSizesRef.current, index);

        if (listPosition - inputPosition < 200) {
            return true;
        }
        return false;
    };

    return (
        <div
            style={{
                ...style,
            }}
        >
            <ListInput
                i={index}
                errors={errors}
                data={inputs[index]}
                isDisabled={isDisabled}
                setSize={setSize}
                change={change}
                placeholder={placeholder}
                hasTopPosition={hasTopPosition()}
                isLastItem={index === inputs.length - 1}
            />
        </div>
    );
};

export default ListInputs;
