/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from "@emotion/react";

import { Fragment, useContext, useEffect, useState } from "react";
import ThemeContext from "../../../../context/theme/themeContext";
import LanguageContext from "../../../../context/language/languageContext";
import LayoutContext from "../../../../context/layout/layoutContext";
import Button from "../../../MuiComponents/Button";
import UserInfoContext from "../../../../context/userInfo/userInfoContext";
import AppTypeContext from "../../../../context/appType/appTypeContext";
import useNavigation from "../../../../utils/useNavigation";
import AlertContext from "../../../../context/alert/alertContext";
import ConfirmModal from "../../../MuiComponents/Modals/ConfirmModal";
import useUploadFile from "../../../../utils/useUploadFile";
import { getFileNameFromPath } from "../../../../utils/helpers";
import MenuItemContext from "../../../../context/menuItem/menuItemContext";
import { AlertStatus, FROM_FILE_LOAD_STATUS, } from "../../../../constants/constants";
import SettingsContext from "../../../../context/settings/settingsContext";
import useTct from "../../../../utils/useTct";
import { NotificationType } from "../../../../context/settings/settingsReducer";

const UploadFiles = () => {
    const {
        colors: { white, gray100 },
    } = useContext(ThemeContext);

    const showLoadFromDevice = false;
    const { t } = useContext(LanguageContext);

    const {
        layoutData,
        actionLoading,
        setUploadedFileDataToLayout,
        uploadFileLoadStatus,
        uploadFileName,
        setUploadFileName,
        setUploadFileLoadStatus,
    } = useContext(LayoutContext);
    const { isEditor } = useContext(AppTypeContext);
    const { setDisabledParameters } = useContext(MenuItemContext);

    const { isDesktopApplication } = useContext(UserInfoContext);
    const { setAlert } = useContext(AlertContext);
    const { setNotification } = useContext(SettingsContext);

    const { chechFileCompatibilityAsync, loadParametersFromFileAsync, showErrorMsg } = useTct();
    const { refreshCurrentPage } = useNavigation();

    const { inputProps, selectedFile, openFileDialog } = useUploadFile({
        accept: ".cfg, .config",
    });

    const getLoadStatus = () =>
        layoutData?.fromFile
            ? FROM_FILE_LOAD_STATUS
            : layoutData?.settings.deviceSettings[0].loadStatus;

    const getFileName = () => {
        return layoutData?.fromFile
            ? getFileNameFromPath(layoutData.path as string)
            : layoutData?.settings.deviceSettings[0].fileName;
    }

    useEffect(() => {
        setUploadFileLoadStatus(getLoadStatus());
        setUploadFileName(getFileName());
    }, [layoutData]);

    const [
        fileCompatibilityErrorDescription,
        setFileCompatibilityErrorDescription,
    ] = useState("");
    const [isConfirmationModalOpen, setIsConfirmationModalOpen] =
        useState(false);
    const onConfirmationModalClose = () => setIsConfirmationModalOpen(false);
    const onConfirmationModalSubmit = () => {
        setIsConfirmationModalOpen(false);
        uploadFile();
    };

    const uploadFile = async () => {
        try {
            if(!layoutData){
                throw new Error("No layout was found");
            }
            if(!selectedFile){
                return;
            }

            const data = await loadParametersFromFileAsync(layoutData.id, selectedFile); 
            data?.visibilities && setDisabledParameters(data.visibilities);

            setUploadFileLoadStatus(FROM_FILE_LOAD_STATUS);

            setUploadFileName(selectedFile.name);
            setUploadedFileDataToLayout({
                fromFile: true,
                path: selectedFile.name,
            });

            refreshCurrentPage();

            if (data.alerts && data.alerts.length > 0) {
                const alert = data.alerts[0];
                setNotification(alert.type as unknown as NotificationType, alert.title, alert.description);
                setAlert(alert.type as unknown as AlertStatus, alert.title, alert.description);
            } else {
                setAlert(AlertStatus.Success, t.ParametersLoadedFile);
            }
        } catch (error) {
            console.log(error);
            showErrorMsg(error as any);
        }
    };
    
    const checkFileCompatibility = async () => {
        try {
            if(!selectedFile){
                return;
            }
            
            await chechFileCompatibilityAsync(layoutData?.id || 0, selectedFile);

            uploadFile();
        } catch (error) {
            if ((error as any).response.status === 409) {
                setFileCompatibilityErrorDescription(
                    (error as any).response.data.detail
                );
                setIsConfirmationModalOpen(true);
                return;
            }
            console.log(error);
            showErrorMsg(error as any);
        }
    };
    
    useEffect(() => {
        if (selectedFile) {
            checkFileCompatibility();
        }
    }, [selectedFile]);

    const renderConfigurationName = () => {
        if (uploadFileLoadStatus === FROM_FILE_LOAD_STATUS) {
            return uploadFileName;
        }
        if (!isDesktopApplication && isEditor) {
            return layoutData?.fileName;
        }
        return t[uploadFileLoadStatus];
    };

    return (
        <Fragment>
            <div>
                <div
                    css={css({
                        color: gray100,
                        fontSize: "12px",
                        lineHeight: "16px",
                        letterSpacing: "0.16px",
                        paddingLeft: "8px",
                        wordBreak: "break-word",
                    })}
                >
                    {t.Configuration}:
                </div>

                <div
                    css={css({
                        color: white,
                        fontWeight: 600,
                        lineHeight: "20px",
                        letterSpacing: "0.1px",
                        paddingLeft: "8px",
                        whiteSpace: "nowrap",
                        textOverflow: "ellipsis",
                        overflowX: "hidden",
                        margin: "12px 0",
                    })}
                >
                    {renderConfigurationName()}
                </div>

                <Fragment>
                    <Button
                        fullWidth
                        variant="textOnly"
                        size="small"
                        color="transparent"
                        disabled={actionLoading}
                        onClick={openFileDialog}
                        css={css({
                            wordBreak: "break-word",
                        })}
                        idForTesting="uploadFileButton"
                    >
                        <span>{"Upload file (.cfg)"}</span>
                    </Button>
                    <input {...inputProps} />
                </Fragment>

                {showLoadFromDevice && layoutData?.isOnline && (
                    <Button
                        fullWidth
                        variant="textOnly"
                        size="small"
                        color="transparent"
                        disabled={actionLoading}
                        css={css({
                            wordBreak: "break-word",
                            marginTop: "4px",
                        })}
                        idForTesting="loadFromDeviceButton"
                    >
                        {t.LoadFromDevice}
                    </Button>
                )}
            </div>
            <ConfirmModal
                open={isConfirmationModalOpen}
                close={onConfirmationModalClose}
                submit={onConfirmationModalSubmit}
                title={t.ConfigurationMismatch}
                description={fileCompatibilityErrorDescription}
                primaryBtnLabel={t.Continue}
                secondaryBtnLabel={t.Cancel}
            />
        </Fragment>
    );
};

export default UploadFiles;
