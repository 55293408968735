import React from "react";
import SvgIcon from "@mui/material/SvgIcon";

const SystemIcon = (props: any) => {
    return (
        <SvgIcon {...props}>
            <path
                d="M4 7C4 6.45 4.45 6 5 6H21C21.55 6 22 5.55 22 5C22 4.45 21.55 4 21 4H4C2.9 4 2 4.9 2 6V17H1.5C0.67 17 0 17.67 0 18.5C0 19.33 0.67 20 1.5 20H14V17H4V7ZM23 8H17C16.45 8 16 8.45 16 9V19C16 19.55 16.45 20 17 20H23C23.55 20 24 19.55 24 19V9C24 8.45 23.55 8 23 8ZM22 17H18V10H22V17Z"
                fill="currentColor"
            />
        </SvgIcon>
    );
};

export default SystemIcon;
