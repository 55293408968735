import React from "react";
import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

const FilterIcon = (props: SvgIconProps) => {
    return (
        <SvgIcon viewBox="0 0 18 14" {...props}>
            <path
                d="M0 0L0 14H18V0L0 0ZM5.33 12H2V2H5.33V12ZM10.67 12H7.34V2H10.67V12ZM16 12H12.67V2H16V12Z"
                fill="#334155"
            />
        </SvgIcon>
    );
};

export default FilterIcon;
