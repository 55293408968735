/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from "@emotion/react";

import { useContext } from "react";
import ThemeContext from "../../../context/theme/themeContext";
import LanguageContext from "../../../context/language/languageContext";

export interface TechnologyProps {
    name: string;
}

const Technology = ({ name }: TechnologyProps) => {
    const {
        colors: { gray200, gray100, blue200, blue900, gray700 },
    } = useContext(ThemeContext);

    const { t } = useContext(LanguageContext);

    const legacyDevice = name === "legacy";

    return (
        <span
            css={css`
                background: ${legacyDevice ? gray100 : blue200};
                color: ${legacyDevice ? gray700 : blue900};
                border: ${legacyDevice ? `1px solid ${gray200}` : "none"};
                border-radius: 4px;
                display: inline-block;
                font-weight: 600;
                padding: ${legacyDevice ? "5px 8px" : "6px 8px"};
                font-size: 14px;
                line-height: 20px;
                letter-spacing: 0.1px;

                @media (max-width: 839px) {
                    padding: 2px 4px;
                    font-size: 12px;
                    line-height: 16px;
                    letter-spacing: 0.4px;
                }
            `}
        >
            {legacyDevice ? t.LegacyDevice : name}
        </span>
    );
};

export default Technology;
