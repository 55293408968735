/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from "@emotion/react";

export interface AccordionDetailItemProps {
    label: React.JSX.Element | string;
}

const AccordionDetailItem: React.FunctionComponent<
    AccordionDetailItemProps
> = ({ label }) => {
    return (
        <span
            css={css({
                width: "100%",
                fontSize: "12px",
                fontWeight: 400,
                fontStyle: "normal",
                letterSpacing: "0.4px",
                lineHeight: "16px",
                display: "flex",
            })}
        >
            {label}
        </span>
    );
};

export default AccordionDetailItem;
