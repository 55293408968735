/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from "@emotion/react";
import { Fragment, useContext, useEffect, useState } from "react";
import FileIcon from "../../../../../assets/icons/FileIcon";
import ThemeContext from "../../../../../context/theme/themeContext";
import Button from "../../../../MuiComponents/Button";
import { noop } from "../../../../../utils/helpers";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import CanConfigurationModal from "./CanConfigurationModal";
import LanguageContext from "../../../../../context/language/languageContext";
import useApi from "../../../../../utils/useApi";
import LayoutContext from "../../../../../context/layout/layoutContext";
import {
    CanConfigurationUIWidgetState,
    UIWidget,
} from "../../../../../generatedTypes";
import AlertContext from "../../../../../context/alert/alertContext";
import { CircularProgress } from "@mui/material";
import LlsCalibrationModal from "./LlsCalibrationModal";
import Divider from "../../../../MuiComponents/Divider";
import { AlertStatus } from "../../../../../constants/constants";

export interface UiWidgetProps {
    data: UIWidget;
}

const UiWidget = ({ data }: UiWidgetProps) => {
    const { type, uid } = data;
    const {
        colors: { gray200, textDarkSecondary, blue700 },
    } = useContext(ThemeContext);
    const { layoutData } = useContext(LayoutContext);
    const { selectedLanguage, t } = useContext(LanguageContext);
    const { setAlert } = useContext(AlertContext);

    const configurationId = layoutData?.id;

    const { deleteData, getData } = useApi();

    const [isModalOpen, setModalOpen] = useState(false);
    const [responseData, setData] =
        useState<CanConfigurationUIWidgetState | null>(null);
    const [reloadData, setReloadData] = useState(false);

    const [isDeleting, setIsDeleting] = useState(false);

    useEffect(() => {
        const getSelectData = async () => {
            try {
                const { data } = await getData(
                    `${selectedLanguage}/editor/${configurationId}/uiwidgets/state?type=${type}&uid=${uid}`
                );
                setData(data);
            } catch (error) {
                console.log("err", error);
            }
        };
        type !== "AinLlsCalibration" && getSelectData();
        setReloadData(false);
    }, [reloadData]);

    const handleDeleteClick = async () => {
        try {
            setIsDeleting(true);
            await deleteData(`${selectedLanguage}/device/canConfig/file`);
            setReloadData(true);
            setIsDeleting(false);
            setAlert(AlertStatus.Success, t.FileDeletedSuccessfully);
        } catch (err) {
            setIsDeleting(false);
            console.log(err);
        }
    };

    const renderContentBasedOnType = () => {
        if (type === "AinLlsCalibration") {
            return (
                <Fragment>
                    <div>
                        {" "}
                        <Button
                            variant="textOnly"
                            size="normal"
                            color="primary"
                            onClick={() => setModalOpen(true)}
                            disabled={isDeleting}
                            idForTesting="calibrate-button"
                            fullWidth
                        >
                            {t.Calibrate}
                        </Button>
                    </div>
                    <LlsCalibrationModal
                        isOpen={isModalOpen}
                        onClose={() => {
                            setModalOpen(false);
                        }}
                    />
                </Fragment>
            );
        }
        if (type === "CanConfiguration") {
            return (
                <Fragment>
                    <div
                        css={css({
                            border: `1px solid ${gray200}`,
                            padding: "16px",
                            marginTop: "10px",
                            borderRadius: "8px",
                            color: textDarkSecondary,
                            fontWeight: 600,
                        })}
                    >
                        <div
                            css={css({
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                marginBottom: "16px",
                            })}
                        >
                            <div
                                css={css({
                                    display: "flex",
                                    alignItems: "center",
                                })}
                            >
                                <FileIcon />
                                <div css={css({ marginLeft: "12px" })}>
                                    {!responseData?.hasCanFile
                                        ? t.NoFileFound
                                        : responseData?.title}
                                </div>
                            </div>
                            {responseData?.hasCanFile && (
                                <div>
                                    <Button
                                        variant="iconOnly"
                                        size="small"
                                        color="primarySubtle"
                                        idForTesting="delete-file-button"
                                        icon={
                                            isDeleting ? (
                                                <CircularProgress
                                                    size={23}
                                                    css={css`
                                                        color: ${blue700};
                                                    `}
                                                />
                                            ) : (
                                                <DeleteOutlineIcon
                                                    css={css({
                                                        color: blue700,
                                                    })}
                                                />
                                            )
                                        }
                                        onClick={() => handleDeleteClick()}
                                    />
                                </div>
                            )}
                        </div>
                        <Divider hasLighterGray />
                        <div css={css({ marginTop: "16px" })}>
                            {t.CanMake}: {responseData?.make}
                        </div>
                        <div
                            css={css({ marginTop: "4px", marginBottom: "6px" })}
                        >
                            {t.CanModel}: {responseData?.model}
                        </div>
                        <div
                            css={css({ marginTop: "4px", marginBottom: "6px" })}
                        >
                            {t.CanYearFromTo}: {responseData?.yearFromTo}
                        </div>
                        <div
                            css={css({ marginTop: "4px", marginBottom: "6px" })}
                        >
                            {t.CanAdded}: {responseData?.canFileTime}
                        </div>
                    </div>
                    <div
                        css={css({
                            display: "flex",
                            marginTop: "10px",
                            gap: "16px",
                            "& > button": {
                                width: "50%",
                            },
                        })}
                    >
                        <Button
                            variant="textOnly"
                            size="small"
                            color="primarySubtle"
                            onClick={() => setModalOpen(true)}
                            disabled={isDeleting}
                            idForTesting="upload-file-button"
                        >
                            {t.ManualUpload}
                        </Button>
                        <Button
                            variant="textOnly"
                            size="small"
                            color="primarySubtle"
                            onClick={noop}
                            disabled={isDeleting}
                            idForTesting="auto-scan-button"
                        >
                            {t.AutoScan}
                        </Button>
                    </div>
                    <CanConfigurationModal
                        isOpen={isModalOpen}
                        onClose={() => {
                            setModalOpen(false);
                            setReloadData(true);
                        }}
                    />
                </Fragment>
            );
        }
        return <div>Missing type</div>;
    };
    return renderContentBasedOnType();
};

export default UiWidget;
