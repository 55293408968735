/** @jsxRuntime classic */
/** @jsx jsx */
import React, { useContext, useMemo, useState } from "react";
import ErrorContext from "./errorContext";
import { jsx } from "@emotion/react";
import { ErrorDetails } from "../../utils/types";
import ErrorSection from "../../components/MuiComponents/ErrorSection";

interface ErrorStateProps {
    children: React.ReactNode;
}

const ErrorState = ({ children }: ErrorStateProps) => {
    const [error, setErrorInternal] = useState<ErrorDetails | null>(null);
    const setError = (error: ErrorDetails) => setErrorInternal(error);
    const removeError = () => setErrorInternal(null);

    const contextValue = useMemo(() => {
        return {
            error,
            setError,
            removeError,
        };
    }, [error]);

    return (
        <ErrorContext.Provider value={contextValue}>
            {error ? <ErrorPage /> : children}
        </ErrorContext.Provider>
    );
};

const ErrorPage = () => {
    const { error } = useContext(ErrorContext);

    return (
        <ErrorSection
            title={error?.title ?? "That's an error."}
            description={error?.description || ""}
            withoutButton
        />
    );
};

export default ErrorState;
