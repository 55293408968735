import React, { useContext } from "react";
import LayoutContext from "../../../context/layout/layoutContext";
import OnlineView from "./OnlineView";
import OfflineView from "./OfflineView";
import UserLayout from "../UserLayout";
import UserInfoContext from "../../../context/userInfo/userInfoContext";
import DeviceAuthorizationState from "../../../context/deviceAuthorization/deviceAuthorizationState";

const StatusView = () => {
    const { layoutData } = useContext(LayoutContext);
    const { isDeviceDisconnected } = useContext(UserInfoContext);

    return (
        <UserLayout>
            {layoutData?.isOnline && !isDeviceDisconnected ? (
                <DeviceAuthorizationState>
                    <OnlineView/>
                </DeviceAuthorizationState>
            ) : (
                <OfflineView />
            )}
        </UserLayout>
    );
};

export default StatusView;
