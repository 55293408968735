import { useLocation } from "react-router-dom";
import qs from "query-string";

const useUrlQueryParams = () => {
    const { pathname, search } = useLocation();

    const getUrlParams = () => {
        const parsedUrlParams = qs.parse(search);

        return parsedUrlParams;
    };

    const sendParamsToUrl = (params: any) => {
        const stringified = `?${qs.stringify(params)}`;

        window.history.replaceState(null, "", stringified);
    };

    return { getUrlParams, sendParamsToUrl, pathname };
};

export default useUrlQueryParams;
