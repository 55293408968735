import BundleUpdateReducer from "./bundleUpdateReducer";
import BundleUpdateContext, {
    BundleUpdateContextState,
} from "./bundleUpdateContext";
import {
    SET_EXPANDED,
    SET_STEP,
    SET_LOADING,
    SET_ERROR,
    SET_FILE,
} from "./bundleUpdateActions";
import React, { useMemo, useReducer } from "react";

export interface BundleUpdateStateProps {
    children: React.ReactNode;
}

const BundleUpdateState = ({ children }: BundleUpdateStateProps) => {
    const initialState = {
        expanded: false,
        step: 0,
        loading: false,
        error: null,
        file: null,
    };

    const [state, dispatch] = useReducer(BundleUpdateReducer, initialState);

    const setExpanded = (expanded: string | false) => {
        dispatch({
            type: SET_EXPANDED,
            payload: expanded,
        });
    };

    const setStep = (step: number) => {
        dispatch({
            type: SET_STEP,
            payload: step,
        });
    };

    const setLoading = (loading: boolean) => {
        dispatch({
            type: SET_LOADING,
            payload: loading,
        });
    };

    const setError = (error: string | null) => {
        dispatch({
            type: SET_ERROR,
            payload: error,
        });
    };

    const setFile = (file: { filename: string; file: FormData }) => {
        dispatch({
            type: SET_FILE,
            payload: file,
        });
    };

    const contextValue = useMemo(() => {
        return {
            setExpanded,
            setStep,
            setLoading,
            setError,
            setFile,
            expanded: state.expanded,
            step: state.step,
            loading: state.loading,
            error: state.error,
            file: state.file,
        } as BundleUpdateContextState;
    }, [state]);

    return (
        <BundleUpdateContext.Provider value={contextValue}>
            {children}
        </BundleUpdateContext.Provider>
    );
};

export default BundleUpdateState;
