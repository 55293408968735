/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from "@emotion/react";

import { useContext, useState } from "react";
import ThemeContext from "../../../../../context/theme/themeContext";
import SingleSearchResult from "../SingleSearchResult";
import ToggleButton from "../../../ToggleButton";
import Collapse from "@mui/material/Collapse";

interface ParametersProps {
    results: any;
}

const Parameters = ({ results }: ParametersProps) => {
    const {
        colors: { textPlaceholder },
    } = useContext(ThemeContext);

    const [showAll, setShowAll] = useState(false);

    const handleShowAllToggle = () => setShowAll(!showAll);

    return (
        <div>
            <div
                css={css({
                    color: textPlaceholder,
                    fontSize: "12px",
                    fontWeight: "600",
                    lineHeight: "16px",
                    letterSpacing: "0.4px",
                    padding: "12px 16px 4px 16px",
                })}
            >
                Parameters
            </div>

            {results.map(
                (result: any, i: number) =>
                    i < 3 && (
                        <SingleSearchResult
                            key={i}
                            type="parameters"
                            result={result}
                        />
                    )
            )}

            <Collapse in={showAll}>
                {results.map(
                    (result: any, i: number) =>
                        i > 2 && (
                            <SingleSearchResult
                                key={i}
                                type="parameters"
                                result={result}
                            />
                        )
                )}
            </Collapse>

            {results.length > 3 && (
                <ToggleButton
                    closed={!showAll}
                    title={`Show all ${results.length} results`}
                    toggleResults={handleShowAllToggle}
                />
            )}
        </div>
    );
};

export default Parameters;
