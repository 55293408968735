import {
    HubConnection,
    HubConnectionBuilder,
    LogLevel,
} from "@microsoft/signalr";
import { useContext, useEffect, useState } from "react";
import UserInfoContext from "../context/userInfo/userInfoContext";
import { JwtTokenKey } from "../constants/constants";

const useWebSockets = (hub: string) => {
    const { isDesktopApplication } = useContext(UserInfoContext);
    const [connection, setConnection] = useState<HubConnection | null>(null);
    useEffect(() => {
        if (isDesktopApplication) {
            const jwt = sessionStorage.getItem(JwtTokenKey) ?? "";
            
            const newConnection = new HubConnectionBuilder()
                .withUrl(hub, {
                   accessTokenFactory: () => jwt,
                })
                .withAutomaticReconnect()
                .configureLogging(LogLevel.Information)
                .build();

            setConnection(newConnection);
        }
        // eslint-disable-next-line
    }, [isDesktopApplication]);

    const startConnection = async () => {
        if (connection) {
            try {
                await connection.start();
            } catch (error) {
                console.log(`${hub} connection error: ${error}`);
            }
        }
    };

    const stopConnection = async () => {
        if (connection) {
            try {
                await connection.stop();
            } catch (error) {
                console.log(`Stop connection for ${hub} error: ${error}`);
            }
        }
    };

    return { connection, startConnection, stopConnection };
};

export default useWebSockets;
