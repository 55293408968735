import React, { useContext } from "react";
import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export interface CircleIconProps extends SvgIconProps {
    css?: React.CSSProperties;
}

const CircleIcon = ({ css, ...props }: CircleIconProps) => {
    return (
        <SvgIcon
            style={css}
            width={32}
            height={32}
            viewBox="0 0 32 32"
            {...props}
        >
            <svg fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect
                    width="32"
                    height="32"
                    rx="8"
                    fill="#F1F5F9"
                    display={"inline"}
                />
                <g clipPath="url(#clip0_16646_75002)">
                    <circle
                        cx="16"
                        cy="16"
                        r="9"
                        stroke="#334155"
                        strokeWidth="2"
                    />
                    <circle
                        cx="16"
                        cy="16"
                        r="1.4"
                        stroke="#334155"
                        strokeWidth="1.2"
                    />
                    <path
                        d="M17 16H26"
                        stroke="#334155"
                        strokeDasharray="1.5 1"
                    />
                </g>
                <defs>
                    <clipPath id="clip0_16646_75002">
                        <rect
                            width="24"
                            height="24"
                            fill="white"
                            transform="translate(4 4)"
                        />
                    </clipPath>
                </defs>
            </svg>
        </SvgIcon>
    );
};

export default CircleIcon;
