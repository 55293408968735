/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from "@emotion/react";

import { Fragment, useContext, useState } from "react";
import ThemeContext from "../../../../context/theme/themeContext";
import LayoutContext from "../../../../context/layout/layoutContext";
import Box from "../../../Box";
import Skeleton from "@mui/material/Skeleton";
import CircularProgress from "@mui/material/CircularProgress";
import TypedGrid from "../../../MuiComponents/TypedGrid";
import DeviceStatusContext from "../../../../context/deviceStatus/deviceStatusContext";
import LanguageContext from "../../../../context/language/languageContext";
import useInterval from "../../../../utils/useInterval";
import Divider from "../../../MuiComponents/Divider";
import UserInfoContext from "../../../../context/userInfo/userInfoContext";

export interface GeneralBlockProps {
    hasNoLoadingText?: boolean;
    hasDivider?: boolean;
}

const GeneralBlock = ({ hasNoLoadingText, hasDivider }: GeneralBlockProps) => {
    const {
        colors: { gray300, gray200, textPlaceholder },
    } = useContext(ThemeContext);

    const {
        rebooting,
        fwUpdating,
        layoutData,
        readingAccelometer,
        capturingDump,
    } = useContext(LayoutContext);
    const { dumpData } = useContext(DeviceStatusContext);
    const { t } = useContext(LanguageContext);
    const { isDesktopApplication } = useContext(UserInfoContext);
    const [secondsRemaining, setSecondsRemaining] = useState<number>(
        layoutData?.settings.captureTimeSec || 0
    );

    const secondsToDisplay = secondsRemaining % 60;
    const minutesRemaining = (secondsRemaining - secondsToDisplay) / 60;
    const minutesToDisplay = minutesRemaining % 60;
    const hoursToDisplay = (minutesRemaining - minutesToDisplay) / 60;
    const twoDigits = (num: number) => String(num).padStart(2, "0");
    useInterval(
        () => {
            if (secondsRemaining > 0) {
                setSecondsRemaining(secondsRemaining - 1);
            }
        },
        readingAccelometer ? 1000 : null
    );
    const renderLoadingText = () => {
        if (hasNoLoadingText) {
            return "";
        }
        if (capturingDump && dumpData) {
            return (
                <Fragment>
                    {t.DownloadDump} {dumpData.currentFile}/
                    {dumpData.filesCount} {dumpData.percents}%
                </Fragment>
            );
        }
        if (readingAccelometer) {
            return (
                <Fragment>
                    {t.ReadingAccelometerInfo} {twoDigits(hoursToDisplay)}:
                    {twoDigits(minutesToDisplay)}:{twoDigits(secondsToDisplay)}
                </Fragment>
            );
        }
        if (rebooting) {
            return t.DeviceIsRebooting;
        }
        if (fwUpdating) {
            return t.FirmwareIsUpdating;
        }
        if (!isDesktopApplication) {
            return t.ConfigurationIsLoading;
        }
        return t.ContentIsLoading;
    };
    return (
        <Box
            css={css({
                height: "100%",
            })}
        >
            <TypedGrid item xs0={12} xl={12}>
                <div
                    css={css({
                        marginBottom: hasDivider ? "0px" : "49px",
                        padding: "16px 24px",
                    })}
                >
                    <TypedGrid
                        item
                        xs0={3}
                        xl={3}
                        css={css({
                            "& > span + span": {
                                marginTop: "12px",
                            },
                        })}
                    >
                        <Skeleton height={24} width={252} />
                        <Skeleton height={20} width={171} />
                    </TypedGrid>
                </div>
                {hasDivider && (
                    <Divider
                        additionalCss={{
                            marginBottom: "48px",
                            background: gray200,
                        }}
                    />
                )}
                <TypedGrid
                    container
                    item
                    xs0={12}
                    xl={12}
                    justifyContent={"center"}
                    alignItems={"center"}
                    flexDirection={"column"}
                >
                    <CircularProgress size={40} css={css({ color: gray300 })} />
                    <div
                        css={css({
                            marginTop: 16,
                            lineHeight: 20,
                            fontSize: 14,
                            color: textPlaceholder,
                            letterSpacing: "0.1px",
                        })}
                    >
                        {renderLoadingText()}
                    </div>
                </TypedGrid>
            </TypedGrid>
        </Box>
    );
};

export default GeneralBlock;
