import React, { useContext } from "react";
import { useHistory, useParams, generatePath } from "react-router-dom";
import MenuItemContext from "../../../context/menuItem/menuItemContext";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { FrameData } from "../../../utils/types";
import { EDITOR_FRAME_ITEM } from "../../../constants/routes";
import { FrameItemViewParams } from ".";

interface FrameItemsMenuProps {
    anchorEl: HTMLDivElement | null;
    closeMenu: () => void;
    activeFrame: {
        index: number;
    };
}

const FrameItemsMenu = ({
    anchorEl,
    closeMenu,
    activeFrame,
}: FrameItemsMenuProps) => {
    const { breadcrumbItems } = useContext(MenuItemContext);
    const history = useHistory();
    const { menuItem } = useParams<FrameItemViewParams>();

    const goToFrameItem = (menuItemIndex: number, isSelected: boolean) => {
        closeMenu();

        if (!isSelected) {
            const generatedPath = generatePath(EDITOR_FRAME_ITEM, {
                menuItem,
                frameItemIndex: menuItemIndex,
            });

            history.push(generatedPath);
        }
    };

    return (
        <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={closeMenu}
            anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
            }}
            transformOrigin={{
                vertical: "top",
                horizontal: "left",
            }}
            sx={{
                "& .MuiPaper-root": {
                    marginLeft: "-22px",
                },
                "& .MuiList-root": {
                    minWidth: "212px",
                },
            }}
        >
            {breadcrumbItems.map((frame: FrameData) => (
                <MenuItem
                    key={frame.index}
                    selected={frame.index === activeFrame.index}
                    onClick={() =>
                        goToFrameItem(
                            frame.index,
                            frame.index === activeFrame.index
                        )
                    }
                >
                    {frame.name}
                </MenuItem>
            ))}
        </Menu>
    );
};

export default FrameItemsMenu;
