/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import { FEList } from "../../../../../../generatedTypes";
import useFeElementParser from "../../../../../../utils/useFeElementParser";

interface FeListProps {
    list: FEList;
}

const FeList = ({ list }: FeListProps) => {
    const { parseTextStyle } = useFeElementParser();

    return (
        <ul css={css({ padding: "0 24px", margin: "4px 0 0 0" })}>
            {list.items.map((listItem: any, index) => (
                <li key={index} css={css(parseTextStyle(listItem.textStyle))}>
                    {listItem.text}
                </li>
            ))}
        </ul>
    );
};

export default FeList;
