/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from "@emotion/react";

import { Fragment } from "react";

export interface IconRightButtonProps {
    children: React.ReactNode;
    icon: React.ReactNode;
}
const IconRightButton = ({ children, icon }: IconRightButtonProps) => {
    return (
        <Fragment>
            {children}

            <span
                css={css`
                    display: flex;
                    align-items: center;
                    margin-left: 8px;
                `}
            >
                {icon}
            </span>
        </Fragment>
    );
};

export default IconRightButton;
