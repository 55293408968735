/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from "@emotion/react";

import { useContext, useState } from "react";
import LanguageContext from "../../../../../context/language/languageContext";
import useApi from "../../../../../utils/useApi";
import Tooltip from "../../../../MuiComponents/Tooltip";
import Button from "../../../../MuiComponents/Button";
import CircularProgress from "@mui/material/CircularProgress";

export interface ResetProps {
    resource: string;
    type: number;
    noData: boolean;
    noDataText: string;
}

const Reset = ({ resource, type, noData, noDataText }: ResetProps) => {
    const { selectedLanguage } = useContext(LanguageContext);

    const { postData, showErrorMsg } = useApi();

    const [resetting, setResetting] = useState(false);

    const resetData = async () => {
        try {
            setResetting(true);

            await postData(`${selectedLanguage}/command/${resource}`, type);
        } catch (error: any) {
            showErrorMsg(error);
        }

        setResetting(false);
    };

    return (
        <Tooltip title={noData ? noDataText : ""} placement="top">
            <span
                css={css`
                    display: block;
                `}
            >
                <Button
                    fullWidth
                    variant={resetting ? "iconLeft" : "textOnly"}
                    icon={
                        resetting ? (
                            <div
                                css={css`
                                    width: 24px;
                                    height: 24px;
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                `}
                            >
                                <CircularProgress size={20} />
                            </div>
                        ) : null
                    }
                    size="small"
                    color="primarySubtle"
                    disabled={noData}
                    onClick={resetData}
                    css={css`
                        pointer-events: ${resetting ? "none" : "auto"};
                    `}
                    idForTesting={noData ? "resetDisabled" : "resetEnabled"}
                >
                    {resetting ? "Resetting" : "Reset"}
                </Button>
            </span>
        </Tooltip>
    );
};

export default Reset;
