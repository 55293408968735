/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import { useContext, Fragment } from "react";
import SettingsContext from "../../../../../../../context/settings/settingsContext";
import ThemeContext from "../../../../../../../context/theme/themeContext";
import Tag from "../../../../../../MuiComponents/Tag";
import Tooltip from "../../../../../../MuiComponents/Tooltip";
import TableSwitchInput from "./TableSwitchInput";

export interface DescriptionWithSwitchProps {
    row: any;
    onChange: (row: any) => void;
}

const DescriptionWithSwitch = ({
    row,
    onChange,
}: DescriptionWithSwitchProps) => {
    const {
        colors: { white },
    } = useContext(ThemeContext);
    const { isParameterIdsHidden, isExplanatoryTextsHidden } =
        useContext(SettingsContext);

    let keySeed = 1;

    const getIoItemNameElement = (): React.JSX.Element => {
        const avlId = row.original.avlId;
        return (
            <Fragment>
                <div key={(keySeed++).toString()}>{row.original.name}</div>
                {avlId ? (
                    <div key={(keySeed++).toString()}>AVL ID: {avlId}</div>
                ) : null}
            </Fragment>
        );
    };

    // Names of properties for IO item parts in order they shall be presented in the tooltip
    const ioPropertyNames = [
        "priority",
        "operand",
        "highLevel",
        "lowLevel",
        "eventOnly",
        "averagingConstant",
        "phone",
        "text",
        "canSrc",
        "canType",
        "canId",
        "dataMask",
    ];
    const lastPropertyName = ioPropertyNames[ioPropertyNames.length - 1];

    const getPartTooltipLines = (propertyName: string): string[] => {
        // For some reason some column ids are different
        const aliases = {
            averagingConstant: "avgConst",
            phone: "sendSmsTo",
            text: "smsText",
        };
        type AliasesObjectKey = keyof typeof aliases;

        const parameter = row.original[propertyName];
        if (parameter) {
            let foundCell = row.allCells.find(
                (cell: { column: { id: string } }) =>
                    cell.column.id === propertyName
            );
            if (!foundCell) {
                const alias = aliases[propertyName as AliasesObjectKey];
                if (alias) {
                    foundCell = row.allCells.find(
                        (cell: { column: { id: string } }) =>
                            cell.column.id ===
                            aliases[propertyName as AliasesObjectKey]
                    );
                }
            }

            if (foundCell)
                return [
                    `${foundCell.column.Header}:`,
                    `ID: ${parameter.parameterId}`,
                ];
        }

        return [];
    };

    // Creates a tooltip title element for IO row
    const getTooltipTitleElement = (): JSX.Element => {
        return (
            <Fragment>
                {getIoItemNameElement()}
                <hr key={(keySeed++).toString()} />
                {ioPropertyNames.map((propertyName) => {
                    const tooltipLines = getPartTooltipLines(propertyName);
                    const divs = tooltipLines.map((line) => (
                        <div key={(keySeed++).toString()}>{line}</div>
                    ));
                    if (divs.length > 0 && propertyName !== lastPropertyName) {
                        divs.push(<hr key={(keySeed++).toString()} />);
                    }
                    return divs;
                })}
            </Fragment>
        );
    };

    return (
        <div
            css={css({
                display: "flex",
                alignItems: "center",
                padding: "12px 24px",
                width: "100%",
                justifyContent: "space-between",
                backgroundColor: white,
            })}
        >
            <div
                css={css({
                    display: "flex",
                    alignItems: "center",
                })}
            >
                <div css={css({ marginRight: "28px" })}>
                    <TableSwitchInput
                        id={row.original.parameterValue.id}
                        label={String(row.original.parameterValue.id)}
                        onChange={onChange}
                    />
                </div>
                <div>
                    <div
                        css={css({
                            marginBotom: "6px",
                            fontWeight: 600,
                            fontSize: "14px",
                            lineHeight: "20px",
                            letterSpacing: "0.1px",
                        })}
                    >
                        {row.original.name}
                    </div>
                    {!isExplanatoryTextsHidden && (
                        <div
                            css={css({
                                fontWeight: 600,
                                fontSize: "12px",
                                lineHeight: "16px",
                                letterSpacing: "0.4px",
                            })}
                        >
                            {row.original.description}
                        </div>
                    )}
                </div>
            </div>
            {!isParameterIdsHidden && (
                <Tooltip title={getTooltipTitleElement()} small placement="top">
                    <span>
                        <Tag size="tiny" color="white" title="ID" />
                    </span>
                </Tooltip>
            )}
        </div>
    );
};

export default DescriptionWithSwitch;
