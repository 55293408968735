/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/react";
import CellWithTooltip from "../../CellWithTooltip";

export interface TypeLabelCellProps {
    row: any;
}

const TypeLabelCell: React.FunctionComponent<TypeLabelCellProps> = ({
    row,
}) => {
    return (
        <CellWithTooltip
            rowValue={row.original.typeLabel}
            maxShownStringLength={20}
        />
    );
};

export default TypeLabelCell;
