/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/react";

import React, { useMemo, useState } from "react";
import { ConnectionTypes } from "../../constants/constants";
import { noop } from "../../utils/helpers";
import DeviceAuthorizationContext from "./deviceAuthorizationContext";
import EnterPasswordModal from "../../components/StartUp/Online/DiscoveredDevices/EnterPasswordModal";
import DeviceLockedModal from "../../components/StartUp/Online/DiscoveredDevices/DeviceLockedModal";

interface DeviceAuthorizationStateProps {
    children: React.ReactNode;
}

const DeviceAuthorizationState = ({
    children,
}: DeviceAuthorizationStateProps) => {
    const [isPasswordModalOpen, setPasswordModalOpenState] = useState(false);
    const [connectAction, setConnectActionState] = useState<
        (redirect: boolean) => void
    >((_redirect: boolean) => noop());
    const [port, setPort] = useState("");
    const [connectionType, setConnectionType] = useState(
        ConnectionTypes.Serial
    );
    const [infoData, setInfoData] = useState(null);

    const [deviceUnlockCode, setDeviceUnlockCode] = useState("");
    const [isUnlockModalOpen, setUnlockModalOpen] = useState(false);

    // Sets the state of whether the enter password modal is open or not, which triggers it to open or close.
    // openEnterPasswordModal should be preferred, since this opens the modal with previous connection values (port, connectionType, connect function)
    const setPasswordModalOpen = (isOpen: boolean) =>
        setPasswordModalOpenState(isOpen);

    const setConnectAction = (action: (redirect: boolean) => void) =>
        setConnectActionState(() => action);

    // Sets the port, connection type and connection function and then opens the enter password modal
    const openEnterPasswordModal = (
        portName: string,
        connType: ConnectionTypes,
        connectFn: (redirect: boolean) => void,
        additionalInfo?: any,
        shouldRedirectToStartupOnCancel?: boolean
    ) => {
        setInfoData({
            ...additionalInfo,
            shouldRedirectToStartupOnCancel: shouldRedirectToStartupOnCancel,
        });
        setPort(portName);
        setConnectionType(connType);
        setConnectAction(connectFn);
        setPasswordModalOpen(true);
    };

    const openExistingUnlockDeviceModal = (unlockCode: string) => {
        setDeviceUnlockCode(unlockCode);
        setUnlockModalOpen(true);
    };
    const openFreshUnlockDeviceModal = (
        portName: string,
        connType: ConnectionTypes,
        connectFn: (redirect: boolean) => void,
        unlockCode: string
    ) => {
        setPort(portName);
        setConnectionType(connType);
        setConnectAction(connectFn);
        setDeviceUnlockCode(unlockCode);
        setUnlockModalOpen(true);
    };

    const onSetInfoData = (data: any) => {
        setInfoData(data);
    };

    const contextValue = useMemo(() => {
        return {
            isPasswordModalOpen,
            setPasswordModalOpen,
            port,
            infoData,
            connectionType,
            connectAction,
            openEnterPasswordModal,
            deviceUnlockCode,
            isUnlockModalOpen,
            setUnlockModalOpen,
            openExistingUnlockDeviceModal,
            openFreshUnlockDeviceModal,
            onSetInfoData,
        };
    }, [
        isPasswordModalOpen,
        port,
        infoData,
        connectionType,
        deviceUnlockCode,
        isUnlockModalOpen,
    ]);
    return (
        <DeviceAuthorizationContext.Provider value={contextValue}>
            {children}
            <EnterPasswordModal />
            <DeviceLockedModal />
        </DeviceAuthorizationContext.Provider>
    );
};

export default DeviceAuthorizationState;
