import { Grid, GridSize } from "@mui/material";
import { BreakpointOverrides } from "@mui/material/styles";
import React from "react";

//https://github.com/mui/material-ui/issues/26369
//Grid doesn't support custom breakpoints for typescript
type TypedGridProps = Parameters<typeof Grid>[0] &
    Partial<Record<keyof BreakpointOverrides, boolean | GridSize>>;

const TypedGrid = ({ children, ...props }: TypedGridProps) => (
    <Grid {...(props as any)}>{children}</Grid>
);

export default TypedGrid;
