/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/react";
import { useContext } from "react";
import LayoutContext from "../../context/layout/layoutContext";
import ErrorSection from "../MuiComponents/ErrorSection";
import UserInfoContext from "../../context/userInfo/userInfoContext";

const ErrorPage = () => {
    const { layoutErrorMessage } = useContext(LayoutContext);
    const { isDesktopApplication } = useContext(UserInfoContext);

    return (
        <ErrorSection
            title="That's an error."
            description={layoutErrorMessage}
            withoutButton={!isDesktopApplication}
        />
    );
};

export default ErrorPage;
