/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx, SerializedStyles } from "@emotion/react";

import { forwardRef, useContext } from "react";
import ThemeContext from "../../context/theme/themeContext";

type Ref = HTMLDivElement;

interface BoxProps {
    children: React.ReactNode;
    css?: SerializedStyles;
    onClick?: () => void;
    id?: string;
    className?: string;
}

const Box = forwardRef<Ref, BoxProps>(
    ({ children, onClick, ...props }, ref) => {
        const {
            colors: { white },
            boxShadows: { boxComponentShadow },
        } = useContext(ThemeContext);

        return (
            <div
                {...props}
                ref={ref}
                onClick={onClick}
                css={css({
                    borderRadius: "8px",
                    background: white,
                    boxShadow: boxComponentShadow,
                })}
            >
                {children}
            </div>
        );
    }
);

export default Box;
