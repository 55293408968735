/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from "@emotion/react";

import { useContext, useEffect } from "react";
import ThemeContext from "../../context/theme/themeContext";
import LinearProgress from "@mui/material/LinearProgress";

export interface ProgressBarProps {
    value: number;
    label?: string;
    onProgressEnd?: () => void;
}

const ProgressBar = ({ value, label, onProgressEnd }: ProgressBarProps) => {
    const {
        colors: { blue100, textDark },
    } = useContext(ThemeContext);

    useEffect(() => {
        if (value === 100) {
            onProgressEnd && onProgressEnd();
        }
    }, [value]);

    return (
        <div
            css={css`
                width: 71%;
                display: flex;
                align-items: center;
            `}
        >
            <LinearProgress
                variant="determinate"
                value={value}
                sx={{
                    flex: 1,
                    backgroundColor: blue100,
                    height: "6px",
                    borderRadius: "3px",
                }}
            />

            {label && (
                <span
                    css={css`
                        margin: 0 6px 0 18px;
                        color: ${textDark};
                        font-size: 16px;
                        line-height: 20px;
                        letter-spacing: 0.15px;
                    `}
                >
                    {label}
                </span>
            )}
        </div>
    );
};

export default ProgressBar;
