/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from "@emotion/react";

import { Fragment, useContext } from "react";
import ThemeContext from "../../../../../../context/theme/themeContext";
import Button from "../../../../../MuiComponents/Button";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { ModalType, SimPinModalTypes } from "./index";
import DeviceLockedIcon from "../../../../../../assets/icons/DeviceLockedIcon";
import LanguageContext from "../../../../../../context/language/languageContext";

interface DeviceLockedProps {
    type: ModalType | null;
    close: () => void;
}

const DeviceLocked = ({ type, close }: DeviceLockedProps) => {
    const {
        colors: { textDark },
    } = useContext(ThemeContext);

    const { t } = useContext(LanguageContext);

    const renderTitle = () => {
        switch (type) {
            case SimPinModalTypes.EnterPin:
                return t.EnterPinCode;
            case SimPinModalTypes.ChangePin:
                return t.ChangePinCode;
            case SimPinModalTypes.EnablePin:
                return t.EnablePinCode;
            case SimPinModalTypes.DisablePin:
                return t.DisablePinCode;
            default:
                return t.DeviceIsLocked;
        }
    };

    return (
        <Fragment>
            <DialogTitle>{renderTitle()}</DialogTitle>

            <DialogContent
                css={css({
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",

                    "& > svg": {
                        fontSize: "96px",
                        marginBottom: "16px",
                    },

                    "& > div": {
                        color: textDark,
                        lineHeight: "20px",
                        letterSpacing: "0.1px",
                    },

                    "& > div:first-of-type": {
                        fontWeight: "600",
                        marginBottom: "16px",
                        textAlign: "center",
                    },
                })}
            >
                <DeviceLockedIcon />

                <div>{t.TooManyIncorrectAttempts}</div>

                <div>{t.SimPinEnteringDisabledExplanation}</div>
            </DialogContent>

            <DialogActions>
                <Button
                    variant="textOnly"
                    size="normal"
                    color="secondary"
                    fullWidth
                    onClick={close}
                    idForTesting="deviceLockedCloseButton"
                >
                    {t.Close}
                </Button>
            </DialogActions>
        </Fragment>
    );
};

export default DeviceLocked;
