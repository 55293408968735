/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from "@emotion/react";
import { useContext } from "react";
import { IoTableColumnTitles } from "../../../../../../../constants/constants";
import ThemeContext from "../../../../../../../context/theme/themeContext";
import { Component } from "../../../../../../../generatedTypes";
import DataMask from "./DataMask";
import TableCollection from "./TableCollection";
import TableInput from "./TableInput";
import TableSwitchInput from "./TableSwitchInput";

export interface MobileTableCardProps {
    row: {
        name: string;
        description: string;
        parameterValue: { id: number; switchValue: string };
        operand: Component;
        priority: Component;
        canType: Component;
        canId: Component;
        dataMask: Component;
    };
}

const MobileTableCard = ({ row }: MobileTableCardProps) => {
    const {
        colors: { gray700, blue100, white },
        boxShadows: { blueBoxShadow, ioTableCardBoxShadow },
    } = useContext(ThemeContext);
    return (
        <div
            css={css({
                marginBottom: "8px",
                filter: "drop-shadow(0px 1px 4px rgba(15, 23, 42, 0.12))",
                fontWeight: 600,
            })}
        >
            <div
                css={css({
                    backgroundColor: blue100,
                    padding: "12px 24px",
                    boxShadow: blueBoxShadow,
                })}
            >
                {row.name}
            </div>
            <div
                css={css({
                    display: "flex",
                    alignItems: "center",
                    padding: "8px 16px",
                    justifyContent: "space-between",
                    backgroundColor: white,
                    boxShadow: ioTableCardBoxShadow,
                })}
            >
                <div>
                    <div
                        css={css({
                            marginBotom: "6px",
                            fontWeight: 600,
                            fontSize: "14px",
                            lineHeight: "20px",
                            letterSpacing: "0.1px",
                        })}
                    >
                        {row.name}
                    </div>
                    <div
                        css={css({
                            fontWeight: 600,
                            fontSize: "12px",
                            lineHeight: "16px",
                            letterSpacing: "0.4px",
                        })}
                    >
                        {row.description}
                    </div>
                </div>
                <div>
                    <TableSwitchInput
                        id={row.parameterValue.id}
                        label={row.parameterValue.id}
                    />
                </div>
            </div>
            <div
                css={css({
                    padding: "12px 16px",
                    backgroundColor: white,
                    boxShadow: ioTableCardBoxShadow,
                })}
            >
                <div css={css({ fontSize: "10px", color: gray700 })}>
                    {IoTableColumnTitles.priority}
                </div>
                <div css={css({ fontSize: "14px" })}>
                    <TableCollection
                        data={row.priority}
                        switchId={row.parameterValue.id}
                    />
                </div>
            </div>
            <div
                css={css({
                    padding: "12px 16px",
                    backgroundColor: white,
                    boxShadow: ioTableCardBoxShadow,
                })}
            >
                <div css={css({ fontSize: "10px", color: gray700 })}>
                    {IoTableColumnTitles.canType}
                </div>
                <div css={css({ fontSize: "14px" })}>
                    <TableCollection
                        data={row.canType}
                        switchId={row.parameterValue.id}
                    />
                </div>
            </div>
            <div
                css={css({
                    padding: "12px 16px",
                    backgroundColor: white,
                    boxShadow: ioTableCardBoxShadow,
                })}
            >
                <div css={css({ fontSize: "10px", color: gray700 })}>
                    {IoTableColumnTitles.canId}
                </div>
                <div css={css({ fontSize: "14px" })}>
                    {row.canId ? (
                        <TableInput
                            data={row.canId}
                            switchId={row.parameterValue.id}
                        />
                    ) : null}
                </div>
            </div>
            <div
                css={css({
                    padding: "12px 16px",
                    backgroundColor: white,
                    boxShadow: ioTableCardBoxShadow,
                })}
            >
                <div css={css({ fontSize: "10px", color: gray700 })}>
                    {IoTableColumnTitles.dataMask}
                </div>
                <DataMask
                    switchId={row.parameterValue.id}
                    data={row.dataMask}
                />
            </div>
            <div
                css={css({
                    padding: "12px 16px",
                    backgroundColor: white,
                    boxShadow: ioTableCardBoxShadow,
                })}
            >
                <div css={css({ fontSize: "10px", color: gray700 })}>
                    {IoTableColumnTitles.operand}
                </div>
                <div css={css({ fontSize: "14px" })}>
                    <TableCollection
                        data={row.operand}
                        switchId={row.parameterValue.id}
                    />
                </div>
            </div>
        </div>
    );
};

export default MobileTableCard;
