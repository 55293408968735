import React, { useReducer } from "react";
import { IoTableColumns, IoTableColumnTitles } from "../../constants/constants";
import {
    FILTER_COLUMNS_BY_NAME,
    RESET_ALL_FILTERS,
    RESET_COLUMN,
    RESET_COLUMNS,
    RESET_FILTER,
    RESET_OPERAND,
    RESET_PRIORITY,
    SET_ACTIVE_STATUS,
    SET_COLUMNS,
    SET_FILTER,
    SET_FILTERS,
    SET_OPERAND,
    SET_PRIORITY,
    SET_SEARCH_VALUE,
} from "./inputOutputActions";
import InputOutputContext from "./inputOutputContext";
import InputOutputReducer from "./inputOutputReducer";

interface InputOutputStateProps {
    children: React.ReactNode;
}

const InputOutputState = ({ children }: InputOutputStateProps) => {
    const defaultColumns = [
        IoTableColumns.priority,
        IoTableColumns.operand,
        IoTableColumns.lowLevel,
        IoTableColumns.highLevel,
    ];

    const initialState = {
        columns: [
            {
                name: IoTableColumnTitles.name,
                isDisabled: true,
            },
            {
                name: IoTableColumnTitles.currentValue,
                value: IoTableColumns.currentValue,
                isDisabled: true,
            },
            {
                name: IoTableColumnTitles.priority,
                value: IoTableColumns.priority,
                isDisabled: false,
            },
            {
                name: IoTableColumnTitles.operand,
                value: IoTableColumns.operand,
                isDisabled: false,
            },
            {
                name: IoTableColumnTitles.lowLevel,
                value: IoTableColumns.lowLevel,
                isDisabled: false,
            },
            {
                name: IoTableColumnTitles.highLevel,
                value: IoTableColumns.highLevel,
                isDisabled: false,
            },
            {
                name: IoTableColumnTitles.eventOnly,
                value: IoTableColumns.eventOnly,
                isDisabled: false,
            },
            {
                name: IoTableColumnTitles.avgConst,
                value: IoTableColumns.avgConst,
                isDisabled: false,
            },
            {
                name: IoTableColumnTitles.sendSmsTo,
                value: IoTableColumns.sendSmsTo,
                isDisabled: false,
            },
            {
                name: IoTableColumnTitles.smsText,
                value: IoTableColumns.smsText,
                isDisabled: false,
            },
        ],
        activeColumns: defaultColumns,
        activeFilters: [],
        activeOperand: [],
        activePriority: [],
        activeStatus: "2",
        searchValue: "",
    };

    const [state, dispatch] = useReducer(InputOutputReducer, initialState);

    const filterColumnsByName = (columnName: string) => {
        dispatch({
            type: FILTER_COLUMNS_BY_NAME,
            payload: columnName,
        });
    };
    const resetColumn = (columnName: string) => {
        dispatch({
            type: RESET_COLUMN,
            payload: columnName,
        });
    };
    const resetColumnsToDefault = () => {
        dispatch({
            type: RESET_COLUMNS,
            payload: defaultColumns,
        });
    };
    const setActiveFilters = (filters: string[]) => {
        dispatch({ type: SET_FILTERS, payload: filters });
    };
    const setActiveFilter = (filter: string) => {
        dispatch({ type: SET_FILTER, payload: filter });
    };
    const setActiveOperand = (operand: { value: string; text: string }[]) => {
        dispatch({ type: SET_OPERAND, payload: operand });
    };
    const setActivePriority = (priority: { value: string; text: string }[]) => {
        dispatch({ type: SET_PRIORITY, payload: priority });
    };
    const resetAllFilters = () => {
        dispatch({ type: RESET_ALL_FILTERS });
    };
    const resetFilter = (value: string) => {
        dispatch({ type: RESET_FILTER, payload: value });
    };
    const resetOperand = (value: string) => {
        dispatch({ type: RESET_OPERAND, payload: value });
    };
    const resetPriority = (value: string) => {
        dispatch({ type: RESET_PRIORITY, payload: value });
    };
    const setActiveStatus = (value: string) => {
        dispatch({ type: SET_ACTIVE_STATUS, payload: value });
    };
    const setSearchValue = (value: string) => {
        dispatch({ type: SET_SEARCH_VALUE, payload: value });
    };

    const setColumns = (value: any[]) => {
        dispatch({ type: SET_COLUMNS, payload: value });
    };

    return (
        <InputOutputContext.Provider
            value={{
                columns: state.columns,
                activeColumns: state.activeColumns,
                activeFilters: state.activeFilters,
                activeOperand: state.activeOperand,
                activePriority: state.activePriority,
                activeStatus: state.activeStatus,
                searchValue: state.searchValue,
                filterColumnsByName,
                resetColumnsToDefault,
                setActiveFilters,
                setActiveFilter,
                setActiveOperand,
                setActivePriority,
                setActiveStatus,
                resetAllFilters,
                resetFilter,
                resetOperand,
                resetPriority,
                setSearchValue,
                resetColumn,
                setColumns,
            }}
        >
            {children}
        </InputOutputContext.Provider>
    );
};

export default InputOutputState;
