/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import { FEParagraph } from "../../../../generatedTypes";
import { renderFeElement } from "./renderElement";

interface FeParagraphProps {
    paragraph: FEParagraph;
}

const FeParagraph = ({ paragraph }: FeParagraphProps) => {
    return (
        <div
            css={css({
                display: "flex",
                gap: 8,
                flexDirection: "column",
            })}
        >
            {paragraph.children.map((element, index) =>
                renderFeElement(element, index)
            )}
        </div>
    );
};

export default FeParagraph;
