/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import {
    Table as MuiTable,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
} from "@mui/material";
import React, {
    Fragment,
    useContext,
    useEffect,
    useMemo,
    useState,
} from "react";
import ThemeContext from "../../../context/theme/themeContext";
import {
    Row,
    TableOptions,
    UseTableRowProps,
    useFlexLayout,
    useGlobalFilter,
    useRowSelect,
    useSortBy,
    useTable,
} from "react-table";
import { OverwriteTableFilterProps } from "../../../utils/types";
import LanguageContext from "../../../context/language/languageContext";
import { ReactComponent as EmptyStateIcon } from "../../../assets/BluetoothEmptyStateIcon.svg";

import {
    BluetoothSensorStatuses,
    BluetoothTableColumns,
    FotaKeyTableColumns,
    TableTypes,
} from "../../../constants/constants";
import { formatDate } from "../../../utils/helpers";
import TableHeadToolbar from "./components/TableHeadToolbar";
import Checkbox from "../Checkbox";
import StatusCell from "./components/cells/userKeys/StatusCell";
import NameCell from "./components/cells/userKeys/NameCell";
import TypeLabelCell from "./components/cells/userKeys/TypeLabelCell";
import Tooltip from "../Tooltip";
import { ReactComponent as SignalStrongIcon } from "../../../assets/bluetooth/SignalStrong.svg";
import { ReactComponent as SignalNormalIcon } from "../../../assets/bluetooth/SignalNormal.svg";
import { ReactComponent as SignalWeakIcon } from "../../../assets/bluetooth/SignalWeak.svg";
import { ReactComponent as SortArrowUpIcon } from "../../../assets/bluetooth/SortArrowUp.svg";
import { ReactComponent as SortArrowDownIcon } from "../../../assets/bluetooth/SortArrowDown.svg";
import InfoIcon from "../../../assets/icons/InfoIcon";
export interface TableProps {
    data: any;
    searchValue?: string;
    tableType: TableTypes;
    onDeleteClick: (selectedRowIds: any) => void;
    hasNoSelectRows?: boolean;
    emptyState?: React.ReactNode;
    hasStickyCell?: boolean;
}

const Table: React.FunctionComponent<TableProps> = ({
    data,
    searchValue = "",
    tableType,
    onDeleteClick,
    hasNoSelectRows,
    emptyState,
    hasStickyCell = true,
}) => {
    const {
        colors: {
            white,
            blue100,
            textDark,
            blue700,
            gray200,
            green600,
            red600,
        },
        boxShadows: { grayBoxShadow },
    } = useContext(ThemeContext);

    const { t } = useContext(LanguageContext);

    const [globalFilterValue, setGlobalFilterValue] = useState(searchValue);

    const columnsByTableType = {
        [TableTypes.UserKeys]: [
            {
                id: FotaKeyTableColumns.name,
                accessor: FotaKeyTableColumns.name,
                Header: t.FotaKeyTableColumnsTitlesName,
                minWidth: 200,
                Cell: NameCell,
            },
            {
                id: FotaKeyTableColumns.status,
                accessor: FotaKeyTableColumns.status,
                Header: t.FotaKeyTableColumnsTitlesStatus,
                minWidth: 100,
                Cell: StatusCell,
            },
            {
                id: FotaKeyTableColumns.typeLabel,
                accessor: FotaKeyTableColumns.typeLabel,
                Header: t.FotaKeyTableColumnsTitlesType,
                minWidth: 200,
                Cell: TypeLabelCell,
            },
            {
                id: FotaKeyTableColumns.created,
                accessor: FotaKeyTableColumns.created,
                Header: t.FotaKeyTableColumnsTitlesCreated,
                minWidth: 180,
                Cell: ({ row }: any) =>
                    row.original.created
                        ? formatDate(row.original.created)
                        : "-",
            },
            {
                id: FotaKeyTableColumns.expiration,
                accessor: FotaKeyTableColumns.expiration,
                Header: t.Expiration,
                minWidth: 180,
                Cell: ({ row }: any) => row.original.expiresAt,
            },
        ],
        [TableTypes.Bluetooth]: [
            {
                id: BluetoothTableColumns.deviceName,
                accessor: BluetoothTableColumns.deviceName,
                Header: t.DeviceName,
                minWidth: 105,
                Cell: ({ row }: any) =>
                    row.original.deviceName ? row.original.deviceName : "-",
            },
            {
                id: BluetoothTableColumns.status,
                accessor: BluetoothTableColumns.status,
                Header: t.Status,
                Cell: ({ row }: any) => {
                    const status = row.original.isDetected
                        ? BluetoothSensorStatuses.Online
                        : BluetoothSensorStatuses.Offline;
                    const statusLocalized = row.original.isDetected
                        ? t.Online
                        : t.Offline;
                    const renderStatusColor: any = {
                        [BluetoothSensorStatuses.Online]: green600,
                        [BluetoothSensorStatuses.Offline]: red600,
                    };
                    return (
                        <Tooltip
                            title={row?.original?.tooltip || ""}
                            small
                            placement="top"
                        >
                            <div
                                css={css({
                                    display: "flex",
                                    alignItems: "center",
                                    gap: "10px",
                                })}
                            >
                                <span
                                    css={css({
                                        width: "8px",
                                        height: "8px",
                                        borderRadius: "50%",
                                        backgroundColor:
                                            renderStatusColor[status],
                                    })}
                                />
                                {statusLocalized}
                            </div>
                        </Tooltip>
                    );
                },
            },
            {
                id: BluetoothTableColumns.macAddress,
                accessor: BluetoothTableColumns.macAddress,
                Header: t.MacAddress,
                minWidth: 130,
                Cell: ({ row }: any) =>
                    row.original.macAddress ? row.original.macAddress : "-",
            },
            {
                id: BluetoothTableColumns.deviceType,
                accessor: BluetoothTableColumns.deviceType,
                Header: t.DeviceType,
                Cell: ({ row }: any) => {
                    return (
                        <div
                            css={css({
                                display: "flex",
                                alignItems: "center",
                                gap: "10px",
                            })}
                        >
                            {row.original.deviceType}
                        </div>
                    );
                },
                minWidth: 200,
                filter: (
                    allRows: Row<any>[],
                    _: any[],
                    filterValue: string
                ) => {
                    return allRows.filter((row: any) => {
                        return row.original.deviceName === filterValue;
                    });
                },
            },
        ],
        [TableTypes.BluetoothScan]: [
            {
                id: BluetoothTableColumns.deviceName,
                accessor: BluetoothTableColumns.deviceName,
                Header: t.DeviceName,
                minWidth: 105,
                Cell: ({ row }: any) =>
                    row.original.deviceName ? row.original.deviceName : "-",
            },
            {
                id: BluetoothTableColumns.macAddress,
                accessor: BluetoothTableColumns.macAddress,
                Header: t.MacAddress,
                minWidth: 105,
                Cell: ({ row }: any) =>
                    row.original.macAddress ? row.original.macAddress : "-",
            },
            {
                id: BluetoothTableColumns.signalStrength,
                accessor: BluetoothTableColumns.signalStrength,
                Header: ({ column }: any) => (
                    <div
                        css={css({
                            display: "flex",
                            alignItems: "center",
                            gap: "3px",
                        })}
                    >
                        <span
                            {...column.getSortByToggleProps()}
                            css={css({
                                display: "flex",
                                alignItems: "center",
                                cursor: "pointer",
                            })}
                        >
                            RSSI
                            <span
                                css={css({
                                    display: "inline-flex",
                                    flexDirection: "column",
                                    gap: "3px",
                                    marginLeft: "4px",
                                })}
                            >
                                <SortArrowUpIcon
                                    css={css({
                                        fontSize: "14px",
                                        fill:
                                            column.isSorted &&
                                            column.isSortedDesc
                                                ? textDark
                                                : gray200,
                                    })}
                                />
                                <SortArrowDownIcon
                                    css={css({
                                        fontSize: "14px",
                                        fill:
                                            column.isSorted &&
                                            !column.isSortedDesc
                                                ? textDark
                                                : gray200,
                                    })}
                                />
                            </span>
                        </span>
                    </div>
                ),
                minWidth: 105,
                Cell: ({ row }: any) => {
                    const signalStrength = row.original.signalStrength;

                    const renderIcon = () => {
                        if (signalStrength > -60) {
                            return <SignalStrongIcon />;
                        } else if (signalStrength >= -79) {
                            return <SignalNormalIcon />;
                        } else {
                            return <SignalWeakIcon />;
                        }
                    };
                    return (
                        <div
                            css={css({
                                display: "flex",
                                alignItems: "center",
                                gap: "3px",
                            })}
                        >
                            {renderIcon()}
                            {signalStrength ? `${signalStrength} dBm` : "-"}
                        </div>
                    );
                },
            },
        ],
    };

    const columnsWithSelect = useMemo(
        () => [
            {
                id: "selection",
                Header: ({ getToggleAllRowsSelectedProps }: any) => (
                    <Checkbox
                        {...getToggleAllRowsSelectedProps()}
                        sx={{ "& .MuiSvgIcon-root": { fontSize: 20 } }}
                    />
                ),
                Cell: ({ row }: any) => {
                    return (
                        <Checkbox
                            {...row.getToggleRowSelectedProps()}
                            sx={{ "& .MuiSvgIcon-root": { fontSize: 20 } }}
                        />
                    );
                },
                width: 50,
            },
            ...columnsByTableType[tableType],
        ],
        [t]
    );

    const columnsWithoutSelect = useMemo(
        () => columnsByTableType[tableType],
        [t]
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        selectedFlatRows,
        setGlobalFilter,
        state: { selectedRowIds },
        toggleAllRowsSelected,
    } = useTable(
        {
            columns: hasNoSelectRows ? columnsWithoutSelect : columnsWithSelect,
            data: data,
            getRowId: (row) => row.id,
            autoResetSortBy: false,
            globalFilter: (
                allRows: UseTableRowProps<any>[],
                _: any[],
                value: string
            ) =>
                allRows.filter((row) => {
                    const isBluetooth =
                        tableType === TableTypes.Bluetooth ||
                        tableType === TableTypes.BluetoothScan;
                    const parameterName = isBluetooth
                        ? row.original?.deviceName?.toLowerCase()
                        : row.original?.name?.toLowerCase();
                    const filterValue = value?.toLowerCase();
                    return parameterName?.includes(filterValue);
                }),
            initialState: {
                globalFilter: globalFilterValue,
            },
        } as TableOptions<any>,
        useFlexLayout,
        useGlobalFilter,
        useSortBy,
        useRowSelect
    ) as OverwriteTableFilterProps;

    useEffect(() => {
        setGlobalFilterValue(searchValue);
        setGlobalFilter(searchValue);
    }, [searchValue, setGlobalFilter]);

    const selectedRowsCount = Object.keys(selectedRowIds).length;

    const allSelected =
        rows.length > 0 && Object.keys(selectedRowIds).length === rows.length;

    const atLeastSingleSelected = selectedFlatRows.length > 0;

    const handleCheckboxChange = () => {
        if (atLeastSingleSelected) {
            toggleAllRowsSelected(false);
            return;
        }
        toggleAllRowsSelected(true);
    };

    const tableCellStyle = {
        getPosition: (index: number): string =>
            index === 0 && hasStickyCell ? "sticky" : "unset",
        getLeft: (index: number): any => (index === 0 ? 0 : "unset"),
        getZIndex: (index: number): number => (index === 0 ? 1 : 0),
    };

    const renderTableContent = () => {
        if (!rows.length) {
            return (
                <TableRow
                    css={css({
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        height: "100%",
                    })}
                >
                    <TableCell
                        sx={{
                            borderBottom: "none",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            flexDirection: "column",
                        }}
                    >
                        {emptyState || (
                            <Fragment>
                                <EmptyStateIcon />
                                <div
                                    css={css({
                                        marginTop: "15px",
                                        fontWeight: 600,
                                        fontSize: "16px",
                                        lineHeight: "20px",
                                        textAlign: "center",
                                        letterSpacing: "0.15px",
                                        color: textDark,
                                    })}
                                >
                                    {t.KeyListEmpty}
                                </div>
                            </Fragment>
                        )}
                    </TableCell>
                </TableRow>
            );
        }

        return rows.map((row, index) => {
            prepareRow(row);
            return (
                <TableRow
                    sx={{
                        minHeight: "40px",
                        boxShadow: grayBoxShadow,
                        position: "relative",
                        paddingLeft: "8px",
                        backgroundColor: selectedRowIds[row.original.id]
                            ? blue100
                            : "unset", // Add this line
                        borderLeft: selectedRowIds[row.original.id]
                            ? `2px solid ${blue700}`
                            : "2px solid transparent",
                        borderBottom: selectedRowIds[row.original.id]
                            ? `1px solid ${gray200}`
                            : "unset",
                        marginTop: index === 0 ? "42px" : "unset",
                    }}
                    {...row.getRowProps()}
                >
                    {row.cells.map((cell, index, arr) => {
                        return (
                            <Fragment key={index}>
                                <TableCell
                                    sx={{
                                        alignItems: "center",
                                        position:
                                            tableCellStyle.getPosition(index),
                                        left: tableCellStyle.getLeft(index),
                                        zIndex: tableCellStyle.getZIndex(index),
                                        background: "unset",
                                        fontWeight: 600,
                                        lineHeight: "20px",
                                        color: textDark,
                                        textOverflow: "ellipsis",
                                        overflow: "hidden",
                                        whiteSpace: "nowrap",
                                        padding: "10px 16px",
                                        display: "flex",
                                    }}
                                    {...cell.getCellProps()}
                                >
                                    {cell.render("Cell")}
                                </TableCell>
                            </Fragment>
                        );
                    })}
                </TableRow>
            );
        });
    };

    return (
        <div
            css={css({
                maxWidth: "100%",
                overflowX: "auto",
                borderBottom: "1px solid #E2E8F0",
                borderLeft: "1px solid #E2E8F0",
                borderRight: "1px solid #E2E8F0",
                maxHeight: "440px",
            })}
        >
            {atLeastSingleSelected && (
                <TableHeadToolbar
                    rowsCount={selectedRowsCount}
                    allSelected={allSelected}
                    atLeastSingleSelected={atLeastSingleSelected}
                    onCheckboxChange={handleCheckboxChange}
                    onDeselectAll={() => toggleAllRowsSelected(false)}
                    onDeleteClick={() =>
                        onDeleteClick(Object.keys(selectedRowIds))
                    }
                />
            )}
            <MuiTable
                css={css({
                    filter: "drop-shadow(0px 1px 4px rgba(15, 23, 42, 0.12))",
                    backgroundColor: white,
                    height: "400px",
                })}
                {...getTableProps()}
            >
                <TableHead
                    css={css({
                        backgroundColor: blue100,
                        position: "sticky",
                        top: "0",
                        left: "0",
                        width: "100%",
                        boxSizing: "border-box",
                        marginTop: "-42px",
                        zIndex: "4",
                        display: "flex",
                        background: blue100,
                        whiteSpace: "nowrap",
                    })}
                >
                    {headerGroups.map((headerGroup, index) => (
                        <TableRow
                            {...headerGroup.getHeaderGroupProps()}
                            css={css({
                                paddingLeft: "8px",
                                marginLeft: "2px",
                                paddingTop: "1px",
                                paddingBottom: "1px",
                                boxShadow: atLeastSingleSelected
                                    ? "unset"
                                    : "0px -1px 0px 0px #C5E2FF inset, 0px 1px 0px 0px #C5E2FF inset",
                            })}
                        >
                            {headerGroup.headers.map((column, index, arr) => {
                                return (
                                    <Fragment key={index}>
                                        <TableCell
                                            css={css({
                                                color: textDark,
                                                fontWeight: 600,
                                                fontSize: "14px",
                                                borderBottom: "unset",
                                                position:
                                                    index === 0 && hasStickyCell
                                                        ? "sticky"
                                                        : "unset",
                                                left: index === 0 ? 0 : "unset",
                                                backgroundColor: blue100,
                                                zIndex: index === 0 ? 1 : 0,
                                                display: "flex",
                                                padding: "8px 16px",
                                            })}
                                            {...column.getHeaderProps()}
                                        >
                                            {column.render("Header")}
                                        </TableCell>
                                    </Fragment>
                                );
                            })}
                        </TableRow>
                    ))}
                </TableHead>
                <TableBody
                    {...getTableBodyProps()}
                    css={css({ marginTop: "42px" })}
                >
                    {renderTableContent()}
                </TableBody>
            </MuiTable>
        </div>
    );
};

export default Table;
