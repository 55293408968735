/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import { useContext, useRef, useState } from "react";
import MenuItemContext from "../../../../../../../context/menuItem/menuItemContext";
import { bitsToNumber, numberToBits } from "../../../../../../../utils/helpers";
import useDebounce from "../../../../../../../utils/useDebounce";
import Checkbox from "../../../../../../MuiComponents/Checkbox";
import { TableSwitchDefaultValue } from "./TableSwitchInput";
import { Component } from "../../../../../../../generatedTypes";
import ThemeContext from "../../../../../../../context/theme/themeContext";

export interface DataMaskProps {
    data: Component;
    switchId?: string | number;
}

const DataMask: React.FunctionComponent<DataMaskProps> = ({
    data,
    switchId,
}) => {
    const { parameterId, parameterValue, label } = data;
    const eightBits = numberToBits(Number(parameterValue));
    const [bits, setBits] = useState(eightBits);
    const { updateParameter, findParameterValueById } =
        useContext(MenuItemContext);
    const {
        colors: { textDarkDisabled, textDark },
    } = useContext(ThemeContext);

    const isInputDisabled =
        findParameterValueById(switchId) === TableSwitchDefaultValue.OFF;

    const elementRef = useRef(null);

    const debouncedChangeHandler = useDebounce(updateParameter, 500);

    const handleClick = (index: number, checked: boolean) => {
        const newBits = [...bits];
        if (checked) {
            newBits[index] = 1;
        }
        if (!checked) {
            newBits[index] = 0;
        }
        setBits(newBits);

        const convertedNumber = bitsToNumber(newBits);
        debouncedChangeHandler(
            parameterId,
            String(convertedNumber),
            label,
            elementRef
        );
    };

    const renderBitInput = (index: number, bit: number, label: number) => {
        return (
            <div
                css={css({
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    pointerEvents: isInputDisabled ? "none" : "unset",
                })}
                key={index}
            >
                <div
                    css={css({
                        fontWeight: 600,
                        fontSize: "12px",
                        lineHeight: "16px",
                        textAlign: "center",
                        letterSpacing: "0.4px",
                        color: isInputDisabled ? textDarkDisabled : textDark,
                    })}
                >
                    {label}
                </div>
                <Checkbox
                    sx={{
                        width: "20px",
                        height: "20px",
                    }}
                    onChange={(e) => handleClick(index, e.target.checked)}
                    checked={!!bit}
                />
            </div>
        );
    };
    return (
        <div
            css={css({
                display: "flex",
                gap: "18px",
                width: "340px",
                justifyContent: "center",
            })}
            ref={elementRef}
            data-test={String(parameterId)}
        >
            <div
                css={css({
                    height: "100%",
                    top: "16px",
                    position: "relative",
                    fontWeight: 600,
                    fontSize: "14px",
                    lineHeight: "20px",
                    letterSpacing: "0.1px",
                    color: isInputDisabled ? textDarkDisabled : textDark,
                })}
            >
                MSB
            </div>
            <div
                css={css({
                    display: "flex",
                    gap: "8px",
                })}
            >
                {bits.map((item, index) =>
                    renderBitInput(index, item, bits.length - index)
                )}
            </div>
            <div
                css={css({
                    height: "100%",
                    top: "16px",
                    position: "relative",
                    fontWeight: 600,
                    fontSize: "14px",
                    lineHeight: "20px",
                    letterSpacing: "0.1px",
                    color: isInputDisabled ? textDarkDisabled : textDark,
                })}
            >
                LSB
            </div>
        </div>
    );
};

export default DataMask;
