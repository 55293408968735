/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, SerializedStyles } from "@emotion/react";

import React, { forwardRef } from "react";
import SwitchComponentWithLabel from "./SwitchComponentWithLabel";
import SwitchComponent from "./SwitchComponent";

type Ref = HTMLInputElement;
type SwitchSizes = "small" | "medium" | "large";
export type SwitchLabelPlacements = "end" | "start" | "bottom" | "top"; // end - default

interface SwitchProps {
    size: SwitchSizes;
    label?: string | React.ReactNode;
    labelPlacement?: SwitchLabelPlacements;
    requesting?: boolean;
    requestFailed?: boolean;
    wrapperStyle?: SerializedStyles;
    checked: boolean;
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    disabled?: boolean;
    idForTesting: string | number;
}

const Switch = forwardRef<Ref, SwitchProps>(
    (
        {
            size,
            label,
            labelPlacement,
            requesting,
            requestFailed,
            wrapperStyle,
            checked,
            onChange,
            idForTesting,
            ...props
        },
        ref
    ) => {
        return label ? (
            <SwitchComponentWithLabel
                ref={ref}
                size={size}
                label={label}
                labelPlacement={labelPlacement}
                requesting={requesting}
                requestFailed={requestFailed}
                wrapperStyle={wrapperStyle}
                checked={checked}
                onChange={onChange}
                idForTesting={String(idForTesting)}
                {...props}
            />
        ) : (
            <SwitchComponent
                ref={ref}
                size={size}
                wrapperStyle={wrapperStyle}
                checked={checked}
                onChange={onChange}
                idForTesting={String(idForTesting)}
                {...props}
            />
        );
    }
);

export default Switch;
