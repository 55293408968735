/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import { useContext } from "react";
import TypedGrid from "../../../../../MuiComponents/TypedGrid";
import ThemeContext from "../../../../../../context/theme/themeContext";
import { Column } from "../../../../../../generatedTypes";
const UnitsColumn = ({ column }: { column: Column }) => {
    const {
        colors: { textColorTitle },
    } = useContext(ThemeContext);
    return (
        <TypedGrid item xl={2} md={6} sm={6} xs0={4}>
            <div
                css={css`
                    min-height: 44px;
                `}
            >
                {column.name}
            </div>
            {column.components.map((component) => {
                return (
                    <div
                        key={component.index}
                        css={css`
                            color: ${textColorTitle};
                            font-weight: 600;
                            margin: 4px;
                            line-height: 20px;
                            min-height: 40px;
                        `}
                    >
                        {component.name}
                    </div>
                );
            })}
        </TypedGrid>
    );
};

export default UnitsColumn;
