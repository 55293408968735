export const SET_EDITABLE_INPUT = "SET_EDITABLE_INPUT";
export const SET_PARAMETER_VALUES = "SET_PARAMETER_VALUES";
export const SET_PARAMETER_VALUE = "SET_PARAMETER_VALUE";
export const SUBMIT_PARAMETER_VALUES = "SUBMIT_PARAMETER_VALUES";
export const SUBMIT_PARAMETER_VALUE = "SUBMIT_PARAMETER_VALUE";
export const REMOVE_PARAMETER_VALUE_BY_ID = "REMOVE_PARAMETER_VALUE";
export const RESET_EDITABLE_INPUTS = "RESET_EDITABLE_INPUTS";
export const REMOVE_EDITABLE_INPUT_BY_ID = "REMOVE_EDITABLE_INPUT_BY_ID";
export const SET_ECOCALCULATOR_VALUES = "SET_ECOCALCULATOR_VALUES";
export const RESET_DEFAULT_VALUES = "RESET_DEFAULT_VALUES";
