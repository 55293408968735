import { InitialState } from "./listReducer";

export const importInputValues = (state: InitialState, action: any) => {
    const { inputsPayload, errorsPayload } = action.payload;

    return {
        ...state,
        isAllowedToScroll: false,
        inputs: inputsPayload,
        errors: errorsPayload,
    };
};
