/** @jsxRuntime classic */
/** @jsx jsx */
import { css, SerializedStyles } from "@emotion/react";

import { useState, useRef } from "react";

interface Params {
    accept?: string;
}

interface InputProps {
    ref: React.RefObject<HTMLInputElement>;
    type: string;
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    css: SerializedStyles;
    accept?: string;
}

export type SelectedFile = File | null;

const useUploadFile = (params: Params = {}) => {
    const [selectedFile, setSelectedFile] = useState<SelectedFile>(null);

    const inputFile = useRef<HTMLInputElement>(null);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files ? e.target.files[0] : null;

        setSelectedFile(file);

        e.target.value = "";
    };

    const inputProps: InputProps = {
        ref: inputFile,
        type: "file",
        onChange: handleChange,
        css: css({ display: "none" }),
        ...(params.accept && { accept: params.accept }),
    };

    const openFileDialog = () => inputFile.current?.click();

    return { inputProps, selectedFile, openFileDialog };
};

export default useUploadFile;
