import { useState } from "react";
import unknown from "../assets/models/unknown.png";
import unknown2 from "../assets/models/unknown@2x.png";

const useImagePath = () => {
    const [modelPaths, setModelPaths] = useState({
        path1: unknown,
        path2: unknown2,
    });

    const [flagPath, setFlagPath] = useState(undefined);

    const getModelSrc = (
        model: string,
        isSubscribed: boolean,
        isCanceled: (value: any) => boolean
    ) => {
        try {
            const src1 = require(`../assets/models/${model}.png`);
            const src2 = require(`../assets/models/${model}@2x.png`);

            if (isSubscribed) {
                setModelPaths({
                    path1: src1,
                    path2: src2,
                });
            }
        } catch (error) {
            if (isSubscribed && !isCanceled(error)) {
                setModelPaths({
                    path1: unknown,
                    path2: unknown2,
                });
            }
        }
    };

    const getFlagSrc = (
        language: string,
        isSubscribed: boolean,
        isCanceled: (value: any) => boolean
    ) => {
        try {
            const src = require(`../assets/flags/${language}.png`);

            if (isSubscribed) {
                setFlagPath(src);
            }
        } catch (error) {
            if (isSubscribed && !isCanceled(error)) {
                isSubscribed && setFlagPath(undefined);
            }
        }
    };

    return {
        modelPaths,
        flagPath,
        getModelSrc,
        getFlagSrc,
    };
};

export default useImagePath;
