import { useEffect } from "react";

const useScript = (url: string, onLoad?: any, autostart = true) => {
    useEffect(() => {
        const loader = (window as any).tctLoader;

        if (loader) {
            const additionalAttributes = [];
            if (!autostart) {
                additionalAttributes.push({ key: "autostart", value: "false" });
            }
            
            (window as any)?.downloadWithProgress({ sourceUrl: url, additionalAttributes }, loader, "script", onLoad);
            loader?.addFileDownloadStartedCount();
        } else {
            const script = document.createElement("script");

            script.src = url;
            !autostart && script.setAttribute("autostart", "false");

            if (onLoad) {
                script.onload = onLoad;
            }
            document.body.appendChild(script);

            return () => {
                document.body.removeChild(script);
            };
        }
    }, [url]);
};

export default useScript;
