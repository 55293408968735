import { SpreadedSearchResults } from "./searchReducer";
import { SearchResult } from "../../generatedTypes";

export const spreadOutResults = (data: SearchResult[] | null) => {
    if (data) {
        const spreadedResults: SpreadedSearchResults = {
            parameters: [],
            sections: [],
        };

        data.forEach((singleResult) => {
            const isComponent: boolean =
                !!(singleResult.searchPath?.componentIndex);

            isComponent
                ? spreadedResults.parameters.push(singleResult)
                : spreadedResults.sections.push(singleResult);
        });

        return spreadedResults;
    } else {
        return null;
    }
};
