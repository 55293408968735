import { StepConnector } from "@mui/material";
import React, { useContext } from "react";
import ThemeContext from "../../../context/theme/themeContext";

export interface CustomConnectorProps {
    activeStep: number;
    stepErrors: {
        step: number;
        error: string;
    };
}

const CustomConnector: React.FunctionComponent<CustomConnectorProps> = ({
    activeStep,
    stepErrors,
}) => {
    const {
        colors: { textDarkDisabled, blue700 },
    } = useContext(ThemeContext);
    const isFirstStepFinished = activeStep > 1 && stepErrors.step < 1;
    return (
        <StepConnector
            sx={{
                "& .MuiStepConnector-line": {
                    border: `1px solid ${
                        isFirstStepFinished ? blue700 : textDarkDisabled
                    }`,
                    backgroundColor: isFirstStepFinished
                        ? blue700
                        : textDarkDisabled,
                    position: "relative",
                    top: "4px",
                    left: "-5px",
                    width: "105%",
                },
            }}
        />
    );
};

export default CustomConnector;
