/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from "@emotion/react";

import { Fragment, useContext } from "react";
import ThemeContext from "../../../../../context/theme/themeContext";
import LanguageContext from "../../../../../context/language/languageContext";
import Reset from "./Reset";
import Card from "../../../../MuiComponents/Card";
import Badge, { BadgeColors } from "../../../../MuiComponents/Badge";
import {
    CellularInformationData,
    DeviceStatusWindow,
    SocketInfo,
} from "../../../../../generatedTypes";
import { SerializedStyles } from "@emotion/react/dist/emotion-react.cjs";
import TypedGrid from "../../../../MuiComponents/TypedGrid";
import Divider from "../../../../MuiComponents/Divider";
import Button from "../../../../MuiComponents/Button";
import UpdateFirmwareModal from "../../../../MuiComponents/Modals/UpdateFirmwareModal/UpdateFirmwareModal";
import {
    AlertStatus,
    FirmwareType,
    Loaders,
} from "../../../../../constants/constants";
import WebSocketsContext from "../../../../../context/webSockets/webSocketsContext";

import useApi from "../../../../../utils/useApi";
import AlertContext from "../../../../../context/alert/alertContext";
import useDesktopHost from "../../../../../utils/useDesktopHost";
import DeviceStatusContext from "../../../../../context/deviceStatus/deviceStatusContext";

interface CellularInformationProps {
    data: CellularInformationData;
    keyBaseStyles: SerializedStyles;
    valueBaseStyles: SerializedStyles;
    visibilitySettings: DeviceStatusWindow;
}

const CellularInformation = ({
    data,
    keyBaseStyles,
    valueBaseStyles,
    visibilitySettings,
}: CellularInformationProps) => {
    const {
        modemStatus,
        networkStatus,
        currentOperatorCode,
        signalLevel,
        sentData,
        sentDataRaw,
        receivedData,
        receivedDataRaw,
        totalTraffic,
        sockets,
        sentRecordsCount,
        lastRecordSend,
        lastServerResponseTime,
        sentSms,
        receivedSms,
        smsCount,
        modemVersion,
    } = data;

    const {
        colors: { gray700 },
    } = useContext(ThemeContext);

    const { setAlert } = useContext(AlertContext);

    const { t, selectedLanguage } = useContext(LanguageContext);
    const { updateModemAsync } = useContext(WebSocketsContext);
    const { setUpdateFirmwareModalInfo, updateFirmwareModalInfo } =
        useContext(DeviceStatusContext);

    const { getData } = useApi();

    const { openFileDialog } = useDesktopHost();

    const handleModemUpdate = async () => {
        openFileDialog(
            {
                title: t.SelectFile,
                filters: [
                    {
                        extensions: ["zip", "bin", "pack"],
                        name: t.ModemFirmwareFile,
                    },
                ],
            },
            async (result: any) => {
                if (result.length > 0) {
                    try {
                        const { data } = await getData(
                            `${selectedLanguage}/updatefile/modem?filePath=${result[0]}`
                        );
                        setUpdateFirmwareModalInfo({
                            isOpen: true,
                            path: result[0],
                            step: 0,
                            newVersion: data,
                            firmwareType: FirmwareType.Modem,
                            currentVersion: modemVersion ?? null,
                            error: "",
                        });
                    } catch (err: any) {
                        const error = err.response?.data;
                        setAlert(AlertStatus.Critical, error.detail);
                        setUpdateFirmwareModalInfo({
                            isOpen: false,
                            path: "",
                            step: 0,
                            newVersion: "",
                            firmwareType: FirmwareType.None,
                            currentVersion: "",
                            error: "",
                        });
                    }
                }
            }
        );
    };

    return (
        <Fragment>
            <Card
                title={t.CellularInfo}
                description={t["CellularInfo.description"]}
            >
                <div
                    css={css`
                        ${valueBaseStyles};

                        margin: 8px 0;
                        font-weight: 600;
                        color: ${gray700};
                    `}
                >
                    {t.CellularStatus}
                </div>

                <Divider />

                {modemVersion && (
                    <Fragment>
                        <div
                            css={css`
                                margin: 6px 0;
                            `}
                        >
                            <TypedGrid
                                container
                                columnSpacing={2}
                                rowSpacing={0.5}
                                alignItems="center"
                            >
                                <TypedGrid item xs0={6}>
                                    <div
                                        css={css`
                                            ${keyBaseStyles};
                                        `}
                                    >
                                        {t.ModemVersion}
                                    </div>
                                </TypedGrid>

                                <TypedGrid item xs0={6}>
                                    <div
                                        css={css`
                                            ${valueBaseStyles};
                                            margin-bottom: 8px;
                                        `}
                                    >
                                        {" "}
                                        {modemVersion}
                                    </div>
                                    {visibilitySettings?.canUpdateModemFw && (
                                        <Button
                                            fullWidth
                                            variant="textOnly"
                                            size="small"
                                            color="primary"
                                            onClick={handleModemUpdate}
                                            idForTesting="updateModem"
                                        >
                                            {t.Update}
                                        </Button>
                                    )}
                                </TypedGrid>
                            </TypedGrid>
                        </div>

                        <Divider />
                    </Fragment>
                )}
                {modemStatus && (
                    <Fragment>
                        <div
                            css={css`
                                margin: 6px 0;
                            `}
                        >
                            <TypedGrid
                                container
                                columnSpacing={2}
                                rowSpacing={0.5}
                                alignItems="center"
                            >
                                <TypedGrid item xs0={6}>
                                    <div
                                        css={css`
                                            ${keyBaseStyles};
                                        `}
                                    >
                                        {t.ModemStatus}
                                    </div>
                                </TypedGrid>

                                <TypedGrid item xs0={6}>
                                    <Badge
                                        color={
                                            modemStatus.color as unknown as BadgeColors
                                        }
                                    >
                                        {modemStatus.label}
                                    </Badge>
                                </TypedGrid>
                            </TypedGrid>
                        </div>

                        <Divider />
                    </Fragment>
                )}

                {networkStatus && (
                    <Fragment>
                        <div
                            css={css`
                                margin: 6px 0;
                            `}
                        >
                            <TypedGrid
                                container
                                columnSpacing={2}
                                rowSpacing={0.5}
                                alignItems="center"
                            >
                                <TypedGrid item xs0={6}>
                                    <div
                                        css={css`
                                            ${keyBaseStyles};
                                        `}
                                    >
                                        {t.NetworkStatus}
                                    </div>
                                </TypedGrid>

                                <TypedGrid item xs0={6}>
                                    <Badge
                                        color={
                                            networkStatus.color as unknown as BadgeColors
                                        }
                                    >
                                        {networkStatus.label}
                                    </Badge>
                                </TypedGrid>
                            </TypedGrid>
                        </div>

                        <Divider />
                    </Fragment>
                )}

                {Boolean(currentOperatorCode) && (
                    <Fragment>
                        <div
                            css={css`
                                margin: 8px 0;
                            `}
                        >
                            <TypedGrid
                                container
                                columnSpacing={2}
                                rowSpacing={0.5}
                                alignItems="center"
                            >
                                <TypedGrid item xs0={6}>
                                    <div
                                        css={css`
                                            ${keyBaseStyles};
                                        `}
                                    >
                                        {t.CurrentOperatorCode}
                                    </div>
                                </TypedGrid>

                                <TypedGrid item xs0={6}>
                                    <div
                                        css={css`
                                            ${valueBaseStyles};
                                        `}
                                    >
                                        {currentOperatorCode}
                                    </div>
                                </TypedGrid>
                            </TypedGrid>
                        </div>

                        <Divider />
                    </Fragment>
                )}

                {signalLevel && (
                    <Fragment>
                        <div
                            css={css`
                                margin: 6px 0;
                            `}
                        >
                            <TypedGrid
                                container
                                columnSpacing={2}
                                rowSpacing={0.5}
                                alignItems="center"
                            >
                                <TypedGrid item xs0={6}>
                                    <div
                                        css={css`
                                            ${keyBaseStyles};
                                        `}
                                    >
                                        {t.SignalLevel}
                                    </div>
                                </TypedGrid>

                                <TypedGrid item xs0={6}>
                                    <Badge
                                        color={
                                            signalLevel.color as unknown as BadgeColors
                                        }
                                    >
                                        {signalLevel.label}
                                    </Badge>
                                </TypedGrid>
                            </TypedGrid>
                        </div>

                        <Divider />
                    </Fragment>
                )}

                {visibilitySettings.isNetworkStatusSupported &&
                    Boolean(sentDataRaw || receivedDataRaw || totalTraffic) && (
                        <Fragment>
                            <div
                                css={css`
                                    ${valueBaseStyles};

                                    margin: 32px 0 8px 0;
                                    font-weight: 600;
                                    color: ${gray700};
                                `}
                            >
                                {t.NetworkTraffic}
                            </div>

                            <Divider />

                            <div
                                css={css`
                                    margin: 8px 0;
                                `}
                            >
                                <TypedGrid
                                    container
                                    columnSpacing={2}
                                    rowSpacing={1}
                                    alignItems="center"
                                >
                                    <TypedGrid item xs0={6}>
                                        <div
                                            css={css`
                                                ${keyBaseStyles};
                                            `}
                                        >
                                            {t.SentData}
                                        </div>
                                    </TypedGrid>

                                    <TypedGrid item xs0={6}>
                                        <div>
                                            <div
                                                css={css`
                                                    ${valueBaseStyles};
                                                    margin-bottom: 8px;
                                                `}
                                            >
                                                {sentData}
                                            </div>

                                            {visibilitySettings.isDataCountResetSupported && (
                                                <Reset
                                                    resource="reset-data"
                                                    type={1}
                                                    noData={sentDataRaw === 0}
                                                    noDataText={
                                                        t.NoDataHasBeenSent
                                                    }
                                                />
                                            )}
                                        </div>
                                    </TypedGrid>
                                </TypedGrid>
                            </div>

                            <Divider />

                            <div
                                css={css`
                                    margin: 8px 0;
                                `}
                            >
                                <TypedGrid
                                    container
                                    columnSpacing={2}
                                    rowSpacing={1}
                                    alignItems="center"
                                >
                                    <TypedGrid item xs0={6}>
                                        <div
                                            css={css`
                                                ${keyBaseStyles};
                                            `}
                                        >
                                            {t.ReceivedData}
                                        </div>
                                    </TypedGrid>

                                    <TypedGrid item xs0={6}>
                                        <div>
                                            <div
                                                css={css`
                                                    ${valueBaseStyles};
                                                    margin-bottom: 8px;
                                                `}
                                            >
                                                {receivedData}
                                            </div>

                                            {visibilitySettings.isDataCountResetSupported && (
                                                <Reset
                                                    resource="reset-data"
                                                    type={0}
                                                    noData={
                                                        receivedDataRaw === 0
                                                    }
                                                    noDataText={
                                                        t.NoDataHasBeenReceived
                                                    }
                                                />
                                            )}
                                        </div>
                                    </TypedGrid>
                                </TypedGrid>
                            </div>

                            <Divider />

                            <div
                                css={css`
                                    margin: 8px 0;
                                `}
                            >
                                <TypedGrid
                                    container
                                    columnSpacing={2}
                                    rowSpacing={0.5}
                                    alignItems="center"
                                >
                                    <TypedGrid item xs0={6}>
                                        <div
                                            css={css`
                                                ${keyBaseStyles};
                                            `}
                                        >
                                            {t.TotalTraffic}
                                        </div>
                                    </TypedGrid>

                                    <TypedGrid item xs0={6}>
                                        <div
                                            css={css`
                                                ${valueBaseStyles};
                                            `}
                                        >
                                            {totalTraffic}
                                        </div>
                                    </TypedGrid>
                                </TypedGrid>
                            </div>

                            <Divider />
                        </Fragment>
                    )}

                {sockets &&
                    sockets.length > 0 &&
                    visibilitySettings.isSocketStatusSupported &&
                    sockets.map((socket: SocketInfo, i: number) => (
                        <Fragment key={i}>
                            <div
                                css={css`
                                    ${valueBaseStyles};

                                    margin: 32px 0 8px 0;
                                    font-weight: 600;
                                    color: ${gray700};
                                `}
                            >
                                {`Socket ${sockets.length > 1 ? i + 1 : ""}`}
                            </div>

                            <Divider />

                            <div
                                css={css`
                                    margin: 8px 0;
                                `}
                            >
                                <TypedGrid
                                    container
                                    columnSpacing={2}
                                    rowSpacing={0.5}
                                    alignItems="center"
                                >
                                    <TypedGrid item xs0={12} sm={6}>
                                        <div
                                            css={css`
                                                ${keyBaseStyles};
                                            `}
                                        >
                                            {t.Type}
                                        </div>
                                    </TypedGrid>

                                    <TypedGrid item xs0={12} sm={6}>
                                        <div
                                            css={css`
                                                ${valueBaseStyles};
                                            `}
                                        >
                                            {socket.type}
                                        </div>
                                    </TypedGrid>
                                </TypedGrid>
                            </div>

                            <Divider />

                            <div
                                css={css`
                                    margin: 8px 0;
                                `}
                            >
                                <TypedGrid
                                    container
                                    columnSpacing={2}
                                    rowSpacing={0.5}
                                    alignItems="center"
                                >
                                    <TypedGrid item xs0={12} sm={6}>
                                        <div
                                            css={css`
                                                ${keyBaseStyles};
                                            `}
                                        >
                                            {t.Socket}
                                        </div>
                                    </TypedGrid>

                                    <TypedGrid item xs0={12} sm={6}>
                                        <div
                                            css={css`
                                                ${valueBaseStyles};
                                            `}
                                        >
                                            {socket.socket}
                                        </div>
                                    </TypedGrid>
                                </TypedGrid>
                            </div>

                            <Divider />
                        </Fragment>
                    ))}

                {visibilitySettings.isRecordsStatusSupported &&
                    Boolean(
                        sentRecordsCount ||
                            lastRecordSend ||
                            lastServerResponseTime
                    ) && (
                        <Fragment>
                            <div
                                css={css`
                                    ${valueBaseStyles};

                                    margin: 32px 0 8px 0;
                                    font-weight: 600;
                                    color: ${gray700};
                                `}
                            >
                                {t.Records}
                            </div>

                            <Divider />

                            <div
                                css={css`
                                    margin: 8px 0;
                                `}
                            >
                                <TypedGrid
                                    container
                                    columnSpacing={2}
                                    rowSpacing={0.5}
                                    alignItems="center"
                                >
                                    <TypedGrid item xs0={6}>
                                        <div
                                            css={css`
                                                ${keyBaseStyles};
                                            `}
                                        >
                                            {t.SentRecordsCount}
                                        </div>
                                    </TypedGrid>

                                    <TypedGrid item xs0={6}>
                                        <div
                                            css={css`
                                                ${valueBaseStyles};
                                            `}
                                        >
                                            {sentRecordsCount}
                                        </div>
                                    </TypedGrid>
                                </TypedGrid>
                            </div>

                            <Divider />

                            <div
                                css={css`
                                    margin: 8px 0;
                                `}
                            >
                                <TypedGrid
                                    container
                                    columnSpacing={2}
                                    rowSpacing={0.5}
                                    alignItems="center"
                                >
                                    <TypedGrid item xs0={12} sm={6}>
                                        <div
                                            css={css`
                                                ${keyBaseStyles};
                                            `}
                                        >
                                            {t.LastRecordSend}
                                        </div>
                                    </TypedGrid>

                                    <TypedGrid item xs0={12} sm={6}>
                                        <div
                                            css={css`
                                                ${valueBaseStyles};
                                            `}
                                        >
                                            {lastRecordSend?.badge ? (
                                                <Badge
                                                    color={
                                                        lastRecordSend.badge
                                                            .color as unknown as BadgeColors
                                                    }
                                                >
                                                    {lastRecordSend.badge.label}
                                                </Badge>
                                            ) : (
                                                lastRecordSend?.date
                                            )}
                                        </div>
                                    </TypedGrid>
                                </TypedGrid>
                            </div>

                            <Divider />

                            <div
                                css={css`
                                    margin: 8px 0;
                                `}
                            >
                                <TypedGrid
                                    container
                                    columnSpacing={2}
                                    rowSpacing={0.5}
                                    alignItems="center"
                                >
                                    <TypedGrid item xs0={12} sm={6}>
                                        <div
                                            css={css`
                                                ${keyBaseStyles};
                                            `}
                                        >
                                            {t.LastServerResponseTime}
                                        </div>
                                    </TypedGrid>

                                    <TypedGrid item xs0={12} sm={6}>
                                        <div
                                            css={css`
                                                ${valueBaseStyles};
                                            `}
                                        >
                                            {lastServerResponseTime?.badge ? (
                                                <Badge
                                                    color={
                                                        lastServerResponseTime
                                                            .badge
                                                            .color as unknown as BadgeColors
                                                    }
                                                >
                                                    {
                                                        lastServerResponseTime
                                                            .badge.label
                                                    }
                                                </Badge>
                                            ) : (
                                                lastServerResponseTime?.date
                                            )}
                                        </div>
                                    </TypedGrid>
                                </TypedGrid>
                            </div>

                            <Divider />
                        </Fragment>
                    )}

                {visibilitySettings.isSmsStatusSupported && (
                    <Fragment>
                        <div
                            css={css`
                                ${valueBaseStyles};

                                margin: 32px 0 8px 0;
                                font-weight: 600;
                                color: ${gray700};
                            `}
                        >
                            {t.SMS}
                        </div>

                        <Divider />

                        <div
                            css={css`
                                margin: 8px 0;
                            `}
                        >
                            <TypedGrid
                                container
                                columnSpacing={2}
                                rowSpacing={1}
                                alignItems="center"
                            >
                                <TypedGrid item xs0={6}>
                                    <div
                                        css={css`
                                            ${keyBaseStyles};
                                        `}
                                    >
                                        {t.SentSms}
                                    </div>
                                </TypedGrid>

                                <TypedGrid item xs0={6}>
                                    <div>
                                        <div
                                            css={css`
                                                ${valueBaseStyles};
                                                margin-bottom: 8px;
                                            `}
                                        >
                                            {sentSms}
                                        </div>

                                        {visibilitySettings.isSmsCountResetSupported && (
                                            <Reset
                                                resource="reset-sms"
                                                type={1}
                                                noData={sentSms === 0}
                                                noDataText={t.NoSmsHasBeenSent}
                                            />
                                        )}
                                    </div>
                                </TypedGrid>
                            </TypedGrid>
                        </div>

                        <Divider />

                        <div
                            css={css`
                                margin: 8px 0;
                            `}
                        >
                            <TypedGrid
                                container
                                columnSpacing={2}
                                rowSpacing={1}
                                alignItems="center"
                            >
                                <TypedGrid item xs0={6}>
                                    <div
                                        css={css`
                                            ${keyBaseStyles};
                                        `}
                                    >
                                        {t.ReceivedSms}
                                    </div>
                                </TypedGrid>

                                <TypedGrid item xs0={6}>
                                    <div>
                                        <div
                                            css={css`
                                                ${valueBaseStyles};
                                                margin-bottom: 8px;
                                            `}
                                        >
                                            {receivedSms}
                                        </div>

                                        {visibilitySettings.isSmsCountResetSupported && (
                                            <Reset
                                                resource="reset-sms"
                                                type={0}
                                                noData={receivedSms === 0}
                                                noDataText={
                                                    t.NoSmsHasBeenReceived
                                                }
                                            />
                                        )}
                                    </div>
                                </TypedGrid>
                            </TypedGrid>
                        </div>

                        <Divider />

                        <div
                            css={css`
                                margin: 8px 0;
                            `}
                        >
                            <TypedGrid
                                container
                                columnSpacing={2}
                                rowSpacing={1}
                                alignItems="center"
                            >
                                <TypedGrid item xs0={6}>
                                    <div
                                        css={css`
                                            ${keyBaseStyles};
                                        `}
                                    >
                                        {t.SmsCount}
                                    </div>
                                </TypedGrid>

                                <TypedGrid item xs0={6}>
                                    <div
                                        css={css`
                                            ${valueBaseStyles};
                                        `}
                                    >
                                        {smsCount}
                                    </div>
                                </TypedGrid>
                            </TypedGrid>
                        </div>
                    </Fragment>
                )}
            </Card>

            {updateFirmwareModalInfo?.isOpen &&
                updateFirmwareModalInfo?.firmwareType ===
                    FirmwareType.Modem && (
                    <UpdateFirmwareModal
                        isOpen={
                            updateFirmwareModalInfo?.isOpen &&
                            updateFirmwareModalInfo?.firmwareType ===
                                FirmwareType.Modem
                        }
                        onUpdateStart={() =>
                            updateModemAsync(
                                updateFirmwareModalInfo?.path,
                                Loaders.ModemUpdate
                            )
                        }
                        title={t.ModemVersionUpdate}
                        description={t.ModemVersionUpdateDescription}
                        extensions={["zip", "bin", "pack"]}
                        loaderName={Loaders.ModemUpdate}
                    />
                )}
        </Fragment>
    );
};

export default CellularInformation;
