import React, { useContext } from "react";
import { Redirect } from "react-router-dom";
import Route from "./Route";
import UserInfoContext from "../../context/userInfo/userInfoContext";

export interface PrivateRouteProps {
    component: any;
    redirectCondition: boolean,
    redirectRoute: string,
    exact: boolean;
    path: string;
}

const PrivateRoute = ({ component, redirectCondition, redirectRoute, ...rest }: PrivateRouteProps) => {
    const { loading, isDesktopApplication } = useContext(UserInfoContext);

    if (loading && !isDesktopApplication) {
        return null;
    }
    if (redirectCondition) {
        return <Redirect to={redirectRoute} />;
    }
    return <Route {...rest} component={component} />;
};

export default PrivateRoute;
