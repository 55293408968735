import { createContext } from "react";
import { DisabledParameterType } from "./MenuItemState";

export interface MenuItemContextState {
    activeFrame: any; // Frame;
    frames: any[]; // Frame[];
    parameters: any;
    disabledParameters: any;
    setFrames: (data: any) => void;
    setActiveFrame: (data: any) => void;
    setDisabledParameters: (data: DisabledParameterType) => void;
    setParameter: (id: any, value: any) => void;
    updateParameter: (
        parameterId: any,
        newValue: string | number,
        label: string,
        elementRef: any,
        successCallback: any,
        errorCallback: any,
        loadingEnd: any,
        setParams: boolean,
        canceledCallback?: any
    ) => void;
    setParameters: (values: any) => void;
    updateParameters: (
        values: any,
        successCallback?: any,
        errorCallback?: any,
        includeAllFails?: boolean,
        tryToFix?: boolean,
        hasParameterErrorArray?: boolean
    ) => void;
    resetParameters: (parameters: number[]) => void;
    findDisabledParameterById: (id: number) => any;
    findDisabledCollectionItemsById: (id: number) => any;
    findParameterValueById: (id: number | string | undefined) => string;
    setParameterError: (id: any, error: any) => any;
    hasParameterErrors: () => boolean;
    setBreadCrumbItems: (data: any) => void;
    setManualGeofenceParameters: (data: any) => void;
    errors: any;
    breadcrumbItems: any[];
    setParameterErrors: (errors: any) => void;
}

const MenuItemContext = createContext({} as MenuItemContextState);

export default MenuItemContext;
