import { Tct } from "./useTct";
import useApi from "./useApi";
import {
    AvlSearchResult,
    BluetoothView,
    CustomTable,
    DeviceGuide,
    DtResponse,
    FEElement,
    Frame,
    GeneratedLayout,
    GeofenceTable,
    LanguageInfo,
    ManualGeofence,
    MenuItem,
    ModifiedParameters,
    SearchResult,
    SerializableResult,
    TranslatableName,
    UserInfo,
} from "../generatedTypes";
import { useContext } from "react";
import LanguageContext from "../context/language/languageContext";
import { ConfigurationPasswordState, DeviceFamily } from "./types";
import { SelectedFile } from "./useUploadFile";
import { getRandomNumber } from "./helpers";
import { ConfigCacheKeySuffix, HttpStatusCodes } from "../constants/constants";

/**
 * Custom error class for WebAssembly errors mimicking AxiosResponse to be used in showErrorMsg
 */
export class WasmResponse extends Error {
    constructor(errorTitle: string | undefined, errorDescription: string, status: number = HttpStatusCodes.BadRequest) {
        super();
        this.response = {
            data: {
                title: errorTitle,
                detail: errorDescription,
            },
            status: status
        };
    }

    response: {
        data: {
            title: string | undefined;
            detail: string;
        };
        status: number;
    };
}

const useWasm = (): Tct => {
    const {
        getData,
        postData,
        changeLanguageAsync: changeLanguageAsyncApi,
        getUserInfoAsync: getUserInfoAsyncApi,
        getLanguagesAsync: getLanguagesAsyncApi,
        postSettingsAsync: postSettingsAsyncApi,
        postSettingsHideOptionsAsync: postSettingsHideOptionsAsyncApi,
        getSettingsAsync: getSettingsAsyncApi,
        getSystemInformationAsync: getSystemInformationAsyncApi,
        getSettingsWithQueryParamsAsync: getSettingsWithQueryParamsAsyncApi,
        getStartupDevicesAsync: getStartupDevicesAsyncApi,
        getExperimentalConfigurationAsync: getExperimentalConfigurationAsyncApi,
        postExternalConfigurationAsync: postExternalConfigurationAsyncApi,
        getBluetoothDevicesAsync: getBluetoothDevicesAsyncApi,
        getBluetoothDevicesScanAsync: getBluetoothDevicesScanAsyncApi,
        getSpecsAsync: getSpecsAsyncApi,
        getDefaultSpecsAsync: getDefaultSpecsAsyncApi,
        showErrorMsg,
    } = useApi();

    const { selectedLanguage } = useContext(LanguageContext);

    const editor = (window as any).editor;
    const parameterEditor = (window as any).parameterEditor;
    const editorTranslator = (window as any).editorTranslator;
    const editorSearch = (window as any).editorSearch;
    const tooltipDrawer = (window as any).tooltipDrawer;

    const getResultValue = <T>(
        result: SerializableResult<T>
    ): T | undefined => {
        if (result.isSuccess) {
            if (result.value) {
                return result.value;
            }
            return;
        }

        throw new WasmResponse(result.errorTitle, result.error ?? "");
    };

    const getCacheKeyForTranslations = (deviceFamily: DeviceFamily, specId: number) => {
        if(specId !== 1){
            return getRandomNumber().toString();
        }

        return localStorage.getItem(`${deviceFamily}-${ConfigCacheKeySuffix}`) ?? getRandomNumber().toString();
    }
    
    const getTranslationsAsync = async (deviceFamily: DeviceFamily, specId: number) => {
        const versionKey = getCacheKeyForTranslations(deviceFamily, specId);
        const { data } = await getData(
            `${selectedLanguage}/editor/translations`,
            {
                params: {
                    deviceFamily: deviceFamily,
                    specId: specId,
                    versionKey: versionKey
                }
            });
        
        return data;
    }

    const getOfflineConfigurationAsync = async (
        hwVersion: string,
        specId: number = 1
    ): Promise<GeneratedLayout> => {


        let { data } = await postData(
            `${selectedLanguage}/editor/offline_web`,
            {
                hardwareVersion: hwVersion,
                specId: specId,
            }
        );
        
        data.translations = await getTranslationsAsync(data.layout.deviceFamily, data.layout.specId);

        const layoutResult = await editor.buildConfigurationAsync(data);

        return getResultValue(layoutResult)!;
    };

    const getConfigurationFromFileAsync = async (
        selectedFile: File
    ): Promise<GeneratedLayout> => {
        const formData = new FormData();
        selectedFile && formData.append("file", selectedFile);
        let { data } = await postData(
            `${selectedLanguage}/editor/fromfile_web`,
            formData
        );
        
        data.translations = await getTranslationsAsync(data.layout.deviceFamily, data.layout.specId);

        const layoutResult = await editor.buildConfigurationAsync(data);

        return getResultValue(layoutResult)!;
    };

    const connectAsync = async (
        portName: string,
        connectionType: number
    ): Promise<GeneratedLayout> => {
        throw new Error("Not supported");
    };

    const reconnectAsync = async (): Promise<GeneratedLayout> => {
        throw new Error("Not supported");
    };

    const getConfigurationFromFotaAsync = async (
        fileId: number
    ): Promise<GeneratedLayout> => {
        let { data } = await getData(
            `${selectedLanguage}/fotaserver/openconfiguration_web?fileId=${fileId}`
        );
        
        data.translations = await getTranslationsAsync(data.layout.deviceFamily, data.layout.specId);

        const layoutResult = await editor.buildConfigurationAsync(data);

        return getResultValue(layoutResult)!;
    };

    const getMenuItemAsync = async (
        configurationId: number,
        menuItemId: number
    ): Promise<MenuItem> => {
        const result = await editor.getMenuItemAsync(
            configurationId,
            menuItemId
        );

        return getResultValue(result)!;
    };

    const getFrameAsync = async (
        configurationId: number,
        menuItemId: number,
        frameId: number
    ): Promise<Frame> => {
        const result = await editor.getFrameAsync(
            configurationId,
            menuItemId,
            frameId
        );

        return getResultValue(result)!;
    };

    const getModifiedParameters = async (
        configurationId: number
    ): Promise<ModifiedParameters> => {
        try {
            return await parameterEditor.getModifiedParametersAsync(
                configurationId
            );
        } catch (error) {
            showErrorMsg(error as any);
        }
        return {
            parameters: [],
            recoveryConfigurationPasswordState:
                ConfigurationPasswordState.Same as any,
            configurationPasswordState: ConfigurationPasswordState.Same as any,
        };
    };

    const getDeviceGuideAsync = async (
        configurationId: number
    ): Promise<DeviceGuide> => {
        const result = await editor.getDeviceGuideAsync(configurationId);

        return getResultValue(result)!;
    };

    const setGuideStepAsCompletedAsync = async (
        configurationId: number,
        stepIndex: number
    ): Promise<void> => {
        const result = await editor.setGuideStepAsCompletedAsync(
            configurationId,
            stepIndex
        );

        return getResultValue(result)!;
    };

    const getUserInfoAsync = async (): Promise<UserInfo> => {
        return await getUserInfoAsyncApi();
    };

    const getLanguagesAsync = async (): Promise<LanguageInfo[]> => {
        return await getLanguagesAsyncApi();
    };

    const importCsvForListConfigAsync = async (
        configurationId: number,
        listName: string,
        file: SelectedFile
    ) => {
        if (!file) {
            throw new Error("File not selected");
        }
        
        const fileArrayBuffer = await file.arrayBuffer();
        const byteArray = new Uint8Array(fileArrayBuffer);
        
        return await parameterEditor.loadCsvForListConfigAsync(
            configurationId,
            listName,
            byteArray
        );
    };

    const chechFileCompatibilityAsync = async (
        configurationId: number,
        configFile: SelectedFile
    ) => {
        if (!configFile) {
            throw new Error("File not selected");
        }

        const fileArrayBuffer = await configFile.arrayBuffer();
        const byteArray = new Uint8Array(fileArrayBuffer);
        
        const result: SerializableResult<string> = 
            await parameterEditor.checkFileCompatibilityAsync(configurationId, byteArray, configFile.name);

        if(result.isSuccess && result.value){
            // If the result is successful but has a value, throw Conflict, just like backend does
            throw new WasmResponse("", result.value, HttpStatusCodes.Conflict);
        }
        
        getResultValue(result);
    };

    const loadParametersFromFileAsync = async (
        configurationId: number,
        configFile: SelectedFile
    ): Promise<DtResponse> => {
        if (!configFile) {
            throw new Error("File not selected");
        }

        const fileArrayBuffer = await configFile.arrayBuffer();
        const byteArray = new Uint8Array(fileArrayBuffer);

        const result: SerializableResult<DtResponse> = 
            await parameterEditor.loadParametersFromFileAsync(configurationId, byteArray, configFile.name);

        return getResultValue(result)!;
    };

    const changeLanguageAsync = async (
        newLanguage: string,
        configurationId?: number
    ) => {
        const data = await changeLanguageAsyncApi(newLanguage, configurationId);

        if ((configurationId ?? 0) > 0) {
            const result = await editorTranslator.setLanguageAsync(
                newLanguage,
                configurationId
            );
            getResultValue(result);
        }

        return data;
    };

    const translateAsync = async (
        configurationId: number,
        keysToTranslate: string[],
        deviceFamily: DeviceFamily,
        specId: number
    ): Promise<TranslatableName[]> => {
        return await editorTranslator.translateAsync(
            keysToTranslate,
            deviceFamily,
            specId
        );
    };

    const saveToFileAsync = async (
        configurationId: number,
        filePath: string,
        modifiedOnly: boolean,
        skipPasswords: boolean
    ) => {
        const portationResult = await editor.getConfigurationToSaveAsync(
            configurationId,
            modifiedOnly,
            skipPasswords
        );

        const portation = getResultValue(portationResult)!;

        await postData(
            `${selectedLanguage}/editor/${configurationId}/storetofile_web`,
            {
                filePath,
                portation,
            }
        );

        await parameterEditor.resetModifiedParametersAsync(configurationId);
    };

    const saveToFotaAsync = async (
        configurationId: number,
        fileName: string,
        modifiedOnly: boolean,
        skipPasswords: boolean
    ) => {
        const portationResult = await editor.getConfigurationToSaveAsync(
            configurationId,
            modifiedOnly,
            skipPasswords
        );

        const portation = getResultValue(portationResult)!;

        await postData(`${selectedLanguage}/fotaserver/sendconfiguration_web`, {
            filePath: fileName,
            portation,
        });

        await parameterEditor.resetModifiedParametersAsync(configurationId);
    };

    const saveToDeviceAsync = async (
        configurationId: number,
        skipPasswords: boolean
    ) => {
        throw new Error("Not supported");
    };

    const getBluetoothViewAsync = async (
        configurationId: number
    ): Promise<BluetoothView> => {
        const result = await editor.getBluetoothViewAsync(configurationId);

        return getResultValue(result)!;
    };

    const getBluetoothPresetListAsync = async (
        configurationId: number
    ): Promise<string[]> => {
        const result = await editor.getBluetoothPresetListAsync(
            configurationId
        );

        return getResultValue(result)!;
    };

    const getBluetoothTableAsync = async (
        configurationId: number,
        modalName: string,
        presetName?: string
    ): Promise<CustomTable> => {
        const result = await editor.getBluetoothTableAsync(
            configurationId,
            presetName,
            modalName
        );

        return getResultValue(result)!;
    };

    const getBluetoothModalDataAsync = async (
        configurationId: number,
        modalName: string,
        isEdit: boolean = false
    ) => {
        const result = await editor.getBluetoothModalAsync(
            configurationId,
            modalName,
            isEdit
        );

        return getResultValue(result)!;
    };

    const saveBluetoothPresetAsync = async (
        configurationId: number,
        tableName: string,
        presetName: string,
        parameters: any
    ) => {
        await editor.saveBluetoothPresetAsync(
            configurationId,
            parameters,
            presetName,
            tableName
        );
    };

    const updateSensorAsync = async (
        configurationId: number,
        modalName: string,
        parameters: any
    ) => {
        await editor.updateSensorTableAsync(
            configurationId,
            modalName,
            parameters
        );
    };

    const removeSensorAsync = async (
        configurationId: number,
        modalName: string,
        sensorNr: number
    ) => {
        await editor.removeSensorAsync(configurationId, modalName, sensorNr);
    };

    const searchAsync = async (
        configurationId: number,
        searchTerm: string
    ): Promise<SearchResult[]> => {
        const result = await editorSearch.searchAsync(
            configurationId,
            searchTerm
        );

        return getResultValue(result)!;
    };

    const searchAvlIdsAsync = async (
        configurationId: number,
        searchTerm: string
    ): Promise<AvlSearchResult[]> => {
        const result = await editorSearch.searchAvlIdsAsync(
            configurationId,
            searchTerm
        );

        return getResultValue(result)!;
    };

    const getGeozoneTableAsync = async (
        configurationId: number
    ): Promise<GeofenceTable> => {
        const result = await editor.getGeozoneTableAsync(configurationId);

        return getResultValue(result)!;
    };

    const getGeozoneAsync = async (
        configurationId: number,
        geozoneId: number,
        restoreDefaults: boolean = false
    ): Promise<ManualGeofence> => {
        const result = await editor.getGeozoneAsync(
            configurationId,
            geozoneId,
            restoreDefaults
        );

        return getResultValue(result)!;
    };

    const getParameterTooltipAsync = async (
        configurationId: number,
        parameterId: number
    ): Promise<FEElement> => {
        const result = await tooltipDrawer.getParameterTooltipAsync(
            configurationId,
            parameterId
        );

        const val = getResultValue(result)!;
        return JSON.parse(val as unknown as string);
    };

    const getBlockTooltipAsync = async (
        configurationId: number,
        blockName: string
    ): Promise<FEElement> => {
        const result = await tooltipDrawer.getBlockTooltipAsync(
            configurationId,
            blockName
        );

        const val = getResultValue(result)!;
        return JSON.parse(val as unknown as string);
    };

    const validateParameterAsync = async (
        configurationId: number,
        parameterId: number,
        value: string
    ) => {
        const result = await parameterEditor.validateParameterAsync(
            configurationId,
            parameterId,
            value
        );

        getResultValue(result);
    };

    const postSettingsAsync = async (settings: { [key: string]: any }) => {
        await postSettingsAsyncApi(settings);
    };

    const postSettingsHideOptionsAsync = async (settings: {
        [key: string]: any;
    }) => {
        await postSettingsHideOptionsAsyncApi(settings);
    };

    const getSettingsAsync = async () => {
        return await getSettingsAsyncApi();
    };

    const getSystemInformationAsync = async () => {
        return await getSystemInformationAsyncApi();
    };

    const getSettingsWithQueryParamsAsync = async (
        hwVersion: string,
        specId: number,
        isOnline: boolean
    ) => {
        return await getSettingsWithQueryParamsAsyncApi(
            hwVersion,
            specId,
            isOnline
        );
    };

    const getStartupDevicesAsync = async () => {
        const startupDevices = await getStartupDevicesAsyncApi();
        try {
            await editor.setHardwareInfoAsync(startupDevices);
        } catch (e) {
            // Might throw an error in development build because of the way the wasm is loaded, should not occur with loader enabled
            // Ignore the error to not interfere with development
            console.error("Failed to set hardware info", e);
        }
        return startupDevices;
    };

    const getExperimentalConfigurationAsync = async () => {
        return await getExperimentalConfigurationAsyncApi();
    };

    const postExternalConfigurationAsync = async (
        value: string | null,
        isDefault: boolean
    ) => {
        await postExternalConfigurationAsyncApi(value, isDefault);
    };

    const updateParameterAsync = async (
        configurationId: number,
        parameterId: number,
        newValue: string | number,
        controllers: any,
        forceDependencyUpdate: boolean
    ) => {
        const result = await parameterEditor.updateParameterAsync(
            configurationId,
            parameterId,
            newValue.toString(),
            forceDependencyUpdate
        );

        return getResultValue(result)!;
    };

    const updateParametersAsync = async (
        configurationId: number,
        payload: { [key: string]: any },
        includeAllFails?: boolean,
        tryToFix?: boolean
    ) => {
        const result = await parameterEditor.updateParametersAsync(
            configurationId,
            payload,
            includeAllFails,
            tryToFix
        );
        return getResultValue(result)!;
    };

    const getBluetoothDevicesAsync = async () => {
        return await getBluetoothDevicesAsyncApi();
    };

    const getBluetoothDevicesScanAsync = async () => {
        return await getBluetoothDevicesScanAsyncApi();
    };

    const getSpecsAsync = async () => {
        return await getSpecsAsyncApi();
    };

    const getDefaultSpecsAsync = async () => {
        return await getDefaultSpecsAsyncApi();
    };

    return {
        showErrorMsg,

        getOfflineConfigurationAsync,
        getConfigurationFromFileAsync,
        connectAsync,
        reconnectAsync,
        getConfigurationFromFotaAsync,

        getMenuItemAsync,
        getFrameAsync,

        getModifiedParameters,

        getDeviceGuideAsync,
        setGuideStepAsCompletedAsync,

        getUserInfoAsync,

        getLanguagesAsync,
        changeLanguageAsync,
        translateAsync,

        saveToFileAsync,
        saveToFotaAsync,
        saveToDeviceAsync,

        getBluetoothViewAsync,
        getBluetoothPresetListAsync,
        getBluetoothTableAsync,
        getBluetoothModalDataAsync,
        saveBluetoothPresetAsync,
        updateSensorAsync,
        removeSensorAsync,

        searchAsync,
        searchAvlIdsAsync,

        getGeozoneTableAsync,
        getGeozoneAsync,

        getParameterTooltipAsync,
        getBlockTooltipAsync,

        chechFileCompatibilityAsync,
        loadParametersFromFileAsync,
        importCsvForListConfigAsync,

        validateParameterAsync,
        postSettingsAsync,
        postSettingsHideOptionsAsync,
        getSettingsAsync,

        getSystemInformationAsync,
        getSettingsWithQueryParamsAsync,
        getStartupDevicesAsync,
        getExperimentalConfigurationAsync,

        postExternalConfigurationAsync,
        updateParameterAsync,
        updateParametersAsync,
        getBluetoothDevicesAsync,
        getBluetoothDevicesScanAsync,

        getSpecsAsync,
        getDefaultSpecsAsync,
    };
};

export default useWasm;
