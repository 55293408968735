/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import { useContext } from "react";
import { FEHyperlink } from "../../../../../../generatedTypes";
import ThemeContext from "../../../../../../context/theme/themeContext";

interface FeHyperlinkProps {
    hyperlink: FEHyperlink;
}
const FeHyperlink = ({ hyperlink }: FeHyperlinkProps) => {
    const {
        colors: { blue700 },
    } = useContext(ThemeContext);

    const url = hyperlink?.url;
    const text = hyperlink?.linkText;
    return (
        <a
            css={css({
                color: blue700,
                fontSize: "10px",
                letterSpacing: "0.4px",
                wordBreak: "break-word",
                textOverflow: "ellipsis",
                overflow: "hidden",
                whiteSpace: "nowrap",
                width: "200px",
                display: "block",
            })}
            href={url || ""}
            target="_blank"
        >
            {text}
        </a>
    );
};

export default FeHyperlink;
