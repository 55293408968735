/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from "@emotion/react";

import { Fragment, useContext } from "react";
import LanguageContext from "../../../../../context/language/languageContext";
import LayoutContext from "../../../../../context/layout/layoutContext";
import useDesktopHost from "../../../../../utils/useDesktopHost";
import Select from "../../../../MuiComponents/Select";
import MenuItem from "@mui/material/MenuItem";
import InputField from "../../../../MuiComponents/InputField";
import TextLink from "../../../../MuiComponents/TextLink";
import { LoadStatus } from "../../../../../constants/constants";

export interface LoadConfigurationProps {
    loadStatus: string;
    path: string;
    change: (e?: any, value?: any) => void;
}
const LoadConfiguration = ({
    loadStatus,
    path,
    change,
}: LoadConfigurationProps) => {
    const { t } = useContext(LanguageContext);

    const { layoutData } = useContext(LayoutContext);

    const { openFileDialog } = useDesktopHost();

    const handleChange = (e: any) => {
        const name = e.target.name;
        const value = e.target.value;

        change(name, value);
    };

    const handleBrowseClick = () =>
        openFileDialog(
            {
                title: t.SelectFile,
                filters: [
                    {
                        extensions: ["cfg", "config"],
                        name: t.ConfigurationFile,
                    },
                ],
            },

            (result: any) =>
                result.length > 0 && change("deviceSettingsPath", result[0])
        );

    return (
        <Fragment>
            <Select
                value={loadStatus}
                onChange={handleChange}
                label={t.LoadConfiguration}
                medium={true}
                name="deviceSettingsLoadStatus"
            >
                {layoutData?.isOnline && (
                    <MenuItem value={LoadStatus.FromDevice}>
                        {t.FromDevice}
                    </MenuItem>
                )}
                <MenuItem value={LoadStatus.FromFile}>{t.FromFile}</MenuItem>
                <MenuItem value={LoadStatus.Default}>{t.Default}</MenuItem>
            </Select>

            {loadStatus === LoadStatus.FromFile && (
                <InputField
                    fullWidth
                    name="deviceSettingsPath"
                    value={path}
                    size="medium"
                    iconRight={
                        <TextLink
                            size="normal"
                            onClick={handleBrowseClick}
                            idForTesting="load-configuration-browse"
                        >
                            {t.Browse}
                        </TextLink>
                    }
                    readOnly
                    wrapperStyle={css({
                        marginTop: "8px !important",
                    })}
                />
            )}
        </Fragment>
    );
};

export default LoadConfiguration;
