import {
    SET_LOADING,
    HANDLE_CHANGE_PASSWORD,
    HANDLE_VISIBILITY,
    HANDLE_ERROR,
} from "./enterPasswordModalActions";

export interface InitialState {
    isLoading: boolean;
    isDisabled: boolean;
    password: string;
    passwordShow: boolean;
    errorPassword: string | null;
    attemptsLeft: number | null;
}

type Action =
    | {
          type: typeof SET_LOADING;
          payload: boolean;
      }
    | {
          type: typeof HANDLE_CHANGE_PASSWORD;
          payload: string;
      }
    | {
          type: typeof HANDLE_VISIBILITY;
      }
    | {
          type: typeof HANDLE_ERROR;
          payload: { errorMsg: string | null; attempts: number | null };
      };

export default (state: InitialState, action: Action) => {
    switch (action.type) {
        case SET_LOADING: {
            return {
                ...state,
                isLoading: action.payload,
            };
        }
        case HANDLE_CHANGE_PASSWORD: {
            return {
                ...state,
                isDisabled: action.payload.length === 0,
                password: action.payload,
            };
        }
        case HANDLE_VISIBILITY: {
            return {
                ...state,
                passwordShow: !state.passwordShow,
            };
        }
        case HANDLE_ERROR: {
            const { errorMsg, attempts } = action.payload;

            return {
                ...state,
                errorPassword: errorMsg,
                attemptsLeft: attempts,
            };
        }
        default:
            return state;
    }
};
