/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import { Fragment } from "react";
import {
    FEElement,
    FEList,
    FEParagraph,
    FEText,
} from "../../../../generatedTypes";
import FeList from "./FeList";
import FeParagraph from "./FeParagraph";
import FeText from "./FeText";

export const renderFeElement = (element: FEElement, index?: number) => {
    switch (element?.type.toString()) {
        case "paragraph":
            return (
                <FeParagraph key={index} paragraph={element as FEParagraph} />
            );
        case "text":
            return <FeText key={index} text={element as FEText} />;
        case "list":
            return <FeList key={index} list={element as FEList} />;
        case undefined:
        case null:
            return <Fragment></Fragment>;
        default:
            return (
                <div
                    css={css({ color: "red" })}
                >{`Unhandled rendering of ${element.type}`}</div>
            );
    }
};
