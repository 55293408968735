import React, { useCallback, useContext, useEffect, useReducer, useRef, } from "react";
import LanguageContext from "../language/languageContext";
import LayoutContext from "../layout/layoutContext";
import SearchContext from "./searchContext";
import SearchReducer from "./searchReducer";
import useApi, { CancelTokenSource } from "../../utils/useApi";
import { CLEAR_INPUT, CLOSE_MODAL, OPEN_MODAL, SELECT_SEARCH_RESULT, SET_LOADING, SET_RESULTS, } from "./searchActions";
import useTct from "../../utils/useTct";
import { SearchResult } from "../../generatedTypes";

interface SearchStateProps {
    children: React.ReactNode;
}

const SearchState = ({ children }: SearchStateProps) => {
    const { selectedLanguage } = useContext(LanguageContext);

    const { layoutData } = useContext(LayoutContext);

    const { axios, isCanceled } = useApi();
    const { searchAsync } = useTct();

    const getCurrentHistoryResults = () => {
        try {
            return JSON.parse(localStorage.getItem("searchHistory")!);
        } catch (error) {
            return null;
        }
    };

    const searchHistoryResults = getCurrentHistoryResults();
    const isArray: boolean = Array.isArray(searchHistoryResults);
    const currentHistoryResults = isArray ? searchHistoryResults : null;

    const initialState = {
        isOpenModal: false,
        isMobileSearchInput: false,
        results: null,
        historyResults: currentHistoryResults,
        searchPhrase: "",
        isLoading: false,
        isInputClear: false,
        searchedItemFrameIndex: null,
    };

    const cancelToken = useRef<CancelTokenSource | null>(null);

    const [state, dispatch] = useReducer(SearchReducer, initialState);

    useEffect(() => {
        if (!state.isOpenModal && cancelToken.current) {
            cancelToken.current.cancel(
                "Operation canceled due to closed modal."
            );
        }
    }, [state.isOpenModal]);

    const setLoading = (loadingState: boolean) =>
        dispatch({ type: SET_LOADING, payload: loadingState });
    const openModal = () => dispatch({ type: OPEN_MODAL });
    const closeModal = () => dispatch({ type: CLOSE_MODAL });

    const setResults = useCallback(
        async (searchValue: string) => {
            cancelToken.current &&
                cancelToken.current.cancel(
                    "Operation canceled due to new request."
                );

            cancelToken.current = axios.CancelToken.source();

            if (searchValue.length === 0) {
                dispatch({
                    type: SET_RESULTS,
                    payload: { data: null, searchValue },
                });
            } else {
                try {
                    setLoading(true);
                    if(!layoutData) {
                        throw new Error("Cannot search without a layout");
                    }
                    
                    const data = await searchAsync(layoutData.id, searchValue.toLocaleLowerCase(), cancelToken.current?.token);

                    dispatch({
                        type: SET_RESULTS,
                        payload: { data, searchValue },
                    });
                } catch (error) {
                    if (!isCanceled(error)) {
                        dispatch({
                            type: SET_RESULTS,
                            payload: { data: null, searchValue },
                        });
                    }
                }
            }
        },
        // eslint-disable-next-line
        [
            dispatch,
            axios.CancelToken,
            searchAsync,
            isCanceled,
            layoutData?.id,
            selectedLanguage,
        ]
    );

    const selectSearchResult = (result: SearchResult) =>
        dispatch({ type: SELECT_SEARCH_RESULT, payload: result });

    const clearInput = () => dispatch({ type: CLEAR_INPUT });

    return (
        <SearchContext.Provider
            value={{
                isOpenModal: state.isOpenModal,
                isMobileSearchInput: state.isMobileSearchInput,
                results: state.results,
                historyResults: state.historyResults,
                searchPhrase: state.searchPhrase,
                isLoading: state.isLoading,
                isInputClear: state.isInputClear,
                searchedItemFrameIndex: state.searchedItemFrameIndex,
                openModal,
                closeModal,
                setResults,
                selectSearchResult,
                clearInput,
            }}
        >
            {children}
        </SearchContext.Provider>
    );
};

export default SearchState;
