import { createContext } from "react";
import { InitialState } from "./avlSearchReducer";

interface SearchContextState extends InitialState {
    setModalAnchor: (openModalAnchor: any) => void;
    setResults: (results: any) => void;
    selectSearchResult: (result: any) => void;
    setActiveParameterId: (parameterId: string) => void;
    setParameterNames: ({ parameterName, parameterId }: any) => void;
    setInitialParameterNames: (parameterNames: any[]) => void;
}

const SearchContext = createContext({} as SearchContextState);

export default SearchContext;
