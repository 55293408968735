import { createContext } from "react";
import { InitialState } from "./layoutReducer";
import { DeviceGuide } from "../../generatedTypes";

export interface LayoutContextState extends InitialState {
    findBindedList: (name: string | undefined) => any;
    setListValues: (items: any) => void;
    setListValue: (items: any[], name: string) => void;
    setActiveFrameIndex: (index: number | null | undefined) => void;
    setLayoutData: (data: any) => void;
    setLayoutError: (error: any) => void;
    setLayoutDataWithSettings: (
        hwVersion: string,
        specId: number,
        isOnline: boolean,
        otherData: any
    ) => void;
    startActionLoading: (action: string) => void;
    stopActionLoading: () => void;
    resetLayoutData: () => void;
    disableNavigationBetweenMenuItems: (action: boolean) => void;
    setNextMenuItemIndex: (action: number) => void;
    setActiveDeviceGuideStep: (action: number | null) => void;
    setActiveDeviceGuideFrameIndex: (action: number | null) => void;
    clearSearchHistory: () => void;
    setDrawerParameterId: (data: number) => void;
    setDrawerBlockName: (data: string) => void;
    setReloadMenuItem: (data: boolean) => void;
    setDeviceConfigureButtonPortName: (data: string) => void;
    setDeviceGuide: (steps: DeviceGuide | null) => void;
    setUploadedFileDataToLayout: (data: any) => void;
    setUploadFileLoadStatus: (status: string) => void;
    setUploadFileName: (name: string) => void;
}
const LayoutContext = createContext({} as LayoutContextState);

export default LayoutContext;
