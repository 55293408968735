/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import Badge from "../../../Badge";

export interface FirmwareWithVersionsProps {
    title: string;
    currentVersion: string;
    newVersion: string;
}

const FirmwareWithVersions: React.FunctionComponent<
    FirmwareWithVersionsProps
> = ({ title, currentVersion, newVersion }) => {
    return (
        <div>
            <span
                css={css({
                    fontSize: "14px",
                    fontStyle: "normal",
                    fontWeight: 400,
                    lineHeight: "20px", // 142.857%
                    letterSpacing: "0.1px",
                })}
            >
                {title}
            </span>
            <div
                css={css({
                    display: "flex",
                    gap: "8px",
                    marginTop: "8px",
                    marginBottom: "20px",
                })}
            >
                <Badge color="light">Current: {currentVersion}</Badge>
                <Badge color="success">New: {newVersion}</Badge>
            </div>
        </div>
    );
};

export default FirmwareWithVersions;
