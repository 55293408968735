/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import { Table, TableBody, TableCell, TableHead, TableRow, } from "@mui/material";
import { Fragment, useContext, useEffect, useMemo, useState } from "react";
import { useFlexLayout, useRowState, useTable } from "react-table";
import ThemeContext from "../../../../../context/theme/themeContext";
import {
    formatArrayIntoOneArrayWithComponents,
    noop,
    returnProvidedNumberOfItemsOfArray,
} from "../../../../../utils/helpers";
import { ReactComponent as BluetoothEmptyStateIcon } from "../../../../../assets/BluetoothEmptyStateIcon.svg";
import TableCollection from "./TableCollection";
import TableInput from "./TableInput";
import AvlSearch from "./SmsAvlSearch";
import avlSearchContext from "../../../../../context/avlSearch/avlSearchContext";
import SettingsContext from "../../../../../context/settings/settingsContext";
import Tooltip from "../../../../MuiComponents/Tooltip";
import InfoIcon from "../../../../../assets/icons/InfoIcon";
import smsEventsTooltips from "../../../../../constants/smsEventsTooltips";
import LanguageContext from "../../../../../context/language/languageContext";

export interface CustomTableProps {
    data: {
        rows: any[];
        name?: string | undefined;
        type: string;
    };
}

const CustomTable = ({ data }: CustomTableProps) => {
    const { t } = useContext(LanguageContext);

    const {
        colors: {
            white,
            blue100,
            blue200,
            textDark,
            blue700,
            textDarkDisabled,
            gray700,
        },
        boxShadows: { grayBoxShadow, blueBoxShadow },
    } = useContext(ThemeContext);

    const { setInitialParameterNames } = useContext(avlSearchContext);

    const { isExplanatoryTextsHidden } = useContext(SettingsContext);

    const rowsToShow =
        data?.rows.filter((row: any) => row.hideRow === false).length + 1;
    const [rowsCount, setRowCount] = useState(rowsToShow);

    useEffect(() => {
        const avlParametersNamesWithId = data?.rows.map((row: any) => ({
            parameterId: row.parameterId,
            parameterName: row.rowName,
        }));
        setRowCount(rowsToShow);
        setInitialParameterNames(avlParametersNamesWithId);
    }, [rowsToShow]);

    const renderComponent = (componentType: string, data: any) => {
        const parameterValueFromContextWithData = data;

        switch (componentType) {
            case "TextBox":
                return <TableInput data={parameterValueFromContextWithData} />;
            case "NumberInput":
                return <AvlSearch data={parameterValueFromContextWithData} />;
            case "Collection":
                return (
                    <TableCollection data={parameterValueFromContextWithData} />
                );
            default:
                return null;
        }
    };
    const columnsData = data?.rows[0].components
        .map((item: any) => {
            if (!item.localizationKey) {
                return null;
            }
            return {
                id: item.localizationKey,
                accessor: () => item.localizationKey,
                Header: item.label || "",
                Cell: ({ row }: any) => {
                    return renderComponent(
                        row.original[item.localizationKey].componentType,
                        row.original[item.localizationKey]
                    );
                },
            };
        })
        .filter(Boolean);

    const columns = useMemo(() => [...columnsData], [data, rowsCount]);

    const formattedRows = formatArrayIntoOneArrayWithComponents(data?.rows);

    const numberOfRows = returnProvidedNumberOfItemsOfArray(
        formattedRows,
        rowsCount
    );
    const rowsData = useMemo(() => numberOfRows, [rowsCount, data]);
    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
        useTable(
            {
                columns,
                data: rowsData,
            },
            useFlexLayout,
            useRowState
        );

    const hasAllRowsActive = rowsCount === rows.length + 1;

    const getColumnTooltip = (column: any): React.ReactElement => {
        const tooltip = column?.id && smsEventsTooltips[column.id];
        if (isExplanatoryTextsHidden || !tooltip) {
            return <Fragment />;
        }
        return (
            <Tooltip title={tooltip} small placement="top">
                <span
                    css={css({
                        alignItems: "center",
                        marginLeft: "5px",
                        display: "flex",
                        "& > svg": {
                            fontSize: "16px",
                            color: blue700,
                        },
                    })}
                >
                    <InfoIcon />
                </span>
            </Tooltip>
        );
    };

    const renderTableContent = () => {
        if (!rows.length) {
            return (
                <TableRow
                    css={css({
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        height: "100%",
                    })}
                >
                    <TableCell
                        sx={{
                            borderBottom: "none",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            flexDirection: "column",
                        }}
                    >
                        <BluetoothEmptyStateIcon />
                        <div
                            css={css({
                                marginTop: "15px",
                                fontWeight: 600,
                                fontSize: "16px",
                                lineHeight: "20px",
                                textAlign: "center",
                                letterSpacing: "0.15px",
                                color: textDark,
                            })}
                        >
                            No rows added
                        </div>
                        <div
                            css={css({
                                fontWeight: 400,
                                fontSize: "14px",
                                lineHeight: "20px",
                                textAlign: "center",
                                letterSpacing: "0.1px",
                                color: gray700,
                                marginTop: "10px",
                                marginBottom: "12px",
                            })}
                        >
                            To get started add
                        </div>
                    </TableCell>
                </TableRow>
            );
        }
        return rows.map((row, i) => {
            prepareRow(row);
            return (
                <TableRow
                    sx={{
                        minHeight: "68px",
                        boxShadow: grayBoxShadow,
                        position: "relative",
                    }}
                    {...row.getRowProps()}
                >
                    {row.cells.map((cell, index, arr) => {
                        return (
                            <Fragment key={index}>
                                <TableCell
                                    sx={{
                                        display: "inline-block",
                                        alignItems: "center",
                                        position: "unset",
                                        borderBottom:
                                            "1px solid rgba(224, 224, 224, 1)",
                                        fontWeight: 600,
                                        lineHeight: "20px",
                                        color: textDark,
                                        textOverflow: "ellipsis",
                                        overflow: "hidden",
                                        whiteSpace: "nowrap",
                                        padding: 0,
                                    }}
                                    {...cell.getCellProps()}
                                >
                                    {cell.render("Cell")}
                                </TableCell>
                                {/* {index === arr.length - 1 && (
                                    <span
                                        css={css({
                                            display: "flex",
                                            gap: "12px",
                                            alignItems: "center",
                                            position: "sticky",
                                            zIndex: 1,
                                            right: "100px",
                                            width: 120,
                                            justifyContent: "flex-end",
                                        })}
                                    >
                                        <Button
                                            variant="iconOnly"
                                            size="normal"
                                            color="secondary"
                                            icon={<DeleteOutlineIcon />}
                                            onClick={() => noop}
                                        />
                                    </span>
                                )} */}
                            </Fragment>
                        );
                    })}
                </TableRow>
            );
        });
    };
    return (
        <Fragment>
            <Table
                css={css({
                    backgroundColor: white,
                    height: "400px",
                    display: "block",
                    overflowY: "auto",
                    border: "1px #E2E8F0 solid",
                })}
                {...getTableProps()}
            >
                <TableHead
                    css={css({
                        backgroundColor: blue100,
                        boxShadow: blueBoxShadow,
                        borderRadius: "12px",
                        display: "flex",
                    })}
                >
                    {headerGroups.map((headerGroup) => (
                        <TableRow {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column, index) => (
                                <TableCell
                                    css={css({
                                        color: textDark,
                                        fontWeight: 600,
                                        fontSize: "14px",
                                        borderBottom: "unset",
                                        backgroundColor: blue100,
                                        display: "flex",
                                        alignItems: "center",
                                    })}
                                    {...column.getHeaderProps()}
                                >
                                    {column.render("Header")}
                                    {getColumnTooltip(column)}
                                </TableCell>
                            ))}
                        </TableRow>
                    ))}
                </TableHead>
                <TableBody
                    css={css({
                        display: "flex",
                        flexDirection: "column",
                    })}
                    {...getTableBodyProps()}
                >
                    {renderTableContent()}
                </TableBody>
            </Table>
            <div
                css={css({
                    width: "99.8%",
                    padding: "16px 0px",
                    display: "block",
                    border: "1px #E2E8F0 solid",
                })}
            >
                <span
                    css={css({
                        fontWeight: 600,
                        fontSize: "14px",
                        lineHeight: "20px",
                        letterSpacing: "0.1px",
                        color: hasAllRowsActive ? textDarkDisabled : blue700,
                        cursor: "pointer",
                        padding: "0px 24px",
                    })}
                    onClick={
                        hasAllRowsActive
                            ? noop
                            : () => setRowCount(rowsCount + 1)
                    }
                >
                    + {t.AddEvent}
                </span>
            </div>
        </Fragment>
    );
};

export default CustomTable;
