import React from "react";
import SvgIcon from "@mui/material/SvgIcon";

const SpecIdIcon = (props: any) => {
    return (
        <SvgIcon {...props}>
            <g fill="none">
                <path
                    d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z"
                    stroke="currentColor"
                    strokeWidth="1.9"
                    strokeLinecap="round"
                    strokeLinejoin="bevel"
                />
                <path
                    d="M8.2998 7.30371H9.0498V16.0237H8.2998V7.30371Z"
                    stroke="currentColor"
                />
                <path
                    d="M13.3798 15.9935H11.5498V7.30351H13.3398H13.35L13.3602 7.3031C13.9211 7.28021 14.4804 7.37682 15.001 7.58648C15.1637 7.65379 15.3115 7.75251 15.4362 7.87712C15.5596 8.0005 15.6576 8.14667 15.7248 8.30738C15.9076 8.79463 15.9911 9.31351 15.9702 9.83343L15.9698 9.84347V9.85351V13.3627C15.9681 13.9107 15.8592 14.453 15.6491 14.9592C15.5112 15.2802 15.2654 15.543 14.9544 15.7021C14.4675 15.9193 13.9363 16.0194 13.4035 15.9941L13.3917 15.9935H13.3798ZM13.3056 15.7806L13.3056 15.7806L13.3136 15.7814C13.7219 15.8192 14.1314 15.724 14.4809 15.5099L14.5153 15.4889L14.5458 15.4626C14.8028 15.2417 14.9773 14.9402 15.0409 14.6074L15.0424 14.5994L15.0437 14.5913C15.1049 14.2029 15.1304 13.8097 15.1198 13.4167V9.67394C15.1349 9.30129 15.1046 8.92824 15.0296 8.56297L15.0297 8.56295L15.0278 8.55473C14.9531 8.2194 14.7486 7.92725 14.459 7.74219L14.4323 7.72509L14.4036 7.71152C14.0668 7.55221 13.6952 7.48073 13.3235 7.50351H12.8098H12.3098V8.00351V15.2235V15.6719L12.7556 15.7206L13.3056 15.7806Z"
                    stroke="currentColor"
                />
            </g>
        </SvgIcon>
    );
};

export default SpecIdIcon;
