/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from "@emotion/react";

import { useContext, SyntheticEvent } from "react";
import ThemeContext from "../../context/theme/themeContext";
import DeleteCircleIcon from "../../assets/icons/DeleteCircleIcon";
import { noop } from "../../utils/helpers";

type TagSizes = "medium" | "small" | "tiny";
type TagColors = "primary" | "white";

interface TagProps {
    size: TagSizes;
    color: TagColors;
    title: string;
    isSelected?: boolean;
    onClick?: () => void;
    onDelete?: () => void;
}

const Tag = ({
    size,
    color,
    title,
    isSelected,
    onClick,
    onDelete,
}: TagProps) => {
    const {
        colors: {
            white,
            gray100,
            gray200,
            gray700,
            blue100,
            blue200,
            blue400,
            blue700,
            blue800,
            blue900,
            textDark,
        },
    } = useContext(ThemeContext);

    const handleClick = (e: SyntheticEvent) => {
        e.stopPropagation();
        onClick && onClick();
    };

    const handleDelete = (e: SyntheticEvent) => {
        e.stopPropagation();
        onDelete && onDelete();
    };

    const styleVariants = {
        colors: {
            primary: {
                backgroundRest: isSelected ? blue700 : blue100,
                backgroundHover: isSelected ? blue800 : blue200,
                backgroundActive: isSelected ? blue900 : blue400,
                textRest: isSelected ? white : blue700,
                textActive: isSelected ? white : blue700,
                border: isSelected ? blue700 : blue100,
                icon: isSelected ? white : blue700,
            },
            white: {
                backgroundRest: white,
                backgroundHover: gray100,
                backgroundActive: gray200,
                textRest: gray700,
                textActive: textDark,
                border: gray200,
                icon: gray700,
            },
        },
        paddings: {
            medium: "5px 8px",
            small: "1px 8px",
            tiny: onDelete ? "1px 4px 1px 8px" : "1px 8px",
        },
    };

    const bgColorRest = styleVariants.colors[color].backgroundRest;
    const bgColorHover = styleVariants.colors[color].backgroundHover;
    const bgColorActive = styleVariants.colors[color].backgroundActive;
    const textColorRest = styleVariants.colors[color].textRest;
    const textColorActive = styleVariants.colors[color].textActive;
    const borderColor = styleVariants.colors[color].border;
    const iconColor = styleVariants.colors[color].icon;

    const padding = styleVariants.paddings[size];

    const fontSize = size === "tiny" ? "12px" : "14px";
    const lineHeight = size === "tiny" ? "16px" : "20px";
    const letterSpacing = size === "tiny" ? "0.4px" : "0.1px";

    return (
        <div
            onClick={onClick ? handleClick : noop}
            css={css({
                display: "inline-flex",
                alignItems: "center",
                border: `1px solid ${borderColor}`,
                borderRadius: "4px",
                cursor: "pointer",
                transition: "all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
                backgroundColor: bgColorRest,
                padding,

                "&:hover": {
                    backgroundColor: bgColorHover,
                },

                "&:active": {
                    backgroundColor: onClick ? bgColorActive : bgColorHover,
                },

                "& > svg": {
                    color: iconColor,
                    fontSize: "16px",
                    marginLeft: "8px",
                    transition: "all 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",

                    "&:hover": {
                        color: isSelected ? iconColor : blue800,
                    },
                },
            })}
        >
            <span
                css={css({
                    fontWeight: "600",
                    color: textColorRest,
                    fontSize,
                    lineHeight,
                    letterSpacing,

                    "&:active": {
                        color: textColorActive,
                    },
                })}
            >
                {title}
            </span>

            {onDelete && <DeleteCircleIcon onClick={handleDelete} />}
        </div>
    );
};

export default Tag;
