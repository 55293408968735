import { FirmwareType } from "../../constants/constants";
import { addOrRemoveStringFromArray } from "../../utils/helpers";
import {
    RESET_PROGRESS_BARS,
    SET_ACTIVE_DALLAS_SENSOR_VALUES,
    SET_ACTIVE_LLS_SENSOR_VALUES,
    SET_BUNDLE_MODAL_PROGRESS_STATES,
    SET_BUNDLE_PROGRESS,
    SET_DALLAS_SENSOR_DATA,
    SET_DUMP_READING_DATA,
    SET_LLS_SENSOR_DATA,
    SET_PROGRESS_BAR,
    SET_UPDATE_BUNDLE_MODAL_INFO,
    SET_UPDATE_FIRMWARE_MODAL_INFO,
} from "./deviceStatusActions";
import { BundleProgress, UpdateBundleModalInfo } from "./deviceStatusContext";
import { InitialState } from "./DeviceStatusState";

type Action =
    | { type: typeof SET_DUMP_READING_DATA; payload: any }
    | { type: typeof SET_DALLAS_SENSOR_DATA; payload: string[] }
    | { type: typeof SET_LLS_SENSOR_DATA; payload: string[] }
    | { type: typeof SET_ACTIVE_DALLAS_SENSOR_VALUES; payload: string }
    | { type: typeof SET_ACTIVE_LLS_SENSOR_VALUES; payload: string }
    | {
          type: typeof SET_PROGRESS_BAR;
          payload: {
              progressBarName: string;
              completedPercent: number;
          };
      }
    | {
          type: typeof SET_UPDATE_FIRMWARE_MODAL_INFO;
          payload: {
              isOpen: boolean;
              path: string;
              step: number;
              newVersion: string;
              firmwareType: FirmwareType;
              currentVersion: string;
              error: string;
              stepWithError?: number;
          };
      }
    | {
          type: typeof RESET_PROGRESS_BARS;
      }
    | {
          type: typeof SET_BUNDLE_PROGRESS;
          payload: BundleProgress;
      }
    | {
          type: typeof SET_UPDATE_BUNDLE_MODAL_INFO;
          payload: UpdateBundleModalInfo;
      }
    | {
          type: typeof SET_BUNDLE_MODAL_PROGRESS_STATES;
          payload: {
              progress: BundleProgress | null;
              steps: any;
          };
      };

export default (state: InitialState, action: Action): InitialState => {
    switch (action.type) {
        case SET_UPDATE_FIRMWARE_MODAL_INFO:
            return {
                ...state,
                updateFirmwareModalInfo: action.payload,
            };
        case SET_BUNDLE_MODAL_PROGRESS_STATES:
            const bundleProgress = action.payload.progress;
            const steps = action.payload.steps;
            const details = steps.map((step: any) => {
                const progressBarName = `firmware_bundle_${step.mcuType}_${step.fileType}`;

                const currentProgress = state.bundleModalProgressStates
                    ? state.bundleModalProgressStates.find(
                          (detail) => detail.progressBarName === progressBarName
                      )?.progress
                    : null;

                const newProgress = () => {
                    if (bundleProgress?.progressBarName === progressBarName) {
                        if (currentProgress) {
                            // Merge the new progress with the existing progress
                            return {
                                ...currentProgress,
                                [bundleProgress.stage]: {
                                    ...bundleProgress,
                                    completedPercent:
                                        bundleProgress.completedPercent || 0,
                                },
                                current: {
                                    ...bundleProgress,
                                    completedPercent:
                                        bundleProgress.completedPercent || 0,
                                },
                            };
                        } else {
                            // Create a new progress object
                            return {
                                [bundleProgress.stage]: {
                                    ...bundleProgress,
                                    completedPercent:
                                        bundleProgress.completedPercent || 0,
                                },
                                current: {
                                    ...bundleProgress,
                                    completedPercent:
                                        bundleProgress.completedPercent || 0,
                                },
                            };
                        }
                    } else if (currentProgress) {
                        return { ...currentProgress };
                    } else {
                        return null;
                    }
                };

                const progress = newProgress();

                return {
                    ...step,
                    progressBarName,
                    progress,
                    details: {
                        upload: {
                            status: progress?.FileUpload?.status || "Waiting",
                        },
                        update: {
                            status: progress?.DeviceReboot?.status || "Waiting",
                        },
                        current: {
                            status: progress?.current?.status || "Waiting",
                            completedPercent:
                                progress?.current?.completedPercent || 0,
                            stage: progress?.current?.stage || "",
                        },
                    },
                };
            });

            return {
                ...state,
                bundleModalProgressStates: details,
            };
        case SET_BUNDLE_PROGRESS:
            return {
                ...state,
                bundleProgress: action.payload,
            };
        case SET_UPDATE_BUNDLE_MODAL_INFO:
            return {
                ...state,
                updateBundleModalInfo: {
                    ...state.updateBundleModalInfo,
                    ...action.payload,
                },
            };
        case SET_DUMP_READING_DATA:
            return {
                ...state,
                dumpData: {
                    ...state.dumpData,
                    ...action.payload,
                },
            };
        case SET_PROGRESS_BAR:
            return {
                ...state,
                progressBars: {
                    ...state.progressBars,
                    [action.payload.progressBarName]:
                        action.payload.completedPercent,
                },
            };
        case SET_DALLAS_SENSOR_DATA:
            return {
                ...state,
                dallasSensorData: action.payload,
            };
        case SET_LLS_SENSOR_DATA:
            return {
                ...state,
                llsSensorData: action.payload,
            };
        case SET_ACTIVE_LLS_SENSOR_VALUES: {
            return {
                ...state,
                activeLlsSensorValues: addOrRemoveStringFromArray(
                    state.activeLlsSensorValues,
                    action.payload
                ),
            };
        }
        case SET_ACTIVE_DALLAS_SENSOR_VALUES: {
            return {
                ...state,
                activeDallasSensorValues: addOrRemoveStringFromArray(
                    state.activeDallasSensorValues,
                    action.payload
                ),
            };
        }
        case RESET_PROGRESS_BARS: {
            return {
                ...state,
                progressBars: {},
            };
        }
        default:
            return state;
    }
};
