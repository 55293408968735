/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from "@emotion/react";

import { useContext } from "react";
import ThemeContext from "../../../context/theme/themeContext";
import useMediaQueries from "../../../utils/useMediaQueries";
import NavigationListButton from "../../MuiComponents/NavigationListButton";
import CircularProgress from "@mui/material/CircularProgress";

const SidebarSkeleton = () => {
    const {
        colors: { blueSideNavigation, white },
    } = useContext(ThemeContext);

    const { fromMd } = useMediaQueries();

    return (
        <div
            css={css({
                width: "236px",
                padding: "16px",
                background: blueSideNavigation,
                boxSizing: "border-box",

                "@media (max-width: 839px)": {
                    width: "100%",
                    padding: "8px 16px",
                    display: "flex",
                    alignItems: "center",
                },
            })}
        >
            <div
                css={css({
                    "@media (max-width: 839px)": {
                        flex: "1",
                        marginLeft: "16px",
                    },
                })}
            >
                <NavigationListButton
                    fullWidth
                    isActive
                    idForTest={"sidebarSkeleton"}
                    icon={
                        <div
                            css={css({
                                width: "24px",
                                height: "24px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                marginRight: "8px",
                            })}
                        >
                            <CircularProgress
                                size={20}
                                css={css({
                                    color: white,
                                })}
                            />
                        </div>
                    }
                    css={css({
                        margin: fromMd ? "4px 0" : "0",
                        pointerEvents: "none",
                    })}
                >
                    Loading
                </NavigationListButton>
            </div>
        </div>
    );
};

export default SidebarSkeleton;
