import { SearchResult } from "../../generatedTypes";
import {
    SET_LOADING,
    OPEN_MODAL,
    CLOSE_MODAL,
    SET_RESULTS,
    SELECT_SEARCH_RESULT,
    CLEAR_INPUT,
} from "./searchActions";
import { spreadOutResults } from "./searchHelpers";

export interface InitialState {
    isOpenModal: boolean;
    isMobileSearchInput: boolean;
    results: SpreadedSearchResults | null;
    historyResults: SearchResult[] | null;
    searchedItemFrameIndex: number | null;
    searchPhrase: string;
    isLoading: boolean;
    isInputClear: boolean;
}

export interface SpreadedSearchResults {
    parameters: SearchResult[];
    sections: SearchResult[];
}

type Action =
    | { type: typeof SET_LOADING; payload: boolean }
    | { type: typeof OPEN_MODAL }
    | { type: typeof CLOSE_MODAL }
    | {
          type: typeof SET_RESULTS;
          payload: { data: SearchResult[] | null; searchValue: string };
      }
    | { type: typeof SELECT_SEARCH_RESULT; payload: SearchResult }
    | { type: typeof CLEAR_INPUT };

export default (state: InitialState, action: Action): InitialState => {
    switch (action.type) {
        case SET_LOADING: {
            return {
                ...state,
                isLoading: action.payload,
            };
        }
        case OPEN_MODAL: {
            return {
                ...state,
                isOpenModal: true,
                isMobileSearchInput: true,
                results: null,
                searchPhrase: "",
                isLoading: false,
                isInputClear: false,
            };
        }
        case CLOSE_MODAL: {
            return {
                ...state,
                isOpenModal: false,
                isMobileSearchInput: false,
            };
        }
        case SET_RESULTS: {
            const { data, searchValue } = action.payload;

            const updatedResults = spreadOutResults(data);

            return {
                ...state,
                results: updatedResults,
                searchPhrase: searchValue,
                isLoading: false,
                isInputClear: false,
            };
        }
        case SELECT_SEARCH_RESULT: {
            const result = action.payload;

            const alreadyExists = state.historyResults
                ? state.historyResults.some(
                      (currentResult) => currentResult === result
                  )
                : false;

            const getUpdatedHistoryResults = () => {
                if (alreadyExists) {
                    return state.historyResults;
                } else {
                    const currentResults = state.historyResults
                        ? state.historyResults
                        : [];

                    const updatedResults = [...currentResults, result];

                    localStorage.setItem(
                        "searchHistory",
                        JSON.stringify(updatedResults)
                    );

                    return updatedResults;
                }
            };

            const updatedHistoryResults = getUpdatedHistoryResults();

            return {
                ...state,
                isOpenModal: false,
                isMobileSearchInput: false,
                historyResults: updatedHistoryResults,
            };
        }
        case CLEAR_INPUT: {
            return {
                ...state,
                results: null,
                searchPhrase: "",
                isInputClear: true,
            };
        }
        default:
            return state;
    }
};
