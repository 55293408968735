import {
    HANDLE_CURRENT_PIN,
    SET_LOADING,
    WRONG_CURRENT_PIN,
} from "./pinActions";
import LanguageContext from "../../../../../../../context/language/languageContext";
import { useContext } from "react";

export interface InitialState {
    isLoading: boolean;
    isDisabled: boolean;
    currentPin: string | null;
    errorMsg: string | null;
    retriesLeft: number | null;
}

type Action =
    | {
          type: typeof SET_LOADING;
          payload: boolean;
      }
    | {
          type: typeof HANDLE_CURRENT_PIN;
          payload: string | null;
      }
    | {
          type: typeof WRONG_CURRENT_PIN;
          payload: number;
      };

export default (state: InitialState, action: Action) => {
    const { t } = useContext(LanguageContext);
    switch (action.type) {
        case SET_LOADING: {
            return {
                ...state,
                isLoading: action.payload,
                errorMsg: action.payload ? null : state.errorMsg,
                retriesLeft: action.payload ? null : state.retriesLeft,
            };
        }
        case HANDLE_CURRENT_PIN: {
            const { payload } = action;

            return {
                ...state,
                isDisabled: !Boolean(payload),
                currentPin: payload,
            };
        }
        case WRONG_CURRENT_PIN: {
            return {
                ...state,
                isLoading: false,
                errorMsg: t.IncorrectPinCode,
                retriesLeft: action.payload - 1,
            };
        }
        default:
            return state;
    }
};
