import React from "react";
import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

const InterfaceIcon = (props: SvgIconProps) => {
    return (
        <SvgIcon {...props} viewBox="0 0 22 18">
            <path
                d="M11 12L12.4 8.9L15.5 7.5L12.4 6.1L11 3L9.6 6.1L6.5 7.5L9.6 8.9L11 12ZM3 15C2.45 15 1.97933 14.8043 1.588 14.413C1.196 14.021 1 13.55 1 13V2C1 1.45 1.196 0.979 1.588 0.587C1.97933 0.195667 2.45 0 3 0H19C19.55 0 20.021 0.195667 20.413 0.587C20.8043 0.979 21 1.45 21 2V13C21 13.55 20.8043 14.021 20.413 14.413C20.021 14.8043 19.55 15 19 15H3ZM3 13H19V2H3V13ZM0 18V16H22V18H0ZM3 13V2V13Z"
                fill="#334155"
            />
        </SvgIcon>
    );
};

export default InterfaceIcon;
