import React from "react";
import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

const FileIcon = (props: SvgIconProps) => {
    return (
        <SvgIcon viewBox="0 0 24 24" {...props}>
            <path
                d="M18 22H6C5.45 22 4.97933 21.8043 4.588 21.413C4.196 21.021 4 20.55 4 20V4C4 3.45 4.196 2.979 4.588 2.587C4.97933 2.19567 5.45 2 6 2H14L20 8V20C20 20.55 19.8043 21.021 19.413 21.413C19.021 21.8043 18.55 22 18 22ZM13 9V4H6V20H18V9H13ZM6 9V4V20V9ZM8 13H16V11H8V13ZM8 16H16V14H8V16ZM8 19H13V17H8V19Z"
                fill="#697D95"
            />
        </SvgIcon>
    );
};

export default FileIcon;
