/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from "@emotion/react";
import {
    Checkbox,
    InputLabel,
    ListItemIcon,
    ListItemText,
    MenuItem,
    Popover,
} from "@mui/material";
import { Fragment, useContext, useState } from "react";
import FilterIcon from "../../../../../../../../assets/icons/FilterIcon";
import { IoTableColumns } from "../../../../../../../../constants/constants";
import InputOutputContext from "../../../../../../../../context/inputOutput/inputOutputContext";
import ThemeContext from "../../../../../../../../context/theme/themeContext";
import OperandSelect from "./OperandSelect";
import PrioritySelect from "./PrioritySelect";
import LanguageContext from "../../../../../../../../context/language/languageContext";
import LayoutContext from "../../../../../../../../context/layout/layoutContext";

const MoreFiltersSelect = () => {
    const { setActiveFilter, activeFilters, resetFilter } =
        useContext(InputOutputContext);
    const {
        colors: { gray200, gray100 },
    } = useContext(ThemeContext);
    const { t } = useContext(LanguageContext);
    const { layoutData } = useContext(LayoutContext);

    const [menuAnchorEl, setMenuAnchorEl] = useState<HTMLDivElement | null>(
        null
    );
    const hasRowsWithCurrentValues = activeFilters.some(
        (item) => item === IoTableColumns.currentValue
    );

    return (
        <Fragment>
            <InputLabel
                css={css({
                    position: "unset",
                    display: "inline-flex",
                    marginLeft: "24px",
                })}
                onClick={(e) => setMenuAnchorEl(e.currentTarget as any)}
            >
                <div
                    css={css({
                        display: "flex",
                        justifyContent: "end",
                        alignItems: "center",
                        padding: "8px 12px",
                        cursor: "pointer",
                        position: "relative",
                        borderRadius: "6px",
                        "&:hover": {
                            backgroundColor: gray100,
                        },
                        "&:active": {
                            backgroundColor: gray200,
                        },
                    })}
                >
                    <FilterIcon css={css({ width: "16px", height: "16px" })} />
                    <div
                        css={css({
                            marginLeft: "12px",
                            fontWeight: 600,
                            fontSize: "14px",
                            lineHeight: "20px",
                            letterSpacing: "0.1px",
                        })}
                    >
                        More filters
                    </div>
                </div>
            </InputLabel>
            <Popover
                onClose={() => setMenuAnchorEl(null)}
                open={!!menuAnchorEl}
                anchorEl={menuAnchorEl}
                anchorPosition={{ top: 0, left: 0 }}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                }}
                sx={{
                    "& .MuiPaper-root": {
                        padding: "16px",
                        maxWidth: "211px",
                        width: "100%",
                    },
                }}
            >
                <div css={css({ marginBottom: "16px" })}>Filters</div>
                <PrioritySelect />
                <OperandSelect />
                {layoutData?.isOnline && (
                    <MenuItem
                        css={{ padding: "unset" }}
                        onClick={() =>
                            hasRowsWithCurrentValues
                                ? resetFilter(IoTableColumns.currentValue)
                                : setActiveFilter(IoTableColumns.currentValue)
                        }
                    >
                        <ListItemIcon>
                            <Checkbox checked={hasRowsWithCurrentValues} />
                        </ListItemIcon>
                        <ListItemText
                            primary={t.CurrentValuesFilter}
                            sx={{
                                ".MuiListItemText-primary": {
                                    fontWeight: "600",
                                    fontSize: "14px",
                                },
                            }}
                        />
                    </MenuItem>
                )}
            </Popover>
        </Fragment>
    );
};

export default MoreFiltersSelect;
