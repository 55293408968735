/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import { Checkbox, ListItemIcon, ListItemText, MenuItem } from "@mui/material";
import { useContext } from "react";
import {
    IoTableColumns,
    operandColumns,
} from "../../../../../../../../constants/constants";
import InputOutputContext from "../../../../../../../../context/inputOutput/inputOutputContext";
import Select from "../../../../../../../MuiComponents/Select";
import LayoutContext from "../../../../../../../../context/layout/layoutContext";
import { DeviceFamily } from "../../../../../../../../utils/types";

const OperandSelect = () => {
    const { setActiveOperand, activeOperand, activeColumns } =
        useContext(InputOutputContext);
    const { layoutData } = useContext(LayoutContext);

    const handleChange = (event: any) => {
        const value = event.target.value;
        setActiveOperand(value);
    };
    const isCheckboxChecked = (value: string) => activeOperand.includes(value);
    const isDisabled = !activeColumns.some(
        (item) => item === IoTableColumns.operand
    );

    const isFtcDeviceFamily =
        !!layoutData?.deviceFamily &&
        (layoutData?.deviceFamily as unknown) === DeviceFamily.FTC;

    return (
        <Select
            multiple
            value={activeOperand}
            renderValue={(selected) => {
                const renderNames = selected.map(
                    (item: string) =>
                        operandColumns(isFtcDeviceFamily)[Number(item)].name
                );
                return <div>{renderNames.join(", ")}</div>;
            }}
            onChange={handleChange}
            wrapperStyle={css({ marginBottom: "12px" })}
            placeholder="Operand"
            disabled={isDisabled}
        >
            {operandColumns(isFtcDeviceFamily).map(
                (column: { name: string; value: string }) => (
                    <MenuItem
                        key={column.name}
                        value={column.value}
                        css={{ padding: "14px 2px 14px 16px" }}
                    >
                        <ListItemText
                            primary={column.name}
                            sx={{
                                ".MuiListItemText-primary": {
                                    fontWeight: "600",
                                    fontSize: "14px",
                                },
                            }}
                        />
                        <ListItemIcon>
                            <Checkbox
                                checked={isCheckboxChecked(column.value)}
                            />
                        </ListItemIcon>
                    </MenuItem>
                )
            )}
        </Select>
    );
};

export default OperandSelect;
