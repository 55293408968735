/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import { Box, Typography } from "@mui/material";
import Button from "../../../../MuiComponents/Button";
import { useContext } from "react";
import LanguageContext from "../../../../../context/language/languageContext";
import SettingsContext from "../../../../../context/settings/settingsContext";
import useApi from "../../../../../utils/useApi";

const UpdateButton = () => {
    const { t, selectedLanguage } = useContext(LanguageContext);

    const {
        setNotification,
        setUpdateDownloading,
        downloadingPercentage,
        updateInformation: { isUpdateDownloading, isUpdateDownloaded },
    } = useContext(SettingsContext);
    const { postData } = useApi();
    const renderControlText = () => {
        if (isUpdateDownloading) {
            return t.Downloading;
        } else if (isUpdateDownloaded) {
            return t.RestartAndInstall;
        }
        return t.Update;
    };
    const handleUpdate = async () => {
        try {
            if (isUpdateDownloaded) {
                await postData(
                    `${selectedLanguage}/autoupdate/restartandinstall`
                );
            } else {
                setUpdateDownloading(true);
                await postData(
                    `${selectedLanguage}/autoupdate/startdownloading`
                );
            }
        } catch (error) {
            setUpdateDownloading(false);
            setNotification("error", t.Error, t.ErrorWhileStartingDownload);
        }
    };

    return isUpdateDownloading ? (
        <Box display="flex" justifyContent="right" alignItems="center">
            <Typography
                fontWeight="bold"
                variant="caption"
                component="span"
                paddingRight="2px"
            >
                {renderControlText()}
            </Typography>
            <Box width="32px">
                <Typography variant="caption" fontWeight="bold">
                    {downloadingPercentage || 0}%
                </Typography>
            </Box>
        </Box>
    ) : (
        <Button
            variant={isUpdateDownloading ? "iconLeft" : "textOnly"}
            size="small"
            color="primary"
            css={css({ marginLeft: "8px" })}
            onClick={handleUpdate}
            disabled={isUpdateDownloading}
            idForTesting="updateButton"
        >
            {renderControlText()}
        </Button>
    );
};

export default UpdateButton;
