/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from "@emotion/react";

import { Fragment, useContext, useReducer, useRef } from "react";
import ThemeContext from "../../../../../../../context/theme/themeContext";
import LanguageContext from "../../../../../../../context/language/languageContext";
import AlertContext from "../../../../../../../context/alert/alertContext";
import useApi from "../../../../../../../utils/useApi";
import changePinReducer from "./changePinReducer";
import DeviceLocked from "../DeviceLocked";
import CodeInput from "../CodeInput";
import Button from "../../../../../../MuiComponents/Button";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import CircularProgress from "@mui/material/CircularProgress";
import {
    GO_TO_NEXT_VIEW,
    HANDLE_CURRENT_PIN,
    HANDLE_NEW_PIN,
    HANDLE_NEW_REPEATED_PIN,
    SET_LOADING,
    WRONG_CURRENT_PIN,
} from "./changePinActions";
import { SimPinModalTypes } from "../index";
import { AlertStatus } from "../../../../../../../constants/constants";

interface ChangePinProps {
    simId: number;
    close: () => void;
}

interface OnSuccess {
    isSuccess: boolean;
    retriesLeft: number;
}

const ChangePin = ({ simId, close }: ChangePinProps) => {
    const {
        colors: { white },
    } = useContext(ThemeContext);

    const { selectedLanguage, t } = useContext(LanguageContext);
    const { setAlert } = useContext(AlertContext);

    const { postData, showErrorMsg } = useApi();

    const submitBtnRef = useRef<HTMLButtonElement>(null);

    const initialState = {
        isLoading: false,
        isDisabled: true,
        step: 1,
        currentPin: null,
        newPin: null,
        newRepeatedPin: null,
        explanatoryText: t.EnterCurrentCode,
        submitButtonText: t.Continue,
        errorMsg: null,
        retriesLeft: null,
    };

    const [state, dispatch] = useReducer(changePinReducer, initialState);

    const {
        isLoading,
        isDisabled,
        step,
        currentPin,
        newRepeatedPin,
        explanatoryText,
        submitButtonText,
        errorMsg,
        retriesLeft,
    } = state;

    const setLoading = (isLoadingSet: boolean) =>
        dispatch({ type: SET_LOADING, payload: isLoadingSet });

    const handlePin = (value: string | null) => {
        switch (step) {
            case 1:
                dispatch({ type: HANDLE_CURRENT_PIN, payload: value });
                break;
            case 2:
                dispatch({ type: HANDLE_NEW_PIN, payload: value });
                break;
            default:
                dispatch({ type: HANDLE_NEW_REPEATED_PIN, payload: value });
        }
    };

    const checkCurrentPin = () => {
        const onSuccess = (response: OnSuccess) => {
            if (response.isSuccess) {
                dispatch({ type: GO_TO_NEXT_VIEW });
            } else {
                dispatch({
                    type: WRONG_CURRENT_PIN,
                    payload: response.retriesLeft,
                });
            }
        };

        if (currentPin) {
            sendPayload(currentPin, currentPin, onSuccess);
        }
    };

    const sendBothPins = () => {
        const onSuccess = (response: OnSuccess) => {
            if (response.isSuccess) {
                setAlert(AlertStatus.Success, t.PinSuccessfullyChanged);
                close();
            } else {
                setAlert(AlertStatus.Critical, t.ErrorOccurredChangingPin);
            }
        };

        if (currentPin && newRepeatedPin) {
            sendPayload(currentPin, newRepeatedPin, onSuccess);
        }
    };

    const handleSubmitBtn = () => {
        switch (step) {
            case 1:
                checkCurrentPin();
                break;
            case 2:
                dispatch({ type: GO_TO_NEXT_VIEW });
                break;
            default:
                sendBothPins();
        }
    };

    const sendPayload = async (
        currentCode: string,
        newCode: string,
        successPayload: (response: OnSuccess) => void
    ) => {
        try {
            setLoading(true);

            const { data } = await postData(
                `${selectedLanguage}/command/changesimpin`,
                { simId: simId, currentPin: currentCode, newPin: newCode }
            );

            successPayload &&
                successPayload({
                    isSuccess: data.success,
                    retriesLeft: data.retriesLeft,
                });
        } catch (error) {
            showErrorMsg(error as any);
        }

        setLoading(false);
    };

    const renderButtonText = () =>
        isLoading ? (
            <CircularProgress
                size={20}
                css={css({
                    color: white,
                })}
            />
        ) : (
            submitButtonText
        );

    return retriesLeft && retriesLeft + 1 <= 1 ? (
        <DeviceLocked type={SimPinModalTypes.ChangePin} close={close} />
    ) : (
        <Fragment>
            <DialogTitle>{t.ChangePinCode}</DialogTitle>

            <DialogContent>
                <CodeInput
                    error={errorMsg}
                    retriesLeft={retriesLeft}
                    explanatoryText={explanatoryText}
                    step={step}
                    setPayloadValue={handlePin}
                />
            </DialogContent>

            <DialogActions
                sx={{
                    "&.MuiDialogActions-root>:not(:first-of-type)": {
                        marginLeft: "12px",
                    },
                }}
            >
                <Button
                    variant="textOnly"
                    size="normal"
                    color="secondary"
                    fullWidth
                    onClick={close}
                    idForTesting="cancelBtn"
                >
                    {t.Cancel}
                </Button>

                <Button
                    ref={submitBtnRef}
                    variant="textOnly"
                    size="normal"
                    color="primary"
                    fullWidth
                    disabled={isDisabled}
                    onClick={handleSubmitBtn}
                    css={css({
                        pointerEvents: isLoading ? "none" : "auto",
                    })}
                    idForTesting="submitBtn"
                >
                    {renderButtonText()}
                </Button>
            </DialogActions>
        </Fragment>
    );
};

export default ChangePin;
