/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import TypedGrid from "../../../../TypedGrid";
import FirmwareWithVersions from "../FirmwareWithVersions";
import { FirmwareBundleUpdateStep } from "../../../../../../generatedTypes";

export interface CustomCardContentProps {
    firmwareSteps: FirmwareBundleUpdateStep[];
    dtbSteps: FirmwareBundleUpdateStep[];
}

const CustomCardContent: React.FunctionComponent<CustomCardContentProps> = ({
    firmwareSteps,
    dtbSteps,
}) => {
    return (
        <div
            css={css({
                display: "flex",
                marginTop: "16px",
            })}
        >
            <TypedGrid xs0={6} xl={6} item>
                {firmwareSteps.map((step, index) => (
                    <FirmwareWithVersions
                        title={`${step.mcuType} ${step.fileType}:`}
                        currentVersion={step.currentVersion}
                        newVersion={step.newVersion}
                        key={`${step.mcuType} ${step.fileType}`}
                    />
                ))}
            </TypedGrid>
            <TypedGrid xs0={6} xl={6} item>
                {dtbSteps.map((step, index) => (
                    <FirmwareWithVersions
                        title={`${step.mcuType} ${step.fileType}:`}
                        currentVersion={step.currentVersion}
                        newVersion={step.newVersion}
                        key={`${step.mcuType} ${step.fileType}`}
                    />
                ))}
            </TypedGrid>
        </div>
    );
};

export default CustomCardContent;
