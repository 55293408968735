const useSettings = () => {
    const isEmpty = (value: string) => value.trim() === "";

    const validNumericValue = (
        value: string,
        minValue: number,
        maxValue: number
    ) => Number(value) >= minValue && Number(value) <= maxValue;

    const loadConfigurationValueIsWrong = (
        loadStatus: string,
        path: string
    ) => {
        if (loadStatus === "FromFile") {
            return isEmpty(path);
        } else {
            return false;
        }
    };

    return {
        isEmpty,
        validNumericValue,
        loadConfigurationValueIsWrong,
    };
};

export default useSettings;
