/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from "@emotion/react";

import { ChangeEvent, useContext, useEffect, useRef, useState } from "react";
import MenuItemContext from "../../../../../context/menuItem/menuItemContext";
import useNavigation from "../../../../../utils/useNavigation";
import TooltipLarge from "../../../../MuiComponents/TooltipLarge";
import InputField from "../../../../MuiComponents/InputField";
import Tooltip from "../../../../MuiComponents/Tooltip";
import Tag from "../../../../MuiComponents/Tag";
import { validateTextLengthAndRegex } from "../../../../../utils/validations";
import { getIdTooltipText } from "../../../../../utils/helpers";
import { Component, SearchPath } from "../../../../../generatedTypes";
import SettingsContext from "../../../../../context/settings/settingsContext";
import LayoutContext from "../../../../../context/layout/layoutContext";
import useDebounce from "../../../../../utils/useDebounce";
import LanguageContext from "../../../../../context/language/languageContext";
import PhoneNumberInput from "../../../../MuiComponents/PhoneNumberInput";
import { defaultListLanguageFlag } from "../../../../../constants/constants";

export interface TextBoxProps {
    data: Component;
    onChange?: (e: { id: number; value: string }, error?: any) => void;
    onError?: (error: string | null) => void;
    multiline?: boolean;
}

const TextBox = ({ data, onChange, onError, multiline }: TextBoxProps) => {
    const {
        label,
        parameterId,
        parameterValue,
        renderType,
        avlId,
        placeholder,
        isDisabled,
        tooltip = "",
        disabledTooltip,
        textMinLength,
        textMaxLength,
        validate,
        validationMessage,
        index,
    } = data;

    const { setActiveDeviceGuideStep, setDrawerParameterId } =
        useContext(LayoutContext);
    const {
        updateParameter,
        findDisabledParameterById,
        setParameterError,
        findParameterValueById,
        setDisabledParameters,
    } = useContext(MenuItemContext);
    const { isParameterIdsHidden, setDeviceGuideButtonDisabled } =
        useContext(SettingsContext);

    const { t, selectedLanguage } = useContext(LanguageContext);

    const isTextBoxDisabled =
        findDisabledParameterById(parameterId) !== undefined
            ? findDisabledParameterById(parameterId)
            : isDisabled;

    const debouncedChangeHandler = useDebounce(updateParameter, 500);
    const { handleSearchNavigation } = useNavigation();

    const paramValFromContext = findParameterValueById(parameterId);

    const [value, setValue] = useState(parameterValue);
    const [previousValue, setPreviousValue] = useState(parameterValue);

    const [error, setError] = useState<string | null>(null);
    const [loading, setLoading] = useState(false);
    const [requestError, setRequestError] = useState(false);

    const elementRef = useRef(null);

    useEffect(() => {
        setValue(paramValFromContext);
        setPreviousValue(paramValFromContext);
    }, [paramValFromContext]);

    useEffect(() => {
        return () => {
            setActiveDeviceGuideStep(null);
        };
        // eslint-disable-next-line
    }, []);

    const hasBeenMounted = useRef<boolean>(false);

    useEffect(() => {
        if (hasBeenMounted.current) {
            onError && onError(error);
            setParameterError(parameterId, error);
        } else {
            hasBeenMounted.current = true;
        }
        // eslint-disable-next-line
    }, [error]);

    const dependenciesToDisable =
        data.dependentParameterIds &&
        data.dependentParameterIds.length > 0 &&
        data.dependentParameterIds.map((dependant: number) => {
            return {
                parameterId: dependant,
                isDisabled: true,
            };
        });
    const disableDependencies = () => {
        dependenciesToDisable && setDisabledParameters(dependenciesToDisable);
    };

    const onSuccessfulRequest = (val: string) => {
        requestError && setRequestError(false);
        setPreviousValue(val);
        setDeviceGuideButtonDisabled(false);
    };

    const onFailedRequest = () => {
        setRequestError(true);
        setError(null);
        setValue(previousValue);
    };

    const handleValueChange = (newValue: string) => {
        const errorValue = validateTextLengthAndRegex(
            newValue,
            textMinLength,
            textMaxLength,
            validate || "",
            validationMessage || "",
            t
        );
        setError(errorValue);
        setValue(newValue);

        if (onChange) {
            onChange({ id: parameterId, value: newValue }, errorValue);
        } else if (errorValue) {
            setLoading(false);
            debouncedChangeHandler.cancel();
        } else {
            setLoading(true);
            disableDependencies();
            debouncedChangeHandler(
                parameterId,
                newValue,
                label,
                elementRef,
                () => onSuccessfulRequest(newValue),
                onFailedRequest,
                () => setLoading(false),
                true
            );
        }
    };

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value;

        handleValueChange(newValue);
    };

    const handlePhoneChange = (e: string, country?: any): void => {
        handleValueChange(e);
    };

    const deviceGuideSelector = `component${String(index)}`;
    const defaultLocale =
        !selectedLanguage || selectedLanguage === "en"
            ? defaultListLanguageFlag
            : selectedLanguage;

    const renderPhoneNumberInput = () => (
        <PhoneNumberInput
            hasTopPosition={false}
            label={label}
            rightLabel={`ID: ${parameterId}`}
            placeholder={placeholder || ""}
            disabled={isDisabled}
            onChange={handlePhoneChange}
            value={value}
            iconRight={
                !isParameterIdsHidden ? (
                    <Tooltip
                        title={getIdTooltipText(parameterId, 0)}
                        small
                        placement="top"
                    >
                        <span>
                            <Tag size="tiny" color="white" title="ID" />
                        </span>
                    </Tooltip>
                ) : null
            }
            locale={defaultLocale}
        ></PhoneNumberInput>
    );

    const renderInputField = () => (
        <InputField
            id={String(parameterId)}
            multiline={multiline}
            deviceGuideId={deviceGuideSelector}
            ref={elementRef}
            fullWidth
            size="medium"
            label={label}
            iconRight={
                parameterId && !isParameterIdsHidden ? (
                    <Tooltip
                        title={getIdTooltipText(parameterId, avlId)}
                        small
                        placement="top"
                    >
                        <span>
                            <Tag size="tiny" color="white" title="ID" />
                        </span>
                    </Tooltip>
                ) : null
            }
            requesting={loading}
            requestFailed={requestError}
            info={tooltip}
            disabled={isTextBoxDisabled}
            placeholder={placeholder ? placeholder : ""}
            value={value}
            onChange={handleChange}
            error={error}
            wrapperStyle={css({
                scrollMargin: "16px",
            })}
            onInfoIconClick={() => setDrawerParameterId(parameterId)}
        />
    );

    return (
        <TooltipLarge
            title={
                isTextBoxDisabled && disabledTooltip
                    ? disabledTooltip.title
                    : ""
            }
            buttonText={
                disabledTooltip?.btnTitle ? disabledTooltip.btnTitle : ""
            }
            onBtnClick={() =>
                handleSearchNavigation(
                    disabledTooltip as { searchPath: SearchPath }
                )
            }
        >
            <span>
                {renderType === "Phone"
                    ? renderPhoneNumberInput()
                    : renderInputField()}
            </span>
        </TooltipLarge>
    );
};

export default TextBox;
