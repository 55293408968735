/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from "@emotion/react";

import { Fragment, useContext } from "react";
import ThemeContext from "../../../../context/theme/themeContext";
import useMediaQueries from "../../../../utils/useMediaQueries";
import MenuItemSettings from "./MenuItemSettings";
import Info from "./Info";
import UploadFiles from "./UploadFiles";
import Settings from "../../../TopBar/Settings";
import Collapse from "@mui/material/Collapse";
import SettingsContext from "../../../../context/settings/settingsContext";
import LayoutContext from "../../../../context/layout/layoutContext";
import Divider from "../../../MuiComponents/Divider";

export interface SideNavigationProps {
    open: boolean;
    onNavigationItemClick: () => void;
}

const SideNavigation = ({
    open,
    onNavigationItemClick,
}: SideNavigationProps) => {
    const {
        colors: { blueSideNavigation },
    } = useContext(ThemeContext);
    const { isDeviceGuideStepsOpen } = useContext(SettingsContext);
    const { layoutData } = useContext(LayoutContext);
    const isConfigurationSupported =
        layoutData?.isConfigurationSupported ?? false;

    const { toMd } = useMediaQueries();

    return (
        <aside
            css={css({
                width: "236px",
                background: blueSideNavigation,
                boxSizing: "border-box",
                position: "fixed",
                top: 0,
                bottom: 0,
                overflowY: "auto",
                zIndex: 1,
                "&::-webkit-scrollbar": {
                    width: "8px",
                },
                "&::-webkit-scrollbar-thumb": {
                    background: "rgba(255, 255, 255, 0.6)",
                    borderRadius: "100px",
                    border: "unset",
                },

                "&::-webkit-scrollbar-thumb:hover": {
                    backgroundColor: "A0D0FF",
                },
                "@media (max-width: 839px)": {
                    width: "100%",
                    position: "relative",
                    overflowY: "unset",
                    zIndex: "auto",
                },
            })}
        >
            <div
                css={css({
                    background: blueSideNavigation,
                    position: toMd ? "absolute" : "static",
                    top: 0,
                    left: 0,
                    right: 0,
                    zIndex: 999,
                })}
            >
                <Collapse in={open && !isDeviceGuideStepsOpen}>
                    <div
                        css={css({
                            padding: toMd ? "0px 16px 40px" : "18px 16px",
                        })}
                    >
                        <Info />

                        <Divider
                            additionalCss={{
                                margin: "16px 0",
                                background: "rgba(255, 255, 255, 0.12)",
                            }}
                        />

                        <MenuItemSettings
                            onNavigationItemClick={onNavigationItemClick}
                        />

                        {isConfigurationSupported && (
                            <Fragment>
                                <Divider
                                    additionalCss={{
                                        margin: "16px 0",
                                        background: "rgba(255, 255, 255, 0.12)",
                                    }}
                                />
                                <UploadFiles />

                                {toMd && (
                                    <Fragment>
                                        <Divider
                                            additionalCss={{
                                                margin: "16px 0",
                                                background:
                                                    "rgba(255, 255, 255, 0.12)",
                                            }}
                                        />

                                        <Settings
                                            startup={false}
                                            disabled={!isConfigurationSupported}
                                        />
                                    </Fragment>
                                )}
                            </Fragment>
                        )}
                    </div>
                </Collapse>
            </div>
        </aside>
    );
};

export default SideNavigation;
