/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from "@emotion/react";

import { useContext } from "react";
import ThemeContext from "../../../../../context/theme/themeContext";
import AlertInfoIcon from "../../../../../assets/icons/AlertInfoIcon";
import Badge from "@mui/material/Badge";
import LanguageContext from "../../../../../context/language/languageContext";
import SettingsContext from "../../../../../context/settings/settingsContext";
import UpdateButton from "./UpdateButton";
import moment from "moment";
import { TctUpdateAvailableAtom } from "../../../../../context/webSockets/webSocketsState";
import { useAtom } from "jotai";

const SingleUpdateNotification = () => {
    const {
        colors: {
            white,
            blue700,
            blue800,
            gray700,
            gray100,
            textDark,
            textPlaceholder,
        },
    } = useContext(ThemeContext);
    const { t } = useContext(LanguageContext);
    const { openVersionHistoryModal, closeNotificationsModal } =
        useContext(SettingsContext);

    const [{ releaseDate, size, version }, _] = useAtom(TctUpdateAvailableAtom);
    const openUpdateNotes = () => {
        closeNotificationsModal();
        openVersionHistoryModal();
    };

    return (
        <div>
            <div
                css={css({
                    color: textPlaceholder,
                    fontSize: "12px",
                    fontWeight: "600",
                    lineHeight: "16px",
                    letterSpacing: "0.4px",
                    padding: "12px 16px 4px 16px",
                })}
            >
                {t.Update}
            </div>

            <li
                css={css({
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    backgroundColor: white,
                    borderRadius: "3px",
                    padding: "12px 16px",
                })}
            >
                <div
                    css={css({
                        display: "flex",
                    })}
                >
                    <span
                        css={css({
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            width: "24px",
                            height: "24px",
                            background: gray100,
                            borderRadius: "4.8px",
                            marginRight: "8px",
                        })}
                    >
                        <Badge
                            badgeContent=" "
                            variant="dot"
                            sx={{
                                "& .MuiBadge-badge": {
                                    backgroundColor: blue700,
                                    right: "3px",
                                    top: "2px",
                                },
                            }}
                        >
                            <AlertInfoIcon
                                css={css({
                                    fontSize: "16px",
                                    marginRight: "0 !important",

                                    "& > path": {
                                        fill: textPlaceholder,
                                    },
                                })}
                            />
                        </Badge>
                    </span>

                    <div>
                        <div
                            css={css({
                                color: textDark,
                                fontSize: "10px",
                                lineHeight: "12px",
                                fontWeight: "600",
                                letterSpacing: "1px",
                                marginBottom: "4px",
                            })}
                        >
                            {(releaseDate
                                ? moment(releaseDate)
                                : moment()
                            ).format("L LTS")}
                        </div>

                        <div
                            css={css({
                                color: textDark,
                                fontSize: "14px",
                                fontWeight: "600",
                                lineHeight: "20px",
                                letterSpacing: "0.1px",
                            })}
                        >
                            {t.ConfiguratorUpdateAvailable}
                        </div>
                        <div
                            css={css({
                                color: gray700,
                                fontSize: "10px",
                                lineHeight: "12px",
                                letterSpacing: "1px",
                                marginTop: "4px",
                                wordBreak: "break-word",
                            })}
                        >
                            {version}
                            <span> - </span>
                            {size}
                        </div>
                        <div
                            css={css({
                                fontWeight: 600,
                                fontSize: 12,
                                letterSpacing: 0.4,
                                color: blue700,
                                textDecorationLine: "underline",
                                "&:hover": {
                                    textDecorationLine: "none",
                                    cursor: "pointer",
                                    color: blue800,
                                },
                            })}
                            onClick={openUpdateNotes}
                        >
                            {t.SeeWhatsNew}
                        </div>
                    </div>
                </div>

                <UpdateButton />
            </li>
        </div>
    );
};

export default SingleUpdateNotification;
