/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from "@emotion/react";

import { useContext } from "react";
import ThemeContext from "../../../../context/theme/themeContext";
import SettingsContext from "../../../../context/settings/settingsContext";
import LanguageContext from "../../../../context/language/languageContext";
import useMediaQueries from "../../../../utils/useMediaQueries";
import Button from "../../../MuiComponents/Button";
import NotificationsList from "./NotificationsList";
import Popover from "@mui/material/Popover";
import CloseIcon from "@mui/icons-material/CloseRounded";

const NotificationsModal = () => {
    const {
        colors: { gray200, gray100, textDark },
    } = useContext(ThemeContext);

    const {
        isReadTab,
        isNotificationModalOpen,
        anchorEl,
        closeNotificationsModal,
        changeTab,
    } = useContext(SettingsContext);

    const { t } = useContext(LanguageContext);
    const { toMd } = useMediaQueries();

    return (
        <Popover
            open={isNotificationModalOpen}
            anchorEl={anchorEl}
            onClose={closeNotificationsModal}
            anchorReference={toMd ? "anchorPosition" : "anchorEl"}
            anchorPosition={{ top: 0, left: 0 }}
            anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
            }}
            transformOrigin={{
                vertical: "top",
                horizontal: "center",
            }}
            marginThreshold={toMd ? 0 : 16}
            sx={{
                "& .MuiPaper-root": {
                    maxHeight: toMd ? "unset" : "626px",
                    height: toMd ? "100%" : "unset",
                    maxWidth: toMd ? "unset" : "566px",
                    width: toMd ? "100%" : "calc(100% - 284px)",
                    marginTop: toMd ? "0" : "10px",
                    overflowY: toMd ? "auto" : "hidden",
                },
            }}
        >
            <div
                css={css({
                    display: "flex",
                    justifyContent: "space-between",
                    padding: "4px 4px 0 24px",

                    "& > h2": {
                        color: textDark,
                        fontSize: "20px",
                        lineHeight: "24px",
                        fontWeight: "600",
                        letterSpacing: "0.15px",
                        alignSelf: "flex-end",
                    },
                })}
            >
                <h2>{t.Notifications}</h2>

                <Button
                    variant="iconOnly"
                    size="small"
                    color="white"
                    icon={<CloseIcon onClick={closeNotificationsModal} />}
                    idForTesting="closeNotificationsModal"
                />
            </div>

            <div
                css={css({
                    padding: "12px 16px 0 16px",
                })}
            >
                <div
                    css={css({
                        display: "flex",
                        background: gray100,
                        border: `1px solid ${gray200}`,
                        borderRadius: "8px",
                        padding: "3px 4px",

                        "& > button + button": {
                            marginLeft: "4px",
                        },
                    })}
                >
                    <Button
                        fullWidth
                        variant="textOnly"
                        size="tiny"
                        color={isReadTab ? "white" : "secondary"}
                        onClick={() => changeTab(true)}
                        idForTesting="newNotificationsTab"
                    >
                        New
                    </Button>

                    <Button
                        fullWidth
                        variant="textOnly"
                        size="tiny"
                        color={isReadTab ? "secondary" : "white"}
                        onClick={() => changeTab(false)}
                        idForTesting="allNotificationsTab"
                    >
                        All other
                    </Button>
                </div>
            </div>

            <NotificationsList />
        </Popover>
    );
};

export default NotificationsModal;
