/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from "@emotion/react";

import { useContext, useEffect, useState } from "react";
import LanguageContext from "../../../../../context/language/languageContext";
import UserInfoContext from "../../../../../context/userInfo/userInfoContext";
import LayoutContext from "../../../../../context/layout/layoutContext";
import useMediaQuery from "../../../../../utils/useMediaQueries";
import useSettings from "./useSettings";
import LoadConfiguration from "./LoadConfiguration";
import CaptureLog from "./CaptureLog";
import CaptureAccelerometer from "./CaptureAccelerometer";
import Presets from "./Presets";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import CloseIcon from "@mui/icons-material/CloseRounded";
import Grid from "@mui/material/Grid";
import TypedGrid from "../../../../MuiComponents/TypedGrid";
import SettingsContext from "../../../../../context/settings/settingsContext";
import Button from "../../../../MuiComponents/Button";
import { ErrorDetails } from "../../../../../utils/types";
import ConfiguratorLog from "./ConfiguratorLog";
import Alert from "../../../../Alert";
import { convertAxiosErrorToErrorDetails } from "../../../../../utils/helpers";
import { AlertStatus, LoadStatus } from "../../../../../constants/constants";
import ReadRecords from "./ReadRecords";
import useTct from "../../../../../utils/useTct";

const EditorSettings = () => {
    const { layoutData, setLayoutData } = useContext(LayoutContext);

    const { t } = useContext(LanguageContext);

    const { configuratorVersion } = useContext(UserInfoContext);

    const { isGeneralSettingsModalOpen, closeGeneralSettingsModal } =
        useContext(SettingsContext);

    const { toMd } = useMediaQuery();
    const { postSettingsAsync, getSettingsWithQueryParamsAsync } = useTct();
    const { isEmpty, validNumericValue, loadConfigurationValueIsWrong } =
        useSettings();

    const minTime = 1;
    const maxTimeLog = 15300;
    const maxTimeAccelerometer = 60;

    const [wrongValue, setWrongValue] = useState(true);

    const [focusSubmitBtn, setfocusSubmitBtn] = useState(false);

    const [error, setError] = useState<ErrorDetails | null>(null);

    const [settingsData, setSettingsData] = useState({
        captureLogPath: "",
        captureLogTime: "",
        captureAccelerometerPath: "",
        captureAccelerometerTime: "",
        presetsPath: "",
        recordsPath: "",
        configuratorLogPath: "",
        deviceSettings: { loadStatus: "", path: "", fileName: "" },
    });

    const {
        captureLogPath,
        captureLogTime,
        captureAccelerometerPath,
        captureAccelerometerTime,
        presetsPath,
        recordsPath,
        configuratorLogPath,
        deviceSettings,
    } = settingsData;

    const loadInitialValues = async () => {
        try {
            const settings = await getSettingsWithQueryParamsAsync(
                layoutData?.hwVersion || "",
                layoutData?.specId || 0,
                layoutData?.isOnline || false
            );

            setSettingsData({
                captureLogPath: settings.captureLogPath,
                captureLogTime: `${settings.captureTimeMin}`, // conversion to string - best performance
                captureAccelerometerPath: settings.captureAccelerometerPath,
                captureAccelerometerTime: `${settings.captureTimeSec}`, // conversion to string - best performance
                presetsPath: settings.presetsPath,
                recordsPath: settings.recordsPath,
                configuratorLogPath: settings.configuratorLogPath,
                deviceSettings: settings.deviceSettings[0],
            });
        } catch (err) {
            setError(convertAxiosErrorToErrorDetails(err as any));
        }
    };

    useEffect(() => {
        isGeneralSettingsModalOpen && loadInitialValues();

        // eslint-disable-next-line
    }, [isGeneralSettingsModalOpen]);

    useEffect(() => {
        const isWrong =
            isEmpty(captureLogPath) ||
            isEmpty(recordsPath) ||
            isEmpty(captureAccelerometerPath) ||
            isEmpty(presetsPath) ||
            !validNumericValue(captureLogTime, minTime, maxTimeLog) ||
            !validNumericValue(
                captureAccelerometerTime,
                minTime,
                maxTimeAccelerometer
            ) ||
            loadConfigurationValueIsWrong(
                deviceSettings.loadStatus,
                deviceSettings.path
            );

        setWrongValue(isWrong);

        // eslint-disable-next-line
    }, [
        captureLogPath,
        captureAccelerometerPath,
        presetsPath,
        captureLogTime,
        minTime,
        maxTimeLog,
        captureAccelerometerTime,
        minTime,
        maxTimeAccelerometer,
        deviceSettings.loadStatus,
        deviceSettings.path,
        recordsPath,
    ]);

    const handleSettingsData = (name: string, value: string) => {
        if (
            name === "deviceSettingsLoadStatus" ||
            name === "deviceSettingsPath"
        ) {
            const deviceSettingsName =
                name === "deviceSettingsLoadStatus" ? "loadStatus" : "path";

            let newDeviceSettings = {
                ...deviceSettings,
                [deviceSettingsName]: value,
            };

            if (
                deviceSettingsName == "loadStatus" &&
                value != LoadStatus.FromFile
            ) {
                // If load from is not "from file", then unset the path
                newDeviceSettings = {
                    ...newDeviceSettings,
                    path: "",
                };
            }
            setSettingsData({
                ...settingsData,
                deviceSettings: newDeviceSettings,
            });
        } else {
            setSettingsData({
                ...settingsData,
                [name]: value,
            });
        }

        name === "captureLogPath" ||
            name === "recordsPath" ||
            name === "captureAccelerometerPath" ||
            name === "certificatesPath" ||
            name === "presetsPath" ||
            (name === "deviceSettingsPath" && setfocusSubmitBtn(true));
    };

    const submit = async (e: any) => {
        e.preventDefault();

        if (!wrongValue) {
            try {
                await postSettingsAsync({
                    captureLogPath,
                    captureAccelerometerPath,
                    captureTimeMin: captureLogTime,
                    captureTimeSec: captureAccelerometerTime,
                    presetsPath,
                    deviceSettings: [deviceSettings],
                    recordsPath,
                });
                setLayoutData({
                    ...layoutData,
                    settings: {
                        captureLogPath,
                        captureAccelerometerPath,
                        captureTimeMin: Number(captureLogTime),
                        captureTimeSec: Number(captureAccelerometerTime),
                        presetsPath,
                        recordsPath,
                        deviceSettings: [deviceSettings],
                    },
                    path: deviceSettings?.path || "",
                    fromFile:
                        deviceSettings?.loadStatus === LoadStatus.FromFile,
                });

                closeGeneralSettingsModal();
                setError(null);
            } catch (err) {
                setError(convertAxiosErrorToErrorDetails(err as any));
            }
        }
    };

    return (
        <Dialog
            onClose={closeGeneralSettingsModal}
            open={isGeneralSettingsModalOpen}
            fullScreen={toMd}
            fullWidth
            maxWidth="md920"
        >
            <DialogTitle>
                {t.Settings}
                <Button
                    variant="iconOnly"
                    size="small"
                    color="white"
                    icon={<CloseIcon onClick={closeGeneralSettingsModal} />}
                    css={css({
                        position: "absolute",
                        top: "4px",
                        right: "4px",
                    })}
                    idForTesting="closeGeneralSettingsModal"
                />
            </DialogTitle>

            <form
                noValidate
                onSubmit={submit}
                css={css({
                    display: "flex",
                    flexDirection: "column",
                    flex: "1 1 auto",
                })}
            >
                <DialogContent dividers>
                    <div
                        css={css({
                            display: "flex",
                            flexDirection: "column",
                            height: "100%",
                        })}
                    >
                        <Grid container columnSpacing={3} rowSpacing={4}>
                            {error && (
                                <TypedGrid
                                    item
                                    xs0={12}
                                    css={css({
                                        "& > div + div": {
                                            marginTop: "32px",
                                        },
                                    })}
                                >
                                    <Alert
                                        status={AlertStatus.Warning}
                                        title={error.title}
                                        description={error.description}
                                    />
                                </TypedGrid>
                            )}
                            <TypedGrid
                                item
                                xs0={12}
                                md={6}
                                css={css({
                                    "& > div + div": {
                                        marginTop: "32px",
                                    },
                                })}
                            >
                                <LoadConfiguration
                                    loadStatus={deviceSettings.loadStatus}
                                    path={deviceSettings.path}
                                    change={handleSettingsData}
                                />

                                <CaptureLog
                                    path={captureLogPath}
                                    time={captureLogTime}
                                    minTimeValue={minTime}
                                    maxTimeValue={maxTimeLog}
                                    validateNumber={validNumericValue}
                                    change={handleSettingsData}
                                />

                                <ConfiguratorLog path={configuratorLogPath} />
                            </TypedGrid>

                            <TypedGrid
                                item
                                xs0={12}
                                md={6}
                                css={css({
                                    "& > div + div": {
                                        marginTop: "32px",
                                    },
                                })}
                            >
                                <CaptureAccelerometer
                                    path={captureAccelerometerPath}
                                    time={captureAccelerometerTime}
                                    minTimeValue={minTime}
                                    maxTimeValue={maxTimeAccelerometer}
                                    validateNumber={validNumericValue}
                                    change={handleSettingsData}
                                />

                                <Presets
                                    path={presetsPath}
                                    change={handleSettingsData}
                                />
                                <ReadRecords
                                    path={recordsPath}
                                    change={handleSettingsData}
                                />
                            </TypedGrid>
                        </Grid>

                        <div
                            css={css({
                                display: "flex",
                                justifyContent: "flex-end",
                                fontSize: "12px",
                                lineHeight: "16px",
                                letterSpacing: "0.4px",
                                marginTop: "16px",

                                "@media (max-width: 839px)": {
                                    flex: "1",
                                    alignItems: "flex-end",
                                    marginTop: 0,
                                },
                            })}
                        >
                            {t.ConfiguratorVersion}: {configuratorVersion}
                        </div>
                    </div>
                </DialogContent>

                <DialogActions>
                    <Button
                        variant="textOnly"
                        size="normal"
                        color="secondary"
                        fullWidth
                        onClick={closeGeneralSettingsModal}
                        idForTesting="closeGeneralSettingsModal"
                    >
                        {t.Close}
                    </Button>

                    <Button
                        disabled={wrongValue}
                        focus={focusSubmitBtn}
                        variant="textOnly"
                        size="normal"
                        color="primary"
                        fullWidth
                        type="submit"
                        onClick={submit}
                        idForTesting="submitGeneralSettingsModal"
                    >
                        {t.Save}
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    );
};

export default EditorSettings;
