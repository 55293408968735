import { createContext } from "react";

interface ThemeContextState {
    colors: { [key: string]: string };
    boxShadows: { [key: string]: string };
}

const ThemeContext = createContext({} as ThemeContextState);

export default ThemeContext;
