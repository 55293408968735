/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import React, { useRef } from "react";
import InputField from "../../../../MuiComponents/InputField";
import TypedGrid from "../../../../MuiComponents/TypedGrid";

export interface PolynomialSensorValueRowProps {
    hasNoBorderRadius?: boolean;
    isFirstRow?: boolean;
    isLastRow?: boolean;
    firstValue: string;
    secondValue: number | undefined;
}

const PolynomialSensorValueRow: React.FunctionComponent<
    PolynomialSensorValueRowProps
> = ({ hasNoBorderRadius, isFirstRow, isLastRow, firstValue, secondValue }) => {
    const elementRef = useRef(null);

    const renderInputBorder = (firstInput: boolean) => {
        if (hasNoBorderRadius) {
            return "0px 0px 0px 0px";
        }
        if (isFirstRow && firstInput) {
            return "6px 0px 0px 0px";
        }
        if (isFirstRow && !firstInput) {
            return "0px 6px 0px 0px";
        }
        if (isLastRow && firstInput) {
            return "0px 0px 0px 6px";
        }
        if (isLastRow && !firstInput) {
            return "0px 0px 6px 0px";
        }
        return "0px 0px 0px 0px";
    };
    return (
        <TypedGrid container alignItems={"center"}>
            <TypedGrid item lg={6} md={6} sm={6} xs={6}>
                <InputField
                    ref={elementRef}
                    fullWidth
                    size="medium"
                    type="text"
                    value={firstValue}
                    borderRadius={renderInputBorder(true)}
                    stacked={true}
                    readOnly
                />
            </TypedGrid>
            <TypedGrid item lg={6} md={6} sm={6} xs={6}>
                <InputField
                    ref={elementRef}
                    fullWidth
                    size="medium"
                    type="number"
                    value={secondValue || 0}
                    borderRadius={renderInputBorder(false)}
                    stacked={true}
                    readOnly
                />
            </TypedGrid>
        </TypedGrid>
    );
};

export default PolynomialSensorValueRow;
