/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import {
    Checkbox,
    InputLabel,
    ListItemIcon,
    ListItemText,
    MenuItem,
    Popover,
} from "@mui/material";
import { Fragment, useContext, useState } from "react";
import ColumnIcon from "../../../../../../../assets/icons/ColumnIcon";
import InputOutputContext from "../../../../../../../context/inputOutput/inputOutputContext";
import ThemeContext from "../../../../../../../context/theme/themeContext";
import Divider from "../../../../../../MuiComponents/Divider";
import LengthBubble from "../../../../../../MuiComponents/LengthBubble";
import LanguageContext from "../../../../../../../context/language/languageContext";

const ColumnSelect = () => {
    const {
        columns,
        filterColumnsByName,
        activeColumns,
        resetColumnsToDefault,
        resetColumn,
    } = useContext(InputOutputContext);
    const {
        colors: { blue700, gray100, gray200, blue200, white, blue400 },
    } = useContext(ThemeContext);

    const [menuAnchorEl, setMenuAnchorEl] = useState<HTMLDivElement | null>(
        null
    );

    const { t } = useContext(LanguageContext);

    return (
        <Fragment>
            <InputLabel
                onClick={(e) => setMenuAnchorEl(e.currentTarget as any)}
                css={css({
                    position: "unset",
                    display: "inline-flex",
                    overflow: "unset",
                })}
            >
                <div
                    css={css({
                        display: "flex",
                        justifyContent: "end",
                        alignItems: "center",
                        padding: "8px 12px",
                        cursor: "pointer",
                        position: "relative",
                        borderRadius: "6px",
                        "&:hover": {
                            backgroundColor: gray100,
                        },
                        "&:active": {
                            backgroundColor: gray200,
                        },
                    })}
                >
                    <LengthBubble bottomPosition={6} leftPosition={25}>
                        {activeColumns.length}
                    </LengthBubble>
                    <ColumnIcon css={css({ width: "18px", height: "14px" })} />
                    <div
                        css={css({
                            marginLeft: "12px",
                            fontWeight: 600,
                            fontSize: "14px",
                            lineHeight: "20px",
                            letterSpacing: "0.1px",
                        })}
                    >
                        {t.ViewColumns}
                    </div>
                </div>
            </InputLabel>
            <Popover
                onClose={() => setMenuAnchorEl(null)}
                open={!!menuAnchorEl}
                anchorEl={menuAnchorEl}
                anchorPosition={{ top: 0, left: 0 }}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                }}
                sx={{
                    "& .MuiPaper-root": {
                        maxWidth: "211px",
                        width: "100%",
                        marginTop: "8px",
                        maxHeight: "529px",
                        "&::-webkit-scrollbar": {
                            width: "18px",
                        },

                        "&::-webkit-scrollbar-thumb": {
                            backgroundColor: blue200,
                            borderRadius: "100px",
                            border: `5px solid ${white}`,
                            minHeight: "48px",
                        },

                        "&::-webkit-scrollbar-thumb:hover": {
                            backgroundColor: blue400,
                        },
                    },
                }}
            >
                <MenuItem
                    onClick={resetColumnsToDefault}
                    css={css({ color: blue700, padding: "14px 2px 14px 16px" })}
                >
                    Reset to default
                </MenuItem>
                <Divider />
                {columns.map((column: any) => (
                    <MenuItem
                        key={column.name}
                        disabled={column.isDisabled}
                        css={{ padding: "14px 2px 14px 16px" }}
                        onClick={() =>
                            activeColumns.includes(column.value)
                                ? resetColumn(column.value)
                                : filterColumnsByName(column.value)
                        }
                    >
                        <ListItemText
                            primary={column.name}
                            sx={{
                                ".MuiListItemText-primary": {
                                    fontWeight: "600",
                                    fontSize: "14px",
                                },
                            }}
                        />
                        <ListItemIcon>
                            <Checkbox
                                checked={
                                    activeColumns.includes(column.value) ||
                                    column.isDisabled
                                }
                            />
                        </ListItemIcon>
                    </MenuItem>
                ))}
            </Popover>
        </Fragment>
    );
};

export default ColumnSelect;
