/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import { CSSTransition } from "react-transition-group";
import Box from "../../Box";
import DiscoveredDevices from "./DiscoveredDevices";
import DiscoveringDevices from "./DiscoveringDevices";
import { useAtom } from "jotai";
import { DiscoveredDevicesAtom } from "../../../context/webSockets/webSocketsState";
import { useEffect, useState, useContext, useRef } from "react";
import LayoutContext from "../../../context/layout/layoutContext";
import ConfigurationContext from "../../../context/configuration/configurationContext";

const Online = () => {
    const [devices] = useAtom(DiscoveredDevicesAtom);
    const [devicesExist, setDevicesExist] = useState(devices.length > 0);
    const { setDeviceConfigureButtonPortName, deviceConfigureButtonPortName } =
        useContext(LayoutContext);
    const { isExperimentalModalOpen } = useContext(ConfigurationContext);
    const hasBeenRendered = useRef(false);

    useEffect(() => {
        //Will undisable the configure button when the component is rendered and new status is received
        if (hasBeenRendered.current && !!deviceConfigureButtonPortName) {
            setDeviceConfigureButtonPortName("");
        }
        setDevicesExist(devices.length > 0);
        hasBeenRendered.current = true;
    }, [devices]);

    const smallScreenMediaCss = `@media (max-width: 839px) { border-radius: 0; background: unset; box-shadow: unset; }`;

    if (isExperimentalModalOpen) {
        return (
            <div
                css={css`
                    position: relative;
                    border-radius: 8px;
                    background: #fff;
                    box-shadow: 0px 0px 2px 0px rgba(37, 42, 49, 0.16),
                        0px 1px 4px 0px rgba(37, 42, 49, 0.12);
                    width: 100%;
                    height: 76px;
                `}
            />
        );
    }
    return (
        <div
            css={css`
                position: relative;
            `}
        >
            <Box
                css={css`
                    display: grid;

                    & > div {
                        grid-area: 1 / 1;
                    }

                    ${devicesExist && smallScreenMediaCss};
                `}
            >
                <CSSTransition
                    in={devicesExist}
                    unmountOnExit
                    timeout={300}
                    classNames="discovered"
                >
                    <DiscoveredDevices devices={devices} />
                </CSSTransition>

                <CSSTransition
                    in={!devicesExist}
                    unmountOnExit
                    timeout={300}
                    classNames="discovering"
                >
                    <DiscoveringDevices />
                </CSSTransition>
            </Box>
        </div>
    );
};

export default Online;
