/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import { ReactComponent as AddIcon } from "../../assets/icons/terminal/Add.svg";
import { useContext } from "react";
import ThemeContext from "../../context/theme/themeContext";
import LanguageContext from "../../context/language/languageContext";

export interface EmptyTerminalBlockProps {}

const EmptyTerminalBlock: React.FunctionComponent<
    EmptyTerminalBlockProps
> = () => {
    const { t } = useContext(LanguageContext);
    const {
        colors: { textDark, textDarkSecondary },
    } = useContext(ThemeContext);
    return (
        <div
            css={css({
                marginTop: "88px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
            })}
        >
            <div css={css({ marginBottom: "16px" })}>
                <AddIcon width={56} height={56} />
            </div>
            <div
                css={css({
                    marginBottom: "8px",
                    color: textDark,
                    fontSize: "16px",
                    fontStyle: "normal",
                    fontWeight: 600,
                    lineHeight: "20px",
                    letterSpacing: "0.15px",
                    fontFamily: "Open Sans",
                    textAlign: "center",
                })}
            >
                {t.TerminalEmptyBlockTopTitle} <br />
                {t.TerminalEmptyBlockBottomTitle}
            </div>
            <div
                css={css({
                    textAlign: "center",
                    color: textDarkSecondary,
                    fontFamily: "Open Sans",
                    fontSize: "14px",
                    fontStyle: "normal",
                    fontWeight: 400,
                    lineHeight: "20px",
                    letterSpacing: "0.1px",
                })}
            >
                {t.TerminalEmptyBlockDescription}
            </div>
        </div>
    );
};

export default EmptyTerminalBlock;
