import { createContext } from "react";
import { InitialState } from "./bluetoothViewReducer";

interface BluetoothViewContextState extends InitialState {
    activeColumns: string[];
    filterColumnsByName: (e: string) => void;
    resetColumnsToDefault: () => void;
    resetColumn: (column: string) => void;
    setBluetoothData: (data: any) => void;
    setBluetoothModalTable: (data: any) => void;
    setBluetoothModalPreset: (data: any) => void;
    setSubmitDisabled: (data: boolean) => void;
    setBluetoothTableStatusFilter: (data: any) => void;
    setTableSearchValue: (data: string) => void;
    setBluetoothModalName: (data: string | null) => void;
    setModalData: (data: any) => void;
    setAddDeviceModal: (data: boolean) => void;
    setEditDeviceModal: (data: boolean) => void;
    setReloadBluetoothView: (data: boolean) => void;
    getModalLayout: (
        modalName: string,
        selectedLanguage: string,
        configurationId: number | undefined,
        presets: any,
        isEdit?: boolean
    ) => void;
    setBluetoothTotalCount: (data: number) => void;
    resetBluetoothView: () => void;
}

const BluetoothViewContext = createContext({} as BluetoothViewContextState);

export default BluetoothViewContext;
