/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from "@emotion/react";

import { Fragment, useContext, useEffect } from "react";
import ThemeContext from "../../../../../context/theme/themeContext";
import SettingsContext from "../../../../../context/settings/settingsContext";
import useMediaQueries from "../../../../../utils/useMediaQueries";
import Popover from "@mui/material/Popover";
import TextLink from "../../../../MuiComponents/TextLink";
import LayoutContext from "../../../../../context/layout/layoutContext";
import SwitchInput from "./SwitchInput";
import useApi from "../../../../../utils/useApi";
import LanguageContext from "../../../../../context/language/languageContext";
import AlertContext from "../../../../../context/alert/alertContext";
import useTct from "../../../../../utils/useTct";
import { AlertStatus } from "../../../../../constants/constants";

const InterfaceSettingsModal = () => {
    const {
        colors: { textDark },
    } = useContext(ThemeContext);
    const { layoutData } = useContext(LayoutContext);
    const { selectedLanguage } = useContext(LanguageContext);
    const { setAlert } = useContext(AlertContext);
    const {
        anchorEl,
        isInterfaceSettingsModalOpen,
        closeInterfaceSettingsModal,
        openSettingsModal,
        hideParameterIds,
        hideExplanatoryTexts,
        isParameterIdsHidden,
        isExplanatoryTextsHidden,
    } = useContext(SettingsContext);

    const { toMd } = useMediaQueries();
    const { postData } = useApi();
    const { postSettingsHideOptionsAsync } = useTct();

    useEffect(() => {
        if (layoutData?.settings.hideOptions) {
            hideParameterIds(layoutData?.settings.hideOptions.hideParameterIds);
            hideExplanatoryTexts(layoutData?.settings.hideOptions.hideTexts);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleHideExplanatoryTexts = async (e: any) => {
        try {
            hideExplanatoryTexts(e.target.checked);
            await postSettingsHideOptionsAsync({
                hideTexts: e.target.checked,
                hideParameterIds: isParameterIdsHidden,
            });
            layoutData!.settings.hideOptions.hideTexts = e.target.checked;
        } catch (error) {
            hideExplanatoryTexts(!e.target.checked);
            setAlert(AlertStatus.Critical, "Unable to hide explanatory text");
        }
    };

    const handleHideParameterIds = async (e: any) => {
        try {
            hideParameterIds(e.target.checked);
            await postSettingsHideOptionsAsync({
                hideTexts: isExplanatoryTextsHidden,
                hideParameterIds: e.target.checked,
            });
            layoutData!.settings.hideOptions.hideParameterIds =
                e.target.checked;
        } catch (error) {
            hideParameterIds(!e.target.checked);
            setAlert(AlertStatus.Critical, "Unable to hide parameter ids");
        }
    };

    const interfaceSettingsItems = [
        {
            label: "Hide explanatory texts",
            value: isExplanatoryTextsHidden,
            onChange: handleHideExplanatoryTexts,
        },
        {
            label: "Hide parameter ids",
            value: isParameterIdsHidden,
            onChange: handleHideParameterIds,
        },
    ];

    return (
        <Fragment>
            <Popover
                open={isInterfaceSettingsModalOpen}
                anchorEl={anchorEl}
                onClose={closeInterfaceSettingsModal}
                anchorReference={toMd ? "anchorPosition" : "anchorEl"}
                anchorPosition={{ top: 0, left: 0 }}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                }}
                marginThreshold={toMd ? 0 : 16}
                sx={{
                    "& .MuiPaper-root": {
                        maxHeight: toMd ? "unset" : "626px",
                        height: toMd ? "100%" : "unset",
                        maxWidth: toMd ? "unset" : "448px",
                        width: toMd ? "100%" : "calc(100% - 284px)",
                        marginTop: toMd ? "0" : "10px",
                        overflowY: toMd ? "auto" : "hidden",
                    },
                }}
            >
                <div
                    css={css({
                        display: "flex",
                        padding: "16px 30px 16px 24px",

                        "& > h2": {
                            color: textDark,
                            fontSize: "20px",
                            lineHeight: "24px",
                            fontWeight: "600",
                            letterSpacing: "0.15px",
                            alignSelf: "flex-end",
                        },
                    })}
                >
                    <TextLink
                        size="normal"
                        onClick={() => openSettingsModal()}
                        idForTesting="interface-settings-modal-back-button"
                        startIcon
                    >
                        Go back
                    </TextLink>
                </div>
                <div>
                    {interfaceSettingsItems.map((item) => (
                        <div
                            css={css({
                                display: "flex",
                                padding: "14px 34px 14px 26px",
                                justifyContent: "space-between",
                                alignItems: "center",
                            })}
                            key={item.label}
                        >
                            <div
                                css={css({
                                    display: "flex",
                                    alignItems: "center",
                                })}
                            >
                                {item.label}
                            </div>
                            <SwitchInput
                                onChange={item.onChange}
                                isSwitched={item.value}
                            />
                        </div>
                    ))}
                </div>
            </Popover>
        </Fragment>
    );
};

export default InterfaceSettingsModal;
