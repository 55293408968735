/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import BannerView from "../../../../MuiComponents/BannerView";
import ModifiedParameterList from "./index";
import { useContext } from "react";
import LayoutContext from "../../../../../context/layout/layoutContext";
import { ModifiedParametersFixedType } from "../../../../../utils/types";

interface ModifiedParameterProps {
    data: ModifiedParametersFixedType;
    hasNoInfoBanner?: boolean;
}

const ModifiedParameterView = ({
    data,
    hasNoInfoBanner,
}: ModifiedParameterProps) => {
    const { layoutData } = useContext(LayoutContext);

    return (
        <div
            css={css({
                display: "flex",
                gap: 16,
                flexDirection: "column",
                marginTop: 8,
            })}
        >
            {!hasNoInfoBanner &&
                layoutData!.parameterSaveBanners?.length > 0 && (
                    <div>
                        {layoutData!.parameterSaveBanners.map(
                            (banner, index) => (
                                <BannerView
                                    key={index}
                                    data={banner}
                                ></BannerView>
                            )
                        )}
                    </div>
                )}
            {data.parameters?.length > 0 && (
                <ModifiedParameterList modifiedParameters={data.parameters} />
            )}
        </div>
    );
};

export default ModifiedParameterView;
