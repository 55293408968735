/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/react";
import { Fragment, useContext } from "react";
import FeaturesContext from "../../../../../../../context/features/featuresContext";
import { Component, Schedule } from "../../../../../../../generatedTypes";

import Collection from "../../../Component/Collection";
import WeekdayInput from "./WeekdayInput";

export interface WeeklyScheduleCardProps {
    weekdays: Schedule["weekdays"];
    timeZone: Component;
}

const WeeklyScheduleCard = ({
    weekdays,
    timeZone,
}: WeeklyScheduleCardProps) => {
    const { setParameterValue, findDefaultParameterValueById } =
        useContext(FeaturesContext);

    const handleOnChange = (event: { id: number; value: string }) => {
        setParameterValue({
            parameterId: event.id,
            value: event.value,
        });
    };
    return (
        <Fragment>
            <Collection
                data={timeZone}
                onChange={handleOnChange}
                defaultValue={findDefaultParameterValueById(
                    timeZone.parameterId
                )}
                hasNoDependencies
            />
            {weekdays.map((item, i) => {
                return (
                    <WeekdayInput
                        key={item.index}
                        data={item}
                        hasNoDivider={i === 0}
                    />
                );
            })}
        </Fragment>
    );
};

export default WeeklyScheduleCard;
