/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from "@emotion/react";
import { ReactComponent as DeviceLocked } from "./DeviceLockedIcon.svg";

const DeviceLockedIcon = ({
    width,
    height,
}: {
    width?: number;
    height?: number;
}) => <DeviceLocked css={css({ width: width, height: height })} />;

export default DeviceLockedIcon;
