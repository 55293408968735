/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from "@emotion/react";

import { useContext } from "react";
import ThemeContext from "../../../context/theme/themeContext";
import useMediaQueries from "../../../utils/useMediaQueries";
import { ReactComponent as Logo } from "../../../assets/TeltonikaLogo.svg";
import Container from "../../Container";
import Box from "../../Box";
import TableSkeleton from "./TableSkeleton";
import Stack from "@mui/material/Stack";
import Skeleton from "@mui/material/Skeleton";

const StartUpSkeleton = () => {
    const {
        colors: { white, gray100 },
    } = useContext(ThemeContext);

    const { toMd, toSm } = useMediaQueries();

    return (
        <div>
            <div
                css={css`
                    background: ${white};
                    padding: 10px 24px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    box-shadow: 0px 6px 14px -6px rgba(24, 39, 75, 0.12),
                        0px 10px 32px -4px rgba(24, 39, 75, 0.1);
                `}
            >
                <Logo />

                <Stack
                    direction="row"
                    css={css`
                        & > span {
                            height: 36px;
                            background: ${gray100};
                        }

                        & > span + span {
                            margin-left: ${toSm ? "4px" : "8px"};
                        }
                    `}
                >
                    <Skeleton width={toSm ? 36 : 101} />
                    <Skeleton width={toSm ? 36 : 107} />
                    <Skeleton width={toSm ? 36 : 84} />
                </Stack>
            </div>

            <Container
                css={css`
                    margin: 24px 0;
                `}
            >
                <Box
                    css={css`
                        display: flex;
                        align-items: center;
                        padding: 28px 24px;

                        span {
                            height: 20px;
                        }
                    `}
                >
                    <Skeleton
                        width={36}
                        css={css`
                            margin-right: 12px;
                        `}
                    />

                    <Skeleton width={toMd ? "100%" : 585} />
                </Box>

                <TableSkeleton />
            </Container>
        </div>
    );
};

export default StartUpSkeleton;
