import { createContext } from "react";
import { ListValue } from "../../generatedTypes";

export interface ListContextState {
    isNumberType: boolean;
    items: ListValue[];
    valuesCountLimit: number;
    idRangeFrom: number;
    idRangeTo: number;
    maxLength: number;
    subtitle: string;
    name: string;
    description: string | undefined;
    modalTitle: string;
    modalDescription: string;
    modalClearAllCaption: string;
    modalSaveSuccessMsg: string;
    modalSaveFailedMsg: string;
    modalAddBtnCaption: string;
    modalImportSuccessMsg: string;
    modalItemLabel: string;
    placeholder: string;
    renderType: string;
    valuesCountLimitWithSecondaryItems?: number;
    idRangeFromSecondary?: number;
    idRangeToSecondary?: number;
    availableParameterIds: number[];
    isSaveLoading: boolean;
    isAllowedToScroll: boolean;
    isOpenConfirmation: boolean;
    isError: boolean;
    inputs: any[];
    previousInputs: any[];
    errors: any;
    setListInitialValues: (inputsPayload: any, transformedInputs: any) => void;
    setImportInputValues: (inputs: any[], errors: any) => void;
    setError: (error: any) => void;
    setCloseListWithouSaving: (
        inputsPayload: any,
        countPayload: any,
        errorsPayload: any
    ) => void;
    openConfirmationModal: () => void;
    closeConfirmationModal: () => void;
    clearInputs: (inputsPayload: any) => void;
    changeInputs: any;
    addInput: any;
    setSavingLoading: (isSaveLoading: boolean) => void;
    saveValues: any;
}

const ListContext = createContext({} as ListContextState);

export default ListContext;
