import { createContext } from "react";
import { InitialState } from "./bluetoothModalsReducer";

interface BluetoothModalsContextState extends InitialState {
    setDeviceType: (data: any) => void;
    setDynamicSelectValue: (data: any) => void;
    setSecondDynamicSelectValue: (data: any) => void;
    setThirdDynamicSelectValue: (data: any) => void;
    setActivePresets: (data: { name: any }[]) => void;
    setBluetoothTableParameterData: (
        data: {
            id: number;
            value: number | string;
            oldValue?: number | string;
            error: string;
        } | null
    ) => void;
    setBluetoothTableParametersData: (
        data: {
            id: number;
            value: number | string;
            error: string;
        }[]
    ) => void;
    setBluetoothListParameter: (
        data: {
            id: number;
            value: string;
            oldValue?: string;
            error: any;
        }
    ) => void;
    setBluetoothListParameters: (
        data: {
            id: number;
            value: string;
            oldValue?: string;
            error: any;
        }[]
    ) => void;
    setModalButtonDisabled: (data: boolean) => void;
}

const BluetoothModalsContext = createContext({} as BluetoothModalsContextState);

export default BluetoothModalsContext;
