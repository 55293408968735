import React from "react";
import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export interface DeviceSetUpIconProps extends SvgIconProps {
    iconColor?: string;
}
const DeviceSetUpIcon = ({
    iconColor = "#334155",
    ...props
}: DeviceSetUpIconProps) => {
    return (
        <SvgIcon viewBox="0 0 25 24" {...props}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M3.99805 7C3.99805 5.89543 4.89348 5 5.99805 5H19.998C21.1026 5 21.998 5.89543 21.998 7V9C21.998 10.1046 21.1026 11 19.998 11H5.99805C4.89348 11 3.99805 10.1046 3.99805 9V7ZM12.998 9V7H19.998V9H12.998Z"
                fill={iconColor}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M3.99805 15C3.99805 13.8954 4.89348 13 5.99805 13H19.998C21.1026 13 21.998 13.8954 21.998 15V17C21.998 18.1046 21.1026 19 19.998 19H5.99805C4.89348 19 3.99805 18.1046 3.99805 17V15ZM19.998 15V17H8.99805V15H19.998Z"
                fill={iconColor}
            />
        </SvgIcon>
    );
};

export default DeviceSetUpIcon;
