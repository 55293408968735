/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from "@emotion/react";

import { useContext } from "react";
import ThemeContext from "../../context/theme/themeContext";
import FormControlLabel from "@mui/material/FormControlLabel";
import CheckboxMui, { CheckboxProps } from "@mui/material/Checkbox";
import CheckboxBlankIcon from "../../assets/icons/CheckboxBlankIcon";
import CheckboxCheckedIcon from "../../assets/icons/CheckboxCheckedIcon";
import CheckboxIndeterminateIcon from "../../assets/icons/CheckboxIndeterminateIcon";

interface MuiCheckboxProps extends CheckboxProps {
    label?: string;
    description?: string;
    error?: boolean;
}

const Checkbox = ({
    label,
    description,
    error,
    ...props
}: MuiCheckboxProps) => {
    const {
        colors: { textDark, gray700, textDarkDisabled },
    } = useContext(ThemeContext);

    const labelColor = props.disabled ? textDarkDisabled : textDark;
    const descriptionColor = props.disabled ? textDarkDisabled : gray700;

    return (
        <FormControlLabel
            control={
                <CheckboxMui
                    {...props}
                    disableRipple
                    icon={<CheckboxBlankIcon error={error} />}
                    checkedIcon={<CheckboxCheckedIcon error={error} />}
                    indeterminateIcon={
                        <CheckboxIndeterminateIcon error={error} />
                    }
                />
            }
            label={
                label ? (
                    <span
                        css={css`
                            & > span {
                                display: block;
                            }
                        `}
                    >
                        <span
                            css={css`
                                color: ${labelColor};
                                font-size: 14px;
                                font-weight: 600;
                                line-height: 20px;
                                letter-spacing: 0.1px;
                            `}
                        >
                            {label}
                        </span>

                        {description && (
                            <span
                                css={css`
                                    color: ${descriptionColor};
                                    font-size: 12px;
                                    line-height: 16px;
                                    letter-spacing: 0.4px;
                                `}
                            >
                                {description}
                            </span>
                        )}
                    </span>
                ) : (
                    ""
                )
            }
            sx={{
                alignItems: description ? "flex-start" : "center",
                margin: "0",

                "& .MuiFormControlLabel-label": {
                    marginLeft: label ? "8px" : "0",
                },
            }}
        />
    );
};

export default Checkbox;
