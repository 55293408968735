/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/react";
import { Fragment, SyntheticEvent, useContext } from "react";
import LanguageContext from "../../../../../context/language/languageContext";
import SaveConfigurationModal from "../SaveConfigurationModal";
import SaveButton, {
    SaveButtonInternalProps,
    SaveButtonProps,
} from "./SaveButton";
import { ModifiedParametersFixedType } from "../../../../../utils/types";

export interface SaveToFileButtonProps extends SaveButtonProps {
    isModalOpen: boolean;
    modifiedParameterResponse: ModifiedParametersFixedType;
    setIsSaving: (isSaving: boolean) => void;
    isPrimaryButtonLoading?: boolean;
}

export interface SaveToFileButtonInternalProps
    extends SaveToFileButtonProps,
        SaveButtonInternalProps {
    onSave: (e: SyntheticEvent, onlyModified: boolean) => void;
}

const SaveToFile = ({
    isDisabled,
    isModalOpen,
    setModalOpen,
    modifiedParameterResponse,
    isSaving,
    onSave,
    btnLabel,
    isPrimaryColor,
    isPrimaryButtonLoading,
}: SaveToFileButtonInternalProps) => {
    const { t } = useContext(LanguageContext);

    const closeSaveModal = () => setModalOpen(false);

    return (
        <Fragment>
            <SaveButton
                isDisabled={isDisabled}
                setModalOpen={setModalOpen}
                isSaving={isSaving}
                btnLabel={btnLabel}
                isPrimaryColor={isPrimaryColor}
                idForTesting="save-to-file-button"
            />
            <SaveConfigurationModal
                title={t.HowToSavePrompt}
                isOpen={isModalOpen}
                onClose={closeSaveModal}
                primaryBtnText={t.FullSave}
                onPrimaryBtnClick={(e: SyntheticEvent) => onSave(e, false)}
                secondaryBtnText={t.OnlyChanges}
                onSecondaryBtnClick={(e: SyntheticEvent) => onSave(e, true)}
                isSecondaryButtonDisabled={
                    !modifiedParameterResponse.parameters.length
                }
                modifiedParameters={modifiedParameterResponse}
                isPrimaryButtonLoading={isPrimaryButtonLoading}
            />
        </Fragment>
    );
};

export default SaveToFile;
