/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx, SerializedStyles } from "@emotion/react";

import { Fragment, useContext, useState } from "react";
import ThemeContext from "../../../../../context/theme/themeContext";
import AlertContext from "../../../../../context/alert/alertContext";
import LayoutContext from "../../../../../context/layout/layoutContext";
import LanguageContext from "../../../../../context/language/languageContext";
import Card from "../../../../MuiComponents/Card";
import MaintenanceButton from "./MaintenanceButton";
import LogModal from "./LogModal";
import useApi from "../../../../../utils/useApi";
import useDesktopHost from "../../../../../utils/useDesktopHost";
import { AlertStatus, PathType } from "../../../../../constants/constants";
import WebSocketsContext from "../../../../../context/webSockets/webSocketsContext";
import Divider from "../../../../MuiComponents/Divider";
import { DeviceStatusWindow } from "../../../../../generatedTypes";

interface MaintenanceInformationProps {
    visibilitySettings: DeviceStatusWindow;
    valueBaseStyles: SerializedStyles;
}

const MaintenanceInformation = ({
    visibilitySettings,
    valueBaseStyles,
}: MaintenanceInformationProps) => {
    const {
        colors: { gray700, textDark },
    } = useContext(ThemeContext);
    const { setAlert } = useContext(AlertContext);
    const { startActionLoading, stopActionLoading } = useContext(LayoutContext);
    const { t, selectedLanguage } = useContext(LanguageContext);
    const { captureDumpAsync } = useContext(WebSocketsContext);

    const [isModalOpen, setOpenedLogModal] = useState(false);
    const { getData } = useApi();
    const { openSpecialDirectory } = useDesktopHost();

    const handleOpenLogModal = () => setOpenedLogModal(true);
    const handleCloseLogModal = () => setOpenedLogModal(false);

    const handleReadAccelometer = async () => {
        try {
            startActionLoading("readingAccelometer");
            await getData(`${selectedLanguage}/dataexport/captureacc`);
            stopActionLoading();
            setAlert(
                AlertStatus.Success,
                t.AccelerometerCaptured,
                null,
                t.OpenFile,
                () => openSpecialDirectory({ type: PathType.Accelerometer })
            );
        } catch (err) {
            stopActionLoading();
            setAlert(AlertStatus.Critical, t.AccelerometerFail);
        }
    };

    const handleDumpRead = async () => {
        try {
            startActionLoading("capturingDump");
            await captureDumpAsync();
            stopActionLoading();
            setAlert(
                AlertStatus.Success,
                t.DumpDownloaded,
                null,
                t.OpenFile,
                () => openSpecialDirectory({ type: PathType.DeviceLog })
            );
        } catch (error) {
            stopActionLoading();
            setAlert(AlertStatus.Critical, t.DumpFail);
        }
    };

    return (
        <Card
            title={t.MaintenanceInfo}
            description={t["MaintenanceInfo.description"]}
        >
            <Divider />
            <div
                css={css`
                    ${valueBaseStyles};

                    margin: 8px 0;
                    font-weight: 600;
                    color: ${gray700};
                `}
            >
                {visibilitySettings.isCaptureLogSupported && t.CaptureLogInfo}
                {visibilitySettings.isCaptureLogSupported &&
                    visibilitySettings.isDownloadLogDumpSupported &&
                    "/"}
                {visibilitySettings.isDownloadLogDumpSupported && t.LogDumpInfo}
            </div>
            <p
                css={css`
                    color: ${textDark};
                    font-size: 14px;
                    line-height: 20px;
                    letter-spacing: 0.1px;
                    margin-top: 12px;
                `}
            >
                {visibilitySettings.isCaptureLogSupported &&
                    t["CaptureLogInfo.description"]}
                {visibilitySettings.isCaptureLogSupported &&
                    visibilitySettings.isDownloadLogDumpSupported &&
                    " "}
                {visibilitySettings.isDownloadLogDumpSupported &&
                    t["LogDumpInfo.description"]}
            </p>
            <div
                css={css`
                    display: flex;
                    margin-top: 25px;
                    margin-bottom: 15px;
                `}
            >
                {visibilitySettings.isCaptureLogSupported && (
                    <MaintenanceButton
                        buttonText={t.LogButton}
                        marginRight
                        onClick={handleOpenLogModal}
                    />
                )}
                {visibilitySettings.isDownloadLogDumpSupported && (
                    <MaintenanceButton
                        buttonText={t.DumpButton}
                        onClick={handleDumpRead}
                    />
                )}
            </div>
            <Divider />
            {visibilitySettings.accelerometerSupport ? (
                <Fragment>
                    <div
                        css={css`
                            ${valueBaseStyles};

                            margin: 8px 0;
                            font-weight: 600;
                            color: ${gray700};
                        `}
                    >
                        {t.AccelometerInfo}
                    </div>
                    <p
                        css={css`
                            color: ${textDark};
                            font-size: 14px;
                            line-height: 20px;
                            letter-spacing: 0.1px;
                            margin-top: 12px;
                        `}
                    >
                        {t["AccelometerInfo.description"]}
                    </p>
                    <div
                        css={css`
                            margin-top: 15px;
                        `}
                    >
                        <MaintenanceButton
                            buttonText={t.Read}
                            onClick={handleReadAccelometer}
                        />
                    </div>
                </Fragment>
            ) : null}
            {isModalOpen && (
                <LogModal isOpen={isModalOpen} onClose={handleCloseLogModal} />
            )}
        </Card>
    );
};

export default MaintenanceInformation;
