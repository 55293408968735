/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from "@emotion/react";

import { useContext, useRef, useEffect } from "react";
import ThemeContext from "../../../context/theme/themeContext";
import LanguageContext from "../../../context/language/languageContext";
import useMediaQueries from "../../../utils/useMediaQueries";
import Button from "../../MuiComponents/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import CloseIcon from "@mui/icons-material/CloseRounded";
import CircularProgress from "@mui/material/CircularProgress";
import Divider from "../Divider";

interface PasswordModalProps {
    isOpen: boolean;
    isDisabled: boolean;
    isLoading: boolean;
    title: string;
    submitBtnText: string;
    close: () => void;
    sendPayload: (e?: any) => void;
    children: React.ReactNode;
    description?: string | JSX.Element | null;
    hasNoDivider?: boolean;
    hasEnterKeySubmit?: boolean;
}

const PasswordModal = ({
    isOpen,
    isDisabled,
    isLoading,
    title,
    submitBtnText,
    close,
    sendPayload,
    children,
    description,
    hasNoDivider,
    hasEnterKeySubmit,
}: PasswordModalProps) => {
    const {
        colors: { white, gray600, gray500, textDark },
    } = useContext(ThemeContext);

    const { t } = useContext(LanguageContext);

    const { toSm } = useMediaQueries();

    const renderButtonText = () =>
        isLoading ? (
            <CircularProgress
                size={20}
                css={css({
                    color: white,
                })}
            />
        ) : (
            submitBtnText
        );

    const primaryButtonRef = useRef<HTMLButtonElement>(null);

    useEffect(() => {
        const handleKeyDown = (event: KeyboardEvent) => {
            if (event.key === "Enter" && hasEnterKeySubmit) {
                primaryButtonRef.current?.click();
            }
        };

        document.addEventListener("keydown", handleKeyDown);

        return () => {
            document.removeEventListener("keydown", handleKeyDown);
        };
    }, []);

    return (
        <Dialog
            onClose={close}
            open={isOpen}
            fullScreen={toSm}
            sx={{
                "& .MuiDialog-paper": {
                    maxWidth: "448px",
                    boxSizing: "border-box",

                    "@media (max-width: 839px)": {
                        maxWidth: "410px",
                    },

                    "@media (max-width: 599px)": {
                        maxWidth: "100%",
                    },
                },

                "& .MuiDialogTitle-root": {
                    padding: "24px 16px 12px 16px",
                },

                "& .MuiDialogTitle-root + .MuiDialogContent-root": {
                    paddingTop: "16px",
                },

                "& .MuiDialogContent-root": {
                    padding: "16px",
                },
            }}
        >
            <Button
                variant={"iconOnly"}
                size="small"
                color="white"
                icon={<CloseIcon onClick={close} />}
                css={css({
                    position: "absolute",
                    top: "4px",
                    right: "4px",
                })}
                idForTesting="close-modal"
            />
            <DialogTitle>{title}</DialogTitle>

            {description && (
                <div
                    css={{
                        fontSize: "14px",
                        lineHeight: "20px",
                        letterSpacing: "0.1px",
                        fontWeight: "600",
                        padding: "0 16px 12px 16px",
                        color: textDark,
                    }}
                >
                    {description}
                </div>
            )}
            {!hasNoDivider && <Divider />}
            <DialogContent
                css={css({
                    button: {
                        padding: "0",
                        color: gray500,
                    },

                    "button:hover": {
                        color: gray600,
                    },
                })}
            >
                {children}
            </DialogContent>
            {!hasNoDivider && <Divider />}
            <DialogActions
                sx={{
                    "&.MuiDialogActions-root>:not(:first-of-type)": {
                        marginLeft: "12px",
                    },
                }}
            >
                <Button
                    variant="textOnly"
                    size="normal"
                    color="secondary"
                    fullWidth
                    onClick={close}
                    idForTesting="cancel-modal"
                >
                    {t.Cancel}
                </Button>

                <Button
                    ref={primaryButtonRef}
                    variant="textOnly"
                    size="normal"
                    color="primary"
                    fullWidth
                    disabled={isDisabled}
                    onClick={sendPayload}
                    css={css({
                        pointerEvents: isLoading ? "none" : "auto",
                    })}
                    idForTesting="submit-modal"
                >
                    {renderButtonText()}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default PasswordModal;
