/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import { Collapse } from "@mui/material";
import { Fragment, useContext, useEffect, useRef, useState } from "react";
import SettingsContext from "../../../../context/settings/settingsContext";
import ThemeContext from "../../../../context/theme/themeContext";
import { Frame } from "../../../../generatedTypes";
import useMediaQueries from "../../../../utils/useMediaQueries";
import TypedGrid from "../../../MuiComponents/TypedGrid";
import Block from "../Block";
import SwitchToggle from "../Segment/Component/SwitchToggle";
import MenuItemContext from "../../../../context/menuItem/menuItemContext";
import Divider from "../../../MuiComponents/Divider";

export interface DefaultFrameProps {
    data: Frame;
}

const DefaultFrame = ({ data }: DefaultFrameProps) => {
    const {
        name,
        description,
        switchId,
        switchValue,
        switchDefaultValue,
        avlId,
        blocks,
        orientation,
    } = data;

    const {
        colors: { textDark },
    } = useContext(ThemeContext);
    const { isExplanatoryTextsHidden } = useContext(SettingsContext);

    const { fromSm } = useMediaQueries();

    const switchRef = useRef<HTMLInputElement>(null);

    const { findParameterValueById } = useContext(MenuItemContext);

    const switchParameterValue = findParameterValueById(switchId);

    const [switchedOn, setSwitchedOn] = useState(switchParameterValue !== "0");

    useEffect(
        () => setSwitchedOn(switchParameterValue !== "0"),
        [switchParameterValue]
    );

    const handleSwitch = (state: boolean) => setSwitchedOn(state);
    return (
        <Fragment>
            <div
                css={css`
                    padding: 24px 24px 16px 24px;
                    color: ${textDark};

                    & > p {
                        font-size: 14px;
                        font-weight: 400;
                        line-height: 20px;
                        letter-spacing: 0.1px;
                        margin-top: ${fromSm ? "6px" : "12px"};
                    }
                `}
            >
                <div
                    css={css`
                        display: flex;
                        justify-content: space-between;
                        align-items: center;

                        & > h4 {
                            font-size: 20px;
                            font-weight: 600;
                            line-height: 24px;
                            letter-spacing: 0.15px;
                        }
                    `}
                >
                    <h4>{name}</h4>

                    {switchValue && (
                        <SwitchToggle
                            id={switchId}
                            avlId={avlId}
                            defaulValue={switchDefaultValue || ""}
                            switchedOn={switchedOn}
                            label={name || ""}
                            elementRef={switchRef}
                            toggle={handleSwitch}
                            dependentParameters={
                                data.switchComponent?.dependentParameterIds ||
                                null
                            }
                            wrapperStyle={css({
                                marginLeft: fromSm ? "12px" : "24px",
                                alignSelf: "flex-start",
                            })}
                        />
                    )}
                </div>

                {description && !isExplanatoryTextsHidden && (
                    <p>{description}</p>
                )}
            </div>
            <Collapse in={switchedOn}>
                <Fragment>
                    {!!blocks.length && <Divider hasLighterGray />}

                    <TypedGrid
                        container
                        spacing={3}
                        css={css({
                            padding: !blocks.length ? "0px" : "16px 24px 24px",
                        })}
                    >
                        {blocks.map((block) => {
                            const hasSmsEventList = block?.smsEventList;
                            if (hasSmsEventList) {
                                return (
                                    <div
                                        css={css({ padding: "16px 24px" })}
                                        key={block.index}
                                    >
                                        table
                                    </div>
                                );
                            }
                            return (
                                <TypedGrid
                                    key={block.index}
                                    item
                                    xs0={12}
                                    xl={orientation === "Vertical" ? 12 : 6}
                                >
                                    <Block data={block} />
                                </TypedGrid>
                            );
                        })}
                    </TypedGrid>
                </Fragment>
            </Collapse>
        </Fragment>
    );
};

export default DefaultFrame;
