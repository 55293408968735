/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import { Frame } from "../../../generatedTypes";
import FormModal from "./FormModal";
import TypedGrid from "../TypedGrid";
import Block from "../../Editor/MenuItemView/Block";
import { useContext } from "react";
import LanguageContext from "../../../context/language/languageContext";

export interface DynamicContentModalProps {
    isOpen: boolean;
    onClose: () => void;
    modalData: Frame;
}

const DynamicContentModal: React.FunctionComponent<
    DynamicContentModalProps
> = ({ isOpen, onClose, modalData }) => {
    const { t } = useContext(LanguageContext);
    return (
        <FormModal
            isOpen={isOpen}
            onClose={onClose}
            title={modalData.name || ""}
            description={modalData.description}
            customModalStyles={{ maxWidth: "920px" }}
            onSubmit={() => {}}
            secondaryButtonText={t.Close}
        >
            <TypedGrid
                container
                spacing={3}
                css={css`
                    padding: 0px;
                `}
            >
                {modalData?.blocks.map((block) => (
                    <TypedGrid
                        key={block.index}
                        item
                        xs0={12}
                        lg={modalData?.orientation === "Vertical" ? 12 : 6}
                    >
                        <Block data={block} />
                    </TypedGrid>
                ))}
            </TypedGrid>
        </FormModal>
    );
};

export default DynamicContentModal;
