/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from "@emotion/react";

import useMediaQuries from "../../../utils/useMediaQueries";
import SidebarSkeleton from "./SidebarSkeleton";
import TopBarSkeleton from "./TopBarSkeleton";
import GeneralSkeleton from "./GeneralSkeleton";
import BottomBarSkeleton from "./BottomBarSkeleton";

const EditorSkeleton = () => {
    const { fromMd } = useMediaQuries();

    return (
        <div
            css={css`
                display: flex;
                min-height: 100vh;

                @media (max-width: 839px) {
                    flex-direction: column;
                }
            `}
        >
            <SidebarSkeleton />

            <div
                css={css`
                    display: flex;
                    flex-direction: column;
                    flex: 1;
                    @media (max-width: 839px) {
                        justify-content: space-between;
                    }
                `}
            >
                {fromMd && <TopBarSkeleton />}

                <GeneralSkeleton />

                <BottomBarSkeleton />
            </div>
        </div>
    );
};

export default EditorSkeleton;
