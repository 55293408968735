export const SET_INITIAL_INPUTS = "SET_INITIAL_INPUTS";
export const IMPORT_INPUT_VALUES = "IMPORT_INPUT_VALUES";
export const CLEAR_INPUTS = "CLEAR_INPUTS";
export const CHANGE_INPUTS = "CHANGE_INPUTS";
export const ADD_INPUT = "ADD_INPUT";
export const SAVE_VALUES = "SAVE_VALUES";
export const CLOSE_WITHOUT_SAVING = "CLOSE_WITHOUT_SAVING";
export const HANDLE_CONFIRMATION_DIALOG = "HANDLE_CONFIRMATION_DIALOG";
export const SET_SAVE_LOADING = "SET_SAVE_LOADING";
export const SET_ERROR = "SET_ERROR";
export const SET_PREVIOUS_LANGUAGE = "SET_PREVIOUS_LANGUAGE";
export const SET_INPUT_COUNT = "SET_INPUT_COUNT";
