export const SET_GEOFENCE_PARAMETERS = "SET_GEOFENCE_PARAMETERS";
export const SEARCH_GEOFENCE_PARAMETERS = "SEARCH_GEOFENCE_PARAMETERS";
export const SET_EDIT_STATE = "SET_EDIT_STATE";
export const SET_HIGHLIGHTED_GEOZONE = "SET_HIGHLIGHTED_GEOZONE";
export const SET_CENTER_COORDINATES = "SET_CENTER_COORDINATES";
export const SET_GEOZONES = "SET_GEOZONES";
export const RESET_ALL_FILTERS = "RESET_ALL_FILTERS";
export const SET_ACTIVE_SHAPE = "SET_ACTIVE_SHAPE";
export const SET_PRIORITY = "SET_PRIORITY";
export const SET_OPERAND = "SET_OPERAND";
export const SET_SHAPE_PARAMETER = "SET_SHAPE_PARAMETER";
export const SET_LATITUDE_LONGITUDE_VALUES = "SET_LATITUDE_LONGITUDE_VALUES";
export const SET_GEOZONE_DISABLED = "SET_GEOZONE_DISABLED";
export const SET_RADIUS = "SET_RADIUS";
export const SET_AVAILABLE_SHAPES = "SET_AVAILABLE_SHAPES";
export const SET_LOADING = "SET_LOADING";
export const SET_REFETCH_LIST = "SET_REFETCH_LIST";
