/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import { Fragment, useContext, useEffect } from "react";
import FeaturesContext from "../../../../../../../context/features/featuresContext";
import TypedGrid from "../../../../../../MuiComponents/TypedGrid";
import Collection from "../../../Component/Collection";
import TimeInput from "../TimeInput";
import { ScheduleEndSettings, ScheduleStartSettings } from "../../../../../../../generatedTypes";

export interface TimelineCardProps {
    title: string;
    settings: ScheduleStartSettings | ScheduleEndSettings;
}

const TimelineCard = ({ title, settings }: TimelineCardProps) => {
    if(!settings || !settings.day || !settings.month || !settings.time || !settings.week) {
        throw new Error("Day, month, time and week parameters are required for TimelineCard component");
    }
    
    const {
        setParameterValue,
        removeParameterValueById,
        findDefaultParameterValueById,
    } = useContext(FeaturesContext);

    useEffect(() => {
        return () => {
            // @ts-ignore month is not undefined, because it is checked above
            removeParameterValueById(settings.month.parameterId);
            // @ts-ignore week is not undefined, because it is checked above
            removeParameterValueById(settings.week.parameterId);
            // @ts-ignore day is not undefined, because it is checked above
            removeParameterValueById(settings.day.parameterId);
            // @ts-ignore time is not undefined, because it is checked above
            removeParameterValueById(settings.time.parameterId);
        };
        // eslint-disable-next-line
    }, []);

    const handleOnChange = (event: { id: number; value: string }) => {
        setParameterValue({
            parameterId: event.id,
            value: event.value,
        });
    };
    return (
        <Fragment>
            <div
                css={css({
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",

                    h4: {
                        display: "flex",
                        alignItems: "center",
                        fontSize: "16px",
                        lineHeight: "24px",
                        fontWeight: "600px",
                        letterSpacing: "0.15px",
                        margin: "12px 0px",
                    },
                })}
            >
                <h4>{title}</h4>
            </div>
            <TypedGrid container rowSpacing={2}>
                <TypedGrid container item justifyContent="space-between">
                    <TypedGrid item xs={5}>
                        <Collection
                            data={settings.month}
                            onChange={handleOnChange}
                            defaultValue={findDefaultParameterValueById(
                                settings.month.parameterId
                            )}
                            hasNoDependencies
                        />
                    </TypedGrid>
                    <TypedGrid item xs={5}>
                        <Collection
                            data={settings.week}
                            onChange={handleOnChange}
                            defaultValue={findDefaultParameterValueById(
                                settings.week.parameterId
                            )}
                            hasNoDependencies
                        />
                    </TypedGrid>
                </TypedGrid>
                <TypedGrid
                    container
                    item
                    justifyContent="space-between"
                    alignItems={"flex-end"}
                >
                    <TypedGrid item xs={5}>
                        <Collection
                            data={settings.day}
                            defaultValue={findDefaultParameterValueById(
                                settings.day.parameterId
                            )}
                            onChange={handleOnChange}
                            hasNoDependencies
                        />
                    </TypedGrid>
                    <TypedGrid item xs={5}>
                        <TimeInput data={settings.time} />
                    </TypedGrid>
                </TypedGrid>
            </TypedGrid>
        </Fragment>
    );
};

export default TimelineCard;
