import { Radio } from "@mui/material";
import React from "react";

export interface RadioButtonProps {
    checked: boolean;
    onChange: (event: React.ChangeEvent<HTMLInputElement> | number) => void;
    value: number;
}

const RadioButton = ({ checked, onChange, value }: RadioButtonProps) => {
    return (
        <Radio
            checked={checked}
            onChange={onChange}
            value={value}
            inputProps={{ "aria-label": String(value) }}
            sx={{
                "&.MuiRadio-root": {
                    padding: 0,
                    "&:hover": {
                        backgroundColor: "unset",
                    },
                },
            }}
        />
    );
};

export default RadioButton;
