import React, { useContext } from "react";
import AlertContext from "../../../../../context/alert/alertContext";
import LanguageContext from "../../../../../context/language/languageContext";
import LayoutContext from "../../../../../context/layout/layoutContext";
import useApi from "../../../../../utils/useApi";
import ConfirmModal from "../../../../MuiComponents/Modals/ConfirmModal";
import { AlertStatus } from "../../../../../constants/constants";

export interface RebootAgreementModalProps {
    open: boolean;
    close: () => void;
}

const RebootAgreementModal = ({ open, close }: RebootAgreementModalProps) => {
    const { setAlert } = useContext(AlertContext);

    const { selectedLanguage, t } = useContext(LanguageContext);

    const { startActionLoading, stopActionLoading } = useContext(LayoutContext);

    const { getData, showErrorMsg } = useApi();

    const handleSubmit = async (e: React.SyntheticEvent<Element, Event>) => {
        e.preventDefault();
        close();

        try {
            startActionLoading("rebooting");

            await getData(`${selectedLanguage}/device/reboot`);

            setAlert(AlertStatus.Success, t.RebootComplete);
        } catch (error: any) {
            showErrorMsg(error);
        }

        stopActionLoading();
    };

    return (
        <ConfirmModal
            open={open}
            close={close}
            submit={handleSubmit}
            title={t.RebootConfirm}
        />
    );
};

export default RebootAgreementModal;
