import { createContext } from "react";

export enum EditorState {
    None,
    Start,
    Cancel,
    Commit,
}
export interface GeozoneEditState {
    currentIndex: number;
    previousIndex: number;
    currentEditorState: EditorState;
}

export interface ManualGeofenceContextState {
    setPreviousGeofenceParameters: (payload: any) => void;
    setGeofenceSearchValue: (payload: any) => void;
    previousGeofenceParameters: any;
    geofenceSearchValue: string;
    editState: GeozoneEditState;
    latitudeLongitudeParameters: {
        lat: number[];
        long: number[];
    };
    shapeParameter: any;
    isLoading: boolean;
    availableShapes: any[];
    setEditState: (payload: GeozoneEditState) => void;
    setHighlightedGeozone: (payload: any) => void;
    highlightedGeozone: number;
    setCenterCoordinates: (payload: [number, number]) => void;
    centerCoordinates: [number, number];
    setGeozones: (payload: any) => void;
    geozones: any;
    activePriority: any;
    setActivePriority: (priority: { value: string; text: string }[]) => void;
    activeOperand: any;
    setActiveOperand: (operand: { value: string; text: string }[]) => void;
    activeShape: any;
    setActiveShape: (shape: { value: string; text: string }[]) => void;
    resetAllFilters: () => void;
    previousGeozones: any;
    setLatitudeLongituteValues: (payload: { lat: any[]; long: any[] }) => void;
    setShapeParameter: (payload: any) => void;
    setGeozoneDisabled: (payload: any) => void;
    isSaveDisabled: boolean;
    setRadius: (payload: any) => void;
    radius: number;
    setAvailableShapes: (payload: any) => void;
    setLoading: (payload: boolean) => void;
    setRefetchList: (payload: boolean) => void;
    refetchList: boolean;
}

const ManualGeofenceContext = createContext({} as ManualGeofenceContextState);

export default ManualGeofenceContext;
