import React, { useContext } from "react";
import SettingsContext from "../../../../../context/settings/settingsContext";
import LatestNotifications from "./LatestNotifications";
import HistoryNotifications from "./HistoryNotifications";

const NotificationsList = () => {
    const { isReadTab } = useContext(SettingsContext);

    return (
        <div>
            {isReadTab ? <LatestNotifications/> : <HistoryNotifications/>}
        </div>
    );
};

export default NotificationsList;
