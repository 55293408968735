/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx, SerializedStyles } from "@emotion/react";

import { useContext, useState } from "react";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import { SearchRounded } from "@mui/icons-material";
import useDebounce from "../../../../../../utils/useDebounce";
import ThemeContext from "../../../../../../context/theme/themeContext";
import LanguageContext from "../../../../../../context/language/languageContext";
import LayoutContext from "../../../../../../context/layout/layoutContext";
import avlSearchContext from "../../../../../../context/avlSearch/avlSearchContext";
import useTct from "../../../../../../utils/useTct";

interface SearchInputProps {
    filled?: boolean;
    wrapperStyle?: SerializedStyles;
}

const SearchInput = ({ filled, wrapperStyle, ...props }: SearchInputProps) => {
    const {
        colors: { white, gray700, textPlaceholder, gray50 },
    } = useContext(ThemeContext);
    const { t } = useContext(LanguageContext);
    const { layoutData } = useContext(LayoutContext);
    const { setResults } = useContext(avlSearchContext);

    const { searchAvlIdsAsync } = useTct();

    const [value, setValue] = useState("");

    const searchForEvents = async (searchValue: string) => {
        try {
            if(!layoutData)
                throw new Error("Cannot search for avl ids without a layout");
            
            const data = await searchAvlIdsAsync(layoutData.id, searchValue);
            
            setResults(data);
        } catch (error) {
            setResults([]);
            console.log(error);
        }
    };

    const debouncedChangeHandler = useDebounce(searchForEvents, 500);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const searchValue = e.target.value;

        setValue(searchValue);

        debouncedChangeHandler(searchValue);
    };

    return (
        <div
            css={css({
                flex: "1",
                ...wrapperStyle,
            })}
        >
            <TextField
                {...props}
                autoFocus
                fullWidth
                variant="filled"
                placeholder={t.Search}
                value={value}
                onChange={handleChange}
                InputProps={{
                    spellCheck: "false",
                    disableUnderline: true,
                    startAdornment: (
                        <InputAdornment position="start">
                            <SearchRounded
                                style={{
                                    color: textPlaceholder,
                                }}
                            />
                        </InputAdornment>
                    ),
                    sx: {
                        backgroundColor: "rgba(255, 255, 255, 0.05)",
                        color: textPlaceholder,
                        fontWeight: "600",
                        lineHeight: "20px",
                        letterSpacing: "0.1px",
                        padding: "6px 12px",
                        height: "52px",
                        borderBottom: "1px solid #CBD5E1",
                        "&:hover": {
                            background: gray50,
                        },

                        "&.Mui-focused": {
                            background: gray50,
                        },

                        "& .MuiFilledInput-input": {
                            padding: "0",

                            "&::placeholder": {
                                color: filled ? white : gray700,
                                lineHeight: "20px",
                                letterSpacing: "0.1px",
                            },
                        },

                        "& .MuiInputAdornment-root.MuiInputAdornment-positionStart.MuiInputAdornment-root:not(.MuiInputAdornment-hiddenLabel)":
                            {
                                marginTop: "0",
                            },

                        "& .MuiInputAdornment-root": {
                            color: filled ? white : gray700,
                            height: "unset",
                        },
                    },
                }}
            />
        </div>
    );
};

export default SearchInput;
