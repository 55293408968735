/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from "@emotion/react";

import { Fragment, useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import LanguageContext from "../../../../../context/language/languageContext";
import useApi from "../../../../../utils/useApi";
import useMediaQuries from "../../../../../utils/useMediaQueries";
import Button from "../../../../MuiComponents/Button";
import OpenIcon from "@mui/icons-material/FileUploadRounded";
import MoreIcon from "@mui/icons-material/MoreVert";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { CircularProgress } from "@mui/material";
import useUploadFile from "../../../../../utils/useUploadFile";
import { EDITOR_STATUS } from "../../../../../constants/routes";
import UserInfoContext from "../../../../../context/userInfo/userInfoContext";
import useConfiguration from "../../../../../utils/useConfiguration";

const Management = () => {
    const { t } = useContext(LanguageContext);
    const { isDesktopApplication } = useContext(UserInfoContext);

    const history = useHistory();

    const { showErrorMsg } = useApi();
    const { getConfigurationFromFile } = useConfiguration();

    const { toXl, toMd } = useMediaQuries();

    const [anchorEl, setAnchorEl] = useState(null);
    const [loading, setLoading] = useState(false);
    const openMenu = (e: any) => setAnchorEl(e.currentTarget);
    const closeMenu = () => setAnchorEl(null);

    const { inputProps, selectedFile, openFileDialog } = useUploadFile({
        accept: ".cfg, .config",
    });

    useEffect(() => {
        if (selectedFile) {
            const getLayoutData = async () => {
                try {
                    setLoading(true);

                    await getConfigurationFromFile(selectedFile);

                    setLoading(false);
                    history.push(EDITOR_STATUS);
                } catch (error: any) {
                    setLoading(false);
                    showErrorMsg(error);
                }
            };

            getLayoutData();
        }
    }, [selectedFile]);

    const renderButtons = () => {
        return (
            <Fragment>
                {toXl ? (
                    <Fragment>
                        <Button
                            variant="iconOnly"
                            size="small"
                            color="white"
                            icon={<MoreIcon />}
                            onClick={openMenu}
                            idForTesting="more-btn"
                        />

                        <Menu
                            anchorEl={anchorEl}
                            open={Boolean(anchorEl)}
                            onClose={closeMenu}
                            anchorOrigin={{
                                vertical: "bottom",
                                horizontal: toMd ? "right" : "center",
                            }}
                            transformOrigin={{
                                vertical: "top",
                                horizontal: toMd ? "right" : "center",
                            }}
                            css={css`
                                img {
                                    margin-right: 8px;
                                }
                            `}
                        >
                            <MenuItem
                                onClick={openFileDialog}
                                disabled={loading}
                            >
                                {loading ? (
                                    <CircularProgress
                                        size={13}
                                        css={css`
                                            position: relative;
                                        `}
                                    />
                                ) : (
                                    <OpenIcon />
                                )}

                                {t.Open}
                            </MenuItem>

                            {/* Compare functionality is not yet implemented
                            <MenuItem>
                                <CompareIcon />

                                {t.Compare}
                            </MenuItem>*/}
                        </Menu>
                    </Fragment>
                ) : (
                    <Fragment>
                        <Button
                            variant="iconLeft"
                            size="small"
                            color="white"
                            idForTesting="open-btn"
                            icon={
                                loading ? (
                                    <CircularProgress
                                        size={13}
                                        css={css`
                                            position: relative;
                                        `}
                                    />
                                ) : (
                                    <OpenIcon />
                                )
                            }
                            onClick={openFileDialog}
                            css={css`
                                margin-right: 24px;
                            `}
                            disabled={loading}
                        >
                            {t.Open}
                        </Button>

                        {/* Compare functionality is not yet implemented
                       <Button
                            variant="iconLeft"
                            size="small"
                            color="white"
                            icon={<CompareIcon />}
                        >
                            {t.Compare}
                        </Button>*/}
                    </Fragment>
                )}
                <input {...inputProps} />
            </Fragment>
        );
    };

    return (
        <div
            css={css`
                @media (max-width: 839px) {
                    order: 3;
                    margin-left: 8px;
                }
            `}
        >
            {isDesktopApplication ? renderButtons() : null}
        </div>
    );
};

export default Management;
