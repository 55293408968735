import React from "react";
import SvgIcon from "@mui/material/SvgIcon";

const AccessoriesIcon = (props: any) => {
    return (
        <SvgIcon {...props}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12 6C12.5523 6 13 5.55229 13 5C13 4.44772 12.5523 4 12 4C11.4477 4 11 4.44772 11 5C11 5.55228 11.4477 6 12 6ZM15 5C15 6.30622 14.1652 7.41746 13 7.82929V8.25601L15.8621 9.11464C17.131 9.49532 18 10.6633 18 11.9881V16.0114C18 16.3913 17.9285 16.7583 17.7967 17.0975L18.337 17.5027C18.813 17.1851 19.3849 17 20 17C21.6569 17 23 18.3431 23 20C23 21.6569 21.6569 23 20 23C18.3431 23 17 21.6569 17 20C17 19.6873 17.0478 19.3858 17.1366 19.1024L16.4885 18.6164C16.2932 18.7279 16.0832 18.8186 15.861 18.8852L12.574 19.87C12.5208 19.8859 12.4671 19.8996 12.4131 19.911C12.2871 19.9682 12.1473 20 12 20C11.8527 20 11.7129 19.9682 11.5869 19.911C11.5329 19.8996 11.4792 19.8859 11.426 19.87L8.13903 18.8852C7.91676 18.8186 7.70675 18.7279 7.51153 18.6164L6.86342 19.1024C6.95217 19.3858 7 19.6873 7 20C7 21.6569 5.65685 23 4 23C2.34315 23 1 21.6569 1 20C1 18.3431 2.34315 17 4 17C4.61514 17 5.18704 17.1851 5.66301 17.5027L6.2033 17.0975C6.07146 16.7583 6 16.3913 6 16.0114V11.9881C6 10.6633 6.869 9.49532 8.13795 9.11464L11 8.25601V7.82929C9.83481 7.41746 9 6.30622 9 5C9 3.34315 10.3431 2 12 2C13.6569 2 15 3.34315 15 5ZM8 13.2208V16.0114C8 16.4531 8.28985 16.8426 8.71301 16.9693L11 17.6545V14.2208L8 13.2208ZM13 17.6545V14.2208L16 13.2208V16.0114C16 16.4531 15.7102 16.8426 15.287 16.9693L13 17.6545ZM15.6509 11.2289L12 12.4459L8.34905 11.2289C8.45274 11.14 8.57571 11.0714 8.71265 11.0303L12 10.0441L15.2874 11.0303C15.4243 11.0714 15.5473 11.14 15.6509 11.2289ZM4 21C4.55229 21 5 20.5523 5 20C5 19.4477 4.55228 19 4 19C3.44772 19 3 19.4477 3 20C3 20.5523 3.44772 21 4 21ZM21 20C21 20.5523 20.5523 21 20 21C19.4477 21 19 20.5523 19 20C19 19.4477 19.4477 19 20 19C20.5523 19 21 19.4477 21 20Z"
                fill="currentColor"
            />
        </SvgIcon>
    );
};

export default AccessoriesIcon;
