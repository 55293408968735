/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import React, { Fragment, useContext } from "react";
import { SimSecurityStatuses } from "../../../../../constants/constants";
import Box from "../../../../Box";
import Badge, { BadgeColors } from "../../../../MuiComponents/Badge";
import Button from "../../../../MuiComponents/Button";
import RadioButton from "../../../../MuiComponents/RadioButton";
import TypedGrid from "../../../../MuiComponents/TypedGrid";
import { ModalType, SimPinModalTypes } from "./PinManagementModal";
import { noop } from "../../../../../utils/helpers";
import { SimStatus } from "../../../../../generatedTypes";
import LanguageContext from "../../../../../context/language/languageContext";
import Divider from "../../../../MuiComponents/Divider";

type ModalOpenEventType = React.ChangeEvent<HTMLButtonElement>;

export interface SimRadioButtonProps {
    simId: number;
    simName: string;
    simStatus: SimStatus;
    simImsi: number;
    simLockEnabled: boolean;
    onRadioButtonChange: (
        event: React.ChangeEvent<HTMLInputElement> | number
    ) => void;
    radioButtonValue: number;
    isActive: boolean;
    checked: boolean;
    openModal: (modalType: ModalType) => void;
    simIccid: string;
}

const SimRadioButton = ({
    simId,
    simIccid,
    simName,
    simStatus,
    onRadioButtonChange,
    radioButtonValue,
    simImsi,
    simLockEnabled,
    isActive,
    checked,
    openModal,
}: SimRadioButtonProps) => {
    const { t } = useContext(LanguageContext);

    const isSimCardReady = simStatus?.status === SimSecurityStatuses.SimReady;
    const isSimCardNeedPin = simStatus?.status === SimSecurityStatuses.NeedPin;

    const openPinModal = (e: ModalOpenEventType, modalType: ModalType) => {
        e.stopPropagation();
        openModal(modalType);
    };

    const getModalType = () => {
        if (isSimCardReady) {
            return simLockEnabled
                ? SimPinModalTypes.DisablePin
                : SimPinModalTypes.EnablePin;
        } else {
            return SimPinModalTypes.EnterPin;
        }
    };

    const getModalButtonName = () => {
        if (isSimCardReady) {
            return simLockEnabled ? t.DisablePin : t.EnablePin;
        } else {
            return t.EnterPin;
        }
    };

    const isRadioButton = radioButtonValue !== -1;

    const handleRadioButtonClick = () =>
        isRadioButton ? onRadioButtonChange(radioButtonValue) : noop();

    return (
        <Box
            onClick={handleRadioButtonClick}
            css={css({
                padding: "16px 18px",
                marginBottom: "8px",
                cursor: isRadioButton ? "pointer" : "auto",
            })}
        >
            <TypedGrid container>
                {isRadioButton && (
                    <TypedGrid item xs={1}>
                        <RadioButton
                            checked={checked}
                            onChange={onRadioButtonChange}
                            value={radioButtonValue}
                        />
                    </TypedGrid>
                )}

                <TypedGrid
                    item
                    xs={isRadioButton ? 11 : 12}
                    container
                    flexDirection="column"
                >
                    <div
                        css={css({
                            fontWeight: 600,
                            fontSize: "16px",
                            lineHeight: "20px",
                            marginBottom: "8px",
                        })}
                    >
                        {simName}
                    </div>
                    <Divider />
                    <TypedGrid container flexDirection={"column"}>
                        <TypedGrid container css={css({ padding: "8px 0px" })}>
                            <div
                                css={css({
                                    lineHeight: "20px",
                                    width: "50%",
                                })}
                            >
                                {t.SimStatus}
                            </div>

                            <div
                                css={css({
                                    width: "50%",
                                })}
                            >
                                {simStatus?.badge && (
                                    <Badge
                                        color={
                                            simStatus.badge
                                                .color as unknown as BadgeColors
                                        }
                                    >
                                        {simStatus.badge.label}
                                    </Badge>
                                )}
                            </div>
                        </TypedGrid>
                        {!!simImsi && isActive && (
                            <Fragment>
                                <Divider />
                                <TypedGrid
                                    container
                                    css={css({ padding: "8px 0px" })}
                                >
                                    <div css={css({ width: "50%" })}>IMSI</div>

                                    <div
                                        css={css({
                                            lineHeight: "20px",
                                            letterSpacing: "0.15px",
                                            width: "50%",
                                        })}
                                    >
                                        {simImsi}
                                    </div>
                                </TypedGrid>
                                <Divider />
                            </Fragment>
                        )}
                        {!!simIccid && isActive && (
                            <Fragment>
                                <TypedGrid
                                    container
                                    css={css({ padding: "8px 0px" })}
                                >
                                    <div css={css({ width: "50%" })}>ICCID</div>

                                    <div
                                        css={css({
                                            lineHeight: "20px",
                                            letterSpacing: "0.15px",
                                            width: "50%",
                                        })}
                                    >
                                        {simIccid}
                                    </div>
                                </TypedGrid>
                            </Fragment>
                        )}
                    </TypedGrid>

                    <TypedGrid container columnSpacing={2}>
                        <TypedGrid
                            item
                            xs={isSimCardReady && simLockEnabled ? 6 : 12}
                        >
                            {isActive && isSimCardReady && simLockEnabled && (
                                <Button
                                    variant="textOnly"
                                    size="small"
                                    color="primarySubtle"
                                    onClick={(e: ModalOpenEventType) =>
                                        openPinModal(
                                            e,
                                            SimPinModalTypes.ChangePin
                                        )
                                    }
                                    fullWidth
                                    css={css({
                                        marginTop: "16px",
                                        padding: "12px 16px",
                                    })}
                                    idForTesting="changePinButton"
                                >
                                    {t.ChangePin}
                                </Button>
                            )}
                        </TypedGrid>
                        <TypedGrid item xs={isSimCardReady ? 6 : 12}>
                            {isActive &&
                                (isSimCardReady || isSimCardNeedPin) && (
                                    <Button
                                        variant="textOnly"
                                        size="small"
                                        color="primarySubtle"
                                        onClick={(e: ModalOpenEventType) =>
                                            openPinModal(e, getModalType())
                                        }
                                        fullWidth
                                        css={css({
                                            marginTop: "16px",
                                            padding: "12px 16px",
                                        })}
                                        idForTesting="enterPinButton"
                                    >
                                        {getModalButtonName()}
                                    </Button>
                                )}
                        </TypedGrid>
                    </TypedGrid>
                </TypedGrid>
            </TypedGrid>
        </Box>
    );
};

export default SimRadioButton;
