import {
    SET_POPOVER_ANCHOR,
    SET_RESULTS,
    SELECT_SEARCH_RESULT,
    SET_ACTIVE_PARAMETER_ID,
    SET_PARAMETER_NAMES,
    SET_INITIAL_PARAMETER_NAMES,
} from "./avlSearchActions";

export interface InitialState {
    openModalAnchor: any;
    results: any[] | null;
    result: any;
    activeParameterId: string;
    parameterNames: {
        parameterId: string;
        parameterName: string;
    }[];
}

type Action =
    | { type: typeof SET_POPOVER_ANCHOR; payload: any }
    | {
          type: typeof SET_RESULTS;
          payload: any[] | null;
      }
    | { type: typeof SELECT_SEARCH_RESULT; payload: any }
    | {
          type: typeof SET_ACTIVE_PARAMETER_ID;
          payload: string;
      }
    | {
          type: typeof SET_PARAMETER_NAMES;
          payload: { parameterId: string; parameterName: string };
      }
    | {
          type: typeof SET_INITIAL_PARAMETER_NAMES;
          payload: { parameterId: string; parameterName: string }[];
      };

export default (state: InitialState, action: Action): InitialState => {
    switch (action.type) {
        case SET_ACTIVE_PARAMETER_ID: {
            return {
                ...state,
                activeParameterId: action.payload,
            };
        }
        case SET_POPOVER_ANCHOR: {
            return {
                ...state,
                openModalAnchor: action.payload,
                results: null,
            };
        }
        case SET_RESULTS: {
            return {
                ...state,
                results: action.payload,
            };
        }
        case SELECT_SEARCH_RESULT: {
            return {
                ...state,
                openModalAnchor: null,
                result: action.payload,
            };
        }
        case SET_PARAMETER_NAMES: {
            const addValueToArray = (
                array: any[],
                parameterName: string,
                parameterId: string
            ) => {
                const newArray = [...array];
                const index = newArray.findIndex(
                    (item) => item.parameterId === parameterId
                );
                if (index === -1) {
                    newArray.push({ parameterName, parameterId });
                } else {
                    newArray[index] = { parameterName, parameterId };
                }
                return newArray;
            };
            return {
                ...state,
                parameterNames: !action.payload
                    ? []
                    : addValueToArray(
                          state.parameterNames,
                          action.payload.parameterName,
                          action.payload.parameterId
                      ),
            };
        }
        case SET_INITIAL_PARAMETER_NAMES: {
            return {
                ...state,
                parameterNames: action.payload,
            };
        }
        default:
            return state;
    }
};
