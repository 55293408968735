/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/react";
import { Fragment } from "react";
import PopOverInput from "./PopOverInput";
import SearchPopOver from "./SearchPopOver";

export interface AvlSearchProps {
    data: any;
}

const AvlSearch = ({ data }: AvlSearchProps) => {
    return (
        <Fragment key={data.parameterId}>
            <PopOverInput data={data} />
            <SearchPopOver data={data} />
        </Fragment>
    );
};

export default AvlSearch;
