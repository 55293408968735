import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga4";

const useGoogleAnalytics = () => {
    const { pathname, search } = useLocation();

    const [initialized, setInitialized] = useState(false);
    const fullPageUrl = `${pathname}${search}`;
    const trackingId = process.env.REACT_APP_TRACKING_ID;

    useEffect(() => {
        if (!initialized && trackingId) {
            ReactGA.initialize(trackingId);
            setInitialized(true);
        }
    }, [initialized]);

    useEffect(() => {
        if (initialized) {
            ReactGA.send({ hitType: "pageview", page: fullPageUrl });
        }
    }, [initialized, pathname, fullPageUrl, search]);

    const sendSelectedFrame = (name: string) => {
        if (initialized) {
            ReactGA.gtag("event", "select_content", {
                content_type: "Frame",
                item_id: name,
            });
        }
    };

    return {
        sendSelectedFrame,
    };
};

export default useGoogleAnalytics;
