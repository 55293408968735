import {
    SET_LOADING,
    HANDLE_CHANGE_PASSWORD,
    HANDLE_VISIBILITY,
    HANDLE_DISABLE,
} from "./setNewPasswordActions";

export interface InitialState {
    isLoading: boolean;
    isDisabled: boolean;
    password: string;
    passwordRepeat: string;
    passwordShow: boolean;
    passwordRepeatShow: boolean;
}

type Action =
    | {
          type: typeof SET_LOADING;
          payload: boolean;
      }
    | {
          type: typeof HANDLE_CHANGE_PASSWORD;
          payload: {
              name: string;
              value: string;
          };
      }
    | {
          type: typeof HANDLE_VISIBILITY;
          payload: "passwordShow" | "passwordRepeatShow";
      }
    | {
          type: typeof HANDLE_DISABLE;
          payload: boolean;
      };

export default (state: InitialState, action: Action) => {
    switch (action.type) {
        case SET_LOADING: {
            return {
                ...state,
                isLoading: action.payload,
            };
        }
        case HANDLE_CHANGE_PASSWORD: {
            const { payload } = action;

            return {
                ...state,
                [payload.name]: payload.value,
            };
        }
        case HANDLE_VISIBILITY: {
            const { payload } = action;

            return {
                ...state,
                [payload]: !state[payload],
            };
        }
        case HANDLE_DISABLE: {
            return {
                ...state,
                isDisabled: !action.payload,
            };
        }
        default:
            return state;
    }
};
