import React from "react";
import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

const ReportIcon = (props: SvgIconProps) => {
    return (
        <SvgIcon {...props}>
            <path d="M4.90095 3H20.0991C21.1446 3 22 3.855 22 4.9V16.3C22 17.345 21.1446 18.2 20.0991 18.2H6.7924L3 22V4.9C3 3.855 3.85543 3 4.90095 3ZM14.001 9C14.001 9.55 13.5508 10 13.0005 10C12.4502 10 12 9.55 12 9V7C12 6.45 12.4502 6 13.0005 6C13.5508 6 14.001 6.45 14.001 7V9ZM14.001 14H12V12H14.001V14Z" />
        </SvgIcon>
    );
};

export default ReportIcon;
