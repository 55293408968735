import { useEffect, useRef } from "react";

const useInterval = (callback: () => void, delay: number | null) => {
    const savedCallback = useRef() as any;
    useEffect(() => {
        savedCallback.current = callback;
        // eslint-disable-next-line
    }, [callback]);

    useEffect(() => {
        function tick() {
            savedCallback.current();
        }
        if (delay !== null) {
            let id = setInterval(tick, delay);
            return () => clearInterval(id);
        }
        // null to reset
        // eslint-disable-next-line
    }, [delay]);
};

export default useInterval;
