/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from "@emotion/react";

import { Fragment, useContext } from "react";
import ThemeContext from "../../../../../../../context/theme/themeContext";
import { ListItemInterface } from "../List";
import ListItem from "./ListItem";

export interface ListItemProps {
    items: ListItemInterface[];
}

const ListItems = ({ items }: ListItemProps) => {
    const {
        colors: { gray700, gray300 },
    } = useContext(ThemeContext);

    const renderItems = () => {
        if (items.length > 6) {
            const firstFourItems = items.slice(0, 4);

            return (
                <Fragment>
                    {firstFourItems.map(
                        ({ index, value, parameterId }: ListItemInterface) => (
                            <ListItem
                                key={index}
                                index={index}
                                value={value}
                                parameterId={parameterId}
                            />
                        )
                    )}

                    <div>...</div>

                    <ListItem
                        index={items[items.length - 1].index}
                        value={items[items.length - 1].value}
                        parameterId={items[items.length - 1].parameterId}
                    />
                </Fragment>
            );
        }

        return items.map(({ index, value, parameterId }: ListItemInterface) => (
            <ListItem
                key={index}
                index={index}
                value={value}
                parameterId={parameterId}
            />
        ));
    };

    return (
        <div
            css={css`
                margin-bottom: 16px;

                & > div {
                    padding-bottom: 8px;
                    border-bottom: 1px solid ${gray300};
                    font-size: 14px;
                    line-height: 20px;
                    font-weight: 600;
                    letter-spacing: 0.1px;
                    color: ${gray700};
                }

                & > div:last-of-type {
                    padding-bottom: 0;
                    border-bottom: none;
                }

                & > div + div {
                    margin-top: 8px;
                }
            `}
        >
            {renderItems()}
        </div>
    );
};

export default ListItems;
