import React, {
    ReactNode,
    useContext,
    useEffect,
    useMemo,
    useRef,
    useState,
} from "react";
import WebSocketsContext from "./webSocketsContext";
import useWebSockets from "../../utils/useWebSockets";
import { DiscoveredDeviceData, RecordReadStatus } from "../../utils/types";
import SettingsContext from "../settings/settingsContext";
import UserInfoContext from "../userInfo/userInfoContext";
import AlertContext from "../alert/alertContext";
import DeviceStatusContext, {
    BundleProgress,
} from "../deviceStatus/deviceStatusContext";
import { atom, useSetAtom } from "jotai";
import {
    DeviceStatusInformation,
    TctUpdateInfo,
    ErrorMessage,
} from "../../generatedTypes";
import LanguageContext from "../language/languageContext";
import {
    AlertStatus,
    FirmwareType,
    FirmwareUpdateStages,
} from "../../constants/constants";

export interface WebSocketsStateProps {
    children: ReactNode;
}

export interface FirmwareUpdateResult {
    isSuccess: boolean;
}

export interface WebSocketsStateData {
    updateFirmwareAsync: (
        fwImagePath: string,
        progressBarName: string
    ) => Promise<any>;
    captureDumpAsync: () => Promise<any>;
    recordReadStatus: RecordReadStatus | null;
    setRecordReadStatus: React.Dispatch<
        React.SetStateAction<RecordReadStatus | null>
    >;
    updateDtbAsync: (path: string, progressBarName: string) => Promise<any>;
    updateModemAsync: (path: string, progressBarName: string) => Promise<any>;
}

export const DiscoveredDevicesAtom = atom([] as DiscoveredDeviceData[]);
export const DeviceStatusAtom = atom(null as DeviceStatusInformation | null);
export const IoStatusAtom = atom(null as any);
export const ActiveGsmDataModeAtom = atom(-1);
export const FirmwareUpdateResultAtom = atom(
    null as FirmwareUpdateResult | null
);

export const TctUpdateAvailableAtom = atom({
    available: false,
    releaseDate: "",
    size: "",
    version: "",
} as TctUpdateInfo);

const WebSocketsState = ({ children }: WebSocketsStateProps) => {
    const { connection, startConnection, stopConnection } =
        useWebSockets("/applicationHub");

    const setDiscoveredDevices = useSetAtom(DiscoveredDevicesAtom);

    const {
        setUpdateDownloaded,
        setUpdateDownloading,
        setDownloadingPercentage,
        setNotification,
    } = useContext(SettingsContext);

    const { isDeviceDisconnected, setDeviceDisconnected } =
        useContext(UserInfoContext);
    const { setAlert } = useContext(AlertContext);
    const {
        setDumpReadingData,
        setDallasSensorData,
        setLlsSensorData,
        setProgressBar,
        setUpdateFirmwareModalInfo,
        updateFirmwareModalInfo,
        resetProgressBars,
        setBundleProgress,
    } = useContext(DeviceStatusContext);
    const { t } = useContext(LanguageContext);

    const updateFirmwareModalInfoRef = useRef(updateFirmwareModalInfo);
    updateFirmwareModalInfoRef.current = updateFirmwareModalInfo;

    const setDeviceStatus = useSetAtom(DeviceStatusAtom);
    const setActiveGsmDataMode = useSetAtom(ActiveGsmDataModeAtom);
    const setIoCurrentValues = useSetAtom(IoStatusAtom);
    const setFirmwareUpdateResult = useSetAtom(FirmwareUpdateResultAtom);
    const setIsTctUpdateAvailable = useSetAtom(TctUpdateAvailableAtom);

    const [recordReadStatus, setRecordReadStatus] = useState(
        null as RecordReadStatus | null
    );

    const handleProgressBar = (data: any) => {
        setProgressBar(data);
        if (
            data.stage === FirmwareUpdateStages.DeviceReboot ||
            data.stage === FirmwareUpdateStages.ModemReboot
        ) {
            setUpdateFirmwareModalInfo({
                isOpen: true,
                path: updateFirmwareModalInfoRef.current?.path,
                step: 2,
                newVersion: updateFirmwareModalInfoRef.current?.newVersion,
                firmwareType: updateFirmwareModalInfoRef.current?.firmwareType,
                currentVersion:
                    updateFirmwareModalInfoRef.current?.currentVersion,
                error: "",
            });
        }
    };

    const handleBundleProgressBar = (data: BundleProgress) => {
        setBundleProgress(data);
    };

    const handleDtbUpdate = (data: any) => {
        setFirmwareUpdateResult({ isSuccess: data.successfull });
        resetProgressBars();
        if (!data.successfull) {
            setUpdateFirmwareModalInfo({
                isOpen: true,
                path: updateFirmwareModalInfoRef.current?.path,
                step: 3,
                newVersion: updateFirmwareModalInfoRef.current?.newVersion,
                firmwareType: FirmwareType.Dtb,
                currentVersion:
                    updateFirmwareModalInfoRef.current?.currentVersion,
                error: data.message,
                stepWithError: updateFirmwareModalInfoRef.current?.step,
            });
            setNotification("error", t.Error, data.message);
            return;
        }
        setUpdateFirmwareModalInfo({
            isOpen: true,
            path: "",
            step: 3,
            newVersion: "",
            firmwareType: FirmwareType.Dtb,
            currentVersion: "",
            error: "",
        });
    };

    const handleModemUpdate = (data: any) => {
        resetProgressBars();
        if (!data.successfull) {
            setUpdateFirmwareModalInfo({
                isOpen: true,
                path: updateFirmwareModalInfoRef.current?.path,
                step: 3,
                newVersion: updateFirmwareModalInfoRef.current?.newVersion,
                firmwareType: FirmwareType.Modem,
                currentVersion:
                    updateFirmwareModalInfoRef.current?.currentVersion,
                error: data.message,
                stepWithError: updateFirmwareModalInfoRef.current?.step,
            });
            setNotification("error", t.Error, data.message);
            return;
        }
        setUpdateFirmwareModalInfo({
            isOpen: true,
            path: "",
            step: 3,
            newVersion: "",
            firmwareType: FirmwareType.Modem,
            currentVersion: "",
            error: "",
        });
    };

    const handleFirmwareUpdate = (data: any) => {
        setFirmwareUpdateResult({ isSuccess: data.successfull });
        resetProgressBars();
        if (!data.successfull) {
            setUpdateFirmwareModalInfo({
                isOpen: true,
                path: updateFirmwareModalInfoRef.current?.path,
                step: 3,
                newVersion: updateFirmwareModalInfoRef.current?.newVersion,
                firmwareType: FirmwareType.Firmware,
                currentVersion:
                    updateFirmwareModalInfoRef.current?.currentVersion,
                error: data.message,
                stepWithError: updateFirmwareModalInfoRef.current?.step,
            });
            setNotification("error", t.Error, data.message);
            return;
        }
        setUpdateFirmwareModalInfo({
            isOpen: true,
            path: "",
            step: 3,
            newVersion: "",
            firmwareType: FirmwareType.Firmware,
            currentVersion: "",
            error: "",
        });
    };

    useEffect(() => {
        if (connection) {
            if (isDeviceDisconnected) {
                connection.off("NotifyConnectionLost");
            }
            connection.on("AvailableDevicesUpdate", ({ devices }) => {
                setDiscoveredDevices(devices);
            });
            connection.on("AutoUpdatePercentage", (percentage) => {
                setDownloadingPercentage(percentage);
            });
            connection.on("UpdateProgressBar", (data) => {
                handleProgressBar(data);
            });
            connection.on("UpdateBundleProgressBar", (data) => {
                handleBundleProgressBar(data);
            });
            connection.on("DtbUpdateResult", (data) => {
                handleDtbUpdate(data);
            });
            connection.on("ModemUpdateResult", (data) => {
                handleModemUpdate(data);
            });
            connection.on("AutoUpdateDownloaded", () => {
                setUpdateDownloading(false);
                setUpdateDownloaded();
            });
            connection.on("ReceiveStatusUpdate", (data) =>
                setDeviceStatus(() => data)
            );
            connection.on("ReceiveDataModeUpdate", (columnIndex) => {
                setActiveGsmDataMode(columnIndex);
            });
            connection.on("NotifyConnectionLost", (_data) => {
                setAlert(
                    AlertStatus.Warning,
                    t.ConnectionToDeviceLostTitle,
                    t.ConnectionToDeviceLostDescription
                );
                setNotification(
                    AlertStatus.Warning,
                    t.ConnectionToDeviceLostTitle,
                    t.ConnectionToDeviceLostDescription
                );
                setDeviceDisconnected(true);
            });
            connection.on("FirmwareUpdateResult", (data) => {
                handleFirmwareUpdate(data);
            });
            connection.on("CaptureDumpResult", (data: string) => {
                if (data) {
                    console.log(
                        "CaptureDumpResult returned with error: ",
                        data
                    );
                } else {
                    console.log("CaptureDumpResult signal received");
                }
            });
            connection.on("DownloadChunkUpdate", (data: number) => {
                setDumpReadingData({
                    percents: data,
                });
            });
            connection.on(
                "DownloadFileUpdate",
                (data: { currentFile: number; filesCount: number }) => {
                    setDumpReadingData(data);
                }
            );
            connection.on("ReceiveDallasSensorsUpdate", (data) => {
                setDallasSensorData(data);
            });
            connection.on("ReceiveLlsSensorsUpdate", (data) => {
                setLlsSensorData(data);
            });
            connection.on("ReceiveIoUpdate", (ioData) => {
                setIoCurrentValues(JSON.parse(ioData));
            });
            connection.on("RichTextNotification", (data) => {
                const formatedData = JSON.parse(data);
                formatedData &&
                    setNotification(
                        formatedData.type.toLowerCase(),
                        "",
                        "",
                        formatedData.body
                    );
            });

            connection.on("ReceiveIsTctUpdateAvailable", (data) =>
                setIsTctUpdateAvailable(data)
            );

            connection.on(
                "RecordReadStatus",
                (recordStatus: RecordReadStatus | null) => {
                    console.log(
                        `downloaded: ${JSON.stringify(recordStatus)}% `
                    );
                    setRecordReadStatus(recordStatus);
                }
            );

            connection.on("AutoUpdateFailed", (errorMessage: ErrorMessage) => {
                setAlert(
                    AlertStatus.Critical,
                    errorMessage.title,
                    errorMessage.message
                );
                setUpdateDownloading(false);
                setDownloadingPercentage(0);
            });

            startConnection();
        }

        return () => {
            stopConnection();
            setIoCurrentValues({});
        };

        // eslint-disable-next-line
    }, [connection, isDeviceDisconnected]);

    const updateFirmwareAsync = (
        fwImagePath: string,
        progressBarName: string
    ) => {
        if (connection) {
            return connection.invoke(
                "UpdateFirmware",
                fwImagePath,
                progressBarName
            );
        }
        throw new ReferenceError(
            `Cannot invoke 'UpdateFirmware'. Web sockets connection is ${connection}`
        );
    };

    const captureDumpAsync = () => {
        if (connection) {
            return connection.invoke("CaptureDump");
        }
        throw new ReferenceError(
            `Cannot invoke 'CaptureDump'. Web sockets connection is ${connection}`
        );
    };

    const updateDtbAsync = (path: string, progressBarName: string) => {
        if (connection) {
            return connection.invoke("UpdateDtb", path, progressBarName);
        }
        throw new ReferenceError(
            `Cannot invoke 'UpdateDtb'. Web sockets connection is ${connection}`
        );
    };

    const updateModemAsync = (path: string, progressBarName: string) => {
        if (connection) {
            return connection.invoke("UpdateModem", path, progressBarName);
        }
        throw new ReferenceError(
            `Cannot invoke 'UpdateModem'. Web sockets connection is ${connection}`
        );
    };

    const soketState = useMemo(() => {
        return {
            updateFirmwareAsync,
            updateModemAsync,
            captureDumpAsync,
            recordReadStatus,
            setRecordReadStatus,
            updateDtbAsync,
        };
    }, [
        connection,
        updateFirmwareAsync,
        captureDumpAsync,
        recordReadStatus,
        updateDtbAsync,
        updateModemAsync,
    ]);

    return (
        <WebSocketsContext.Provider value={soketState}>
            {children}
        </WebSocketsContext.Provider>
    );
};

export default WebSocketsState;
