import { findAdjacentItem } from "../../../utils/helpers";
import {
    SET_ACTIVE_TAB_ID,
    SET_IS_OPEN_CONFIRMATION_MODAL,
    SET_DEVICES,
    SET_PORT_LIST_ANCHOR_EL,
    SET_SINGLE_PORT_LIST_ANCHOR_EL,
    SET_TERMINAL_SIZE,
    SET_ACTIVE_PORTS_FOR_DROPDOWN,
    SET_ACTIVE_TERMINAL_PORT,
    REMOVE_ACTIVE_TERMINAL_PORT,
    SET_REMOVE_PORT_MODAL_INFO,
    REMOVE_ALL_ACTIVE_TERMINAL_PORTS,
    SET_PORT_MESSAGES,
    SET_PORT_KEEP_FOLDER,
    SET_PORT_IS_LOGGING,
    SET_COMMAND,
    SET_COMMAND_HISTORY_INDEX,
    SET_COMMAND_HISTORY,
    CLEAR_PORT_MESSAGES_BY_PORT_ID,
} from "./terminalActions";

export default (state: any, action: any): any => {
    switch (action.type) {
        case SET_COMMAND: {
            return {
                ...state,
                command: action.payload,
            };
        }
        case SET_COMMAND_HISTORY_INDEX: {
            return {
                ...state,
                commandHistoryIndex: action.payload,
            };
        }
        case SET_ACTIVE_TAB_ID: {
            return {
                ...state,
                activeTabId: action.payload,
            };
        }
        case SET_IS_OPEN_CONFIRMATION_MODAL: {
            return {
                ...state,
                isOpenConfirmationModal: action.payload,
            };
        }
        case SET_TERMINAL_SIZE: {
            return {
                ...state,
                terminalSize: {
                    width: action.payload.width,
                    height: action.payload.height,
                },
            };
        }
        case SET_PORT_LIST_ANCHOR_EL: {
            return {
                ...state,
                portListAnchorEl: action.payload,
            };
        }
        case SET_SINGLE_PORT_LIST_ANCHOR_EL: {
            return {
                ...state,
                singlePortAnchorEl: action.payload,
            };
        }
        case SET_DEVICES: {
            return {
                ...state,
                devices: action.payload,
            };
        }
        case SET_ACTIVE_PORTS_FOR_DROPDOWN: {
            return {
                ...state,
                activePortsForDropdown: action.payload,
            };
        }
        case SET_ACTIVE_TERMINAL_PORT: {
            const addObjectToArrayIfNotExists = (
                array: any[],
                object: any
            ): any[] => {
                const existingObject = array.find(
                    (item) => item.id === object.id
                );
                if (!existingObject) {
                    return [...array, object];
                }
                return array;
            };
            return {
                ...state,
                activeTerminalPorts: addObjectToArrayIfNotExists(
                    state.activeTerminalPorts,
                    action.payload
                ),
            };
        }
        case REMOVE_ACTIVE_TERMINAL_PORT: {
            const tabIds = state.activeTerminalPorts.map(
                (port: any) => port.id
            );
            const nextTabIdAfterReloval = findAdjacentItem(
                tabIds,
                action.payload.id
            );
            return {
                ...state,
                activeTerminalPorts: state.activeTerminalPorts.filter(
                    (port: any) => port.id !== action.payload.id
                ),
                portMessages: state.portMessages.filter(
                    (message: any) => message.port !== action.payload.id
                ),
                activeTabId: nextTabIdAfterReloval,
            };
        }
        case REMOVE_ALL_ACTIVE_TERMINAL_PORTS: {
            return {
                ...state,
                activeTerminalPorts: [],
                portMessages: [],
            };
        }
        case SET_REMOVE_PORT_MODAL_INFO: {
            return {
                ...state,
                removePortModalInfo: action.payload,
            };
        }
        case SET_PORT_MESSAGES: {
            const activeTab = state.activeTerminalPorts.find(
                (port: any) => port.id === state.activeTabId
            );
            const isActiveTabLogging = activeTab?.isLogging;

            const messages = isActiveTabLogging ? action.payload : [];

            const allMessages = [...state.portMessages, ...messages];

            return {
                ...state,
                portMessages: allMessages,
            };
        }
        case SET_PORT_KEEP_FOLDER: {
            return {
                ...state,
                activeTerminalPorts: state.activeTerminalPorts.map(
                    (port: any) =>
                        port.id === action.payload.id
                            ? {
                                  ...port,
                                  logPath: action.payload.logPath,
                              }
                            : port
                ),
            };
        }
        case SET_PORT_IS_LOGGING: {
            return {
                ...state,
                activeTerminalPorts: state.activeTerminalPorts.map(
                    (port: any) =>
                        port.id === action.payload.id
                            ? {
                                  ...port,
                                  isLogging: action.payload.isLogging,
                              }
                            : port
                ),
            };
        }
        case SET_COMMAND_HISTORY: {
            return {
                ...state,
                commandHistory: action.payload,
            };
        }
        case CLEAR_PORT_MESSAGES_BY_PORT_ID: {
            return {
                ...state,
                portMessages: state.portMessages.filter(
                    (message: any) => message.port !== action.payload
                ),
            };
        }
        default:
            return state;
    }
};
