/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import { Chip, Stack } from "@mui/material";
import { useContext } from "react";
import {
    operandColumns,
    priorityColumns,
} from "../../../../../../../constants/constants";
import InputOutputContext from "../../../../../../../context/inputOutput/inputOutputContext";
import ThemeContext from "../../../../../../../context/theme/themeContext";
import Button from "../../../../../../MuiComponents/Button";
import LayoutContext from "../../../../../../../context/layout/layoutContext";
import { DeviceFamily } from "../../../../../../../utils/types";

const FilteredBySection = () => {
    const {
        resetAllFilters,
        resetFilter,
        resetOperand,
        resetPriority,
        activeFilters,
        activeOperand,
        activePriority,
    } = useContext(InputOutputContext);
    const {
        colors: { blue800, blue700, blue100, textDark },
    } = useContext(ThemeContext);

    const { layoutData } = useContext(LayoutContext);

    const isFtcDeviceFamily =
        !!layoutData?.deviceFamily &&
        (layoutData?.deviceFamily as unknown) === DeviceFamily.FTC;

    return (
        <div
            css={css`
                display: flex;
                align-items: stretch;
                padding: 14px;
            `}
        >
            <p
                css={css`
                    color: ${textDark};
                    line-height: 20px;
                    padding: 6px 0;
                    letter-spacing: 0.1px;
                    margin-right: 16px;
                    white-space: nowrap;
                `}
            >
                {"Filtered by"}:
            </p>

            <Stack
                direction="row"
                css={css`
                    flex-wrap: wrap;
                    margin: -6px 16px 0 -6px;

                    & > div {
                        background: ${blue100};
                        color: ${blue700};
                        font-weight: 600;
                        line-height: 20px;
                        letter-spacing: 0.1px;
                        margin-left: 6px;
                        margin-top: 6px;
                        padding: 6px 8px;
                        border-radius: 4px;
                    }
                `}
            >
                {activePriority.map((priority: string) => (
                    <Chip
                        key={priority}
                        label={
                            priorityColumns.find(
                                (item) => item.value === priority
                            )?.name
                        }
                        onDelete={() => resetPriority(priority)}
                        sx={{
                            "& .MuiChip-label": {
                                padding: "0 8px 0 0",
                            },

                            "& .MuiChip-deleteIcon": {
                                color: blue700,
                                margin: "0",
                                fontSize: "16px",

                                "&:hover": {
                                    color: blue800,
                                },
                            },
                        }}
                    />
                ))}
                {activeOperand.map((operand: string, index: number) => (
                    <Chip
                        key={operand}
                        label={
                            operandColumns(isFtcDeviceFamily).find(
                                (item) => item.value === operand
                            )?.name
                        }
                        onDelete={() => resetOperand(operand)}
                        sx={{
                            "& .MuiChip-label": {
                                padding: "0 8px 0 0",
                            },

                            "& .MuiChip-deleteIcon": {
                                color: blue700,
                                margin: "0",
                                fontSize: "16px",

                                "&:hover": {
                                    color: blue800,
                                },
                            },
                        }}
                    />
                ))}
                {activeFilters.map((value) => {
                    const translations: { [key: string]: string } = {
                        currentValue: "Current Values",
                        sendSmsTo: "Send Event Set",
                    };
                    return (
                        <Chip
                            key={value}
                            label={translations[value]}
                            onDelete={() => resetFilter(value)}
                            sx={{
                                "& .MuiChip-label": {
                                    padding: "0 8px 0 0",
                                },

                                "& .MuiChip-deleteIcon": {
                                    color: blue700,
                                    margin: "0",
                                    fontSize: "16px",

                                    "&:hover": {
                                        color: blue800,
                                    },
                                },
                            }}
                        />
                    );
                })}
            </Stack>

            <Button
                variant="textOnly"
                size="small"
                color="white"
                onClick={resetAllFilters}
                css={css`
                    align-self: flex-start;
                    white-space: nowrap;
                    margin-top: -2px;
                `}
                idForTesting="clearAllFilters"
            >
                {"Clear all"}
            </Button>
        </div>
    );
};

export default FilteredBySection;
