/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from "@emotion/react";

import { useContext } from "react";
import ThemeContext from "../../../../../context/theme/themeContext";
import EmptySearchHistoryIcon from "../../../../../assets/icons/EmptySearchHistoryIcon";

const NoHistoryResults = () => {
    const {
        colors: { textDark, gray700 },
    } = useContext(ThemeContext);

    return (
        <div
            css={css({
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                padding: "16px 32px 24px 32px",

                "& > svg": {
                    fontSize: "120px",
                    marginBottom: "16px",
                },

                "& > h4": {
                    color: textDark,
                    fontSize: "16px",
                    lineHeight: "20px",
                    fontWeight: "600",
                    letterSpacing: "0.15px",
                    textAlign: "center",
                    marginBottom: "8px",
                },

                "& > p": {
                    color: gray700,
                    fontSize: "14px",
                    lineHeight: "20px",
                    letterSpacing: "0.1px",
                    textAlign: "center",
                },
            })}
        >
            <EmptySearchHistoryIcon />

            <h4>Search history is empty</h4>
            <p>All the previous searches will be listed here</p>
        </div>
    );
};

export default NoHistoryResults;
