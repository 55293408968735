import React from "react";
import SvgIcon from "@mui/material/SvgIcon";

const FeaturesIcon = (props: any) => {
    return (
        <SvgIcon {...props}>
            <path
                d="M13 13.9999V19.9999C13 20.5499 13.45 20.9999 14 20.9999H20C20.55 20.9999 21 20.5499 21 19.9999V13.9999C21 13.4499 20.55 12.9999 20 12.9999H14C13.45 12.9999 13 13.4499 13 13.9999ZM4 20.9999H10C10.55 20.9999 11 20.5499 11 19.9999V13.9999C11 13.4499 10.55 12.9999 10 12.9999H4C3.45 12.9999 3 13.4499 3 13.9999V19.9999C3 20.5499 3.45 20.9999 4 20.9999ZM3 3.99992V9.99992C3 10.5499 3.45 10.9999 4 10.9999H10C10.55 10.9999 11 10.5499 11 9.99992V3.99992C11 3.44992 10.55 2.99992 10 2.99992H4C3.45 2.99992 3 3.44992 3 3.99992ZM15.95 2.39992L11.7 6.63992C11.31 7.02992 11.31 7.65992 11.7 8.04992L15.95 12.2999C16.34 12.6899 16.97 12.6899 17.36 12.2999L21.61 8.04992C22 7.65992 22 7.02992 21.61 6.63992L17.37 2.39992C16.98 2.00992 16.34 2.00992 15.95 2.39992V2.39992Z"
                fill="currentColor"
            />
        </SvgIcon>
    );
};

export default FeaturesIcon;
