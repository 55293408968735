import React, { useContext } from "react";
import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export interface PolygonIconProps extends SvgIconProps {
    css?: React.CSSProperties;
}

const PolygonIcon = ({ css, ...props }: PolygonIconProps) => {
    return (
        <SvgIcon
            style={css}
            width={32}
            height={32}
            viewBox="0 0 32 32"
            {...props}
        >
            <svg
                width="32"
                height="33"
                viewBox="0 0 32 33"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <rect
                    y="0.00390625"
                    width="32"
                    height="32"
                    rx="8"
                    fill="#F1F5F9"
                />
                <path
                    d="M19.5 22.5039L22 9.50391"
                    stroke="#334155"
                    strokeDasharray="2 2"
                />
                <mask id="path-3-inside-1_17126_69187" fill="white">
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M22 10.0039C23.1046 10.0039 24 9.10848 24 8.00391C24 6.89934 23.1046 6.00391 22 6.00391C20.8954 6.00391 20 6.89934 20 8.00391C20 9.10848 20.8954 10.0039 22 10.0039ZM22 9.00391C22.5523 9.00391 23 8.55619 23 8.00391C23 7.45162 22.5523 7.00391 22 7.00391C21.4477 7.00391 21 7.45162 21 8.00391C21 8.55619 21.4477 9.00391 22 9.00391Z"
                    />
                </mask>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M22 10.0039C23.1046 10.0039 24 9.10848 24 8.00391C24 6.89934 23.1046 6.00391 22 6.00391C20.8954 6.00391 20 6.89934 20 8.00391C20 9.10848 20.8954 10.0039 22 10.0039ZM22 9.00391C22.5523 9.00391 23 8.55619 23 8.00391C23 7.45162 22.5523 7.00391 22 7.00391C21.4477 7.00391 21 7.45162 21 8.00391C21 8.55619 21.4477 9.00391 22 9.00391Z"
                    fill="#F1F5F9"
                />
                <path
                    d="M22.8 8.00391C22.8 8.44573 22.4418 8.80391 22 8.80391V11.2039C23.7673 11.2039 25.2 9.77122 25.2 8.00391H22.8ZM22 7.20391C22.4418 7.20391 22.8 7.56208 22.8 8.00391H25.2C25.2 6.2366 23.7673 4.80391 22 4.80391V7.20391ZM21.2 8.00391C21.2 7.56208 21.5582 7.20391 22 7.20391V4.80391C20.2327 4.80391 18.8 6.2366 18.8 8.00391H21.2ZM22 8.80391C21.5582 8.80391 21.2 8.44573 21.2 8.00391H18.8C18.8 9.77122 20.2327 11.2039 22 11.2039V8.80391ZM21.8 8.00391C21.8 7.89345 21.8895 7.80391 22 7.80391V10.2039C23.215 10.2039 24.2 9.21893 24.2 8.00391H21.8ZM22 8.20391C21.8895 8.20391 21.8 8.11436 21.8 8.00391H24.2C24.2 6.78888 23.215 5.80391 22 5.80391V8.20391ZM22.2 8.00391C22.2 8.11436 22.1105 8.20391 22 8.20391V5.80391C20.785 5.80391 19.8 6.78888 19.8 8.00391H22.2ZM22 7.80391C22.1105 7.80391 22.2 7.89345 22.2 8.00391H19.8C19.8 9.21893 20.785 10.2039 22 10.2039V7.80391Z"
                    fill="#334155"
                    mask="url(#path-3-inside-1_17126_69187)"
                />
                <mask id="path-5-inside-2_17126_69187" fill="white">
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M19 24.8039C19.4418 24.8039 19.8 24.4457 19.8 24.0039C19.8 23.5621 19.4418 23.2039 19 23.2039C18.5582 23.2039 18.2 23.5621 18.2 24.0039C18.2 24.4457 18.5582 24.8039 19 24.8039ZM19 26.0039C20.1046 26.0039 21 25.1085 21 24.0039C21 22.8993 20.1046 22.0039 19 22.0039C17.8954 22.0039 17 22.8993 17 24.0039C17 25.1085 17.8954 26.0039 19 26.0039Z"
                    />
                </mask>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M19 24.8039C19.4418 24.8039 19.8 24.4457 19.8 24.0039C19.8 23.5621 19.4418 23.2039 19 23.2039C18.5582 23.2039 18.2 23.5621 18.2 24.0039C18.2 24.4457 18.5582 24.8039 19 24.8039ZM19 26.0039C20.1046 26.0039 21 25.1085 21 24.0039C21 22.8993 20.1046 22.0039 19 22.0039C17.8954 22.0039 17 22.8993 17 24.0039C17 25.1085 17.8954 26.0039 19 26.0039Z"
                    fill="#F1F5F9"
                />
                <path
                    d="M18.6 24.0039C18.6 23.783 18.7791 23.6039 19 23.6039V26.0039C20.1046 26.0039 21 25.1085 21 24.0039H18.6ZM19 24.4039C18.7791 24.4039 18.6 24.2248 18.6 24.0039H21C21 22.8993 20.1046 22.0039 19 22.0039V24.4039ZM19.4 24.0039C19.4 24.2248 19.2209 24.4039 19 24.4039V22.0039C17.8954 22.0039 17 22.8993 17 24.0039H19.4ZM19 23.6039C19.2209 23.6039 19.4 23.783 19.4 24.0039H17C17 25.1085 17.8954 26.0039 19 26.0039V23.6039ZM19.8 24.0039C19.8 24.4457 19.4418 24.8039 19 24.8039V27.2039C20.7673 27.2039 22.2 25.7712 22.2 24.0039H19.8ZM19 23.2039C19.4418 23.2039 19.8 23.5621 19.8 24.0039H22.2C22.2 22.2366 20.7673 20.8039 19 20.8039V23.2039ZM18.2 24.0039C18.2 23.5621 18.5582 23.2039 19 23.2039V20.8039C17.2327 20.8039 15.8 22.2366 15.8 24.0039H18.2ZM19 24.8039C18.5582 24.8039 18.2 24.4457 18.2 24.0039H15.8C15.8 25.7712 17.2327 27.2039 19 27.2039V24.8039Z"
                    fill="#334155"
                    mask="url(#path-5-inside-2_17126_69187)"
                />
                <mask id="path-7-inside-3_17126_69187" fill="white">
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M9 16.8039C9.44183 16.8039 9.8 16.4457 9.8 16.0039C9.8 15.5621 9.44183 15.2039 9 15.2039C8.55817 15.2039 8.2 15.5621 8.2 16.0039C8.2 16.4457 8.55817 16.8039 9 16.8039ZM9 18.0039C10.1046 18.0039 11 17.1085 11 16.0039C11 14.8993 10.1046 14.0039 9 14.0039C7.89543 14.0039 7 14.8993 7 16.0039C7 17.1085 7.89543 18.0039 9 18.0039Z"
                    />
                </mask>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M9 16.8039C9.44183 16.8039 9.8 16.4457 9.8 16.0039C9.8 15.5621 9.44183 15.2039 9 15.2039C8.55817 15.2039 8.2 15.5621 8.2 16.0039C8.2 16.4457 8.55817 16.8039 9 16.8039ZM9 18.0039C10.1046 18.0039 11 17.1085 11 16.0039C11 14.8993 10.1046 14.0039 9 14.0039C7.89543 14.0039 7 14.8993 7 16.0039C7 17.1085 7.89543 18.0039 9 18.0039Z"
                    fill="#F1F5F9"
                />
                <path
                    d="M8.6 16.0039C8.6 15.783 8.77909 15.6039 9 15.6039V18.0039C10.1046 18.0039 11 17.1085 11 16.0039H8.6ZM9 16.4039C8.77909 16.4039 8.6 16.2248 8.6 16.0039H11C11 14.8993 10.1046 14.0039 9 14.0039V16.4039ZM9.4 16.0039C9.4 16.2248 9.22091 16.4039 9 16.4039V14.0039C7.89543 14.0039 7 14.8993 7 16.0039H9.4ZM9 15.6039C9.22091 15.6039 9.4 15.783 9.4 16.0039H7C7 17.1085 7.89543 18.0039 9 18.0039V15.6039ZM9.8 16.0039C9.8 16.4457 9.44183 16.8039 9 16.8039V19.2039C10.7673 19.2039 12.2 17.7712 12.2 16.0039H9.8ZM9 15.2039C9.44183 15.2039 9.8 15.5621 9.8 16.0039H12.2C12.2 14.2366 10.7673 12.8039 9 12.8039V15.2039ZM8.2 16.0039C8.2 15.5621 8.55817 15.2039 9 15.2039V12.8039C7.23269 12.8039 5.8 14.2366 5.8 16.0039H8.2ZM9 16.8039C8.55817 16.8039 8.2 16.4457 8.2 16.0039H5.8C5.8 17.7712 7.23269 19.2039 9 19.2039V16.8039Z"
                    fill="#334155"
                    mask="url(#path-7-inside-3_17126_69187)"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M20.4853 7.64453L9.2271 14.3995C8.91518 14.5866 8.677 14.8399 8.51461 15.1274C8.65831 15.0475 8.82376 15.002 8.99984 15.002C9.55213 15.002 9.99984 15.4497 9.99984 16.002C9.99984 16.5543 9.55213 17.002 8.99984 17.002C8.75982 17.002 8.53954 16.9175 8.36719 16.7765C8.48638 17.1189 8.70201 17.4357 9.0168 17.6842L17.3802 24.2869L18.6195 22.7171L10.2561 16.1145L21.5143 9.35952L20.4853 7.64453Z"
                    fill="#334155"
                />
            </svg>
        </SvgIcon>
    );
};

export default PolygonIcon;
