import React from "react";
import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";
import { SerializedStyles } from "@emotion/react";

export interface DeleteCircleProps extends SvgIconProps {
    css?: SerializedStyles;
}
const DeleteCircleIcon = ({ css, ...props }: DeleteCircleProps) => {
    return (
        <SvgIcon {...props}>
            <path
                d="M4.92892 4.92893C1.02569 8.83216 1.02569 15.1678 4.92892 19.0711C8.83215 22.9743 15.1678 22.9743 19.0711 19.0711C22.9743 15.1678 22.9743 8.83216 19.0711 4.92893C15.1678 1.0257 8.83215 1.0257 4.92892 4.92893ZM15.5355 9.87868L13.4142 12L15.5355 14.1213C15.9244 14.5102 15.9244 15.1466 15.5355 15.5355C15.1466 15.9244 14.5102 15.9244 14.1213 15.5355L12 13.4142L9.87866 15.5355C9.48976 15.9244 8.85336 15.9244 8.46445 15.5355C8.07554 15.1466 8.07554 14.5102 8.46445 14.1213L10.5858 12L8.46445 9.87868C8.07554 9.48977 8.07554 8.85338 8.46445 8.46447C8.85336 8.07556 9.48976 8.07556 9.87866 8.46447L12 10.5858L14.1213 8.46447C14.5102 8.07556 15.1466 8.07556 15.5355 8.46447C15.9244 8.85338 15.9244 9.48977 15.5355 9.87868Z"
                fill="currentColor"
            />
        </SvgIcon>
    );
};

export default DeleteCircleIcon;
