import React from "react";
import { createRoot } from "react-dom/client";
import { HashRouter } from "react-router-dom";
import * as serviceWorker from "./serviceWorker";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import App from "./App";

const rootElement = document.getElementById("root") as any;

Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [
        new BrowserTracing({
            console: false,
        } as any),
    ],
    tracesSampleRate: 0.2,
    environment: `${process.env.REACT_APP_APPLICATION_TYPE}:${
        (process.env.REACT_APP_ENVIRONMENT || "").charAt(0).toUpperCase() +
        (process.env.REACT_APP_ENVIRONMENT || "").slice(1)
    }`,
    release: process.env.REACT_APP_VERSION,
});

Sentry.configureScope((scope) => {
    scope.setTag("channel", process.env.REACT_APP_CHANNEL);
});

createRoot(rootElement).render(
    <HashRouter>
        <App />
    </HashRouter>
);

serviceWorker.unregister();
