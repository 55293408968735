/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from "@emotion/react";

import { ListItemButton } from "@mui/material";
import { Collapse } from "@mui/material";
import { useContext, useState } from "react";
import ThemeContext from "../../../../../context/theme/themeContext";
import useMediaQueries from "../../../../../utils/useMediaQueries";
import { ModifiedParameter } from "../../../../../generatedTypes";
import ModifiedParameterListItem from "./ModifiedParameterListItem";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import LanguageContext from "../../../../../context/language/languageContext";

interface ModifiedParameterListProps {
    modifiedParameters: ModifiedParameter[];
}

const ModifiedParameterList = ({
    modifiedParameters,
}: ModifiedParameterListProps) => {
    const {
        colors: { white, blue400, blue200, gray200 },
    } = useContext(ThemeContext);

    const { t } = useContext(LanguageContext);

    const [open, setOpen] = useState(false);

    const { toMd } = useMediaQueries();

    return (
        <div
            css={css({
                maxHeight: toMd ? "unset" : "398px",
                overflowY: "auto",
                border: `1px solid ${gray200}`,
                borderRadius: "8px",

                "&::-webkit-scrollbar": {
                    width: "18px",
                },

                "&::-webkit-scrollbar-thumb": {
                    backgroundColor: blue200,
                    borderRadius: "100px",
                    border: `5px solid ${white}`,
                    minHeight: "48px",
                },

                "&::-webkit-scrollbar-thumb:hover": {
                    backgroundColor: blue400,
                },
            })}
        >
            {modifiedParameters.length > 0 && (
                <ListItemButton
                    onClick={() => {
                        setOpen(!open);
                    }}
                >
                    <div
                        css={css({
                            width: "100%",
                            justifyContent: "space-between",
                            display: "flex",
                            fontWeight: "600",
                        })}
                    >
                        <span>
                            {open
                                ? `${t.HideAll} (${modifiedParameters.length}) ${t.ChangesMadeForThisFile}`
                                : `${t.ShowAll} (${modifiedParameters.length}) ${t.ChangesMadeForThisFile}`}
                        </span>
                        {open ? <ExpandLess /> : <ExpandMore />}
                    </div>
                </ListItemButton>
            )}
            <div>
                <Collapse in={open}>
                    {modifiedParameters.map((modifiedParameter, index) => (
                        <ModifiedParameterListItem
                            key={`${modifiedParameter.parameterId}-${
                                modifiedParameter?.label || ""
                            }`}
                            {...modifiedParameter}
                        />
                    ))}
                </Collapse>
            </div>
        </div>
    );
};

export default ModifiedParameterList;
