import { createContext } from "react";

export interface BundleUpdateContextInitialState {
    expanded: string | false;
    step: number;
    loading: boolean;
    error: string | null;
    file: { filename: string; file: FormData } | null;
}
export interface BundleUpdateContextState
    extends BundleUpdateContextInitialState {
    setExpanded: (expanded: string | false) => void;
    setStep: (step: number) => void;
    setLoading: (loading: boolean) => void;
    setError: (error: string | null) => void;
    setFile: (file: { filename: string; file: FormData }) => void;
}

const BundleUpdateContext = createContext({} as BundleUpdateContextState);

export default BundleUpdateContext;
