/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from "@emotion/react";

import { useContext, Fragment } from "react";
import ThemeContext from "../../context/theme/themeContext";
import Tooltip from "./Tooltip";
import InfoIcon from "../../assets/icons/InfoIcon";
import CircularProgress from "@mui/material/CircularProgress";
import AlertWarningIcon from "../../assets/icons/AlertWarningIcon";

export interface LabelProps {
    title: string;
    required?: boolean;
    info?: string;
    rightTitle?: string | null;
    requesting?: boolean;
    requestFailed?: boolean;
    onInfoIconClick?: () => void;
    withoutTooltipPadding?: boolean;
}
const Label = ({
    title,
    required,
    info,
    rightTitle,
    requesting,
    requestFailed,
    onInfoIconClick,
    withoutTooltipPadding,
}: LabelProps) => {
    const {
        colors: { blue700, gray700, gray200 },
    } = useContext(ThemeContext);

    return (
        <div
            css={css`
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 4px;

                .label {
                    color: ${gray700};
                    font-size: 14px;
                    font-weight: 600;
                    line-height: 20px;
                    letter-spacing: 0.1px;
                }

                svg {
                    font-size: 16px;
                }
            `}
        >
            <div
                css={css`
                    display: flex;
                    align-items: center;
                `}
            >
                <span className="label">{title}</span>

                {required && <Fragment>&#42;</Fragment>}

                {info && (
                    <Tooltip title={info} placement="top">
                        <span
                            css={css`
                                display: flex;
                                align-items: center;
                                padding: ${withoutTooltipPadding
                                    ? "0px"
                                    : "4px"};
                                margin-left: 4px;
                                border-radius: 6px;
                                cursor: pointer;
                                &:hover {
                                    background-color: ${gray200};
                                    transform: scale(1.2);
                                }
                            `}
                        >
                            <InfoIcon
                                color="primary"
                                onClick={onInfoIconClick}
                            />
                        </span>
                    </Tooltip>
                )}
            </div>

            <div
                css={css`
                    display: flex;
                    align-items: center;
                `}
            >
                {(requesting || requestFailed) && (
                    <div
                        css={css`
                            width: 16px;
                            height: 16px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            margin-left: 4px;
                        `}
                    >
                        {requesting ? (
                            <CircularProgress
                                size={13}
                                css={css`
                                    color: ${blue700};
                                `}
                            />
                        ) : (
                            <Tooltip
                                title="Changes that were made recently could not be saved due to an error."
                                placement="top"
                            >
                                <span
                                    css={css`
                                        display: inline-flex;
                                    `}
                                >
                                    <AlertWarningIcon />
                                </span>
                            </Tooltip>
                        )}
                    </div>
                )}

                {rightTitle && (
                    <div
                        css={css`
                            margin-left: 4px;
                        `}
                    >
                        <span className="label">{rightTitle}</span>
                    </div>
                )}
            </div>
        </div>
    );
};

export default Label;
