export const OPEN_NOTIFICATION_MODAL = "OPEN_NOTIFICATION_MODAL";
export const CLOSE_NOTIFICATION_MODAL = "CLOSE_NOTIFICATION_MODAL";
export const CHANGE_TAB = "CHANGE_TAB";
export const SET_NOTIFICATION = "SET_NOTIFICATION";
export const READ_NOTIFICATION = "READ_NOTIFICATION";
export const OPEN_SETTINGS_MODAL = "OPEN_SETTINGS_MODAL";
export const CLOSE_SETTINGS_MODAL = "CLOSE_SETTINGS_MODAL";
export const OPEN_GENERAL_SETTINGS_MODAL = "OPEN_GENERAL_SETTINGS_MODAL";
export const CLOSE_GENERAL_SETTINGS_MODAL = "CLOSE_GENERAL_SETTINGS_MODAL";
export const OPEN_INTERFACE_SETTINGS_MODAL = "OPEN_INTERFACE_SETTINGS_MODAL";
export const CLOSE_INTERFACE_SETTINGS_MODAL = "CLOSE_INTERFACE_SETTINGS_MODAL";
export const OPEN_LANGUAGE_SETTINGS_MODAL = "OPEN_LANGUAGE_SETTINGS_MODAL";
export const CLOSE_LANGUAGE_SETTINGS_MODAL = "CLOSE_LANGUAGE_SETTINGS_MODAL";
export const OPEN_VERSION_HISTORY_MODAL = "OPEN_VERSION_HISTORY_MODAL";
export const CLOSE_VERSION_HISTORY_MODAL = "CLOSE_VERSION_HISTORY_MODAL";
export const OPEN_PRIVACY_POLICY_MODAL = "OPEN_PRIVACY_POLICY_MODAL";
export const HIDE_EXPLANATORY_TEXTS = "HIDE_EXPLANATORY_TEXTS";
export const HIDE_PARAMETER_IDS = "HIDE_PARAMETER_IDS";
export const SET_RELEASE_NOTES = "SET_RELEASE_NOTES";
export const SET_UPDATE_DOWNLOADED = "SET_UPDATE_DOWNLOADED";
export const SET_UPDATE_DOWNLOADING = "SET_UPDATE_DOWNLOADING";
export const OPEN_ACTIVATE_KEY_MODAL = "OPEN_ACTIVATE_KEY_MODAL";
export const CLOSE_ACTIVATE_KEY_MODAL = "CLOSE_ACTIVATE_KEY_MODAL";
export const SET_UPDATE_DOWNLOADING_PERCENTAGE =
    "SET_UPDATE_DOWNLOADING_PERCENTAGE";
export const OPEN_VERSION_INFO_MODAL = "OPEN_VERSION_INFO_MODAL";
export const CLOSE_VERSION_INFO_MODAL = "CLOSE_VERSION_INFO_MODAL";
export const OPEN_SYSTEM_INFORMATION_MODAL = "OPEN_SYSTEM_INFORMATION_MODAL";
export const CLOSE_SYSTEM_INFORMATION_MODAL = "CLOSE_SYSTEM_INFORMATION_MODAL";
export const OPEN_TERMINAL = "OPEN_TERMINAL";
export const CLOSE_TERMINAL = "CLOSE_TERMINAL";
export const OPEN_DEVICE_SET_UP_MODAL = "OPEN_DEVICE_SET_UP_MODAL";
export const CLOSE_DEVICE_SET_UP_MODAL = "CLOSE_DEVICE_SET_UP_MODAL";
export const OPEN_DEVICE_GUIDE_STEPS = "OPEN_DEVICE_GUIDE_STEPS";
export const SET_DEVICE_GUIDE_STEP_INDEX = "SET_DEVICE_GUIDE_STEP_INDEX";
export const SET_DEVICE_GUIDE_BUTTON_DISABLED =
    "SET_DEVICE_GUIDE_BUTTON_DISABLED";
export const CLOSE_DEVICE_GUIDE_STEPS = "CLOSE_DEVICE_GUIDE_STEPS";
export const SET_IS_DEVICE_GUIDE_IN_PROGRESS = "SET_IS_DEVICE_GUIDE_IN_PROGRESS";
