import moment from "moment";
import "moment/locale/ru";

import {
    SET_LOADING,
    SET_INITIAL_LANGUAGES_DATA,
    CHANGE_LANGUAGE,
    SET_PREVIOUS_LIST_LANGUAGE,
} from "./languageActions";

export interface InitialState {
    loading: boolean;
    selectedLanguage: string;
    previousListLanguage: string;
    languages: any[];
    translations: any;
    t: any;
}

const createTranslationsObj = (translations: any, allLanguages: any) => {
    const langTranslationsObj = allLanguages.reduce(
        (previous: any, current: any) => {
            return {
                ...previous,
                [current.shortName]: current.description,
            };
        },
        {}
    );

    return {
        ...translations,
        NavigationBar: {
            ...translations.NavigationBar,
            ...langTranslationsObj,
        },
    };
};

export default (state: any, action: any) => {
    switch (action.type) {
        case SET_LOADING: {
            return {
                ...state,
                loading: action.payload,
            };
        }
        case SET_INITIAL_LANGUAGES_DATA: {
            const payload = action.payload;

            const language = payload.language;
            const allLanguages = payload.languages;
            const translations = payload.translations;

            const updatedTranslations = createTranslationsObj(
                translations,
                allLanguages
            );

            moment.locale(language);

            return {
                ...state,
                selectedLanguage: language,
                languages: allLanguages,
                translations: updatedTranslations,
            };
        }
        case CHANGE_LANGUAGE: {
            const payload = action.payload;

            const language = payload.language;
            const allLanguages = state.languages;
            const translations = payload.translations;

            const updatedTranslations = createTranslationsObj(
                translations,
                allLanguages
            );

            moment.locale(language);

            return {
                ...state,
                loading: false,
                selectedLanguage: language,
                translations: updatedTranslations,
            };
        }
        case SET_PREVIOUS_LIST_LANGUAGE: {
            const payload = action.payload;
            return {
                ...state,
                previousListLanguage: payload,
            };
        }
        default:
            return state;
    }
};
