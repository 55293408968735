export const SET_FRAMES = "SET_FRAMES";
export const SET_ACTIVE_FRAME = "SET_FRAME";
export const SET_PARAMETER = "SET_PARAMETER";
export const SET_PARAMETERS = "SET_PARAMETERS";
export const SET_ERROR = "SET_ERROR";
export const SET_DISABLED_PARAMETERS = "SET_DISABLED_PARAMETERS";
export const SET_BREADCRUMB_ITEMS = "SET_BREADCRUMB_ITEMS";
export const SET_MANUAL_GEOFENCE_PARAMETERS = "SET_MANUAL_GEOFENCE_PARAMETERS";
export const SET_ERRORS = "SET_ERRORS";
export const RESET_PARAMETERS = "RESET_PARAMETERS";
