import {
    SET_LOADING,
    RENDER_UNLOCK_CODE,
    HANDLE_CHANGE_CODE,
    HANDLE_VISIBILITY,
    HANDLE_COPY,
    HANDLE_ERROR,
} from "./deviceLockedModalActions";

export interface InitialState {
    isUnlockCodeModal: boolean;
    isLoading: boolean;
    isDisabled: boolean;
    code: string;
    codeShow: boolean;
    isCopied: boolean;
    errorCode: string | null;
}

type Action =
    | {
          type: typeof SET_LOADING;
          payload: boolean;
      }
    | {
          type: typeof RENDER_UNLOCK_CODE;
      }
    | {
          type: typeof HANDLE_CHANGE_CODE;
          payload: string;
      }
    | {
          type: typeof HANDLE_VISIBILITY;
      }
    | {
          type: typeof HANDLE_COPY;
          payload: boolean;
      }
    | {
          type: typeof HANDLE_ERROR;
          payload: string | null;
      };

export default (state: InitialState, action: Action) => {
    switch (action.type) {
        case SET_LOADING: {
            return {
                ...state,
                isLoading: action.payload,
            };
        }
        case RENDER_UNLOCK_CODE: {
            return {
                ...state,
                isUnlockCodeModal: true,
            };
        }
        case HANDLE_CHANGE_CODE: {
            return {
                ...state,
                isDisabled: action.payload.length === 0,
                code: action.payload,
            };
        }
        case HANDLE_VISIBILITY: {
            return {
                ...state,
                codeShow: !state.codeShow,
            };
        }
        case HANDLE_COPY: {
            return {
                ...state,
                isCopied: action.payload,
            };
        }
        case HANDLE_ERROR: {
            return {
                ...state,
                errorCode: action.payload,
            };
        }
        default:
            return state;
    }
};
