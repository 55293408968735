/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from "@emotion/react";

import { useContext } from "react";
import ThemeContext from "../../../context/theme/themeContext";
import LanguageContext from "../../../context/language/languageContext";
import DiscoveringAnimation from "./DiscoveringAnimation";

const DiscoveringDevices = () => {
    const {
        colors: { textPlaceholder },
    } = useContext(ThemeContext);

    const { t } = useContext(LanguageContext);

    return (
        <div
            css={css`
                &.discovering-enter {
                    opacity: 0;
                }

                &.discovering-enter-active {
                    opacity: 1;
                    transition: opacity 300ms;
                }

                &.discovering-exit {
                    opacity: 1;
                }

                &.discovering-exit-active {
                    opacity: 0;
                    transition: opacity 150ms;
                }
            `}
        >
            <div
                css={css`
                    padding: 28px 24px;
                    display: flex;
                    align-items: center;
                `}
            >
                <DiscoveringAnimation />

                <p
                    css={css`
                        color: ${textPlaceholder};
                        font-weight: 600;
                        line-height: 20px;
                        letter-spacing: 0.1px;
                        margin-left: 12px;
                    `}
                >
                    {t && t.LookingForDevices}
                </p>
            </div>
        </div>
    );
};

export default DiscoveringDevices;
