/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from "@emotion/react";

import { useContext } from "react";
import ThemeContext from "../../../../context/theme/themeContext";
import LanguageContext from "../../../../context/language/languageContext";
import Select from "../../../MuiComponents/Select";
import MenuItem from "@mui/material/MenuItem";

export interface RowsPerPageProps {
    rowsPerPage: number;
    change: (event: any) => void;
}

const RowsPerPage = ({ rowsPerPage, change }: RowsPerPageProps) => {
    const {
        colors: { textIconDark },
    } = useContext(ThemeContext);

    const { t } = useContext(LanguageContext);

    const handleChange = (e: any) => change(e);

    return (
        <div
            css={css`
                display: flex;
                align-items: center;
                margin-right: 16px;

                p {
                    color: ${textIconDark};
                    font-weight: 600;
                    line-height: 20px;
                    letter-spacing: 0.1px;
                    margin-right: 8px;
                    white-space: nowrap;
                }

                @media (max-width: 839px) {
                    margin-right: 0;
                    margin-bottom: 16px;
                }
            `}
        >
            <p>{t.DevicesPerPage}</p>

            <Select value={rowsPerPage} onChange={handleChange}>
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={25}>25</MenuItem>
                <MenuItem value={50}>50</MenuItem>
                <MenuItem value={-1}>{t.All}</MenuItem>
            </Select>
        </div>
    );
};

export default RowsPerPage;
