import React from "react";
import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

const SearchHistoryIcon = (props: SvgIconProps) => {
    return (
        <SvgIcon viewBox="0 0 16 16" {...props}>
            <path
                d="M8.83994 2.00001C5.44661 1.90667 2.66661 4.63334 2.66661 8.00001H1.47327C1.17327 8.00001 1.02661 8.36001 1.23994 8.56667L3.09994 10.4333C3.23327 10.5667 3.43994 10.5667 3.57327 10.4333L5.43327 8.56667C5.63994 8.36001 5.49327 8.00001 5.19327 8.00001H3.99994C3.99994 5.40001 6.11994 3.30001 8.73327 3.33334C11.2133 3.36667 13.2999 5.45334 13.3333 7.93334C13.3666 10.54 11.2666 12.6667 8.66661 12.6667C7.59327 12.6667 6.59994 12.3 5.81327 11.68C5.54661 11.4733 5.17327 11.4933 4.93327 11.7333C4.65327 12.0133 4.67327 12.4867 4.98661 12.7267C5.99994 13.5267 7.27327 14 8.66661 14C12.0333 14 14.7599 11.22 14.6666 7.82667C14.5799 4.70001 11.9666 2.08667 8.83994 2.00001ZM8.49994 5.33334C8.22661 5.33334 7.99994 5.56001 7.99994 5.83334V8.28667C7.99994 8.52001 8.12661 8.74001 8.32661 8.86001L10.4066 10.0933C10.6466 10.2333 10.9533 10.1533 11.0933 9.92001C11.2333 9.68001 11.1533 9.37334 10.9199 9.23334L8.99994 8.09334V5.82667C8.99994 5.56001 8.77327 5.33334 8.49994 5.33334Z"
                fill="currentColor"
            />
        </SvgIcon>
    );
};

export default SearchHistoryIcon;
