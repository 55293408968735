import React, { forwardRef, useContext } from "react";
import ThemeContext from "../../../../../../../context/theme/themeContext";
import TextField from "@mui/material/TextField";
import { Ref } from "./index";

interface SingleInputProps {
    index: number;
    value: string;
    error?: string | null;
    onChange: (index: number, value: string) => void;
    handleFocus: () => void;
    onKeyDown: (event: React.KeyboardEvent<HTMLInputElement>) => void;
}

const SingleInput = forwardRef<Ref, SingleInputProps>(
    ({ index, value, error, onChange, handleFocus, onKeyDown }, ref) => {
        const {
            colors: { textDark, blue700, gray300, gray200, red800, red100 },
        } = useContext(ThemeContext);

        const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
            const newValue: string = e.target.value;
            const lastValue = newValue.slice(-1);
            const regex = /\d/;

            if (lastValue === "" || regex.test(lastValue)) {
                onChange(index, lastValue);
            }
        };

        return (
            <TextField
                inputRef={ref}
                variant="standard"
                value={value}
                placeholder="Placeholder"
                onChange={handleChange}
                onFocus={handleFocus}
                onKeyDown={onKeyDown}
                InputProps={{
                    sx: {
                        "&.MuiInput-root": {
                            paddingBottom: "8px",
                        },

                        "&.MuiInput-root:before": {
                            borderBottom: `2px solid ${
                                error ? red100 : gray200
                            }`,
                        },

                        "&.MuiInput-root:after": {
                            borderBottom: `2px solid ${
                                error ? red800 : blue700
                            }`,
                        },

                        "&.MuiInput-root:hover:not(.Mui-disabled):before": {
                            borderBottom: `2px solid ${
                                error ? red100 : gray300
                            }`,
                        },

                        "& .MuiInput-input": {
                            height: "28px",
                            padding: "0",
                            paddingLeft: value.length > 0 ? "0" : "12px",
                            margin: "2px 0",
                            textAlign: "center",
                            fontSize: "24px",
                            lineHeight: "32px",
                            fontWeight: "600",
                            color: textDark,
                            position: "relative",
                            caretColor: error ? red800 : blue700,

                            "&::placeholder": {
                                width: "10px",
                                height: "10px",
                                borderRadius: "100px",
                                background: error ? red800 : textDark,
                                opacity: "1",
                                position: "absolute",
                                top: "9px",
                                left: "15px",
                            },
                        },
                    },
                }}
            />
        );
    }
);

export default SingleInput;
