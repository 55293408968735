/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import { Fragment, useContext } from "react";
import Joyride from "react-joyride";
import MenuItemContext from "../../context/menuItem/menuItemContext";
import SettingsContext from "../../context/settings/settingsContext";
import ThemeContext from "../../context/theme/themeContext";
import Button from "../MuiComponents/Button";
import GuideTooltip from "./GuideTooltip";
import { isNullOrUndefined } from "../../utils/helpers";
import LayoutContext from "../../context/layout/layoutContext";

const DeviceGuide = () => {
    const {
        colors: { white },
        boxShadows: { deviceGuideBoxShadow },
    } = useContext(ThemeContext);
    const {
        isDeviceGuideStepsOpen,
        deviceGuideStepIndex,
        closeDeviceGuideSteps,
        setDeviceGuideStepIndex,
        setDeviceGuideButtonDisabled,
        setIsDeviceGuideInProgress,
    } = useContext(SettingsContext);
    const { deviceGuide } = useContext(LayoutContext);

    const { findParameterValueById } = useContext(MenuItemContext);

    const steps =
        deviceGuide?.guideSteps.map(
            (
                { description, guideType, searchPath, localizationKey },
                index,
                arr
            ) => {
                const targetSelector = () => {
                    if (!isNullOrUndefined(searchPath?.componentIndex)) {
                        return `#component${String(
                            searchPath?.componentIndex
                        )}`;
                    } else if (!isNullOrUndefined(searchPath?.frame?.index)) {
                        return `#frame${String(searchPath?.frame?.index)}`;
                    } else {
                        return `#menuItem${String(
                            searchPath?.menuItem?.index
                        )}`;
                    }
                };
                return {
                    content: description,
                    title: guideType,
                    target: targetSelector(),
                    disableBeacon: true,
                    nextSearchPath: arr[index + 1]?.searchPath,
                    hasNoRedirect:
                        arr[index + 1]?.searchPath?.menuItem?.index ===
                        searchPath?.menuItem?.index,
                    placement: "top" as const,
                    isButtonDisabled:
                        !findParameterValueById(localizationKey) &&
                        !isNullOrUndefined(searchPath?.componentIndex),
                };
            }
        ) || [];

    const handleCallback = (data: any) => {
        const { step } = data;

        if (step.isButtonDisabled) {
            setDeviceGuideButtonDisabled(true);
        } else {
            setDeviceGuideButtonDisabled(false);
        }
    };

    return (
        <Fragment>
            <Joyride
                hideBackButton
                hideCloseButton
                callback={handleCallback}
                run={isDeviceGuideStepsOpen}
                stepIndex={deviceGuideStepIndex || -1}
                steps={steps}
                styles={{
                    options: {
                        zIndex: 10000,
                    },
                }}
                disableOverlayClose={true}
                disableOverlay={false}
                tooltipComponent={GuideTooltip}
                floaterProps={{ hideArrow: true }}
                spotlightClicks={true}
                scrollOffset={100}
            />
            {isDeviceGuideStepsOpen && (
                <div
                    css={css({
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                    })}
                >
                    <div
                        css={css({
                            display: "flex",
                            justifyContent: "space-around",
                            alignItems: "center",
                            fontWeight: 600,
                            padding: "8px",
                            zIndex: 1000000,
                            maxWidth: "233px",
                            width: "100%",
                            bottom: "32px",
                            position: "fixed",
                            backgroundColor: white,
                            borderRadius: "12px",
                            boxShadow: deviceGuideBoxShadow,
                        })}
                    >
                        <div>Device set-up mode</div>
                        <Button
                            size="small"
                            variant="textOnly"
                            color="primary"
                            onClick={() => {
                                closeDeviceGuideSteps();
                                setDeviceGuideStepIndex(null);
                                setIsDeviceGuideInProgress(false);
                            }}
                            idForTesting="deviceGuideExitButton"
                        >
                            Exit
                        </Button>
                    </div>
                </div>
            )}
        </Fragment>
    );
};

export default DeviceGuide;
