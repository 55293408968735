/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import React, { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import TerminalContext from "../../context/components/terminal/terminalContext";
import { ReactComponent as DragHandleIcon } from "../../assets/icons/terminal/DragHandle.svg";

export interface RndWindowProps {
    children: React.ReactNode;
}

const RndWindow: React.FC<RndWindowProps> = ({ children }) => {
    const {
        terminalSize: { width, height },
        setTerminalSize,
    } = useContext(TerminalContext);

    const [isResizing, setIsResizing] = useState(false);
    const [startY, setStartY] = useState(0);

    const location = useLocation();
    const currentPath = location.pathname;

    const footerHeight = location.pathname === "/" ? 10 : 56; // Height of the footer in pixels
    const minTerminalHeight = 500; // Minimum height of the terminal
    const maxTerminalHeight = window.innerHeight * 0.8; // Maximum height of the terminal (80% of viewport height)

    const handleMouseDown = (e: React.MouseEvent) => {
        e.preventDefault();
        setIsResizing(true);
        setStartY(e.clientY);
    };

    const handleMouseMove = (e: MouseEvent) => {
        if (isResizing) {
            const newHeight = Math.max(
                minTerminalHeight,
                Math.min(maxTerminalHeight, height - (e.clientY - startY))
            );
            setTerminalSize({
                width: window.innerWidth,
                height: newHeight,
            });
            setStartY(e.clientY);
        }
    };

    const handleMouseUp = () => {
        setIsResizing(false);
    };

    const handleResize = () => {
        setTerminalSize({
            width: window.innerWidth,
            height: Math.min(height, window.innerHeight - footerHeight),
        });
    };

    const isStartup = currentPath === "/";

    useEffect(() => {
        window.addEventListener("mousemove", handleMouseMove);
        window.addEventListener("mouseup", handleMouseUp);
        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("mousemove", handleMouseMove);
            window.removeEventListener("mouseup", handleMouseUp);
            window.removeEventListener("resize", handleResize);
        };
    }, [isResizing, height]);

    useEffect(() => {
        const initialHeight = Math.min(
            window.innerHeight / 2,
            window.innerHeight - footerHeight
        );
        setTerminalSize({
            width: window.innerWidth,
            height: initialHeight,
        });
    }, []);

    return (
        <div
            css={css`
                width: 100%;
                max-width: ${isStartup ? width : width - 254}px;
                height: ${height}px;
                position: fixed;
                min-height: ${minTerminalHeight}px;
                max-height: calc(100vh - ${footerHeight}px);
                z-index: 399;
                bottom: ${footerHeight}px;
                right: 0;
                display: flex;
                flex-direction: column;
                box-shadow: 0px -6px 14px -6px rgba(24, 39, 75, 0.08),
                    0px -10px 32px -4px rgba(24, 39, 75, 0.06);
            `}
        >
            <div
                css={css`
                    height: 4px;
                    width: 100%;
                    cursor: ns-resize;
                    position: absolute;
                    top: -4px;
                `}
                onMouseDown={handleMouseDown}
            >
                <div
                    css={css({
                        display: "flex",
                        justifyContent: "center",
                        background: "white",
                    })}
                >
                    <DragHandleIcon />
                </div>
            </div>
            <div
                css={css`
                    flex: 1;
                    overflow: hidden;
                    display: flex;
                    flex-direction: column;
                `}
            >
                {children}
            </div>
        </div>
    );
};

export default RndWindow;
