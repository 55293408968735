/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from "@emotion/react";

import { useContext } from "react";
import ThemeContext from "../../../../../../context/theme/themeContext";
import useMediaQueries from "../../../../../../utils/useMediaQueries";
import EnterPin from "./EnterPin";
import ChangePin from "./ChangePin";
import EnableDisablePin from "./EnableDisablePin";
import DeviceLocked from "./DeviceLocked";
import Button from "../../../../../MuiComponents/Button";
import Dialog from "@mui/material/Dialog";
import CloseIcon from "@mui/icons-material/CloseRounded";

export enum SimPinModalTypes {
    EnterPin = "enterPin",
    ChangePin = "changePin",
    EnablePin = "enablePin",
    DisablePin = "disablePin",
}

export type ModalType = SimPinModalTypes;

interface PinManagementModalProps {
    simId: number;
    type: ModalType | null;
    retriesLeft: number;
    close: () => void;
}

const PinManagementModal = ({
    simId,
    type,
    retriesLeft,
    close,
}: PinManagementModalProps) => {
    const {
        colors: { gray300 },
    } = useContext(ThemeContext);

    const { toSm } = useMediaQueries();

    const renderModal = () => {
        switch (type) {
            case SimPinModalTypes.EnterPin:
                return <EnterPin simId={simId} close={close} />;
            case SimPinModalTypes.ChangePin:
                return <ChangePin simId={simId} close={close} />;
            case SimPinModalTypes.EnablePin:
            case SimPinModalTypes.DisablePin:
                return (
                    <EnableDisablePin simId={simId} close={close} type={type} />
                );
        }
    };

    return (
        <Dialog
            onClose={close}
            open={!!type}
            fullScreen={toSm}
            sx={{
                "& .MuiDialog-paper": {
                    maxWidth: "448px",
                    boxSizing: "border-box",

                    "@media (max-width: 839px)": {
                        maxWidth: "410px",
                    },

                    "@media (max-width: 599px)": {
                        maxWidth: "100%",
                    },
                },

                "& .MuiDialogTitle-root": {
                    padding: "24px 16px 12px 16px",
                    borderBottom: `1px solid ${gray300}`,
                },

                "& .MuiDialogTitle-root + .MuiDialogContent-root": {
                    paddingTop: "16px",
                },

                "& .MuiDialogContent-root": {
                    padding: "16px",
                    borderBottom: `1px solid ${gray300}`,
                    height: "300px",
                },
            }}
        >
            <Button
                variant={"iconOnly"}
                size="small"
                color="white"
                icon={<CloseIcon onClick={close} />}
                css={css`
                    position: absolute;
                    top: 4px;
                    right: 4px;
                `}
                idForTesting={"closePinManagementModal"}
            />

            {retriesLeft <= 1 ? (
                <DeviceLocked type={type} close={close} />
            ) : (
                renderModal()
            )}
        </Dialog>
    );
};

export default PinManagementModal;
