import { createContext } from "react";

export interface AppTypeContextProps {
    webVersion: boolean;
    isEditor: boolean;
    fileId: number;
    isWasm: boolean;
}

const AppTypeContext = createContext({} as AppTypeContextProps);

export default AppTypeContext;
