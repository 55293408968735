import React, { useContext, useState, useEffect, SyntheticEvent } from "react";
import AlertContext from "../../context/alert/alertContext";
import useUrlQueryParams from "../../utils/useUrlQueryParams";
import Alert from "../Alert";
import MuiSnackbar, { SnackbarCloseReason } from "@mui/material/Snackbar";
import { EDITOR_MENU_ITEM, EDITOR_STATUS } from "../../constants/routes";

const Snackbar = () => {
    const { alert, removeAlert } = useContext(AlertContext);

    const { pathname } = useUrlQueryParams();
    const onEditorRoute =
        pathname === EDITOR_STATUS || pathname === EDITOR_MENU_ITEM;
    const [open, setOpen] = useState(true);
    const [snackPack, setSnackPack] = useState<any>([]);
    const [alertData, setAlertData] = useState({
        type: null,
        title: null,
        description: null,
        buttonPrimary: null,
        handlePrimaryBtnClick: null,
        buttonSecondary: null,
        handleSecondaryBtnClick: null,
    } as any);

    useEffect(() => {
        alert ? setSnackPack([...snackPack, alert]) : setOpen(false);

        // eslint-disable-next-line
    }, [alert]);

    useEffect(() => {
        if (snackPack.length > 0) {
            if (!alertData.type) {
                setAlertData(snackPack.shift());
                setOpen(true);
            } else if (alertData.type && open) {
                setOpen(false);
            }
        }
        // eslint-disable-next-line
    }, [snackPack, alertData, open]);

    const handleClose = (
        e: Event | SyntheticEvent<any, Event>,
        reason?: SnackbarCloseReason
    ) => {
        if (reason === "clickaway") {
            return;
        }

        removeAlert();
    };

    const handleExited = () =>
        setAlertData({
            type: null,
            title: null,
            description: null,
            buttonPrimary: null,
            handlePrimaryBtnClick: null,
            buttonSecondary: null,
            handleSecondaryBtnClick: null,
        });

    return alertData.type ? (
        <MuiSnackbar
            open={open}
            disableWindowBlurListener
            autoHideDuration={6000}
            onClose={handleClose}
            TransitionProps={{ onExited: handleExited }}
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            sx={{
                width: "448px",

                "@media (min-width: 600px)": {
                    bottom: onEditorRoute ? "80px" : "84px",
                },

                "@media (max-width: 599px)": {
                    width: "unset",
                    left: "16px",
                    right: "16px",
                    bottom: onEditorRoute ? "80px" : "84px",
                },
            }}
        >
            <Alert
                status={alertData.type}
                title={alertData.title}
                description={alertData.description}
                buttonPrimary={alertData.buttonPrimary}
                buttonSecondary={alertData.buttonSecondary}
                close={handleClose}
                handlePrimaryBtnClick={alertData.handlePrimaryBtnClick}
                handleSecondaryBtnClick={alertData.handleSecondaryBtnClick}
            />
        </MuiSnackbar>
    ) : null;
};

export default Snackbar;
