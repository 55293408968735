import React, { useContext } from "react";
import AlertContext from "../../../../../context/alert/alertContext";
import LanguageContext from "../../../../../context/language/languageContext";
import useApi from "../../../../../utils/useApi";
import ConfirmModal from "../../../../MuiComponents/Modals/ConfirmModal";
import { AlertStatus } from "../../../../../constants/constants";

export interface FormatAgreementModalProps {
    open: boolean;
    close: () => void;
    startFormatting: () => void;
    stopFormatting: () => void;
}

const FormatAgreementModal = ({
    open,
    close,
    startFormatting,
    stopFormatting,
}: FormatAgreementModalProps) => {
    const { setAlert } = useContext(AlertContext);

    const { selectedLanguage, t } = useContext(LanguageContext);

    const { getData, showErrorMsg } = useApi();

    const handleSubmit = async (e: React.SyntheticEvent<Element, Event>) => {
        e.preventDefault();
        close();

        try {
            startFormatting();

            await getData(`${selectedLanguage}/device/format`);

            setAlert(AlertStatus.Success, t.FormatSdComplete);
        } catch (error: any) {
            showErrorMsg(error);
        }

        stopFormatting();
    };

    return (
        <ConfirmModal
            open={open}
            close={close}
            submit={handleSubmit}
            title="Do you really want to format internal flash memory?"
        />
    );
};

export default FormatAgreementModal;
