/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/react";
import CellWithTooltip from "../../CellWithTooltip";

export interface NameCellProps {
    row: any;
}

const NameCell: React.FunctionComponent<NameCellProps> = ({
    row,
}: NameCellProps) => {
    return (
        <CellWithTooltip
            rowValue={row.original.name}
            maxShownStringLength={20}
        />
    );
};

export default NameCell;
