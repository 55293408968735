/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from "@emotion/react";

import { useContext, useEffect, useState } from "react";
import ThemeContext from "../../../../../../context/theme/themeContext";
import CheckWhiteIcon from "../../../../../../assets/icons/CheckWhiteIcon";
import LayoutContext from "../../../../../../context/layout/layoutContext";
import { DeviceFamily } from "../../../../../../utils/types";

interface PasswordValidationProps {
    ruleTitles: string[];
    currentPassword?: string;
    password: string;
    passwordRepeat: string;
    onRulesMet: (value: boolean) => void;
    isFtcDevice?: boolean;
}

interface IsRuleMet {
    [key: number]: boolean;
}

const PasswordValidation = ({
    ruleTitles,
    currentPassword,
    password,
    passwordRepeat,
    onRulesMet,
    isFtcDevice,
}: PasswordValidationProps) => {
    const {
        colors: { green600, textDark, textDarkDisabled },
    } = useContext(ThemeContext);

    const [isRuleMet, setRuleMet] = useState<IsRuleMet>({
        0: false,
        1: false,
        2: false,
    });

    useEffect(() => {
        const rule1met = password.length >= 4 && password.length <= 10;
        const rule1metFtc = password.length >= 5 && password.length <= 10;
        const rule2met = /^[0-9a-zA-Z]+$/.test(password);
        const rule3met = password.length > 0 && password === passwordRepeat;
        const rule4met =
            currentPassword !== undefined ? currentPassword.length > 0 : true;

        setRuleMet({
            0: isFtcDevice ? rule1metFtc : rule1met,
            1: rule2met,
            2: rule3met,
        });

        onRulesMet(
            (isFtcDevice ? rule1metFtc : rule1met) &&
                rule2met &&
                rule3met &&
                rule4met
        );
        // eslint-disable-next-line
    }, [currentPassword, password, passwordRepeat]);

    return (
        <div
            css={css({
                "& > div + div": {
                    marginTop: "4px",
                },
            })}
        >
            {ruleTitles.map((ruleTitle: string, i: number) => (
                <div
                    key={i}
                    css={css({
                        display: "flex",
                        alignItems: "center",
                    })}
                >
                    <CheckWhiteIcon
                        css={css({
                            fontSize: "16px",
                            borderRadius: "50%",
                            backgroundColor: isRuleMet[i]
                                ? green600
                                : textDarkDisabled,
                            marginRight: "8px",
                        })}
                    />

                    <span
                        css={css({
                            display: "inline-block",
                            color: textDark,
                            fontWeight: "600",
                            lineHeight: "20px",
                            letterSpacing: "0.1px",
                        })}
                    >
                        {ruleTitle}
                    </span>
                </div>
            ))}
        </div>
    );
};

export default PasswordValidation;
