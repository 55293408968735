/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from "@emotion/react";

import Button from "../../../../MuiComponents/Button";

interface MaintenanceButtonProps {
    buttonText: string;
    marginRight?: boolean;
    onClick: () => void;
    disabled?: boolean;
}

const MaintenanceButton = ({
    buttonText,
    marginRight,
    onClick,
    disabled = false,
}: MaintenanceButtonProps) => {
    return (
        <div
            css={css`
                width: 100%;
                margin-right: ${marginRight ? "15px" : "0px"};
            `}
        >
            <Button
                variant={"textOnly"}
                size="small"
                color="primarySubtle"
                disabled={disabled}
                onClick={onClick}
                idForTesting={"maintenanceButton"}
                fullWidth
            >
                {buttonText}
            </Button>
        </div>
    );
};

export default MaintenanceButton;
