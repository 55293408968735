/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from "@emotion/react";

import { useContext } from "react";
import ThemeContext from "../../../../context/theme/themeContext";
import useMediaQueries from "../../../../utils/useMediaQueries";
import Skeleton from "@mui/material/Skeleton";

const FooterSkeleton = () => {
    const {
        colors: { gray200, gray50 },
    } = useContext(ThemeContext);

    const { toSm } = useMediaQueries();

    return (
        <div
            css={css`
                position: sticky;
                bottom: 0;
                z-index: 3;
                background: ${gray50};
                box-shadow: inset 0px 1px 0px ${gray200};
                border-radius: 0 0 8px 8px;
                padding: 8px 24px;
                display: flex;
                justify-content: space-between;
                align-items: center;

                @media (max-width: 839px) {
                    align-items: flex-start;
                    padding: 8px;
                }

                @media (max-width: 599px) {
                    flex-direction: column;
                    align-items: center;
                }
            `}
        >
            <div
                css={css`
                    display: flex;

                    span:first-of-type {
                        margin-right: 10px;
                    }

                    @media (max-width: 839px) {
                        margin-top: 8px;
                    }

                    @media (max-width: 599px) {
                        margin-top: 0;
                        margin-bottom: 16px;
                    }
                `}
            >
                <Skeleton width={20} height={20} />
                <Skeleton width={138} height={20} />
            </div>

            <div
                css={css`
                    display: flex;
                    align-items: center;

                    @media (max-width: 839px) {
                        flex-direction: column;
                        align-items: flex-end;

                        & > div:first-of-type {
                            margin-bottom: 16px;
                            margin-right: 0;
                        }
                    }
                `}
            >
                <div
                    css={css`
                        display: flex;
                        align-items: center;
                        margin-right: 16px;

                        span:first-of-type {
                            margin-right: 8px;
                        }
                    `}
                >
                    <Skeleton width={116} height={20} />
                    <Skeleton width={toSm ? 156 : 80} height={36} />
                </div>

                <div
                    css={css`
                        display: flex;
                        align-items: center;

                        span + span {
                            margin-left: 8px;
                        }

                        span:first-of-type {
                            margin-right: 8px;
                        }
                    `}
                >
                    <Skeleton width={60} height={20} />
                    <Skeleton width={36} height={36} />
                    <Skeleton width={36} height={36} />
                    <Skeleton width={36} height={36} />
                    <Skeleton width={36} height={36} />
                </div>
            </div>
        </div>
    );
};

export default FooterSkeleton;
