/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from "@emotion/react";

import { useContext } from "react";
import ThemeContext from "../../../../../context/theme/themeContext";
import NotificationsOffRoundedIcon from "@mui/icons-material/NotificationsOffRounded";

interface NoNotificationsProps {
    description: string;
}

const NoNotifications = ({ description }: NoNotificationsProps) => {
    const {
        colors: { gray700, gray100, textDark, textPlaceholder },
    } = useContext(ThemeContext);

    return (
        <div
            css={css({
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                padding: "16px",
            })}
        >
            <div
                css={css({
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "40px",
                    height: "40px",
                    background: gray100,
                    borderRadius: "8px",
                    marginBottom: "16px",

                    "& > svg": {
                        color: textPlaceholder,
                    },
                })}
            >
                <NotificationsOffRoundedIcon />
            </div>

            <div
                css={css({
                    color: textDark,
                    fontSize: "16px",
                    fontWeight: "600",
                    lineHeight: "20px",
                    letterSpacing: "0.15px",
                    marginBottom: "8px",
                })}
            >
                No notifications to show
            </div>

            <div
                css={css({
                    color: gray700,
                    fontSize: "14px",
                    fontWeight: "400",
                    lineHeight: "20px",
                    letterSpacing: "0.1px",
                })}
            >
                {description}
            </div>
        </div>
    );
};

export default NoNotifications;
