/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from "@emotion/react";

import { Fragment, useContext } from "react";
import LanguageContext from "../../../../../../context/language/languageContext";
import LocationMap from "./LocationMap";
import { Marker, Popup } from "react-leaflet";
import LayoutContext from "../../../../../../context/layout/layoutContext";
import { Icon } from "leaflet";
import MapMarkerIcon from "../../../../../../assets/icons/MapMarkerIcon.png";
import { GnssLocationInformationData } from "../../../../../../generatedTypes";
import { SerializedStyles } from "@emotion/react/dist/emotion-react.cjs";
import TypedGrid from "../../../../../MuiComponents/TypedGrid";
import Divider from "../../../../../MuiComponents/Divider";

interface LocationProps {
    data: GnssLocationInformationData;
    keyBaseStyles: SerializedStyles;
    valueBaseStyles: SerializedStyles;
}

const Location = ({ data, keyBaseStyles, valueBaseStyles }: LocationProps) => {
    const {
        latitudeString,
        latitude,
        longitudeString,
        longitude,
        altitude,
        speed,
        angle,
        hdop,
        pdop,
    } = data;

    const { t } = useContext(LanguageContext);
    const { layoutData } = useContext(LayoutContext);

    const markerIcon = new Icon({
        iconUrl: MapMarkerIcon,
        iconRetinaUrl: MapMarkerIcon,
        iconSize: [40, 48],
    });
    return (
        <div
            css={css`
                .leaflet-container {
                    width: 100%;
                    height: 408px;
                    z-index: 300;
                }
            `}
        >
            <div
                css={css`
                    margin: 8px 0;

                    @media (max-width: 1439px) {
                        margin: 4px 0;
                    }

                    @media (max-width: 839px) {
                        margin: 8px 0;
                    }

                    @media (max-width: 599px) {
                        margin: 0;
                    }
                `}
            >
                <TypedGrid container columnSpacing={2} alignItems="center">
                    <TypedGrid item xs0={6}>
                        <div
                            css={css`
                                ${keyBaseStyles};
                            `}
                        >
                            {t.Latitude}/{t.Longitude}
                        </div>
                    </TypedGrid>

                    <TypedGrid item xs0={6}>
                        <div
                            css={css`
                                ${valueBaseStyles};

                                & > span:last-of-type {
                                    margin-left: 5px;
                                }

                                @media (max-width: 599px) {
                                    & > span {
                                        display: block;
                                    }

                                    & > span:last-of-type {
                                        margin-left: 0;
                                    }
                                }
                            `}
                        >
                            <span>{latitudeString},</span>

                            <span>{longitudeString}</span>
                        </div>
                    </TypedGrid>
                </TypedGrid>
            </div>

            <Divider />

            <div
                css={css`
                    margin: 8px 0;
                `}
            >
                <TypedGrid container columnSpacing={2} alignItems="center">
                    <TypedGrid item xs0={6}>
                        <div
                            css={css`
                                ${keyBaseStyles};
                            `}
                        >
                            {t.Altitude}
                        </div>
                    </TypedGrid>

                    <TypedGrid item xs0={6}>
                        <div
                            css={css`
                                ${valueBaseStyles};
                            `}
                        >
                            {altitude}
                        </div>
                    </TypedGrid>
                </TypedGrid>
            </div>

            <Divider />

            <div
                css={css`
                    margin: 8px 0;
                `}
            >
                <TypedGrid container columnSpacing={2} alignItems="center">
                    <TypedGrid item xs0={6}>
                        <div
                            css={css`
                                ${keyBaseStyles};
                            `}
                        >
                            {t.Speed}
                        </div>
                    </TypedGrid>

                    <TypedGrid item xs0={6}>
                        <div
                            css={css`
                                ${valueBaseStyles};
                            `}
                        >
                            {speed}
                        </div>
                    </TypedGrid>
                </TypedGrid>
            </div>

            <Divider />

            <div
                css={css`
                    margin: 8px 0;
                `}
            >
                <TypedGrid container columnSpacing={2} alignItems="center">
                    <TypedGrid item xs0={6}>
                        <div
                            css={css`
                                ${keyBaseStyles};
                            `}
                        >
                            {t.Angle}
                        </div>
                    </TypedGrid>

                    <TypedGrid item xs0={6}>
                        <div
                            css={css`
                                ${valueBaseStyles};
                            `}
                        >
                            {angle}
                        </div>
                    </TypedGrid>
                </TypedGrid>
            </div>

            <Divider />

            {hdop && (
                <Fragment>
                    <div
                        css={css`
                            margin: 8px 0;
                        `}
                    >
                        <TypedGrid
                            container
                            columnSpacing={2}
                            alignItems="center"
                        >
                            <TypedGrid item xs0={6}>
                                <div
                                    css={css`
                                        ${keyBaseStyles};
                                    `}
                                >
                                    {t.Hdop}
                                </div>
                            </TypedGrid>

                            <TypedGrid item xs0={6}>
                                <div
                                    css={css`
                                        ${valueBaseStyles};
                                    `}
                                >
                                    {hdop}
                                </div>
                            </TypedGrid>
                        </TypedGrid>
                    </div>

                    <Divider />
                </Fragment>
            )}

            {pdop && (
                <Fragment>
                    <div
                        css={css`
                            margin: 8px 0;
                        `}
                    >
                        <TypedGrid
                            container
                            columnSpacing={2}
                            alignItems="center"
                        >
                            <TypedGrid item xs0={6}>
                                <div
                                    css={css`
                                        ${keyBaseStyles};
                                    `}
                                >
                                    {t.Pdop}
                                </div>
                            </TypedGrid>

                            <TypedGrid item xs0={6}>
                                <div
                                    css={css`
                                        ${valueBaseStyles};
                                    `}
                                >
                                    {pdop}
                                </div>
                            </TypedGrid>
                        </TypedGrid>
                    </div>

                    <Divider />
                </Fragment>
            )}

            <LocationMap
                center={[latitude, longitude]}
                addMarginTop={true}
                zoom={15}
            >
                <Marker position={[latitude, longitude]} icon={markerIcon}>
                    <Popup>{layoutData!.fmType}</Popup>
                </Marker>
            </LocationMap>
        </div>
    );
};

export default Location;
