/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from "@emotion/react";

import { useContext, useState } from "react";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import ThemeContext from "../../../../context/theme/themeContext";
import LanguageContext from "../../../../context/language/languageContext";
import DiscoveringAnimation from "../DiscoveringAnimation";
import SingleDiscoveredDevice from "./SingleDiscoveredDevice";
import ToggleButton from "./ToggleButton";
import Grid from "@mui/material/Grid";
import Collapse from "@mui/material/Collapse";

export interface DiscoveredDevicesProps {
    devices: any[];
}

const DiscoveredDevices = ({ devices }: DiscoveredDevicesProps) => {
    const {
        colors: { textDark },
    } = useContext(ThemeContext);

    const { t } = useContext(LanguageContext);

    const [showMore, setShowMore] = useState(false);

    const handleDevicesToggle = () => setShowMore(!showMore);

    return (
        <div
            css={css`
                &.discovered-enter {
                    opacity: 0;
                }

                &.discovered-enter-active {
                    opacity: 1;
                    transition: opacity 300ms;
                }

                &.discovered-exit {
                    opacity: 1;
                    max-height: 0;
                }

                &.discovered-exit-active {
                    opacity: 0;
                    max-height: 0;
                    transition: opacity 150ms;
                }
            `}
        >
            <div
                css={css`
                    padding: 16px 24px;

                    @media (max-width: 839px) {
                        padding: 0;
                    }
                `}
            >
                <div
                    css={css`
                        display: flex;
                        align-items: center;
                        margin-bottom: 16px;

                        h2 {
                            color: ${textDark};
                            font-size: 20px;
                            font-weight: 600;
                            line-height: 24px;
                            letter-spacing: 0.15px;
                            margin-right: 16px;
                        }
                    `}
                >
                    <h2>{t.DiscoveredDevices}</h2>

                    <DiscoveringAnimation />
                </div>

                <Grid container rowSpacing={2} columnSpacing={3}>
                    <TransitionGroup component={null}>
                        {devices.map(
                            (device, i) =>
                                i < 4 && (
                                    <CSSTransition
                                        key={i}
                                        timeout={300}
                                        classNames="discovered-single"
                                    >
                                        <SingleDiscoveredDevice
                                            key={device.imei}
                                            data={device}
                                        />
                                    </CSSTransition>
                                )
                        )}
                    </TransitionGroup>
                </Grid>

                <Collapse in={showMore}>
                    <Grid
                        container
                        rowSpacing={2}
                        columnSpacing={3}
                        css={css`
                            margin-top: 0;
                        `}
                    >
                        <TransitionGroup component={null}>
                            {devices.map(
                                (device, i) =>
                                    i > 3 && (
                                        <CSSTransition
                                            key={i}
                                            timeout={300}
                                            classNames="discovered-single"
                                        >
                                            <SingleDiscoveredDevice
                                                key={device.imei}
                                                data={device}
                                            />
                                        </CSSTransition>
                                    )
                            )}
                        </TransitionGroup>
                    </Grid>
                </Collapse>

                <ToggleButton
                    closed={!showMore}
                    count={devices.length - 4}
                    toggleDevices={handleDevicesToggle}
                />
            </div>
        </div>
    );
};

export default DiscoveredDevices;
