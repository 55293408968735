/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from "@emotion/react";

import { useContext, useRef } from "react";
import { Popover } from "@mui/material";
import SearchInput from "./SearchInput";
import avlSearchContext from "../../../../../../context/avlSearch/avlSearchContext";
import MenuItemContext from "../../../../../../context/menuItem/menuItemContext";
import ThemeContext from "../../../../../../context/theme/themeContext";

export interface SearchPopOverProps {
    data?: any; //
}
const SearchPopOver = ({ data }: SearchPopOverProps) => {
    const elementRef = useRef(null);
    const { updateParameter } = useContext(MenuItemContext);
    const {
        colors: { textPlaceholder, textDark, gray50 },
    } = useContext(ThemeContext);

    const {
        setModalAnchor,
        openModalAnchor,
        results,
        activeParameterId,
        setActiveParameterId,
        setParameterNames,
    } = useContext(avlSearchContext);

    const onSuccess = (data: any) => {
        setParameterNames({
            parameterId: activeParameterId,
            parameterName: data.parameterName,
        });
        setActiveParameterId("");
    };

    const onError = (data: any) => {
        setParameterNames({
            parameterId: activeParameterId,
            parameterName: data.parameterName,
        });
        setActiveParameterId("");
    };

    const handleItemClick = async (selection: any) => {
        setModalAnchor(null);
        updateParameter(
            activeParameterId,
            selection.avlId,
            "AVL ID",
            elementRef,
            () => onSuccess(selection),
            () => onError(selection),
            null,
            true
        );
    };

    return (
        <Popover
            ref={elementRef}
            open={!!openModalAnchor}
            anchorEl={openModalAnchor}
            onClose={() => setModalAnchor(null)}
            anchorPosition={{ top: 0, left: 0 }}
            anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
            }}
            transformOrigin={{
                vertical: "top",
                horizontal: "center",
            }}
        >
            <div css={css({ minWidth: "380px" })}>
                <SearchInput />
            </div>
            {!!results?.length && (
                <div
                    css={css({
                        height: "180px",
                        overflow: "auto",
                    })}
                >
                    {results?.map((result, index) => {
                        return (
                            <div
                                css={css({
                                    padding: "16px 24px",
                                    borderBottom: "1px solid #CBD5E1",
                                    cursor: "pointer",
                                    "&:hover": {
                                        backgroundColor: gray50,
                                    },
                                })}
                                onClick={() => handleItemClick(result)}
                                key={index}
                            >
                                <div
                                    css={css({
                                        fontStyle: "normal",
                                        fontWeight: 600,
                                        fontSize: "10px",
                                        lineHeight: "12px",
                                        letterSpacing: "1px",
                                        color: textPlaceholder,
                                    })}
                                >
                                    AVL ID: {result.avlId}{" "}
                                </div>
                                <div
                                    css={css({
                                        fontStyle: "normal",
                                        fontWeight: 600,
                                        fontSize: "14px",
                                        lineHeight: "20px",
                                        letterSpacing: "0.1px",
                                        color: textDark,
                                    })}
                                >
                                    {result.parameterName}
                                </div>
                            </div>
                        );
                    })}
                </div>
            )}
        </Popover>
    );
};

export default SearchPopOver;
