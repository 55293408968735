import React, { useContext } from "react";
import { Route as RouterRoute } from "react-router-dom";
import UserInfoContext from "../../context/userInfo/userInfoContext";
import Loader from "../Loader";

export interface RouteProps {
    component: any;
    exact?: boolean;
    path: string;
}

const Route = ({ component: Component, ...rest }: RouteProps) => {
    const { loading } = useContext(UserInfoContext);
    return loading ? (
        <Loader />
    ) : (
        <RouterRoute {...rest} render={(props) => <Component {...props} />} />
    );
};

export default Route;
