/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from "@emotion/react";

import { useContext } from "react";
import ThemeContext from "../../../context/theme/themeContext";
import Search from "../Search";
import Settings from "../Settings";
import { noop } from "../../../utils/helpers";
import { TopBarProps } from "../../../utils/types";

const DesktopTopBar = ({ disabled }: TopBarProps) => {
    const {
        colors: { white },
        boxShadows: { paperBoxShadow },
    } = useContext(ThemeContext);

    return (
        <header
            css={css({
                background: white,
                padding: "10px 24px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                boxShadow: paperBoxShadow,
                position: "sticky",
                top: 0,
                zIndex: 400,
            })}
        >
            <Search isOpenMenu={false} toggleMenu={noop} disabled={disabled} />

            <Settings startup={false} disabled={disabled} />
        </header>
    );
};

export default DesktopTopBar;
