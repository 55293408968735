/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from "@emotion/react";

import { useContext, useEffect, useRef, useState } from "react";
import { validateNumberLength } from "../../../../../../utils/validations";
import { preventENotation } from "../../../../../../utils/helpers";
import InputField from "../../../../../MuiComponents/InputField";
import { SelectChangeEvent } from "@mui/material";
import LanguageContext from "../../../../../../context/language/languageContext";

export interface CalculatorInputProps {
    onChange: (e: SelectChangeEvent<any>) => void;
    onError: (hasError: boolean) => void;
    initialValue: number;
    min: number;
    max: number;
    increment: number;
    label: string;
}

const CalculatorInput = ({
    initialValue,
    min,
    max,
    increment,
    onChange,
    onError,
    label,
}: CalculatorInputProps) => {
    const [value, setValue] = useState(initialValue);
    const [error, setError] = useState<string | null>(null);

    const { t } = useContext(LanguageContext);

    const elementRef = useRef(null);

    useEffect(() => onError(Boolean(error)), [error]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value;
        const errorValue = validateNumberLength(newValue, min, max, t);

        setError(errorValue);
        setValue(Number(newValue));

        if (errorValue) return;

        onChange(e);
    };

    return (
        <InputField
            ref={elementRef}
            fullWidth
            size="medium"
            label={label}
            type="number"
            inputProps={{
                min,
                max,
                step: increment,
            }}
            value={value}
            onChange={handleChange}
            onKeyDown={preventENotation}
            error={error}
            wrapperStyle={css({
                scrollMargin: "16px",
            })}
        />
    );
};

export default CalculatorInput;
