/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx, SerializedStyles } from "@emotion/react";

import { Fragment, useContext, useState } from "react";
import ThemeContext from "../../context/theme/themeContext";
import Checkbox from "./Checkbox";
import MenuItem from "@mui/material/MenuItem";
import CloseIcon from "@mui/icons-material/CloseRounded";
import { SvgIconProps } from "@mui/material/SvgIcon";
import useFeElementParser from "../../utils/useFeElementParser";

interface ListChoiceProps {
    timeStamp?: React.ReactNode;
    title: string;
    description?: React.ReactNode;
    icon?: React.ReactElement<SvgIconProps>;
    checkbox?: boolean;
    dismissIcon?: boolean;
    selected?: boolean;
    disabled?: boolean;
    onClick?: () => void;
    css?: SerializedStyles;
    dynamicContent?: any;
}

const ListChoice = ({
    timeStamp,
    title,
    description,
    icon,
    checkbox,
    dismissIcon,
    selected,
    disabled,
    dynamicContent,
    onClick,
    ...props
}: ListChoiceProps) => {
    const {
        colors: { white, gray700, gray100, textDark, textDarkDisabled },
    } = useContext(ThemeContext);

    const [isSelected, setIsSelected] = useState(selected);

    const { renderFeElement } = useFeElementParser();

    const handleToggleSelect = () => {
        setIsSelected(!isSelected);
        onClick && onClick();
    };

    const renderContent = () => {
        if (dynamicContent) {
            return renderFeElement(dynamicContent, undefined, "notification");
        }
        return (
            <Fragment>
                <div
                    css={css({
                        color: disabled ? textDarkDisabled : textDark,
                        fontSize: "14px",
                        fontWeight: "600",
                        lineHeight: "20px",
                        letterSpacing: "0.1px",
                    })}
                >
                    {title}
                </div>

                {description && (
                    <div
                        css={css({
                            color: disabled ? textDarkDisabled : gray700,
                            fontSize: "12px",
                            lineHeight: "16px",
                            letterSpacing: "0.4px",
                            marginTop: "4px",
                            wordBreak: "break-word",
                        })}
                    >
                        {description}
                    </div>
                )}
            </Fragment>
        );
    };
    return (
        <MenuItem
            {...props}
            selected={isSelected && !checkbox}
            onClick={handleToggleSelect}
            css={css({
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                backgroundColor: white,
                borderRadius: "3px",
                cursor: disabled ? "default" : "pointer",
                pointerEvents: disabled ? "none" : "auto",
                padding: "12px 16px",
                transition:
                    "background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;",

                "&:hover": {
                    backgroundColor: gray100,
                },
            })}
        >
            <div
                css={css({
                    display: "flex",
                    alignItems: description ? "normal" : "center",
                })}
            >
                {icon && (
                    <span
                        css={css({
                            display: "inherit",

                            "& > svg": {
                                color: disabled ? textDarkDisabled : textDark,
                            },
                        })}
                    >
                        {icon}
                    </span>
                )}

                <div>
                    {timeStamp ? timeStamp : ""}

                    {renderContent()}
                </div>
            </div>

            {checkbox && (
                <Checkbox
                    checked={isSelected}
                    disabled={disabled}
                    css={css({
                        marginLeft: "8px",
                    })}
                />
            )}

            {dismissIcon && (
                <CloseIcon
                    css={css({
                        alignSelf: "flex-start",
                        fontSize: "16px",
                        marginLeft: "8px",
                        marginRight: "0 !important",
                    })}
                />
            )}
        </MenuItem>
    );
};

export default ListChoice;
