/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from "@emotion/react";

import { useContext } from "react";
import ThemeContext from "../../../../../context/theme/themeContext";
import SearchContext from "../../../../../context/search/searchContext";
import Button from "../../../../MuiComponents/Button";
import EmptySearchResultsIcon from "../../../../../assets/icons/EmptySearchResultsIcon";

const NoSearchResults = () => {
    const {
        colors: { textDark, gray700 },
    } = useContext(ThemeContext);

    const { searchPhrase, isLoading, clearInput } = useContext(SearchContext);

    return (
        <div
            css={css({
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                padding: "16px 32px 24px 32px",

                "& > svg": {
                    fontSize: "120px",
                    marginBottom: "16px",
                },

                "& > h4": {
                    color: textDark,
                    fontSize: "16px",
                    lineHeight: "20px",
                    fontWeight: "600",
                    letterSpacing: "0.15px",
                    textAlign: "center",
                    marginBottom: "8px",
                },

                "& > p": {
                    color: gray700,
                    fontSize: "14px",
                    lineHeight: "20px",
                    letterSpacing: "0.1px",
                    textAlign: "center",
                    marginBottom: "12px",
                },
            })}
        >
            <EmptySearchResultsIcon />

            <h4>{`No results for "${searchPhrase}"`}</h4>

            <p>Please try a different keyword for more results</p>

            <Button
                variant="textOnly"
                size="normal"
                color="primary"
                onClick={clearInput}
                css={css({
                    pointerEvents: isLoading ? "none" : "auto",
                })}
                idForTesting="clearSearchButton"
            >
                Clear Search
            </Button>
        </div>
    );
};

export default NoSearchResults;
