/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import PasswordModal from "../../../../../../MuiComponents/Modals/PasswordModal";
import InputField from "../../../../../../MuiComponents/InputField";
import LanguageContext from "../../../../../../../context/language/languageContext";
import { useContext, useState } from "react";
import Tooltip from "../../../../../../MuiComponents/Tooltip";
import { IconButton } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import BannerView from "../../../../../../MuiComponents/BannerView";
import AlertContext from "../../../../../../../context/alert/alertContext";
import { convertAxiosErrorToErrorDetails } from "../../../../../../../utils/helpers";
import LayoutContext from "../../../../../../../context/layout/layoutContext";
import {
    AlertStatus,
    HttpStatusCodes,
} from "../../../../../../../constants/constants";
import useTct from "../../../../../../../utils/useTct";

export interface EnterSavedPasswordsModalProps {
    enterPasswordsModalInfo: {
        isOpen: boolean;
        hasBothPasswords: boolean;
        hasOnlyPrimaryPassword: boolean;
        modified: boolean;
    };
    onClose: () => void;
    isPasswordChanged?: boolean;
    onPasswordSave: (e: any) => void;
}

const EnterSavedPasswordsModal: React.FunctionComponent<
    EnterSavedPasswordsModalProps
> = ({
    enterPasswordsModalInfo,
    onClose,
    isPasswordChanged,
    onPasswordSave,
}) => {
    const { setAlert } = useContext(AlertContext);
    const { t } = useContext(LanguageContext);
    const { layoutData } = useContext(LayoutContext);
    const [passwordShow, setPasswordShow] = useState(false);
    const [seconadaryPasswordShow, setSecondaryPasswordShow] = useState(false);
    const [primaryPassword, setPrimaryPassword] = useState("");
    const [secondaryPassword, setSecondaryPassword] = useState("");

    const [primaryPasswordError, setPrimaryPasswordError] = useState("");
    const [secondaryPasswordError, setSecondaryPasswordError] = useState("");
    const { validateParameterAsync } = useTct();

    const handleClose = () => {
        setPrimaryPasswordError("");
        setSecondaryPasswordError("");
        onClose();
    };

    const renderTitle = () => {
        if (enterPasswordsModalInfo.hasBothPasswords) {
            return t.ConfigurationPasswordsRequired;
        }
        if (enterPasswordsModalInfo.hasOnlyPrimaryPassword) {
            return t.PrimaryPasswordTitle;
        }
        return "";
    };

    const renderDescription = () => {
        if (isPasswordChanged) {
            return (
                <BannerView
                    data={{
                        bannerType: "Info",
                        description: t.PasswordsDifferentFromDevice,
                    }}
                />
            );
        }
        if (enterPasswordsModalInfo.hasBothPasswords) {
            return t.RecoveryPasswordConfirmationDescription;
        }
        if (enterPasswordsModalInfo.hasOnlyPrimaryPassword) {
            return t.EnterCurrentPrimaryPasswordDescription;
        }
        return "";
    };

    const validatePassword = async (
        password: string,
        isPrimary: boolean
    ): Promise<string | null> => {
        if (!layoutData) {
            throw new Error("Cannot save passwords without a layout");
        }

        try {
            const parameterId = isPrimary
                ? layoutData.parameterConfiguration.configurationPasswordId
                : layoutData.parameterConfiguration.recoveryPasswordId;
            await validateParameterAsync(layoutData.id, parameterId, password);
        } catch (err: any) {
            if (err.response.status != HttpStatusCodes.UnprocessableEntity) {
                const error = convertAxiosErrorToErrorDetails(err);
                return error.description;
            }
        }

        return null;
    };

    const handleSubmit = async (e: any) => {
        const primaryValidation = validatePassword(primaryPassword, true);
        const recoveryValidation = validatePassword(secondaryPassword, false);

        const primaryError = await primaryValidation;
        const recoveryError = await recoveryValidation;
        if (primaryError) {
            setPrimaryPasswordError(primaryError);
            return;
        }
        if (recoveryError) {
            setSecondaryPasswordError(recoveryError);
            return;
        }

        try {
            onPasswordSave(e);
        } catch (err: any) {
            handleClose();
            const error = err.response?.data;
            setAlert(AlertStatus.Critical, error.detail);
        }
    };

    return (
        <PasswordModal
            isOpen={enterPasswordsModalInfo?.isOpen}
            isDisabled={false}
            isLoading={false}
            title={renderTitle()}
            description={renderDescription()}
            submitBtnText="Save"
            close={handleClose}
            sendPayload={handleSubmit}
        >
            <InputField
                fullWidth
                size="medium"
                label={t.SetPasswordPrimaryLabel}
                placeholder={t.EnterHere}
                type={passwordShow ? "text" : "password"}
                name="password"
                onChange={(e) => setPrimaryPassword(e.target.value)}
                iconRight={
                    <Tooltip
                        title={passwordShow ? t.HidePassword : t.ShowPassword}
                        placement="top-end"
                        css={css({
                            "& .MuiTooltip-tooltip": {
                                marginRight: "-6px",
                            },
                        })}
                    >
                        <IconButton
                            disableRipple
                            onMouseDown={(e) => setPasswordShow(!passwordShow)}
                            size="large"
                        >
                            {passwordShow ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                    </Tooltip>
                }
                wrapperStyle={css({
                    marginBottom: "16px",
                })}
                error={primaryPasswordError}
            />

            {enterPasswordsModalInfo?.hasBothPasswords && (
                <InputField
                    fullWidth
                    size="medium"
                    label={t.SetPasswordRecoveryLabel}
                    placeholder={t.EnterHere}
                    type={seconadaryPasswordShow ? "text" : "password"}
                    name="password"
                    onChange={(e) => setSecondaryPassword(e.target.value)}
                    iconRight={
                        <Tooltip
                            title={
                                seconadaryPasswordShow
                                    ? t.HidePassword
                                    : t.ShowPassword
                            }
                            placement="top-end"
                            css={css({
                                "& .MuiTooltip-tooltip": {
                                    marginRight: "-6px",
                                },
                            })}
                        >
                            <IconButton
                                disableRipple
                                onMouseDown={(e) =>
                                    setSecondaryPasswordShow(
                                        !seconadaryPasswordShow
                                    )
                                }
                                size="large"
                            >
                                {seconadaryPasswordShow ? (
                                    <VisibilityOff />
                                ) : (
                                    <Visibility />
                                )}
                            </IconButton>
                        </Tooltip>
                    }
                    error={secondaryPasswordError}
                />
            )}
        </PasswordModal>
    );
};

export default EnterSavedPasswordsModal;
