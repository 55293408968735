/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx, SerializedStyles } from "@emotion/react";

import { useContext } from "react";
import ThemeContext from "../../context/theme/themeContext";
import ButtonMui from "@mui/material/Button";

export interface NavigationListButtonProps {
    children?: React.ReactNode;
    icon?: React.ReactNode;
    isActive?: boolean;
    narrow?: boolean;
    disabled?: boolean;
    fullWidth?: boolean;
    css?: SerializedStyles;
    onClick?: (e: any) => void;
    component?: any;
    href?: string;
    target?: string;
    rel?: string;
    idForTest: string | number;
}

const NavigationListButton = ({
    children,
    icon,
    isActive,
    narrow,
    idForTest,
    ...props
}: NavigationListButtonProps) => {
    const {
        colors: { white },
    } = useContext(ThemeContext);

    const bgColor = () => {
        if (isActive) {
            return "rgba(255, 255, 255, 0.05)";
        }
        return "transparent";
    };

    return (
        <ButtonMui
            {...props}
            data-test={String(idForTest)}
            css={css({
                backgroundColor: bgColor(),
                color: props.disabled ? "rgba(255, 255, 255, 0.3)" : white,
                flexDirection: narrow ? "column" : "row",
                justifyContent: narrow ? "center" : "flex-start",
                padding: icon ? "6px 8px" : "8px",
                letterSpacing: "0.1px",
                borderRadius: "4px",
                wordBreak: "break-word",
                "& > svg": {
                    marginRight: narrow || !children ? "0" : "8px",
                    marginBottom: narrow ? "8px" : "0",
                },

                "&.Mui-disabled": {
                    backgroundColor: "transparen",
                    color: "rgba(255, 255, 255, 0.3)",

                    "& > svg": {
                        color: "rgba(255, 255, 255, 0.3)",
                    },
                },

                "&:hover": {
                    backgroundColor: "rgba(255, 255, 255, 0.05)",
                },
            })}
        >
            {icon ? icon : ""}

            {children}
        </ButtonMui>
    );
};

export default NavigationListButton;
