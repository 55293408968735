/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from "@emotion/react";

import { Fragment, useContext } from "react";
import ThemeContext from "../../../../../context/theme/themeContext";
import SettingsContext from "../../../../../context/settings/settingsContext";
import useMediaQueries from "../../../../../utils/useMediaQueries";
import Popover from "@mui/material/Popover";
import TextLink from "../../../../MuiComponents/TextLink";
import LanguageContext from "../../../../../context/language/languageContext";
import SingleLanguage from "./SingleLanguage";
import { LanguageInfo } from "../../../../../generatedTypes";

const InterfaceSettingsModal = () => {
    const {
        colors: { textDark },
    } = useContext(ThemeContext);
    const { languages } = useContext(LanguageContext);
    const {
        anchorEl,
        isLanguageSettingsModalOpen,
        closeLanguageSettingsModal,
        openSettingsModal,
    } = useContext(SettingsContext);

    const { toMd } = useMediaQueries();

    return (
        <Fragment>
            <Popover
                open={isLanguageSettingsModalOpen}
                anchorEl={anchorEl}
                onClose={closeLanguageSettingsModal}
                anchorReference={toMd ? "anchorPosition" : "anchorEl"}
                anchorPosition={{ top: 0, left: 0 }}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                }}
                marginThreshold={toMd ? 0 : 16}
                sx={{
                    "& .MuiPaper-root": {
                        maxHeight: toMd ? "unset" : "626px",
                        height: toMd ? "100%" : "unset",
                        maxWidth: toMd ? "unset" : "448px",
                        width: toMd ? "100%" : "calc(100% - 284px)",
                        marginTop: toMd ? "0" : "10px",
                        overflowY: toMd ? "auto" : "hidden",
                    },
                }}
            >
                <div
                    css={css({
                        display: "flex",
                        padding: "16px 30px 16px 24px",

                        "& > h2": {
                            color: textDark,
                            fontSize: "20px",
                            lineHeight: "24px",
                            fontWeight: "600",
                            letterSpacing: "0.15px",
                            alignSelf: "flex-end",
                        },
                    })}
                >
                    <TextLink
                        size="normal"
                        onClick={() => openSettingsModal()}
                        idForTesting="language-settings-modal-back-button"
                        startIcon
                    >
                        Go back
                    </TextLink>
                </div>
                <div>
                    {languages.map((lang: LanguageInfo) => (
                        <SingleLanguage data={lang} key={lang.shortName} />
                    ))}
                </div>
            </Popover>
        </Fragment>
    );
};

export default InterfaceSettingsModal;
