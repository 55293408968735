import React from "react";
import ConfirmModal from "../../../../../../MuiComponents/Modals/ConfirmModal";

export interface SavePasswordsConfirmationModalProps {
    modalInfoData: {
        isOpen: boolean;
        hasBothPasswords: boolean;
        hasOnlyPrimaryPassword: boolean;
    };
    onClose: () => void;
    onSubmit: (e: React.SyntheticEvent) => void;
    onSecondaryButtonClick: (e: React.SyntheticEvent) => void;
}

const SavePasswordsConfirmationModal: React.FunctionComponent<
    SavePasswordsConfirmationModalProps
> = ({ modalInfoData, onClose, onSubmit, onSecondaryButtonClick }) => {
    const { isOpen, hasBothPasswords, hasOnlyPrimaryPassword } = modalInfoData;

    const renderTitle = () => {
        if (hasBothPasswords) {
            return "Do you want to save your primary and recovery passwords to file?";
        }
        if (hasOnlyPrimaryPassword) {
            return "Do you want to save your primary password to file?";
        }
        return "";
    };

    return (
        <ConfirmModal
            open={isOpen}
            onSecondaryButtonClick={onSecondaryButtonClick}
            close={onClose}
            submit={onSubmit}
            title={renderTitle()}
        />
    );
};

export default SavePasswordsConfirmationModal;
