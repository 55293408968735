/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from "@emotion/react";

import { Fragment } from "react";

export interface IconLeftButtonProps {
    children: React.ReactNode;
    icon: React.ReactNode;
}
const IconLeftButton = ({ children, icon }: IconLeftButtonProps) => {
    return (
        <Fragment>
            <span
                css={css`
                    display: flex;
                    align-items: center;
                    margin-right: 8px;
                `}
            >
                {icon}
            </span>

            {children}
        </Fragment>
    );
};

export default IconLeftButton;
