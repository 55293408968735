/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import LocationMap from "../../../../StatusView/OnlineView/GnssInformation/Location/LocationMap";
import { useContext } from "react";
import "leaflet-draw/dist/leaflet.draw.css";
import { Geozone } from "../../../../../../utils/types";
import MapCenter from "./MapCenter";
import EditableLayer from "./EditableLayer";
import { GeozoneEditState, GeozoneOptions } from "../index";
import ThemeContext from "../../../../../../context/theme/themeContext";
import { LatLngBoundsExpression } from "leaflet";
import ManualGeofenceContext, {
    EditorState,
} from "../../../../../../context/manualGeofence/manualGeofenceContext";
import { formatCoordinatesBasedOnGeozoneShape } from "../../../../../../utils/helpers";
import {
    defaultRadius,
    polygonMaxVerticles,
} from "../../../../../../constants/constants";

const ManualGeofenceMap = () => {
    const {
        colors: { blue700, white },
    } = useContext(ThemeContext);

    const {
        editState,
        centerCoordinates,
        setEditState,
        setCenterCoordinates,
        setLoading,
        setHighlightedGeozone,
        highlightedGeozone,
        geozones,
        setLatitudeLongituteValues,
        setRadius,
        availableShapes,
        setShapeParameter,
    } = useContext(ManualGeofenceContext);

    const bounds: LatLngBoundsExpression = [
        [-90, -181], // -181 and 181 is used to allow user to see the center of the circle when on map bounds
        [90, 181],
    ];

    const geozoneOptions: GeozoneOptions = {
        AllowedShapes: availableShapes,
        MaxPolygonVerticesCount: polygonMaxVerticles,
    };

    const onEditStart = (index: number) => {
        const getGeozoneCoordinates = (index: number) => {
            const geozone = geozones.find((zone: any) => zone.index === index);
            return geozone?.coordinates.length
                ? geozone.coordinates[0]
                : centerCoordinates;
        };
        setEditState({
            currentIndex: index,
            previousIndex: editState.currentIndex,
            currentEditorState: EditorState.Start,
        });
        setCenterCoordinates(getGeozoneCoordinates(index));
    };

    const onShapeChange = (geozone: Geozone) => {
        const { latitude, longitude } =
            formatCoordinatesBasedOnGeozoneShape(geozone);

        setShapeParameter(geozone.type - 1);
        setLatitudeLongituteValues({
            lat: latitude,
            long: longitude,
        });
        setRadius(Math.round(geozone.radius || defaultRadius));
    };

    const onMapChange = () => {
        setLoading(true);
    };

    const onHighlightGeozone = (index: number) => {
        setHighlightedGeozone(index);
    };

    const onGeozoneChanged = (geozone: Geozone) => {
        const { latitude, longitude } =
            formatCoordinatesBasedOnGeozoneShape(geozone);

        setLatitudeLongituteValues({
            lat: latitude,
            long: longitude,
        });
        setRadius(Math.round(geozone.radius || defaultRadius));
    };

    return (
        <div
            css={css({
                ".leaflet-container": {
                    width: "100%",
                    height: 648,
                    zIndex: 300,
                },
                ".leaflet-editing-icon": {
                    background: white,
                    border: `2px solid ${blue700}`,
                    borderRadius: 100,
                    width: "10px !important",
                    height: "10px !important",
                    marginTop: "-5px !important",
                    marginLeft: "-5px !important",
                },
                ".leaflet-tooltip": {
                    border: "none",
                    background: "none",
                    boxShadow: "none",
                    fontFamily: "Open Sans",
                    fontWeight: 600,
                    lineHeight: 20,
                    textAlign: "center",
                    letterSpacing: 0.15,
                },
            })}
        >
            <LocationMap center={centerCoordinates} zoom={4} bounds={bounds}>
                <MapCenter center={centerCoordinates} />
                {geozones.map((geozone: Geozone, index: number) => (
                    <EditableLayer
                        key={index}
                        geozone={geozone}
                        highlightedGeozone={highlightedGeozone}
                        highlightGeozone={onHighlightGeozone}
                        editState={editState}
                        onEditStart={onEditStart}
                        geozoneOptions={geozoneOptions}
                        onGeozoneChanged={onGeozoneChanged}
                        onShapeChange={onShapeChange}
                        onMapChange={onMapChange}
                    />
                ))}
            </LocationMap>
        </div>
    );
};

export default ManualGeofenceMap;
