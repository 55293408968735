/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/react";
import { useContext, useEffect, useState } from "react";
import LanguageContext from "../../../../../context/language/languageContext";
import AlertContext from "../../../../../context/alert/alertContext";
import useApi from "../../../../../utils/useApi";
import useDesktopHost from "../../../../../utils/useDesktopHost";
import { AlertStatus, PathType } from "../../../../../constants/constants";
import WebSocketsContext from "../../../../../context/webSockets/webSocketsContext";
import { Problem } from "../../../../../utils/types";
import CheckBox from "../../../../MuiComponents/Checkbox";
import FormModal from "../../../../MuiComponents/Modals/FormModal";

interface RecordModalProps {
    isOpen: boolean;
    onClose: () => void;
}

const RecordModal = ({ isOpen, onClose }: RecordModalProps) => {
    const { setRecordReadStatus } = useContext(WebSocketsContext);
    const { setAlert } = useContext(AlertContext);
    const { selectedLanguage, t } = useContext(LanguageContext);
    const { getData, cancelSource, isCanceled } = useApi();
    const { openSpecialDirectory } = useDesktopHost();
    const [startCapturing, setStartCapturing] = useState(false);
    const [deleteAfter, setDeleteAfter] = useState(false);

    const showRecordsCaptured = (message: string) => {
        setAlert(
            AlertStatus.Success,
            t.RecordsCaptured,
            message,
            t.OpenPath,
            () =>
                openSpecialDirectory({
                    type: PathType.Records,
                })
        );
        onStop();
    };

    const handleError = (err: any) => {
        if (isCanceled(err)) {
            setAlert(AlertStatus.Info, t.ErrorTitle_RequestTimeout);
        } else {
            const problem = err.response?.data as Problem;
            if (problem) {
                const severity =
                    problem.title === t.NoRecords
                        ? AlertStatus.Info
                        : AlertStatus.Critical;
                setAlert(severity, problem.title, problem.detail);
            } else {
                setAlert(
                    AlertStatus.Critical,
                    t.ErrorTitle_DataExport_ReadRecords
                );
            }
        }
        onStop();
    };

    const readRecords = async () => {
        await getData(
            `${selectedLanguage}/dataexport/readrecords/${deleteAfter}`
        )
            .then((response) => showRecordsCaptured(response.data))
            .catch((err) => handleError(err));
    };

    useEffect(() => {
        if (startCapturing) {
            readRecords();

            return () => cancelSource.cancel();
        }
    }, [startCapturing]);

    const onStart = () => {
        setStartCapturing(true);
    };

    const handleDeleteChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setDeleteAfter(event.target.checked);
    };

    const onStop = () => {
        setStartCapturing(false);
        cancelSource.cancel();
        setRecordReadStatus(null);
        onClose();
    };

    const recordDeleteControl = () => (
        <div
            style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
            }}
        >
            <CheckBox
                checked={deleteAfter}
                onChange={handleDeleteChange}
                label={t.DeleteReadRecords}
                disabled={startCapturing}
            />
        </div>
    );

    return (
        <FormModal
            title={t.ReadRecordsFromDevice}
            isOpen={isOpen}
            onClose={onStop}
            primaryButtonText={t.StartRecordReading}
            onSubmit={onStart}
            isPrimaryButtonDisabled={startCapturing}
            secondaryButtonText={t.Cancel}
            onSecondaryButtonClick={onStop}
            description={t.ReadRecordsInfo}
            isSubmitting={startCapturing}
            hasNoDivider
        >
            {recordDeleteControl()}
        </FormModal>
    );
};

export default RecordModal;
