/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from "@emotion/react";

import { useContext, useEffect, Fragment, useRef } from "react";
import ThemeContext from "../../context/theme/themeContext";

import { ReactComponent as RemoveIcon } from "../../assets/icons/terminal/Dismiss.svg";
import { ReactComponent as DownloadIcon } from "../../assets/icons/terminal/Download.svg";
import { ReactComponent as MinimizeIcon } from "../../assets/icons/terminal/Minimize.svg";
import { ReactComponent as StartArrowIcon } from "../../assets/icons/terminal/StartArrow.svg";
import { ReactComponent as PauseIcon } from "../../assets/icons/terminal/PauseLog.svg";
import { ReactComponent as AddIcon } from "../../assets/icons/terminal/Add.svg";
import { ReactComponent as FiltersIcon } from "../../assets/icons/terminal/Filters.svg";
import { ReactComponent as StopTerminalLogIcon } from "../../assets/icons/terminal/StopTerminalLog.svg";

import SearchIcon from "@mui/icons-material/SearchRounded";

import Tooltip from "../MuiComponents/Tooltip";
import SettingsContext from "../../context/settings/settingsContext";
import ConfirmModal from "../MuiComponents/Modals/ConfirmModal";
import InputField from "../MuiComponents/InputField";
import LanguageContext from "../../context/language/languageContext";
import PortList from "./PortList";
import TerminalContext from "../../context/components/terminal/terminalContext";
import UserInfoContext from "../../context/userInfo/userInfoContext";
import CommandInput from "./CommandInput/CommandInput";
import { TerminalMessage } from "../../generatedTypes";
import RndWindow from "./RndWindow";
import {
    AlertStatus,
    PortMessageType,
    PortStates,
    terminalMessageLength,
} from "../../constants/constants";
import useApi from "../../utils/useApi";
import useDesktopHost from "../../utils/useDesktopHost";
import AlertContext from "../../context/alert/alertContext";
import { TerminalPortType } from "../../utils/types";
import EmptyTerminalBlock from "./EmptyTerminalBlock";
import { useLocation } from "react-router-dom";
import useWebSockets from "../../utils/useWebSockets";
import { replacePlaceholders } from "../../utils/helpers";

export interface TerminalProps {}

const Terminal = () => {
    const {
        colors: { gray700, white, textPlaceholder, textDark, gray200, gray100 },
    } = useContext(ThemeContext);

    const { t } = useContext(LanguageContext);

    const { closeTerminal } = useContext(SettingsContext);

    const {
        setActiveTabId,
        activeTab,
        setIsOpenConfirmationModal,
        isOpenConfirmationModal,
        portListAnchorEl,
        setPortListAnchorEl,
        activeTerminalPorts,
        removeActiveTerminalPort,
        removePortModalInfo,
        setRemovePortModalInfo,
        removeAllActiveTerminalPorts,
        setPortMessages,
        portMessages,
        setPortKeepFolder,
        setPortIsLogging,
        terminalSize,
    } = useContext(TerminalContext);

    const { isDeviceDisconnected } = useContext(UserInfoContext);
    const { setAlert } = useContext(AlertContext);

    const { postData, showErrorMsg } = useApi();
    const { connection, startConnection, stopConnection } =
        useWebSockets("/terminalHub");
    const { saveFileDialog, openDirectory } = useDesktopHost();
    const location = useLocation();

    const ref = useRef<HTMLDivElement>(null);
    const messagesEndRef = useRef<HTMLDivElement>(null);
    const terminalMessageRef = useRef<HTMLDivElement>(null);
    const activeTerminalPortsRef = useRef(activeTerminalPorts);

    const handleDownload = async (deviceName: string, imei: string) => {
        if (!!activeTab?.logPath) {
            try {
                await postData("terminal/stopDebugLog", {
                    port: activeTab?.port?.address,
                });

                const getDirectory = (filePath: string) => {
                    const lastSlashIndex = filePath.lastIndexOf("\\");
                    return filePath.slice(0, lastSlashIndex);
                };
                const logPathWithoutFileName = getDirectory(activeTab?.logPath);

                setAlert(
                    AlertStatus.Success,
                    t.TerminalLogStopSuccess,
                    null,
                    null,
                    null,
                    t.OpenDirectory,
                    () => openDirectory(logPathWithoutFileName)
                );
                setPortKeepFolder(null, activeTab?.port?.address);
            } catch (err) {
                showErrorMsg(err as any);
            }
        } else {
            let now = new Date();
            let formattedDate =
                now.getFullYear() +
                "-" +
                ("0" + (now.getMonth() + 1)).slice(-2) +
                "-" +
                ("0" + now.getDate()).slice(-2) +
                "_" +
                ("0" + now.getHours()).slice(-2) +
                "." +
                ("0" + now.getMinutes()).slice(-2) +
                "." +
                ("0" + now.getSeconds()).slice(-2);

            saveFileDialog(
                {
                    title: t.SaveFile,
                    defaultPath: `${deviceName}_${imei}_${formattedDate}`,
                    filters: [
                        {
                            extensions: ["log"],
                            name: t.TerminalLogFile,
                        },
                    ],
                },
                (result: string[]) => {
                    if (result.length > 0) {
                        const exportToFile = async () => {
                            try {
                                await postData("terminal/keepDebugLog", {
                                    port: activeTab?.port?.address,
                                    logFilePath: result,
                                });
                                setPortKeepFolder(
                                    result,
                                    activeTab?.port?.address
                                );
                                setAlert(
                                    AlertStatus.Success,
                                    t.TerminalLogSuccess
                                );
                            } catch (error) {
                                showErrorMsg(error as any);
                            }
                        };

                        exportToFile();
                    }
                }
            );
        }
    };

    const isTerminalLoggingStarted = activeTab?.isLogging;
    const portHeaderActionButtons =
        activeTab?.port?.type === TerminalPortType.Device
            ? []
            : [
                  {
                      icon: isTerminalLoggingStarted ? (
                          <PauseIcon />
                      ) : (
                          <StartArrowIcon />
                      ),
                      tooltip: isTerminalLoggingStarted
                          ? t.CaptureTerminalLogPause
                          : t.CaptureTerminalLogStart,
                      onClick: () =>
                          setPortIsLogging(
                              !isTerminalLoggingStarted,
                              activeTab?.port?.address
                          ),
                  },
                  {
                      icon: !!activeTab?.logPath ? (
                          <StopTerminalLogIcon />
                      ) : (
                          <DownloadIcon />
                      ),
                      tooltip: !!activeTab?.logPath
                          ? t.TerminalLogStarted
                          : t.TerminalLogStart,
                      onClick: () =>
                          handleDownload(
                              activeTab?.deviceName,
                              activeTab?.imei
                          ),
                  },
              ];

    const actionButtons = [
        {
            icon: <MinimizeIcon />,
            tooltip: "",
            onClick: () => closeTerminal(),
        },
        {
            icon: <RemoveIcon />,
            onClick: !!activeTerminalPorts.length
                ? () => setIsOpenConfirmationModal(true)
                : () => closeTerminal(),
            tooltip: "",
        },
    ];

    const filteredPortMessages = portMessages.filter(
        (message: any) => message.port === activeTab?.port?.address
    );
    const activePortMessages = [...filteredPortMessages].slice(
        -terminalMessageLength
    );

    useEffect(() => {
        activeTerminalPortsRef.current = activeTerminalPorts;
    }, [activeTerminalPorts]);

    useEffect(() => {
        if (connection) {
            if (isDeviceDisconnected) {
                connection.off("NotifyConnectionLost");
            }
            connection.on(
                "SendPortAvailabilityEventAsync",
                (data: {
                    portName: string;
                    state: number;
                    portState: string;
                }) => {
                    const hasPortOpen = activeTerminalPortsRef.current.some(
                        (item: any) => item.id === data.portName
                    );
                    const portInfo = activeTerminalPortsRef.current.find(
                        (item: any) => item.id === data.portName
                    );

                    if (data.portState === PortStates.Removed && hasPortOpen) {
                        connection.invoke(
                            "DisconnectFromPortAsync",
                            data.portName
                        );
                        const description = replacePlaceholders(
                            t.ConnectionToDeviceLostAlertDescription,
                            portInfo?.deviceName,
                            portInfo?.imei,
                            portInfo?.id
                        );
                        setAlert(
                            AlertStatus.Critical,
                            t.ConnectionToDeviceLostTitle,
                            description
                        );
                    }
                    if (data.portState === PortStates.Arrived && hasPortOpen) {
                        connection.invoke("ConnectToPortAsync", data.portName);
                        const description = replacePlaceholders(
                            t.ConnectionToDeviceReconnectedAlertDescription,
                            portInfo?.deviceName,
                            portInfo?.imei,
                            portInfo?.id
                        );
                        setAlert(
                            AlertStatus.Success,
                            t.ConnectionToDeviceReconnectedTitle,
                            description
                        );
                    }
                }
            );
            connection.on(
                "SendPortMessagesEventAsync",
                (data: TerminalMessage[]) => {
                    setPortMessages(data);
                }
            );

            startConnection();
        }

        return () => {};

        // eslint-disable-next-line
    }, [connection, isDeviceDisconnected]);

    useEffect(() => {
        //scroll on initial tab render
        const messagesEnd = messagesEndRef.current;
        if (messagesEnd) {
            messagesEnd.scrollIntoView({ behavior: "smooth" });
        }
    }, []);

    useEffect(() => {
        const devicePort = activeTerminalPorts.find(
            (port: any) => port.port.type === TerminalPortType.Device
        );
        if (location.pathname === "/" && devicePort) {
            removeActiveTerminalPort(devicePort);
        }
    }, [location]);

    useEffect(() => {
        const terminalMessages = terminalMessageRef.current;
        const messagesEnd = messagesEndRef.current;
        if (terminalMessages && messagesEnd) {
            const isAtBottom =
                terminalMessages.scrollTop + terminalMessages.clientHeight >=
                terminalMessages.scrollHeight - 300;

            const isDevicePort =
                activeTab?.port?.type === TerminalPortType.Device;

            if (isAtBottom && !isDevicePort) {
                messagesEnd.scrollIntoView({ behavior: "smooth" });
            }
        }
    }, [activePortMessages]);

    const handlePortRemove = () => {
        try {
            if (
                connection &&
                removePortModalInfo.port.port.type === TerminalPortType.Debug
            ) {
                connection.invoke(
                    "DisconnectFromPortAsync",
                    removePortModalInfo.port.id
                );
            }
            removeActiveTerminalPort(removePortModalInfo.port);
        } catch (err) {
            throw new ReferenceError(
                `Cannot invoke 'DisconnectFromPortAsync'. Web sockets connection is ${connection}`
            );
        } finally {
            setRemovePortModalInfo({ isOpen: false, port: null });
        }
    };

    const handleConfirmModalClick = () => {
        removeAllActiveTerminalPorts();
        setIsOpenConfirmationModal(false);
        closeTerminal();
        stopConnection();
    };

    return (
        <Fragment>
            <RndWindow>
                <div
                    ref={ref}
                    css={css`
                        display: flex;
                        flex-direction: column;
                        height: 100%; // Ensure it takes full height of RndWindow
                        padding: 24px;
                        background-color: ${white};
                        overflow: hidden; // Add this to prevent content from overflowing
                    `}
                >
                    <div
                        css={css({
                            display: "flex",
                            justifyContent: "space-between",
                            marginBottom: "16px",
                            marginTop: "4px",
                            alignItems: "flex-end",
                        })}
                    >
                        <div css={css({ display: "flex" })}>
                            {!!activeTerminalPorts.length &&
                                activeTerminalPorts.map((tab: any) => (
                                    <div
                                        key={tab.port.address}
                                        css={css({
                                            display: "flex",
                                            gap: "4px",
                                            padding: "10px 15px",
                                            cursor: "pointer",
                                            boxShadow:
                                                tab !== activeTab
                                                    ? "0px -2px 0px 0px #F1F5F9 inset"
                                                    : "0px -2px 0px 0px #0054A6 inset",
                                            ":hover": {
                                                backgroundColor: white,
                                            },
                                            position: "relative",
                                            fontSize: "14px",
                                            fontStyle: "normal",
                                            fontWeight: 600,
                                            lineHeight: "20px",
                                            letterSpacing: "0.1px",
                                        })}
                                        onClick={() =>
                                            setActiveTabId(tab.port.address)
                                        }
                                    >
                                        <b>{tab.port.address}</b>
                                        <span
                                            css={css({
                                                color: textPlaceholder,
                                            })}
                                        >
                                            {`(${
                                                tab.port.type ===
                                                TerminalPortType.Debug
                                                    ? "Debug"
                                                    : "Device"
                                            } port - IMEI: ${tab.imei})`}
                                        </span>
                                        <span
                                            css={css({
                                                cursor: "pointer",
                                                "&:hover": {
                                                    background: gray200,
                                                },
                                                padding: "2px",
                                                height: "16px",
                                            })}
                                            onClick={() =>
                                                setRemovePortModalInfo({
                                                    isOpen: true,
                                                    port: tab,
                                                })
                                            }
                                        >
                                            <RemoveIcon
                                                width={16}
                                                height={16}
                                            />
                                        </span>
                                    </div>
                                ))}
                            <Tooltip
                                title={t.TerminalNewPort}
                                small
                                placement="top"
                            >
                                <span>
                                    <div
                                        css={css({
                                            cursor: "pointer",
                                            "&:hover": {
                                                background: gray200,
                                            },
                                            padding: "6px",
                                            borderRadius: "6px",
                                            marginLeft: "8px",
                                            background: !!portListAnchorEl
                                                ? gray200
                                                : "unset",
                                        })}
                                        onClick={(e) =>
                                            setPortListAnchorEl(
                                                e?.currentTarget
                                            )
                                        }
                                    >
                                        <AddIcon />
                                    </div>
                                </span>
                            </Tooltip>
                        </div>
                        <div
                            css={css({
                                display: "flex",
                                justifyContent: "space-between",
                            })}
                        >
                            {actionButtons.map((actionButton, index) => {
                                return (
                                    <Tooltip
                                        title={actionButton.tooltip}
                                        small
                                        placement="top"
                                        key={index}
                                    >
                                        <span>
                                            <div
                                                css={css({
                                                    cursor: "pointer",
                                                    "&:hover": {
                                                        background: gray200,
                                                    },
                                                    padding: "6px",
                                                    borderRadius: "6px",
                                                })}
                                                onClick={
                                                    actionButton.onClick
                                                        ? actionButton.onClick
                                                        : () => {}
                                                }
                                            >
                                                {actionButton.icon}
                                            </div>
                                        </span>
                                    </Tooltip>
                                );
                            })}
                        </div>
                    </div>
                    <div
                        css={css`
                            flex: 1;
                            display: flex;
                            flex-direction: column;
                            padding: 12px;
                            border-radius: 6px;
                            border: 1px solid #cbd5e1;
                            background: ${gray100};
                            overflow: hidden;
                            min-height: 0; // Add this to allow flex child to shrink
                        `}
                    >
                        {activeTab ? (
                            <Fragment>
                                <div
                                    css={css({
                                        paddingBottom: "4px",
                                        borderBottom: "1px solid #E2E8F0",
                                        display: "flex",
                                        justifyContent: "space-between",
                                    })}
                                >
                                    <div
                                        css={css({
                                            display: "flex",
                                            gap: "16px",
                                            visibility: "hidden", // For the moment this is hidden. Related to https://jira.teltonika.lt/browse/CONF-3670
                                        })}
                                    >
                                        <InputField
                                            size="small"
                                            iconLeft={<SearchIcon />}
                                            placeholder={`${t.Search}...`}
                                            css={css`
                                                width: 244px;

                                                svg {
                                                    color: ${textPlaceholder};
                                                }

                                                @media (max-width: 1439px) {
                                                    width: 180px;
                                                }

                                                @media (max-width: 839px) {
                                                    width: 100%;
                                                }
                                            `}
                                        />
                                        <div
                                            css={css({
                                                display: "flex",
                                                justifyContent: "end",
                                                alignItems: "center",
                                                padding: "6px 12px",
                                                cursor: "pointer",
                                                position: "relative",
                                                borderRadius: "6px",
                                                "&:hover": {
                                                    background: gray200,
                                                },
                                            })}
                                        >
                                            <FiltersIcon />
                                            <div
                                                css={css({
                                                    marginLeft: "8px",
                                                    fontWeight: 600,
                                                    fontSize: "14px",
                                                    lineHeight: "20px",
                                                    letterSpacing: "0.1px",
                                                })}
                                            >
                                                Filter
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        css={css({
                                            display: "flex",
                                            gap: "8px",
                                            alignItems: "center",
                                        })}
                                    >
                                        {portHeaderActionButtons.map(
                                            (portHeaderButton, index) => {
                                                return (
                                                    <Tooltip
                                                        title={
                                                            portHeaderButton.tooltip
                                                        }
                                                        small
                                                        placement="top"
                                                        key={index}
                                                    >
                                                        <span>
                                                            <div
                                                                css={css({
                                                                    cursor: "pointer",
                                                                    pointerEvents:
                                                                        "unset",
                                                                    backgroundColor:
                                                                        "inherit",
                                                                    "&:hover": {
                                                                        background:
                                                                            gray200,
                                                                    },
                                                                    padding:
                                                                        "6px",
                                                                    borderRadius:
                                                                        "6px",
                                                                })}
                                                                onClick={
                                                                    portHeaderButton?.onClick ||
                                                                    (() => {})
                                                                }
                                                            >
                                                                {
                                                                    portHeaderButton.icon
                                                                }
                                                            </div>
                                                        </span>
                                                    </Tooltip>
                                                );
                                            }
                                        )}
                                    </div>
                                </div>
                                <div
                                    css={css({
                                        overflow: "auto",
                                        flex: 1,
                                        marginTop: "12px",
                                        "&::-webkit-scrollbar-thumb": {
                                            height: "50px",
                                        },
                                        minHeight: 0, // Add this to allow flex child to shrink
                                    })}
                                    ref={terminalMessageRef}
                                >
                                    {activePortMessages.map(
                                        (item: any, index) => {
                                            return (
                                                <div
                                                    css={css({
                                                        display: "flex",
                                                        gap: "5px",
                                                        marginBottom: "5px",
                                                        fontFamily:
                                                            item.type !==
                                                            PortMessageType.Command
                                                                ? "Consolas, monospace"
                                                                : "unset",
                                                        whiteSpace:
                                                            "break-spaces",
                                                    })}
                                                    key={index}
                                                >
                                                    {activeTab?.port?.type !==
                                                        TerminalPortType.Device && (
                                                        <div
                                                            css={css({
                                                                fontWeight: 600,
                                                                fontSize:
                                                                    "14px",
                                                                lineHeight:
                                                                    "20px",
                                                                letterSpacing:
                                                                    "0.1px",
                                                                color: textDark,
                                                                minWidth:
                                                                    "max-content",
                                                            })}
                                                        >
                                                            {item.tctTime}:
                                                        </div>
                                                    )}
                                                    <div
                                                        css={css({
                                                            fontWeight: 600,
                                                            fontSize: "14px",
                                                            lineHeight: "20px",
                                                            letterSpacing:
                                                                "0.1px",
                                                            color:
                                                                item.type ===
                                                                PortMessageType.Command
                                                                    ? textPlaceholder
                                                                    : textDark,
                                                            fontStyle:
                                                                item.type ===
                                                                PortMessageType.Command
                                                                    ? "italic"
                                                                    : "normal",
                                                        })}
                                                    >
                                                        {item.message}
                                                    </div>
                                                </div>
                                            );
                                        }
                                    )}
                                    <div
                                        css={css({ height: "20px" })}
                                        ref={messagesEndRef}
                                    />
                                </div>
                            </Fragment>
                        ) : (
                            <EmptyTerminalBlock />
                        )}
                    </div>
                    {activeTab?.port?.type === TerminalPortType.Device && (
                        <div css={css({ marginTop: "10px" })}>
                            <CommandInput messagesEndRef={messagesEndRef} />
                        </div>
                    )}
                </div>
            </RndWindow>
            <ConfirmModal
                open={isOpenConfirmationModal}
                close={() => setIsOpenConfirmationModal(false)}
                submit={handleConfirmModalClick}
                title={t.TerminalClose}
                description={t.TerminalCloseDescription}
            />
            <ConfirmModal
                open={removePortModalInfo.isOpen}
                close={() =>
                    setRemovePortModalInfo({ isOpen: false, port: null })
                }
                submit={handlePortRemove}
                title={t.TerminalPortClose}
            />
            <PortList connection={connection} />
        </Fragment>
    );
};

export default Terminal;
