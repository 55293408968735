/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import { useContext } from "react";
import { CheckCircle, RadioButtonUnchecked } from "@mui/icons-material";
import ThemeContext from "../../../../../context/theme/themeContext";
import { Checkbox } from "@mui/material";
import Tooltip from "../../../../MuiComponents/Tooltip";
import LanguageContext from "../../../../../context/language/languageContext";

export interface SinglePortPopupItemProps {
    portName: string;
    onClick?: (e: any) => void;
    isDisabled?: boolean;
    isAlreadyAdded?: boolean;
}

const SinglePortPopupItem: React.FunctionComponent<
    SinglePortPopupItemProps
> = ({ portName, onClick, isDisabled, isAlreadyAdded }) => {
    const { t } = useContext(LanguageContext);

    const {
        colors: { textDark, gray100, textDarkDisabled, blue200 },
    } = useContext(ThemeContext);

    const renderDisabledTooltipText = () => {
        if (isDisabled) {
            return t.DevicePortIsDisabled;
        }
        if (isAlreadyAdded) {
            return t.DevicePortIsAlreadyAdded;
        }
        return "";
    };

    return (
        <Tooltip title={renderDisabledTooltipText()} placement="top">
            <div
                css={{
                    padding: " 12px 16px",
                    display: "flex",
                    justifyContent: "space-between",
                    "&:hover": {
                        borderRadius: "3px",
                        background: isAlreadyAdded ? blue200 : gray100,
                    },
                    cursor: "pointer",
                    backgroundColor: isAlreadyAdded ? blue200 : "transparent",
                }}
                onClick={isDisabled || isAlreadyAdded ? () => {} : onClick}
            >
                <div
                    css={{
                        fontSize: "14px",
                        fontStyle: "normal",
                        fontWeight: 600,
                        lineHeight: "20px" /* 142.857% */,
                        letterSpacing: "0.1px",
                        color: isDisabled ? textDarkDisabled : textDark,
                    }}
                >
                    {portName}
                </div>
                <Checkbox
                    icon={<RadioButtonUnchecked />}
                    checkedIcon={<CheckCircle />}
                    checked={isAlreadyAdded}
                />
            </div>
        </Tooltip>
    );
};

export default SinglePortPopupItem;
