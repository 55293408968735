/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/react";

import Card from "../../../../MuiComponents/Card";
import { Fragment, useContext } from "react";
import LanguageContext from "../../../../../context/language/languageContext";
import SettingsContext from "../../../../../context/settings/settingsContext";
import DoutSwitch from "./DoutSwitch";
import { DoutInfo } from "../../../../../generatedTypes";

interface DoutInformationProps {
    data: DoutInfo[];
}

const DoutInformation = ({ data }: DoutInformationProps) => {
    const { t } = useContext(LanguageContext);

    const { isExplanatoryTextsHidden } = useContext(SettingsContext);

    return (
        <Card
            title={t.DoutInfo}
            description={
                isExplanatoryTextsHidden ? "" : t["DoutInfo.description"]
            }
        >
            <Fragment>
                {data.map((dout) => {
                    return <DoutSwitch key={dout.id} dout={dout} />;
                })}
            </Fragment>
        </Card>
    );
};

export default DoutInformation;
