import { createContext } from "react";

interface ConfigurationContextState {
    selectedConfiguration: any[];
    offlineConfigurations: any[];
    filteredValues: any[];
    searchValue: string;
    technologies: any[];
    loading: boolean;
    experimentalModalValue: {
        value: string | null;
        default: boolean;
        active: boolean;
        path: string;
    };
    experimentalConfigurations: any[] | undefined;
    setOfflineConfigurations: (configurations: {}) => void;
    toggleLegacyConfigurations: (showLegacy: boolean) => void;
    filterConfigurations: (filteredValues: any[]) => void;
    searchConfigurations: (searchValue: string) => void;
    setExperimentalConfigurations: (configurations: any[]) => void;
    isExperimentalModalOpen: boolean;
    hasExperimentalModalClose: boolean;
    setIsExperimentalModalOpen: (isOpen: boolean, hasClose?: boolean) => void;
    getExperimentalConfigurations: (onSuccess?: (data: any[]) => void) => void;
    setExperimentalModalValue: (value: any) => void;
}

const ConfigurationContext = createContext({} as ConfigurationContextState);

export default ConfigurationContext;
