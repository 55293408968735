import { createContext } from "react";
import { ErrorDetails } from "../../utils/types";

interface ErrorContextState {
    error: ErrorDetails | null;
    removeError: () => void;
    setError: (error: ErrorDetails) => void;
}

const ErrorContext = createContext({} as ErrorContextState);

export default ErrorContext;