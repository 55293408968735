/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, SerializedStyles } from "@emotion/react";

import React, { Fragment, useContext } from "react";
import PhoneInput from "react-phone-input-2";
import Label from "./Label";

import ThemeContext from "../../context/theme/themeContext";

import "react-phone-input-2/lib/style.css";
export interface PhoneNumberInputProps {
    locale: string;
    value: string;
    onChange: (value: string, country: any) => void;
    placeholder: string;
    disabled?: boolean;
    ref?: React.Ref<HTMLInputElement>;
    wrapperStyle?: SerializedStyles;
    label?: string;
    rightLabel?: string | null;
    requesting?: boolean;
    requestFailed?: boolean;
    required?: boolean;
    info?: string;
    regexp?: RegExp;
    iconRight: any;
    hasTopPosition?: boolean;
}

const PhoneNumberInput: React.FunctionComponent<PhoneNumberInputProps> = ({
    locale,
    value,
    onChange,
    placeholder,
    disabled,
    label,
    requesting,
    requestFailed,
    required,
    info,
    regexp,
    iconRight,
    hasTopPosition,
}) => {
    const {
        colors: { textDark, gray300, gray50 },
    } = useContext(ThemeContext);
    return (
        <Fragment>
            {label && (
                <Label
                    title={label}
                    requesting={requesting}
                    requestFailed={requestFailed}
                    required={required}
                    info={info}
                />
            )}
            <PhoneInput
                country={locale}
                value={value}
                onChange={onChange}
                placeholder={placeholder}
                disabled={disabled}
                inputStyle={{
                    width: "100%",
                    height: "44px",
                    borderRadius: "6px",
                    color: textDark,
                    border: `1px solid ${gray300}`,
                }}
                dropdownStyle={{ bottom: hasTopPosition ? "44px" : "unset" }}
                buttonStyle={{
                    backgroundColor: gray50,
                }}
                isValid={(inputValue) => {
                    if (regexp && inputValue.match(regexp)) {
                        return false;
                    } else {
                        return true;
                    }
                }}
            />
            <div css={{ position: "absolute", right: 12, bottom: 12 }}>
                {iconRight}
            </div>
        </Fragment>
    );
};

export default PhoneNumberInput;
