/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx, SerializedStyles } from "@emotion/react";

import { forwardRef, MouseEventHandler, useContext } from "react";
import ThemeContext from "../../context/theme/themeContext";
import AlertInfoIcon from "../../assets/icons/AlertInfoIcon";
import AlertCriticalIcon from "../../assets/icons/AlertCriticalIcon";
import AlertWarningIcon from "../../assets/icons/AlertWarningIcon";
import AlertSuccessIcon from "../../assets/icons/AlertSuccessIcon";
import Button from "../MuiComponents/Button";
import CloseIcon from "@mui/icons-material/CloseRounded";
import { AlertStatus } from "../../constants/constants";

type Ref = HTMLDivElement;

interface AlertProps {
    status: AlertStatus;
    title: string;
    description?: string;
    descriptionAsHtml?: boolean;
    buttonPrimary?: string;
    buttonSecondary?: string;
    close?: MouseEventHandler<SVGSVGElement>;
    handlePrimaryBtnClick?: () => void;
    handleSecondaryBtnClick?: () => void;
    additionalStyles?: SerializedStyles;
}

const Alert = forwardRef<Ref, AlertProps>(
    (
        {
            status,
            title,
            description,
            descriptionAsHtml,
            buttonPrimary,
            buttonSecondary,
            close,
            handlePrimaryBtnClick,
            handleSecondaryBtnClick,
            additionalStyles,
        },
        ref
    ) => {
        const {
            colors: {
                white,
                blue700,
                gray700,
                red700,
                orange600,
                textDark,
                green600,
            },
            boxShadows: { alertBoxShadow },
        } = useContext(ThemeContext);

        const styleVariants = {
            info: {
                border: blue700,
                icon: <AlertInfoIcon />,
            },
            critical: {
                border: red700,
                icon: <AlertCriticalIcon />,
            },
            warning: {
                border: orange600,
                icon: <AlertWarningIcon />,
            },
            success: {
                border: green600,
                icon: <AlertSuccessIcon />,
            },
        };

        const borderColor = styleVariants[status].border;
        const iconImage = styleVariants[status].icon;

        return (
            <div
                ref={ref}
                css={css({
                    display: "flex",
                    width: "100%",
                    boxSizing: "border-box",
                    padding: "12px 12px 12px 8px",
                    backgroundColor: white,
                    borderLeft: `4px solid ${borderColor}`,
                    boxShadow: alertBoxShadow,
                    borderRadius: "4px",
                    wordBreak: "break-word",

                    "& > svg:first-of-type": {
                        marginRight: "8px",
                    },

                    ...additionalStyles,
                })}
            >
                {iconImage}

                <div
                    css={css({
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        flex: "1",
                        letterSpacing: "0.1px",
                        lineHeight: "20px",
                    })}
                >
                    <div
                        css={css({
                            color: textDark,
                            fontWeight: "600",
                        })}
                    >
                        {title}
                    </div>

                    {description &&
                        (descriptionAsHtml ? (
                            <div
                                css={css({
                                    color: gray700,
                                    marginTop: "4px",
                                })}
                                dangerouslySetInnerHTML={{
                                    __html: description,
                                }}
                            />
                        ) : (
                            <div
                                css={css({
                                    color: gray700,
                                    marginTop: "4px",
                                })}
                            >
                                {description}
                            </div>
                        ))}

                    {(buttonPrimary || buttonSecondary) && (
                        <div
                            css={css({
                                display: "flex",
                                marginTop: "12px",

                                "button + button": {
                                    marginLeft:
                                        buttonPrimary && buttonSecondary
                                            ? "8px"
                                            : "0",
                                },
                            })}
                        >
                            {buttonSecondary && (
                                <Button
                                    fullWidth
                                    variant="textOnly"
                                    size="small"
                                    color="secondary"
                                    onClick={handleSecondaryBtnClick}
                                    idForTesting={`alert-secondary-button`}
                                >
                                    {buttonSecondary}
                                </Button>
                            )}

                            {buttonPrimary && (
                                <Button
                                    fullWidth
                                    variant="textOnly"
                                    size="small"
                                    color="primary"
                                    onClick={handlePrimaryBtnClick}
                                    idForTesting={`alert-primary-button`}
                                >
                                    {buttonPrimary}
                                </Button>
                            )}
                        </div>
                    )}
                </div>

                {close && (
                    <CloseIcon
                        onClick={close}
                        css={css({
                            fontSize: "16px",
                            cursor: "pointer",
                            marginTop: "-4px",
                            marginRight: "-4px",
                        })}
                    />
                )}
            </div>
        );
    }
);

export default Alert;
