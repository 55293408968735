import L, { LeafletMouseEvent, PathOptions } from "leaflet";
import { useContext } from "react";
import {
    EditorState,
    GeozoneEditState,
} from "../context/manualGeofence/manualGeofenceContext";
import ThemeContext from "../context/theme/themeContext";
import { Geozone, GeozoneType } from "./types";

const useGeozones = (
    highlightGeozone: (index: number) => void,
    onEditStart: (index: number) => void,
    editStateRef: React.MutableRefObject<GeozoneEditState>
) => {
    const {
        colors: { blue700, textDark, textDarkSecondary },
    } = useContext(ThemeContext);
    const commonFeatureOptions: PathOptions = {
        color: blue700,
        opacity: 0.4,
        weight: 1,
        fillColor: blue700,
        fillOpacity: 0.4,
        dashArray: [0],
    };
    const highlightedFeatureOptions: PathOptions = {
        fillColor: blue700,
        fillOpacity: 0.6,
        opacity: 1,
        weight: 1,
        dashArray: [0],
    };
    const selectedFeatureOptions: PathOptions = {
        fillColor: blue700,
        fillOpacity: 0.2,
        color: blue700,
        opacity: 1,
        weight: 1,
        dashArray: [5, 7],
    };
    L.Edit.PolyVerticesEdit.include({
        _createMiddleMarker(_marker1: any, _marker2: any) {
            return;
        },
    });

    const onMouseOver = (e: LeafletMouseEvent) =>
        editStateRef.current.currentEditorState !== EditorState.Start &&
        highlightGeozone(e.target.options.editLayerId);
    const onMouseOut = () =>
        editStateRef.current.currentEditorState !== EditorState.Start &&
        highlightGeozone(0);
    const onClick = (e: LeafletMouseEvent) =>
        onEditStart(e.target.options.editLayerId);
    const convertGeozoneToFeature = (geozone: Geozone): L.Layer => {
        let shape: L.Path;
        switch (geozone.type) {
            case GeozoneType.None: {
                return new L.Layer({
                    editLayerId: geozone.index,
                } as ExtendedLayerOptions);
            }
            case GeozoneType.Circle: {
                shape = new L.Circle(geozone.coordinates[0], {
                    radius: geozone.radius,
                    editLayerId: geozone.index,
                    ...commonFeatureOptions,
                } as ExtendedCircleOptions);
                break;
            }
            case GeozoneType.Rectangle: {
                shape = new L.Rectangle(geozone.coordinates, {
                    editLayerId: geozone.index,
                    ...commonFeatureOptions,
                } as ExtendedPolylineOptions);
                break;
            }
            case GeozoneType.Polygon: {
                shape = new L.Polygon(geozone.coordinates, {
                    editLayerId: geozone.index,
                    ...commonFeatureOptions,
                } as ExtendedPolylineOptions);
                break;
            }
        }
        shape.on("mouseover", onMouseOver);
        shape.on("mouseout", onMouseOut);
        shape.on("click", onClick);

        createGeozoneTooltip(shape, geozone.name);

        return shape;
    };
    const pathToGeozoneCircle = (
        path: L.Path,
        index: number,
        name: string
    ): Geozone => {
        const circle = path as L.Circle;
        const center = circle.getBounds().getCenter();
        return {
            index,
            name,
            type: GeozoneType.Circle,
            radius: circle.getRadius(),
            coordinates: [[center.lat, center.lng]],
        };
    };
    const pathToGeozoneRectangle = (
        path: L.Path,
        index: number,
        name: string
    ): Geozone => {
        const rectangle = path as L.Rectangle;
        const bounds = rectangle.getBounds();
        const northEast = bounds.getNorthEast();
        const southWest = bounds.getSouthWest();

        return {
            index,
            name,
            type: GeozoneType.Rectangle,
            coordinates: [
                [northEast.lat, northEast.lng],
                [southWest.lat, southWest.lng],
            ],
        };
    };
    const pathToGeozonePolygon = (
        path: L.Path,
        index: number,
        name: string
    ): Geozone => {
        const polygon = path as L.Polygon;
        const bounds = polygon.getLatLngs();
        return {
            index,
            name,
            type: GeozoneType.Polygon,
            coordinates: (bounds[0] as L.LatLng[]).map((latLng: L.LatLng) => [
                latLng.lat,
                latLng.lng,
            ]),
        };
    };
    const convertFeatureToGeozoneByGeozoneType = (
        feature: L.Path,
        type: GeozoneType,
        index: number,
        name: string
    ): Geozone => {
        switch (type) {
            case GeozoneType.Circle: {
                return pathToGeozoneCircle(feature, index, name);
            }
            case GeozoneType.Rectangle: {
                return pathToGeozoneRectangle(feature, index, name);
            }
            case GeozoneType.Polygon: {
                return pathToGeozonePolygon(feature, index, name);
            }
            default: {
                return {
                    index,
                    name,
                    type: GeozoneType.None,
                    coordinates: [],
                };
            }
        }
    };

    const convertFeatureToGeozoneByFeatureType = (
        feature: L.Path,
        type: string,
        index: number,
        name: string
    ) => {
        let geozoneType = GeozoneType.None;
        switch (type) {
            case "circle": {
                geozoneType = GeozoneType.Circle;
                break;
            }
            case "rectangle": {
                geozoneType = GeozoneType.Rectangle;
                break;
            }
            case "polygon": {
                geozoneType = GeozoneType.Polygon;
                break;
            }
        }

        return convertFeatureToGeozoneByGeozoneType(
            feature,
            geozoneType,
            index,
            name
        );
    };
    const getTooltipContent = (
        layer: L.Layer,
        name: string,
        isHighlighted?: boolean
    ): string => {
        const width =
            ((layer as ExtendedLayer)._pxBounds?.max.x ?? 0) -
            ((layer as ExtendedLayer)._pxBounds?.min.x ?? 0);
        const fontSize = width / name.length;
        return `<div style="color:${
            isHighlighted ? textDark : textDarkSecondary
        }; font-size:${fontSize}px">${name}</div>`;
    };
    const createGeozoneTooltip = (feature: L.Path, name: string) =>
        feature.bindTooltip(getTooltipContent(feature, name), {
            permanent: true,
            offset: [0, 0],
            direction: "center",
        });
    const updateGeozoneTooltip = (
        layer: L.Layer,
        name: string,
        isHighlighted?: boolean
    ) =>
        layer
            .getTooltip()
            ?.setContent(getTooltipContent(layer, name, isHighlighted));

    return {
        commonFeatureOptions,
        highlightedFeatureOptions,
        selectedFeatureOptions,
        convertGeozoneToFeature,
        convertFeatureToGeozoneByGeozoneType,
        convertFeatureToGeozoneByFeatureType,
        updateGeozoneTooltip,
    };
};

export default useGeozones;

interface ExtendedLayerOptions extends L.LayerOptions {
    editLayerId: number;
}

interface ExtendedPolylineOptions extends L.PolylineOptions {
    editLayerId: number;
}

interface ExtendedCircleOptions extends L.CircleOptions {
    editLayerId: number;
}

interface ExtendedLayer extends L.Layer {
    _pxBounds?: {
        min: { x: number; y: number };
        max: { x: number; y: number };
    };
}
