/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/react";
import SaveToFile from "./SaveToFile";
import { Fragment, SyntheticEvent, useContext, useState } from "react";
import useApi from "../../../../../utils/useApi";
import LayoutContext from "../../../../../context/layout/layoutContext";
import AlertContext from "../../../../../context/alert/alertContext";
import LanguageContext from "../../../../../context/language/languageContext";
import SaveToFotaModal from "../SaveToFotaModal";
import { SaveToFileProps } from "./SaveToLocalFile";
import useTct from "../../../../../utils/useTct";
import { AlertStatus } from "../../../../../constants/constants";

const SaveToFotaFile = ({
    isDisabled,
    isModalOpen,
    setModalOpen,
    modifiedParameterResponse,
    isMobileSaveModalOpen,
    setMobileSaveModal,
    isSaving,
    setIsSaving,
}: SaveToFileProps) => {
    const { setAlert } = useContext(AlertContext);
    const { t } = useContext(LanguageContext);
    const { layoutData } = useContext(LayoutContext);

    const [openedSaveToFotaModal, setOpenedSaveToFotaModal] = useState(false);
    const [isModified, setWithModifiedChanges] = useState(false);

    const { showErrorMsg } = useApi();
    const { saveToFotaAsync } = useTct();

    const openSaveToFotaModal = (e: SyntheticEvent, modified: boolean) => {
        setOpenedSaveToFotaModal(true);
        setWithModifiedChanges(modified);
    };
    const closeSaveToFotaModal = () => setOpenedSaveToFotaModal(false);

    const handleSaveChangesToFota = (e: SyntheticEvent, fileName: string) => {
        e.preventDefault();
        isMobileSaveModalOpen && setMobileSaveModal(false);
        openedSaveToFotaModal && closeSaveToFotaModal();
        isModalOpen && setModalOpen(false);
        const saveToFota = async () => {
            try {
                if (!layoutData) {
                    throw new Error("No layout");
                }

                setIsSaving(true);

                await saveToFotaAsync(
                    layoutData.id,
                    fileName,
                    isModified,
                    false
                );

                setAlert(AlertStatus.Success, t.ConfigurationSuccessfullySaved);
            } catch (error) {
                showErrorMsg(error as any);
            } finally {
                setIsSaving(false);
            }
        };

        saveToFota();
    };

    return (
        <Fragment>
            <SaveToFile
                isDisabled={isDisabled}
                isModalOpen={isModalOpen}
                setModalOpen={setModalOpen}
                modifiedParameterResponse={modifiedParameterResponse}
                onSave={openSaveToFotaModal}
                isSaving={isSaving}
                setIsSaving={setIsSaving}
                btnLabel={t.SaveToFota}
                isPrimaryColor={!layoutData?.isOnline}
            />
            <SaveToFotaModal
                isModalOpen={openedSaveToFotaModal}
                onSubmit={handleSaveChangesToFota}
                onClose={closeSaveToFotaModal}
            />
        </Fragment>
    );
};

export default SaveToFotaFile;
