/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/react";
import { Fragment, useContext } from "react";
import MobileSaveModal from "../MobileSaveModal";
import SaveButton, { SaveButtonProps } from "./SaveButton";
import LanguageContext from "../../../../../context/language/languageContext";

export interface MobileSaveButtonProps extends SaveButtonProps {
    isModalOpen: boolean;
    openSaveToDeviceModal: () => void;
    openSaveToFileModal: () => void;
}

const MobileSaveButton = ({
    isDisabled,
    isSaving,
    isModalOpen,
    setModalOpen,
    openSaveToDeviceModal,
    openSaveToFileModal,
}: MobileSaveButtonProps) => {
    const { t } = useContext(LanguageContext);

    const closeSaveModal = () => setModalOpen(false);

    return (
        <Fragment>
            <SaveButton
                isDisabled={isDisabled}
                setModalOpen={setModalOpen}
                isSaving={isSaving}
                btnLabel={t.Save}
                isPrimaryColor={true}
                idForTesting="mobile-save-button"
            />
            <MobileSaveModal
                isOpen={isModalOpen}
                onClose={closeSaveModal}
                onPrimaryBtnClick={openSaveToDeviceModal}
                onSecondaryBtnClick={openSaveToFileModal}
            />
        </Fragment>
    );
};

export default MobileSaveButton;
