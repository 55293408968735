import { createContext } from "react";

interface UserInfoContextState {
    loading: boolean;
    configuratorVersion: string;
    isDesktopApplication: boolean;
    isStartUpWebsocketsActive: boolean;
    enableSentryCrashReport: boolean;
    enableTerminal: boolean;
    isDeviceDisconnected: boolean;
    isFirstTimeOnStartup: boolean;
    speces: any[];
    getInitialUserInfo: () => void;
    setStartupWebsocketsActive: (active: boolean) => void;
    setDeviceDisconnected: (disconnected: boolean) => void;
    setVisitCount: () => void;
    setSpeces: (speces: any[]) => void;
    setDefaultSpecs: (defaultSpecs: any[]) => void;
    defaultSpecs: any[];
}

const UserInfoContext = createContext({} as UserInfoContextState);

export default UserInfoContext;
