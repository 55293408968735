/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import React, { Fragment, useContext, useEffect } from "react";
import FormModal from "../../MuiComponents/Modals/FormModal";
import Select from "../../MuiComponents/Select";
import { DialogActions, MenuItem } from "@mui/material";
import LanguageContext from "../../../context/language/languageContext";
import ConfigurationContext from "../../../context/configuration/configurationContext";
import Button from "../../MuiComponents/Button";
import ThemeContext from "../../../context/theme/themeContext";
import useTct from "../../../utils/useTct";
import Switch from "../../MuiComponents/Switch";
import Badge from "../../MuiComponents/Badge";

export interface ExperimentalFirmwareModalProps {}

const ExperimentalFirmwareModal: React.FunctionComponent<
    ExperimentalFirmwareModalProps
> = () => {
    const { t } = useContext(LanguageContext);
    const {
        colors: { textDark, textDarkSecondary },
    } = useContext(ThemeContext);

    const {
        experimentalConfigurations,
        isExperimentalModalOpen,
        setIsExperimentalModalOpen,
        hasExperimentalModalClose,
        getExperimentalConfigurations,
        experimentalModalValue,
        setExperimentalModalValue,
    } = useContext(ConfigurationContext);

    const { postExternalConfigurationAsync } = useTct();

    const handleSubmit = async () => {
        try {
            await postExternalConfigurationAsync(
                experimentalModalValue?.path,
                experimentalModalValue?.default
            );
            setIsExperimentalModalOpen(false);
        } catch (e) {
            console.log(e);
        }
    };

    const handleSwitch = () => {
        setExperimentalModalValue({
            ...experimentalModalValue,
            default: !experimentalModalValue.default,
        });
    };

    useEffect(() => {
        const handleSuccess = (data: any[]) => {
            const activeItem =
                data.find((item) => item.active === true) || undefined;
            setExperimentalModalValue(activeItem);
        };

        getExperimentalConfigurations(handleSuccess);
    }, []);

    return (
        <Fragment>
            <FormModal
                isOpen={isExperimentalModalOpen}
                title={t.SelectFirmware}
                onSubmit={handleSubmit}
                onClose={
                    hasExperimentalModalClose
                        ? () => setIsExperimentalModalOpen(false)
                        : undefined
                }
            >
                <Select
                    value={experimentalModalValue?.path || ""}
                    wrapperStyle={css({ marginBottom: "16px" })}
                    placeholder={t.ChooseFirmware}
                    label={t.Firmware}
                    sx={{
                        minHeight: "44px",
                    }}
                    renderValue={(selected) => {
                        const selectedConfig = experimentalConfigurations?.find(
                            (item: any) =>
                                item.path === selected || selected === "Default"
                        );
                        return (
                            <span
                                css={css({
                                    fontFamily: "Open Sans",
                                    fontSize: 14,
                                    fontStyle: "normal",
                                    fontWeight: 600,
                                    lineHeight: "20px",
                                    letterSpacing: "0.1px",
                                    color: textDark,
                                })}
                            >
                                {selectedConfig?.name}
                            </span>
                        );
                    }}
                >
                    {experimentalConfigurations?.map((item: any) => {
                        return (
                            <MenuItem
                                key={item.name}
                                value={item.path}
                                onClick={() => setExperimentalModalValue(item)}
                                disabled={item.isDisabled}
                                css={css({
                                    display: "flex",
                                    flexDirection: "column",
                                    gap: "4px",
                                    alignItems: "flex-start",
                                })}
                            >
                                <span
                                    css={css({
                                        fontFamily: "Open Sans",
                                        fontSize: 14,
                                        fontStyle: "normal",
                                        fontWeight: 600,
                                        lineHeight: "20px",
                                        letterSpacing: "0.1px",
                                        color: textDark,
                                    })}
                                >
                                    <span css={css({ marginRight: "6px" })}>
                                        {item.name}
                                    </span>
                                    {item?.default && (
                                        <Badge color={"light"}>Default</Badge>
                                    )}
                                </span>
                                <span
                                    css={css({
                                        fontFamily: "Open Sans",
                                        fontSize: 12,
                                        fontStyle: "normal",
                                        fontWeight: 400,
                                        lineHeight: "16px",
                                        letterSpacing: "0.4px",
                                        color: textDarkSecondary,
                                    })}
                                >
                                    {item.description}
                                </span>
                            </MenuItem>
                        );
                    })}
                </Select>
                <div
                    css={css({
                        marginBottom: "16px",
                    })}
                >
                    <Switch
                        checked={experimentalModalValue?.default}
                        onChange={handleSwitch}
                        size="small"
                        disabled={!experimentalModalValue?.path}
                        idForTesting={`switch-default`}
                        label={t.SetAsDefault}
                    />
                </div>
                <DialogActions
                    sx={{
                        padding: "12px 0px 0px 0px ",
                        "span:last-of-type": {
                            marginLeft: "12px",
                        },
                    }}
                >
                    {hasExperimentalModalClose && (
                        <Button
                            variant="textOnly"
                            size="normal"
                            color="secondary"
                            fullWidth
                            onClick={() => setIsExperimentalModalOpen(false)}
                            idForTesting="cancelGeneralSettingsModal"
                        >
                            {t.Close}
                        </Button>
                    )}
                    <Button
                        focus
                        variant="textOnly"
                        size="normal"
                        color="primary"
                        fullWidth
                        onClick={handleSubmit}
                        disabled={!experimentalModalValue?.path}
                        idForTesting="primary-button"
                    >
                        {t.Apply}
                    </Button>
                </DialogActions>
            </FormModal>
        </Fragment>
    );
};

export default ExperimentalFirmwareModal;
