/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from "@emotion/react";

import { useContext } from "react";
import ThemeContext from "../../context/theme/themeContext";

interface DividerProps {
    additionalCss?: React.CSSProperties;
    hasLighterGray?: boolean;
}
const Divider = ({ additionalCss, hasLighterGray }: DividerProps) => {
    const {
        colors: { gray300, gray200 },
    } = useContext(ThemeContext);

    return (
        <div
            css={css({
                height: "1px",
                width: "100%",
                background: hasLighterGray ? gray200 : gray300,
                ...additionalCss,
            })}
        />
    );
};

export default Divider;
