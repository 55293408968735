import React, { useReducer } from "react";
import {
    REMOVE_EDITABLE_INPUT_BY_ID,
    REMOVE_PARAMETER_VALUE_BY_ID,
    RESET_DEFAULT_VALUES,
    RESET_EDITABLE_INPUTS,
    SET_ECOCALCULATOR_VALUES,
    SET_EDITABLE_INPUT,
    SET_PARAMETER_VALUE,
    SET_PARAMETER_VALUES,
    SUBMIT_PARAMETER_VALUE,
    SUBMIT_PARAMETER_VALUES,
} from "./featuresActions";
import FeaturesContext from "./featuresContext";
import FeaturesReducer from "./featuresReducer";
import {
    DriveTypes,
    DrivingModes,
    TransmissionTypes,
} from "../../constants/constants";

export interface FeaturesStateProps {
    children: React.ReactNode;
}

export interface EcoCalculatorValues {
    driveType: DriveTypes;
    transmissionType: TransmissionTypes;
    drivingMode: DrivingModes;
    vehicleWeight: number;
    vehiclePower: number;
    vehicleWeightError: boolean;
    vehiclePowerError: boolean;
}

export type ParameterValue = {
    index?: number;
    value: number | string;
    parameterId: number;
    label?: string;
    error?: string | null;
};

export interface FeaturesInitialState {
    editableInputs: number[];
    parametersValues: ParameterValue[];
    defaultParameterValues: ParameterValue[];
    ecoCalculatorValues?: EcoCalculatorValues | null;
}

const FeaturesState = ({ children }: FeaturesStateProps) => {
    const initialState = {
        editableInputs: [],
        parametersValues: [],
        defaultParameterValues: [],
    };
    const [state, dispatch] = useReducer(FeaturesReducer, initialState);

    const setEditableInput = (data: number) => {
        dispatch({
            type: SET_EDITABLE_INPUT,
            payload: data,
        });
    };
    const resetEditableInputs = () => {
        dispatch({
            type: RESET_EDITABLE_INPUTS,
        });
    };
    const removeEditableInputById = (data: number) => {
        dispatch({
            type: REMOVE_EDITABLE_INPUT_BY_ID,
            payload: data,
        });
    };
    const setParametersValues = (data: ParameterValue[]) => {
        dispatch({
            type: SET_PARAMETER_VALUES,
            payload: data,
        });
    };
    const setParameterValue = (data: ParameterValue) => {
        dispatch({
            type: SET_PARAMETER_VALUE,
            payload: data,
        });
    };
    const removeParameterValueById = (data: number) => {
        dispatch({
            type: REMOVE_PARAMETER_VALUE_BY_ID,
            payload: data,
        });
    };
    const submitParameterValues = () => {
        dispatch({
            type: SUBMIT_PARAMETER_VALUES,
        });
    };
    const submitParameterValue = (data: ParameterValue) => {
        dispatch({
            type: SUBMIT_PARAMETER_VALUE,
            payload: data,
        });
    };
    const findParameterValueById = (data: number) => {
        return state.parametersValues.find((item) => item.parameterId === data)
            ?.value;
    };

    const findDefaultParameterValueById = (data: number) => {
        return state.defaultParameterValues.find(
            (item) => item.parameterId === data
        )?.value;
    };
    const hasEditableInput = (parameterId: number) =>
        state.editableInputs.length > 0 &&
        state.editableInputs.some((i: number) => i === parameterId);

    const getEcoCalculatorValues = () => state.ecoCalculatorValues;

    const setEcoCalculatorValues = (values: EcoCalculatorValues | null) =>
        dispatch({
            type: SET_ECOCALCULATOR_VALUES,
            payload: values,
        });

    const resetDefaultParameterValues = () => {
        dispatch({
            type: RESET_DEFAULT_VALUES,
        });
    };
    return (
        <FeaturesContext.Provider
            value={{
                editableInputs: state.editableInputs,
                parametersValues: state.parametersValues,
                setEditableInput,
                removeEditableInputById,
                resetEditableInputs,
                setParametersValues,
                setParameterValue,
                removeParameterValueById,
                submitParameterValues,
                submitParameterValue,
                findDefaultParameterValueById,
                findParameterValueById,
                hasEditableInput,
                getEcoCalculatorValues,
                setEcoCalculatorValues,
                resetDefaultParameterValues,
            }}
        >
            {children}
        </FeaturesContext.Provider>
    );
};

export default FeaturesState;
