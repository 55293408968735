/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from "@emotion/react";
import { useContext } from "react";
import ThemeContext from "../../context/theme/themeContext";

export interface LoadingBubblesProps {
    loading?: boolean;
}

const LoadingBubbles: React.FunctionComponent<LoadingBubblesProps> = () => {
    const {
        colors: { gray300 },
    } = useContext(ThemeContext);

    return (
        <div
            css={css`
                display: flex;

                & > div {
                    width: 8px;
                    height: 8px;
                    border-radius: 50%;
                    background-color: ${gray300};
                }

                & > div:nth-of-type(1) {
                    animation: bounce 1.2s infinite ease-in-out;
                }

                & > div:nth-of-type(2) {
                    animation: bounce 1.2s infinite ease-in-out;
                    animation-delay: 0.2s;
                    margin-left: 6px;
                }

                & > div:nth-of-type(3) {
                    animation: bounce 1.2s infinite ease-in-out;
                    animation-delay: 0.4s;
                    margin-left: 6px;
                }

                @keyframes bounce {
                    0% {
                        transform: translate(0, 0);
                    }
                    20% {
                        transform: translate(0, 0);
                    }
                    50% {
                        transform: translate(0, -8px);
                    }
                    80% {
                        transform: translate(0, 0);
                    }
                    100% {
                        transform: translate(0, 0);
                    }
                }
            `}
        >
            <div></div>
            <div></div>
            <div></div>
        </div>
    );
};

export default LoadingBubbles;
