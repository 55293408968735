import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

const DeviceStatusIcon = (props: SvgIconProps) => {
    return (
        <SvgIcon viewBox="0 0 24 24" {...props}>
            <path
                d="M4 19.9875C3.45334 19.9875 2.98568 19.793 2.59634 19.4037C2.207 19.0143 2.0125 18.5467 2.0125 18V13.0125H7.36727L9.08862 16.4552C9.08865 16.4552 9.08868 16.4553 9.08872 16.4554C9.1731 16.6409 9.29987 16.7804 9.46898 16.8735C9.63742 16.9661 9.81448 17.0125 10 17.0125C10.1855 17.0125 10.3626 16.9661 10.531 16.8735C10.7001 16.7804 10.8269 16.6409 10.9113 16.4554C10.9113 16.4553 10.9113 16.4552 10.9114 16.4552L14 10.2779L15.0886 12.4552C15.0887 12.4552 15.0887 12.4553 15.0887 12.4554C15.1731 12.6409 15.2999 12.7804 15.469 12.8735C15.6374 12.9661 15.8145 13.0125 16 13.0125H21.9875V18C21.9875 18.5467 21.793 19.0143 21.4037 19.4037C21.0143 19.793 20.5467 19.9875 20 19.9875H4ZM13.0886 7.54483L10 13.722L8.91138 11.5448C8.91135 11.5448 8.91132 11.5447 8.91129 11.5446C8.82691 11.3591 8.70013 11.2196 8.53102 11.1265C8.36258 11.0339 8.18552 10.9875 8 10.9875H2.0125V6C2.0125 5.45334 2.207 4.98568 2.59634 4.59634C2.98568 4.207 3.45334 4.0125 4 4.0125H20C20.5467 4.0125 21.0143 4.207 21.4037 4.59634C21.793 4.98568 21.9875 5.45334 21.9875 6V10.9875H16.6327L14.9114 7.54483C14.9113 7.54476 14.9113 7.54469 14.9113 7.54462C14.8268 7.35893 14.6998 7.22745 14.5301 7.1511C14.3618 7.07537 14.1851 7.0375 14 7.0375C13.8149 7.0375 13.6382 7.07537 13.4699 7.1511C13.3002 7.22745 13.1732 7.35893 13.0887 7.54462C13.0887 7.54469 13.0887 7.54476 13.0886 7.54483Z"
                fill="white"
                stroke="white"
                strokeWidth="0.025"
            />
        </SvgIcon>
    );
};

export default DeviceStatusIcon;
