/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/react";
import { Fragment, useContext, useEffect, useState } from "react";
import AlertContext from "../../../../../../context/alert/alertContext";
import FeaturesContext from "../../../../../../context/features/featuresContext";
import { ParameterValue } from "../../../../../../context/features/FeaturesState";
import LanguageContext from "../../../../../../context/language/languageContext";
import LayoutContext from "../../../../../../context/layout/layoutContext";
import { EcoCalculator } from "../../../../../../generatedTypes";
import FormModal from "../../../../../MuiComponents/Modals/FormModal";
import TypedGrid from "../../../../../MuiComponents/TypedGrid";
import CardSection from "../CardSection";
import EcoAutoCalculator from "./EcoAutoCalculator";
import MenuItemContext from "../../../../../../context/menuItem/menuItemContext";
import useTct from "../../../../../../utils/useTct";
import { AlertStatus } from "../../../../../../constants/constants";

const EcoCalculatorModalSection = ({ data }: { data: EcoCalculator }) => {
    const { t } = useContext(LanguageContext);
    const { layoutData } = useContext(LayoutContext);
    const { setAlert } = useContext(AlertContext);
    const {
        editableInputs,
        parametersValues,
        submitParameterValues,
        setParametersValues,
    } = useContext(FeaturesContext);
    const { setParameter, findParameterValueById } =
        useContext(MenuItemContext);

    const [isLoading, setLoading] = useState(false);
    const [isModalOpen, setOpenModal] = useState(false);
    const handleOpenModal = () => setOpenModal(true);
    const handleCloseModal = () => {
        const previousParameterValues = parametersValues.map((parameter) => {
            return {
                ...parameter,
                value: findParameterValueById(parameter.parameterId),
                error: null,
            };
        });
        setParametersValues(previousParameterValues);
        setOpenModal(false);
    };
    const { updateParametersAsync } = useTct();

    useEffect(() => {
        setParametersValues(
            data.components!.map((component) => {
                const parameter: ParameterValue = {
                    parameterId: component.parameterId,
                    label: component.label,
                    value: component.parameterValue,
                    index: component.index,
                };
                return parameter;
            })
        );
        // eslint-disable-next-line
    }, []);

    const handleSubmitForm = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (parametersValues.find((parameter) => parameter.error)) {
            return;
        }
        if (parametersValues.length > 0) {
            const payload = parametersValues.map(
                (parameter: ParameterValue) => ({
                    id: parameter.parameterId,
                    value: String(parameter.value),
                })
            );
            try {
                setLoading(true);
                await updateParametersAsync(layoutData?.id || 0, payload);
                submitParameterValues();
                parametersValues.forEach((parameter) => {
                    setParameter(parameter.parameterId, parameter.value);
                });

                setOpenModal(false);
                setAlert(AlertStatus.Success, "Updated successfully");
            } catch (error) {
                setAlert(AlertStatus.Critical, "Failed to update");
            }

            setLoading(false);
        }
        setOpenModal(false);
    };

    const disabledTooltipText = {
        title: Boolean(parametersValues.find((parameter) => parameter.error))
            ? t.CannotSaveConfigurationWithErrors
            : "Some values need to be confirmed before saving",
        goTo: 0,
        btnTitle: "",
    };

    return (
        <Fragment>
            <CardSection
                data={data as any}
                onButtonClick={handleOpenModal}
                isPrimaryButtonDisabled={false}
                isEditable={true}
                isDisabled={isModalOpen}
            />
            <FormModal
                isOpen={isModalOpen}
                onClose={handleCloseModal}
                onSubmit={handleSubmitForm}
                title={data.name || ""}
                description={data.description}
                secondaryButtonText={data.cancelCaption}
                primaryButtonText={data.saveCaption}
                isSubmitting={isLoading}
                customModalStyles={{ maxWidth: "920px" }}
                isPrimaryButtonDisabled={
                    editableInputs.length > 0 ||
                    Boolean(
                        parametersValues.find((parameter) => parameter.error)
                    )
                }
                disabledTooltip={disabledTooltipText}
            >
                <TypedGrid container spacing={3}>
                    <TypedGrid item sm={6} xs0={12}>
                        <EcoAutoCalculator
                            parameters={data.components!}
                            inputParameters={data.inputParameters!}
                        />
                    </TypedGrid>
                    <TypedGrid item sm={6} xs0={12}>
                        <CardSection
                            data={data as any}
                            isEditable={true}
                            submitValuesOnChange={false}
                        />
                    </TypedGrid>
                </TypedGrid>
            </FormModal>
        </Fragment>
    );
};

export default EcoCalculatorModalSection;
