import {
    GO_TO_NEXT_VIEW,
    HANDLE_CURRENT_PIN,
    HANDLE_NEW_PIN,
    HANDLE_NEW_REPEATED_PIN,
    SET_LOADING,
    WRONG_CURRENT_PIN,
} from "./changePinActions";
import LanguageContext from "../../../../../../../context/language/languageContext";
import { useContext } from "react";

export interface InitialState {
    isLoading: boolean;
    isDisabled: boolean;
    step: number;
    currentPin: string | null;
    newPin: string | null;
    newRepeatedPin: string | null;
    explanatoryText: string;
    submitButtonText: string;
    errorMsg: string | null;
    retriesLeft: number | null;
}

export type Action =
    | {
          type: typeof SET_LOADING;
          payload: boolean;
      }
    | {
          type: typeof HANDLE_CURRENT_PIN;
          payload: string | null;
      }
    | {
          type: typeof HANDLE_NEW_PIN;
          payload: string | null;
      }
    | {
          type: typeof HANDLE_NEW_REPEATED_PIN;
          payload: string | null;
      }
    | {
          type: typeof GO_TO_NEXT_VIEW;
      }
    | {
          type: typeof WRONG_CURRENT_PIN;
          payload: number;
      };

export default (state: InitialState, action: Action) => {
    const { t } = useContext(LanguageContext);
    switch (action.type) {
        case SET_LOADING: {
            return {
                ...state,
                isLoading: action.payload,
                errorMsg: action.payload ? null : state.errorMsg,
                retriesLeft: action.payload ? null : state.retriesLeft,
            };
        }
        case HANDLE_CURRENT_PIN: {
            const { payload } = action;

            return {
                ...state,
                isDisabled: !Boolean(payload),
                currentPin: payload,
            };
        }
        case HANDLE_NEW_PIN: {
            const { payload } = action;

            return {
                ...state,
                isDisabled: !Boolean(payload),
                newPin: payload,
            };
        }
        case HANDLE_NEW_REPEATED_PIN: {
            const { payload } = action;

            const doNotMatch =
                Boolean(state.newPin) &&
                Boolean(payload) &&
                payload !== state.newPin;

            return {
                ...state,
                isDisabled: !Boolean(payload) || doNotMatch,
                step: doNotMatch ? 2 : state.step,
                newPin: doNotMatch ? null : state.newPin,
                newRepeatedPin: doNotMatch ? null : payload,
                explanatoryText: doNotMatch
                    ? t.EnterNewCode
                    : state.explanatoryText,
                submitButtonText: doNotMatch
                    ? t.Continue
                    : state.submitButtonText,
                errorMsg: doNotMatch ? t.NewPinCodesDoNotMatch : null,
            };
        }
        case GO_TO_NEXT_VIEW: {
            return {
                ...state,
                isDisabled: true,
                step: ++state.step,
                explanatoryText:
                    state.step === 3 ? t.RepeatNewCode : t.EnterNewCode,
                submitButtonText: state.step === 3 ? t.Change : t.Continue,
            };
        }
        case WRONG_CURRENT_PIN: {
            return {
                ...state,
                errorMsg: t.IncorrectCurrentPinCode,
                retriesLeft: action.payload - 1,
            };
        }
        default:
            return state;
    }
};
