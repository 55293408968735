import React, { useReducer } from "react";
import ListContext from "../../../../../../context/list/listContext";
import List from "./List";
import ListReducer from "../../../../../../context/list/listReducer";
import {
    ADD_INPUT,
    CHANGE_INPUTS,
    CLEAR_INPUTS,
    CLOSE_WITHOUT_SAVING,
    HANDLE_CONFIRMATION_DIALOG,
    IMPORT_INPUT_VALUES,
    SAVE_VALUES,
    SET_ERROR,
    SET_INITIAL_INPUTS,
    SET_SAVE_LOADING,
} from "../../../../../../context/list/listActions";
import { createListErrors } from "../../../../../../utils/helpers";

export interface ListManagementModal {
    name: string;
    title: string;
    description: string;
    itemLabel: string;
    addBtnCaption: string;
    clearAllCaption: string;
    saveSuccess: string;
    saveFailed: string;
}

export interface ListModal extends ListManagementModal {
    importTooltip: string;
    exportTooltip: string;
    importSuccess: string;
    importFailed: string;
    exportSuccess: string;
    exportFailed: string;
}

export interface ListConfig {
    name: string;
    caption: string;
    startId: number;
    startIdSecond: number;
    numberOfItems: number;
    numberOfItemsSecond: number;
    textMaxLength: number;
    addCaption: string;
    manageCaption: string;
    items: { value: string }[];
    listType: string;
    listModal: ListModal;
}

export interface ListData {
    listConfig: ListConfig;
}

interface ListStateProps {
    data: any; //validateInitial not found in Component
}

const ListState = ({ data }: ListStateProps) => {
    const {
        listConfig: {
            name,
            caption,
            description,
            startId,
            startIdSecond,
            numberOfItems,
            numberOfItemsSecond,
            textMaxLength,
            addCaption,
            manageCaption,
            items,
            listType,
            listModal,
            renderType,
        },
        placeholder,
    } = data;

    const {
        title: modalTitle,
        description: modalDescription,
        itemLabel: modalItemLabel,
        addBtnCaption: modalAddBtnCaption,
        clearAllCaption: modalClearAllCaption,
        importTooltip: modalImportTooltip,
        exportTooltip: modalExportTooltip,
        exportDisabledTooltip: modalExportDisabledTooltip,
        importSuccess: modalImportSuccessMsg,
        saveSuccess: modalSaveSuccessMsg,
        saveFailed: modalSaveFailedMsg,
    } = listModal;

    const initialState = {
        isSaveLoading: false,
        isAllowedToScroll: false,
        isOpenConfirmation: false,
        isError: false,
        inputs: [],
        previousInputs: [],
        errors: {},
        dallasSensorData: [],
        availableParameterIds: [],
    };

    const [state, dispatch] = useReducer(ListReducer, initialState);

    const setListInitialValues = (
        inputsPayload: any,
        transformedInputs: any
    ) => {
        dispatch({
            type: SET_INITIAL_INPUTS,
            payload: {
                inputsPayload: inputsPayload,
                errorsPayload: createListErrors(
                    transformedInputs,
                    textMaxLength
                ),
            },
        });
    };

    const setImportInputValues = (inputs: any, transformedInputs: any) => {
        dispatch({
            type: IMPORT_INPUT_VALUES,
            payload: {
                inputsPayload: inputs,
                errorsPayload: createListErrors(
                    transformedInputs,
                    textMaxLength
                ),
            },
        });
    };

    const setError = (error: any) => {
        dispatch({ type: SET_ERROR, payload: error });
    };

    const setCloseListWithouSaving = (
        inputsPayload: any,
        countPayload: any,
        errorsPayload: any
    ) => {
        dispatch({
            type: CLOSE_WITHOUT_SAVING,
            payload: {
                inputsPayload: inputsPayload,
                countPayload: countPayload,
                errorsPayload: createListErrors(errorsPayload, textMaxLength),
            },
        });
    };

    const openConfirmationModal = () => {
        dispatch({ type: HANDLE_CONFIRMATION_DIALOG, payload: true });
    };

    const closeConfirmationModal = (): void =>
        dispatch({ type: HANDLE_CONFIRMATION_DIALOG, payload: false });

    const clearInputs = (inputs: any) => {
        dispatch({ type: CLEAR_INPUTS, payload: inputs });
    };

    const changeInputs = (
        updatedInputs: any,
        count: any,
        errorPayload: any
    ) => {
        dispatch({
            type: CHANGE_INPUTS,
            payload: {
                inputsPayload: updatedInputs,
                countPayload: count,
                errorValuePayload: errorPayload,
            },
        });
    };

    const addInput = (newIndex: any, language: any, newParameterId: any) => {
        dispatch({
            type: ADD_INPUT,
            payload: {
                inputPayload: {
                    index: newIndex,
                    value: "",
                    language: language,
                    parameterId: newParameterId,
                },
                errorPayload: { [newIndex]: null },
            },
        });
    };

    const setSavingLoading = (isLoading: boolean) => {
        dispatch({ type: SET_SAVE_LOADING, payload: isLoading });
    };

    const saveValues = (countPayload: any, payloadInputs: any, errors: any) => {
        dispatch({
            type: SAVE_VALUES,
            payload: {
                countPayload: countPayload,
                payloadInputs: payloadInputs,
                errorsPayload: createListErrors(errors, textMaxLength),
            },
        });
    };

    return (
        <ListContext.Provider
            value={{
                isNumberType: listType === "Int32",
                items,
                valuesCountLimit: numberOfItems,
                valuesCountLimitWithSecondaryItems:
                    numberOfItems + numberOfItemsSecond,
                idRangeFrom: startId,
                idRangeFromSecondary: startIdSecond,
                idRangeTo: startId + (numberOfItems - 1),
                idRangeToSecondary: startIdSecond + (numberOfItemsSecond - 1),
                maxLength: textMaxLength,
                subtitle: caption,
                name,
                description,
                modalTitle,
                modalDescription,
                modalClearAllCaption,
                modalSaveSuccessMsg,
                modalSaveFailedMsg,
                modalAddBtnCaption,
                modalImportSuccessMsg,
                modalItemLabel,
                placeholder,
                renderType,
                availableParameterIds: items.map(
                    (item: any) => item.parameterId
                ),
                isSaveLoading: state.isSaveLoading,
                isAllowedToScroll: state.isAllowedToScroll,
                isOpenConfirmation: state.isOpenConfirmation,
                isError: state.isError,
                inputs: state.inputs,
                previousInputs: state.previousInputs,
                errors: state.errors,
                setListInitialValues,
                setImportInputValues,
                setError,
                setCloseListWithouSaving,
                openConfirmationModal,
                closeConfirmationModal,
                clearInputs,
                changeInputs,
                addInput,
                setSavingLoading,
                saveValues,
            }}
        >
            <List
                addCaption={addCaption}
                manageCaption={manageCaption}
                exportTooltip={modalExportTooltip}
                exportDisabledTooltip={modalExportDisabledTooltip}
                importTooltip={modalImportTooltip}
            />
        </ListContext.Provider>
    );
};

export default ListState;
