/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from "@emotion/react";

import { useContext, useEffect } from "react";
import ThemeContext from "../../../../../../../context/theme/themeContext";
import AlertContext from "../../../../../../../context/alert/alertContext";
import LanguageContext from "../../../../../../../context/language/languageContext";
import LayoutContext from "../../../../../../../context/layout/layoutContext";
import MenuItemContext from "../../../../../../../context/menuItem/menuItemContext";
import ListContext from "../../../../../../../context/list/listContext";

import { ListItemInterface } from "../List";
import useApi from "../../../../../../../utils/useApi";
import useMediaQuery from "../../../../../../../utils/useMediaQueries";
import useAuxiliary from "../useAuxiliary";
import { SelectedFile } from "../../../../../../../utils/useUploadFile";
import Button from "../../../../../../MuiComponents/Button";
import Badge from "../../../../../../MuiComponents/Badge";
import ConfirmModal from "../../../../../../MuiComponents/Modals/ConfirmModal";
import ListInputs from "./ListInputs";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import CloseIcon from "@mui/icons-material/CloseRounded";
import AddIcon from "@mui/icons-material/AddRounded";
import CircularProgress from "@mui/material/CircularProgress";
import useTct from "../../../../../../../utils/useTct";
import { AlertStatus } from "../../../../../../../constants/constants";

interface ListManagementProps {
    isOpen: boolean;
    selectedFile: SelectedFile;
    valuesCount: number;
    openDialog: () => void;
    closeDialog: () => void;
    setImportLoading: React.Dispatch<React.SetStateAction<boolean>>;
    setValues: React.Dispatch<React.SetStateAction<ListItemInterface[]>>;
}

const ListManagement = ({
    isOpen,
    selectedFile,
    valuesCount,
    openDialog,
    closeDialog,
    setImportLoading,
    setValues,
}: ListManagementProps) => {
    const {
        colors: { white, blue400, blue200 },
    } = useContext(ThemeContext);

    const { setAlert } = useContext(AlertContext);
    const { selectedLanguage, t } = useContext(LanguageContext);
    const { layoutData } = useContext(LayoutContext);
    const { setParameter, setDisabledParameters } = useContext(MenuItemContext);
    const {
        isNumberType,
        items,
        maxLength,
        valuesCountLimit,
        subtitle,
        name,
        modalTitle,
        modalDescription,
        modalClearAllCaption,
        modalSaveSuccessMsg,
        modalSaveFailedMsg,
        modalAddBtnCaption,
        modalImportSuccessMsg,
        placeholder,
        idRangeFromSecondary,
        valuesCountLimitWithSecondaryItems,
        availableParameterIds,
        isSaveLoading,
        isAllowedToScroll,
        isOpenConfirmation,
        isError,
        inputs,
        previousInputs,
        errors,
        setImportInputValues,
        setError,
        setCloseListWithouSaving,
        openConfirmationModal,
        closeConfirmationModal,
        clearInputs,
        changeInputs,
        addInput,
        setSavingLoading,
        saveValues,
    } = useContext(ListContext);
    const hasSecondaryItems = !!idRangeFromSecondary;
    const { cancelSource, isCanceled, showErrorMsg } = useApi();
    const { importCsvForListConfigAsync, updateParametersAsync } = useTct();
    const { toMd } = useMediaQuery();
    const {
        filterAndTransform,
        insertMissingItems,
        filterItems,
        findFirstUnusedParameterId,
    } = useAuxiliary();

    useEffect(() => {
        let isSubscribed = true;
        const sendImportedFile = async () => {
            try {
                setImportLoading(true);
                const data = await importCsvForListConfigAsync(
                    layoutData?.id || 0,
                    name,
                    selectedFile
                );
                const result = data?.result;
                const warning = data?.warning;

                if (isSubscribed) {
                    const transformedValues = filterAndTransform(
                        result.map((listValue: string) => ({
                            value: listValue,
                        }))
                    );
                    const transformedInputs = insertMissingItems(
                        transformedValues,
                        availableParameterIds,
                        0
                    );

                    const addParameterIdsToInputs = transformedInputs.map(
                        (item, index) => {
                            return {
                                ...item,
                                parameterId: availableParameterIds[index],
                            };
                        }
                    );
                    setImportInputValues(
                        addParameterIdsToInputs,
                        addParameterIdsToInputs
                    );

                    openDialog();

                    if (warning) {
                        const warningTitle = warning.title;
                        const warningDescription = warning.paragraphs.map(
                            (line: string, i: number) => (
                                <div key={i}>{line}</div>
                            )
                        );

                        setAlert(
                            AlertStatus.Warning,
                            warningTitle,
                            warningDescription
                        );
                    } else {
                        setAlert(AlertStatus.Success, modalImportSuccessMsg);
                    }
                }
            } catch (err) {
                if (isSubscribed && !isCanceled(err)) {
                    showErrorMsg(err as any);
                }
            }

            setImportLoading(false);
        };

        selectedFile && sendImportedFile();

        return () => {
            isSubscribed = false;
            cancelSource.cancel("Api is being cancelled");
        };

        // eslint-disable-next-line
    }, [selectedFile]);

    useEffect(() => {
        const checkIfErrorExists = (): boolean => {
            const errorArray: (string | null)[] = Object.values(errors);
            return errorArray.some((item) => item !== null);
        };

        setError(checkIfErrorExists());

        // eslint-disable-next-line
    }, [inputs]);

    const closeModalWithoutSaving = () => {
        closeDialog();
        setCloseListWithouSaving(previousInputs, valuesCount, previousInputs);
    };

    const submitConfirmationModal = (): void => {
        closeConfirmationModal();
        closeModalWithoutSaving();
    };

    const handleClearInputs = () => {
        const clearedInputs: ListItemInterface[] = inputs.map(
            (input: ListItemInterface) => ({
                ...input,
                value: "",
            })
        );
        clearInputs(clearedInputs);
    };

    const handleChangeInputs = (
        inputIndex: number,
        inputValue: string,
        errorValue: string | null,
        parameterId: number,
        language?: string
    ) => {
        const udatedInput = {
            index: inputIndex,
            value: inputValue,
            ...(language && { language: language }),
            parameterId,
        };
        const updatedInputs: ListItemInterface[] = inputs.map(
            (input: ListItemInterface) =>
                input.index === inputIndex ? udatedInput : input
        );

        const filteredInputs = filterItems(updatedInputs);
        changeInputs(updatedInputs, filteredInputs.length, {
            [inputIndex]: errorValue,
        });
    };

    const handleAddInput = () => {
        const lastIndex: number =
            inputs.length > 0 ? inputs[inputs.length - 1].index : 0;
        const newIndex: number = lastIndex + 1;
        const newParameterId = findFirstUnusedParameterId(
            availableParameterIds,
            inputs.map((input: any) => input.parameterId)
        );
        addInput(newIndex, inputs[inputs.length - 1]?.language, newParameterId);
    };

    const submitForm = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        setSavingLoading(true);
        const transformedInputs = insertMissingItems(
            inputs,
            availableParameterIds,
            valuesCountLimit
        );

        const getValuePayload = (val: string) => {
            if (isNumberType) {
                return val.length > 0 ? val : "0";
            }

            return val;
        };

        const payload = transformedInputs.map(({ value, parameterId }) => ({
            id: parameterId,
            value: getValuePayload(value),
        }));

        try {
            const data = await updateParametersAsync(
                layoutData?.id || 0,
                payload
            );
            data?.visibilities && setDisabledParameters(data.visibilities);
            const filteredValues = filterItems(inputs);
            const filteredTransformedInputs = insertMissingItems(
                filteredValues,
                availableParameterIds
            );

            setParameter(name, filteredValues.length > 0);
            setValues(filteredValues);
            saveValues(
                filteredValues.length,
                filteredTransformedInputs,
                filteredTransformedInputs
            );

            closeDialog();
            setAlert(AlertStatus.Success, modalSaveSuccessMsg);
        } catch (error) {
            setAlert(AlertStatus.Critical, modalSaveFailedMsg);
        }
        setSavingLoading(false);
    };
    return (
        <Dialog
            onClose={isSaveLoading ? undefined : openConfirmationModal}
            open={isOpen}
            fullScreen={toMd}
            fullWidth
            maxWidth="md920"
        >
            <DialogTitle
                sx={{
                    marginRight: "24px",
                }}
            >
                {modalTitle}
                <Button
                    variant="iconOnly"
                    size="small"
                    color="white"
                    disabled={isSaveLoading}
                    icon={<CloseIcon onClick={openConfirmationModal} />}
                    css={css`
                        position: absolute;
                        top: 4px;
                        right: 4px;
                    `}
                    idForTesting="closeListManagementModal"
                />
                <span
                    css={css`
                        display: block;
                        font-size: 14px;
                        line-height: 20px;
                        letter-spacing: 0.1px;
                        margin-top: 8px;
                    `}
                >
                    {modalDescription}
                </span>
            </DialogTitle>

            <form
                noValidate
                onSubmit={submitForm}
                css={css`
                    display: flex;
                    flex-direction: column;
                    flex: 1 1 auto;
                `}
            >
                <DialogContent
                    dividers
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                    }}
                >
                    <div
                        css={css`
                            flex: 1 1 auto;
                            margin-right: -16px;
                            padding-right: 16px;
                            margin-bottom: 16px;
                            height: 414px;
                            overflow-y: auto;

                            &::-webkit-scrollbar {
                                width: 18px;
                            }

                            &::-webkit-scrollbar-thumb {
                                background-color: ${blue200};
                                border-radius: 100px;
                                border: 5px solid ${white};
                            }

                            &::-webkit-scrollbar-thumb:hover {
                                background-color: ${blue400};
                            }
                        `}
                    >
                        <div
                            css={css`
                                display: flex;
                                justify-content: space-between;
                                align-items: center;
                            `}
                        >
                            <div
                                css={css`
                                    display: flex;
                                    align-items: center;
                                `}
                            >
                                <div
                                    css={css`
                                        font-size: 16px;
                                        font-weight: 600;
                                        line-height: 20px;
                                        letter-spacing: 0.15px;
                                        margin-right: 8px;
                                    `}
                                >
                                    {subtitle}
                                </div>

                                <Badge color="info">
                                    {valuesCount} /{" "}
                                    {hasSecondaryItems
                                        ? valuesCountLimitWithSecondaryItems
                                        : valuesCountLimit}
                                </Badge>
                            </div>

                            <Button
                                variant="textOnly"
                                size="small"
                                color="secondary"
                                disabled={isSaveLoading}
                                onClick={handleClearInputs}
                                idForTesting="clearAllListInputs"
                            >
                                {modalClearAllCaption}
                            </Button>
                        </div>

                        <ListInputs
                            errors={errors}
                            inputs={inputs}
                            isDisabled={isSaveLoading}
                            change={handleChangeInputs}
                            isAllowedToScroll={isAllowedToScroll}
                            placeholder={placeholder}
                            locale={selectedLanguage}
                        />
                    </div>

                    <Button
                        fullWidth
                        variant="iconLeft"
                        size="normal"
                        color="primarySubtle"
                        icon={<AddIcon />}
                        disabled={
                            isSaveLoading || hasSecondaryItems
                                ? inputs.length ===
                                  valuesCountLimitWithSecondaryItems
                                : inputs.length === valuesCountLimit
                        }
                        onClick={handleAddInput}
                        idForTesting="addListInput"
                    >
                        {modalAddBtnCaption}
                    </Button>
                </DialogContent>

                <DialogActions>
                    <Button
                        variant="textOnly"
                        size="normal"
                        color="secondary"
                        fullWidth
                        disabled={isSaveLoading}
                        onClick={closeModalWithoutSaving}
                        idForTesting="cancelListManagementModal"
                    >
                        {t.Cancel}
                    </Button>

                    <Button
                        variant="textOnly"
                        size="normal"
                        color="primary"
                        fullWidth
                        type="submit"
                        disabled={isError}
                        onClick={submitForm}
                        css={css({
                            pointerEvents: isSaveLoading ? "none" : "auto",
                        })}
                        idForTesting="saveListManagementModal"
                    >
                        {isSaveLoading ? (
                            <div
                                css={css({
                                    width: "20px",
                                    height: "20px",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                })}
                            >
                                <CircularProgress
                                    size={20}
                                    css={css`
                                        color: ${white};
                                    `}
                                />
                            </div>
                        ) : (
                            t.Save
                        )}
                    </Button>
                </DialogActions>
            </form>

            <ConfirmModal
                open={isOpenConfirmation}
                close={closeConfirmationModal}
                submit={submitConfirmationModal}
                title="Are you sure want to close and lose all progress?"
            />
        </Dialog>
    );
};

export default ListManagement;
