/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, SerializedStyles } from "@emotion/react";

import { forwardRef, ReactNode, useContext } from "react";
import ThemeContext from "../../context/theme/themeContext";
import Label from "./Label";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import SelectMui, { SelectChangeEvent } from "@mui/material/Select";
import InputAdornment from "@mui/material/InputAdornment";
import ExpandMoreIcon from "@mui/icons-material/ExpandMoreRounded";
import { SxProps, Theme } from "@mui/material";

export interface SelectProps {
    onChange?: (event: SelectChangeEvent<any>, child: ReactNode) => void;
    children: React.ReactNode;
    label?: string;
    rightLabel?: string | null;
    requesting?: boolean;
    requestFailed?: boolean;
    placeholder?: string;
    placeholderIcon?: React.ReactNode;
    medium?: boolean;
    iconRight?: React.ReactNode;
    info?: string;
    disabled?: boolean;
    readOnly?: boolean;
    wrapperStyle?: SerializedStyles;
    value?: any;
    sx?: SxProps<Theme>;
    multiple?: boolean;
    renderValue?: (selected: any) => ReactNode;
    variant?: "standard" | "outlined" | "filled";
    disableUnderline?: boolean;
    withoutArrow?: boolean;
    css?: SerializedStyles;
    MenuProps?: any;
    name?: string;
    open?: boolean;
    onInfoIconClick?: () => void;
    id?: string;
}

const Select = forwardRef<HTMLDivElement, SelectProps>(
    (
        {
            children,
            label,
            rightLabel,
            requesting,
            requestFailed,
            placeholder,
            placeholderIcon,
            medium,
            iconRight,
            info,
            disabled,
            readOnly,
            wrapperStyle,
            withoutArrow,
            onInfoIconClick,
            id,
            ...props
        },
        ref
    ) => {
        const {
            colors: { white, gray100, textPlaceholder, textDarkDisabled },
        } = useContext(ThemeContext);

        return (
            <div ref={ref} css={wrapperStyle}>
                {label && (
                    <Label
                        title={label}
                        rightTitle={rightLabel}
                        requesting={requesting}
                        requestFailed={requestFailed}
                        info={info}
                        onInfoIconClick={onInfoIconClick}
                    />
                )}

                <FormControl fullWidth disabled={disabled}>
                    {placeholder && (
                        <InputLabel
                            shrink={false}
                            sx={{
                                color: textPlaceholder,
                                fontSize: "14px",
                                fontWeight: "600",
                                lineHeight: "24px",
                                transform: "none",
                                top: "calc(50% - 12px)",
                                left: "12px",
                                maxWidth: "calc(100% - 48px)",

                                "&.Mui-focused": {
                                    color: textPlaceholder,
                                },

                                "&.Mui-disabled": {
                                    color: textDarkDisabled,
                                    WebkitTextFillColor: textDarkDisabled,
                                },

                                "& svg": {
                                    marginRight: "8px",
                                },
                            }}
                        >
                            {placeholderIcon &&
                                props.value.length === 0 &&
                                placeholderIcon}

                            {props.value.length === 0 && placeholder}
                        </InputLabel>
                    )}

                    <SelectMui
                        {...props}
                        data-test={id}
                        inputProps={{ readOnly }}
                        IconComponent={ExpandMoreIcon}
                        endAdornment={
                            iconRight ? (
                                <InputAdornment position="end">
                                    {iconRight}
                                </InputAdornment>
                            ) : null
                        }
                        sx={{
                            ...props.sx,
                            paddingRight: "0",

                            "& .MuiSelect-select": {
                                padding: medium ? "10px 12px" : "6px 12px",
                                minWidth: "28px",
                                background: readOnly ? gray100 : white,
                            },
                            "& .MuiSelect-icon": {
                                display: withoutArrow ? "none" : "unset",
                            },
                            "& .MuiInputAdornment-root": {
                                position: "absolute",
                                right: "44px",
                            },
                        }}
                    >
                        {children}
                    </SelectMui>
                </FormControl>
            </div>
        );
    }
);

export default Select;
