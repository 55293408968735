import React from "react";
import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

const InfoIcon = (props: SvgIconProps) => {
    return (
        <SvgIcon {...props} viewBox="0 0 16 16">
            <path
                viewBox="0 0 16 16"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.06732 4.86634C9.06732 4.40412 8.66287 3.99967 8.20065 3.99967C7.73843 3.99967 7.33398 4.40412 7.33398 4.86634C7.33398 5.32856 7.68065 5.73301 8.20065 5.73301C8.72065 5.73301 9.06732 5.32856 9.06732 4.86634ZM7.22287 11.333H9.4451C9.77843 11.333 10.0007 11.1757 10.0007 10.8611C10.0007 10.5465 9.77843 10.3368 9.4451 10.3368H9.00065C8.9451 10.3368 8.88954 10.2843 8.88954 10.2319V7.71503C8.88954 7.13825 8.38954 6.66634 7.77843 6.66634H7.22287C6.88954 6.66634 6.66732 6.87608 6.66732 7.19069C6.66732 7.50529 6.88954 7.71503 7.22287 7.71503H7.66732C7.72287 7.71503 7.77843 7.76746 7.77843 7.8199V10.1794C7.77843 10.2319 7.72287 10.2843 7.66732 10.2843H7.22287C6.88954 10.2843 6.66732 10.4941 6.66732 10.8087C6.66732 11.1233 6.88954 11.333 7.22287 11.333ZM8.00065 1.33301C4.33398 1.33301 1.33398 4.33301 1.33398 7.99967C1.33398 11.6663 4.33398 14.6663 8.00065 14.6663C11.6673 14.6663 14.6673 11.6663 14.6673 7.99967C14.6673 4.33301 11.6673 1.33301 8.00065 1.33301Z"
                fill="currentColor"
            />
        </SvgIcon>
    );
};

export default InfoIcon;
