/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import React from "react";
import { Stepper as MuiStepper, Step, StepLabel } from "@mui/material";
import CustomStep from "./CustomStep";
import CustomConnector from "./CustomConnector";

export interface StepperProps {
    steps: {
        label: React.ReactNode;
        indexes: number[];
    }[];
    activeStep: number;
    stepErrors: {
        step: number;
        error: string;
    };
}

const Stepper: React.FunctionComponent<StepperProps> = ({
    steps,
    activeStep,
    stepErrors,
}) => {
    return (
        <MuiStepper
            alternativeLabel
            activeStep={activeStep}
            connector={
                <CustomConnector
                    activeStep={activeStep}
                    stepErrors={stepErrors}
                />
            }
            sx={{
                maxWidth: "400px",
                alignItems: "center",
                margin: "0 auto",
            }}
        >
            {steps.map((step) => {
                const stepHasError = step?.indexes.some(
                    (item) => item === stepErrors.step
                );
                const hasMultipleIndexes = step?.indexes.length > 1;
                const isNextStepFailed =
                    stepErrors.error && stepErrors.step < step?.indexes[0];
                const isStepFinished = () => {
                    if (stepHasError || isNextStepFailed) {
                        return false;
                    }
                    if (hasMultipleIndexes) {
                        return step?.indexes[1] < activeStep;
                    }
                    return step?.indexes[0] < activeStep;
                };

                const renderCustomStep = (props: any) => {
                    return (
                        <CustomStep
                            {...props}
                            active={step?.indexes.some(
                                (item) => item === activeStep
                            )}
                            stepIsFinished={isStepFinished()}
                            stepHasError={stepHasError}
                        />
                    );
                };

                return (
                    <Step key={step?.indexes[0]}>
                        <StepLabel StepIconComponent={renderCustomStep}>
                            <span
                                css={css({
                                    fontSize: "14px",
                                    fontStyle: "normal",
                                    fontWeight: 600,
                                    lineHeight: "20px",
                                    letterSpacing: "0.1px",
                                })}
                            >
                                {step.label}
                            </span>
                        </StepLabel>
                    </Step>
                );
            })}
        </MuiStepper>
    );
};

export default Stepper;
