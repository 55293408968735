import React, { useContext, useEffect, Fragment } from "react";
import { generatePath, Switch, useLocation } from "react-router-dom";
import UserInfoContext from "../../context/userInfo/userInfoContext";
import useMuiStyles from "./useMuiStyles";
import GlobalStyles from "./GlobalStyles";
import Snackbar from "../Snackbar";
import PrivateRoute from "./PrivateRoute";
import Route from "./Route";
import StartUp from "../StartUp";
import {
    ThemeProvider,
    StyledEngineProvider,
    createTheme,
} from "@mui/material/styles";
import {
    START_UP,
    EDITOR_STATUS,
    EDITOR_MENU_ITEM,
    EDITOR_FRAME_ITEM,
} from "../../constants/routes";
import StatusView from "../Editor/StatusView";
import MenuItemView from "../Editor/MenuItemView";
import FrameItemView from "../Editor/FrameItemView";
import useGoogleAnalytics from "../../utils/useGoogleAnalytics";
import AppTypeContext from "../../context/appType/appTypeContext";
import DeviceGuide from "../DeviceGuide";
import Drawer from "../MuiComponents/Drawer";
import Terminal from "../Terminal";
import SettingsContext from "../../context/settings/settingsContext";

const Router = () => {
    const { getInitialUserInfo, isDesktopApplication } =
        useContext(UserInfoContext);
    const { isEditor } = useContext(AppTypeContext);
    const { isTerminalOpen } = useContext(SettingsContext);

    const { search } = useLocation();
    const webVersionRoute =
        generatePath(EDITOR_MENU_ITEM, {
            menuItem: 0,
        }) + `/${search}`;

    const muiThemeStyles = useMuiStyles();

    const theme = createTheme(muiThemeStyles as any);
    useEffect(() => {
        getInitialUserInfo();
        // eslint-disable-next-line
    }, []);
    useGoogleAnalytics();

    return (
        <Fragment>
            <GlobalStyles />

            <StyledEngineProvider injectFirst>
                <ThemeProvider theme={theme}>
                    {isTerminalOpen && isDesktopApplication && <Terminal />}
                    <DeviceGuide />
                    <Snackbar />
                    <Drawer />
                    <Switch>
                        <PrivateRoute
                            exact
                            path={START_UP}
                            component={StartUp}
                            redirectCondition={
                                !isDesktopApplication && isEditor
                            }
                            redirectRoute={webVersionRoute}
                        />
                        <PrivateRoute
                            exact
                            path={EDITOR_STATUS}
                            component={StatusView}
                            redirectCondition={!isDesktopApplication}
                            redirectRoute={webVersionRoute}
                        />
                        <Route
                            exact
                            path={EDITOR_MENU_ITEM}
                            component={MenuItemView}
                        />
                        <Route
                            exact
                            path={EDITOR_FRAME_ITEM}
                            component={FrameItemView}
                        />
                    </Switch>
                </ThemeProvider>
            </StyledEngineProvider>
        </Fragment>
    );
};

export default Router;
