/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from "@emotion/react";

import { useContext, useEffect } from "react";
import ThemeContext from "../../../../context/theme/themeContext";
import Collapse from "@mui/material/Collapse";
import {
    Component as ComponentData,
    GroupBox as GroupBoxData,
    GeoRadius,
} from "../../../../generatedTypes";
import { Grid } from "@mui/material";
import NumberInput from "./Component/NumberInput";
import Label from "../../../MuiComponents/Label";
import LanguageContext from "../../../../context/language/languageContext";
import ManualGeofenceContext from "../../../../context/manualGeofence/manualGeofenceContext";
import MenuItemContext from "../../../../context/menuItem/menuItemContext";
import Divider from "../../../MuiComponents/Divider";

export interface CoordinatesBoxProps {
    data: GroupBoxData;
}

const CoordinatesBox = ({ data }: CoordinatesBoxProps) => {
    const { border, separator, components, name } = data;

    const {
        colors: { gray200 },
    } = useContext(ThemeContext);

    const { updateParameters, resetParameters } = useContext(MenuItemContext);
    const {
        latitudeLongitudeParameters,
        radius,
        setLoading: setManualGeofenceLoading,
    } = useContext(ManualGeofenceContext);

    const { t } = useContext(LanguageContext);

    const borderStyle = border ? `1px solid ${gray200}` : "none";

    const geoComponents = components as GeoRadius[];

    const frameBorderAndRadiusComponents = geoComponents.filter(
        (item) =>
            item.elementType === "GeoFrameBorder" ||
            item.coordinateType === "GeoRadius"
    );

    const longituteComponents = geoComponents.filter(
        (item) => item.coordinateType === "GeoLongitude"
    );

    const latitudeComponents = geoComponents.filter(
        (item) => item.coordinateType === "GeoLatitude"
    );

    const longituteParameterIds = longituteComponents.map((item, index) => {
        return {
            id: item.parameterId,
            value: String(latitudeLongitudeParameters.long[index]),
        };
    });

    const latitudeParameterIds = latitudeComponents.map((item, index) => {
        return {
            id: item.parameterId,
            value: String(latitudeLongitudeParameters.lat[index]),
        };
    });

    const geodRadiusId: any[] = frameBorderAndRadiusComponents
        .map((item) => {
            if (item.coordinateType === "GeoRadius") {
                return {
                    id: item.parameterId,
                    value: String(radius),
                };
            }
        })
        .filter(Boolean);

    const joinedParameterArray = longituteParameterIds.concat([
        ...latitudeParameterIds,
        ...geodRadiusId,
    ]);

    const hasUndefinedValues = joinedParameterArray.some(
        (item) => item.value === "undefined"
    );

    const joinedParameterArrayIds = longituteParameterIds
        .map((item) => item.id)
        .concat([
            ...latitudeParameterIds.map((item) => item.id),
            ...geodRadiusId.map((item) => item.id),
        ]);

    useEffect(() => {
        !hasUndefinedValues &&
            updateParameters(
                joinedParameterArray,
                () => setManualGeofenceLoading(false),
                () => setManualGeofenceLoading(false),
                true,
                false,
                true
            );
    }, [latitudeLongitudeParameters]);

    useEffect(() => {
        return () => {
            resetParameters(joinedParameterArrayIds);
        };
    }, []);

    return (
        <div
            css={css`
                border: ${borderStyle};
                border-radius: 8px;
                scroll-margin: 16px;
            `}
        >
            <div
                css={css`
                    padding: 8px 0 16px 0;

                    & > p {
                        font-size: 14px;
                        font-weight: 400;
                        line-height: 20px;
                        letter-spacing: 0.1px;
                        margin-top: 8px;
                    }
                `}
            >
                <div
                    css={css`
                        display: flex;
                        justify-content: ${name ? "space-between" : "flex-end"};

                        & > h5 {
                            font-size: 16px;
                            font-weight: 600;
                            line-height: 20px;
                            letter-spacing: 0.15px;
                        }
                    `}
                >
                    {name && <h5>{name}</h5>}
                </div>
            </div>
            <Collapse in={true}>
                {separator && <Divider />}

                <Grid container columnSpacing={3}>
                    {frameBorderAndRadiusComponents.map(
                        (component: ComponentData) => {
                            return (
                                <Grid item xs={6} key={component.parameterId}>
                                    <NumberInput
                                        data={component}
                                        isGeoInput
                                        stacked
                                    />
                                </Grid>
                            );
                        }
                    )}
                </Grid>
                <Grid
                    container
                    columnSpacing={3}
                    css={css({ marginTop: "12px" })}
                >
                    <Grid item xs={6}>
                        <Label title={t.LatitudeY} />
                        {latitudeComponents.map((item, index) => {
                            return (
                                <NumberInput
                                    data={item}
                                    key={item.parameterId}
                                    isGeoInput
                                    otherInputSx={{
                                        borderTopLeftRadius:
                                            index === 0 ? "6px" : "0px",
                                        borderTopRightRadius:
                                            index === 0 ? "6px" : "0px",
                                        borderBottomLeftRadius:
                                            index + 1 ===
                                            latitudeComponents.length
                                                ? "6px"
                                                : "0px",
                                        borderBottomRightRadius:
                                            index + 1 ===
                                            latitudeComponents.length
                                                ? "6px"
                                                : "0px",
                                    }}
                                    withoutLabel
                                    stacked
                                />
                            );
                        })}
                    </Grid>
                    <Grid item xs={6}>
                        <Label title={t.LongitudeX} />
                        {longituteComponents.map((item, index) => {
                            return (
                                <NumberInput
                                    data={item}
                                    key={item.parameterId}
                                    isGeoInput
                                    otherInputSx={{
                                        borderTopLeftRadius:
                                            index === 0 ? "6px" : "0px",
                                        borderTopRightRadius:
                                            index === 0 ? "6px" : "0px",
                                        borderBottomLeftRadius:
                                            index + 1 ===
                                            longituteComponents.length
                                                ? "6px"
                                                : "0px",
                                        borderBottomRightRadius:
                                            index + 1 ===
                                            longituteComponents.length
                                                ? "6px"
                                                : "0px",
                                    }}
                                    withoutLabel
                                    stacked
                                />
                            );
                        })}
                    </Grid>
                </Grid>
            </Collapse>
        </div>
    );
};

export default CoordinatesBox;
