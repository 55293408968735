/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import { Fragment, useContext, useState } from "react";
import FeaturesContext from "../../../../../../../context/features/featuresContext";
import { Component, Schedule } from "../../../../../../../generatedTypes";
import Card from "../../../../../../MuiComponents/Card";
import Switch from "../../../../../../MuiComponents/Switch";
import TimelineCard from "./TimelineCard";
import Tooltip from "../../../../../../MuiComponents/Tooltip";
import Tag from "../../../../../../MuiComponents/Tag";

export interface DaylightSettingsCardProps {
    daylight: Component;
    startDateSettings: Schedule["startSettings"];
    endDateSettings: Schedule["endSettings"];
}

export enum DaylightSettingsSwitchValues {
    ON = "1",
    OFF = "0",
}

const DaylightSettingsCard = ({
    daylight,
    endDateSettings,
    startDateSettings,
}: DaylightSettingsCardProps) => {
    const { setParameterValue, findDefaultParameterValueById } =
        useContext(FeaturesContext);

    const defaultSwitchValue =
        findDefaultParameterValueById(daylight.parameterId) ||
        daylight.parameterValue ||
        daylight.default;

    const [isSwitchedOn, setSwitchOn] = useState(defaultSwitchValue);

    const isSwitchToggleOn = isSwitchedOn === DaylightSettingsSwitchValues.ON;
    const handleSwitchToggle = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSwitchOn(
            isSwitchToggleOn
                ? DaylightSettingsSwitchValues.OFF
                : DaylightSettingsSwitchValues.ON
        );
        setParameterValue({
            parameterId: daylight.parameterId,
            value: event.target.checked
                ? DaylightSettingsSwitchValues.ON
                : DaylightSettingsSwitchValues.OFF,
        });
    };
    return (
        <Card
            title="Daylight saving settings"
            description="The device shifts an hour according to the configuration set. You can set up start and end times for daylight savings."
            action={
                <div css={css({ display: "flex" })}>
                    <Tooltip
                        title={`ID: ${daylight.parameterId}`}
                        small
                        placement="top"
                    >
                        <span>
                            <Tag size="tiny" color="white" title="ID" />
                        </span>
                    </Tooltip>
                    <Switch
                        checked={isSwitchToggleOn}
                        onChange={handleSwitchToggle}
                        size="medium"
                        labelPlacement="start"
                        wrapperStyle={css({ marginLeft: "8px" })}
                        idForTesting="daylight-switch"
                    />
                </div>
            }
            divider={isSwitchToggleOn}
            isSection
        >
            {isSwitchToggleOn && (
                <Fragment>
                    <TimelineCard
                        title="Start on"
                        settings={startDateSettings!}
                    />
                    <TimelineCard title="End on" settings={endDateSettings!} />
                </Fragment>
            )}
        </Card>
    );
};

export default DaylightSettingsCard;
