import React from "react";
import { EDITOR_STATUS } from "../../../../../constants/routes";
import useUrlQueryParams from "../../../../../utils/useUrlQueryParams";
import EditorSettings from "./EditorSettings";
import StartupSettings from "./StartupSettings";

const SettingsModal = () => {
    const { pathname } = useUrlQueryParams();

    const onEditorRoute = pathname.toLowerCase().includes(EDITOR_STATUS);

    return onEditorRoute ? <EditorSettings /> : <StartupSettings />;
};

export default SettingsModal;
