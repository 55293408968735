/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import React, { Fragment, useContext } from "react";
import BundleUpdateContext from "../../../context/bundleUpdateContext";
import ThemeContext from "../../../../../../../context/theme/themeContext";
import { ExpandMore, CheckCircle, ErrorOutline } from "@mui/icons-material";
import LanguageContext from "../../../../../../../context/language/languageContext";
import {
    UpdateStages,
    UpdateStatusType,
} from "../../../../../../../constants/constants";
import AccordionDetailItem from "./AccordionDetailItem";
import { ReactComponent as SucessStatusIcon } from "../../../../../../../assets/bundleUpdate/SuccessStatus.svg";
import DeviceStatusContext from "../../../../../../../context/deviceStatus/deviceStatusContext";

export interface InfoStepProps {}

const InfoStep: React.FunctionComponent<InfoStepProps> = () => {
    const { setExpanded, expanded } = useContext(BundleUpdateContext);
    const {
        colors: { gray200, blue700, red700 },
    } = useContext(ThemeContext);
    const { t } = useContext(LanguageContext);
    const { bundleModalProgressStates } = useContext(DeviceStatusContext);

    const handleChange =
        (panel: string) => (_: React.SyntheticEvent, isExpanded: boolean) => {
            setExpanded(isExpanded ? panel : false);
        };

    const renderStatusLabel = (status: string) => {
        if (status === UpdateStatusType.InProgress) {
            return (
                <div
                    css={css({
                        marginLeft: "8px",
                    })}
                >
                    {t.UpdateStatusTypeProgress}...
                </div>
            );
        }
        if (status === UpdateStatusType.Success) {
            return (
                <div
                    css={css({
                        display: "flex",
                        alignItems: "center",
                        gap: "4px",
                        marginLeft: "8px",
                    })}
                >
                    <CheckCircle
                        css={{
                            color: blue700,
                            fontSize: "16px",
                        }}
                    />
                    <span>{t.UpdateStatusTypeSuccess}</span>
                </div>
            );
        }
        if (status === UpdateStatusType.Failed) {
            return (
                <div
                    css={css({
                        display: "flex",
                        alignItems: "center",
                        gap: "4px",
                        marginLeft: "8px",
                    })}
                >
                    <ErrorOutline
                        css={{
                            color: red700,
                            fontSize: "16px",
                        }}
                    />
                    <span>{t.UpdateStatusTypeFailed}</span>
                </div>
            );
        }
        return (
            <div
                css={css({
                    marginLeft: "8px",
                })}
            >
                {t.UpdateStatusTypeWaiting}
            </div>
        );
    };

    const renderMcuTypeUpperCase = (step: { mcuType: string }) => {
        if (step?.mcuType === "Ble" || step?.mcuType === "Can") {
            return step.mcuType.toUpperCase();
        }
        return step?.mcuType;
    };

    const renderFileType = (step: { fileType: string }) => {
        if (step?.fileType === "Dtb") {
            return step.fileType.toUpperCase();
        }
        return step?.fileType;
    };

    const renderCurrentStatusText = (
        step: any,
        withoutSuccessStep?: boolean
    ) => {
        const progress = step?.progress?.current;

        if (progress?.status === UpdateStatusType.Failed) {
            return `Failed to update ${step.mcuType} ${step.fileType}`;
        }
        if (
            !withoutSuccessStep &&
            progress?.status === UpdateStatusType.Success
        ) {
            return (
                <div
                    css={css({
                        display: "flex",
                        alignItems: "center",
                        gap: "4px",
                    })}
                >
                    <SucessStatusIcon />
                    <span>Success</span>
                </div>
            );
        }
        if (progress?.status === UpdateStatusType.InProgress) {
            const label =
                progress?.stage === UpdateStages.FileUpload
                    ? "Uploading"
                    : "Updating";
            return `${label} ${renderMcuTypeUpperCase(step)} ${renderFileType(
                step
            )} ...${progress.completedPercent}%`;
        }
        return t.UpdateStatusTypeWaiting;
    };

    const firmwareStatusDetails =
        bundleModalProgressStates?.filter(
            (step: any) => step.fileType === "Firmware"
        ) || [];

    const dtbStatusDetails =
        bundleModalProgressStates?.filter(
            (step: any) => step.fileType === "Dtb"
        ) || [];

    return (
        <Fragment>
            {firmwareStatusDetails.map((step: any, index: number) => {
                const dtbStep = dtbStatusDetails.find(
                    (dtbStep: any) => dtbStep.mcuType === step.mcuType
                );
                const renderAccodionDetailItem = (
                    step: any,
                    isUpload?: boolean
                ) => {
                    const status = isUpload
                        ? step.details.upload.status
                        : step.details.update.status;
                    return (
                        <Fragment>
                            <div
                                css={css({
                                    width: "100%",
                                    maxWidth: "162px",
                                })}
                            >
                                {renderMcuTypeUpperCase(step)}{" "}
                                {renderFileType(step)}{" "}
                                {isUpload ? "file upload" : "update"}:
                            </div>
                            <div>{renderStatusLabel(status)}</div>
                        </Fragment>
                    );
                };

                const renderDtbStepStatuses = () => {
                    if (!!dtbStep) {
                        return (
                            <Fragment>
                                <AccordionDetailItem
                                    label={renderAccodionDetailItem(
                                        dtbStep,
                                        true
                                    )}
                                />
                                <AccordionDetailItem
                                    label={renderAccodionDetailItem(dtbStep)}
                                />
                            </Fragment>
                        );
                    }
                    return <Fragment></Fragment>;
                };

                const renderGeneralStatus = (step: any, dtbStep: any) => {
                    const isUploadCompleted =
                        step?.details?.upload?.status ===
                        UpdateStatusType.Success;
                    const isUpdateCompleted =
                        step?.details?.update?.status ===
                        UpdateStatusType.Success;
                    const isDtbUploadCompleted = !!dtbStep
                        ? dtbStep?.details?.upload?.status ===
                          UpdateStatusType.Success
                        : true;
                    const isDtbUpdateCompleted = !!dtbStep
                        ? dtbStep?.details?.update?.status ===
                          UpdateStatusType.Success
                        : true;

                    const isUploadFailed =
                        step?.details?.upload?.status ===
                        UpdateStatusType.Failed;
                    const isUpdateFailed =
                        step?.details?.update?.status ===
                        UpdateStatusType.Failed;
                    const isDtbUploadFailed =
                        dtbStep?.details?.upload?.status ===
                        UpdateStatusType.Failed;
                    const isDtbUpdateFailed =
                        dtbStep?.details?.update?.status ===
                        UpdateStatusType.Failed;

                    if (
                        isUploadCompleted &&
                        isUpdateCompleted &&
                        isDtbUploadCompleted &&
                        isDtbUpdateCompleted
                    ) {
                        return (
                            <div
                                css={css({
                                    display: "flex",
                                    alignItems: "center",
                                    gap: "4px",
                                    marginLeft: "8px",
                                })}
                            >
                                <CheckCircle
                                    css={{
                                        color: blue700,
                                        fontSize: "16px",
                                    }}
                                />
                                <span>{t.UpdateStatusTypeSuccess}</span>
                            </div>
                        );
                    }

                    if (
                        isUploadFailed ||
                        isUpdateFailed ||
                        isDtbUploadFailed ||
                        isDtbUpdateFailed
                    ) {
                        return (
                            <div
                                css={css({
                                    display: "flex",
                                    alignItems: "center",
                                    gap: "4px",
                                    marginLeft: "8px",
                                })}
                            >
                                <ErrorOutline
                                    css={{
                                        color: red700,
                                        fontSize: "16px",
                                    }}
                                />
                                <span>{t.UpdateStatusTypeFailed}</span>
                            </div>
                        );
                    }
                    return renderCurrentStatusText(step, true);
                };
                return (
                    <Accordion
                        key={`${step.mcuType}${step.fileType}`}
                        expanded={expanded === `panel${index}`}
                        onChange={handleChange(`panel${index}`)}
                        css={css({
                            "&:before": { display: "none" },
                            "&.Mui-expanded": {
                                margin: "0px",
                            },
                            boxShadow: "none",
                            border: `1px solid ${gray200}`,
                            borderTopLeftRadius: index === 0 ? "12px" : "0px",
                            borderTopRightRadius: index === 0 ? "12px" : "0px",
                            borderBottomLeftRadius:
                                index === bundleModalProgressStates.length - 1
                                    ? "12px"
                                    : "0px",
                            borderBottomRightRadius:
                                index === bundleModalProgressStates.length - 1
                                    ? "12px"
                                    : "0px",
                        })}
                    >
                        <AccordionSummary
                            expandIcon={<ExpandMore />}
                            css={css({
                                "& .MuiAccordionSummary-expandIconWrapper": {
                                    marginRight: "12px",
                                },
                            })}
                        >
                            <div
                                css={css({
                                    display: "flex",
                                    alignItems: "center",
                                    width: "100%",
                                    gap: "16px",
                                })}
                            >
                                <span
                                    css={css({
                                        fontWeight: 600,
                                        width: "100%",
                                        maxWidth: "162px",
                                    })}
                                >
                                    {renderMcuTypeUpperCase(step)}{" "}
                                    {renderFileType(step)}
                                </span>
                                <div
                                    css={css({
                                        display: "flex",
                                        alignItems: "center",
                                    })}
                                >
                                    {renderGeneralStatus(step, dtbStep)}
                                </div>
                            </div>
                        </AccordionSummary>
                        <AccordionDetails>
                            <div
                                css={css({
                                    paddingLeft: "16px",
                                    borderLeft: `2px solid ${gray200}`,
                                })}
                            >
                                <div
                                    css={css({
                                        display: "flex",
                                        gap: "8px",
                                        marginBottom: "8px",
                                        flexDirection: "column",
                                    })}
                                >
                                    <AccordionDetailItem
                                        label={renderAccodionDetailItem(
                                            step,
                                            true
                                        )}
                                    />
                                    <AccordionDetailItem
                                        label={renderAccodionDetailItem(step)}
                                    />
                                    {renderDtbStepStatuses()}
                                </div>
                            </div>
                        </AccordionDetails>
                    </Accordion>
                );
            })}
        </Fragment>
    );
};

export default InfoStep;
