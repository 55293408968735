/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from "@emotion/react";

import { useContext } from "react";
import moment from "moment";
import ThemeContext from "../../../../../context/theme/themeContext";
import { NotificationType } from "../../../../../context/settings/settingsReducer";
import SettingsContext from "../../../../../context/settings/settingsContext";
import ListChoice from "../../../../MuiComponents/ListChoice";
import AlertCriticalIcon from "../../../../../assets/icons/AlertCriticalIcon";
import AlertWarningIcon from "../../../../../assets/icons/AlertWarningIcon";
import Badge from "@mui/material/Badge";
import { noop } from "../../../../../utils/helpers";

interface SingleNotificationProps {
    id: string;
    type: NotificationType;
    timeStamp: Date;
    title: string;
    description: string;
    isRead?: boolean;
    dynamicContent?: string;
}

const SingleNotification = ({
    id,
    type,
    timeStamp,
    title,
    description,
    isRead,
    dynamicContent,
}: SingleNotificationProps) => {
    const {
        colors: {
            gray700,
            gray100,
            red600,
            orange500,
            textDark,
            textPlaceholder,
        },
    } = useContext(ThemeContext);

    const { readNotification } = useContext(SettingsContext);

    const setBadgeColor = () => {
        switch (type) {
            case "warning":
                return orange500;
            case "error":
                return red600;
        }
    };

    const Icon = type === "warning" ? AlertWarningIcon : AlertCriticalIcon;

    const badgeColor = setBadgeColor();

    return (
        <ListChoice
            dismissIcon={!isRead}
            timeStamp={
                <div
                    css={css({
                        color: textDark,
                        fontSize: "10px",
                        lineHeight: "12px",
                        fontWeight: "600",
                        letterSpacing: "1px",
                        marginBottom: "4px",
                    })}
                >
                    {moment(timeStamp).format("L LTS")}
                </div>
            }
            title={title}
            icon={
                <span
                    css={css({
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "24px",
                        height: "24px",
                        background: gray100,
                        borderRadius: "4.8px",
                        marginRight: "8px",
                    })}
                >
                    <Badge
                        invisible={isRead}
                        badgeContent=" "
                        variant="dot"
                        sx={{
                            "& .MuiBadge-badge": {
                                backgroundColor: badgeColor,
                                right: "3px",
                                top: "2px",
                            },
                        }}
                    >
                        <Icon
                            css={css({
                                fontSize: "16px",
                                marginRight: "0 !important",

                                "& > path": {
                                    fill: textPlaceholder,
                                },
                            })}
                        />
                    </Badge>
                </span>
            }
            dynamicContent={dynamicContent}
            description={
                <div
                    css={css({
                        color: gray700,
                        fontSize: "10px",
                        lineHeight: "12px",
                        letterSpacing: "1px",
                        marginTop: "4px",
                    })}
                >
                    {description}
                </div>
            }
            onClick={() => (isRead ? noop() : readNotification(id))}
            css={css({
                pointerEvents: isRead && !dynamicContent ? "none" : "auto",

                "&.latest-notification-single-enter": {
                    opacity: "0",
                },

                "&.latest-notification-single-enter-active": {
                    opacity: "1",
                    transition: "opacity 300ms",
                },

                "&.latest-notification-single-exit": {
                    opacity: "1",
                },

                "&.latest-notification-single-exit-active": {
                    opacity: "0",
                    transition: "opacity 150ms",
                },
            })}
        />
    );
};

export default SingleNotification;
