/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from "@emotion/react";

import { useContext } from "react";

import ThemeContext from "../../../../../context/theme/themeContext";
import LanguageContext from "../../../../../context/language/languageContext";
import useMediaQueries from "../../../../../utils/useMediaQueries";
import TextLink from "../../../TextLink";
import Checkbox from "../../../Checkbox";

interface TableHeadToolbarProps {
    rowsCount?: number;
    allSelected?: boolean;
    atLeastSingleSelected?: boolean;
    onCheckboxChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onDeselectAll?: () => void;
    onDeleteClick?: () => void;
}

const TableHeadToolbar = ({
    rowsCount,
    allSelected,
    atLeastSingleSelected,
    onCheckboxChange,
    onDeselectAll,
    onDeleteClick,
}: TableHeadToolbarProps) => {
    const {
        colors: { blue200, blue400, blue500, textDark, blue100 },
    } = useContext(ThemeContext);

    const { t } = useContext(LanguageContext);

    const { toMd, fromMd } = useMediaQueries();

    return (
        <div
            css={css({
                position: "sticky",
                top: "0",
                left: "0",
                width: "100%",
                boxSizing: "border-box",
                marginTop: "-42px",
                zIndex: "4",
                display: "flex",
                alignItems: "center",
                flexWrap: "wrap",
                rowGap: "8px",
                background: blue100,
                borderRadius: "unset",
                padding: "5px 18px",
                whiteSpace: "nowrap",
                boxShadow:
                    "0px -1px 0px 0px #C5E2FF inset, 0px 1px 0px 0px #C5E2FF inset",
            })}
        >
            <div
                css={css({
                    display: "inline-flex",
                    borderRadius: "8px",
                    marginRight: "16px",

                    "& > div:hover": {
                        background: blue400,
                        borderColor: blue400,
                        zIndex: "1",
                    },

                    "& > div:active": {
                        background: blue500,
                        borderColor: blue500,
                        zIndex: "1",
                    },
                })}
            >
                <div
                    css={css({
                        div: {
                            marginLeft: toMd ? "6px" : "18px",
                        },
                        borderRadius: "8px 0 0 8px",
                        display: "flex",
                        alignItems: "center",
                        color: textDark,
                        fontSize: "14px",
                        lineHeight: "20px",
                        fontWeight: 600,
                        letterSpacing: "0.1px",
                        border: `1px solid ${blue200}`,
                        marginRight: "-1px",
                        padding: "3px 16px 3px 6px",
                        cursor: "pointer",
                        transition:
                            "all 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
                    })}
                >
                    <Checkbox
                        label={`${t.TableSelected} ${rowsCount}`}
                        checked={allSelected}
                        indeterminate={
                            !allSelected && atLeastSingleSelected
                                ? true
                                : undefined
                        }
                        onChange={onCheckboxChange}
                        sx={{ "& .MuiSvgIcon-root": { fontSize: 20 } }}
                    />
                </div>

                <div
                    css={css({
                        borderRadius: !toMd ? "8px" : "0 8px 8px 0",
                        display: "flex",
                        padding: "5px 16px",
                        borderTopLeftRadius: "0px",
                        borderBottomLeftRadius: "0px",
                        border: `1px solid ${blue200}`,
                        cursor: "pointer",
                        fontSize: "14px",
                        fontStyle: "normal",
                        fontWeight: 600,
                        lineHeight: "20px",
                        letterSpacing: "0.1px",
                    })}
                    onClick={onDeleteClick}
                >
                    {t.TableDelete}
                </div>

                {fromMd && (
                    <TextLink
                        size={"normal"}
                        onClick={onDeselectAll}
                        css={css({
                            marginLeft: "16px",
                        })}
                        idForTesting="deselect-all-rows"
                    >
                        {t.TableDeselectAll}
                    </TextLink>
                )}
            </div>
        </div>
    );
};

export default TableHeadToolbar;
