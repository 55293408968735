/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from "@emotion/react";

import { useContext } from "react";
import SearchContext from "../../context/search/searchContext";
import TextLink from "../MuiComponents/TextLink";

interface ToggleButtonProps {
    closed: boolean;
    title: string;
    toggleResults: () => void;
}

const ToggleButton = ({ closed, title, toggleResults }: ToggleButtonProps) => {
    const { isLoading } = useContext(SearchContext);

    return (
        <TextLink
            size="small"
            idForTesting="toggleButton"
            onClick={toggleResults}
            css={css({
                padding: "8px 16px 16px",
                pointerEvents: isLoading ? "none" : "auto",
            })}
        >
            {closed ? title : "Show less"}
        </TextLink>
    );
};

export default ToggleButton;
