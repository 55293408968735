import { FEElement } from "../../generatedTypes";
import {
    CHANGE_TAB,
    CLOSE_ACTIVATE_KEY_MODAL,
    CLOSE_DEVICE_GUIDE_STEPS,
    CLOSE_DEVICE_SET_UP_MODAL,
    CLOSE_GENERAL_SETTINGS_MODAL,
    CLOSE_INTERFACE_SETTINGS_MODAL,
    CLOSE_LANGUAGE_SETTINGS_MODAL,
    CLOSE_NOTIFICATION_MODAL,
    CLOSE_SETTINGS_MODAL,
    CLOSE_SYSTEM_INFORMATION_MODAL,
    CLOSE_TERMINAL,
    CLOSE_VERSION_HISTORY_MODAL,
    CLOSE_VERSION_INFO_MODAL,
    HIDE_EXPLANATORY_TEXTS,
    HIDE_PARAMETER_IDS,
    OPEN_ACTIVATE_KEY_MODAL,
    OPEN_DEVICE_GUIDE_STEPS,
    OPEN_DEVICE_SET_UP_MODAL,
    OPEN_GENERAL_SETTINGS_MODAL,
    OPEN_INTERFACE_SETTINGS_MODAL,
    OPEN_LANGUAGE_SETTINGS_MODAL,
    OPEN_NOTIFICATION_MODAL,
    OPEN_PRIVACY_POLICY_MODAL,
    OPEN_SETTINGS_MODAL,
    OPEN_SYSTEM_INFORMATION_MODAL,
    OPEN_TERMINAL,
    OPEN_VERSION_HISTORY_MODAL,
    OPEN_VERSION_INFO_MODAL,
    READ_NOTIFICATION,
    SET_DEVICE_GUIDE_BUTTON_DISABLED,
    SET_DEVICE_GUIDE_STEP_INDEX,
    SET_IS_DEVICE_GUIDE_IN_PROGRESS,
    SET_NOTIFICATION,
    SET_RELEASE_NOTES,
    SET_UPDATE_DOWNLOADED,
    SET_UPDATE_DOWNLOADING,
    SET_UPDATE_DOWNLOADING_PERCENTAGE,
} from "./settingsActions";

export type NotificationType = "warning" | "error";

export interface SingleNotification {
    id: string;
    type: NotificationType;
    timeStamp: Date;
    title: string;
    description: string;
    dynamicContent?: string;
}

export interface InitialState {
    anchorEl: null | HTMLButtonElement;
    previousAnchorEl: null | HTMLButtonElement;
    isReadTab: boolean;
    latestNotifications: SingleNotification[];
    historyNotifications: SingleNotification[];
    isNotificationModalOpen: boolean;
    isSettingsModalOpen: boolean;
    isGeneralSettingsModalOpen: boolean;
    isInterfaceSettingsModalOpen: boolean;
    isLanguageSettingsModalOpen: boolean;
    isVersionHistoryModalOpen: boolean;
    isVersionInfoModalOpen: boolean;
    isExplanatoryTextsHidden: boolean;
    isParameterIdsHidden: boolean;
    isActivateKeyModalOpen: boolean;
    firmwareVersion: string;
    updateInformation: UpdateInformation;
    downloadingPercentage: number | null;
    isSystemInformationModalOpen: boolean;
    isTerminalOpen: boolean;
    isDeviceSetUpModalOpen: boolean;
    isDeviceGuideStepsOpen: boolean;
    deviceGuideStepIndex: number | null;
    isDeviceGuideButtonDisabled: boolean;
    isDeviceGuideInProgress: boolean;
}

interface UpdateInformation {
    isUpdateDownloading: boolean;
    isUpdateDownloaded: boolean;
    releaseInformation: FEElement[];
}

type Action =
    | {
          type: typeof OPEN_NOTIFICATION_MODAL;
          payload: null | HTMLButtonElement;
      }
    | { type: typeof CLOSE_NOTIFICATION_MODAL }
    | { type: typeof CHANGE_TAB; payload: boolean }
    | { type: typeof SET_NOTIFICATION; payload: SingleNotification }
    | { type: typeof READ_NOTIFICATION; payload: string }
    | { type: typeof CLOSE_SETTINGS_MODAL }
    | { type: typeof OPEN_SETTINGS_MODAL; payload: null | HTMLButtonElement }
    | { type: typeof CLOSE_GENERAL_SETTINGS_MODAL }
    | { type: typeof OPEN_GENERAL_SETTINGS_MODAL }
    | { type: typeof OPEN_INTERFACE_SETTINGS_MODAL }
    | { type: typeof CLOSE_INTERFACE_SETTINGS_MODAL }
    | { type: typeof CLOSE_LANGUAGE_SETTINGS_MODAL }
    | { type: typeof OPEN_LANGUAGE_SETTINGS_MODAL }
    | { type: typeof CLOSE_VERSION_HISTORY_MODAL }
    | { type: typeof OPEN_VERSION_HISTORY_MODAL }
    | { type: typeof OPEN_PRIVACY_POLICY_MODAL }
    | { type: typeof HIDE_PARAMETER_IDS; payload: boolean }
    | { type: typeof HIDE_EXPLANATORY_TEXTS; payload: boolean }
    | {
          type: typeof SET_RELEASE_NOTES;
          payload: FEElement[] | null;
      }
    | { type: typeof SET_UPDATE_DOWNLOADED }
    | {
          type: typeof SET_UPDATE_DOWNLOADING;
          payload: boolean;
      }
    | {
          type: typeof SET_UPDATE_DOWNLOADING_PERCENTAGE;
          payload: number | null;
      }
    | {
          type: typeof OPEN_ACTIVATE_KEY_MODAL;
      }
    | { type: typeof CLOSE_ACTIVATE_KEY_MODAL }
    | { type: typeof OPEN_VERSION_INFO_MODAL }
    | { type: typeof CLOSE_VERSION_INFO_MODAL }
    | { type: typeof OPEN_SYSTEM_INFORMATION_MODAL }
    | { type: typeof CLOSE_SYSTEM_INFORMATION_MODAL }
    | { type: typeof OPEN_TERMINAL }
    | { type: typeof CLOSE_TERMINAL }
    | {
    type: typeof OPEN_DEVICE_SET_UP_MODAL;
    payload: null | HTMLButtonElement;
}
    | { type: typeof CLOSE_DEVICE_SET_UP_MODAL }
    | { type: typeof SET_DEVICE_GUIDE_STEP_INDEX; payload: number | null }
    | { type: typeof OPEN_DEVICE_GUIDE_STEPS }
    | { type: typeof CLOSE_DEVICE_GUIDE_STEPS }
    | { type: typeof SET_DEVICE_GUIDE_BUTTON_DISABLED; payload: boolean }
    | { type: typeof SET_IS_DEVICE_GUIDE_IN_PROGRESS; payload: boolean };

export default (state: InitialState, action: Action): InitialState => {
    switch (action.type) {
        case OPEN_TERMINAL: {
            return {
                ...state,
                isTerminalOpen: true,
                isSettingsModalOpen: false,
            };
        }
        case CLOSE_TERMINAL: {
            return {
                ...state,
                isTerminalOpen: false,
                isSettingsModalOpen: false,
            };
        }
        case OPEN_SYSTEM_INFORMATION_MODAL: {
            return {
                ...state,
                isSystemInformationModalOpen: true,
            };
        }
        case CLOSE_SYSTEM_INFORMATION_MODAL: {
            return {
                ...state,
                isSystemInformationModalOpen: false,
            };
        }
        case OPEN_VERSION_INFO_MODAL: {
            return {
                ...state,
                isVersionInfoModalOpen: true,
                isSettingsModalOpen: false,
            };
        }
        case CLOSE_VERSION_INFO_MODAL: {
            return {
                ...state,
                isVersionInfoModalOpen: false,
            };
        }
        case OPEN_ACTIVATE_KEY_MODAL: {
            return {
                ...state,
                isActivateKeyModalOpen: true,
            };
        }
        case CLOSE_ACTIVATE_KEY_MODAL: {
            return {
                ...state,
                isActivateKeyModalOpen: false,
            };
        }
        case OPEN_NOTIFICATION_MODAL: {
            return {
                ...state,
                anchorEl: action.payload,
                isNotificationModalOpen: true,
            };
        }
        case CLOSE_NOTIFICATION_MODAL: {
            return {
                ...state,
                anchorEl: null,
                isReadTab: true,
                isNotificationModalOpen: false,
            };
        }
        case OPEN_GENERAL_SETTINGS_MODAL: {
            return {
                ...state,
                isGeneralSettingsModalOpen: true,
            };
        }
        case CLOSE_GENERAL_SETTINGS_MODAL: {
            return {
                ...state,
                isGeneralSettingsModalOpen: false,
            };
        }
        case OPEN_LANGUAGE_SETTINGS_MODAL: {
            return {
                ...state,
                isLanguageSettingsModalOpen: true,
                isSettingsModalOpen: false,
            };
        }
        case CLOSE_LANGUAGE_SETTINGS_MODAL: {
            return {
                ...state,
                isLanguageSettingsModalOpen: false,
            };
        }
        case OPEN_VERSION_HISTORY_MODAL: {
            return {
                ...state,
                isVersionHistoryModalOpen: true,
                isSettingsModalOpen: false,
            };
        }
        case CLOSE_VERSION_HISTORY_MODAL: {
            return {
                ...state,
                isVersionHistoryModalOpen: false,
            };
        }
        case OPEN_PRIVACY_POLICY_MODAL: {
            return {
                ...state,
                isSettingsModalOpen: false,
            };
        }
        case OPEN_INTERFACE_SETTINGS_MODAL: {
            return {
                ...state,
                isInterfaceSettingsModalOpen: true,
                isSettingsModalOpen: false,
            };
        }
        case CLOSE_INTERFACE_SETTINGS_MODAL: {
            return {
                ...state,
                isInterfaceSettingsModalOpen: false,
            };
        }
        case OPEN_SETTINGS_MODAL: {
            return {
                ...state,
                anchorEl: action.payload,
                previousAnchorEl: action.payload,
                isSettingsModalOpen: true,
                isGeneralSettingsModalOpen: false,
                isInterfaceSettingsModalOpen: false,
                isLanguageSettingsModalOpen: false,
                isVersionInfoModalOpen: false,
            };
        }
        case CLOSE_SETTINGS_MODAL: {
            return {
                ...state,
                anchorEl: null,
                isSettingsModalOpen: false,
            };
        }
        case CHANGE_TAB: {
            return {
                ...state,
                isReadTab: action.payload,
            };
        }
        case HIDE_EXPLANATORY_TEXTS: {
            return {
                ...state,
                isExplanatoryTextsHidden: action.payload,
            };
        }
        case HIDE_PARAMETER_IDS: {
            return {
                ...state,
                isParameterIdsHidden: action.payload,
            };
        }
        case SET_NOTIFICATION: {
            const notification = action.payload;

            const updatedNotifications = [
                ...state.latestNotifications,
                notification,
            ];

            sessionStorage.setItem(
                "latestNotifications",
                JSON.stringify(updatedNotifications)
            );

            return {
                ...state,
                latestNotifications: updatedNotifications,
            };
        }
        case READ_NOTIFICATION: {
            const id = action.payload;

            const currentLatestNotifications = state.latestNotifications;

            const notificationIndex = currentLatestNotifications.findIndex(
                (notif) => notif.id === id
            );

            const readNotification = currentLatestNotifications.splice(
                notificationIndex,
                1
            );

            const updatedHistoryNotifications = [
                ...state.historyNotifications,
                ...readNotification,
            ];

            sessionStorage.setItem(
                "latestNotifications",
                JSON.stringify(currentLatestNotifications)
            );

            sessionStorage.setItem(
                "historyNotifications",
                JSON.stringify(updatedHistoryNotifications)
            );

            return {
                ...state,
                latestNotifications: currentLatestNotifications,
                historyNotifications: updatedHistoryNotifications,
            };
        }
        case SET_RELEASE_NOTES: {
            return {
                ...state,
                updateInformation: {
                    ...state.updateInformation,
                    releaseInformation: action.payload ?? [],
                },
            };
        }
        case SET_UPDATE_DOWNLOADED: {
            return {
                ...state,
                updateInformation: {
                    ...state.updateInformation,
                    isUpdateDownloaded: true,
                },
            };
        }
        case SET_UPDATE_DOWNLOADING: {
            return {
                ...state,
                updateInformation: {
                    ...state.updateInformation,
                    isUpdateDownloading: action.payload,
                },
            };
        }
        case SET_UPDATE_DOWNLOADING_PERCENTAGE: {
            return {
                ...state,
                downloadingPercentage: action.payload,
            };
        }
        case OPEN_DEVICE_GUIDE_STEPS: {
            return {
                ...state,
                isDeviceGuideStepsOpen: true,
            };
        }
        case CLOSE_DEVICE_GUIDE_STEPS: {
            return {
                ...state,
                isDeviceGuideStepsOpen: false,
            };
        }
        case OPEN_DEVICE_SET_UP_MODAL: {
            return {
                ...state,
                anchorEl: action.payload,
                isDeviceSetUpModalOpen: true,
            };
        }
        case CLOSE_DEVICE_SET_UP_MODAL: {
            return {
                ...state,
                isDeviceSetUpModalOpen: false,
            };
        }
        case SET_DEVICE_GUIDE_STEP_INDEX: {
            return {
                ...state,
                deviceGuideStepIndex: action.payload,
            };
        }
        case SET_DEVICE_GUIDE_BUTTON_DISABLED: {
            return {
                ...state,
                isDeviceGuideButtonDisabled: action.payload,
            };
        }
        case SET_IS_DEVICE_GUIDE_IN_PROGRESS: {
            return {
                ...state,
                isDeviceGuideInProgress: action.payload,
            }
        }
        default:
            return state;
    }
};
