/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from "@emotion/react";

import { useContext, useEffect, useRef, useState } from "react";
import ThemeContext from "../../../context/theme/themeContext";
import SwitchToggle from "./Segment/Component/SwitchToggle";
import Segment from "./Segment";
import Collapse from "@mui/material/Collapse";
import { Block as BlockData, Segment as SegmentData, } from "../../../generatedTypes";
import SettingsContext from "../../../context/settings/settingsContext";
import MenuItemContext from "../../../context/menuItem/menuItemContext";
import TooltipComposite from "../../MuiComponents/TooltipComposite";
import LayoutContext from "../../../context/layout/layoutContext";
import Divider from "../../MuiComponents/Divider";
import { DeviceFamily } from "../../../utils/types";

export interface BlockProps {
    data: BlockData;
}

const Block = ({ data }: BlockProps) => {
    const {
        border,
        separator,
        name,
        switchId,
        switchValue,
        switchDefaultValue,
        avlId,
        description,
        segments,
        disabledTooltip,
        justifyContent,
        localizationKey,
        orientation,
    } = data;

    const {
        colors: { gray200, textDark },
    } = useContext(ThemeContext);
    const { isExplanatoryTextsHidden } = useContext(SettingsContext);

    const { findParameterValueById } = useContext(MenuItemContext);
    const { setDrawerBlockName, layoutData } = useContext(LayoutContext);

    const switchParameterValue = findParameterValueById(switchId);

    const [switchedOn, setSwitchedOn] = useState(switchParameterValue !== "0");

    useEffect(
        () => setSwitchedOn(switchParameterValue !== "0"),
        [switchParameterValue]
    );

    const blockRef = useRef(null);

    const handleSwitch = (state: boolean) => setSwitchedOn(state);

    const setSegmentPadding = () => {
        // Specific case for Auto APN block as filetransfer is not hidden in layout from BE, but from FE
        if (localizationKey === "Auto APN" && !layoutData?.isOnline) {
            return "0px";
        }
        if (separator) {
            return "16px 24px 24px";
        }

        return border ? "0 24px 24px" : "0";
    };

    const isFtcDeviceFamily =
        layoutData?.deviceFamily &&
        (layoutData?.deviceFamily as unknown) === DeviceFamily.FTC;

    const getBlockBorder = () => {
        if (border || (!!segments?.[0]?.passwordBox && !!isFtcDeviceFamily)) {
            return `1px solid ${gray200}`;
        }
        return "none";
    };

    const segmentPadding = setSegmentPadding();

    const renderTableTooltip = () => {
        const segmentWithTable = segments?.find((x) => x.table);
        return segmentWithTable ? (
            <TooltipComposite
                components={segmentWithTable.table!.columns[1].components}
                onClick={() => setDrawerBlockName(localizationKey || "")}
            />
        ) : (
            ""
        );
    };

    const renderSegment = () => {
        const segmentCount = (segments?.length ?? 0);
        if (
            segmentCount > 0 &&
            justifyContent === "SpaceBetween" &&
            orientation === "Horizontal"
        ) {
            return (
                <div
                    css={css({
                        padding: "16px",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        gap: "24px",
                    })}
                >
                    {segments!.map((segment: SegmentData, i: number) => (
                        <Segment
                            key={segment.index}
                            data={segment}
                            lastSegment={false}
                            fullComponentWidth
                        />
                    ))}
                </div>
            );
        } else if (segmentCount > 0) {
            return (
                <div
                    css={css({
                        padding: segmentPadding,
                    })}
                >
                    {segments!.map((segment: SegmentData, i: number) => (
                        <Segment
                            key={segment.index}
                            data={segment}
                            lastSegment={i === segments!.length - 1}
                        />
                    ))}
                </div>
            );
        } else {
            return null;
        }
    };

    return (
        <div
            ref={blockRef}
            css={css({
                color: textDark,
                border: getBlockBorder(),
                borderRadius: "8px",
                scrollMargin: "16px",
            })}
        >
            {(name || switchId !== 0 || description) && (
                <div
                    css={css({
                        padding: "24px 24px 16px 24px",

                        "& > p": {
                            fontSize: "14px",
                            fontWeight: 400,
                            lineHeight: "20px",
                            letterSpacing: "0.1px",
                            marginTop: "8px",
                        },
                    })}
                >
                    <div
                        css={css({
                            display: "flex",
                            justifyContent: name ? "space-between" : "flex-end",
                        })}
                    >
                        {name && (
                            <div
                                css={css({
                                    display: "flex",
                                    alignItems: "center",
                                })}
                            >
                                <h5
                                    css={css({
                                        fontSize: "16px",
                                        fontWeight: 600,
                                        lineHeight: "20px",
                                        letterSpacing: "0.15px",
                                        marginRight: 6,
                                    })}
                                >
                                    {name}
                                </h5>
                                {renderTableTooltip()}
                            </div>
                        )}

                        {switchValue && (
                            <SwitchToggle
                                id={switchId}
                                avlId={avlId}
                                defaulValue={switchDefaultValue || ""}
                                switchedOn={switchedOn}
                                label={name || ""}
                                elementRef={blockRef}
                                toggle={handleSwitch}
                                dependentParameters={
                                    data.switchComponent
                                        ?.dependentParameterIds || null
                                }
                                disabledTooltip={disabledTooltip}
                                wrapperStyle={css({
                                    marginLeft: name ? "24px" : "0",
                                })}
                            />
                        )}
                    </div>

                    {description && !isExplanatoryTextsHidden && (
                        <p>{description}</p>
                    )}
                </div>
            )}
            <Collapse in={switchedOn}>
                {separator && <Divider />}
                {renderSegment()}
            </Collapse>
        </div>
    );
};

export default Block;
