/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from "@emotion/react";

import { Fragment } from "react";

export interface TwoIconsButtonProps {
    children: React.ReactNode;
    icon: React.ReactNode[];
}

const TwoIconsButton = ({ children, icon }: TwoIconsButtonProps) => {
    return (
        <Fragment>
            <span
                css={css`
                    display: flex;
                    align-items: center;
                    margin-right: 8px;
                `}
            >
                {icon[0]}
            </span>

            {children}

            <span
                css={css`
                    display: flex;
                    align-items: center;
                    margin-left: 8px;
                `}
            >
                {icon[1]}
            </span>
        </Fragment>
    );
};

export default TwoIconsButton;
