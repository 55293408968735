import { addOrReplaceObjectInArray } from "../../utils/helpers";
import { AccessoriesInitialState } from "./AccessoriesState";
import {
    RESET_FUEL_VALUES,
    SET_FUEL_VALUE,
    SET_FUEL_VALUES,
} from "./accessoriesActions";

type Action =
    | {
          type: typeof SET_FUEL_VALUE;
          payload: {
              fuelValue: number | undefined;
              llsValue: string;
              id: number;
          };
      }
    | { type: typeof SET_FUEL_VALUES; payload: any[] }
    | { type: typeof RESET_FUEL_VALUES };

export default (state: AccessoriesInitialState, action: Action) => {
    switch (action.type) {
        case SET_FUEL_VALUE:
            const updatedArray = addOrReplaceObjectInArray(
                state.fuelValues,
                action.payload
            );
            return {
                ...state,
                fuelValues: updatedArray,
            };
        case RESET_FUEL_VALUES:
            return {
                ...state,
                fuelValues: [],
            };
        case SET_FUEL_VALUES:
            return {
                ...state,
                fuelValues: action.payload,
            };
        default:
            return state;
    }
};
