import React from "react";
import SvgIcon from "@mui/material/SvgIcon";

const BluetoothIcon = (props: any) => {
    return (
        <SvgIcon {...props}>
            <path
                d="M16.9997 7.00005L12.7097 2.71005C12.0797 2.08005 10.9997 2.52005 10.9997 3.41005V9.59005L7.10973 5.70005C6.71973 5.31005 6.08973 5.31005 5.69973 5.70005C5.30973 6.09005 5.30973 6.72005 5.69973 7.11005L10.5897 12.0001L5.69973 16.8901C5.30973 17.2801 5.30973 17.9101 5.69973 18.3001C6.08973 18.6901 6.71973 18.6901 7.10973 18.3001L10.9997 14.4101V20.5901C10.9997 21.4801 12.0797 21.9301 12.7097 21.3001L16.9997 17.0001C17.3897 16.6101 17.3897 15.9801 16.9997 15.5901L13.4097 12.0001L16.9997 8.42005C17.3897 8.03005 17.3897 7.39005 16.9997 7.00005ZM12.9997 5.83005L14.8797 7.71005L12.9997 9.59005V5.83005ZM14.8797 16.2901L12.9997 18.1701V14.4101L14.8797 16.2901V16.2901Z"
                fill="currentColor"
            />
        </SvgIcon>
    );
};

export default BluetoothIcon;
