import { useContext } from "react";
import { generatePath, useHistory } from "react-router-dom";
import LayoutContext from "../context/layout/layoutContext";
import SearchContext from "../context/search/searchContext";
import { EDITOR_MENU_ITEM, EDITOR_FRAME_ITEM } from "../constants/routes";
import SettingsContext from "../context/settings/settingsContext";
import { SearchPath } from "../generatedTypes";

const useNavigation = () => {
    const { setActiveFrameIndex } = useContext(LayoutContext);
    const { selectSearchResult } = useContext(SearchContext);
    const {
        setDeviceGuideStepIndex,
        closeDeviceSetUpModal,
        closeDeviceGuideSteps,
    } = useContext(SettingsContext);

    const history = useHistory();

    const handleSearchNavigation = (
        result: { searchPath: SearchPath },
        putToSearchedResults?: boolean
    ) => {
        const menuItemId = result.searchPath.menuItem?.index;
        const subRoute = result.searchPath.frame?.index;
        const hasNestedRoutes = result.searchPath.isNested;
        const pathToRedirectTo = hasNestedRoutes
            ? generatePath(EDITOR_FRAME_ITEM, {
                  menuItem: menuItemId as number,
                  frameItemIndex: subRoute as number,
              })
            : generatePath(EDITOR_MENU_ITEM, {
                  menuItem: menuItemId as number,
              });

        setActiveFrameIndex(subRoute);
        putToSearchedResults && selectSearchResult(result);
        history.push(pathToRedirectTo);
    };

    const handleDeviceGuideNavigation = (
        searchPath: SearchPath,
        stepIndex: number,
        hasNoRedirect?: boolean
    ) => {
        const menuItemId = searchPath.menuItem?.index;
        closeDeviceSetUpModal();
        const pathToRedirectTo = generatePath(EDITOR_MENU_ITEM, {
            menuItem: menuItemId as number,
        });
        if (!hasNoRedirect) {
            closeDeviceGuideSteps();
            history.push(pathToRedirectTo);
        }
        setDeviceGuideStepIndex(stepIndex);
    };

    const refreshCurrentPage = () => {
        history.push("/not_a_valid_path");
        history.goBack();
    };

    return {
        handleSearchNavigation,
        handleDeviceGuideNavigation,
        refreshCurrentPage,
    };
};

export default useNavigation;
