/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import React, { Fragment, useContext, useEffect } from "react";
import { TableTypes } from "../../../../../constants/constants";
import BluetoothViewContext from "../../../../../context/bluetoothView/bluetoothViewContext";
import BluetoothViewToolbar from "./BluetoothViewToolbar";

import Table from "../../../../MuiComponents/Table";
import useTct from "../../../../../utils/useTct";
import usePolling from "../../../../../utils/usePolling";
import Button from "../../../../MuiComponents/Button";

import { ReactComponent as EmptyBluetoothStateIcon } from "../../../../../assets/icons/bluetooth/EmptyBluetoothState.svg";
import { ReactComponent as BluetoothMarkIcon } from "../../../../../assets/icons/bluetooth/BluetoothMark.svg";
import LanguageContext from "../../../../../context/language/languageContext";

export interface BluetoothViewProps {}

const BluetoothView: React.FunctionComponent<BluetoothViewProps> = () => {
    const { searchValue, setBluetoothTotalCount } =
        useContext(BluetoothViewContext);
    const { t } = useContext(LanguageContext);

    const { getBluetoothDevicesAsync } = useTct();

    const { data: bluetoothDevices } = usePolling(
        () => getBluetoothDevicesAsync(),
        5000,
        true
    );

    useEffect(() => {
        setBluetoothTotalCount(bluetoothDevices?.length || 0);
    }, [bluetoothDevices]);

    return (
        <div>
            <BluetoothViewToolbar />
            <div
                css={css({
                    maxWidth: "100%",
                    overflowX: "auto",
                })}
            >
                <Table
                    data={
                        bluetoothDevices?.map((item: any, index: number) => ({
                            ...item,
                            id: index,
                        })) || []
                    }
                    searchValue={searchValue}
                    tableType={TableTypes.Bluetooth}
                    onDeleteClick={() => {}}
                    emptyState={
                        <Fragment>
                            <EmptyBluetoothStateIcon />
                            <div
                                css={css({
                                    fontFamily: "Open Sans",
                                    fontSize: "16px",
                                    fontStyle: "normal",
                                    fontWeight: 600,
                                    lineHeight: "20px", // 125%
                                    letterSpacing: "0.15px",
                                    marginBottom: "8px",
                                })}
                            >
                                {t.BluetoothTableEmptyStateTitle}
                            </div>
                            <div
                                css={css({
                                    fontFamily: "Open Sans",
                                    fontSize: "14px",
                                    fontStyle: "normal",
                                    fontWeight: 400,
                                    lineHeight: "20px", // 142.857%
                                    letterSpacing: "0.1px",
                                    marginBottom: "8px",
                                })}
                            >
                                {t.BluetoothTableEmptyStateDescription}
                            </div>
                            <Button
                                variant="iconLeft"
                                color="primary"
                                onClick={() => {}}
                                size="small"
                                idForTesting="emptyBluetoothState"
                                icon={<BluetoothMarkIcon />}
                            >
                                {t.AddBluetoothDevice}
                            </Button>
                        </Fragment>
                    }
                />
            </div>
        </div>
    );
};

export default BluetoothView;
