/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import { SyntheticEvent, useContext, useState } from "react";
import ThemeContext from "../../../../context/theme/themeContext";
import InputField from "../../../MuiComponents/InputField";
import FormModal from "../../../MuiComponents/Modals/FormModal";

export interface SaveToFotaModalProps {
    isModalOpen: boolean;
    onClose: () => void;
    onSubmit: (e: SyntheticEvent, value: string) => void;
}

const SaveToFotaModal = ({
    isModalOpen,
    onClose,
    onSubmit,
}: SaveToFotaModalProps) => {
    const {
        colors: { textDark },
    } = useContext(ThemeContext);

    const [inputValue, setInputValue] = useState("");

    return (
        <FormModal
            isOpen={isModalOpen}
            onClose={onClose}
            onSubmit={(e: SyntheticEvent) => onSubmit(e, inputValue)}
            title={"Save file as: "}
            secondaryButtonText={"Cancel"}
            primaryButtonText={"Save"}
            isPrimaryButtonDisabled={inputValue.length === 0}
        >
            <InputField
                label={"File name"}
                name={"fileName"}
                fullWidth
                size="medium"
                placeholder={"Enter file name"}
                type="text"
                onChange={(e) => setInputValue(e.target.value)}
                value={inputValue}
                iconRight={
                    <span
                        css={css({
                            color: textDark,
                            fontSize: "14px",
                            fontWeight: "600",
                            lineHeight: "20px",
                            letterSpacing: "0.1px",
                        })}
                    >
                        .cfg
                    </span>
                }
                wrapperStyle={css({
                    scrollMargin: "16px",
                })}
            />
        </FormModal>
    );
};

export default SaveToFotaModal;
