/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/react";
import { Fragment, useEffect } from "react";
import useScript from "../../utils/useScript";

const BlazorApi = () => {
    const addBrotliCompressionToBlazorBoot = () => {
        const script = document.createElement('script');
        script.type = "module"
        script.setAttribute("defer", "");
        script.text = `import { BrotliDecode } from './decode.min.js';

    Blazor.start({
        loadBootResource: function (type, name, defaultUri, integrity) {
            const useBrotli = type !== 'dotnetjs' && location.hostname !== 'localhost' && type !== 'configuration';
            let uriToUse = defaultUri;
            if (useBrotli) {
                uriToUse += '.br';
            }
            
            const loader = window.tctLoader;
            loader?.addFilesToDownload();
            
            const currentFileId = loader?.getFileDownloadStartedCount();
            
            loader?.addFileDownloadStartedCount();
            
            const download = (async function () {
                try {
                    const response = await fetch(uriToUse, { cache: 'no-cache', });
                    if (!response.ok) {
                        throw new Error(response.statusText);
                    }
                    const contentEncoding = response.headers.get('content-encoding');
                    const isCompressed = !!contentEncoding;
            
                    const contentLength = response.headers.get('content-length');
                    const lengthComputable = !!contentLength && !isCompressed;
                    // If the content length is not available, we'll just assume a 5MB file
                    const totalBytes = lengthComputable ? parseInt(contentLength, 10) : 5_000_000;
            
                    let totalContent = new Int8Array(totalBytes);
                    let loadedBytes = 0;
                    const reader = response.body.getReader();
                    while (true) {
                        const { done, value } = await reader.read();
            
                        if (done) {
                            break;
                        }
            
                        totalContent.set(value, loadedBytes);
                        loadedBytes += value.byteLength;
                        if (lengthComputable) {
                            loader?.setFileDownloadProgress(currentFileId, loadedBytes / totalBytes * 100);
                        }
                    }
            
                    if (!lengthComputable) {
                        // Trim the array to the actual length
                        totalContent = totalContent.slice(0, loadedBytes);
                        loader?.setFileDownloadProgress(currentFileId, 100);
                    }
            
                    let decompressedResponseArray = totalContent;
                    if (useBrotli) {
                        decompressedResponseArray = BrotliDecode(totalContent);
                    }
                    const contentType = type === 'dotnetwasm' ? 'application/wasm' : 'application/octet-stream';
                    return new Response(decompressedResponseArray, { headers: { 'content-type': contentType,
                            'content-length': decompressedResponseArray.byteLength } });
                }
                catch (error) {
                    console.error('Error downloading ' + uriToUse, error);
                    throw error;
                }
            })();
            
            // When fetching dotnet.js it expects return type to be a URI to the downloaded file 
            if (type === 'dotnetjs') {
                return uriToUse;
            } else {
                return download;
            }
        }
    });`;

        document.body.appendChild(script);
    };

    useEffect(() => {
        (window as any).tctLoader?.incrementStage();
    }, []);

    // Blazor WebAssembly framework library
    useScript("./_framework/blazor.webassembly.js", addBrotliCompressionToBlazorBoot, false);
    // Web editor components
    useScript("./Components/Editor.razor.js");
    useScript("./Components/ParameterEditor.razor.js");
    useScript("./Components/Translator.razor.js");
    useScript("./Components/Search.razor.js");
    useScript("./Components/TooltipDrawer.razor.js");

    return <Fragment></Fragment>;
}

export default BlazorApi;