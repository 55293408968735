import { createContext } from "react";

interface LanguageContextState {
    setInitialLanguagesData: (data: any) => void;
    setPreviousLanguage: (lang: string) => void;
    changeLanguage: (newLang: string, configurationId: number) => void;
    loading: boolean;
    selectedLanguage: string;
    previousListLanguage: string;
    languages: any[];
    t: any;
}

const LanguageContext = createContext({} as LanguageContextState);

export default LanguageContext;
