/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from "@emotion/react";

import { useContext, useEffect, useRef, useState } from "react";
import ThemeContext from "../../../../context/theme/themeContext";
import SwitchToggle from "./Component/SwitchToggle";
import Component from "./Component";
import Collapse from "@mui/material/Collapse";
import {
    Component as ComponentData,
    GroupBox as GroupBoxData,
} from "../../../../generatedTypes";
import SettingsContext from "../../../../context/settings/settingsContext";
import MenuItemContext from "../../../../context/menuItem/menuItemContext";
import ManualGeofenceContext from "../../../../context/manualGeofence/manualGeofenceContext";
import Divider from "../../../MuiComponents/Divider";

export interface GroupBoxProps {
    data: GroupBoxData;
    lastGroupBox: boolean;
}

const GroupBox = ({ data, lastGroupBox }: GroupBoxProps) => {
    const {
        border,
        topBorder,
        separator,
        name,
        switchId,
        switchValue,
        switchDefaultValue,
        avlId,
        description,
        components,
        disabledTooltip,
        localizationKey,
        switchOffValue,
    } = data;

    const {
        colors: { gray200 },
    } = useContext(ThemeContext);
    const { isExplanatoryTextsHidden } = useContext(SettingsContext);
    const { findParameterValueById } = useContext(MenuItemContext);
    const { setGeozoneDisabled } = useContext(ManualGeofenceContext);

    const switchParameterValue = findParameterValueById(switchId);

    const isTurnedOn =
        switchId === 0 || switchParameterValue !== switchOffValue;

    const [switchedOn, setSwitchedOn] = useState(isTurnedOn);

    useEffect(() => setSwitchedOn(isTurnedOn), [switchParameterValue]);

    const groupBoxRef = useRef(null);

    const handleSwitch = (state: boolean) => setSwitchedOn(state);

    const getBottomMargin = () => {
        if (!components.length) {
            return "0px";
        } else if (border || (!border && switchedOn)) {
            return lastGroupBox ? "0" : "16px";
        } else {
            return lastGroupBox ? "-24px" : "-16px";
        }
    };

    const bottomMargin = getBottomMargin();

    const borderStyle = border ? `1px solid ${gray200}` : "none";

    useEffect(() => {
        if (localizationKey === "Geozone settings" && switchValue !== "1") {
            setGeozoneDisabled(true);
        }
    }, []);

    return (
        <div
            ref={groupBoxRef}
            css={css`
                border: ${borderStyle};
                border-radius: 8px;
                margin-bottom: ${bottomMargin};
                scroll-margin: 16px;
            `}
        >
            {topBorder && (
                <Divider
                    additionalCss={{
                        margin: "16px -24px",
                    }}
                />
            )}

            {(name || switchId !== 0 || description) && (
                <div
                    css={css`
                        padding: ${border
                            ? "24px 24px 16px 24px"
                            : "8px 0 24px 0"};

                        & > p {
                            font-size: 14px;
                            font-weight: 400;
                            line-height: 20px;
                            letter-spacing: 0.1px;
                            margin-top: 8px;
                        }
                    `}
                >
                    <div
                        css={css`
                            display: flex;
                            justify-content: ${name
                                ? "space-between"
                                : "flex-end"};

                            & > h5 {
                                font-size: 16px;
                                font-weight: 600;
                                line-height: 20px;
                                letter-spacing: 0.15px;
                            }
                        `}
                    >
                        {name && <h5>{name}</h5>}

                        {switchValue && (
                            <SwitchToggle
                                id={switchId}
                                avlId={avlId}
                                defaulValue={switchDefaultValue || ""}
                                switchedOn={switchedOn}
                                defaultOffValue={switchOffValue}
                                label={name || ""}
                                elementRef={groupBoxRef}
                                toggle={handleSwitch}
                                dependentParameters={
                                    data.switchComponent
                                        ?.dependentParameterIds || null
                                }
                                disabledTooltip={disabledTooltip}
                                wrapperStyle={css`
                                    margin-left: ${name ? "24px" : "0"};
                                `}
                                customSuccessCallback={() => {
                                    if (
                                        localizationKey === "Geozone settings"
                                    ) {
                                        setGeozoneDisabled(switchedOn);
                                    }
                                    return null;
                                }}
                            />
                        )}
                    </div>

                    {description && !isExplanatoryTextsHidden && (
                        <p>{description}</p>
                    )}
                </div>
            )}

            <Collapse in={switchedOn}>
                {separator && <Divider />}

                {components.length > 0 && (
                    <div
                        css={css`
                            padding: ${border && !!components.length
                                ? "16px 24px 24px"
                                : "0px"};
                        `}
                    >
                        {components.map(
                            (component: ComponentData, i: number) => (
                                <Component
                                    key={component.index}
                                    data={component}
                                    lastComponent={i === components.length - 1}
                                />
                            )
                        )}
                    </div>
                )}
            </Collapse>
        </div>
    );
};

export default GroupBox;
