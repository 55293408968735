/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from "@emotion/react";

import { useContext, ReactNode, MouseEvent } from "react";
import ThemeContext from "../../context/theme/themeContext";
import { ExpandMoreRounded as DropdownIcon } from "@mui/icons-material";

interface BreadcrumbsProps {
    isActive?: boolean;
    isSeparator?: boolean;
    isIconRight?: boolean;
    onClick?: (e: MouseEvent<HTMLDivElement>) => void;
    children: ReactNode;
}

const Breadcrumbs = ({
    isActive,
    isSeparator,
    isIconRight,
    onClick,
    children,
}: BreadcrumbsProps) => {
    const {
        colors: { gray700, textDark, textPlaceholder },
    } = useContext(ThemeContext);

    const handleClick = (e: MouseEvent<HTMLDivElement>) => {
        e.stopPropagation();
        onClick && onClick(e);
    };

    return (
        <div
            css={css({
                display: "inline-flex",
                alignItems: "center",
                fontSize: "14px",
                lineHeight: "20px",
                fontWeight: "600",
                letterSpacing: "0.1px",
                marginRight: "8px",
            })}
        >
            {isSeparator && (
                <span
                    css={css({
                        color: textPlaceholder,
                        marginRight: "8px",
                    })}
                >
                    /
                </span>
            )}

            <span
                onClick={handleClick}
                css={css({
                    display: "flex",
                    alignItems: "center",
                    color: isActive ? textDark : textPlaceholder,
                    cursor: isActive ? "pointer" : "auto",
                    transition: "all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",

                    "& > svg": {
                        fontSize: "16px",
                        marginLeft: "8px",
                    },

                    "&:hover": {
                        color: isActive ? gray700 : textPlaceholder,
                    },
                })}
            >
                {children}

                {isIconRight && <DropdownIcon />}
            </span>
        </div>
    );
};

export default Breadcrumbs;
