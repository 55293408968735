import React from "react";
import useUrlQueryParams from "../../utils/useUrlQueryParams";
import EditorSkeleton from "./EditorSkeleton";
import StartUpSkeleton from "./StartUpSkeleton";
import { EDITOR_MENU_ITEM, EDITOR_STATUS } from "../../constants/routes";
import { generatePath } from "react-router-dom";

const Loader = () => {
    const { pathname } = useUrlQueryParams();

    const editorMenuItems =
        generatePath(EDITOR_MENU_ITEM, {
            menuItem: 0,
        }) + "/";

    const onEditorRoute =
        pathname === EDITOR_STATUS || pathname === editorMenuItems;

    return onEditorRoute ? <EditorSkeleton /> : <StartUpSkeleton />;
};

export default Loader;
