/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/react";
import { useContext } from "react";
import FormModal from "../FormModal";
import LanguageContext from "../../../../context/language/languageContext";
import useApi from "../../../../utils/useApi";
import BundleUpdateContext from "./context/bundleUpdateContext";
import IntroStep from "./items/steps/IntroStep";
import InfoStep from "./items/steps/InfoStep/InfoStep";
import { useSetAtom } from "jotai";
import { FirmwareUpdateResultAtom } from "../../../../context/webSockets/webSocketsState";
import DeviceStatusContext from "../../../../context/deviceStatus/deviceStatusContext";
import { AlertStatus } from "../../../../constants/constants";
import AlertContext from "../../../../context/alert/alertContext";

export interface ProgressBarItem {
    progressBarName: string;
    completedPercent: number;
    status: string;
    stage: string;
    label: string;
    statusText: string;
    details: { label: string; value: string }[];
    dtb?: any;
    state?: string;
    title?: string;
}

const BundleUpdateModal = () => {
    const { selectedLanguage } = useContext(LanguageContext);
    const setFirmwareUpdateResult = useSetAtom(FirmwareUpdateResultAtom);
    const { setError, file } = useContext(BundleUpdateContext);
    const {
        updateBundleModalInfo,
        setUpdateBundleModalInfo,
        setBundleUpdateProgressStates,
    } = useContext(DeviceStatusContext);
    const { setAlert } = useContext(AlertContext);

    const isOpen = updateBundleModalInfo.isOpen;
    const step = updateBundleModalInfo.step;
    const isSuccessful = updateBundleModalInfo.isSuccessful;

    const { postData } = useApi();

    const handleSubmit = async () => {
        if (!file) return;

        try {
            setUpdateBundleModalInfo({
                isOpen: true,
                step: 1,
            });
            await postData(
                `${selectedLanguage}/device/updatefirmware`,
                file.file
            );
            setUpdateBundleModalInfo({
                isSuccessful: true,
                isFailed: false,
            });
            setFirmwareUpdateResult({ isSuccess: true });
        } catch (error: any) {
            console.log("error", error);
            setUpdateBundleModalInfo({
                isSuccessful: false,
                isFailed: true,
            });
            const err = error.response?.data;
            setAlert(AlertStatus.Critical, err.detail);
            setError(err.detail);
        }
    };

    const renderContent = () => {
        if (step === 0) {
            return <IntroStep />;
        }
        return <InfoStep />;
    };

    const isFirmwareUpdateStepSuccessful = isSuccessful;

    const handleClose = () => {
        setUpdateBundleModalInfo({
            isOpen: false,
            step: 0,
            isSuccessful: false,
            isFailed: false,
        });
        setBundleUpdateProgressStates(null, []);
    };

    const isSecondaryButtonDisabled = () => {
        if (step !== 0 && !isFirmwareUpdateStepSuccessful) {
            return true;
        }
        return false;
    };

    const isPrimaryButtonDisabled = () => {
        if (step === 0 && !file) {
            return true;
        }
    };
    return (
        <FormModal
            isOpen={isOpen}
            onClose={step === 0 ? handleClose : undefined}
            onSecondaryButtonClick={
                !isSecondaryButtonDisabled() ? handleClose : undefined
            }
            title="Firmware update"
            description="Select a firmware file and get detailed information outlining the specific parts that need to be updated."
            primaryButtonText={step === 0 ? "Update" : ""}
            secondaryButtonText="Close"
            isPrimaryButtonDisabled={isPrimaryButtonDisabled()}
            isSecondaryButtonDisabled={isSecondaryButtonDisabled()}
            onSubmit={handleSubmit}
            customModalStyles={{ maxWidth: "684px" }}
        >
            {renderContent()}
        </FormModal>
    );
};

export default BundleUpdateModal;
