/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from "@emotion/react";

import { useContext } from "react";
import ThemeContext from "../../../../context/theme/themeContext";
import LanguageContext from "../../../../context/language/languageContext";
import ConfigurationContext from "../../../../context/configuration/configurationContext";
import Checkbox from "../../../MuiComponents/Checkbox";
import RowsPerPage from "./RowsPerPage";
import DisplayedRows from "./DisplayedRows";
import Pagination from "./Pagination";

export interface FooterProps {
    page: number;
    rowsPerPage: number;
    toggleLegacyDevices: () => void;
    changePage: (event: any, newPage: number) => void;
    changeRowsPerPage: (event: any) => void;
}

const Footer = ({
    page,
    rowsPerPage,
    toggleLegacyDevices,
    changePage,
    changeRowsPerPage,
}: FooterProps) => {
    const {
        colors: { gray50, gray100, gray200 },
    } = useContext(ThemeContext);

    const { t } = useContext(LanguageContext);

    const { offlineConfigurations } = useContext(ConfigurationContext);

    const handleCheckbox = (e: React.ChangeEvent<HTMLInputElement>) => {
        toggleLegacyDevices();
        changePage(e, 0);
    };

    return (
        <div
            css={css`
                position: sticky;
                bottom: 0;
                z-index: 3;
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 8px 24px;
                background: ${gray50};
                box-shadow: inset 0px 1px 0px ${gray200};
                border-radius: 0 0 8px 8px;

                @media (max-width: 839px) {
                    background: ${gray100};
                    align-items: flex-start;
                    padding: 8px;
                }

                @media (max-width: 599px) {
                    flex-direction: column;
                    align-items: center;
                }
            `}
        >
            <div
                css={css`
                    @media (max-width: 839px) {
                        margin-top: 6px;
                    }

                    @media (max-width: 599px) {
                        margin-top: 0;
                    }
                `}
            >
                <Checkbox
                    label={t.ShowLegacyDevices}
                    onChange={handleCheckbox}
                />
            </div>

            <div
                css={css`
                    display: flex;
                    align-items: center;
                    margin-left: 16px;

                    @media (max-width: 839px) {
                        flex-direction: column;
                        align-items: flex-end;
                    }

                    @media (max-width: 599px) {
                        margin-left: 0;
                        margin-top: 16px;
                    }
                `}
            >
                <RowsPerPage
                    rowsPerPage={rowsPerPage}
                    change={changeRowsPerPage}
                />

                <div
                    css={css`
                        display: flex;
                        align-items: center;
                    `}
                >
                    <DisplayedRows
                        count={offlineConfigurations.length}
                        page={page}
                        rowsPerPage={rowsPerPage}
                    />

                    <Pagination
                        count={offlineConfigurations.length}
                        page={page}
                        rowsPerPage={rowsPerPage}
                        onPageChange={changePage}
                    />
                </div>
            </div>
        </div>
    );
};

export default Footer;
