/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/react";
import { useContext, useEffect, useState } from "react";
import { saveAs } from "file-saver";
import DeleteIcon from "../../../../../assets/icons/DeleteIcon";
import DownloadIcon from "../../../../../assets/icons/DownloadIcon";
import AlertContext from "../../../../../context/alert/alertContext";
import LanguageContext from "../../../../../context/language/languageContext";
import {
    convertBytesToKilobytes,
    convertUnixTimeToDate,
    getFileNameFromPath,
} from "../../../../../utils/helpers";
import useApi from "../../../../../utils/useApi";
import Dropzone from "../../../../MuiComponents/Dropzone";
import { MenuItem } from "../../../../MuiComponents/Dropzone/MoreActionsMenu";
import { FileInputProps } from "./FileInput";
import { AlertStatus } from "../../../../../constants/constants";

const CertificateFileInput = ({ data }: FileInputProps) => {
    const { selectedLanguage, t } = useContext(LanguageContext);
    const { setAlert } = useContext(AlertContext);

    const { postData, getData } = useApi();

    const [results, setResults] = useState<any>([]);
    const [loading, setLoading] = useState(false);

    const supportedExtensions = data.supportedFormats
        ?.split(",")
        .map((format) => format.trim());

    const getDropzoneResults = async () => {
        try {
            const { data: fileData } = await getData(
                `${selectedLanguage}/command/getcertificatesinfo`
            );
            setResults(
                fileData.map((file: any) => {
                    return {
                        filePath: file.path,
                        fileName: file.fileName,
                        timeStamp: convertUnixTimeToDate(
                            file.timeStamp as number
                        ),
                        fileSize: convertBytesToKilobytes(
                            file.fileSize as number
                        ),
                        isEmpty: file.isEmpty,
                    };
                })
            );
        } catch (error) {
            setResults([]);
        }
    };

    const handleInputChange = async (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        const files = event.target.files as FileList;

        if (!files || files.length === 0) {
            return;
        }

        if (
            supportedExtensions?.every(
                (subString) => event.target.value.indexOf(subString) === -1
            )
        ) {
            setAlert(AlertStatus.Critical, t.WrongFileFormat);
            return;
        }

        const formData = new FormData();

        for (let file of files) {
            formData.append(file.name, file);
        }

        event.target.value = "";
        try {
            setLoading(true);
            await postData(
                `${selectedLanguage}/command/uploadcertificate`,
                formData
            );
            await getDropzoneResults();
            setLoading(false);
        } catch (error) {
            setLoading(false);
        }
    };

    const handleItemDelete = async (filePath?: string) => {
        if (filePath === null) return;
        try {
            await postData(`${selectedLanguage}/command/deletefiles`, [
                filePath,
            ]);
            await getDropzoneResults();
        } catch (error) {
            setAlert(AlertStatus.Critical, "Failed to delete");
        }
    };

    const handleItemDownload = async (filePath?: string) => {
        if (filePath === null) return;
        try {
            const { data: file } = await postData(
                `${selectedLanguage}/command/downloadcertificates`,
                [filePath]
            );
            const blob = new Blob([file], { type: "application/octet-stream" });
            const fileName = getFileNameFromPath(filePath!);
            saveAs(blob, fileName);
        } catch (error) {
            setAlert(AlertStatus.Critical, "Failed to download");
        }
    };

    const menuItems: MenuItem[] = [
        {
            icon: <DownloadIcon />,
            label: "Download",
            onItemClick: handleItemDownload,
        },
        {
            icon: <DeleteIcon />,
            label: "Delete",
            onItemClick: handleItemDelete,
        },
    ];

    useEffect(() => {
        getDropzoneResults();
        // eslint-disable-next-line
    }, []);

    return (
        <Dropzone
            supportedFileFormats={data.supportedFormats || ""}
            label={data.label ?? "Add files"}
            menuItems={menuItems}
            OnInputChange={handleInputChange}
            items={results}
            loading={loading}
            isDropzoneDisabled={results?.length === 3}
        />
    );
};

export default CertificateFileInput;
