export const SET_DEVICE_TYPE = "SET_DEVICE_TYPE";
export const SET_DYNAMIC_SELECT_VALUE = "SET_DYNAMIC_SELECT_VALUE";
export const SET_SECOND_DYNAMIC_SELECT_VALUE =
    "SET_SECOND_DYNAMIC_SELECT_VALUE";
export const SET_THIRD_DYNAMIC_SELECT_VALUE = "SET_THIRD_DYNAMIC_SELECT_VALUE";
export const SET_ACTIVE_PRESETS = "SET_ACTIVE_PRESETS";
export const SET_BLUETOOTH_TABLE_PARAMETER_DATA =
    "SET_BLUETOOTH_TABLE_PARAMETER_DATA";
export const SET_BLUETOOTH_LIST_PARAMETER = "SET_BLUETOOTH_LIST_PARAMETER";
export const SET_BLUETOOTH_LIST_PARAMETERS = "SET_BLUETOOTH_LIST_PARAMETERS";
export const SET_MODAL_BUTTON_DISABLED = "SET_MODAL_BUTTON_DISABLED";
export const SET_BLUETOOTH_TABLE_PARAMETERS_DATA =
    "SET_BLUETOOTH_TABLE_PARAMETERS_DATA";
