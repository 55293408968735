import React, { useReducer } from "react";
import AlertContext from "./alertContext";
import AlertReducer from "./alertReducer";
import { REMOVE_ALERT, SET_ALERT } from "./alertActions";
import { AlertStatus } from "../../constants/constants";

interface AlertStateProps {
    children: React.ReactNode;
}

const AlertState = ({ children }: AlertStateProps) => {
    const initialState = null;

    const [state, dispatch] = useReducer(AlertReducer, initialState);

    const setAlert = (
        type: AlertStatus | undefined,
        title: string | undefined,
        description: string | null = null,
        buttonPrimary: string | null = null,
        handlePrimaryBtnClick: any = null,
        buttonSecondary: string | null = null,
        handleSecondaryBtnClick: any = null
    ) =>
        dispatch({
            type: SET_ALERT,
            payload: {
                type: type?.toLowerCase(),
                title,
                description,
                buttonPrimary,
                handlePrimaryBtnClick,
                buttonSecondary,
                handleSecondaryBtnClick,
            },
        });

    const removeAlert = () =>
        dispatch({
            type: REMOVE_ALERT,
        });

    return (
        <AlertContext.Provider
            value={{
                alert: state,
                setAlert,
                removeAlert,
            }}
        >
            {children}
        </AlertContext.Provider>
    );
};

export default AlertState;
