import React, { Fragment, useContext } from "react";
import Button from "../../../MuiComponents/Button";
import ConfirmModal from "../../../MuiComponents/Modals/ConfirmModal";
import useApi from "../../../../utils/useApi";
import LanguageContext from "../../../../context/language/languageContext";
import LayoutContext from "../../../../context/layout/layoutContext";
import AlertContext from "../../../../context/alert/alertContext";
import { AlertStatus } from "../../../../constants/constants";

export interface GenericCommandButtonProps {
    data: any;
}

const GenericCommandButton = ({ data }: GenericCommandButtonProps) => {
    const [isOpenConfirmation, setIsOpenConfirmation] = React.useState(false);
    const { selectedLanguage, t } = useContext(LanguageContext);
    const { setAlert } = useContext(AlertContext);
    const { layoutData } = useContext(LayoutContext);
    const { postData } = useApi();

    const submitConfirmationModal = async (): Promise<void> => {
        try {
            await postData(`${selectedLanguage}/command/send-generic`, {
                requestId: data.requestId,
                responseAckId: data.responseAckId,
                timeout: data.timeout,
            });
            setIsOpenConfirmation(false);
            setAlert(AlertStatus.Success, t.OemSuccess);
        } catch (error) {
            setIsOpenConfirmation(false);
            setAlert(AlertStatus.Critical, t.OemFailed);
        }
    };

    return (
        <Fragment>
            <Button
                fullWidth
                variant="textOnly"
                size="normal"
                color="primarySubtle"
                onClick={() => setIsOpenConfirmation(true)}
                disabled={!layoutData?.isOnline}
                idForTesting={data.name}
            >
                {data.name}
            </Button>
            <ConfirmModal
                open={isOpenConfirmation}
                close={() => setIsOpenConfirmation(false)}
                submit={submitConfirmationModal}
                title={data.confirmationMessageHeader}
                description={data.confirmationMessageBody}
            />
        </Fragment>
    );
};

export default GenericCommandButton;
