/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import { useContext, useState } from "react";
import {
    FEBadge,
    FEElementType,
    FEParagraph,
    FEText,
    Orientation,
} from "../../../../../../generatedTypes";
import useFeElementParser from "../../../../../../utils/useFeElementParser";
import { Collapse } from "@mui/material";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import ThemeContext from "../../../../../../context/theme/themeContext";
import LanguageContext from "../../../../../../context/language/languageContext";

import UpdateButton from "../../../NotificationsModal/UpdateNotification/UpdateButton";
import UserInfoContext from "../../../../../../context/userInfo/userInfoContext";
import { TctUpdateAvailableAtom } from "../../../../../../context/webSockets/webSocketsState";
import { useAtom } from "jotai";

interface VersionProps {
    element: FEParagraph;
    isOldest: boolean;
    isLatest: boolean;
}

const Version = ({ element, isOldest, isLatest }: VersionProps) => {
    const { t } = useContext(LanguageContext);
    const {
        colors: { gray200 },
    } = useContext(ThemeContext);
    const [{ available }, _] = useAtom(TctUpdateAvailableAtom);
    const { configuratorVersion } = useContext(UserInfoContext);

    const {
        renderFeElement,
        isParagraph,
        isText,
        isBadge,
        isHeader1,
        isHeader2,
    } = useFeElementParser();

    const [expanded, setExpanded] = useState(isLatest);

    const doesParagraphContainBadge = (
        paragraph: FEParagraph,
        badgeText: string
    ) =>
        paragraph.children.find(
            (child) => isBadge(child) && (child as FEBadge).text === badgeText
        );

    const addLatestBadge = (paragraph: FEParagraph) => {
        const latestBadge: FEBadge = {
            type: "badge" as FEElementType,
            text: t.LatestVersion,
            color: "success",
        };
        paragraph.children.push(latestBadge);
        paragraph.orientation = "Horizontal" as Orientation;
    };

    const addCurrentBadge = (paragraph: FEParagraph) => {
        const currentBadge: FEBadge = {
            type: "badge" as FEElementType,
            text: t.YourVersion,
            color: "neutral",
        };
        paragraph.children.push(currentBadge);
        paragraph.orientation = "Horizontal" as Orientation;
    };

    const addBadges = (paragraph: FEParagraph) => {
        if (
            isLatest &&
            !doesParagraphContainBadge(paragraph, t.LatestVersion)
        ) {
            addLatestBadge(paragraph);
        }
        const versionText = paragraph.children.find((child) =>
            isText(child)
        ) as FEText;
        if (
            versionText.text === `TCT ${configuratorVersion}` &&
            !doesParagraphContainBadge(paragraph, t.YourVersion)
        ) {
            addCurrentBadge(paragraph);
        }
    };

    const renderHeaders = (paragraph: FEParagraph) => {
        let header1, header2;

        const header1Index = paragraph.children.findIndex(
            (child) => isParagraph(child) && isHeader1(child as FEParagraph)
        );
        if (header1Index !== -1) {
            header1 = paragraph.children[header1Index] as FEParagraph;
            addBadges(header1);
        }

        const header2Index = paragraph.children.findIndex(
            (child) => isParagraph(child) && isHeader2(child as FEParagraph)
        );
        if (header2Index !== -1) {
            header2 = paragraph.children[header2Index];
        }

        return (
            <div>
                {header1 && renderFeElement(header1)}
                {header2 && renderFeElement(header2)}
            </div>
        );
    };

    const toggleVersionNotes = (
        e: React.MouseEvent<HTMLDivElement, MouseEvent>
    ) => setExpanded(!expanded);

    return (
        <div
            css={css({
                borderBottom: isOldest ? "none" : `1px solid ${gray200}`,
                padding: "16px",
            })}
        >
            <div
                css={css({ display: "flex", justifyContent: "space-between" })}
            >
                {renderHeaders(element)}
                <div
                    css={css({ display: "flex", alignItems: "center", gap: 8 })}
                >
                    {isLatest && available && <UpdateButton />}
                    <div onClick={toggleVersionNotes}>
                        {expanded ? <ExpandLess /> : <ExpandMore />}
                    </div>
                </div>
            </div>
            <Collapse in={expanded}>
                {renderFeElement({
                    ...element,
                    children: element.children.filter(
                        (child) =>
                            isParagraph(child) &&
                            !isHeader1(child as FEParagraph) &&
                            !isHeader2(child as FEParagraph)
                    ),
                } as FEParagraph)}
            </Collapse>
        </div>
    );
};

export default Version;
