/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import { FEElement, FEParagraph } from "../../../../../../generatedTypes";
import Container from "../../../../../Container";
import { useContext, useEffect } from "react";
import SettingsContext from "../../../../../../context/settings/settingsContext";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "../../../../../MuiComponents/Button";
import CloseIcon from "@mui/icons-material/CloseRounded";
import DialogContent from "@mui/material/DialogContent";
import useMediaQuery from "../../../../../../utils/useMediaQueries";
import LanguageContext from "../../../../../../context/language/languageContext";
import Version from "./Version";
import useAutoUpdate from "../../../../../../utils/useAutoUpdate";

const VersionHistoryModal = () => {
    const {
        updateInformation: { releaseInformation },
        isVersionHistoryModalOpen,
        closeVersionHistoryModal,
    } = useContext(SettingsContext);
    const { toMd } = useMediaQuery();
    const { t } = useContext(LanguageContext);
    const { getReleaseNotes } = useAutoUpdate();

    useEffect(() => {
        if (isVersionHistoryModalOpen) {
            getReleaseNotes();
        }
        // eslint-disable-next-line
    }, [isVersionHistoryModalOpen]);

    const renderReleaseInformation = (releaseInformation: FEElement[]) =>
        releaseInformation.map((release, index) => (
            <Version
                key={index}
                element={release as FEParagraph}
                isLatest={index === 0}
                isOldest={index === releaseInformation.length - 1}
            />
        ));

    return (
        <Dialog
            onClose={closeVersionHistoryModal}
            open={isVersionHistoryModalOpen}
            fullScreen={toMd}
            fullWidth
            maxWidth="md920"
        >
            <DialogTitle>
                {t.ReleaseChangeLog}
                <Button
                    variant="iconOnly"
                    size="small"
                    color="white"
                    icon={<CloseIcon onClick={closeVersionHistoryModal} />}
                    css={css({
                        position: "absolute",
                        top: "4px",
                        right: "4px",
                    })}
                    idForTesting="closeVersionHistoryModal"
                />
            </DialogTitle>
            <DialogContent dividers>
                <Container
                    css={css({
                        fontWeight: 600,
                        lineHeight: "20px",
                        letterSpacing: "0.1px",
                    })}
                >
                    {renderReleaseInformation(releaseInformation)}
                </Container>
            </DialogContent>
        </Dialog>
    );
};

export default VersionHistoryModal;
