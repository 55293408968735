/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from "@emotion/react";
import { useContext, useState } from "react";
import FileIcon from "../../../assets/icons/FileIcon";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ThemeContext from "../../../context/theme/themeContext";
import Button from "../Button";
import Divider from "../Divider";
import MoreActionsMenu, { MenuItem } from "./MoreActionsMenu";

export interface ListCardProps {
    fileName: string;
    filePath: string;
    timeStamp: string;
    fileSize: string;
    menuItems: MenuItem[];
    isEmpty?: boolean;
    customButton?: React.JSX.Element;
    customCardContent?: React.JSX.Element;
}

const ListCard = ({
    fileName,
    filePath,
    timeStamp,
    fileSize,
    menuItems,
    customButton,
    customCardContent,
}: ListCardProps) => {
    const {
        colors: { gray200, textDarkSecondary },
    } = useContext(ThemeContext);

    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event: any) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <div
            css={css({
                border: `1px solid ${gray200}`,
                padding: "16px",
                marginTop: "10px",
                borderRadius: "8px",
                color: textDarkSecondary,
                fontWeight: 600,
            })}
        >
            <div
                css={css({
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginBottom: "16px",
                })}
            >
                <div css={css({ display: "flex", alignItems: "center" })}>
                    <FileIcon />
                    <div css={css({ marginLeft: "12px" })}>{fileName}</div>
                </div>
                <div>
                    {!!customButton && customButton}
                    {!customButton && (
                        <Button
                            variant="iconOnly"
                            size="small"
                            color="primarySubtle"
                            icon={<MoreVertIcon />}
                            onClick={handleClick}
                            idForTesting="more-actions-button"
                        />
                    )}
                    <MoreActionsMenu
                        anchorEl={anchorEl}
                        isOpen={Boolean(anchorEl)}
                        onClose={handleClose}
                        menuItems={menuItems.map((item) => {
                            return {
                                icon: item.icon,
                                label: item.label,
                                onItemClick: () => item.onItemClick(filePath),
                            };
                        })}
                    />
                </div>
            </div>
            <Divider />
            {timeStamp && (
                <div css={css({ marginTop: "16px" })}>Added: {timeStamp}</div>
            )}
            {fileSize && (
                <div css={css({ marginTop: "4px", marginBottom: "6px" })}>
                    Size: {fileSize}
                </div>
            )}
            {!!customCardContent && customCardContent}
        </div>
    );
};

export default ListCard;
