import { Menu, MenuItem } from "@mui/material";
import React from "react";

export type MenuItem = {
    label: string;
    onItemClick: (value?: string) => Promise<void>;
    icon: any;
};
export interface MoreActionsMenuProps {
    anchorEl: any;
    isOpen: boolean;
    onClose: () => void;
    menuItems: MenuItem[];
}

const MoreActionsMenu = ({
    anchorEl,
    isOpen,
    onClose,
    menuItems,
}: MoreActionsMenuProps) => {
    return (
        <Menu
            anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
            }}
            transformOrigin={{
                vertical: -8,
                horizontal: "right",
            }}
            anchorEl={anchorEl}
            open={isOpen}
            onClose={onClose}
            sx={{
                "& .MuiPaper-root": {
                    maxWidth: "215px",
                    width: "100%",
                    svg: {
                        fontSize: "14px",
                        marginRight: "12px",
                    },
                },
            }}
        >
            {menuItems?.map((item, index) => (
                <MenuItem key={index} onClick={() => item.onItemClick()}>
                    {item.icon}
                    {item.label}
                </MenuItem>
            ))}
        </Menu>
    );
};

export default MoreActionsMenu;
