/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from "@emotion/react";

import { useContext } from "react";
import ThemeContext from "../../../../context/theme/themeContext";
import LanguageContext from "../../../../context/language/languageContext";

export interface FooterProps {
    count: number;
    page: number;
    rowsPerPage: number;
}

const DisplayedRows = ({ count, page, rowsPerPage }: FooterProps) => {
    const {
        colors: { textIconDark },
    } = useContext(ThemeContext);

    const { t } = useContext(LanguageContext);

    const from = page * rowsPerPage + 1;

    const lastPage = page >= Math.ceil(count / rowsPerPage) - 1;
    const toNotLast = page * rowsPerPage + rowsPerPage;
    const toLast = count;
    const to = lastPage ? toLast : toNotLast;

    const pageTranslation = t.PageOfPages.replace("{page}", `${from}-${to}`);
    const finalTranslation = pageTranslation.replace("{pages}", count);

    return (
        <div
            css={css`
                color: ${textIconDark};
                font-weight: 600;
                line-height: 20px;
                letter-spacing: 0.1px;
                white-space: nowrap;
            `}
        >
            {finalTranslation}
        </div>
    );
};

export default DisplayedRows;
