/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from "@emotion/react";

import { useContext } from "react";
import ThemeContext from "../../../context/theme/themeContext";
import Box from "../../Box";
import Divider from "../Divider";

export interface CardProps {
    children: React.ReactNode;
    title?: string;
    description?: string;
    action?: React.ReactNode;
    divider?: boolean;
    prefix?: string;
    isSection?: boolean;
}
const Card = ({
    children,
    title,
    description,
    action,
    divider,
    prefix,
    isSection,
}: CardProps) => {
    const {
        colors: { textDark },
    } = useContext(ThemeContext);

    return (
        <Box>
            <div
                css={css`
                    padding: 24px 24px 0 24px;
                `}
            >
                <div
                    css={css`
                        display: flex;
                        justify-content: space-between;
                        align-items: center;

                        h4 {
                            display: flex;
                            align-items: center;
                            color: ${textDark};
                            font-size: ${isSection ? "16px" : "20px"};
                            line-height: 24px;
                            font-weight: 600px;
                            letter-spacing: 0.15px;
                        }
                    `}
                >
                    {title && (
                        <h4>
                            {prefix && (
                                <span
                                    css={css`
                                        display: flex;
                                        align-items: center;
                                        margin-right: 8px;
                                    `}
                                >
                                    {prefix}
                                </span>
                            )}

                            {title}
                        </h4>
                    )}

                    {action ? action : ""}
                </div>

                {description && (
                    <p
                        css={css`
                            color: ${textDark};
                            font-size: 14px;
                            line-height: 20px;
                            letter-spacing: 0.1px;
                            margin-top: 12px;
                            margin-left: ${prefix ? "32px" : "0"};
                        `}
                    >
                        {description}
                    </p>
                )}
            </div>

            {divider && (
                <Divider
                    additionalCss={{
                        marginTop: "16px",
                    }}
                    hasLighterGray
                />
            )}

            <div
                css={css({
                    padding: "16px 24px 24px",
                })}
            >
                {children}
            </div>
        </Box>
    );
};

export default Card;
