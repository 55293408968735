export const FILTER_COLUMNS_BY_NAME = "FILTER_COLUMNS_BY_NAME";
export const SET_FILTERS = "SET_FILTERS";
export const SET_FILTER = "SET_FILTER";
export const SET_PRIORITY = "SET_PRIORITY";
export const SET_OPERAND = "SET_OPERAND";
export const RESET_ALL_FILTERS = "RESET_ALL_FILTERS";
export const RESET_FILTER = "RESET_FILTER";
export const RESET_PRIORITY = "RESET_PRIORITY";
export const RESET_OPERAND = "RESET_OPERAND";
export const SET_ACTIVE_STATUS = "SET_ACTIVE_STATUS";
export const SET_SEARCH_VALUE = "SET_SEARCH_VALUE";
export const RESET_COLUMN = "RESET_COLUMN";
export const RESET_COLUMNS = "RESET_COLUMNS";
export const SET_COLUMNS = "SET_COLUMNS";
