/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/react";
import { Fragment, useContext, useState } from "react";
import AlertContext from "../../../../../../context/alert/alertContext";
import FeaturesContext from "../../../../../../context/features/featuresContext";
import { ParameterValue } from "../../../../../../context/features/FeaturesState";
import LanguageContext from "../../../../../../context/language/languageContext";
import LayoutContext from "../../../../../../context/layout/layoutContext";
import { BadgeColors } from "../../../../../MuiComponents/Badge";
import CardSection from "../CardSection";
import FormModal from "../../../../../MuiComponents/Modals/FormModal";
import TypedGrid from "../../../../../MuiComponents/TypedGrid";
import DaylightSettingsCard from "./DaylightSettingsCard";
import WeeklyScheduleCard from "./WeeklyScheduleCard";
import { Schedule } from "../../../../../../generatedTypes";
import useMediaQueries from "../../../../../../utils/useMediaQueries";
import useTct from "../../../../../../utils/useTct";
import { AlertStatus } from "../../../../../../constants/constants";

export interface ScheduleModalSectionProps {
    data: Schedule;
}

const ScheduleModalSection = ({ data }: ScheduleModalSectionProps) => {
    const { t } = useContext(LanguageContext);
    const { layoutData } = useContext(LayoutContext);
    const { setAlert } = useContext(AlertContext);
    const {
        parametersValues,
        submitParameterValues,
        findDefaultParameterValueById,
    } = useContext(FeaturesContext);

    const { updateParametersAsync } = useTct();
    const { toSm } = useMediaQueries();

    const [isLoading, setLoading] = useState(false);
    const [isModalOpen, setOpenModal] = useState(false);
    const [frameData, setFrameData] = useState(data);

    const handleOpenModal = () => setOpenModal(true);
    const handleCloseModal = () => setOpenModal(false);
    const hasMonday =
        findDefaultParameterValueById(
            frameData.weekdays[0].status!.parameterId
        ) !== "0";
    const hasTuesday =
        findDefaultParameterValueById(
            frameData.weekdays[1].status!.parameterId
        ) !== "0";
    const hasWednesday =
        findDefaultParameterValueById(
            frameData.weekdays[2].status!.parameterId
        ) !== "0";
    const hasThursday =
        findDefaultParameterValueById(
            frameData.weekdays[3].status!.parameterId
        ) !== "0";
    const hasFriday =
        findDefaultParameterValueById(
            frameData.weekdays[4].status!.parameterId
        ) !== "0";
    const hasSaturday =
        findDefaultParameterValueById(
            frameData.weekdays[5].status!.parameterId
        ) !== "0";
    const hasSunday =
        findDefaultParameterValueById(
            frameData.weekdays[6].status!.parameterId
        ) !== "0";

    const isWeeklyScheduleSet =
        hasMonday ||
        hasTuesday ||
        hasWednesday ||
        hasThursday ||
        hasFriday ||
        hasSaturday ||
        hasSunday;

    const isDaylightSettingsOn =
        (findDefaultParameterValueById(
            frameData.settings!.daylight!.parameterId
        ) !== "0" &&
            findDefaultParameterValueById(
                frameData.settings!.daylight!.parameterId
            ) !== undefined) ||
        frameData.settings!.daylight!.parameterValue !== "0";

    const formatedDataForCardSection = {
        name: frameData.name,
        modalName: frameData.modalName,
        components: [
            {
                index: 0,
                parameterId: 0,
                label: "Weekly schedule",
                badgeText: isWeeklyScheduleSet ? t.Set : t.NotSet,
                badgeColor: (isWeeklyScheduleSet
                    ? "success"
                    : "neutral") as BadgeColors,
            },
            {
                index: 1,
                parameterId: 0,
                label: "Daylight saving settings",
                badgeText: isDaylightSettingsOn ? "On" : "Off",
                badgeColor: (isDaylightSettingsOn
                    ? "success"
                    : "neutral") as BadgeColors,
            },
        ],
    };
    const handleSubmitForm = async (e: React.FormEvent<HTMLFormElement>) => {
        if (parametersValues.length > 0) {
            e.preventDefault();
            const payload = parametersValues.map(
                (parameter: ParameterValue) => ({
                    id: parameter.parameterId,
                    value: String(parameter.value),
                })
            );
            try {
                setLoading(true);
                await updateParametersAsync(layoutData?.id || 0, payload);
                submitParameterValues();
                setOpenModal(false);
                setAlert(AlertStatus.Success, "Updated successfully");
            } catch (error) {
                setFrameData(data);
                setAlert(AlertStatus.Critical, "Failed to update");
            }

            setLoading(false);
        }
        setOpenModal(false);
    };

    return (
        <Fragment>
            <CardSection
                data={formatedDataForCardSection}
                onButtonClick={handleOpenModal}
            />
            <FormModal
                isOpen={isModalOpen}
                onClose={handleCloseModal}
                onSubmit={handleSubmitForm}
                title={frameData.name || ""}
                description={frameData.description}
                secondaryButtonText={"Cancel"}
                primaryButtonText={"Save"}
                isSubmitting={isLoading}
                customModalStyles={{
                    maxWidth: "920px",
                    minWidth: toSm ? "100vw" : "unset",
                    minHeight: toSm ? "100vh" : "unset",
                }}
            >
                <TypedGrid container spacing={3}>
                    <TypedGrid item sm={6} xs0={12}>
                        <WeeklyScheduleCard
                            weekdays={frameData.weekdays}
                            timeZone={frameData.settings!.timeZone!}
                        />
                    </TypedGrid>
                    <TypedGrid item sm={6} xs0={12}>
                        <DaylightSettingsCard
                            daylight={frameData.settings!.daylight!}
                            startDateSettings={frameData.startSettings}
                            endDateSettings={frameData.endSettings}
                        />
                    </TypedGrid>
                </TypedGrid>
            </FormModal>
        </Fragment>
    );
};

export default ScheduleModalSection;
