import {
    REMOVE_EDITABLE_INPUT_BY_ID,
    REMOVE_PARAMETER_VALUE_BY_ID,
    RESET_DEFAULT_VALUES,
    RESET_EDITABLE_INPUTS,
    SET_ECOCALCULATOR_VALUES,
    SET_EDITABLE_INPUT,
    SET_PARAMETER_VALUE,
    SET_PARAMETER_VALUES,
    SUBMIT_PARAMETER_VALUE,
    SUBMIT_PARAMETER_VALUES,
} from "./featuresActions";
import {
    EcoCalculatorValues,
    FeaturesInitialState,
    ParameterValue,
} from "./FeaturesState";

type Action =
    | {
          type: typeof SET_PARAMETER_VALUES;
          payload: ParameterValue[];
      }
    | {
          type: typeof SET_PARAMETER_VALUE;
          payload: ParameterValue;
      }
    | {
          type: typeof SUBMIT_PARAMETER_VALUES;
      }
    | {
          type: typeof SUBMIT_PARAMETER_VALUE;
          payload: ParameterValue;
      }
    | {
          type: typeof REMOVE_PARAMETER_VALUE_BY_ID;
          payload: number;
      }
    | {
          type: typeof REMOVE_EDITABLE_INPUT_BY_ID;
          payload: number;
      }
    | {
          type: typeof SET_EDITABLE_INPUT;
          payload: number;
      }
    | {
          type: typeof RESET_EDITABLE_INPUTS;
      }
    | {
          type: typeof SET_ECOCALCULATOR_VALUES;
          payload: EcoCalculatorValues | null;
      }
    | {
          type: typeof RESET_DEFAULT_VALUES;
      };

export default (state: FeaturesInitialState, action: Action) => {
    switch (action.type) {
        case SET_PARAMETER_VALUES:
            return {
                ...state,
                parametersValues: [...action.payload],
            };
        case SET_PARAMETER_VALUE:
            return {
                ...state,
                parametersValues: [
                    ...state.parametersValues.filter(
                        (item) =>
                            item.parameterId !== action.payload.parameterId
                    ),
                    action.payload,
                ],
            };
        case SUBMIT_PARAMETER_VALUES:
            return {
                ...state,
                defaultParameterValues: [...state.parametersValues],
            };
        case SUBMIT_PARAMETER_VALUE:
            return {
                ...state,
                defaultParameterValues: [
                    ...state.parametersValues.filter(
                        (item) =>
                            item.parameterId !== action.payload.parameterId
                    ),
                    action.payload,
                ],
            };
        case REMOVE_PARAMETER_VALUE_BY_ID:
            return {
                ...state,
                parametersValues: [
                    ...state.parametersValues.filter(
                        (item) => item.parameterId !== action.payload
                    ),
                ],
            };
        case REMOVE_EDITABLE_INPUT_BY_ID:
            return {
                ...state,
                editableInputs: [
                    ...state.editableInputs.filter(
                        (item) => item !== action.payload
                    ),
                ],
            };
        case SET_EDITABLE_INPUT:
            return {
                ...state,
                editableInputs: [
                    ...state.editableInputs.filter(
                        (item: number) => item !== action.payload
                    ),
                    action.payload,
                ],
            };
        case RESET_EDITABLE_INPUTS:
            return {
                ...state,
                editableInputs: [],
            };
        case RESET_DEFAULT_VALUES:
            return {
                ...state,
                defaultParameterValues: [],
            };
        case SET_ECOCALCULATOR_VALUES:
            return {
                ...state,
                ecoCalculatorValues: action.payload,
            };
        default:
            return state;
    }
};
