import { SET_ALERT, REMOVE_ALERT } from "./alertActions";

export interface AlertState {
    type: string;
    title: string;
    description: string | null;
    buttonPrimary: string | null;
    handlePrimaryBtnClick: any;
    buttonSecondary: string | null;
    handleSecondaryBtnClick: any;
}

export default (state: AlertState | null, action: any) => {
    switch (action.type) {
        case SET_ALERT:
            return action.payload;
        case REMOVE_ALERT:
            return null;
        default:
            return state;
    }
};
