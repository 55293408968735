/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import { useContext } from "react";
import ThemeContext from "../../context/theme/themeContext";

export interface LengthBubbleProps {
    children: any;
    bottomPosition?: number;
    leftPosition: number;
    topPosition?: number;
    isHidden?: boolean;
}

const LengthBubble = ({
    children,
    bottomPosition,
    leftPosition,
    topPosition,
    isHidden,
}: LengthBubbleProps) => {
    const {
        colors: { red600, textWhite },
    } = useContext(ThemeContext);
    if (isHidden) {
        return null;
    }
    return (
        <span
            css={css({
                position: "relative",
                fontSize: "10px",
                backgroundColor: red600,
                color: textWhite,
                borderRadius: "50%",
                width: "12px",
                height: "12px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                bottom: `${bottomPosition}px`,
                left: `${leftPosition}px`,
                top: `${topPosition}px`,
                zIndex: 1,
            })}
        >
            {children}
        </span>
    );
};

export default LengthBubble;
