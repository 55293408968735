import {
    FILTER_CONFIGURATIONS,
    SEARCH_CONFIGURATIONS,
    SET_EXPERIMENTAL_CONFIGURATIONS,
    SET_EXPERIMENTAL_MODAL_OPEN,
    SET_EXPERIMENTAL_MODAL_VALUE,
    SET_OFFLINE_CONFIGURATIONS,
    TOGGLE_LEGACY_CONFIGURATIONS,
} from "./configurationActions";

const getUpdatedConfigurations = (
    state: any,
    showLegacy: boolean,
    filteredValues: any[],
    searchValue: string
) => {
    const configurations = showLegacy ? state.allOffline : state.modernOffline;

    const filterConfigurations = () => {
        const technologiesExist = (configTechnologies: any[]) =>
            filteredValues.every((filter) =>
                configTechnologies.includes(filter)
            );

        if (filteredValues.length > 0) {
            return configurations.filter((config: any) =>
                technologiesExist(config.technologies)
            );
        } else {
            return configurations;
        }
    };

    const getConfigurationsContainingSearchValue = (
        inputConfigurations: any
    ) => {
        const substringToFind = searchValue.toLowerCase();
        return inputConfigurations.filter((config: any) =>
            config.fmType.toLowerCase().includes(substringToFind)
        );
    };

    const filteredConfigurations = filterConfigurations();

    return getConfigurationsContainingSearchValue(filteredConfigurations);
};

export default (state: any, action: any): any => {
    switch (action.type) {
        case SET_OFFLINE_CONFIGURATIONS: {
            const allConfigurations = action.payload.hardwareVersionTypes;

            const notEmpty = allConfigurations.length > 0;

            const allTechnologies = notEmpty
                ? action.payload.technologyPriorities
                : [];

            const onlyLatest = allConfigurations.filter(
                (configuration: any) => !configuration.legacy
            );
            let displayed = onlyLatest;

            if (
                (state.searchValue && state.searchValue.length > 0) ||
                state.filteredValues?.length > 0 ||
                state.legacy
            ) {
                const tempState = {
                    ...state,
                    allOffline: allConfigurations,
                    modernOffline: onlyLatest,
                    displayedOffline: onlyLatest,
                    technologies: allTechnologies,
                    loading: false,
                };
                displayed = getUpdatedConfigurations(
                    tempState,
                    tempState.legacy,
                    tempState.filteredValues,
                    tempState.searchValue
                );
            }

            return {
                ...state,
                allOffline: allConfigurations,
                modernOffline: onlyLatest,
                displayedOffline: displayed,
                technologies: allTechnologies,
                loading: false,
            };
        }
        case SET_EXPERIMENTAL_CONFIGURATIONS: {
            const experimentalConfigurations = action.payload;
            return {
                ...state,
                experimentalConfigurations,
            };
        }
        case TOGGLE_LEGACY_CONFIGURATIONS: {
            const showLegacy = action.payload;

            const updatedOffline = getUpdatedConfigurations(
                state,
                showLegacy,
                state.filteredValues,
                state.searchValue
            );

            return {
                ...state,
                displayedOffline: updatedOffline,
                legacy: showLegacy,
            };
        }
        case FILTER_CONFIGURATIONS: {
            const values = action.payload;

            const updatedOffline = getUpdatedConfigurations(
                state,
                state.legacy,
                values,
                state.searchValue
            );

            return {
                ...state,
                displayedOffline: updatedOffline,
                filteredValues: values,
            };
        }
        case SEARCH_CONFIGURATIONS: {
            const value = action.payload;

            const updatedOffline = getUpdatedConfigurations(
                state,
                state.legacy,
                state.filteredValues,
                value
            );

            return {
                ...state,
                displayedOffline: updatedOffline,
                searchValue: value,
            };
        }
        case SET_EXPERIMENTAL_MODAL_OPEN: {
            return {
                ...state,
                isExperimentalModalOpen: action.payload.isOpen,
                hasExperimentalModalClose: !!action.payload.hasClose,
            };
        }
        case SET_EXPERIMENTAL_MODAL_VALUE: {
            return {
                ...state,
                experimentalModalValue: action.payload,
            };
        }
        default: {
            return state;
        }
    }
};
