/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/react";
import { Grid } from "@mui/material";
import { Fragment } from "react";
import { Column, TableControl } from "../../../../../generatedTypes";
import useMediaQuery from "../../../../../utils/useMediaQueries";
import InputsColumn from "./Columns/InputsColumn";
import UnitsColumn from "./Columns/UnitsColumn";
import { ActiveGsmDataModeAtom } from "../../../../../context/webSockets/webSocketsState";
import { useAtom } from "jotai";

const MultiColTable = ({ data }: { data: TableControl }) => {
    const { columns } = data;
    const { toXl } = useMediaQuery();

    const isDesktopView = !toXl;

    const [activeColumnIndex] = useAtom(ActiveGsmDataModeAtom);
    const isActiveColumn = (dataModeIndex: number) => {
        //Ignore empty columns
        if (dataModeIndex === 0 || dataModeIndex === 4) {
            return false;
        }
        if (dataModeIndex < 4 && dataModeIndex === activeColumnIndex) {
            return true;
        }
        if (dataModeIndex > 4 && dataModeIndex - 1 === activeColumnIndex) {
            return true;
        }
        return false;
    };

    const renderColumn = (i: number, column: Column) => {
        if (isDesktopView && i === 0) {
            return <UnitsColumn column={column} />;
        }
        if (!isDesktopView && i === 0) {
            return null;
        }
        return (
            <Fragment>
                {!isDesktopView && <UnitsColumn column={columns[0]} />}
                <InputsColumn
                    column={column}
                    isActive={isActiveColumn(column.dataModeIndex)}
                />
            </Fragment>
        );
    };

    return (
        <Grid container columnSpacing={2} justifyContent="space-between">
            {columns.map((column, i) => (
                <Fragment key={i}>{renderColumn(i, column)}</Fragment>
            ))}
        </Grid>
    );
};

export default MultiColTable;
