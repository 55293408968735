export const SET_ACTIVE_TAB_ID = "SET_ACTIVE_TAB_ID";
export const SET_IS_OPEN_CONFIRMATION_MODAL = "SET_IS_OPEN_CONFIRMATION_MODAL";
export const SET_TERMINAL_SIZE = "SET_TERMINAL_SIZE";
export const SET_PORT_LIST_ANCHOR_EL = "SET_PORT_LIST_ANCHOR_EL";
export const SET_DEVICES = "SET_DEVICES";
export const SET_SINGLE_PORT_LIST_ANCHOR_EL = "SET_SINGLE_PORT_LIST_ANCHOR_EL";
export const SET_ACTIVE_PORTS_FOR_DROPDOWN = "SET_ACTIVE_PORTS_FOR_DROPDOWN";
export const SET_ACTIVE_TERMINAL_PORT = "SET_ACTIVE_TERMINAL_PORT";
export const REMOVE_ACTIVE_TERMINAL_PORT = "REMOVE_ACTIVE_TERMINAL_PORT";
export const SET_REMOVE_PORT_MODAL_INFO = "SET_REMOVE_PORT_MODAL_INFO";
export const REMOVE_ALL_ACTIVE_TERMINAL_PORTS =
    "REMOVE_ALL_ACTIVE_TERMINAL_PORTS";
export const SET_PORT_MESSAGES = "SET_PORT_MESSAGES";
export const SET_PORT_KEEP_FOLDER = "SET_PORT_KEEP_FOLDER";
export const SET_PORT_IS_LOGGING = "SET_PORT_IS_LOGGING";
export const SET_COMMAND = "SET_COMMAND";
export const SET_COMMAND_HISTORY_INDEX = "SET_COMMAND_HISTORY_INDEX";
export const SET_COMMAND_HISTORY = "SET_COMMAND_HISTORY";
export const CLEAR_PORT_MESSAGES_BY_PORT_ID = "CLEAR_PORT_MESSAGES_BY_PORT_ID";
