/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import { Fragment, useContext, useState } from "react";
import TypedGrid from "../../../../../MuiComponents/TypedGrid";
import NumberInput from "./NumberInput";
import ThemeContext from "../../../../../../context/theme/themeContext";
import { Column } from "../../../../../../generatedTypes";
import ErrorIcon from "@mui/icons-material/ErrorRounded";
import { FormHelperText } from "@mui/material";

export interface InputsColumnProps {
    column: Column;
    isActive: boolean;
}

export interface Error {
    parameterId: number;
    error: string;
}

const InputsColumn = ({ column, isActive }: InputsColumnProps) => {
    const {
        colors: { textColorTitle, white, blue700, blue100, red700 },
    } = useContext(ThemeContext);

    const activeStyles = css`
        border: 1px solid ${blue700};
        background-color: ${blue700};
        border-radius: 6px 6px 0px 0px;
    `;
    const [errors, setErrors] = useState<Error[]>([]);

    const addError = (e: { id: number; error: string | null }): void => {
        let errorsCopy = errors.filter((error) => error.parameterId !== e.id);

        if (!e.error) {
            setErrors(errorsCopy);

            return;
        }

        setErrors([
            ...errorsCopy,
            { parameterId: e.id, error: String(e.error) },
        ]);
    };

    const renderColumnName = () => {
        return (
            <div
                css={css({
                    display: "flex",
                    alignItems: "center",
                })}
            >
                {column.name}
            </div>
        );
    };

    const renderError = () => {
        return (
            <Fragment>
                <FormHelperText>
                    <ErrorIcon
                        css={css({
                            color: red700,
                            fontSize: "16px",
                            marginRight: "4px",
                        })}
                    />
                    {errors[errors.length - 1].error}
                </FormHelperText>
            </Fragment>
        );
    };

    return (
        <TypedGrid item xl={3} md={6} sm={6} xs0={8}>
            <div
                css={css`
                    display: flex;
                    flex-direction: column;
                    ${isActive && activeStyles}
                `}
            >
                <div
                    css={css`
                        color: ${isActive ? white : textColorTitle};
                        font-weight: 600;
                        margin: 3px;
                        line-height: 20px;
                        justify-content: space-between;
                        display: flex;
                        padding: ${isActive ? "2px 4px" : "4px 4px"};
                    `}
                >
                    {renderColumnName()}
                    {isActive && (
                        <span
                            css={css`
                                background-color: ${blue100};
                                border-radius: 4px;
                                color: ${blue700};
                                font-weight: 600;
                                font-size: 12px;
                                line-height: 16px;
                                letter-spacing: 0.4px;
                                width: 38px;
                                height: 16px;
                                left: 4px;
                                top: 2px;
                                padding: 2px 4px;
                            `}
                        >
                            Active
                        </span>
                    )}
                </div>
                {column.components.map((component, i) => {
                    return (
                        <NumberInput
                            key={component.index}
                            data={component}
                            isFirstColumnElement={i === 0}
                            isLastColumnElement={
                                i === column.components.length - 1
                            }
                            addError={addError}
                            hasNoTopBorder={i === 1}
                            hasNoBottomBorder={
                                i !== 0 && i !== column.components.length - 1
                            }
                        />
                    );
                })}
                {errors.length > 0 ? renderError() : ""}
            </div>
        </TypedGrid>
    );
};

export default InputsColumn;
