import React, { useContext, useReducer } from "react";
import {
    BluetoothTableColumns,
    BluetoothTableColumnsTitles,
    BluetoothTableStatuses,
} from "../../constants/constants";
import useApi from "../../utils/useApi";
import {
    FILTER_COLUMNS_BY_NAME,
    RESET_BLUETOOTH_VIEW,
    RESET_COLUMN,
    RESET_COLUMNS,
    SET_ACTIVE_STATUS_FILTER,
    SET_ADD_DEVICE_MODAL,
    SET_BLUETOOTH_DATA,
    SET_BLUETOOTH_MODAL_NAME,
    SET_BLUETOOTH_MODAL_PRESET,
    SET_BLUETOOTH_MODAL_TABLE,
    SET_BLUETOOTH_TOTAL_COUNT,
    SET_EDIT_DEVICE_MODAL,
    SET_MODAL_DATA,
    SET_RELOAD_BLUETOOTH_VIEW,
    SET_SUBMIT_DISABLED,
    SET_TABLE_SEARCH_VALUE,
} from "./bluetoothViewActions";
import BluetoothViewContext from "./bluetoothViewContext";
import BluetoothViewReducer from "./bluetoothViewReducer";
import LanguageContext from "../language/languageContext";
import useTct from "../../utils/useTct";

interface BluetoothViewStateProps {
    children: React.ReactNode;
}

const BluetoothViewState = ({ children }: BluetoothViewStateProps) => {
    const { t } = useContext(LanguageContext);
    const { getBluetoothModalDataAsync } = useTct();

    const defaultColumns = [
        BluetoothTableColumns.deviceName,
        BluetoothTableColumns.status,
        BluetoothTableColumns.macAddress,
        BluetoothTableColumns.deviceType,
    ];

    const initialState = {
        columns: [
            {
                name: t.DeviceType,
                value: BluetoothTableColumns.deviceName,
                isDisabled: true,
            },
            {
                name: BluetoothTableColumnsTitles.status,
                value: BluetoothTableColumns.status,
                isDisabled: true,
            },
            {
                name: BluetoothTableColumnsTitles.macAddress,
                value: BluetoothTableColumns.macAddress,
            },
            {
                name: BluetoothTableColumnsTitles.deviceType,
                value: BluetoothTableColumns.deviceType,
            },
        ],
        activeColumns: defaultColumns,
        bluetoothData: null,
        bluetoothModalTable: null,
        bluetoothModalPreset: null,
        isSubmitDisabled: false,
        activeStatusFilter: BluetoothTableStatuses.AllStatuses,
        searchValue: "",
        bluetoothModalName: "",
        modalData: null,
        isAddDeviceModalOpen: false,
        isEditDeviceModalOpen: false,
        reloadBluetoothView: true, // so that it would fetch data initiialy
        bluetoothTotalCount: 0,
    };

    const [state, dispatch] = useReducer(BluetoothViewReducer, initialState);

    const { getData } = useApi();

    const filterColumnsByName = (columnName: string) => {
        dispatch({
            type: FILTER_COLUMNS_BY_NAME,
            payload: columnName,
        });
    };

    const resetBluetoothView = () => {
        dispatch({
            type: RESET_BLUETOOTH_VIEW,
        });
    };

    const resetColumn = (columnName: string) => {
        dispatch({
            type: RESET_COLUMN,
            payload: columnName,
        });
    };
    const resetColumnsToDefault = () => {
        dispatch({
            type: RESET_COLUMNS,
            payload: defaultColumns,
        });
    };
    const setBluetoothData = (data: any) => {
        dispatch({
            type: SET_BLUETOOTH_DATA,
            payload: data,
        });
    };
    const setBluetoothModalTable = (data: any) => {
        dispatch({
            type: SET_BLUETOOTH_MODAL_TABLE,
            payload: data,
        });
    };

    const setBluetoothModalPreset = (data: any) => {
        dispatch({
            type: SET_BLUETOOTH_MODAL_PRESET,
            payload: data,
        });
    };
    const setSubmitDisabled = (disabled: boolean) => {
        dispatch({
            type: SET_SUBMIT_DISABLED,
            payload: disabled,
        });
    };
    const setBluetoothTableStatusFilter = (data: any) => {
        dispatch({
            type: SET_ACTIVE_STATUS_FILTER,
            payload: data,
        });
    };

    const setTableSearchValue = (data: any) => {
        dispatch({
            type: SET_TABLE_SEARCH_VALUE,
            payload: data,
        });
    };

    const setBluetoothModalName = (data: any) => {
        dispatch({
            type: SET_BLUETOOTH_MODAL_NAME,
            payload: data,
        });
    };

    const setModalData = (data: any) => {
        dispatch({
            type: SET_MODAL_DATA,
            payload: data,
        });
    };

    const setAddDeviceModal = (data: boolean) => {
        dispatch({
            type: SET_ADD_DEVICE_MODAL,
            payload: data,
        });
    };

    const setEditDeviceModal = (data: boolean) => {
        dispatch({
            type: SET_EDIT_DEVICE_MODAL,
            payload: data,
        });
    };

    const setReloadBluetoothView = (data: boolean) => {
        dispatch({
            type: SET_RELOAD_BLUETOOTH_VIEW,
            payload: data,
        });
    };
    const getModalLayout = async (
        modalName: string,
        selectedLanguage: string,
        configurationId: number | undefined,
        presets: any,
        isEdit: boolean = false
    ) => {
        let data = null;
        try {
            const bluetoothModalData = await getBluetoothModalDataAsync(
                configurationId || 0,
                modalName,
                isEdit
            );

            data = bluetoothModalData;
            setModalData(data);
            setBluetoothModalName(data?.name);
            if (data && data.customTables.length > 0) {
                const { data: modalTableData } = await getData(
                    `${selectedLanguage}/editor/${configurationId}/bluetooth/getbluetoothtable${
                        data?.customTables[0].name
                            ? `?tableName=${data?.customTables[0].name}`
                            : ""
                    }${presets?.name ? `&presetName=${presets?.name}` : ""}`
                );

                setBluetoothModalTable(modalTableData);
            }
            setAddDeviceModal(false);
            setEditDeviceModal(true);
        } catch (error) {
            console.log(error);
        }
    };

    const setBluetoothTotalCount = (data: number) => {
        dispatch({
            type: SET_BLUETOOTH_TOTAL_COUNT,
            payload: data,
        });
    };

    return (
        <BluetoothViewContext.Provider
            value={{
                columns: state.columns,
                activeColumns: state.activeColumns,
                bluetoothData: state.bluetoothData,
                bluetoothModalTable: state.bluetoothModalTable,
                bluetoothModalPreset: state.bluetoothModalPreset,
                isSubmitDisabled: state.isSubmitDisabled,
                activeStatusFilter: state.activeStatusFilter,
                searchValue: state.searchValue,
                bluetoothModalName: state.bluetoothModalName,
                modalData: state.modalData,
                isAddDeviceModalOpen: state.isAddDeviceModalOpen,
                isEditDeviceModalOpen: state.isEditDeviceModalOpen,
                reloadBluetoothView: state.reloadBluetoothView,
                bluetoothTotalCount: state.bluetoothTotalCount,
                filterColumnsByName,
                resetColumnsToDefault,
                resetColumn,
                setBluetoothData,
                setBluetoothModalTable,
                setBluetoothModalPreset,
                setSubmitDisabled,
                setBluetoothTableStatusFilter,
                setTableSearchValue,
                setBluetoothModalName,
                setModalData,
                setAddDeviceModal,
                setEditDeviceModal,
                getModalLayout,
                setReloadBluetoothView,
                setBluetoothTotalCount,
                resetBluetoothView,
            }}
        >
            {children}
        </BluetoothViewContext.Provider>
    );
};

export default BluetoothViewState;
