/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx, SerializedStyles } from "@emotion/react";
import { MenuItem, Popover } from "@mui/material";
import { ReactNode, useContext, useEffect, useRef, useState } from "react";
import LayoutContext from "../../../../../../../context/layout/layoutContext";
import MenuItemContext from "../../../../../../../context/menuItem/menuItemContext";
import { TableSwitchDefaultValue } from "./TableSwitchInput";
import { ExpandMore as ExpandMoreIcon } from "@mui/icons-material";
import { Component, SearchPath } from "../../../../../../../generatedTypes";
import ThemeContext from "../../../../../../../context/theme/themeContext";
import Tooltip from "../../../../../../MuiComponents/Tooltip";
import { noop } from "../../../../../../../utils/helpers";
import TooltipLarge from "../../../../../../MuiComponents/TooltipLarge";
import useNavigation from "../../../../../../../utils/useNavigation";
import LanguageContext from "../../../../../../../context/language/languageContext";
export interface TableCollectionProps {
    data: Component;
    switchId?: string | number;
    onOptionClick?: (value: any) => void;
}
interface CollectionMenuItemProps {
    tooltipTitle?: string;
    tooltipButtonText?: string;
    tooltipOnBtnClick?: () => void;
    disabled: boolean;
    children: ReactNode;
    value: any;
    onClick?: any;
    css?: SerializedStyles;
}

const TableCollection = ({
    data,
    switchId,
    onOptionClick,
}: TableCollectionProps) => {
    const {
        label,
        parameterId,
        parameterValue,
        collectionConfig,
        sourceBindToList,
        disabledTooltip,
        isDisabled,
    } = data;
    const { findBindedList, listValues } = useContext(LayoutContext);
    const {
        parameters,
        updateParameter,
        findParameterValueById,
        findDisabledCollectionItemsById,
    } = useContext(MenuItemContext);
    const {
        colors: { gray100, textDarkDisabled, blue700, red700 },
    } = useContext(ThemeContext);
    const { t } = useContext(LanguageContext);

    const [isOpen, setIsOpen] = useState(false);
    const [menuAnchorEl, setMenuAnchorEl] = useState<HTMLDivElement | null>(
        null
    );
    const [value, setValue] = useState<string>(parameterValue);
    const [previousValue, setPreviousValue] = useState(parameterValue);
    const [requestError, setRequestError] = useState(false);
    const [listItems, setListItems] = useState(
        listValues[sourceBindToList || ""]
    );

    const elementRef = useRef(null);

    const { handleSearchNavigation } = useNavigation();

    const paramValFromContext = parameters[parameterId];

    useEffect(() => {
        if (listValues[sourceBindToList || ""]) {
            setListItems([
                { value: t.NotSet, index: 0 },
                ...listValues[sourceBindToList || ""],
            ]);
        }
        // eslint-disable-next-line
    }, [listValues[sourceBindToList || ""]]);

    useEffect(() => {
        setValue(paramValFromContext);
        setPreviousValue(paramValFromContext);
        // eslint-disable-next-line
    }, [paramValFromContext]);

    const onSuccessfulRequest = (val: string) => {
        requestError && setRequestError(false);
        setPreviousValue(val);
    };

    const onFailedRequest = () => {
        setRequestError(true);
        setValue(previousValue);
    };

    const onCanceledRequest = () => {
        setValue(previousValue);
    };

    const isInputDisabled =
        findParameterValueById(switchId) === TableSwitchDefaultValue.OFF;

    const isCollectionDisabled = (): boolean => {
        if (
            findBindedList(sourceBindToList) &&
            !listValues[sourceBindToList || ""]?.length
        ) {
            return true;
        }
        return isInputDisabled || isDisabled;
    };

    const toggling = (e: React.MouseEvent<HTMLDivElement>) => {
        setIsOpen(!isOpen);
        setMenuAnchorEl(e.currentTarget);
    };

    const onOptionClicked = (value: any) => {
        onOptionClick && onOptionClick(value);
        setValue(value);
        updateParameter(
            parameterId,
            value,
            label || "",
            elementRef,
            () => onSuccessfulRequest(value),
            onFailedRequest,
            () => noop,
            true,
            onCanceledRequest
        );
        setIsOpen(false);
    };

    const renderBorderColor = () => {
        if (requestError) {
            return `1px solid ${red700}`;
        }
        if (isOpen) {
            return `1px solid ${blue700}`;
        }
        return "unset";
    };

    const CollectionMenuItem = ({
        tooltipTitle,
        tooltipButtonText,
        tooltipOnBtnClick,
        disabled,
        children,
        value,
        css,
        ...props
    }: CollectionMenuItemProps) => {
        return disabled ? (
            <TooltipLarge
                noMargin
                placement="top-start"
                title={tooltipTitle || ""}
                buttonText={tooltipButtonText || ""}
                onBtnClick={tooltipOnBtnClick || noop}
            >
                <span>
                    <MenuItem
                        disabled={disabled}
                        {...props}
                        onClick={() => onOptionClicked(value)}
                        component="div"
                    >
                        {children}
                    </MenuItem>
                </span>
            </TooltipLarge>
        ) : (
            <MenuItem {...props} onClick={() => onOptionClicked(value)}>
                {children}
            </MenuItem>
        );
    };

    const renderSelectValue = () => {
        if (listItems && listItems.length) {
            return (
                listItems?.find((item) => item.index === Number(value))
                    ?.value || ""
            );
        } else {
            return (
                collectionConfig?.collectionItems.find(
                    (item) => item.value === value
                )?.text || ""
            );
        }
    };

    return (
        <Tooltip
            title={
                isCollectionDisabled() && disabledTooltip
                    ? disabledTooltip.title
                    : ""
            }
            small
            placement="top"
        >
            <span
                css={css({ display: "block", width: "100%", height: "100%" })}
            >
                <div
                    css={css({
                        pointerEvents: isCollectionDisabled() ? "none" : "auto",
                        backgroundColor: isCollectionDisabled()
                            ? gray100
                            : "unset",
                        color: isCollectionDisabled()
                            ? textDarkDisabled
                            : "unset",
                        width: "100%",
                        cursor: "pointer",
                        height: "100%",
                        fontWeight: 600,
                        fontSize: "14px",
                        display: "flex",
                    })}
                >
                    <div
                        css={css({
                            padding: "14px 16px",
                            minWidth: "28px",
                            width: "100%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            boxSizing: "border-box",
                            border: renderBorderColor(),
                        })}
                        onClick={toggling}
                        data-test={String(parameterId)}
                    >
                        {renderSelectValue()}
                        <ExpandMoreIcon
                            css={css({
                                transform: isOpen ? "rotate(-180deg)" : "unset",
                            })}
                        />
                    </div>
                    <Popover
                        open={isOpen}
                        anchorEl={menuAnchorEl}
                        onClose={() => setIsOpen(false)}
                        anchorPosition={{ top: 0, left: 0 }}
                        anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "center",
                        }}
                        transformOrigin={{
                            vertical: "top",
                            horizontal: "center",
                        }}
                    >
                        {listItems?.map((item) => {
                            if (item.value) {
                                return (
                                    <CollectionMenuItem
                                        key={item.index}
                                        value={item.index}
                                        disabled={false}
                                        onClick={() =>
                                            onOptionClicked(item.value)
                                        }
                                    >
                                        {item.value}
                                    </CollectionMenuItem>
                                );
                            }
                        })}
                        {collectionConfig?.collectionItems.map(
                            (item, index, arr) => {
                                const hasDisabledItems =
                                    findDisabledCollectionItemsById(
                                        parameterId
                                    );

                                const isMenuItemDisabled =
                                    hasDisabledItems &&
                                    hasDisabledItems[item.value];

                                const itemDisabledTooltip =
                                    item.disabledTooltip;
                                if (item.isInvisible) {
                                    return null;
                                }
                                return (
                                    <CollectionMenuItem
                                        onClick={() =>
                                            onOptionClicked(item.value)
                                        }
                                        key={item.value}
                                        value={item.value}
                                        disabled={isMenuItemDisabled}
                                        tooltipTitle={
                                            isMenuItemDisabled &&
                                            itemDisabledTooltip
                                                ? itemDisabledTooltip.title
                                                : ""
                                        }
                                        tooltipButtonText={
                                            itemDisabledTooltip
                                                ? itemDisabledTooltip.btnTitle
                                                : ""
                                        }
                                        tooltipOnBtnClick={() =>
                                            handleSearchNavigation(
                                                itemDisabledTooltip as {
                                                    searchPath: SearchPath;
                                                }
                                            )
                                        }
                                        css={css({
                                            minWidth: "220px",
                                        })}
                                    >
                                        {item.text}
                                    </CollectionMenuItem>
                                );
                            }
                        )}
                    </Popover>
                </div>
            </span>
        </Tooltip>
    );
};

export default TableCollection;
