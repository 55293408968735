/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import { Fragment, useContext, useEffect, useRef, useState } from "react";
import CheckWhiteIcon from "../../../../../../assets/icons/CheckWhiteIcon";
import EditIcon from "../../../../../../assets/icons/EditIcon";
import FeaturesContext from "../../../../../../context/features/featuresContext";
import LayoutContext from "../../../../../../context/layout/layoutContext";
import MenuItemContext from "../../../../../../context/menuItem/menuItemContext";
import ThemeContext from "../../../../../../context/theme/themeContext";
import { Component } from "../../../../../../generatedTypes";
import {
    decimalCount,
    findInArrayByIndex,
    getIdTooltipText,
    preventENotation,
} from "../../../../../../utils/helpers";
import useDebounce from "../../../../../../utils/useDebounce";
import Badge, { BadgeColors } from "../../../../../MuiComponents/Badge";
import Button from "../../../../../MuiComponents/Button";
import Card from "../../../../../MuiComponents/Card";
import Divider from "../../../../../MuiComponents/Divider";
import InputField from "../../../../../MuiComponents/InputField";
import TypedGrid from "../../../../../MuiComponents/TypedGrid";
import { validateNumberLengthAndRegex } from "../../../../../../utils/validations";
import { FormHelperText } from "@mui/material";
import ErrorIcon from "@mui/icons-material/ErrorRounded";
import Tooltip from "../../../../../MuiComponents/Tooltip";
import Tag from "../../../../../MuiComponents/Tag";
import SettingsContext from "../../../../../../context/settings/settingsContext";
import Label from "../../../../../MuiComponents/Label";
import LanguageContext from "../../../../../../context/language/languageContext";

export interface CardSectionComponentData extends Component {
    badgeText?: string;
    badgeColor?: BadgeColors;
}

export interface CardSectionProps {
    data: {
        name?: string;
        modalName?: string;
        components: any[];
    };
    onButtonClick?: () => void;
    children?: React.ReactNode;
    isPrimaryButtonDisabled?: boolean;
    isEditable?: boolean;
    submitValuesOnChange?: boolean;
    isDisabled?: boolean;
}

export interface Error {
    parameterId: number;
    error: string;
}

const CardSection = ({
    data,
    onButtonClick,
    children,
    isPrimaryButtonDisabled,
    isEditable = false,
    isDisabled,
    submitValuesOnChange = true,
}: CardSectionProps) => {
    const {
        editableInputs,
        parametersValues,
        setParameterValue,
        findParameterValueById,
        findDefaultParameterValueById,
        hasEditableInput,
        resetEditableInputs,
        setEditableInput,
        submitParameterValue,
        removeEditableInputById,
    } = useContext(FeaturesContext);
    const { updateParameter } = useContext(MenuItemContext);
    const {
        disableNavigationBetweenMenuItems,
        listValues,
        setDrawerParameterId,
    } = useContext(LayoutContext);
    const {
        colors: { blue700, gray100, red700, gray400 },
    } = useContext(ThemeContext);
    const { isParameterIdsHidden } = useContext(SettingsContext);
    const { t } = useContext(LanguageContext);

    const debouncedChangeHandler = useDebounce(updateParameter, 500);

    const elementRef = useRef(null);

    const [errors, setErrors] = useState<Error[]>([]);

    useEffect(() => {
        setErrors(
            parametersValues
                .filter((parameter) => parameter?.error)
                .map<Error>((parameter, index) => {
                    const error: Error = {
                        parameterId: parameter.parameterId,
                        error: String(parameter.error),
                    };
                    return error;
                })
        );
    }, [parametersValues]);

    useEffect(() => {
        disableNavigationBetweenMenuItems(editableInputs.length > 0);
        // eslint-disable-next-line
    }, [editableInputs]);

    useEffect(() => {
        resetEditableInputs();
        return () => {
            resetEditableInputs();
        };
        // eslint-disable-next-line
    }, []);

    const defaultParameterValue = (component: CardSectionComponentData) => {
        if (isDisabled) {
            return (
                findDefaultParameterValueById(component.parameterId) ||
                component.parameterValue
            );
        }
        return (
            findParameterValueById(component.parameterId) ||
            component.parameterValue
        );
    };

    const handleInputChange = (
        value: number,
        component: CardSectionComponentData,
        _index: number,
        increment?: number
    ) => {
        let newValue = value;
        if (decimalCount(increment) < decimalCount(value)) {
            newValue = Number(Number(value).toFixed(decimalCount(increment)));
        }
        const valueAsString = String(newValue);
        const errorValue = validateNumberLengthAndRegex(
            valueAsString,
            Number(component.min),
            Number(component.max),
            component.validate || "",
            component.validationMessage || "",
            t
        );

        const parValue = {
            parameterId: component.parameterId,
            label: component.label,
            value: valueAsString,
            index: component.index,
            error: errorValue,
        };

        setParameterValue(parValue);
    };
    const handleIconClick = async (parameterId: number) => {
        try {
            if (hasEditableInput(parameterId)) {
                if (parametersValues.length > 0) {
                    const component = data.components.find(
                        (dataComponent) =>
                            dataComponent.parameterId === parameterId
                    );
                    const parameter = findInArrayByIndex(
                        parametersValues,
                        component.index
                    );

                    if (parameter.error) return;

                    if (submitValuesOnChange) {
                        debouncedChangeHandler(
                            parameter.parameterId,
                            parameter.value,
                            parameter.label,
                            elementRef,
                            () =>
                                submitParameterValue({
                                    parameterId: parameter.parameterId,
                                    value: parameter.value,
                                }),
                            null,
                            null,
                            true
                        );
                    }
                }
                removeEditableInputById(parameterId);
            } else {
                disableNavigationBetweenMenuItems(true);
                setEditableInput(parameterId);
            }
        } catch (error) {
            console.log(error);
        }
    };

    const renderError = (error: string) => {
        return (
            <Fragment>
                <FormHelperText>
                    <ErrorIcon
                        css={css({
                            color: red700,
                            fontSize: "16px",
                            marginRight: "4px",
                        })}
                    />
                    {error}
                </FormHelperText>
            </Fragment>
        );
    };

    const renderInput = (
        component: CardSectionComponentData,
        parameterId: number
    ) => {
        const selectedSmsNumberFromCollection =
            listValues[component.sourceBindToList || ""] &&
            component.parameterValue &&
            Number(component.parameterValue) &&
            listValues[component.sourceBindToList || ""].find(
                (item) => item.index === Number(component.parameterValue)
            );

        if (hasEditableInput(parameterId) && !isDisabled) {
            return (
                <TypedGrid item sm={11} xs0={11}>
                    <InputField
                        size="medium"
                        value={defaultParameterValue(component)}
                        type="number"
                        inputProps={{
                            min: component.min,
                            max: component.max,
                            step: component.increment,
                        }}
                        onChange={(e) =>
                            handleInputChange(
                                Number(e.target.value),
                                component,
                                component.parameterId,
                                component.increment
                            )
                        }
                        onKeyDown={preventENotation}
                        wrapperStyle={css({ marginRight: "8px" })}
                        fullWidth
                        error={
                            errors.find(
                                (error) =>
                                    error.parameterId === component.parameterId
                            )?.error
                        }
                    />
                </TypedGrid>
            );
        }
        if (component.sourceBindToList && selectedSmsNumberFromCollection) {
            return (
                <TypedGrid item>
                    {selectedSmsNumberFromCollection.value}
                </TypedGrid>
            );
        }
        if (component.parameterValue && !component.sourceBindToList) {
            const error = errors.find(
                (stateError) => stateError.parameterId === component.parameterId
            );
            return (
                <TypedGrid
                    item
                    css={css({
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        paddingRight: 8,
                    })}
                >
                    {defaultParameterValue(component)}
                    {error ? renderError(String(error.error)) : ""}
                </TypedGrid>
            );
        }
        if (component.badgeText && component.badgeColor) {
            return (
                <Badge color={component.badgeColor}>
                    {component.badgeText}
                </Badge>
            );
        }
        return <Badge color="neutral">{t.NotSet}</Badge>;
    };

    const renderSubmitButton = (parameterId: number) => {
        const error = Boolean(
            errors.find((stateError) => stateError.parameterId === parameterId)
        );
        return (
            <CheckWhiteIcon
                onClick={() => handleIconClick(parameterId)}
                css={css({
                    cursor: "pointer",
                    width: "36px",
                    height: "36px",
                    background: error ? gray400 : blue700,
                    borderRadius: "6px",
                })}
                disabled={error}
            />
        );
    };

    const renderEditButton = (parameterId: number) => {
        return (
            <EditIcon
                onClick={() => handleIconClick(parameterId)}
                css={css({
                    cursor: "pointer",
                    width: "36px",
                    height: "36px",
                    "&:hover": {
                        background: gray100,
                    },
                })}
            />
        );
    };

    return (
        <div css={css({ marginBottom: "16px" })}>
            <Card title={data.name} isSection>
                {data.components.map((component: Component) => (
                    <Fragment key={component.index}>
                        <div
                            css={css({
                                margin: isEditable ? "16px 0px 0px" : "8px 0",
                                fontWeight: 600,
                            })}
                        >
                            <TypedGrid
                                container
                                columnSpacing={2}
                                rowSpacing={0.5}
                                flexDirection={isEditable ? "column" : "row"}
                            >
                                <TypedGrid
                                    item
                                    xs0={isEditable ? 12 : 6}
                                    sm={isEditable ? 12 : 6}
                                >
                                    <Label
                                        title={component.label || ""}
                                        info={component.tooltip}
                                        onInfoIconClick={() =>
                                            setDrawerParameterId(
                                                component.parameterId
                                            )
                                        }
                                    />
                                </TypedGrid>

                                <TypedGrid
                                    item
                                    xs0={isEditable ? 12 : 6}
                                    sm={isEditable ? 12 : 6}
                                    container
                                    justifyContent="space-between"
                                    alignItems="center"
                                    flexWrap="nowrap"
                                    css={css({
                                        margin: isEditable
                                            ? "0px 0px 8px 0px"
                                            : "unset",
                                    })}
                                >
                                    {renderInput(
                                        component,
                                        component.parameterId
                                    )}
                                    <div
                                        css={css({
                                            display: "flex",
                                            flexDirection: "row",
                                            gap: "16px",
                                        })}
                                    >
                                        {component.parameterId &&
                                        !isParameterIdsHidden ? (
                                            <Tooltip
                                                title={getIdTooltipText(
                                                    component.parameterId,
                                                    component.avlId
                                                )}
                                                small
                                                placement="top"
                                            >
                                                <span
                                                    css={css({
                                                        marginTop: "8px",
                                                        marginRight: "8px",
                                                    })}
                                                >
                                                    <Tag
                                                        size="tiny"
                                                        color="white"
                                                        title="ID"
                                                    />
                                                </span>
                                            </Tooltip>
                                        ) : null}
                                        {isEditable && (
                                            <TypedGrid
                                                item
                                                xs0={1}
                                                sm={1}
                                                justifyContent="center"
                                                container
                                                css={css({
                                                    marginLeft:
                                                        isParameterIdsHidden
                                                            ? "16px"
                                                            : "unset",
                                                })}
                                            >
                                                {hasEditableInput(
                                                    component.parameterId
                                                )
                                                    ? renderSubmitButton(
                                                          component.parameterId
                                                      )
                                                    : renderEditButton(
                                                          component.parameterId
                                                      )}
                                            </TypedGrid>
                                        )}
                                    </div>
                                </TypedGrid>
                            </TypedGrid>
                        </div>
                        <Divider />
                    </Fragment>
                ))}
                {data.modalName && onButtonClick && (
                    <div
                        css={css({
                            display: "flex",
                            marginTop: "16px",
                        })}
                    >
                        <Button
                            fullWidth
                            variant="textOnly"
                            size="small"
                            color="primarySubtle"
                            onClick={onButtonClick}
                            disabled={isPrimaryButtonDisabled}
                            idForTesting={data.modalName}
                        >
                            {data.modalName}
                        </Button>
                    </div>
                )}
            </Card>
            {children}
        </div>
    );
};

export default CardSection;
