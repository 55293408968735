/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from "@emotion/react";

import { useContext } from "react";
import ThemeContext from "../../../context/theme/themeContext";
import useMediaQueries from "../../../utils/useMediaQueries";
import Skeleton from "@mui/material/Skeleton";

const TopBarSkeleton = () => {
    const {
        colors: { white, gray100 },
    } = useContext(ThemeContext);

    const { fromXl } = useMediaQueries();

    return (
        <div
            css={css`
                background: ${white};
                padding: 10px 24px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                box-shadow: 0px 6px 14px -6px rgba(24, 39, 75, 0.12),
                    0px 10px 32px -4px rgba(24, 39, 75, 0.1);

                span {
                    background: ${gray100};
                }
            `}
        >
            <div>
                <Skeleton width={99} height={36} />
            </div>

            <div
                css={css`
                    display: flex;

                    & > span + span {
                        margin-left: 8px;
                    }
                `}
            >
                <Skeleton width={fromXl ? 99 : 36} height={36} />
                <Skeleton width={fromXl ? 208 : 36} height={36} />
                <Skeleton width={fromXl ? 101 : 36} height={36} />
                <Skeleton width={fromXl ? 84 : 36} height={36} />
            </div>
        </div>
    );
};

export default TopBarSkeleton;
