/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import React, { useContext, useRef } from "react";
import InputField from "../../../../MuiComponents/InputField";
import TypedGrid from "../../../../MuiComponents/TypedGrid";
import { ReactComponent as UpdateIcon } from "../../../../../assets/UpdateIcon.svg";
import { ReactComponent as DeleteCircleIcon } from "../../../../../assets/DeleteCircleIcon.svg";
import useApi from "../../../../../utils/useApi";
import LanguageContext from "../../../../../context/language/languageContext";
import Tooltip from "../../../../MuiComponents/Tooltip";
import AccessoriesContext from "../../../../../context/accessories/accessoriesContext";
import { preventENotation } from "../../../../../utils/helpers";

export interface FuelRowProps {
    hasNoBorderRadius?: boolean;
    isFirstRow?: boolean;
    isLastRow?: boolean;
    rowId: number;
    sensorId: number;
}

const FuelRow: React.FunctionComponent<FuelRowProps> = ({
    hasNoBorderRadius,
    isFirstRow,
    isLastRow,
    rowId,
    sensorId,
}) => {
    const { selectedLanguage } = useContext(LanguageContext);
    const { setFuelValue, fuelValues } = useContext(AccessoriesContext);
    const elementRef = useRef(null);

    const { getData, deleteData } = useApi();

    const fuelValue = fuelValues.find((item) => item.id === rowId)?.fuelValue;

    const llsValue =
        fuelValues.find((item) => item.id === rowId)?.llsValue || "N/A";

    const handleOnClick = async () => {
        const { data } = await getData(
            `${selectedLanguage}/device/lls/measurells?userValue=${fuelValue}&selectedSensorId=${sensorId}&selectedRowId=${rowId}`
        );
        setFuelValue({ fuelValue, id: rowId, llsValue: data.deviceValue });
    };

    const handleRemoveOnClick = async () => {
        await deleteData(
            `${selectedLanguage}/device/lls/row?selectedRowId=${rowId}`
        );
        setFuelValue({ fuelValue: 0, id: rowId, llsValue: "N/A" });
    };

    const handleOnChange = (e: any) => {
        const value =
            e.target.value === "" ? undefined : Number(e.target.value);
        setFuelValue({
            id: rowId,
            fuelValue: value,
            llsValue,
        });
    };
    const renderInputBorder = (firstInput: boolean) => {
        if (hasNoBorderRadius) {
            return "0px 0px 0px 0px";
        }
        if (isFirstRow && firstInput) {
            return "6px 0px 0px 0px";
        }
        if (isFirstRow && !firstInput) {
            return "0px 6px 0px 0px";
        }
        if (isLastRow && firstInput) {
            return "0px 0px 0px 6px";
        }
        if (isLastRow && !firstInput) {
            return "0px 0px 6px 0px";
        }
        return "0px 0px 0px 0px";
    };
    return (
        <TypedGrid container alignItems={"center"}>
            <TypedGrid item lg={5} md={5} sm={5} xs={5}>
                <InputField
                    ref={elementRef}
                    fullWidth
                    size="medium"
                    placeholder={""}
                    type="number"
                    inputProps={{ step: 1 }}
                    value={fuelValue}
                    onChange={handleOnChange}
                    onKeyDown={preventENotation}
                    borderRadius={renderInputBorder(true)}
                    stacked={true}
                />
            </TypedGrid>
            <TypedGrid item lg={5} md={5} sm={5} xs={5}>
                <InputField
                    ref={elementRef}
                    fullWidth
                    size="medium"
                    placeholder={""}
                    type="text"
                    inputProps={{ step: 1 }}
                    value={llsValue}
                    borderRadius={renderInputBorder(false)}
                    stacked={true}
                    readOnly
                />
            </TypedGrid>
            <div
                css={css({
                    display: "flex",
                    gap: "16px",
                    marginLeft: "8px",
                })}
            >
                <Tooltip title={"Get current value"} small placement="top">
                    <span>
                        <UpdateIcon
                            css={css({ cursor: "pointer" })}
                            onClick={handleOnClick}
                        />
                    </span>
                </Tooltip>
                <DeleteCircleIcon
                    css={css({ cursor: "pointer" })}
                    onClick={handleRemoveOnClick}
                />
            </div>
        </TypedGrid>
    );
};

export default FuelRow;
