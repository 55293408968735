import useApi from "./useApi";
import { DeviceGuide } from "../generatedTypes";
import { useContext } from "react";
import LayoutContext from "../context/layout/layoutContext";
import useTct from "./useTct";

const useDeviceGuideProvider = () => {
    const { showErrorMsg } = useApi();
    const { getDeviceGuideAsync, setGuideStepAsCompletedAsync } = useTct();
    const { layoutData, setDeviceGuide } = useContext(LayoutContext);

    const getDeviceGuide = async (setInContext: boolean = true): Promise<DeviceGuide | null> => {
        if (!layoutData) {
            throw new ReferenceError("Cannot get device guide without a layout");
        }

        try {
            const data = await getDeviceGuideAsync(layoutData.id);

            setInContext && setDeviceGuide(data);

            return data;
        } catch (error) {
            showErrorMsg(error as any);
        }

        return null;
    }

    const setGuideStepCompleted = async (stepIndex: number): Promise<void> => {
        if (!layoutData) {
            throw new ReferenceError("Cannot set device guide step as completed without a layout");
        }

        try {
            await setGuideStepAsCompletedAsync(layoutData.id, stepIndex);
        } catch (error) {
            showErrorMsg(error as any);
        }
    };

    return { getDeviceGuide, setGuideStepCompleted }
}

export default useDeviceGuideProvider;