/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx, SerializedStyles } from "@emotion/react";

import { forwardRef, useContext, Fragment, useCallback } from "react";
import ThemeContext from "../../../context/theme/themeContext";
import SwitchComponent, { SwitchComponentSizes } from "./SwitchComponent";
import FormControlLabel from "@mui/material/FormControlLabel";
import Tooltip from "../Tooltip";
import CircularProgress from "@mui/material/CircularProgress";
import AlertWarningIcon from "../../../assets/icons/AlertWarningIcon";
import { SwitchLabelPlacements } from "../Switch";

type Ref = HTMLInputElement;

interface SwitchComponentWithLabelProps {
    size: SwitchComponentSizes;
    label: string | React.ReactNode;
    labelPlacement?: SwitchLabelPlacements;
    requesting?: boolean;
    requestFailed?: boolean;
    wrapperStyle?: SerializedStyles;
    checked?: boolean;
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
    idForTesting: string;
}

const SwitchComponentWithLabel = forwardRef<Ref, SwitchComponentWithLabelProps>(
    (
        {
            size,
            label,
            labelPlacement,
            requesting,
            requestFailed,
            wrapperStyle,
            checked,
            onChange,
            idForTesting,
            ...props
        },
        ref
    ) => {
        const {
            colors: { blue700, textDark, textDarkDisabled },
        } = useContext(ThemeContext);

        const fullLabel = (
            <Fragment>
                <span
                    css={css`
                        display: flex;
                        align-items: center;
                    `}
                >
                    {(requesting || requestFailed) && (
                        <span
                            css={css`
                                width: 16px;
                                height: 16px;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                margin-right: 4px;
                            `}
                        >
                            {requesting ? (
                                <CircularProgress
                                    size={13}
                                    css={css`
                                        color: ${blue700};
                                    `}
                                />
                            ) : (
                                <Tooltip
                                    title="Changes that were made recently could not be saved due to an error."
                                    placement="top"
                                >
                                    <span
                                        css={css`
                                            display: inline-flex;

                                            & > svg {
                                                font-size: 16px;
                                            }
                                        `}
                                    >
                                        <AlertWarningIcon />
                                    </span>
                                </Tooltip>
                            )}
                        </span>
                    )}
                </span>
                {label}
            </Fragment>
        );

        const handleClick = useCallback(
            (event: React.MouseEvent<HTMLLabelElement>) => {
                //Prevent the click event from being triggered when clicking on the label itself
                const target = event.target as HTMLElement;
                const isSwitch =
                    target.tagName === "INPUT" ||
                    target.classList.contains("MuiSwitch-switchBase");

                if (!isSwitch) {
                    event.preventDefault();
                }
            },
            []
        );

        return (
            <FormControlLabel
                control={
                    <SwitchComponent
                        ref={ref}
                        size={size}
                        checked={Boolean(checked)}
                        onChange={onChange}
                        idForTesting={idForTesting}
                        {...props}
                    />
                }
                onClick={handleClick}
                label={fullLabel}
                labelPlacement={labelPlacement}
                sx={{
                    margin: "0",
                    alignItems:
                        labelPlacement === "bottom" || labelPlacement === "top"
                            ? "flex-end"
                            : "center",
                    whiteSpace: "nowrap",

                    "& .MuiFormControlLabel-label": {
                        display: "flex",
                        alignItems: "center",
                        color: textDark,
                        fontSize: "14px",
                        fontWeight: "600",
                        lineHeight: "20px",
                        letterSpacing: "0.1px",

                        "&.Mui-disabled": {
                            color: textDarkDisabled,
                        },
                    },

                    "&.MuiFormControlLabel-labelPlacementEnd .MuiFormControlLabel-label":
                        {
                            marginLeft: "12px",
                        },

                    "&.MuiFormControlLabel-labelPlacementStart .MuiFormControlLabel-label":
                        {
                            marginRight: "12px",
                        },

                    "&.MuiFormControlLabel-labelPlacementBottom .MuiFormControlLabel-label":
                        {
                            marginTop: "6px",
                        },

                    "&.MuiFormControlLabel-labelPlacementTop .MuiFormControlLabel-label":
                        {
                            marginBottom: "6px",
                        },
                }}
                css={wrapperStyle}
            />
        );
    }
);

export default SwitchComponentWithLabel;
