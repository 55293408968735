import { useState, useEffect, useRef, useCallback } from "react";

interface PollingResult<T> {
    data: T | null;
    loading: boolean;
    error: Error | null;
}

function usePolling<T>(
    fetchFunction: () => Promise<T>,
    interval: number = 5000,
    isActive: boolean = true
): PollingResult<T> {
    const [data, setData] = useState<T | null>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<Error | null>(null);

    const fetchFunctionRef = useRef(fetchFunction);
    const intervalRef = useRef(interval);

    useEffect(() => {
        fetchFunctionRef.current = fetchFunction;
        intervalRef.current = interval;
    }, [fetchFunction, interval]);

    const fetchData = useCallback(async () => {
        try {
            setLoading(true);
            const result = await fetchFunctionRef.current();
            setData(result);
            setError(null);
        } catch (err) {
            setError(
                err instanceof Error
                    ? err
                    : new Error("An unknown error occurred")
            );
        } finally {
            setLoading(false);
        }
    }, []);

    useEffect(() => {
        let pollTimeout: NodeJS.Timeout | null = null;

        const runPolling = async () => {
            await fetchData();
            if (isActive) {
                pollTimeout = setTimeout(runPolling, intervalRef.current);
            }
        };

        if (isActive) {
            runPolling();
        }

        return () => {
            if (pollTimeout) {
                clearTimeout(pollTimeout);
            }
        };
    }, [isActive, fetchData]);

    return { data, loading, error };
}

export default usePolling;
