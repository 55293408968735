/// <reference path="../../../../../generatedTypes.d.ts" />
/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from "@emotion/react";

import { Fragment, useContext, useEffect } from "react";
import ThemeContext from "../../../../../context/theme/themeContext";
import LayoutContext from "../../../../../context/layout/layoutContext";
import LanguageContext from "../../../../../context/language/languageContext";
import DialogModal from "../../../../MuiComponents/Modals/DialogModal";
import Button from "../../../../MuiComponents/Button";
import AlertContext from "../../../../../context/alert/alertContext";
import useApi from "../../../../../utils/useApi";
import { convertMinToSeconds } from "../../../../../utils/helpers";
import Timer from "../../../../Timer";
import { CircularProgress } from "@mui/material";
import useDesktopHost from "../../../../../utils/useDesktopHost";
import { AlertStatus, PathType } from "../../../../../constants/constants";
import Divider from "../../../../MuiComponents/Divider";

interface LogModalProps {
    isOpen: boolean;
    onClose: () => void;
}

const LogModal = ({ isOpen, onClose }: LogModalProps) => {
    const {
        colors: { blue700 },
    } = useContext(ThemeContext);
    const { layoutData } = useContext(LayoutContext);
    const { setAlert } = useContext(AlertContext);
    const { selectedLanguage, t } = useContext(LanguageContext);
    const { getData, cancelSource, isCanceled } = useApi();
    const { openSpecialDirectory } = useDesktopHost();

    useEffect(() => {
        const getLogData = async () => {
            try {
                await getData(`${selectedLanguage}/dataexport/capturelog`);
                onClose();
                setAlert(
                    AlertStatus.Success,
                    t.LogCaptured,
                    null,
                    t.OpenFile,
                    () => openSpecialDirectory({ type: PathType.DeviceLog })
                );
            } catch (err) {
                if (isCanceled(err)) {
                    setAlert(
                        AlertStatus.Success,
                        t.LogCaptured,
                        null,
                        t.OpenFile,
                        () =>
                            openSpecialDirectory({
                                type: PathType.DeviceLog,
                            })
                    );
                } else {
                    setAlert(AlertStatus.Critical, t.LogFail);
                }
            }
        };
        getLogData();
        return () => {
            cancelSource.cancel();
        };
        // eslint-disable-next-line
    }, []);

    return (
        <DialogModal
            isOpen={isOpen}
            close={onClose}
            title={t.CaptureLogModalInfo}
            description={
                <Fragment>
                    <Divider />
                    <div
                        css={css`
                            display: flex;
                            min-height: 300px;
                            justify-content: center;
                            align-items: center;
                            flex-direction: column;
                        `}
                    >
                        <CircularProgress
                            size={40}
                            css={css`
                                color: ${blue700};
                            `}
                        />
                        <Timer
                            timerText={t["CaptureLogModalInfo.description"]}
                            initialCountSeconds={convertMinToSeconds(
                                layoutData?.settings.captureTimeMin
                            )}
                        />
                    </div>
                </Fragment>
            }
            customButtons={
                <Button
                    variant={"textOnly"}
                    size="small"
                    color="primarySubtle"
                    disabled={false}
                    onClick={onClose}
                    idForTesting={"cancelCaptureLog"}
                    fullWidth
                >
                    {t.Cancel}
                </Button>
            }
        />
    );
};

export default LogModal;
