/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import { FEList, FEText } from "../../../../generatedTypes";
import { renderFeElement } from "./renderElement";

interface FeListProps {
    list: FEList;
}

const FeList = ({ list }: FeListProps) => {
    const parseTextStyle = (textStyle: string) => {
        switch (textStyle) {
            case "bullet": {
                return {
                    fontSize: "16px",
                    letterSpacing: "0.15px",
                    marginBottom: "4px",
                };
            }
            default: {
                return {
                    fontSize: "16px",
                    letterSpacing: "0.15px",
                    marginBottom: "8px",
                };
            }
        }
    };

    return (
        <ul css={css({ padding: "0 24px", margin: "4px 0 0 0" })}>
            {list.items.map((listItem: any, index) => (
                <li
                    key={index}
                    css={css(parseTextStyle((listItem as FEText).textStyle))}
                >
                    {renderFeElement(listItem, index)}
                </li>
            ))}
        </ul>
    );
};

export default FeList;
