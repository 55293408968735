/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from "@emotion/react";

import { useContext, Fragment } from "react";
import { WidgetsRounded as EmptyStateIcon } from "@mui/icons-material";
import ThemeContext from "../../../../../context/theme/themeContext";
import Button from "../../../../MuiComponents/Button";
import LanguageContext from "../../../../../context/language/languageContext";
import Divider from "../../../../MuiComponents/Divider";

interface FrameEmptyStateProps {
    name: string;
    switchRef: React.RefObject<any>;
}

const FrameEmptyState = ({ name, switchRef }: FrameEmptyStateProps) => {
    const {
        colors: { gray100, textDark, textPlaceholder },
    } = useContext(ThemeContext);
    const { t } = useContext(LanguageContext);
    return (
        <Fragment>
            <Divider hasLighterGray />

            <div
                css={css({
                    padding: "16px 24px 24px",
                })}
            >
                <div
                    css={css({
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        padding: "16px",

                        "& > p": {
                            color: textDark,
                            fontSize: "16px",
                            lineHeight: "20px",
                            fontWeight: "600",
                            letterSpacing: "0.15px",
                            margin: "16px 0 12px 0",
                        },
                    })}
                >
                    <div
                        css={css({
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            padding: "8px",
                            backgroundColor: gray100,
                            borderRadius: "8px",

                            "& > svg": {
                                color: textPlaceholder,
                            },
                        })}
                    >
                        <EmptyStateIcon />
                    </div>

                    <p>
                        {name} {t.IsTurnedOff}
                    </p>

                    <Button
                        variant="textOnly"
                        size="small"
                        color="primary"
                        onClick={() => switchRef.current?.click()}
                        idForTesting="enableFrameButton"
                    >
                        {t.Enable} {name}
                    </Button>
                </div>
            </div>
        </Fragment>
    );
};

export default FrameEmptyState;
