import React from "react";
import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

const ManageIcon = (props: SvgIconProps) => {
    return (
        <SvgIcon {...props}>
            <path
                d="M6.66667 15H17.3333C17.7 15 18 14.55 18 14C18 13.45 17.7 13 17.3333 13H6.66667C6.3 13 6 13.45 6 14C6 14.55 6.3 15 6.66667 15ZM6.66667 19H17.3333C17.7 19 18 18.55 18 18C18 17.45 17.7 17 17.3333 17H6.66667C6.3 17 6 17.45 6 18C6 18.55 6.3 19 6.66667 19ZM6.66667 11H17.3333C17.7 11 18 10.55 18 10C18 9.45 17.7 9 17.3333 9H6.66667C6.3 9 6 9.45 6 10C6 10.55 6.3 11 6.66667 11ZM6 6C6 6.55 6.3 7 6.66667 7H17.3333C17.7 7 18 6.55 18 6C18 5.45 17.7 5 17.3333 5H6.66667C6.3 5 6 5.45 6 6Z"
                fill="currentColor"
            />
        </SvgIcon>
    );
};

export default ManageIcon;
