import React, { useReducer } from "react";
import {
    RESET_FUEL_VALUES,
    SET_FUEL_VALUE,
    SET_FUEL_VALUES,
} from "./accessoriesActions";
import AccessoriesContext from "./accessoriesContext";
import AccessoriesReducer from "./accessoriesReducer";

export interface AccessoriesStateProps {
    children: React.ReactNode;
}

export interface AccessoriesInitialState {
    fuelValues: {
        fuelValue: number | undefined;
        llsValue: string;
        id: number;
    }[];
}

const AccessoriesState = ({ children }: AccessoriesStateProps) => {
    const initialState = {
        fuelValues: [],
    };
    const [state, dispatch] = useReducer(AccessoriesReducer, initialState);

    const setFuelValue = (data: {
        fuelValue: number | undefined;
        llsValue: string;
        id: number;
    }) => {
        dispatch({
            type: SET_FUEL_VALUE,
            payload: data,
        });
    };

    const setFuelValues = (data: any[]) => {
        dispatch({
            type: SET_FUEL_VALUES,
            payload: data,
        });
    };
    const resetFuelValues = () => {
        dispatch({
            type: RESET_FUEL_VALUES,
        });
    };
    return (
        <AccessoriesContext.Provider
            value={{
                setFuelValue,
                setFuelValues,
                resetFuelValues,
                fuelValues: state.fuelValues,
            }}
        >
            {children}
        </AccessoriesContext.Provider>
    );
};

export default AccessoriesState;
