import { createContext } from "react";

interface TerminalContextState {
    setActiveTabId: (id: string) => void;
    activeTab: any;
    setIsOpenConfirmationModal: (val: boolean) => void;
    isOpenConfirmationModal: boolean;
    setTerminalSize: (data: { width: number; height: number }) => void;
    terminalSize: { width: number; height: number };
    setPortListAnchorEl: (data: (EventTarget & HTMLDivElement) | null) => void;
    portListAnchorEl: (EventTarget & HTMLDivElement) | null;
    setDevices: (data: any) => void;
    devices: any[];
    setSinglePortListAnchorEl: (
        data: (EventTarget & HTMLDivElement) | null
    ) => void;
    singlePortAnchorEl: (EventTarget & HTMLDivElement) | null;
    setActivePortsForDropdown: (data: any) => void;
    activePortsForDropdown: any;
    setActiveTerminalPort: (data: any) => void;
    activeTerminalPorts: any;
    removeActiveTerminalPort: (port: any) => void;
    removeAllActiveTerminalPorts: () => void;
    removePortModalInfo: {
        isOpen: boolean;
        port: any;
    };
    setRemovePortModalInfo: (data: any) => void;
    setPortMessages: (data: any) => void;
    portMessages: any;
    setPortKeepFolder: (logPath: string[] | null, id: string) => void;
    isTerminalLoggingStarted: boolean;
    setPortIsLogging: (isLogging: boolean, id: string) => void;
    setCommand: (command: string) => void;
    command: string;
    commandHistory: string[];
    setCommandHistory: (commandHistory: string) => void;
    commandHistoryIndex: number;
    setCommandHistoryIndex: (index: number) => void;
    clearPortMessagesByPort: (port: string) => void;
}

const TerminalContext = createContext({} as TerminalContextState);

export default TerminalContext;
