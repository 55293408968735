import { InputAdornment, TextField } from "@mui/material";
import React, { useContext, useState } from "react";
import ThemeContext from "../../../context/theme/themeContext";
import { ReactComponent as TerminalArrowIcon } from "../../../assets/icons/terminal/TerminalArrow.svg";
import TerminalContext from "../../../context/components/terminal/terminalContext";
import useApi from "../../../utils/useApi";
import LanguageContext from "../../../context/language/languageContext";
import { PortMessageType } from "../../../constants/constants";
import useDebounce from "../../../utils/useDebounce";
import Tooltip from "../../MuiComponents/Tooltip";
import UserInfoContext from "../../../context/userInfo/userInfoContext";

export interface CommandInputProps {
    messagesEndRef: React.RefObject<HTMLDivElement>;
}

const CommandInput: React.FunctionComponent<CommandInputProps> = ({
    messagesEndRef,
}) => {
    const {
        colors: {
            gray300,
            gray700,
            textDark,
            gray400,
            blue700,
            gray100,
            white,
        },
        boxShadows: { focusBoxShadow },
    } = useContext(ThemeContext);
    const { t } = useContext(LanguageContext);
    const {
        command,
        setCommand,
        activeTab,
        setPortMessages,
        commandHistory,
        setCommandHistory,
        commandHistoryIndex,
        setCommandHistoryIndex,
        clearPortMessagesByPort,
    } = useContext(TerminalContext);
    const { isDeviceDisconnected } = useContext(UserInfoContext);

    const [isDisabled, setIsDisabled] = useState(false);

    const { postData } = useApi();

    const handleScrollToBottom = () => {
        messagesEndRef?.current?.scrollIntoView({ behavior: "smooth" });
    };
    const debouncedScrollToBottom = useDebounce(handleScrollToBottom, 500);

    const handleKeyDown = async (
        event: React.KeyboardEvent<HTMLInputElement>
    ) => {
        if (event.key === "Enter") {
            event.preventDefault();
            setCommandHistory(command);
            setCommandHistoryIndex(-1);
            setCommand("");
            if (command === "/clear") {
                clearPortMessagesByPort(activeTab?.port?.address);
                return;
            }
            setIsDisabled(true);
            await postData(`/terminal/deviceCommand`, {
                port: activeTab?.port?.address,
                command,
            });
            setPortMessages([
                {
                    message: `${t.CommandSent}: “${command}“`,
                    port: activeTab?.port?.address,
                    type: PortMessageType.Command,
                },
            ]);
            setIsDisabled(false);
            debouncedScrollToBottom();
        } else if (event.key === "ArrowUp") {
            event.preventDefault();
            if (commandHistoryIndex < commandHistory.length - 1) {
                setCommandHistoryIndex(commandHistoryIndex + 1);
                setCommand(commandHistory[commandHistoryIndex + 1]);
            }
        } else if (event.key === "ArrowDown") {
            event.preventDefault();
            if (commandHistoryIndex > 0) {
                setCommandHistoryIndex(commandHistoryIndex - 1);
                setCommand(commandHistory[commandHistoryIndex - 1]);
            } else {
                setCommandHistoryIndex(-1);
                setCommand("");
            }
        }
    };

    const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCommand(event.target.value);
        if (event.target.value === "") {
            setCommandHistoryIndex(-1);
        }
    };

    const renderTooltipTitle = () => {
        if (isDeviceDisconnected) {
            return t.DeviceIsDisconnected;
        }
        return "";
    };

    return (
        <Tooltip title={renderTooltipTitle()} small placement="top">
            <TextField
                autoFocus
                fullWidth
                variant="filled"
                placeholder={t.CommandInputPlaceholder}
                value={command}
                onChange={handleOnChange}
                onKeyDown={handleKeyDown}
                autoComplete="off"
                disabled={isDisabled || isDeviceDisconnected}
                InputProps={{
                    spellCheck: "false",
                    disableUnderline: true,

                    endAdornment: (
                        <InputAdornment position="end">
                            <TerminalArrowIcon />
                        </InputAdornment>
                    ),
                    sx: {
                        backgroundColor: "rgba(255, 255, 255, 0.05)",
                        color: textDark,
                        fontWeight: "600",
                        lineHeight: "20px",
                        letterSpacing: "0.1px",
                        padding: "6px 12px",
                        borderRadius: "6px",
                        fontSize: "14px",
                        border: `1px solid ${gray300}`,
                        "&:hover": {
                            borderColor: gray400,
                            backgroundColor: white,
                        },

                        "&.Mui-disabled": {
                            backgroundColor: gray100,
                        },
                        "&.Mui-focused": {
                            borderColor: blue700,
                            borderWidth: "1px",
                            boxShadow: focusBoxShadow,
                            backgroundColor: white,
                        },

                        "& .MuiFilledInput-input": {
                            padding: "0",
                            "&::placeholder": {
                                color: gray700,
                                lineHeight: "20px",
                                letterSpacing: "0.1px",
                            },
                        },

                        "& .MuiInputAdornment-root.MuiInputAdornment-positionStart.MuiInputAdornment-root:not(.MuiInputAdornment-hiddenLabel)":
                            {
                                marginTop: "0",
                            },

                        "& .MuiInputAdornment-root": {
                            color: gray700,
                            height: "unset",
                        },
                    },
                }}
            />
        </Tooltip>
    );
};

export default CommandInput;
