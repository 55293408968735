import React, { useReducer } from "react";
import {
    SET_ACTIVE_PRESETS,
    SET_BLUETOOTH_LIST_PARAMETER,
    SET_BLUETOOTH_LIST_PARAMETERS,
    SET_BLUETOOTH_TABLE_PARAMETER_DATA,
    SET_BLUETOOTH_TABLE_PARAMETERS_DATA,
    SET_DEVICE_TYPE,
    SET_DYNAMIC_SELECT_VALUE,
    SET_MODAL_BUTTON_DISABLED,
    SET_SECOND_DYNAMIC_SELECT_VALUE,
    SET_THIRD_DYNAMIC_SELECT_VALUE,
} from "./bluetoothModalsActions";
import BluetoothModalsContext from "./bluetoothModalsContext";
import BluetoothModalsReducer from "./bluetoothModalsReducer";

interface BluetoothModalsStateProps {
    children: React.ReactNode;
}

const BluetoothModalsState = ({ children }: BluetoothModalsStateProps) => {
    const initialState = {
        deviceType: null,
        dynamicSelectValue: null,
        secondDynamicSelectValue: null,
        thirdDynamicSelectValue: null,
        activePresets: [],
        isAddDeviceModalOpen: false,
        isEditDeviceModalOpen: false,
        bluetoothTableParameterData: [],
        bluetoothListParameters: [],
        isModalButtonDisabled: false,
    };

    const [state, dispatch] = useReducer(BluetoothModalsReducer, initialState);

    const setDynamicSelectValue = (data: any) => {
        dispatch({
            type: SET_DYNAMIC_SELECT_VALUE,
            payload: data,
        });
    };
    const setSecondDynamicSelectValue = (data: any) => {
        dispatch({
            type: SET_SECOND_DYNAMIC_SELECT_VALUE,
            payload: data,
        });
    };
    const setThirdDynamicSelectValue = (data: any) => {
        dispatch({
            type: SET_THIRD_DYNAMIC_SELECT_VALUE,
            payload: data,
        });
    };
    const setActivePresets = (data: { name: any }[]) => {
        if(!data){
            // if received no presets, do not invoke reducer, so to not create empty preset list
            return;
        }
        dispatch({
            type: SET_ACTIVE_PRESETS,
            payload: data,
        });
    };
    const setDeviceType = (data: { name: any }) => {
        dispatch({
            type: SET_DEVICE_TYPE,
            payload: data,
        });
    };

    const setBluetoothTableParameterData = (
        data: {
            id: number;
            value: string | number;
            oldValue?: string | number;
            error: string;
        } | null
    ) => {
        dispatch({
            type: SET_BLUETOOTH_TABLE_PARAMETER_DATA,
            payload: data,
        });
    };

    const setBluetoothTableParametersData = (
        data: {
            id: number;
            value: string | number;
            error: string;
        }[]
    ) => {
        dispatch({
            type: SET_BLUETOOTH_TABLE_PARAMETERS_DATA,
            payload: data,
        });
    };

    const setBluetoothListParameter = (
        data: {
            value: string;
            oldValue?: string;
            id: number;
            error: any;
        }
    ) => {
        dispatch({
            type: SET_BLUETOOTH_LIST_PARAMETER,
            payload: data,
        });
    };

    const setBluetoothListParameters = (
        data: {
            value: string;
            oldValue?: string;
            id: number;
            error: any;
        }[] | null
    ) => {
        dispatch({
            type: SET_BLUETOOTH_LIST_PARAMETERS,
            payload: data,
        });
    };

    const setModalButtonDisabled = (data: boolean) => {
        dispatch({
            type: SET_MODAL_BUTTON_DISABLED,
            payload: data,
        });
    };

    return (
        <BluetoothModalsContext.Provider
            value={{
                deviceType: state.deviceType,
                dynamicSelectValue: state.dynamicSelectValue,
                secondDynamicSelectValue: state.secondDynamicSelectValue,
                thirdDynamicSelectValue: state.thirdDynamicSelectValue,
                activePresets: state.activePresets,
                bluetoothTableParameterData: state.bluetoothTableParameterData,
                bluetoothListParameters: state.bluetoothListParameters,
                isModalButtonDisabled: state.isModalButtonDisabled,
                setDynamicSelectValue,
                setSecondDynamicSelectValue,
                setThirdDynamicSelectValue,
                setActivePresets,
                setDeviceType,
                setBluetoothTableParameterData,
                setBluetoothListParameter,
                setBluetoothListParameters,
                setModalButtonDisabled,
                setBluetoothTableParametersData,
            }}
        >
            {children}
        </BluetoothModalsContext.Provider>
    );
};

export default BluetoothModalsState;
