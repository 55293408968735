/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from "@emotion/react";

import { useContext, useEffect, useState } from "react";
import LayoutContext from "../../../context/layout/layoutContext";
import useMediaQuries from "../../../utils/useMediaQueries";
import DeviceStatusTopBar from "../../TopBar/DeviceStatusTopBar";
import Loader from "../../Loader";
import ErrorPage from "../../ErrorPage";
import SideNavigation from "./SideNavigation";
import Footer from "./Footer";
import useConfiguration from "../../../utils/useConfiguration";

export interface UserLayoutProps {
    children: React.ReactNode;
    hasOverFlow?: boolean;
}

const UserLayout = ({ children, hasOverFlow }: UserLayoutProps) => {
    const { layoutError, clearSearchHistory, drawerParameterId, layoutData } =
        useContext(LayoutContext);
    const isConfigurationSupported =
        layoutData?.isConfigurationSupported ?? false;

    const { toMd, fromMd } = useMediaQuries();
    const { getConfigurationFromOffline } = useConfiguration();

    const [isOpenMobileMenu, setOpenMobileMenu] = useState(fromMd);

    useEffect(() => {
        setOpenMobileMenu(fromMd);
    }, [fromMd]);

    /*Mock FMB003 data for faster development when on inside routes*/
    const getMockedDeviceData = async (isProDevice: boolean) => {
        // history.push(EDITOR_STATUS);

        try {
            await getConfigurationFromOffline(
                isProDevice ? "FMB640:1" : "FMB0:4"
            );
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        if (process.env.REACT_APP_ENABLE_AUTO_MOCK_DATA === "true") {
            getMockedDeviceData(false);
        }
    }, []);

    // Clear search history on editor unmount so when device will change new search history will begin
    // eslint-disable-next-line
    useEffect(() => clearSearchHistory, []);

    const toggleMobileMenu = () => setOpenMobileMenu(!isOpenMobileMenu);

    const renderContent = () => {
        if (layoutError) {
            return <ErrorPage />;
        }

        return !layoutData ? (
            <Loader />
        ) : (
            <div
                css={css({
                    display: "flex",
                    minHeight: "100vh",
                    marginRight: !!drawerParameterId ? "354px" : "initial",
                    "@media (max-width: 839px)": {
                        flexDirection: "column",
                        marginRight: "0px !important",
                    },
                    "@media (max-width: 1023px)": {
                        marginRight: !!drawerParameterId ? "198px" : "initial",
                    },
                })}
            >
                {toMd && (
                    <DeviceStatusTopBar
                        isMobile
                        isOpenMobileMenu={isOpenMobileMenu}
                        toggleMobileMenu={toggleMobileMenu}
                        disabled={!isConfigurationSupported}
                    />
                )}

                <SideNavigation
                    open={isOpenMobileMenu}
                    onNavigationItemClick={
                        fromMd ? () => {} : () => setOpenMobileMenu(false)
                    }
                />

                <div
                    css={css({
                        display: "flex",
                        flexDirection: "column",
                        flex: 1,
                        marginLeft: "236px",
                        "@media (max-width: 839px)": {
                            marginLeft: 0,
                        },
                        overflow: hasOverFlow ? "auto" : "unset",
                    })}
                >
                    {fromMd && (
                        <DeviceStatusTopBar
                            disabled={!isConfigurationSupported}
                        />
                    )}

                    <main
                        css={css({
                            flex: "1",
                            display: "flex",
                            justifyContent: "center",
                        })}
                    >
                        {children}
                    </main>

                    <Footer />
                </div>
            </div>
        );
    };

    return renderContent();
};

export default UserLayout;
