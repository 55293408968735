/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from "@emotion/react";

import TextBox from "./TextBox";
import NumberInput from "./NumberInput";
import Collection from "./Collection";
import MultiCollection from "./MultiCollection";
import List from "./List";
import Alert from "../../../../Alert";
import { Component as ComponentData } from "../../../../../generatedTypes";
import FileInput from "./FileInput";
import { useContext } from "react";
import LayoutContext from "../../../../../context/layout/layoutContext";
import CertificateFileInput from "./CertificateFileInput";
import BinaryInput from "./BinaryInput";
import { AlertStatus } from "../../../../../constants/constants";

export interface ComponentProps {
    data: ComponentData;
    lastComponent: boolean;
    fullComponentWidth?: boolean;
}

const Component = ({
    data,
    lastComponent,
    fullComponentWidth,
}: ComponentProps) => {
    const { componentType } = data;

    const { layoutData } = useContext(LayoutContext);

    const parameterValueFromContextWithData = data;

    const initiaListValuesFromContextWithData = data;

    const renderComponentType = () => {
        if (data.switch)
            return <BinaryInput data={parameterValueFromContextWithData} />;
        switch (componentType) {
            case "TextBox":
                return <TextBox data={parameterValueFromContextWithData} />;
            case "NumberInput":
                return <NumberInput data={parameterValueFromContextWithData} />;
            case "Collection":
                return <Collection data={parameterValueFromContextWithData} />;
            case "MultiCollection":
                return (
                    <MultiCollection data={parameterValueFromContextWithData} />
                );
            case "List":
                return <List data={initiaListValuesFromContextWithData} />;
            case "FileTransfer":
                return layoutData?.isOnline ? <FileInput data={data} /> : null;
            case "CertificateFileTransfer":
                return layoutData?.isOnline ? (
                    <CertificateFileInput data={data} />
                ) : null;
            default:
                return (
                    <Alert
                        status={AlertStatus.Critical}
                        title="No component is shown"
                    />
                );
        }
    };

    return (
        <div
            css={css`
                margin-bottom: ${lastComponent ? "0" : "16px"};
                width: ${fullComponentWidth ? "100%" : "auto"};
            `}
        >
            {renderComponentType()}
        </div>
    );
};

export default Component;
