/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from "@emotion/react";

import {
    useContext,
    useReducer,
    ChangeEvent,
    MouseEvent,
    useEffect,
} from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import ThemeContext from "../../../../../context/theme/themeContext";
import LanguageContext from "../../../../../context/language/languageContext";
import AlertContext from "../../../../../context/alert/alertContext";
import useApi from "../../../../../utils/useApi";
import unlockCodeModalReducer from "./deviceLockedModalReducer";
import PasswordModal from "../../../../MuiComponents/Modals/PasswordModal";
import Tooltip from "../../../../MuiComponents/Tooltip";
import InputField from "../../../../MuiComponents/InputField";
import Button from "../../../../MuiComponents/Button";
import DeviceLockedIcon from "../../../../../assets/icons/DeviceLockedIcon";
import IconButton from "@mui/material/IconButton";
import { Visibility, VisibilityOff, ContentCopy } from "@mui/icons-material";
import { noop } from "../../../../../utils/helpers";
import {
    SET_LOADING,
    RENDER_UNLOCK_CODE,
    HANDLE_CHANGE_CODE,
    HANDLE_VISIBILITY,
    HANDLE_COPY,
    HANDLE_ERROR,
} from "./deviceLockedModalActions";
import DeviceAuthorizationContext from "../../../../../context/deviceAuthorization/deviceAuthorizationContext";
import { AlertStatus } from "../../../../../constants/constants";

const DeviceLockedModal = () => {
    const {
        colors: { textDark },
    } = useContext(ThemeContext);

    const { selectedLanguage } = useContext(LanguageContext);
    const { setAlert } = useContext(AlertContext);
    const {
        isUnlockModalOpen,
        setUnlockModalOpen,
        connectAction,
        deviceUnlockCode,
        port,
        connectionType,
    } = useContext(DeviceAuthorizationContext);

    const { postData, isAxiosError, disconnectFromDevice } = useApi();

    const initialState = {
        isUnlockCodeModal: false,
        isLoading: false,
        isDisabled: true,
        code: "",
        codeShow: false,
        isCopied: false,
        errorCode: null,
    };

    const [state, dispatch] = useReducer(unlockCodeModalReducer, initialState);

    const {
        isUnlockCodeModal,
        isLoading,
        isDisabled,
        code,
        codeShow,
        isCopied,
        errorCode,
    } = state;

    const closeModal = async () => {
        await disconnectFromDevice(selectedLanguage, true);
        setUnlockModalOpen(false);
    };

    const setLoading = (isLoadingSet: boolean) =>
        dispatch({ type: SET_LOADING, payload: isLoadingSet });

    const renderUnlockCode = () => dispatch({ type: RENDER_UNLOCK_CODE });

    const handleChange = (e: ChangeEvent<HTMLInputElement>) =>
        dispatch({
            type: HANDLE_CHANGE_CODE,
            payload: e.target.value,
        });

    const handleToggleVisibility = (e: MouseEvent<HTMLButtonElement>) => {
        dispatch({ type: HANDLE_VISIBILITY });
        e.preventDefault();
    };

    const handleCopy = (value: boolean) =>
        dispatch({ type: HANDLE_COPY, payload: value });

    const handleError = (errorMsg: string | null) =>
        dispatch({ type: HANDLE_ERROR, payload: errorMsg });

    const sendPayload = async () => {
        try {
            setLoading(true);

            await postData(`${selectedLanguage}/device/enterunlockcode`, {
                port,
                unlockCode: code,
                connectionType,
            });

            connectAction(true);
        } catch (error) {
            if (isAxiosError(error) && (error as any).response) {
                const errorStatus = (error as any).response.status;
                const errorData = (error as any).response.data;

                if (errorStatus === 401) {
                    handleError("Wrong code");

                    setLoading(false);
                } else {
                    setAlert(AlertStatus.Critical, errorData.title);
                    await closeModal();
                }
            }
        }
    };

    const inputType = codeShow ? "text" : "password";
    const visibilityIcon = codeShow ? <VisibilityOff /> : <Visibility />;
    const visibilityTooltipTitle = codeShow ? "Hide code" : "Show code";
    const copyTooltipTitle = isCopied ? "Copied" : "Copy to clipboard";

    return (
        <PasswordModal
            isOpen={isUnlockModalOpen}
            isDisabled={isUnlockCodeModal ? isDisabled : false}
            isLoading={isUnlockCodeModal ? isLoading : false}
            title={
                isUnlockCodeModal
                    ? "Enter unlock code"
                    : "Device temporarily locked"
            }
            submitBtnText="Unlock"
            close={closeModal}
            sendPayload={isUnlockCodeModal ? sendPayload : renderUnlockCode}
        >
            {isUnlockCodeModal ? (
                <InputField
                    fullWidth
                    size="medium"
                    label="Unlock code"
                    placeholder="Enter"
                    type={inputType}
                    error={errorCode}
                    onFocus={() => (errorCode ? handleError(null) : noop())}
                    onChange={handleChange}
                    iconRight={
                        <Tooltip
                            title={visibilityTooltipTitle}
                            placement="top-end"
                            css={css({
                                "& .MuiTooltip-tooltip": {
                                    marginRight: "-6px",
                                },
                            })}
                        >
                            <IconButton
                                disableRipple
                                onMouseDown={handleToggleVisibility}
                                size="large"
                            >
                                {visibilityIcon}
                            </IconButton>
                        </Tooltip>
                    }
                />
            ) : (
                <div
                    css={css({
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",

                        "& > svg": {
                            fontSize: "96px",
                            marginBottom: "16px",
                        },

                        "& > div": {
                            color: textDark,
                            lineHeight: "20px",
                            letterSpacing: "0.1px",
                            wordBreak: "break-all",
                        },

                        "& > div:first-of-type": {
                            fontWeight: "600",
                            marginBottom: "16px",
                            textAlign: "center",
                        },
                    })}
                >
                    <DeviceLockedIcon width={96} height={96} />

                    <div>Too many incorrect attempts</div>

                    <div>
                        Device temporarily locked, due to a large number of
                        incorrect password attempts. Please reach out and send
                        this code to Teltonika Telematics sales representative
                        or technical support:
                    </div>

                    <div
                        css={css({
                            display: "flex",
                            alignItems: "center",
                            fontWeight: "700",
                            margin: "16px 0",
                        })}
                    >
                        {deviceUnlockCode}

                        <CopyToClipboard
                            text={deviceUnlockCode}
                            onCopy={() => handleCopy(true)}
                        >
                            <Tooltip
                                title={copyTooltipTitle}
                                placement="top"
                                TransitionProps={{
                                    onExited: () => handleCopy(false),
                                }}
                            >
                                <Button
                                    variant="iconOnly"
                                    size="tiny"
                                    color="white"
                                    icon={<ContentCopy />}
                                    css={css({
                                        marginLeft: "8px",
                                    })}
                                    idForTesting="copyUnlockCode"
                                />
                            </Tooltip>
                        </CopyToClipboard>
                    </div>

                    <div>
                        The technical support or sales representative will
                        provide you with the unlock code so that you can unlock
                        the device again.
                    </div>
                </div>
            )}
        </PasswordModal>
    );
};

export default DeviceLockedModal;
