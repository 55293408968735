import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import AlertContext from "../../../context/alert/alertContext";
import LanguageContext from "../../../context/language/languageContext";
import LayoutContext from "../../../context/layout/layoutContext";
import MenuItemContext from "../../../context/menuItem/menuItemContext";
import useApi from "../../../utils/useApi";
import MenuItem from "./MenuItem";
import Loader from "../../Loader/EditorSkeleton/GeneralSkeleton";
import UserLayout from "../UserLayout";
import UserInfoContext from "../../../context/userInfo/userInfoContext";
import AppTypeContext from "../../../context/appType/appTypeContext";
import SettingsContext from "../../../context/settings/settingsContext";
import useConfiguration from "../../../utils/useConfiguration";
import useTct from "../../../utils/useTct";

export type MenuItemViewParams = {
    menuItem: string;
};

const MenuItemView = () => {
    const { selectedLanguage } = useContext(LanguageContext);
    const { isDesktopApplication } = useContext(UserInfoContext);
    const { isEditor, fileId } = useContext(AppTypeContext);
    const { layoutData, setLayoutError, actionLoading, stopActionLoading } =
        useContext(LayoutContext);
    const { frames, setFrames, setBreadCrumbItems } =
        useContext(MenuItemContext);
    const { openDeviceGuideSteps, deviceGuideStepIndex } =
        useContext(SettingsContext);

    const configurationId = layoutData?.id;
    const menuItems = layoutData?.menuItems;
    const { cancelSource, isCanceled } = useApi();
    const { getMenuItemAsync } = useTct();
    const { getConfigurationFromFota } = useConfiguration();

    const [loading, setLoading] = useState(true);

    const { menuItem } = useParams<MenuItemViewParams>();
    const getLayoutData = async () => {
        try {
            await getConfigurationFromFota();
        } catch (error) {
            setLayoutError(error);
        }
    };

    useEffect(() => {
        const isWebEditor = !isDesktopApplication && isEditor;
        if (isWebEditor && !layoutData && fileId) {
            getLayoutData();
        }
        return () => {
            window.scrollTo(0, 0);
        };
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        let isSubscribed = true;
        const getMenuItemData = async () => {
            try {
                setLoading(true);
                const data = await getMenuItemAsync(configurationId ?? 0, Number(menuItem));

                if (isSubscribed) {
                    setFrames(data.frames);
                    setBreadCrumbItems(data.frames);
                    actionLoading && stopActionLoading();
                    setLoading(false);
                }
            } catch (error) {
                if (isSubscribed && !isCanceled(error)) {
                    setFrames(null);
                    actionLoading && stopActionLoading();
                    setLoading(false);
                }
            }
        };
        if (
            configurationId &&
            Number(menuItem) >= 0 &&
            menuItems &&
            menuItems.length > Number(menuItem)
        ) {
            getMenuItemData();
        }

        return () => {
            isSubscribed = false;
            cancelSource.cancel("Api is being cancelled");
        };

        // eslint-disable-next-line
    }, [menuItem, menuItems, selectedLanguage]);

    useEffect(() => {
        if (deviceGuideStepIndex !== null && !loading) {
            openDeviceGuideSteps();
        }
        // eslint-disable-next-line
    }, [loading, deviceGuideStepIndex]);

    const isBluetooth = frames?.[0]?.menuItemName === "Bluetooth";

    const renderContent = () => {
        if (actionLoading || loading) {
            return (
                <Loader
                    hasOneColumn={true}
                    hasDivider={true}
                    hasNoLoadingText={true}
                />
            );
        }

        return !frames ? <div>Menu item error page</div> : <MenuItem />;
    };

    return <UserLayout hasOverFlow={isBluetooth}>{renderContent()}</UserLayout>;
};

export default MenuItemView;
