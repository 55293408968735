import React, { useContext } from "react";
import { Global, css } from "@emotion/react";
import ThemeContext from "../../context/theme/themeContext";
import LanguageContext from "../../context/language/languageContext";

const GlobalStyles = () => {
    const { colors } = useContext(ThemeContext);

    const { loading } = useContext(LanguageContext);

    return (
        <Global
            styles={css`
                html {
                    height: 100%;
                }
                &::-webkit-scrollbar {
                    width: 18px;
                }

                &::-webkit-scrollbar-thumb {
                    background-color: #dde9fb;
                    border-radius: 100px;
                    border: 5px solid #ffffff;
                }

                &::-webkit-scrollbar-thumb:hover {
                    background-color: A0D0FF;
                }
                body {
                    min-height: 100vh;
                    margin: 0;
                    font-family: "Open Sans", sans-serif;
                    font-size: 14px;
                    background: ${colors.gray50};
                    opacity: ${loading ? 0.4 : 1};
                    pointer-events: ${loading ? "none" : "auto"};
                }

                h1,
                h2,
                h3,
                h4,
                h5,
                h6,
                p {
                    margin: 0;
                }

                #root {
                    min-height: 100vh;
                }

                #root,
                .remaining-height {
                    display: flex;
                    flex-direction: column;
                    flex: 1;
                }
                .sentry-error-embed-wrapper .powered-by {
                    display: none;
                }
                .leaflet-draw-actions {
                    display: none !important;
                }
                .react-tel-input {
                    .invalid-number-message {
                        display: none;
                    }
                    .invalid-number {
                        border-color: #991b1b !important;
                        background-color: unset !important;
                    }
                    .country-list {
                        max-height: 110px !important;
                        &::-webkit-scrollbar {
                            width: 18px;
                        }

                        &::-webkit-scrollbar-thumb {
                            background-color: #c5e2ff;
                            border-radius: 100px;
                            border: 5px solid #ffffff;
                        }

                        &::-webkit-scrollbar-thumb:hover {
                            background-color: A0D0FF;
                        }
                    }
                }
            `}
        />
    );
};

export default GlobalStyles;
