import React from "react";
import Switch from "../../../../MuiComponents/Switch";

export interface SwitchInputProps {
    isSwitched: boolean;
    onChange: (e: any) => void;
}

const SwitchInput = ({ isSwitched, onChange }: SwitchInputProps) => {
    return (
        <Switch
            checked={isSwitched}
            onChange={onChange}
            size="medium"
            labelPlacement="start"
            idForTesting="switch-input"
        />
    );
};

export default SwitchInput;
