import { useContext } from "react";
import useApi from "./useApi";
import UserInfoContext from "../context/userInfo/userInfoContext";
import LanguageContext from "../context/language/languageContext";
import { FEElement } from "../generatedTypes";
import SettingsContext from "../context/settings/settingsContext";

const useAutoUpdate = () => {
    const { getData } = useApi();
    const { selectedLanguage } = useContext(LanguageContext);
    const { isDesktopApplication } = useContext(UserInfoContext);
    const { setReleaseNotes } = useContext(SettingsContext);

    const getReleaseNotes = async () => {
        if (isDesktopApplication) {
            try {
                const { data }: { data: FEElement[] } = await getData(
                    `${selectedLanguage}/autoupdate/releasenotes`
                );
                setReleaseNotes(data);
            } catch (error) {
                setReleaseNotes(null);
            }
        }
    };

    return { getReleaseNotes };
};

export default useAutoUpdate;
