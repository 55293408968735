/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from "@emotion/react";

import Button from "../../../MuiComponents/Button";
import Stack from "@mui/material/Stack";
import FirstPageIcon from "@mui/icons-material/FirstPageRounded";
import PreviousPageIcon from "@mui/icons-material/ChevronLeftRounded";
import NextPageIcon from "@mui/icons-material/ChevronRightRounded";
import LastPageIcon from "@mui/icons-material/LastPageRounded";

export interface PaginationProps {
    count: number;
    page: number;
    rowsPerPage: number;
    onPageChange: (event: unknown, newPage: number) => void;
}

const Pagination = ({
    count,
    page,
    rowsPerPage,
    onPageChange,
}: PaginationProps) => {
    const handleFirstPageBtnClick = (e: any) => onPageChange(e, 0);

    const handlePreviousPageBtnClick = (e: any) => onPageChange(e, page - 1);

    const handleNextPageBtnClick = (e: any) => onPageChange(e, page + 1);

    const handleLastPageBtnClick = (e: any) =>
        onPageChange(e, Math.max(0, Math.ceil(count / rowsPerPage) - 1));

    return (
        <Stack
            direction="row"
            css={css`
                margin-left: 16px;

                button + button {
                    margin-left: 8px;
                }
            `}
        >
            <Button
                variant="iconOnly"
                size="small"
                color="white"
                icon={<FirstPageIcon />}
                disabled={page === 0}
                onClick={handleFirstPageBtnClick}
                idForTesting="first-page-btn"
            />

            <Button
                variant="iconOnly"
                size="small"
                color="white"
                icon={<PreviousPageIcon />}
                disabled={page === 0}
                onClick={handlePreviousPageBtnClick}
                idForTesting="previous-page-btn"
            />

            <Button
                variant="iconOnly"
                size="small"
                color="white"
                icon={<NextPageIcon />}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                onClick={handleNextPageBtnClick}
                idForTesting="next-page-btn"
            />

            <Button
                variant="iconOnly"
                size="small"
                color="white"
                icon={<LastPageIcon />}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                onClick={handleLastPageBtnClick}
                idForTesting="last-page-btn"
            />
        </Stack>
    );
};

export default Pagination;
