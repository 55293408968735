import { Templates } from "../../constants/constants";
import {
    SET_ACTIVE_PRESETS,
    SET_BLUETOOTH_LIST_PARAMETER,
    SET_BLUETOOTH_LIST_PARAMETERS,
    SET_BLUETOOTH_TABLE_PARAMETER_DATA,
    SET_BLUETOOTH_TABLE_PARAMETERS_DATA,
    SET_DEVICE_TYPE,
    SET_DYNAMIC_SELECT_VALUE,
    SET_MODAL_BUTTON_DISABLED,
    SET_SECOND_DYNAMIC_SELECT_VALUE,
    SET_THIRD_DYNAMIC_SELECT_VALUE,
} from "./bluetoothModalsActions";

export interface InitialState {
    deviceType: any | null;
    dynamicSelectValue: any | null;
    secondDynamicSelectValue: any | null;
    thirdDynamicSelectValue: any | null;
    activePresets: { name: any }[];
    bluetoothTableParameterData: any;
    bluetoothListParameters: any;
    isModalButtonDisabled: boolean;
}

export default (state: any, action: any): any => {
    switch (action.type) {
        case SET_DEVICE_TYPE: {
            return {
                ...state,
                deviceType: action.payload,
            };
        }
        case SET_DYNAMIC_SELECT_VALUE: {
            return {
                ...state,
                dynamicSelectValue: action.payload,
            };
        }
        case SET_SECOND_DYNAMIC_SELECT_VALUE: {
            return {
                ...state,
                secondDynamicSelectValue: action.payload,
            };
        }
        case SET_THIRD_DYNAMIC_SELECT_VALUE: {
            return {
                ...state,
                thirdDynamicSelectValue: action.payload,
            };
        }
        case SET_ACTIVE_PRESETS: {
            return {
                ...state,
                activePresets: [{ name: Templates.Blank }, ...action.payload],
            };
        }

        case SET_BLUETOOTH_TABLE_PARAMETER_DATA: {
            const parametersArray = () => {
                const newState = [...state.bluetoothTableParameterData];
                const index = newState.findIndex(
                    (s) => s.id === action.payload.id
                );
                if (index === -1) {
                    newState.push({
                        id: action.payload.id,
                        value: action.payload.value,
                        oldValue: action.payload.oldValue,
                        error: action.payload.error,
                    });
                } else {
                    newState[index] = {
                        value: action.payload.value,
                        id: action.payload.id,
                        oldValue: action.payload.oldValue,
                        error: action.payload.error,
                    };
                }
                return newState;
            };
            return {
                ...state,
                bluetoothTableParameterData: !action.payload
                    ? []
                    : parametersArray(),
            };
        }
        case SET_BLUETOOTH_TABLE_PARAMETERS_DATA: {
            const newArray = [
                ...state.bluetoothTableParameterData.filter(
                    (item: any) => item.uniqueTag !== "tableWithPreset"
                ),
                ...action.payload,
            ];
            return {
                ...state,
                bluetoothTableParameterData: newArray,
            };
        }
        case SET_BLUETOOTH_LIST_PARAMETER: {
            return {
                ...state,
                bluetoothListParameters: addOrUpdateValuesInArray(
                    state.bluetoothListParameters,
                    [{
                        value: action.payload.value,
                        id: action.payload.id,
                        error: action.payload.error,
                        oldValue: action.payload.oldValue
                    }]
                ),
            };
        }
        case SET_BLUETOOTH_LIST_PARAMETERS: {
            return {
                ...state,
                bluetoothListParameters: action.payload?.length > 0
                    ? addOrUpdateValuesInArray(state.bluetoothListParameters, action.payload)
                    : [],
            };
        }
        case SET_MODAL_BUTTON_DISABLED: {
            return {
                ...state,
                isModalButtonDisabled: action.payload,
            };
        }
        default:
            return state;
    }
};

const addOrUpdateValuesInArray = (
    array: any[],
    values: {
        value: string,
        id: number,
        error: any,
        oldValue?: string
    }[]
) => {
    const newArray = [...array];
    values.forEach(value => {
        const index = newArray.findIndex(item => item.id === value.id);
        if (index === -1) {
            newArray.push({ value: value.value, oldValue: value.oldValue, id: value.id, error: value.error });
        } else {
            newArray[index] = {
                value: value.value,
                oldValue: value.oldValue,
                id: value.id,
                error: value.error
            };
        }
    });
    return newArray;
};
