import React, { useContext, useReducer } from "react";
import LanguageContext from "../language/languageContext";
import UserInfoContext from "./userInfoContext";
import UserInfoReducer from "./userInfoReducer";
import {
    SET_DEFAULT_SPECS,
    SET_DEVICE_DISCONNECTED,
    SET_LOADING,
    SET_SPECES,
    SET_STARTUP_WEBSOCKETS_ACTIVE,
    SET_USER_INFO,
    SET_VISIT_COUNT,
} from "./userInfoActions";
import useTct from "../../utils/useTct";

interface UserInfoStateProps {
    children: React.ReactNode;
}

const UserInfoState = ({ children }: UserInfoStateProps) => {
    const { setInitialLanguagesData } = useContext(LanguageContext);

    const { getUserInfoAsync, getLanguagesAsync, changeLanguageAsync, getSystemInformationAsync } =
        useTct();

    const initialState = {
        loading: true,
        configuratorVersion: "",
        desktopVersion: false,
        enableSentryCrashReport: false,
        isStartUpWebsocketsActive: false,
        isDeviceDisconnected: false,
        enableTerminal: false,
        firstTimeOnStartup: true,
        visitCount: 0,
        speces: [],
        defaultSpecs: [],
    };

    const [state, dispatch] = useReducer(UserInfoReducer, initialState);

    const setLoading = () => dispatch({ type: SET_LOADING });

    const setUserInfo = (data: any) =>
        dispatch({ type: SET_USER_INFO, payload: data });

    const setStartupWebsocketsActive = (data: boolean) =>
        dispatch({ type: SET_STARTUP_WEBSOCKETS_ACTIVE, payload: data });

    const setDeviceDisconnected = (data: boolean) =>
        dispatch({ type: SET_DEVICE_DISCONNECTED, payload: data });

    const setSpeces = (speces: any[]) =>
        dispatch({ type: SET_SPECES, payload: speces });

    const setDefaultSpecs = (defaultSpecs: any[]) =>
        dispatch({ type: SET_DEFAULT_SPECS, payload: defaultSpecs });

    const getInitialUserInfo = async () => {
        try {
            setLoading();

            const {
                appVersion,
                desktopVersion,
                lastSelectedLanguage: lang,
                enableSentryCrashReport,
                enableTerminal,
            } = await getUserInfoAsync();

            await getSystemInformationAsync();
            const languagesRequest = getLanguagesAsync();
            const translationsRequest = changeLanguageAsync(lang, 0);

            const languages = await languagesRequest;
            const translations = await translationsRequest;

            const payloadData = {
                languages: languages,
                translations: translations,
                language: lang,
            };

            setInitialLanguagesData(payloadData);

            setUserInfo({
                configurationVersion: appVersion,
                desktopVersion,
                enableSentryCrashReport,
                enableTerminal,
            });
        } catch (error) {
            console.log("error: ", error);
        }
    };

    const setVisitCount = () => dispatch({ type: SET_VISIT_COUNT });

    return (
        <UserInfoContext.Provider
            value={{
                loading: state.loading,
                configuratorVersion: state.configuratorVersion,
                isDesktopApplication: state.desktopVersion,
                isStartUpWebsocketsActive: state.isStartUpWebsocketsActive,
                enableSentryCrashReport: state.enableSentryCrashReport,
                enableTerminal: state.enableTerminal,
                isDeviceDisconnected: state.isDeviceDisconnected,
                isFirstTimeOnStartup: state.visitCount === 0,
                speces: state.speces,
                defaultSpecs: state.defaultSpecs,
                getInitialUserInfo,
                setStartupWebsocketsActive,
                setDeviceDisconnected,
                setVisitCount,
                setSpeces,
                setDefaultSpecs,
            }}
        >
            {children}
        </UserInfoContext.Provider>
    );
};

export default UserInfoState;
