/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import { LatLngBoundsExpression } from "leaflet";
import { MapContainer, TileLayer } from "react-leaflet";

export interface LocationMapProps {
    center: [number, number];
    children: React.ReactNode;
    zoom: number;
    addMarginTop?: boolean;
    bounds?: LatLngBoundsExpression;
}
const LocationMap = ({
    center,
    children,
    zoom,
    addMarginTop = false,
    bounds,
}: LocationMapProps) => {
    return (
        <MapContainer
            center={center}
            zoom={zoom}
            minZoom={3}
            attributionControl={false}
            maxBounds={bounds}
            maxBoundsViscosity={1.0}
            css={
                addMarginTop &&
                css({
                    marginTop: 24,
                })
            }
        >
            <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
            {children}
        </MapContainer>
    );
};

export default LocationMap;
