import React from "react";
import { SvgIconProps } from "@mui/material/SvgIcon";
import {ReactComponent as EmptySearch} from "./EmptySearchResultsIcon.svg";

export interface EmptySearchResultsIconProps {
    props?: SvgIconProps;
}

const EmptySearchResultsIcon = ({ ...props }: EmptySearchResultsIconProps) => <EmptySearch />;

export default EmptySearchResultsIcon;
