import React from "react";
import GroupBox from "./GroupBox";
import Component from "./Component";
import MultiColTable from "./MultiColTable";
import Alert from "../../../Alert";
import EcoCalculatorModalSection from "./CardSectionsWithModals/EcoCalculatorModalSection";
import SetSmsModalSection from "./CardSectionsWithModals/SetSmsModalSection";
import ScheduleModalSection from "./CardSectionsWithModals/ScheduleModalSection";
import { Segment as SegmentData } from "../../../../generatedTypes";
import PasswordBox from "./PasswordBox";
import Label from "./Label";
import LinkBoxes from "./LinkBoxes";
import BluetoothView from "./BluetoothView/BluetoothView";
import ManualGeofenceView from "./ManualGeofence";
import RangeSlider from "../../../MuiComponents/RangeSlider";
import CoordinatesBox from "./CoordinatesBox";
import UiWidget from "./UiWidget";
import GenericCommandButton from "./GenericCommandButton";
import { AlertStatus } from "../../../../constants/constants";

export interface SegmentProps {
    data: SegmentData;
    lastSegment: boolean;
    fullComponentWidth?: boolean;
}

const Segment = ({ data, lastSegment, fullComponentWidth }: SegmentProps) => {
    const {
        component,
        groupBox,
        table,
        modal,
        ecoCalculator,
        schedule,
        passwordBox,
        label,
        linkBox,
        bluetoothView,
        manualGeofence,
        rangeSlider,
        geoCoordinates,
        uiWidget,
        button,
    } = data;

    const renderSegment = () => {
        if (uiWidget) {
            return <UiWidget data={uiWidget} />;
        }
        if (button) {
            return <GenericCommandButton data={button} />;
        }
        if (groupBox) {
            return <GroupBox data={groupBox} lastGroupBox={lastSegment} />;
        }
        if (table) {
            return <MultiColTable data={table} />;
        }
        if (modal) {
            return <SetSmsModalSection data={modal} />;
        }
        if (ecoCalculator) {
            return <EcoCalculatorModalSection data={ecoCalculator} />;
        }
        if (schedule) {
            return <ScheduleModalSection data={schedule} />;
        }
        if (passwordBox) {
            return <PasswordBox data={passwordBox} />;
        }
        if (bluetoothView) {
            return <BluetoothView />;
        }
        if (label) {
            return <Label data={label} />;
        }
        if (linkBox) {
            return <LinkBoxes data={linkBox as any} />;
        }
        if (manualGeofence) {
            return <ManualGeofenceView data={manualGeofence} />;
        }
        if (rangeSlider) {
            return <RangeSlider data={rangeSlider} />;
        }
        if (geoCoordinates) {
            return <CoordinatesBox data={geoCoordinates} />;
        }
        return component ? (
            <Component
                data={component}
                lastComponent={lastSegment}
                fullComponentWidth={fullComponentWidth}
            />
        ) : (
            <Alert status={AlertStatus.Critical} title="No segment is shown" />
        );
    };

    return renderSegment();
};

export default Segment;
