/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import { ExpandMore as ExpandMoreIcon } from "@mui/icons-material";
import { useContext, useEffect, useState } from "react";
import avlSearchContext from "../../../../../../context/avlSearch/avlSearchContext";
import MenuItemContext from "../../../../../../context/menuItem/menuItemContext";
import { getIdTooltipText } from "../../../../../../utils/helpers";
import Tag from "../../../../../MuiComponents/Tag";
import Tooltip from "../../../../../MuiComponents/Tooltip";
import LanguageContext from "../../../../../../context/language/languageContext";
import ThemeContext from "../../../../../../context/theme/themeContext";

export interface PopoverInputProps {
    data: any;
}

const PopoverInput: React.FunctionComponent<PopoverInputProps> = ({ data }) => {
    const { parameterId, avlId, parameterValue } = data;
    const { t } = useContext(LanguageContext);

    const {
        colors: { textPlaceholder, textDark },
    } = useContext(ThemeContext);

    const {
        setModalAnchor,
        openModalAnchor,
        setActiveParameterId,
        parameterNames,
    } = useContext(avlSearchContext);

    const { parameters } = useContext(MenuItemContext);

    const [value, setValue] = useState(parameterValue);

    const handleOpen = (e: any) => {
        setActiveParameterId(parameterId);
        setModalAnchor(e.currentTarget);
    };

    const handleClose = () => {
        setActiveParameterId("");
        setModalAnchor(null);
    };

    const toggling = (e: any) => {
        !openModalAnchor ? handleOpen(e) : handleClose();
    };
    const paramValFromContext = parameters[parameterId];

    useEffect(() => {
        if (paramValFromContext) {
            setValue(paramValFromContext);
        }
    }, [paramValFromContext]);

    const parameterName = parameterNames.find(
        (item) => item.parameterId === parameterId
    )?.parameterName;

    return (
        <div
            css={css({
                padding: "16px 16px 16px 24px",
                display: "flex",
                justifyContent: "space-between",
                cursor: "pointer",
                minHeight: "36px",
                alignItems: "center",
            })}
            data-test={String(parameterId)}
            onClick={toggling}
        >
            {value !== "0" ? (
                <div css={css({})}>
                    <div
                        css={css({
                            fontStyle: "normal",
                            fontWeight: 600,
                            fontSize: "10px",
                            lineHeight: "12px",
                            letterSpacing: "1px",
                            color: textPlaceholder,
                        })}
                    >
                        AVL ID: {value}{" "}
                    </div>
                    <div
                        css={css({
                            fontStyle: "normal",
                            fontWeight: 600,
                            fontSize: "14px",
                            lineHeight: "20px",
                            letterSpacing: "0.1px",
                            color: textDark,
                        })}
                    >
                        {parameterName}
                    </div>
                </div>
            ) : (
                <div
                    css={css({
                        fontStyle: "normal",
                        fontWeight: 600,
                        fontSize: "14px",
                        lineHeight: "20px",
                        letterSpacing: "0.1px",
                        color: textPlaceholder,
                    })}
                >
                    {t.SearchName}
                </div>
            )}
            <div
                css={css({
                    alignItems: "center",
                    display: "flex",
                })}
            >
                <Tooltip
                    title={getIdTooltipText(parameterId, avlId)}
                    small
                    placement="top"
                >
                    <span>
                        <Tag size="tiny" color="white" title="ID" />
                    </span>
                </Tooltip>
                <ExpandMoreIcon
                    css={css({
                        transform: !!openModalAnchor
                            ? "rotate(-180deg)"
                            : "unset",
                        marginLeft: "14px",
                    })}
                />
            </div>
        </div>
    );
};

export default PopoverInput;
