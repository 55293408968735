/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from "@emotion/react";

import { useContext, useMemo } from "react";
import SettingsContext from "../../context/settings/settingsContext";
import ThemeContext from "../../context/theme/themeContext";
import useNavigation from "../../utils/useNavigation";
import Button from "../MuiComponents/Button";
import useMediaQueries from "../../utils/useMediaQueries";
import LanguageContext from "../../context/language/languageContext";
import { DeviceGuideType } from "../../constants/constants";
import useDeviceGuideProvider from "../../utils/useDeviceGuide";
import LayoutContext from "../../context/layout/layoutContext";

export interface GuideTooltipProps {
    step: any;
    tooltipProps: any;
    primaryProps: any;
    index: number;
    isLastStep: boolean;
}
const GuideTooltip = ({
    step,
    tooltipProps,
    index,
    isLastStep,
}: GuideTooltipProps) => {
    const {
        colors: { gray700, white },
    } = useContext(ThemeContext);
    const {
        closeDeviceGuideSteps,
        isDeviceGuideButtonDisabled,
        setDeviceGuideStepIndex,
        setIsDeviceGuideInProgress,
    } = useContext(SettingsContext);
    const { t } = useContext(LanguageContext);
    const {deviceGuide} = useContext(LayoutContext);

    const currentStep = useMemo(
        () => deviceGuide?.guideSteps[index],
        [deviceGuide]
    );

    const { handleDeviceGuideNavigation } = useNavigation();
    const { toMd } = useMediaQueries();
    const { setGuideStepCompleted } = useDeviceGuideProvider();

    const handleClick = async () => {
        try {
            if (isLastStep) {
                setIsDeviceGuideInProgress(false);
                closeDeviceGuideSteps();
                setDeviceGuideStepIndex(null);
            }
            if (!isLastStep) {
                handleDeviceGuideNavigation(
                    step.nextSearchPath,
                    index + 1,
                    step.hasNoRedirect
                );
            }
            if (
                currentStep &&
                currentStep.guideType === (DeviceGuideType.Optional as string)
            ) {
                await setGuideStepCompleted(index);
            }
        } catch (err) {
            console.log(err);
        }
    };

    return (
        <div
            css={css({
                padding: "16px",
                backgroundColor: gray700,
                maxWidth: "342px",
                width: toMd ? "80vw" : "100%",
                borderRadius: "12px",
            })}
            {...tooltipProps}
        >
            <div
                css={css({
                    marginBottom: "8px",
                    color: white,
                    fontWeight: 600,
                })}
            >
                {step.title}
            </div>
            <div css={css({ marginBottom: "16px", color: white })}>
                {step.content}
            </div>
            <Button
                variant="textOnly"
                size="normal"
                color="secondary"
                fullWidth
                onClick={handleClick}
                disabled={isDeviceGuideButtonDisabled}
                idForTesting={`device-guide-button-${index}`}
            >
                {isLastStep ? t.Finish : t.Next}
            </Button>
        </div>
    );
};

export default GuideTooltip;
