/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx, SerializedStyles } from "@emotion/react";

import { Fragment, useContext, useEffect, useState } from "react";
import LanguageContext from "../../../../../context/language/languageContext";
import ThemeContext from "../../../../../context/theme/themeContext";
import { noop } from "../../../../../utils/helpers";
import useApi from "../../../../../utils/useApi";
import Card from "../../../../MuiComponents/Card";
import SimRadioButton from "./SimRadioButton";
import PinManagementModal, { ModalType } from "./PinManagementModal";
import { DeviceStatusInformation } from "../../../../../generatedTypes";
import { DeviceStatusAtom } from "../../../../../context/webSockets/webSocketsState";
import { useAtom } from "jotai";

interface SimSecurityInformationProps {
    valueBaseStyles: SerializedStyles;
}

const SimSecurityInformation = ({
    valueBaseStyles,
}: SimSecurityInformationProps) => {
    const [deviceStatus] = useAtom(DeviceStatusAtom);
    const activeSim = (deviceStatus as DeviceStatusInformation).simSecurity
        .activeSim;
    const simStates = (deviceStatus as DeviceStatusInformation).simSecurity
        .simStates;

    const {
        colors: { gray700 },
    } = useContext(ThemeContext);

    const { selectedLanguage, t } = useContext(LanguageContext);

    const { postData } = useApi();

    const [isRadioButtonPending, setRadioButtonPending] = useState(false);
    const [radioButtonValue, setRadioButtonValue] = useState(0);
    const [modalType, setModalType] = useState<ModalType | null>(null);
    const [retriesLeft, setRetriesLeft] = useState<number>(0);

    useEffect(() => {
        if (simStates) {
            const activeSimState = simStates.find(
                (sim) => sim.slotIndex == activeSim
            );
            setRetriesLeft(activeSimState?.retriesLeft ?? 0);
        }
    }, [simStates, activeSim]);

    const handleOpenModal = (type: ModalType) => setModalType(type);
    const handleCloseModal = () => setModalType(null);

    const handleRadioButtonChange = async (
        event: React.ChangeEvent<HTMLInputElement> | number
    ) => {
        try {
            const simId =
                typeof event === "number" ? event : Number(event.target.value);
            setRadioButtonPending(true);
            setRadioButtonValue(simId);
            await postData(`${selectedLanguage}/command/toggleactivesim`, {
                simId: simId,
            });
            setRadioButtonPending(false);
        } catch (error) {
            setRadioButtonPending(false);
        }
    };

    return (
        <Fragment>
            <Card
                title={t.SimSecurityStatusTitle}
                description={t.SimSecurityStatusDescription}
            >
                <div
                    css={css({
                        valueBaseStyles,
                        margin: "8px 0",
                        fontWeight: 600,
                        color: gray700,
                    })}
                >
                    {t.ActiveSim + ":"}
                </div>

                {simStates?.map((simState) => {
                    return (
                        <SimRadioButton
                            key={simState.slotIndex}
                            simId={simState.slotIndex}
                            simName={"SIM " + (simState.slotIndex + 1)}
                            simStatus={simState.status!}
                            simImsi={simState.imsi}
                            simIccid={simState.iccid!}
                            simLockEnabled={simState.lockEnabled}
                            onRadioButtonChange={
                                isRadioButtonPending &&
                                activeSim === simState.slotIndex
                                    ? noop
                                    : handleRadioButtonChange
                            }
                            radioButtonValue={
                                simStates?.length > 1 ? simState.slotIndex : -1
                            }
                            isActive={activeSim === simState.slotIndex}
                            checked={radioButtonValue === simState.slotIndex}
                            openModal={handleOpenModal}
                        />
                    );
                })}
            </Card>

            <PinManagementModal
                simId={activeSim}
                type={modalType}
                retriesLeft={retriesLeft}
                close={handleCloseModal}
            />
        </Fragment>
    );
};

export default SimSecurityInformation;
