/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import { ReactComponent as FolderOpenIcon } from "../../../../../assets/icons/FolderOpenIcon.svg";

import { useContext } from "react";
import InputField from "../../../../MuiComponents/InputField";
import LanguageContext from "../../../../../context/language/languageContext";
import useDesktopHost from "../../../../../utils/useDesktopHost";
import Button from "../../../../MuiComponents/Button";
import ThemeContext from "../../../../../context/theme/themeContext";

export interface ConfiguratorLogProps {
    path: string;
}

const ConfiguratorLog = ({ path }: ConfiguratorLogProps) => {
    const { t } = useContext(LanguageContext);
    const {
        colors: { gray100 },
    } = useContext(ThemeContext);

    const { openDirectory } = useDesktopHost();

    return (
        <InputField
            fullWidth
            name="configuratorLogPath"
            value={path}
            size="medium"
            label={t.TctLog}
            additionalContent={
                <Button
                    variant="iconOnly"
                    size="normal"
                    color="white"
                    icon={<FolderOpenIcon />}
                    onClick={() => openDirectory(path)}
                    css={css({
                        backgroundColor: gray100,
                        borderRadius: "6px",
                        marginLeft: "auto",
                    })}
                    idForTesting="configuratorLogPathButton"
                />
            }
            readOnly
            disabled
        />
    );
};

export default ConfiguratorLog;
