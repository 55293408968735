/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from "@emotion/react";

import { ChangeEvent, MouseEvent, useContext, useReducer, useRef } from "react";
import LanguageContext from "../../../../../../../context/language/languageContext";
import AlertContext from "../../../../../../../context/alert/alertContext";
import disablePasswordReducer from "./disablePasswordReducer";
import PasswordModal from "../../../../../../MuiComponents/Modals/PasswordModal";
import InputField from "../../../../../../MuiComponents/InputField";
import Tooltip from "../../../../../../MuiComponents/Tooltip";
import IconButton from "@mui/material/IconButton";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
    convertAxiosErrorToErrorDetails,
    noop,
} from "../../../../../../../utils/helpers";
import {
    HANDLE_CHANGE_PASSWORD,
    HANDLE_ERROR,
    HANDLE_VISIBILITY,
    SET_LOADING,
} from "./disablePasswordActions";
import { Data } from "../index";
import MenuItemContext from "../../../../../../../context/menuItem/menuItemContext";
import { DeviceFamily } from "../../../../../../../utils/types";
import LayoutContext from "../../../../../../../context/layout/layoutContext";
import {
    AlertStatus,
    HttpStatusCodes,
} from "../../../../../../../constants/constants";
import useTct from "../../../../../../../utils/useTct";

interface DisablePasswordProps {
    isOpen: boolean;
    close: () => void;
    togglePassword: (value: boolean) => void;
    isRecoveryPassword?: boolean;
    data: Data;
}

const DisablePassword = ({
    isOpen,
    close,
    togglePassword,
    isRecoveryPassword,
    data,
}: DisablePasswordProps) => {
    const {
        component: { parameterId, label },
    } = data;
    const { t } = useContext(LanguageContext);
    const { setAlert } = useContext(AlertContext);
    const { findParameterValueById, updateParameter } =
        useContext(MenuItemContext);
    const { layoutData } = useContext(LayoutContext);

    const { validateParameterAsync } = useTct();

    const elementRef = useRef(null);

    const initialState = {
        isLoading: false,
        isDisabled: true,
        password: "",
        passwordShow: false,
        errorPassword: null,
    };

    const [state, dispatch] = useReducer(disablePasswordReducer, initialState);

    const { isLoading, isDisabled, password, passwordShow, errorPassword } =
        state;

    const setLoading = (isLoadingSet: boolean) =>
        dispatch({ type: SET_LOADING, payload: isLoadingSet });

    const handleChange = (e: ChangeEvent<HTMLInputElement>) =>
        dispatch({
            type: HANDLE_CHANGE_PASSWORD,
            payload: e.target.value,
        });

    const handleToggleVisibility = (e: MouseEvent<HTMLButtonElement>) => {
        dispatch({ type: HANDLE_VISIBILITY });
        e.preventDefault();
    };

    const handleError = (value: string | null) =>
        dispatch({ type: HANDLE_ERROR, payload: value });

    const handleClose = () => {
        handleError(null);
        close();
    };

    const sendPayload = async () => {
        setLoading(true);
        const previousValue = findParameterValueById(parameterId);
        if (
            (!isFtcDeviceFamily || !layoutData?.isOnline) &&
            password !== previousValue
        ) {
            handleError(t.WrongPassword);
            setLoading(false);
            return;
        }
        try {
            //check if passwords are correct on device
            if (isFtcDeviceFamily && layoutData?.isOnline) {
                try {
                    await validateParameterAsync(
                        layoutData.id,
                        parameterId,
                        password
                    );
                } catch (err: any) {
                    if (
                        err.response.status !=
                        HttpStatusCodes.UnprocessableEntity
                    ) {
                        const error = convertAxiosErrorToErrorDetails(err);
                        handleError(error.description);
                        return;
                    }
                } finally {
                    setLoading(false);
                }
                handleError("");
            }
            updateParameter(
                parameterId,
                "",
                label,
                elementRef,
                () => {
                    setAlert(AlertStatus.Success, t.DisabledPasswordSuccess);
                    togglePassword(false);
                    handleClose();
                },
                () => {
                    handleError(t.WrongPassword);
                    setLoading(false);
                },
                null,
                true
            );
        } catch (err: any) {
            const error = err.response?.data;
            setAlert(AlertStatus.Critical, error.detail);
        }
    };

    const isFtcDeviceFamily =
        layoutData?.deviceFamily &&
        (layoutData?.deviceFamily as unknown) === DeviceFamily.FTC;

    const renderTitle = () => {
        if (isRecoveryPassword) {
            return t.DisableRecoveryPassword;
        }

        return isFtcDeviceFamily ? t.DisablePasswordPrimary : t.DisablePassword;
    };

    const renderLabel = () => {
        if (isRecoveryPassword) {
            return "Enter current recovery password";
        }
        return isFtcDeviceFamily
            ? t.EnterCurrentPrimaryPassword
            : t.EnterCurrentPassword;
    };
    return (
        <PasswordModal
            isOpen={isOpen}
            isDisabled={isDisabled}
            isLoading={isLoading}
            title={renderTitle()}
            submitBtnText={t.Disable}
            close={handleClose}
            sendPayload={sendPayload}
        >
            <InputField
                fullWidth
                size="medium"
                label={renderLabel()}
                placeholder={t.EnterHere}
                type={passwordShow ? "text" : "password"}
                error={errorPassword}
                onFocus={() => (errorPassword ? handleError(null) : noop())}
                onChange={handleChange}
                iconRight={
                    <Tooltip
                        title={passwordShow ? t.HidePassword : t.ShowPassword}
                        placement="top-end"
                        css={css({
                            "& .MuiTooltip-tooltip": {
                                marginRight: "-6px",
                            },
                        })}
                    >
                        <IconButton
                            disableRipple
                            onMouseDown={handleToggleVisibility}
                            size="large"
                        >
                            {passwordShow ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                    </Tooltip>
                }
            />
        </PasswordModal>
    );
};

export default DisablePassword;
