/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from "@emotion/react";
import { useContext } from "react";
import {
    IoTableColumns,
    IoTableColumnTitles,
} from "../../../../../../../constants/constants";
import InputOutputContext from "../../../../../../../context/inputOutput/inputOutputContext";
import ThemeContext from "../../../../../../../context/theme/themeContext";
import { Component } from "../../../../../../../generatedTypes";
import TableCollection from "./TableCollection";
import TableInput from "./TableInput";
import TableSwitchInput from "./TableSwitchInput";

export interface MobileTableCardProps {
    row: {
        name: string;
        description: string;
        parameterValue: { id: number; switchValue: string };
        eventOnly: Component;
        highLevel: Component;
        lowLevel: Component;
        operand: Component;
        phone: Component;
        priority: Component;
        text: Component;
        averagingConstant: Component;
        currentValue: string;
        canSrc: Component;
        canType: Component;
        canId: Component;
        dataMask: Component;
    };
}

const MobileTableCard = ({ row }: MobileTableCardProps) => {
    const { activeColumns } = useContext(InputOutputContext);
    const {
        colors: { gray700, blue100, blue200, gray100, gray300, white },
        boxShadows: { blueBoxShadow, ioTableCardBoxShadow },
    } = useContext(ThemeContext);
    const isHiddenRow = (colName: string) =>
        activeColumns.some((activeCol) => activeCol === colName) ? true : null;
    return (
        <div
            css={css({
                marginBottom: "8px",
                filter: "drop-shadow(0px 1px 4px rgba(15, 23, 42, 0.12))",
                fontWeight: 600,
            })}
        >
            <div
                css={css({
                    backgroundColor: blue100,
                    padding: "12px 24px",
                    boxShadow: blueBoxShadow,
                })}
            >
                {row.name}
            </div>
            <div
                css={css({
                    display: "flex",
                    alignItems: "center",
                    padding: "8px 16px",
                    justifyContent: "space-between",
                    backgroundColor: white,
                    boxShadow: ioTableCardBoxShadow,
                })}
            >
                <div>
                    <div
                        css={css({
                            marginBotom: "6px",
                            fontWeight: 600,
                            fontSize: "14px",
                            lineHeight: "20px",
                            letterSpacing: "0.1px",
                        })}
                    >
                        {row.name}
                    </div>
                    <div
                        css={css({
                            fontWeight: 600,
                            fontSize: "12px",
                            lineHeight: "16px",
                            letterSpacing: "0.4px",
                        })}
                    >
                        {row.description}
                    </div>
                </div>
                <div>
                    <TableSwitchInput
                        id={row.parameterValue.id}
                        label={String(row.parameterValue.id)}
                    />
                </div>
            </div>
            <div
                css={css({
                    padding: "12px 16px",
                    backgroundColor: gray100,
                    boxShadow: ioTableCardBoxShadow,
                })}
            >
                <div css={css({ fontSize: "10px", color: gray700 })}>
                    {IoTableColumnTitles.currentValue}
                </div>
                <div css={css({ fontSize: "14px" })}>
                    {row.currentValue || "N/A"}
                </div>
            </div>
            {isHiddenRow(IoTableColumns.priority) && (
                <div
                    css={css({
                        padding: "12px 16px",
                        backgroundColor: white,
                        boxShadow: ioTableCardBoxShadow,
                    })}
                >
                    <div css={css({ fontSize: "10px", color: gray700 })}>
                        {IoTableColumnTitles.priority}
                    </div>
                    <div css={css({ fontSize: "14px" })}>
                        <TableCollection
                            data={row.priority}
                            switchId={row.parameterValue.id}
                        />
                    </div>
                </div>
            )}
            {isHiddenRow(IoTableColumns.operand) && (
                <div
                    css={css({
                        padding: "12px 16px",
                        backgroundColor: white,
                        boxShadow: ioTableCardBoxShadow,
                    })}
                >
                    <div css={css({ fontSize: "10px", color: gray700 })}>
                        {IoTableColumnTitles.operand}
                    </div>
                    <div css={css({ fontSize: "14px" })}>
                        <TableCollection
                            data={row.operand}
                            switchId={row.parameterValue.id}
                        />
                    </div>
                </div>
            )}
            {isHiddenRow(IoTableColumns.lowLevel) && (
                <div
                    css={css({
                        padding: "12px 16px",
                        backgroundColor: white,
                        boxShadow: ioTableCardBoxShadow,
                    })}
                >
                    <div css={css({ fontSize: "10px", color: gray700 })}>
                        {IoTableColumnTitles.lowLevel}
                    </div>
                    <div css={css({ fontSize: "14px" })}>
                        {row.lowLevel ? (
                            <TableInput data={row.lowLevel} />
                        ) : null}
                    </div>
                </div>
            )}
            {isHiddenRow(IoTableColumns.highLevel) && (
                <div
                    css={css({
                        padding: "12px 16px",
                        backgroundColor: white,
                        boxShadow: ioTableCardBoxShadow,
                    })}
                >
                    <div css={css({ fontSize: "10px", color: gray700 })}>
                        {IoTableColumnTitles.highLevel}
                    </div>
                    <div css={css({ fontSize: "14px" })}>
                        {row.highLevel ? (
                            <TableInput data={row.highLevel} />
                        ) : null}
                    </div>
                </div>
            )}
            {isHiddenRow(IoTableColumns.eventOnly) && (
                <div
                    css={css({
                        padding: "12px 16px",
                        backgroundColor: white,
                        boxShadow: ioTableCardBoxShadow,
                    })}
                >
                    <div css={css({ fontSize: "10px", color: gray700 })}>
                        {IoTableColumnTitles.eventOnly}
                    </div>
                    <div css={css({ fontSize: "14px" })}>
                        {row.eventOnly ? (
                            <TableInput data={row.eventOnly} />
                        ) : null}
                    </div>
                </div>
            )}
            {isHiddenRow(IoTableColumns.avgConst) && (
                <div
                    css={css({
                        padding: "12px 16px",
                        backgroundColor: white,
                        boxShadow: ioTableCardBoxShadow,
                    })}
                >
                    <div css={css({ fontSize: "10px", color: gray700 })}>
                        {IoTableColumnTitles.avgConst}
                    </div>
                    <div css={css({ fontSize: "14px" })}>
                        {row.averagingConstant ? (
                            <TableInput data={row.averagingConstant} />
                        ) : null}
                    </div>
                </div>
            )}
            {isHiddenRow(IoTableColumns.sendSmsTo) && (
                <div
                    css={css({
                        padding: "12px 16px",
                        backgroundColor: white,
                        boxShadow: ioTableCardBoxShadow,
                    })}
                >
                    <div css={css({ fontSize: "10px", color: gray700 })}>
                        {IoTableColumnTitles.sendSmsTo}
                    </div>
                    <div css={css({ fontSize: "14px" })}>
                        {row.phone ? <TableInput data={row.phone} /> : null}
                    </div>
                </div>
            )}
        </div>
    );
};

export default MobileTableCard;
