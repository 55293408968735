import React from "react";
import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

const SearchSectionsIcon = (props: SvgIconProps) => {
    return (
        <SvgIcon {...props}>
            <path
                d="M8.33333 5H4.66667C4.3 5 4 5.3 4 5.66667V7.33333C4 7.7 4.3 8 4.66667 8H8.33333C8.7 8 9 7.7 9 7.33333V5.66667C9 5.3 8.7 5 8.33333 5ZM14 2H1.99996C1.26663 2 0.666626 2.6 0.666626 3.33333V12.6667C0.666626 13.4 1.26663 13.9867 1.99996 13.9867H14C14.7333 13.9867 15.3333 13.4 15.3333 12.6667V3.33333C15.3333 2.6 14.7333 2 14 2ZM13.3333 12.6733H2.66663C2.29996 12.6733 1.99996 12.3733 1.99996 12.0067V3.98667C1.99996 3.62 2.29996 3.32 2.66663 3.32H13.3333C13.7 3.32 14 3.62 14 3.98667V12.0067C14 12.3733 13.7 12.6733 13.3333 12.6733Z"
                fill="currentColor"
            />
        </SvgIcon>
    );
};

export default SearchSectionsIcon;
