/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from "@emotion/react";

import { useContext, Fragment } from "react";
import ThemeContext from "../../../../../context/theme/themeContext";
import SearchContext from "../../../../../context/search/searchContext";
import NoSearchResults from "./NoSearchResults";
import Parameters from "./Parameters";
import Sections from "./Sections";

const SearchResults = () => {
    const {
        colors: { gray300 },
    } = useContext(ThemeContext);

    const { results } = useContext(SearchContext);

    const parametersExist = results!.parameters.length > 0;
    const sectionsExist = results!.sections.length > 0;

    return !parametersExist && !sectionsExist ? (
        <NoSearchResults />
    ) : (
        <Fragment>
            {parametersExist && <Parameters results={results!.parameters} />}

            {parametersExist && sectionsExist && (
                <div
                    css={css({
                        height: "1px",
                        backgroundColor: gray300,
                    })}
                />
            )}

            {sectionsExist && <Sections results={results!.sections} />}
        </Fragment>
    );
};

export default SearchResults;
