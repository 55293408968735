/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from "@emotion/react";

import { Fragment, useContext } from "react";
import LanguageContext from "../../../../context/language/languageContext";
import Button from "../../../MuiComponents/Button";
import ShowMoreIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import ShowLessIcon from "@mui/icons-material/KeyboardArrowUpRounded";

interface ToggleButtonProps {
    closed: boolean;
    count: number;
    toggleDevices: () => void;
}

const ToggleButton = ({ closed, count, toggleDevices }: ToggleButtonProps) => {
    const { t } = useContext(LanguageContext);

    return (
        <Fragment>
            {count > 0 && (
                <Button
                    fullWidth
                    variant="iconRight"
                    size="normal"
                    color="secondary"
                    icon={closed ? <ShowMoreIcon /> : <ShowLessIcon />}
                    onClick={toggleDevices}
                    css={css({
                        marginTop: "16px",
                    })}
                    idForTesting="toggleButton"
                >
                    {closed ? `${t.ShowMore} (${count})` : t.ShowLess}
                </Button>
            )}
        </Fragment>
    );
};

export default ToggleButton;
