import React, { useContext, useState, Fragment } from "react";
import ThemeContext from "../../context/theme/themeContext";
import MuiTabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

export interface Tab {
    title: string;
    content: React.ReactNode;
}

export interface TabsProps {
    tabs: Tab[];
    small?: boolean;
    variant?: "standard" | "scrollable" | "fullWidth";
}

const Tabs = ({ tabs, small, ...props }: TabsProps) => {
    const {
        colors: { gray50, gray100, gray300, blue700, blue800, textPlaceholder },
    } = useContext(ThemeContext);

    const [value, setValue] = useState(0);
    const [hoveredActive, setHoveredActive] = useState(false);

    const handleChange = (
        e: React.SyntheticEvent<Element, Event>,
        newValue: number
    ) => setValue(newValue);

    const handleMouseEnter = (index: number) =>
        value === index && setHoveredActive(true);

    const handleMouseLeave = (index: number) =>
        value === index && setHoveredActive(false);

    return (
        <Fragment>
            <MuiTabs
                {...props}
                value={value}
                onChange={handleChange}
                sx={{
                    minHeight: "unset",
                    marginBottom: "16px",

                    "& .MuiTabs-indicator": {
                        backgroundColor: hoveredActive ? blue800 : blue700,
                    },
                }}
            >
                {tabs.map((tab, i: number) => (
                    <Tab
                        key={tab.title}
                        label={tab.title}
                        onMouseEnter={() => handleMouseEnter(i)}
                        onMouseLeave={() => handleMouseLeave(i)}
                        onClick={() => setHoveredActive(true)}
                        sx={{
                            color: textPlaceholder,
                            padding: small ? "8px 16px" : "12px 16px",
                            fontSize: small ? "14px" : "16px",
                            fontWeight: "600",
                            lineHeight: "20px",
                            letterSpacing: small ? "0.1px" : "0.15px",
                            textTransform: "none",
                            minHeight: "unset",
                            borderRadius: "9px 9px 0 0",
                            boxShadow: `inset 0px -2px 0px ${gray100}`,

                            "&:hover": {
                                background: gray50,
                                boxShadow: `inset 0px -2px 0px ${gray300}`,
                            },
                        }}
                    />
                ))}
            </MuiTabs>

            {tabs.map(
                (tab, i) =>
                    value === i && <Fragment key={i}>{tab.content}</Fragment>
            )}
        </Fragment>
    );
};

export default Tabs;
