/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import { useContext } from "react";
import ThemeContext from "../../../../context/theme/themeContext";
import LanguageContext from "../../../../context/language/languageContext";
import Badge from "../../Badge";
import { FirmwareType } from "../../../../constants/constants";

export interface VersionBlockProps {
    version: string;
    isNew?: boolean;
    type: string;
}

const VersionBlock: React.FunctionComponent<VersionBlockProps> = ({
    version,
    isNew,
    type,
}) => {
    const {
        colors: { gray200 },
    } = useContext(ThemeContext);
    const { t } = useContext(LanguageContext);

    const isDtb = type === FirmwareType.Dtb;
    const isModem = type === FirmwareType.Modem;

    const renderVersionLabel = () => {
        if (isDtb) {
            return t.DtbVersion;
        }
        if (isModem) {
            return t.ModemVersion;
        }
        return t.FirmwareVersion;
    };

    const renderCurrentVersionLabel = () => {
        if (isDtb) {
            return t.DtbVersionCurrent;
        }
        if (isModem) {
            return t.ModemVersionCurrent;
        }
        return t.FirmwareVersionCurrent;
    };

    const renderNewVersionLabel = () => {
        if (isDtb) {
            return t.DtbVersionNew;
        }
        if (isModem) {
            return t.ModemVersionNew;
        }
        return t.FirmwareVersionNew;
    };

    return (
        <div
            css={css({
                padding: "16px",
                border: `1px solid ${gray200}`,
                borderRadius: "6px",
                fontSize: "16px",
                fontWeight: 600,
                lineHeight: "20px",
                letterSpacing: "0.15px",
                display: "flex",
                gap: "8px",
                alignItems: "center",
            })}
        >
            <span
                css={css({
                    wordBreak: "break-all",
                    maxWidth: "500px",
                })}
            >
                {renderVersionLabel()}: {version}
            </span>
            <span>
                {isNew ? (
                    <Badge color={"success"}>{renderNewVersionLabel()}</Badge>
                ) : (
                    <Badge color={"light"}>{renderCurrentVersionLabel()}</Badge>
                )}
            </span>
        </div>
    );
};

export default VersionBlock;
