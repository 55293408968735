/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import { Fragment, useContext } from "react";
import AvlSearchState from "../../../../../context/avlSearch/avlSearchState";
import ThemeContext from "../../../../../context/theme/themeContext";
import { Frame } from "../../../../../generatedTypes";
import useMediaQueries from "../../../../../utils/useMediaQueries";
import CustomTable from "./CustomTable";
import Divider from "../../../../MuiComponents/Divider";

export interface SmsEventTableFrameProps {
    data: Frame;
}

const SmsEventTableFrame = ({ data }: SmsEventTableFrameProps) => {
    const { name, description, blocks } = data;

    const {
        colors: { textDark },
    } = useContext(ThemeContext);

    const { fromSm } = useMediaQueries();

    return (
        <Fragment>
            <div
                css={css`
                    padding: 24px 24px 16px 24px;
                    color: ${textDark};

                    & > p {
                        font-size: 14px;
                        font-weight: 400;
                        line-height: 20px;
                        letter-spacing: 0.1px;
                        margin-top: ${fromSm ? "6px" : "12px"};
                    }
                `}
            >
                <div
                    css={css`
                        display: flex;
                        justify-content: space-between;
                        align-items: center;

                        & > h4 {
                            font-size: 20px;
                            font-weight: 600;
                            line-height: 24px;
                            letter-spacing: 0.15px;
                        }
                    `}
                >
                    <h4>{name}</h4>
                </div>
                {description && <p>{description}</p>}
            </div>
            <Fragment>
                <Divider hasLighterGray />

                <div
                    css={css`
                        padding: 16px 24px 24px;
                        overflow: hidden;
                    `}
                >
                    <AvlSearchState>
                        <CustomTable
                            data={blocks[0].smsEventList!.smsEventListTable}
                        />
                    </AvlSearchState>
                </div>
            </Fragment>
        </Fragment>
    );
};

export default SmsEventTableFrame;
