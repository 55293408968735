import React, { SyntheticEvent, useContext } from "react";
import AlertContext from "../../../../context/alert/alertContext";
import LanguageContext from "../../../../context/language/languageContext";
import LayoutContext from "../../../../context/layout/layoutContext";
import useApi from "../../../../utils/useApi";
import ConfirmModal from "../../../MuiComponents/Modals/ConfirmModal";
import useConfiguration from "../../../../utils/useConfiguration";
import useNavigation from "../../../../utils/useNavigation";
import { AlertStatus } from "../../../../constants/constants";

export interface ResetConfigAgreementModalProps {
    isOpen: boolean;
    close: () => void;
}
const ResetConfigAgreementModal = ({
    isOpen,
    close,
}: ResetConfigAgreementModalProps) => {
    const { setAlert } = useContext(AlertContext);
    const { selectedLanguage, t } = useContext(LanguageContext);

    const {
        startActionLoading,
        stopActionLoading,
        layoutData,
        setLayoutError,
    } = useContext(LayoutContext);

    const { getData } = useApi();
    const { refreshCurrentPage } = useNavigation();
    const { getConfigurationFromDevice } = useConfiguration();

    const getLayoutData = async () => {
        try {
            await getConfigurationFromDevice(
                layoutData?.port ?? "",
                Number(layoutData?.connectionType),
                true
            );
            refreshCurrentPage();
        } catch (error) {
            setLayoutError(error);
        }
    };

    const handleSubmit = async (e: SyntheticEvent) => {
        e.preventDefault();
        close();

        try {
            startActionLoading("configResetting");

            await getData(`${selectedLanguage}/device/reset`);
            await getLayoutData();

            setAlert(AlertStatus.Success, t.ResetConfigurationSucceeded);
        } catch (error) {
            setAlert(AlertStatus.Critical, t.ResetConfigurationFailed);
        } finally {
            stopActionLoading();
        }
    };

    return (
        <ConfirmModal
            open={isOpen}
            close={close}
            submit={handleSubmit}
            title={t.DoYouWantToResetConfiguration}
            description={t.ResetConfigurationDescription}
        />
    );
};

export default ResetConfigAgreementModal;
