import { createContext } from "react";
import { AlertState } from "./alertReducer";
import { AlertStatus } from "../../constants/constants";

interface AlertContextState {
    setAlert: (
        type: AlertStatus | undefined,
        title: string | undefined,
        description?: string | null,
        buttonPrimary?: string | null,
        handlePrimaryBtnClick?: any,
        buttonSecondary?: string | null,
        handleSecondaryBtnClick?: any
    ) => void;
    removeAlert: () => void;
    alert: AlertState | null;
}

const AlertContext = createContext({} as AlertContextState);

export default AlertContext;
