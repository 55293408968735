/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from "@emotion/react";

import { useContext } from "react";
import ThemeContext from "../../../../context/theme/themeContext";
import ConfigurationContext from "../../../../context/configuration/configurationContext";
import Box from "../../../Box";
import NoSearchResults from "../NoSearchResults";
import SingleMobileCard from "./SingleMobileCard";
import Footer from "../Footer";

export interface MobileCardsProps {
    page: number;
    cardsPerPage: number;
    noSearchResults: boolean;
    toggleLegacyDevices: () => void;
    changePage: (e: any, page: number) => void;
    changeCardsPerPage: (rowsPerPage: number) => void;
}

const MobileCards = ({
    page,
    cardsPerPage,
    noSearchResults,
    toggleLegacyDevices,
    changePage,
    changeCardsPerPage,
}: MobileCardsProps) => {
    const {
        colors: { gray100 },
    } = useContext(ThemeContext);

    const { offlineConfigurations } = useContext(ConfigurationContext);

    return (
        <Box
            className={noSearchResults ? "remaining-height" : ""}
            css={css`
                background: ${gray100};

                & > div + div {
                    margin-top: 4px;
                }

                & > div:first-of-type {
                    border-radius: 8px 8px 0 0;
                }

                & > div:last-of-type {
                    margin-top: 0;
                }
            `}
        >
            {noSearchResults ? (
                <NoSearchResults />
            ) : (
                offlineConfigurations
                    .slice(
                        page * cardsPerPage,
                        page * cardsPerPage + cardsPerPage
                    )
                    .map((card, i) => {
                        return (
                            <SingleMobileCard
                                key={`${i}-${card.fmType}`}
                                data={card}
                            />
                        );
                    })
            )}

            <Footer
                page={page}
                rowsPerPage={cardsPerPage}
                toggleLegacyDevices={toggleLegacyDevices}
                changePage={changePage}
                changeRowsPerPage={changeCardsPerPage}
            />
        </Box>
    );
};

export default MobileCards;
