import {
    AvlSearchResult,
    BluetoothView,
    CustomTable,
    DeviceGuide,
    DtResponse,
    FEElement,
    Frame,
    GeneratedLayout,
    GeofenceTable,
    HardwareDefaultSpec,
    HardwareInfo,
    LanguageInfo,
    ManualGeofence,
    MenuItem,
    SearchResult,
    SpecDefinition,
    SystemInformation,
    TranslatableName,
    UserInfo,
} from "../generatedTypes";
import { useContext } from "react";
import AppTypeContext from "../context/appType/appTypeContext";
import useWasm from "./useWasm";
import useApi from "./useApi";
import { DeviceFamily } from "./types";
import { AxiosResponse, CancelToken } from "axios";
import { SelectedFile } from "./useUploadFile";

export interface Tct {
    showErrorMsg: (
        err: AxiosResponse<{ message: string }>,
        customErrorTitle?: string,
        customErrorDescription?: string
    ) => void;

    getOfflineConfigurationAsync: (
        hwVersion: string,
        specId?: number
    ) => Promise<GeneratedLayout>;
    getConfigurationFromFileAsync: (
        selectedFile: File
    ) => Promise<GeneratedLayout>;
    connectAsync: (
        portName: string,
        connectionType: number
    ) => Promise<GeneratedLayout>;
    reconnectAsync: () => Promise<GeneratedLayout>;
    getConfigurationFromFotaAsync: (fileId: number) => Promise<GeneratedLayout>;

    getMenuItemAsync: (
        configurationId: number,
        menuItemId: number
    ) => Promise<MenuItem>;
    getFrameAsync: (
        configurationId: number,
        menuItemId: number,
        frameId: number
    ) => Promise<Frame>;

    getModifiedParameters: (configurationId: number) => Promise<any>;

    getDeviceGuideAsync: (configurationId: number) => Promise<DeviceGuide>;
    setGuideStepAsCompletedAsync: (
        configurationId: number,
        stepIndex: number
    ) => Promise<void>;

    getUserInfoAsync: () => Promise<UserInfo>;

    getLanguagesAsync: () => Promise<LanguageInfo[]>;
    changeLanguageAsync: (
        newLanguage: string,
        configurationId?: number
    ) => Promise<{ [index: string]: string }>;
    translateAsync: (
        configurationId: number,
        keysToTranslate: string[],
        deviceFamily: DeviceFamily,
        specId: number
    ) => Promise<TranslatableName[]>;

    saveToFileAsync: (
        configurationId: number,
        filePath: string,
        modifiedOnly: boolean,
        skipPasswords: boolean
    ) => Promise<void>;
    saveToFotaAsync: (
        configurationId: number,
        fileName: string,
        modifiedOnly: boolean,
        skipPasswords: boolean
    ) => Promise<void>;
    saveToDeviceAsync: (
        configurationId: number,
        skipPasswords: boolean
    ) => Promise<void>;

    getBluetoothViewAsync: (configurationId: number) => Promise<BluetoothView>;
    getBluetoothPresetListAsync: (configurationId: number) => Promise<string[]>;
    getBluetoothTableAsync: (
        configurationId: number,
        modalName: string,
        presetName?: string
    ) => Promise<CustomTable>;
    getBluetoothModalDataAsync: (
        configurationId: number,
        modalName: string,
        isEdit: boolean
    ) => Promise<any>;
    saveBluetoothPresetAsync: (
        configurationId: number,
        tableName: string,
        presetName: string,
        parameters: any
    ) => Promise<void>;
    updateSensorAsync: (
        configurationId: number,
        modalName: string,
        parameters: any
    ) => Promise<void>;
    removeSensorAsync: (
        configurationId: number,
        modalName: string,
        sensorNr: number
    ) => Promise<void>;
    searchAsync: (
        configurationId: number,
        searchTerm: string,
        cancelToken?: CancelToken
    ) => Promise<SearchResult[]>;
    searchAvlIdsAsync: (
        configurationId: number,
        searchTerm: string
    ) => Promise<AvlSearchResult[]>;

    getGeozoneTableAsync: (configurationId: number) => Promise<GeofenceTable>;
    getGeozoneAsync: (
        configurationId: number,
        geozoneId: number,
        restoreDefaults: boolean
    ) => Promise<ManualGeofence>;

    getParameterTooltipAsync: (
        configurationId: number,
        parameterId: number
    ) => Promise<FEElement>;
    getBlockTooltipAsync: (
        configurationId: number,
        blockName: string
    ) => Promise<FEElement>;

    chechFileCompatibilityAsync: (
        configurationId: number,
        configFile: SelectedFile
    ) => Promise<void>;
    loadParametersFromFileAsync: (
        configurationId: number,
        configFile: SelectedFile
    ) => Promise<DtResponse>
    importCsvForListConfigAsync: (
        configurationId: number,
        name: string,
        file: SelectedFile
    ) => Promise<any>;
    validateParameterAsync: (
        configurationId: number,
        parameterId: number,
        value: string
    ) => Promise<void>;
    postSettingsAsync: (settings: { [key: string]: any }) => Promise<void>;
    postSettingsHideOptionsAsync: (settings: {
        [key: string]: any;
    }) => Promise<void>;
    getSettingsAsync: () => Promise<{ [key: string]: any }>;
    getSystemInformationAsync: () => Promise<SystemInformation>;
    getSettingsWithQueryParamsAsync: (
        hwVersion: string,
        specId: number,
        isOnline: boolean
    ) => Promise<any>;
    getStartupDevicesAsync: () => Promise<HardwareInfo>;
    getExperimentalConfigurationAsync: () => Promise<any>;
    postExternalConfigurationAsync: (
        value: string | null,
        isDefault: boolean
    ) => Promise<void>;
    updateParameterAsync: (
        configurationId: number,
        parameterId: number,
        newValue: string | number,
        controllers: any,
        forceDependencyUpdate: boolean
    ) => Promise<any>;
    updateParametersAsync: (
        configurationId: number,
        payload: { [key: string]: any },
        includeAllFails?: boolean,
        tryToFix?: boolean
    ) => Promise<any>;
    getBluetoothDevicesAsync: () => Promise<any>;
    getBluetoothDevicesScanAsync: () => Promise<any>;
    
    getSpecsAsync: () => Promise<SpecDefinition[]>;
    getDefaultSpecsAsync: () => Promise<HardwareDefaultSpec[]>;
}

const useTct = (): Tct => {
    const { isWasm } = useContext(AppTypeContext);

    return isWasm ? useWasm() : useApi(); // NOSONAR S6440: React Hooks should be properly called | Justification: In the scope of the application's life, the same hook will always be called, also this is required to implement the strategy pattern
};

export default useTct;
